import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { RequireRole } from '../../components/custom/RequireRole';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity
    }),
    {},
)(RequireRole as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
