export const formatFileSize = (bytes: number|undefined) => {
    if (bytes == undefined) {
        return '';
    }
    else {
        const thresh = 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
        let u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }
}


export const downloadBlob = (blob: Blob, filename: string) => {
    const a = document.createElement('a');

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);

    a.click();
}