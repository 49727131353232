import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as User from '@store/user';
import * as AdminActions from '@store/admin';
import { ManageWorkerAppUser } from '@components/users/ManageWorkerAppUser';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...User.plainActions,
        work: state.user.work,
        ...AdminActions.plainActions,
    }),
    User.actionCreators,
)(ManageWorkerAppUser as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
