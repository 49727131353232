export function storageGet<T>(key: string, defaultValue: T) {
    const data = localStorage.getItem(key);

    if (data != undefined && data != '') {
        return JSON.parse(atob(data)) as T;
    }
    else {
        return defaultValue;
    }
}

export function storageSet<T>(key: string, value: T) {
    localStorage.setItem(key, btoa(JSON.stringify(value)));
}

type Predicate = (key: string) => boolean;

export function storageClear(key: string | Predicate) {
    if (typeof (key) === 'string') {
        localStorage.removeItem(key);
    }
    else {
        const keys = Object.keys(localStorage);
        for (const k of keys) {
            if (key(k)) {
                localStorage.removeItem(k);
            }
        }
    }
}

// storageClear(k => k.startsWith('filters'));
