import { IPaginateOptions, IWorker } from '@models';
import { q, m, getClient } from './graphql';
import { ensureType, formatProperty } from "@store/actions/utils";
import { pushTemporalResource } from './documents';

export async function getWorkers(workId: number, opts: Partial<IPaginateOptions> = {}) {
    const paginateOpts = {
        page: 0,
        limit: 10,
        ...opts,
    };
    return q(`query GetWorkers($workId: Int!, $paginateOpts: PaginateOptionsGraphInputType!) {
        work(id: $workId) {
            paginateWorkers(options: $paginateOpts) {
                page, total, limit,
                data {
                    id, name, surname, code, workerDocumentTypeId, workerTypeId,
                    currentStatusType, currentStatusDate, forcedStatusUntilDate, contractorId
                    contractor {
                        id, name
                    },
                    properties {
                        id, jobHasWorkerId, propertyGroupTypeId, value
                    },
                }
            }
        }
    }`, { workId, paginateOpts }, { returnPath: 'work.paginateWorkers' });
}

export async function getWorker(workId: number, id: number) {
    return q(`query GetWorker($workId: Int!, $id: Int!) {
        work(id: $workId) {
            worker(id: $id) {
                id, name, surname, code, workerDocumentTypeId, workerTypeId,
                currentStatusType, currentStatusDate,
                workId,
                forcedStatusUntilDate, contractorId,
                isActive,
                remarks,
                phone,
                contractor {
                    id, name
                },
                photo { id },
                properties {
                    id, propertyGroupTypeId, workerId, jobHasWorkerId, value
                },
            }
        }
    }`,
        { workId, id },
        { returnPath: 'work.worker' });
}

export interface ISaveWorkerOpts {
    createJobRelations: boolean,
    imageFile?: File,
}

const defaultSaveWorkerOpts = {
    createJobRelations: false,
}

const uploadImageFile = async (f: File | undefined) => {
    if (f) {
        const res = await pushTemporalResource(f);
        return res.hasValue ? res.value : null;
    }
    else {
        return null;
    }
}

export async function saveWorker(workId: number, rawData: IWorker, options: ISaveWorkerOpts = defaultSaveWorkerOpts) {
    const data = ensureType<IWorker>({
        ...rawData,
        properties: (rawData.properties ?? []).map(formatProperty(workId)),
    }, {
        exclude: [
            'currentStatusType', 'currentStatusDate', 'forcedStatusUntilDate',
            'contractor', 'photo'
        ]
    });

    const imageResourceId = await uploadImageFile(options.imageFile);

    return await m(`
mutation SaveWorker($data: WorkerInputType!, $createJobRelations: Boolean, $imageResource: String) {
    saveWorker(worker: $data, createJobRelations: $createJobRelations, imageResource: $imageResource) {
        isError, error, value, hasValue
    }
}
    `,
        {
            data,
            createJobRelations: options.createJobRelations,
            imageResource: imageResourceId,
        },
        { returnPath: 'saveWorker' });
}

export function removeWorker(id: number, workId: number, remove: boolean) {
    return m(`
mutation RemoveWorker($id: Int!, $workId: Int!, $remove: Boolean!) {
    removeWorker(id: $id, workId: $workId, remove: $remove) {
        isError, error, value
    }
}
    `, { id, workId, remove }, { returnPath: 'removeWorker' });
}

export function reactivateWorker(workId: number, id: number) {
    return m(`
mutation ReactivateWorker($id: Int!, $workId: Int!) {
    reactivateWorker(id: $id, workId: $workId) {
        isError, error, value
    }
}
    `, { id, workId }, { returnPath: 'reactivateWorker' });
}

export async function restrictWorker(workId: number, workerId: number, name: string, surname: string, code: string, contractorId: number, applicantName: string, applicantEmail: string, reason: string, remarks: string) {

    const resp = await m(`
mutation RestrictWorker($workId: Int!, $workerId: Int!, $name: String!, $surname: String!, $code: String!, $contractorId: Int!, $applicantName: String!, $applicantEmail: String!, $reason: String!, $remarks: String!) {
    restrictWorker(workId: $workId, workerId: $workerId, name: $name, surname: $surname, code: $code, contractorId: $contractorId, applicantName: $applicantName, applicantEmail: $applicantEmail, reason: $reason, remarks: $remarks) {
        isError, error, value, hasValue
    }
}
    `, { workId, workerId, name, surname, code, contractorId, applicantName, applicantEmail, reason, remarks });

    return resp?.data;

}

export async function removeRestrictedWorker(id: number, workId: number) {
    return m(`
mutation RemoveRestrictedWorker($id: Int!, $workId: Int!) {
    removeRestrictedWorker(id: $id, workId: $workId) {
        isError, error, value, hasValue
    }
}
    `, { id, workId });
}
