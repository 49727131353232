import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { JobInformationLoaded } from '@components/jobs/JobInformationLoaded';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        ...state.identity,
        ...state.user,
    }),
    JobsStore.actionCreators,
)(JobInformationLoaded as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
