import * as React from 'react';

import { INotification, IMachineryType } from '@models';
import {
    classNames,
    InputText,
    NotificationsBox,
    useForm,
    useTranslation,
    ValidationBuilder,
} from '@components';

import './WorkForm.css';

export interface IProps {
    data?: IMachineryType;
    workId: number;
    footer?: any;
    notifications: INotification[];
    onChange?: Function;
    onSubmit: Function;
}

export const validateMachinery = (data: IMachineryType) => {
    return ValidationBuilder.create(data).notEmpty('name').build();
}

export const isValid = (data: IMachineryType) => {
    return Object.keys(validateMachinery(data)).length == 0;
}

export function MachineryTypeForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<IMachineryType>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            workId: props.workId,
        },
        validate: validateMachinery,
        onSubmit: (data: IMachineryType) => {
            props.onSubmit(data);
            return {ok: true};
        },
        onChange: props.onChange,
    });

    return <div>
        <form onSubmit={form.handleSubmit}
            className='p-fluid'>
            <div className='c md pd g-30'>
                {form.floatField('name', t('Name'), () =>
                    <InputText
                    id='name'
                    type='text'
                    value={form.values.name}
                    onChange={form.handleChange}
                    autoFocus
                    className={classNames({ 'p-invalid': !form.isFormFieldValid('name')})}/>
                )}
            </div>
            <div className='errors-container'>
                {form.errorBox()}
                <NotificationsBox notifications={props.notifications} />
            </div>
            <div className='e' />

            <div className='r'>
                <span className='e' />
                <button
                    className='primary'
                    onClick={() => props.onSubmit}>
                    {t('Add')}
                </button>
            </div>
        </form>
    </div>
}