import * as React from 'react';

import { TreeSelect } from '@components';
import { ICountry, countriesToNode } from '@models';

export interface IProps {
    className?: string;
    countries: ICountry[];
    data: IHasCountryAndRegion;
    onChange?: Function;
    readonly?: boolean;
}

interface IHasCountryAndRegion {
    countryId?: number;
    regionId?: number;
    countyId?: number;
}

const getSelectedNodes = (w: IHasCountryAndRegion | undefined) =>
    w ? [w.countryId, w.regionId, w.countyId].filter(d => d != undefined)
        : undefined;

export function CountryRegionSelect(props: IProps) {
    const countryNodes = countriesToNode(props.countries);

    const [data, setData] = React.useState<IHasCountryAndRegion>(props.data);

    const [selectedRegionNode, setSelectedRegionNode] =
        React.useState<any>(getSelectedNodes(props.data));

    const onSelectedRegionNodeChanged = (ev: any) => {
        setSelectedRegionNode(ev.value);
        const [countryId, regionId, countyId] =
            ev.value
                .concat([undefined, undefined, undefined])
                .slice(0, 3);

        setData(d => {
            const newData = { countryId, regionId, countyId };
            props.onChange && props.onChange(newData);
            setData({ ...d, ...newData });
            return newData;
        });
    }

    return <TreeSelect
        className={props.className}
        options={countryNodes}
        value={selectedRegionNode}
        onChange={onSelectedRegionNodeChanged}
        disabled={props.readonly} />;
}