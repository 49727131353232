import { connect } from "react-redux";
import { ApplicationState } from '../../store';
import * as ConstantsStore from '@store/constants';
import * as RequirementsStore from '@store/requirements';
import * as App from '@store/app';

import { AddRequirementIncidence } from '../../components/requirements/AddRequirementIncidence';

export default connect(
    (state: ApplicationState) => ({
        ...RequirementsStore.plainActions,
        user: state.identity?.user,
    }),
    {},
)(AddRequirementIncidence as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any