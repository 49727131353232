const MAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const minLength = (min: number) =>
    (s: number|string|undefined) =>
        s && s != null && typeof(s) === 'string' && s.length >= min;

const notEmpty = (s: number|string|undefined) =>
    s && s != null && (typeof(s) !== 'string' || s.trim() !== '');

const match = (pre: string|undefined) =>
    (value: string|undefined) => value == pre;

const email = (s: string) =>
    s == '' || s == undefined || s.match(MAIL_REGEXP) != undefined;

const validations = {
    minLength,
    match,
    notEmpty,
    email,
}

const validate = (data: any, prop: string, errors: any, fn: Function, message: string = 'Error', all: boolean = false) => {
    const v = fn(data ? (!all ? data[prop] : data) : undefined, data);

    if (!v) {
        errors[prop] = message;
    }
}

export {
    validations,
    validate
};