import * as React from 'react';

import {
    BreadcrumbItem,
    classNames,
    F,
    G,
    i18n,
    IRemoteData,
    R,
    SplitButton,
    Tag,
    ValidationBuilder,
    useConfirm,
    useDialogs,
    useForm,
    useLoading,
    useMemoized,
    useDebounce,
    useMessage,
    useRemoteData,
    useTranslation,
    Chips,
    Button
} from '@components';
import Constants from '../../Constants';
import { INotificationTemplate, IWork, IUserIdentity } from '@models';
import {
    IRequirementExpirationType,
    IRequirementGroup,
    IRequirementType,
    IRequirementTypeDocumentRestriction,
    IRequirementValidationType,
    RequirementTargetType,
    RequirementTypeKind,
    requirementTargetTypes
} from '@models/requirements';
import EditRequirementTypeDependenciesContainer
    from '@containers/admin/requirementTypes/EditRequirementTypeDependenciesContainer';
import EditRequirementTypeExpression from '@containers/admin/requirementTypes/EditRequirementTypeExpressionContainer';
import EditRequirementTypeDocumentRestrictionsContainer
    from '@containers/admin/requirementTypes/EditRequirementTypeDocumentRestrictionsContainer';
import RequirementTypeSuggestions from '@containers/admin/requirementTypes/RequirementTypeSuggestionsContainer';
import RequirementTypeValidationHolders
    from '@containers/admin/requirementTypes/RequirementTypeValidationHoldersContainer';
import { WorkToolbar } from './WorkToolbar';

import './AdminWorkRequirements.scss';
import { flatten } from '@utils';

const requirementTypeKinds = [
    { id: RequirementTypeKind.Mandatory, name: i18n('Mandatory') },
    { id: RequirementTypeKind.Optional, name: i18n('Optional') }
];

function AddRequirementGroupInput({
    onClose, onSuccess, saveRequirementGroup, workId,
}: {
    onClose: Function,
    onSuccess: Function,
    saveRequirementGroup: Function,
    workId: number,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const form = useForm<IRequirementGroup>({
        initialValues: {
            name: '',
            title: '',
            description: '',
            workId: workId,
            isActive: true,
        },
        validateOnMount: true,
        validate: ValidationBuilder
            .new()
            .notEmpty('name')
            .notEmpty('title')
            .lift(),
    });

    const doSave = loading.wrap(async (..._: any) => {
        const res = await saveRequirementGroup(workId, form.values);
        message.set(res);
        if (res.hasValue) {
            onSuccess();
        }
    });

    return <div className='c' style={{ height: '100%', justifyContent: 'space-between' }}>
        <div className='md pd' style={{ height: '70%' }}>
            <div className='form-1 l200' style={{ height: '100%' }}>
                <G label={t('Name')}>{form.input('name', { autoFocus: true })}</G>
                <G label={t('Title')}>{form.input('title')}</G>
                <G label={t('Description')}>{form.input('description')}</G>
                <G label={t('Color')}>{form.input('color', { type: 'color' })}</G>
                <div className='parent-legend-container' style={{ height: '100%' }}>
                    {form.textarea('legend', { containerClassName: 'legend-container', containerStyle: { height: '100%' } })}
                </div>
            </div>
            <div className='center'>
                <h3 style={{ justifyContent: 'center' }}>Previsualización</h3>
            </div>
        </div>
        <div className='center legend' style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: '#000', padding: '10px' }}>
            <div dangerouslySetInnerHTML={{ __html: form.values.legend }} />
        </div>
        {message.render()}
        <div className='footer r r-end'>
            <button
                disabled={loading.isLoading()}
                onClick={() => onClose()}>{t('Cancel')}</button>
            <button
                className='primary'
                disabled={loading.isLoading() || form.isInvalid()}
                onClick={doSave}>
                {t('Save')}
            </button>
        </div>
    </div>
}

function EditRequirementGroup({
    onClose, onSuccess, saveRequirementGroup, workId,
    data
}: {
    data: IRequirementGroup,
    onClose: Function,
    onSuccess: Function,
    saveRequirementGroup: Function,
    workId: number,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const form = useForm<IRequirementGroup>({
        initialValues: data,
        validateOnMount: true,
        validate: ValidationBuilder
            .new()
            .notEmpty('name')
            .notEmpty('title')
            .lift(),
    });

    const doSave = loading.wrap(async (..._: any) => {
        const res = await saveRequirementGroup(workId, form.values);
        message.set(res);
        if (res.hasValue) {
            onSuccess();
        }
    });

    return <div className='c' style={{ height: '100%', justifyContent: 'space-between' }}>
        <div className='md pd' style={{ height: '70%' }}>
            <div className='form-1 l200' style={{ height: '100%' }}>
                <G label={t('Order')}>{form.input('orderValue', { type: 'number' })}</G>
                <div className='parent-legend-container' style={{ height: '100%' }}>
                    {form.textarea('legend', { containerClassName: 'legend-container', containerStyle: { height: '100%' } })}
                </div>
            </div>
            <div className='center'>
                <h3 style={{ justifyContent: 'center' }}>Previsualización</h3>
            </div>
        </div>
        <div className='center legend' style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: '#000', padding: '10px' }}>
            <div dangerouslySetInnerHTML={{ __html: form.values.legend }} />
        </div>
        {message.render()}
        <div className='footer r r-end'>
            <button
                disabled={loading.isLoading()}
                onClick={() => onClose()}>{t('Cancel')}</button>
            <button
                className='primary'
                disabled={loading.isLoading() || form.isInvalid()}
                onClick={doSave}>
                {t('Save')}
            </button>
        </div>
    </div>
}

function AddRequirementTypeInput({
    group, onClose, onSuccess,
    requirementExpirationTypes,
    requirementValidationTypes,
    saveRequirementType,
}: {
    group: IRequirementGroup,
    onClose: Function,
    onSuccess: Function,
    requirementExpirationTypes: IRequirementExpirationType[],
    requirementValidationTypes: IRequirementValidationType[],
    saveRequirementType: Function,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();
    const form = useForm<IRequirementType>({
        initialValues: {
            name: '',
            title: '',
            description: '',
            criteria: '',
            template: '',
            kindId: RequirementTypeKind.Mandatory,
            workId: group.workId,
            requirementGroupId: group.id!,
            targetType: RequirementTargetType.Contractor,
            expirationTypeId: requirementExpirationTypes?.[0]?.id,
            isActive: true,
            isResourcePicture: false,
        },
        validateOnMount: true,
        validate: ValidationBuilder.new().notEmpty('name').lift(),
    });

    const doSave = loading.wrap(async () => {
        const res = await saveRequirementType(group.workId, form.values);
        message.set(res);
        if (res.hasValue) {
            onSuccess();
        }
    });

    return <div className='c'>
        <div className='form-1 l200 md pd'>
            <G label={t('Type')}>
                {form.select('kindId', requirementTypeKinds)}
            </G>
            <G label={t('Name')}>
                {form.input('name')}
            </G>
            <G label={t('Title')}>
                {form.input('title')}
            </G>
            <G label={t('Description')}>
                {form.input('description')}
            </G>
            <G label={t('ResourceType')}>
                {form.select('targetType', requirementTargetTypes)}
            </G>
            <G label={t('Expiration type')}>
                {form.select('expirationTypeId', requirementExpirationTypes)}
            </G>
            <G label={t('Validation type')}>
                {form.select('validationTypeId', requirementValidationTypes)}
            </G>
            <G label={t('Is Resource Picture')}>
                {form.checkBox('isResourcePicture')}
            </G>
        </div>
        {loading.renderBox()}
        {message.render()}
        <div className='footer r r-end'>
            <button disabled={loading.isLoading()} onClick={() => onClose()}>{t('Cancel')}</button>
            <button disabled={loading.isLoading() || form.isInvalid()} onClick={doSave} className='primary'>{t('Save')}</button>
        </div>
    </div>
}

function RequirementTypeConfiguration({
    onClose, onSuccess,
    requirementType, requirementExpirationTypes, requirementValidationTypes,
    saveRequirementType,
    templates,
    users
}: {
    onClose: Function,
    onSuccess: Function,
    requirementType: IRequirementType,
    requirementExpirationTypes: IRequirementExpirationType[],
    requirementValidationTypes: IRequirementValidationType[],
    saveRequirementType: Function,
    templates: INotificationTemplate[],
    users: IUserIdentity[],
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();
    const [excludeUsersIds, setExcludeUsersIds] = React.useState<number[]>(requirementType.usersExcludedChargedNotifications ?? []);

    const form = useForm<IRequirementType>({
        initialValues: requirementType
    });

    const billingTypes = [
        { id: 1, name: t('') },
        { id: 2, name: t('Generic documentation') },
        { id: 3, name: t('Juridic documentation') }
    ];

    const doSave = loading.wrap(async () => {
        form.values.usersExcludedChargedNotifications = excludeUsersIds;
        const resp = await saveRequirementType(requirementType.workId, form.values);
        message.set(resp);
        if (resp.hasValue) {
            onSuccess(form.values);
            onClose();
        }
    });

    const customChip = (item : string) => {
        const user = users.find(u => u.id == parseInt(item));
        return (
            <div>
                <span>{user?.userName}</span>
            </div>
        );
    };

    const addChip = (item: string) => {

        const isNumeric = /^\d+$/.test(item);

        if(isNumeric){
            setExcludeUsersIds([...excludeUsersIds, parseInt(item)])
        }else{
            const user = users.find(u => u.userName == item);
            if(user){
                setExcludeUsersIds([...excludeUsersIds, user.id])
            }
        }
    };

    const chipsOnClick = () => {
        if(excludeUsersIds.length > 0){
            const users = excludeUsersIds.join(',');
            navigator.clipboard.writeText(users);
        }else{
            navigator.clipboard.readText().then(text => {
                const users = text.split(',');
                setExcludeUsersIds(users.map(u => parseInt(u)));
            });
        }
    };


    return <div className='c'>
        <div className='r' style={{ minWidth: '60vw' }}>
            <div className={'c e'}>
                <div className='form-2 grid-2-50p l200 lg pd'>
                    <F label={t('Type')}>
                        {form.select('kindId', requirementTypeKinds)}
                    </F>
                    <F label={t('Shared requirement?')}>
                        {form.checkBox('isShared', { containerClassName: 'center' })}
                    </F>
                    <F label={t('Is public?')}>
                        {form.checkBox('isPublic', { containerClassName: 'center' })}
                    </F>
                    <F label={t('Validation type')}>
                        {form.select('validationTypeId',
                            requirementValidationTypes.map(v => ({ ...v, name: t(v.name) })))}
                    </F>
                    <F label={t('Expiration')}>
                        {form.select('expirationTypeId', requirementExpirationTypes)}
                    </F>
                    <F label={t('Expiration threshold')}>
                        {form.input('expirationThreshold', { type: 'number' })}
                    </F>
                    <F label={t('Criteria')}>
                        {form.editor('criteria', { className: 'criteria-editor' })}
                    </F>
                    <F label={t('Template')}>
                        {form.input('template')}
                    </F>
                    <F label={t('Document generic or juridic')}>
                        {form.select('billingTypeId', billingTypes)}
                    </F>
                </div>
            </div>
            <div className='c e'>
                <div className='form-2 grid-2-50p l200 lg pd'>
                    <F label={t('Color')}>
                        {form.input('color', { type: 'color' })}
                    </F>
                    <div>
                    </div>
                    <div style={{ background: form.values.color, minHeight: '16px', borderRadius: '4px' }}>
                    </div>
                    <F label={t('Message')}>
                        {form.select('messageTemplateId', templates)}
                    </F>
                    <F label={t('Order')}>
                        {form.input('orderValue', { type: 'number' })}
                    </F>
                    <F label={t('requirement-type.notify.contractors')}>
                        {form.checkBox('notifyContractors', { containerClassName: 'center' })}
                    </F>
                    <F label={t('requirement-type.notify.responsible')}>
                        {form.checkBox('notifyResponsible', { containerClassName: 'center' })}
                    </F>
                    <F label={t('requirement-type.require.job-information')}>
                        {form.checkBox('requireJobInformationAccess', { containerClassName: 'center' })}
                    </F>
                    <F label={t('requirement-type.notify.title')}>
                        {form.input('notifyTitle')}
                    </F>
                    <F label={t('requirement-type.notify.policy')}>
                        {form.input('notifyPolicy')}
                    </F>
                    <F label={t('requirement-type.information-charged.notify.exclude_users')}>
                        <div className='exclude-chips'>
                            <Chips
                                className='exclude-users-chips'
                                separator=','
                                value={excludeUsersIds}
                                onAdd={d => addChip(d.value)}
                                onRemove={d => setExcludeUsersIds(excludeUsersIds.filter((o: any) => o != d.value))}
                                allowDuplicate={false}
                                itemTemplate={customChip}
                            />
                            <Button
                            onClick={() => chipsOnClick()}
                            icon={excludeUsersIds.length > 0 ? 'pi pi-upload' : 'pi pi-download' }
                            className='exclude-users-chips-button'
                            />
                        </div>
                    </F>
                    <F label={t('requirement-type.expiration.required')}>
                        {form.checkBox('expirationRequired')}
                    </F>
                    <F label={t('Is Resource Picture')}>
                        {form.checkBox('isResourcePicture')}
                    </F>
                </div>
            </div>
        </div>
        <div className='footer r r-end'>
            <button disabled={loading.isLoading()} onClick={() => onClose()}>{t('Cancel')}</button>
            <button disabled={loading.isLoading()} onClick={_ => doSave()} className='primary'>{t('Save')}</button>
        </div>
    </div>
}

function RequirementTypeSetPolicy({ onClose, onSuccess, requirementType, saveRequirementType }: {
    onClose: Function, onSuccess: Function, requirementType: IRequirementType, saveRequirementType: Function,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const form = useForm<IRequirementType>({
        initialValues: requirementType
    });

    const doSave = loading.wrap(async () => {
        const resp = await saveRequirementType(requirementType.workId, form.values);
        message.set(resp);
        if (resp.hasValue) {
            onSuccess(form.values);
            onClose();
        }
    });

    return <div className='c'>
        <div className='form-2 l200 md pd'>
            <F label={'Policy'}>
                {form.input('policy')}
            </F>
        </div>
        <div className='footer r r-end'>
            <button disabled={loading.isLoading()} onClick={() => onClose()}>{t('Cancel')}</button>
            <button disabled={loading.isLoading()} onClick={_ => doSave()} className='primary'>{t('Save')}</button>
        </div>
    </div>
}

function DocumentRestrictionRepresentation({ restriction }: { restriction: IRequirementTypeDocumentRestriction }) {
    const { t } = useTranslation();

    const style = ['success', 'info', 'warn', 'error'];
    const restrictions: ('mimeTypes' | 'regexp' | 'limitFileSize')[] = ['mimeTypes', 'regexp', 'limitFileSize'];
    const reps = restrictions
        .filter(r => restriction[r])
        .map((r, i) => <Tag severity={style[i]} value={`${t(r)}: ${restriction[r]}`} key={i} />);

    return <div className='r g-10'>
        {reps}
    </div>
}

function RequirementTypeInfo({
    requirementGroups, requirementType, requirementValidationTypes,
}: {
    requirementGroups: IRequirementGroup[],
    requirementType: IRequirementType,
    requirementValidationTypes: IRequirementValidationType[],
}) {
    const { t } = useTranslation();

    const form = useForm({
        initialValues: requirementType,
        readonly: true,
    });

    const [allRequirements, setAllRequirements] = React.useState(
        flatten(requirementGroups.map(g => g.requirementTypes ?? []))
            .filter(r => r.isActive && r.id != requirementType.id));
    const resolveDependency = useMemoized((id: number) => {
        return allRequirements.find(d => d.id == id)?.name;
    });
    const dependencies = requirementType.dependencies ?? [];
    const documentRestrictions =
        (requirementType.documentRestrictions ?? []).filter(r => r.isActive);

    return <div className='form-1 l200'>
        <R label={t('Shared requirement?')}>
            {form.checkBox('isShared')}
        </R>
        <R label={t('Is public?')}>
            {form.checkBox('isPublic')}
        </R>
        <R label={t('Validation type')}>
            {form.select('validationTypeId', requirementValidationTypes)}
        </R>
        <R label={t('Policy')}>
            {form.input('policy')}
        </R>
        <R label={t('Expression')}>
            <code className='code'>
                {form.values.expression}
            </code>
        </R>
        <R label={t('Dependencies')}>
            <div className='box'>
                <ul>
                    {dependencies.map((d, i) =>
                        <li key={i}>{resolveDependency(d.dependsOnRequirementId)}</li>)}
                </ul>
            </div>
        </R>
        <R label={t('Document restrictions')}>
            <div className='box'>
                {documentRestrictions.map((d, i) =>
                    <div key={i} className='r g-10 sm pd'>
                        {d.name} {d.title} <DocumentRestrictionRepresentation restriction={d} />
                    </div>)}
            </div>
        </R>
    </div>
}

function RequirementTypeRow({
    className,
    notificationTemplates,
    requirementGroup,
    requirementGroups, requestRefresh, requirementType,
    requirementExpirationTypes,
    requirementValidationTypes,
    saveRequirementType,
    selectedTargetType,
    work,
    requestSelected,
    users
}: {
    className?: string,
    notificationTemplates: INotificationTemplate[],
    requirementGroup: IRequirementGroup,
    requirementGroups: IRequirementGroup[],
    requestRefresh: Function,
    requirementType: IRequirementType,
    requirementExpirationTypes: IRequirementExpirationType[],
    requirementValidationTypes: IRequirementValidationType[],
    saveRequirementType: Function,
    selectedTargetType: number | undefined,
    work: IWork,
    requestSelected: Function,
    users: IUserIdentity[]
}) {
    const { t } = useTranslation();
    const splitButton = React.useRef<any>();
    const dialogs = useDialogs({
        onShow: () => requestSelected?.(requirementType.id),
    });
    const loading = useLoading();
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const form = useForm<IRequirementType>({
        initialValues: requirementType,
    });

    const saveChanges = async (..._: any) => {
        if (form.hasChanges()) {
            await saveRequirementType(requirementType.workId, form.values);
        }
    }

    const doRemove = async () => {
        const obj = {
            ...form.values,
            isActive: false
        };
        await saveRequirementType(requirementType.workId, obj);
        requestRefresh();
    }

    const confirmRemove = useConfirm({
        accept: doRemove,
        message: t('Are you sure to remove this requirement type?'),
        target: () => splitButton.current.container,
    });

    const actions = [
        { label: t('Configure'), command: dialogs.showFromEvent('configuration') },
        { label: t('Set security policy'), command: dialogs.showFromEvent('set-policy') },
        { label: t('Edit expression'), command: dialogs.showFromEvent('edit-expression') },
        { label: t('Edit dependencies'), command: dialogs.showFromEvent('edit-dependencies') },
        { label: t('Document restrictions'), command: dialogs.showFromEvent('edit-document-restrictions') },
        { label: t('Suggestions'), command: dialogs.showFromEvent('suggestions') },
        { label: t('Validation holders'), command: dialogs.showFromEvent('validationHolders') },
        { label: t('Remove requirement'), command: confirmRemove },
    ];

    const onSuccess = (r: IRequirementType, close: boolean = true) => {
        form.setFieldValues(r);
        if (close) {
            dialogs.clear();
        }
    }

    const allowDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const onDrop = loading.wrap(async (event: React.DragEvent<HTMLDivElement>) => {
        const currentOrder = requirementType.orderValue;
        const sourceId = parseInt(event.dataTransfer.getData('text/plain'));
        const sourceRequirement = requirementGroup.requirementTypes?.find(r => r.id === sourceId);
        if (sourceRequirement) {
            sourceRequirement.orderValue = (currentOrder ?? 0);
            const siblings = requirementGroup
                .requirementTypes?.filter(r =>
                    r.id != sourceRequirement.id
                    && (
                        ((r.orderValue ?? 0) > (currentOrder ?? 0))
                        || ((r.orderValue === currentOrder) && r.id! > requirementType.id!)));
            siblings?.forEach(s => s.orderValue = (s.orderValue ?? 0) + 1);
            requirementType.orderValue = (currentOrder ?? 0) + 1;

            await saveRequirementType(requirementType.workId, requirementType);
            await saveRequirementType(requirementType.workId, sourceRequirement);
            for (const s of siblings ?? []) {
                await saveRequirementType(requirementType.workId, s);
            }
            await requestRefresh();
        }
    });

    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('text/plain', requirementType.id + '');
    }

    return <>
        <tr className={className}>
            <td onDrop={onDrop} onDragOver={allowDrop} className='center'>
                {dialogs.render('edit-document-restrictions', {
                    title: t('Edit document restrictions') + ': ' + requirementType.name,
                }, () =>
                    <EditRequirementTypeDocumentRestrictionsContainer
                        onClose={dialogs.clear}
                        onSuccess={onSuccess}
                        requirementGroups={requirementGroups}
                        requirementType={form.values} />)}
                {dialogs.render(
                    'edit-dependencies',
                    {
                        title: t('Edit requirement dependencies') + ': ' + requirementType.name,
                        style: { width: '80%' }
                    }, () =>
                    <EditRequirementTypeDependenciesContainer
                        onClose={dialogs.clear}
                        onSuccess={onSuccess}
                        requirementGroups={requirementGroups}
                        requirementType={form.values}
                        selectedTargetType={selectedTargetType}
                        work={work} />)}
                {dialogs.render('edit-expression', {
                    title: t('Edit requirement expression') + ': ' + requirementType.name,
                    style: { width: '80%' }
                }, () =>
                    <EditRequirementTypeExpression
                        onClose={dialogs.clear}
                        onSuccess={onSuccess}
                        work={work}
                        requirementType={form.values}
                        saveRequirementType={saveRequirementType} />)}
                {dialogs.render('suggestions', {
                    title: t('Suggestions') + ': ' + requirementType.name,
                    style: { width: '80%' }
                }, () =>
                    <RequirementTypeSuggestions
                        requirementTypeId={requirementType.id}
                        onClose={dialogs.clear}
                        onSuccess={onSuccess}
                        workId={requirementType.workId} />)}
                {dialogs.render('configuration', {
                    title: t('Requirement configuration') + ': ' + requirementType.name,
                    style: { width: '90%' }
                }, () =>
                    <RequirementTypeConfiguration
                        requirementType={form.values}
                        requirementExpirationTypes={requirementExpirationTypes}
                        requirementValidationTypes={requirementValidationTypes}
                        saveRequirementType={saveRequirementType}
                        templates={notificationTemplates}
                        onClose={dialogs.clear}
                        onSuccess={form.setFieldValues}
                        users={users} />)}
                {dialogs.render('set-policy', {
                    title: t('Set policy') + ': ' + requirementType.name,
                }, () =>
                    <RequirementTypeSetPolicy
                        requirementType={form.values}
                        saveRequirementType={saveRequirementType}
                        onClose={dialogs.clear}
                        onSuccess={form.setFieldValues} />)}
                {dialogs.render('validationHolders', {
                    title: t('Validation holders') + ': ' + requirementType.name,
                    style: { width: '50%' }
                }, () =>
                    <RequirementTypeValidationHolders
                        onClose={dialogs.clear}
                        onSuccess={form.setFieldValues}
                        requirementType={form.values} />)}

                <div className='order-handle'
                    draggable
                    onDragStart={onDragStart}>
                    <i className='fas fa-grip-vertical'></i>
                </div>
            </td>
            <td>
                <div className='r v-center'>
                    <i
                        className={classNames('pi pointer',
                            {
                                'pi-chevron-right pointer': !expanded,
                                'pi-chevron-down pointer': expanded
                            })}
                        onClick={_ => setExpanded(e => !e)} />
                    <strong>{requirementType.id}</strong>
                    {form.input('name', { onBlur: saveChanges })}

                    <div style={{ background: requirementType.color, height: '12px', width: '12px', borderRadius: '3px' }} />
                </div>
            </td>
            <td>
                {form.input('title', { onBlur: saveChanges })}
            </td>
            <td>
                {form.input('description', { onBlur: saveChanges })}
            </td>
            <td>
                {form.select('targetType', { onBlur: saveChanges, options: requirementTargetTypes })}
            </td>
            <td>
                {form.select('expirationTypeId', { onBlur: saveChanges, options: requirementExpirationTypes })}
            </td>
            <td className='center'>
                <span className='pointer' onClick={() => dialogs.show('edit-dependencies')}><i className='fas fa-code-branch' /></span>
            </td>
            <td className='center'>
                <span className='pointer' onClick={() => dialogs.show('edit-expression')}><i className='fas fa-code' /></span>
            </td>

            <td className='center'>
                <span className='pointer' onClick={() => dialogs.show('configuration')}><i className='fas fa-cog' /></span>
            </td>
            <td>
                <SplitButton className='actions-btn' model={actions} ref={splitButton} />
            </td>
        </tr>
        {expanded && <tr key={'info'}>
            <td colSpan={2} />
            <td colSpan={8}>
                <RequirementTypeInfo
                    requirementGroups={requirementGroups}
                    requirementType={requirementType}
                    requirementValidationTypes={requirementValidationTypes} />
            </td>
        </tr>}
    </>
}

function RequirementGroupRow({
    query,
    notificationTemplates,
    requirementGroup,
    requirementGroups,
    requirementExpirationTypes,
    requirementValidationTypes,
    saveRequirementGroup,
    saveRequirementType,
    selectedTargetType,
    work,
    users
}: {
    notificationTemplates: INotificationTemplate[],
    query: IRemoteData<IRequirementGroup[]>,
    requirementGroup: IRequirementGroup,
    requirementGroups: IRequirementGroup[],
    requirementExpirationTypes: IRequirementExpirationType[],
    requirementValidationTypes: IRequirementValidationType[],
    saveRequirementGroup: Function,
    saveRequirementType: Function,
    selectedTargetType: number | undefined,
    work: IWork,
    users: IUserIdentity[]
}) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const colorRef = React.useRef<any>();
    const form = useForm<IRequirementGroup>({
        initialValues: requirementGroup
    });
    const [requirementTypes, setRequirementTypes] = React.useState<IRequirementType[]>([]);
    const [selectedRequirementType, setSelectedRequirementType] = React.useState<number | undefined>();
    const saveChanges = async (..._: any) => {
        await saveRequirementGroup(form.values.workId, form.values);
    }

    const pickColor = (..._: any) => {
        colorRef.current.click();
    }

    const actions = [
        { label: t('Add requirement'), command: dialogs.showFromEvent('add-requirement') },
        { label: t('Configure'), command: dialogs.showFromEvent('edit-requirement') }
    ];

    const refresh = () => {
        dialogs.clear();
        return query.query();
    }

    React.useEffect(() => {
        setRequirementTypes(requirementGroup.requirementTypes ?? []);
    }, [requirementGroup.requirementTypes]);

    return <>
        <tr>
            {dialogs.render('edit-requirement', { title: t('Configure'), maximized: true }, () => {
                return <EditRequirementGroup
                    data={requirementGroup}
                    onClose={dialogs.clear}
                    onSuccess={refresh}
                    saveRequirementGroup={saveRequirementGroup}
                    workId={requirementGroup.workId} />;
            })}
            {dialogs.render('add-requirement', { title: t('Add requirement') }, () => {
                return <AddRequirementTypeInput
                    group={requirementGroup}
                    onClose={() => dialogs.clear()}
                    onSuccess={refresh}
                    requirementExpirationTypes={requirementExpirationTypes}
                    requirementValidationTypes={requirementValidationTypes}
                    saveRequirementType={saveRequirementType} />
            })}
            <td colSpan={2}>
                <div className='r v-center'>
                    <i className='pi pi-chevron-down md mr-right' />
                    <span
                        className='color-circle sm mr-right pointer'
                        onClick={pickColor}
                        style={{ backgroundColor: form.values.color }} />
                    {form.input('color', { ref: colorRef, onBlur: saveChanges, type: 'color', containerClassName: 'hide' })}
                    {form.input('name', { onBlur: saveChanges })}
                </div>
            </td>
            <td>
                {form.input('title', { onBlur: saveChanges })}
            </td>
            <td>
                {form.input('description', { onBlur: saveChanges })}
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td colSpan={1}>
                <SplitButton model={actions} className='actions-btn requirement-group-btn' />
            </td>
        </tr>
        {requirementTypes
            .filter(r => r.isActive)
            .sort((a, b) => (a.orderValue ?? -100) - (b.orderValue ?? -100))
            .map(r =>
                <RequirementTypeRow
                    key={r.id}
                    notificationTemplates={notificationTemplates}
                    requestRefresh={refresh}
                    requirementType={r}
                    requirementGroup={requirementGroup}
                    requirementGroups={requirementGroups}
                    requirementExpirationTypes={requirementExpirationTypes}
                    requirementValidationTypes={requirementValidationTypes}
                    saveRequirementType={saveRequirementType}
                    selectedTargetType={selectedTargetType}
                    className={r.id == selectedRequirementType ? ' selected ' : ''}
                    requestSelected={(id: number) => setSelectedRequirementType(id)}
                    work={work}
                    users={users} />)}
    </>;
}

function RequirementGroups({
    notificationTemplates,
    query,
    requirementExpirationTypes,
    requirementValidationTypes,
    saveRequirementGroup,
    saveRequirementType,
    selectedTargetType,
    work,
    workId,
    users
}: {
    notificationTemplates: INotificationTemplate[],
    query: IRemoteData<IRequirementGroup[]>,
    requirementExpirationTypes: IRequirementExpirationType[],
    requirementValidationTypes: IRequirementValidationType[],
    saveRequirementGroup: Function,
    saveRequirementType: Function,
    selectedTargetType: number | undefined,
    work: IWork,
    workId: number,
    users: IUserIdentity[]
}) {
    const { t } = useTranslation();
    const [data, setData] = React.useState<IRequirementGroup[]>([]);

    React.useEffect(() => {
        const d = query.value ?? [];
        setData(d.sort((a, b) => (a.orderValue ?? 100) - (b.orderValue ?? 100)));
    }, [query.value]);

    return <>
        <table className='requirement-groups-table'>
            <thead>
                <tr>
                    <th colSpan={2}>{t('Name')}</th>
                    <th>{t('Title')}</th>
                    <th>{t('Description')}</th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                    <th className='td-sm' />
                </tr>
            </thead>

            <tbody>
                {data.map(r =>
                    <RequirementGroupRow
                        key={r.id}
                        query={query}
                        notificationTemplates={notificationTemplates}
                        requirementExpirationTypes={requirementExpirationTypes}
                        requirementValidationTypes={requirementValidationTypes}
                        requirementGroup={r}
                        requirementGroups={data}
                        saveRequirementGroup={saveRequirementGroup}
                        saveRequirementType={saveRequirementType}
                        selectedTargetType={selectedTargetType}
                        work={work}
                        users={users} />)}
            </tbody>
        </table>
    </>
}

export interface IProps {
    getNotificationTemplates: Function;
    getWorkRequirementGroups: Function;
    requirementExpirationTypes: IRequirementExpirationType[];
    saveWorkRequirementGroup: Function;
    saveWorkRequirementType: Function;
    work: IWork;
    users: IUserIdentity[];
}

export function AdminWorkRequirements(props: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();

    const requirementValidationTypes = props.work.requirementValidationTypes ?? [];
    const [selectedTargetType, setSelectedTargetType] = React.useState<number | undefined>();

    const query = useRemoteData<IRequirementGroup[]>(
        props.getWorkRequirementGroups, {
        parameters: [props.work.id, selectedTargetType],
    });

    const notificationTemplates = useRemoteData<INotificationTemplate[]>(
        props.getNotificationTemplates, {
        parameters: [props.work.id],
    });

    const refresh = () => {
        dialogs.clear();
        return query.query();
    }

    React.useEffect(() => {
        query.query();
    }, [selectedTargetType]);

    return <div className='c he AdminWorkRequirements'>
        <BreadcrumbItem text={t('Requirements')} />

        <WorkToolbar work={props.work}>
            {query.renderLoading()}
        </WorkToolbar>

        <div className='inner-container'>
            <div className='toolbar'>
                <div className='e' />
                <select onChange={e => setSelectedTargetType(parseInt(e.target.value))} value={selectedTargetType}>
                    <option>{t('Filter requirement types')}</option>
                    {requirementTargetTypes.map((tt, i) => <option key={i} value={tt.id}>
                        {t(tt.name)}
                    </option>)}
                </select>
                <button className='tool-btn' onClick={dialogs.showFromEvent('add-requirement-group')}>
                    <i className='pi pi-plus' />
                    {t('Add requirement group')}
                </button>
            </div>
            <div className='body'>
                {dialogs.render(
                    'add-requirement-group',
                    { title: t('Add requirement group'), maximized: true },
                    () => <AddRequirementGroupInput
                        onClose={dialogs.clear}
                        onSuccess={refresh}
                        saveRequirementGroup={props.saveWorkRequirementGroup}
                        workId={props.work.id} />)}
                <RequirementGroups
                    query={query}
                    notificationTemplates={notificationTemplates.value ?? []}
                    requirementExpirationTypes={props.requirementExpirationTypes}
                    saveRequirementGroup={props.saveWorkRequirementGroup}
                    saveRequirementType={props.saveWorkRequirementType}
                    requirementValidationTypes={requirementValidationTypes}
                    selectedTargetType={selectedTargetType}
                    work={props.work}
                    workId={props.work.id}
                    users={props.users} />
            </div>
        </div>
    </div>;
}
