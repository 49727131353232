import * as React from 'react';

import { NotificationBox } from './NotificationBox';
import { INotification } from '../../models';

export interface IProps {
    notifications: INotification[];
}

export function NotificationsBox(props: IProps) {
    return <div>
        {props.notifications.map((n, k) => <NotificationBox notification={n} key={k} />)}
    </div>
}