import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as UserStore from '@store/user';
import { ChangePassword } from '@components/user/ChangePassword';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...UserStore.plainActions,
    }),
    {
    },
)(ChangePassword as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
