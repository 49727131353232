import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as Admin from '../../../store/admin';
import * as App from '../../../store/app';

import { ManageWorkerSettings } from '@components/admin/workers/ManageWorkerSettings';

export default connect(
  (state: ApplicationState) => ({
    ...Admin.plainActions,
  }),
  Admin.actionCreators,
)(ManageWorkerSettings as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
