import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';
import * as UserActions from '../../store/user'

import { AdminWorkPermissions, IProps } from '../../components/admin/AdminWorkPermissions';

export default connect(
    (state: ApplicationState) => ({
        ...Admin.plainActions,
        ...UserActions.plainActions,
        availableRoles: state.admin.roles,
        users: state.admin.users,
        works: state.admin.works,
    }),
    Admin.actionCreators,
)(AdminWorkPermissions as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
