import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as Admin from '@store/admin';
import { UserForm } from '@components/admin/UserForm';

export default connect(
  (state: ApplicationState) => ({
      availableRoles: state.admin.roles.map(r => r.name),
      notifications: [],
  }),
  Admin.actionCreators
)(UserForm as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
