import * as React from 'react';

import {
    BreadcrumbItem,
    classNames,
    G, Panel, Portal, RequirePermission,
    Tag,
    useConfirm, useDataTable,
    useDialogs,
    useLoading,
    useMessage,
    useResolveName,
    useTranslation,
    useWorkFormSettings,
    useScheduler,
    useSearch,
    useSignalR,
    useSearchFilters,
    onChange,
    Tooltip
} from '@components';
import {
    IContractor,
    IJob,
    IJobHasMachinery,
    IMachinery,
    IMachineryType,
    IPropertyGroupType,
    IResourceStatusType,
    ISecurity,
    IUserIdentity,
    IWork,
    IWorkSettings,
    PropertyGroupObjectType,
    ResourceType,
    getMachineryName,
    IJobHasContractor,
    ModuleManager,
} from '@models';

import './ManageJobMachineries.scss';
import { ResourceStatus } from '@components/common/ResourceStatus';
import { getQueryParameter, redirectTo, strJoin } from '@utils';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import { DynamicProperties } from '@components/user/DynamicProperties';
import { ResourceStatusType, resourceStatusTypes } from '@models/resources';
import { IRequirementNotification } from '@models/requirements';
import dateUtils from '@utils/date-utils';

export interface IProps {
    activateJobHasMachinery: Function;
    contractors: IContractor[];
    contractorId?: number;
    jobHasContractorId?: number;
    relationId?: number;
    selectedJobHasContractor?: IJobHasContractor;
    getJobHasMachineries: Function;
    getJobHasMachinery: (workId: number, jobId: number, relationId: number) => Promise<IJobHasMachinery>,
    hasPermission: Function;
    job: IJob;
    jobHasMachinery: IJobHasMachinery;
    machineryTypes: IMachineryType[];
    propertyGroupTypes: IPropertyGroupType[];
    removeJobHasMachinery: Function;
    deleteJobHasMachinery: Function;
    resourceStatusTypes: IResourceStatusType[];
    saveJobHasMachinery: Function;
    searchContractors: Function;
    searchMachineries: Function;
    security: ISecurity;
    work: IWork;
    workSettings: IWorkSettings;
    appUsers: IUserIdentity[];
    moduleManager: ModuleManager;
}
function MachineryInfo({ className, machinery, machineryTypes, work, contractors }: {
    className: string,
    machinery: IMachinery | undefined,
    machineryTypes: IMachineryType[],
    contractors: IContractor[],
    work: IWork
}) {
    const { t } = useTranslation();
    const machineryTypeName = useResolveName(machineryTypes);
    const contractorName = useResolveName(contractors);

    const settings = useWorkFormSettings({ work: work, scope: 'machinery' });

    const renderLicensePlate = (m: IMachinery | undefined) => {
        if (m?.prefixLicenseNumber != null) {
            return <span className='value'>
                {strJoin('/', m.prefixLicenseNumber, m.licenseNumber, m.suffixLicenseNumber)}
            </span>
        }
        else {
            return <span className='value' />;
        }
    }

    React.useEffect(() => {
        try{
            contractorName(machinery?.contractorId)
        }
        catch(e){
            console.log(e);
        }
    }, [machinery]);

    return <div className={classNames(className, 'form-1 l200 lg pd e')}>
        {settings.show('name') &&
            <G label={t('Name')}>
                <span className='value'>{machinery?.name}</span>
            </G>}
        {settings.show('code') &&
            <G label={t('serialNumber')}>
                <span className='value'>{machinery?.code}</span>
            </G>}
        <G label={t('License plate')}>
            {renderLicensePlate(machinery)}
        </G>
        <G label={t('Description')}>
            <span className='value'>{machinery?.description}</span>
        </G>
        <G label={t('Type')}>
            <span className='value'>{machineryTypeName(machinery?.machineryTypeId)}</span>
        </G>
        <G label={t('Contractor')}>
            <span className='value'>{contractorName(machinery?.contractorId)}</span>
        </G>
    </div>
}

enum FormType {
    List = 0,
    MachineryDocumentation = 1,
}

type IJobHasMachineryR = IJobHasMachinery & {
    machineryName: string;
    machineryCode: string;
    machineryContractorName: string;
    machineryContractorId: number;

    machineryPrefixLicenseNumber?: string;
    machineryLicenseNumber?: string;
    machinerySuffixNumber?: string;
    machineryTypeFullName?: string;

    validatedCount?: number;
    totalCount?: number;
}

export function ManageJobMachineries(props: IProps) {
    const { t } = useTranslation();
    const [formType, setFormType] = React.useState<FormType>(
        props.jobHasMachinery ? FormType.MachineryDocumentation : FormType.List);
    const [selectedMachinery, setSelectedMachinery] = React.useState<IJobHasMachinery | undefined>(props.jobHasMachinery);
    const dialogs = useDialogs();
    const settings = useWorkFormSettings({ work: props.work, scope: 'machinery' });
    const [machineries, setMachineries] = React.useState<IJobHasMachineryR[]>([]);
    const [canRemoveState, setCanRemoveState] = React.useState<boolean>(false);
    const [canValidateState, setCanValidateState] = React.useState<boolean>(false);
    const [contractors, setContractors] = React.useState<IContractor[]>([]);

    const initializeContractors = async () => {
        const contractors = await props.searchContractors(props.work.id, '');
        setContractors(contractors);
    }

    React.useEffect(() => {
        initializeContractors();
    }, []);



    const loading = useLoading();
    const messages = useMessage({
        autoClear: true,
    });

    const filters = useSearchFilters({
        security: props.security,
        workId: props.work.id,
        name: 'machineries/manageJobMachineries',
        persist: true,
        references: {
            resourceStatusTypes: resourceStatusTypes,
        },
    });

    const urlJobHasContractorId: number = getQueryParameter<number>(
        window.location.search,
        'jobHasContractorId',);

    const query = useSearch<IJobHasMachineryR>({
        workId: props.work.id,
        search: 'jobs/machineries',
        normalizeKeys: true,
        lazy: true,
        filters: filters.merge({
            jobId: props.job.id,
            workId: props.work.id,
            jobHasContractorId: props.relationId ?? props.selectedJobHasContractor?.id ?? urlJobHasContractorId,
            onlyMainContractors: props.security.isGestorOrWorker() && props.jobHasContractorId == undefined
                ? true
                : undefined,
        }),
    });

    const workSettings: IWorkSettings | undefined = props.work.settingsObj;
    const HideByDefaultEnded = workSettings?.hideByDefaultEnded ?? false;

    React.useEffect(() => {
        if (HideByDefaultEnded && filters.filters.resourceStatusTypes != 3) {
            const filteredContractors = query.value.filter((c: IJobHasMachinery) => c.currentStatusType !== 3);
            setMachineries(filteredContractors);
        } else {
            setMachineries(query.value ?? []);
        }
    }, [query.value]);

    const initialize = loading.wrap(async () => {
        await query.doSearch();
    });

    const doRemove = loading.wrap(async (relation: IJobHasMachinery) => {
        const resp = await props.removeJobHasMachinery(props.work.id, props.job.id, relation.id);
        messages.set(resp, {
            successMessage: t('Relation removed successfully'),
        });

        await initialize();
    });

    const canRemove = props.security.hasPermission('jobs.machineries.remove');

    const calculateCanRemove = async () => {
        const res = await props.moduleManager.requirePermission('jobs.machineries.remove', {
            work: props.work,
            security: props.security,
        }, canRemove);

        setCanRemoveState(res ?? false);
    };

    React.useEffect(() => {
        calculateCanRemove();
    }, []);

    const calculateCanValidate = async () => {
        const res = await props.moduleManager.requirePermission('jobs.resources.validate', {
            work: props.work,
            security: props.security
        }, canValidate);

        setCanValidateState(res ?? false);
    };

    React.useEffect(() => {
        calculateCanValidate();
    }, []);

    const canValidate = props.security.hasPermission('jobs.resources.validate');

    const confirmRemove = useConfirm({
        message: t('Are you sure to finalize machinery from this job?'),
        accept: doRemove,
    });

    React.useEffect(() => {
        initialize();
    }, [props.selectedJobHasContractor]);

    const scheduler = useScheduler();

    const _ = useSignalR<IRequirementNotification>({
        method: 'RequirementNotification',
        onMessage: r => {
            scheduler.schedule(reload, 1500);
        }
    });

    const reload = () => {
        query.doSearch();
    }

    onChange(() => {
        reload();
    }, [filters.filters]);

    const doDeleteMachinery = loading.wrap(async (i: IJobHasMachinery) => {
        const resp = props.deleteJobHasMachinery(props.work.id, props.job.id, i.id);
        messages.set(resp, {
            successMessage: t('Relation removed successfully'),
        });

        await initialize();
    });

    const confirmDeleteMachinery = useConfirm({
        message: t('Are you sure to remove machinery from this job?'),
        accept: doDeleteMachinery,
    });

    const clearMachinery = (..._: any) => {
        setFormType(FormType.List);
        setSelectedMachinery(undefined);
    }

    const reactivateJobHasMachinery = loading.wrap(async (d: IJobHasMachinery) => {
        await props.activateJobHasMachinery(props.work.id, props.job.id, d.machineryId, d.id);
        await initialize();
    });

    const renderStatus = (i: IJobHasMachinery) => {
        return <ResourceStatus
            currentStatusType={i.currentStatusType!}
            resourceId={i.id}
            resourceType={ResourceType.JobHasMachinery}
            workId={props.work.id} />;
    }

    const idGenerate = () => {
        return `i${Math.random().toString(36).substring(2)}`;
    }

    const renderLicensePlate = (m: IJobHasMachinery) => {

        const id = idGenerate();
        const content = strJoin(
            '/',
            m.machineryPrefixLicenseNumber,
            m.machineryLicenseNumber,
            m.machinerySuffixLicenseNumber);
        return <div id={`${id}`}>
            <span>{content}</span>
            <Tooltip className='job_machinery_tooltip' target={`#${id}`}>{content}</Tooltip>
        </div>
    }

    const canShowDocumentation = (w: IJobHasMachineryR) =>
        props.security.isContractor()
            // && props.workSettings.mainContractorCanFulfillSubs == true
            ? true
            // : props.security.isContractor()
            // ? w.machineryContractorId == selfContractorId
            : true;

    const dataTable = useDataTable<IJobHasMachineryR>({
        tooltip: true,
        columns: [
            settings.show('name')
                ? { title: 'Name', field: 'machineryName', className: 'td-md', sortKey: 'machineryName' }
                : undefined,
            settings.show('code')
                ? { title: 'serialNumber', field: 'machineryCode', className: 'td-md', sortKey: 'machineryCode' }
                : undefined,
            {
                title: 'License plate',
                field: 'machineryLicenseNumber',
                render: renderLicensePlate,
                className: 'td-md',
                sortKey: 'machineryLicenseNumber',
            },
            {
                title: 'Machinery type',
                field: 'machineryTypeFullName',
                className: 'td-lg',
                sortKey: 'machineryTypeFullName',
            },
            {
                title: 'Contractor',
                render: d => d.machineryContractorName
                    ? <span title={d.machineryContractorName}><Tag value={d.machineryContractorName} /></span>
                    : null,
                className: 'center',
                sortKey: 'machineryContractorName',
            },
            {
                title: t('AssignmentDate'), className: 'td-md',
                render: d => dateUtils.formatFromUtc(d.startDate),
                sortKey: 'startDate',
            },
            {
                title: 'currentStatusType',
                field: 'currentStatusType',
                className: 'td-md',
                render: renderStatus,
                sortKey: 'currentStatusType'
            },
            {
                title: t('Documentation'), className: 'td-indicator',
                render: d =>
                    canShowDocumentation(d)
                        ? <div onClick={_ => loadRequirements(d.id)} className='pointer'>
                            <Tag value={`${d.validatedCount ?? 0}/${d.totalCount ?? 0}`} />
                        </div>
                        : null
            },
            {
                className: 'td-sm',
                render: s => (s.hasDelayedRevokation && props.work.jobSettings?.showResourceIncidenceIndicator)
                    ? <i className='pi pi-warning' />
                    : null,
            }
        ],
        actions: [
            (canRemoveState)
                ? {
                    icon: d => d.currentStatusType == ResourceStatusType.FINALIZED
                        ? 'fas fa-redo'
                        : 'pi pi-minus-circle',
                    tooltip: d => d.currentStatusType == ResourceStatusType.FINALIZED
                        ? t('Reactivate')
                        : t('Finalize machinery'),
                    onClick: (d, event) => d.currentStatusType == ResourceStatusType.FINALIZED
                        ? reactivateJobHasMachinery(d)
                        : confirmRemove(d, event)
                }
                : undefined,
            (props.security.isGestor())
                ? { icon: 'trash', onClick: confirmDeleteMachinery, tooltip: t('Remove machinery') }
                : undefined,
            (canValidateState && !props.security.isContractor())
                ? {
                    icon: 'pi pi-check-square',
                    tooltip: t('Force resource validation'),
                    onClick: r => dialogs.showFromEvent('force-validation', r)()
                }
                : undefined
        ],
        data: machineries,
        className: 'machineries-table',
    });

    const loadRequirements = (machineryId: number) => {
        redirectTo(`/work/${props.work.id}/jobs/${props.job.id}/machinery/${machineryId}/requirements`);
    }

    const machineryName = selectedMachinery && selectedMachinery?.machinery
        ? getMachineryName(selectedMachinery.machinery)
        : '';

    return <div className='ManageMachineries'>
        <Portal container='#breadcrumb-right'>
            <div>
                {filters.renderAsButton({ className: 'fas fa-filter pointer' })}
            </div>
        </Portal>
        <BreadcrumbItem
            text={t('Machineries')}
            onClick={() => setFormType(FormType.List)} />
        {formType == FormType.List && <div className='r h100p'>
            <div className='machineries-table'>
                {dataTable()}

                {dialogs.render('force-validation', { title: t('Force resource validation') }, (d: IJobHasMachinery) =>
                    <ForceValidationResourceContainer
                        currentStatusType={d.currentStatusType}
                        workId={props.work.id}
                        requestClose={() => {
                            dialogs.clear();
                            initialize();
                        }}
                        resourceType={ResourceType.JobHasMachinery}
                        resourceId={d.id} />
                )}
            </div>
        </div>}

        {/* VIEW JHM & PANEL */}
        {formType == FormType.MachineryDocumentation && selectedMachinery && <div className='r h100p selected-machinery'>
            <div className='c he'>
                <Portal container='#job-actions'>
                    <Panel
                        className='sub-section buttons'
                        header={<div className={'c center g-10'}>
                            <div className='r we'>
                                <i className='fas fa-truck-loading' />
                                <span className='e' />
                                <ResourceStatus currentStatusType={selectedMachinery.currentStatusType!}
                                    resourceId={selectedMachinery.id}
                                    resourceType={ResourceType.JobHasMachinery}
                                    workId={props.work.id} />
                            </div>
                            <span className='section-title'>{machineryName}</span>
                        </div>}>
                        <div className={'r r-end fwrap'}>
                            <RequirePermission permission='jobs.forceStatus'>
                                <button onClick={dialogs.showFromEvent('force-validation')}>
                                    <i className='fas fa-check-square' />
                                    <span className='e'>{t('Validate') + ' ' + t('Machinery')}</span>
                                </button>
                            </RequirePermission>
                            <button onClick={() => setFormType(FormType.MachineryDocumentation)}>
                                <i className='fas fa-table' />
                                <span className='e'>{t('View data')}</span>
                            </button>
                            <button onClick={() => loadRequirements(selectedMachinery?.id)}>
                                <i className='fas fa-folder' />
                                <span className='e'>{t('Requirements')}</span>
                            </button>
                        </div>
                    </Panel>
                </Portal>
                {dialogs.render('force-validation', { title: t('Force resource validation'), className: 'g pd' }, () =>
                    <ForceValidationResourceContainer
                        currentStatusType={selectedMachinery?.currentStatusType}
                        workId={props.work.id}
                        requestClose={dialogs.clear}
                        resourceType={ResourceType.JobHasMachinery}
                        resourceId={selectedMachinery?.id} />
                )}

                <Portal container='#user-breadcrumb'>
                    <ul>
                        <li>{machineryName}</li>
                    </ul>
                </Portal>
            </div>
            <div className='e c main'>
                <div className='form-header'>
                    {t('View machinery')}
                </div>
                <div className='r'>
                    <MachineryInfo
                        className='e'
                        machinery={selectedMachinery?.machinery}
                        machineryTypes={props.machineryTypes}
                        work={props.work}
                        contractors={contractors} />
                    <div className='sm pd-top pd-right e'>
                        <DynamicProperties
                            className='e sm mr-left'
                            object={selectedMachinery}
                            objectType={PropertyGroupObjectType.JobHasMachinery}
                            propertyGroupTypes={props.propertyGroupTypes}
                            readonly />
                    </div>
                </div>
                <div className='e' />

                <div className='sm pd r'>
                    <div className='e' />
                    <button onClick={clearMachinery}>{t('Back')}</button>
                </div>
            </div>
        </div>}
    </div>
}
