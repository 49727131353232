import * as React from 'react';

import { Avatar, ContextMenu, useDialogs, useRemoteData, useTranslation } from '@components';
import { IMachinery, IUserIdentity, IWork, IWorker } from '@models';
import { IRequirement, IRequirementStatus, IRequirementStatusType, IRequirementType, RequirementStatusTypes, RequirementTargetType } from '@models/requirements';
import AddRequirementIncidenceContainer from '@containers/requirements/AddRequirementIncidenceContainer';
import AddRequirementNoteContainer from '@containers/requirements/AddRequirementNoteContainer';
import UploadRequirement from '@containers/requirements/UploadRequirementContainer';
import { ViewRequirementHistory } from '@components/requirements/ViewRequirementHistory';

export interface IProps {
    identity: IUserIdentity;
    resourceId: number;
    targetType: number;
    targetTitle: string;
    work: IWork;
    appUsers: IUserIdentity[];
    resourcePictureRequirement: IRequirement;
    requirementStatusTypes: IRequirementStatusType[];
    requirementTypes: IRequirementType[];
    getRequirements: Function;
    getMachinery: Function;
    getWorker: Function;
    onRender?: Function;
}

export function ResourcePhoto(props: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const [resource, setResource] = React.useState<IMachinery | IWorker>();
    const [requirementStatus, setRequirementStatus] = React.useState<IRequirementStatus>();

    const initialize = async () => {
        if (props.targetType === RequirementTargetType.Machinery) {
            const machinery = await props.getMachinery(props.work.id, props.resourceId);
            setResource(machinery);
        }
        else if (props.targetType === RequirementTargetType.Worker) {
            const worker = await props.getWorker(props.work.id, props.resourceId);
            setResource(worker);
        }
        const requirement = await props.getRequirements(props.work.id, props.targetType, props.resourceId);
        const requirementStatus = requirement.find((r: IRequirement) => {
            const rt = props.requirementTypes.find(rt => rt.id == r.requirementTypeId);
            return rt?.isResourcePicture ?? false;
        })?.status;
        setRequirementStatus(requirementStatus.find((s: IRequirementStatus) => s.isCurrent));
    }

    const reRender = async () => {
        if (props.onRender) {
            await props.onRender();
        }
        initialize();
        renderPhoto();
        dialogs.clear();
    }

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        initialize();
        renderPhoto();
    }, [props.resourcePictureRequirement]);

    const photoMenu = [
        { label: t('Report incidences'), command: () => { dialogs.show('add-incidence') }, icon: 'pi pi-exclamation-triangle' },
        { label: t('Add note'), command: () => { dialogs.show('add-note') }, icon: 'pi pi-comment' },
        { label: t('Upload picture'), command: () => { dialogs.show('upload-picture') }, icon: 'pi pi-upload' },
        { label: t('View history'), command: () => { dialogs.show('view-history') }, icon: 'pi pi-clock' },
    ];

    const pm = React.useRef<any>();

    if (requirementStatus?.statusTypeId !== RequirementStatusTypes.PENDING || props.identity.roles.includes('contractor') || props.identity.roles.includes('gestor')) {
        photoMenu.splice(0, 2);
    }

    if (props.identity.policies.includes('terzi.pics')) {
        photoMenu.splice(2, 1);
    }

    if (props.identity.policies.includes('observator')) {
        photoMenu.splice(0, 3);
    }

    const renderPhoto = () => {
        if (resource !== undefined) {
            const url = (`/api/files/${props.work.id}/document/${resource.photo?.id}?version=${new Date().getTime()}`);
            if (url && (requirementStatus?.statusTypeId == 2 || requirementStatus?.statusTypeId == 3) && resource.photo?.id != 0 && resource.photo?.id != null && resource.photo?.id != undefined) {
                return <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "auto" }} onClick={ev => pm.current.show(ev)}>
                    {((requirementStatus?.statusTypeId == 2 || requirementStatus?.statusTypeId == 3) && resource.photo?.id != 0 && url != undefined) &&
                        <Avatar
                            image={url}
                            shape='circle'
                            size='xlarge'
                            style={{ width: "8em", height: "8em", marginLeft: "auto", marginRight: "auto", marginBottom: "auto" }}
                            className='pointer' />}
                </div>
            }
            else {
                return <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "auto" }} onClick={ev => pm.current.show(ev)}>
                    <Avatar
                        image='/img/default_worker_image.png'
                        shape='circle'
                        size='xlarge'
                        style={{ width: "8em", height: "8em", marginLeft: "auto", marginRight: "auto", marginBottom: "auto" }}
                        className='pointer' />
                </div>
            }
        }
        else {
            return <div>???</div>
        }
    }

    React.useEffect(() => {
        renderPhoto();
    }, [requirementStatus, resource?.photo?.id]);

    const requirementType = props
        .requirementTypes
        .find(rt => rt.id === props.resourcePictureRequirement.requirementTypeId);

    return <>
        {dialogs.render('add-incidence', { title: t('Report incidence'), className: 'g', style: { minWidth: '50vw' } }, () =>
            <AddRequirementIncidenceContainer
                onClose={dialogs.clear}
                onSuccess={reRender}
                requirement={props.resourcePictureRequirement} />)}

        {dialogs.render('add-note', { title: t('Add note'), className: 'g', style: { minWidth: '50vw' } }, () =>
            <AddRequirementNoteContainer
                onClose={dialogs.clear}
                onSuccess={reRender}
                requirement={props.resourcePictureRequirement} />)}

        {dialogs.render('upload-picture', { title: t('Upload picture') }, () =>
            <UploadRequirement
                requirement={props.resourcePictureRequirement}
                requirementType={requirementType}
                targetTitle={props.targetTitle}
                requestClose={reRender} />)}

        {dialogs.render('view-history', { title: t('View history') }, () =>
            <ViewRequirementHistory
                requirement={props.resourcePictureRequirement}
                requestClose={reRender}
                work={props.work}
                appUsers={props.appUsers}
                canDownload={false}
                requirementStatusTypes={props.requirementStatusTypes} />)}

        <ContextMenu model={photoMenu} ref={pm} />
        {renderPhoto()}
    </>
}
