import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import * as User from '../../store/user';
import { JobRequirements } from '@components/jobs/JobRequirements';

export default connect(
    (state: ApplicationState, ownProps: any) => ({
        ...state.identity,
        ...state.user,
        job: state.jobs.selectedJob,
        ...state.jobs,
        ...User.plainActions,
        ...JobsStore.plainActions,
        moduleManager: state.modules.moduleManager,
    }),
    JobsStore.actionCreators,
)(JobRequirements as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
