import * as React from 'react';
import ReportContainer from '@containers/reports/ReportContainer';
import { IReportColumn, IReportDefinition, IReportOutput, IReportOutputAction } from '@models/reports';
import { ISecurity } from '@models';
import { search, searchExportToXls } from '@store/actions/search';
import {
    useDataTable, useDialogs, useLoading, useTranslation, useSearchFilters, Portal,
    classNames,
} from '@components';
import { downloadBlob } from '@utils/file-utils';
import { interpolate } from '@utils';
import './ReportOutputTableComponent.scss';
import dateUtils from '@utils/date-utils';
import { useDispatch } from 'react-redux';
import useSynchronizedDispatch from '@components/custom/useSynchronizedDispatch';

export interface IProps {
    filtersContext: any;
    filterParameters?: any;
    report: IReportDefinition;
    output: IReportOutput;
    security: ISecurity;
    workId: number;
}

const parseColumn = (c: IReportColumn) => {
    if (!c.hidden) {
        return {
            title: c.title,
            className: classNames(c.className, 'center'),
            field: c.name,
            // render: (d: any) => d[c.name],
            delegate: c.delegate,
            sortKey: c.name,
        };
    }
}

const parseColumns = (c: IReportColumn[] | undefined) => {
    if (c) {
        return c.map(parseColumn);
    }
    else {
        return [];
    }
}

interface ISubReport {
    name: string;
    data: any;
    title?: string;
}

export function ReportOutputTableComponent(props: IProps) {
    const { t } = useTranslation();
    const [data, setData] = React.useState<any[]>([]);
    const [columns, setColumns] = React.useState<any[]>([]);
    const dialogs = useDialogs();

    const loading = useLoading();

    const report = props.report;
    const output = props.output;

    const initialize = loading.wrap(async (value: any) => {
        setColumns(parseColumns(output.columns));
        if (output.autoStart) {
            const res = await search(props.workId, output.searchName, {
                filters: value.filters ?? userFilters.filters,
            });

            if (res.hasValue) {
                res.value.map((r:any) => {
                    let array = Object.getOwnPropertyNames(r);
                    array.map(a => {
                        if (new Date(r[a]).toString() === 'Invalid Date') {
                        r[a] = t(r[a]);
                        }
                    })
                })
                setData(res.value ?? []);
            }
        }
    });

    const dispatcher = useSynchronizedDispatch(initialize);

    const parseAction = (c: IReportOutputAction) => {
        if (c.action === 'open') {
            return {
                icon: c.icon,
                title: c.title,
                onClick: (r: any) => {
                    const url = interpolate(c.parameter ?? '', r);
                    window.open(url, '_blank', 'location=no,status=no');
                }
            }
        }
        else if (c.action === 'report' || c.action === 'sublist') {
            return {
                icon: c.icon,
                tooltip: c.title,
                onClick: (r: any) => {
                    dialogs.show('sublist', {
                        name: c.parameter,
                        data: r,
                        title: c.title,
                    });
                }
            }
        }
        else {
            return undefined;
        }
    }

    const parseActions = (c: IReportOutputAction[] | undefined) => {
        if (c) {
            return c.map(parseAction).filter(t => t);
        }
        else {
            return [];
        }
    }

    const userFilters = useSearchFilters({
        security: props.security,
        workId: props.workId,
        name: output.searchName,
        references: props.filtersContext,
        persist: true,
        awaitForApply: true,
    });

    React.useEffect(() => {
        dispatcher.append({
            report: props.report,
            parameters: props.filterParameters,
            filters: userFilters.filters,
        });
    }, [props.report, props.filterParameters, userFilters.filters]);

    const actions = parseActions(props.output.actions);

    const dataTable = useDataTable({
        actions: actions,
        columns: columns,
        data: data,
        scrollHeight: '100%',
        scrollable: true,
        className: `ReportOutputTableComponent ${props.report.className}`,
        tooltip: true,
        resizableColumns: true,
        lazy: true,
        paginator: true,
        rows: 100,
        rowsPerPageOptions: [100, 200, 300]
    });

    const exportToXls = async (filters: any, filename: string | undefined = undefined) => {
        const resp = await searchExportToXls(props.workId, output.searchName, {
            filters: filters,
        });
        downloadBlob(resp, filename ?? (report.title + '.xlsx') ?? 'export.xlsx');
    }

    return <div className={'c he e'}>
        {loading.renderBox()}
        <Portal container='#breadcrumb-right'>
            <div className='r g-20' style={{ alignItems: 'center' }}>
                {output.canExport && <span className='exporter'>
                    <img
                        src='img/icons/excel-export-download.png'
                        className='pointer'
                        title={t('paginator.export')}
                        onClick={() => exportToXls(userFilters.filters)}
                        style={{ height: '23px', marginTop: '3px' }} />
                </span>}
                {userFilters.renderAsButton({ className: 'fas fa-filter pointer' })}
            </div>
        </Portal>

        {dataTable()}

        {dialogs.render('sublist', {}, (data: ISubReport) =>
            <div style={{ width: '90vw', height: '90vh' }}>
                <div className='r md pd subreport-header'>
                    <span className='e'>{data.title}</span>
                    <i className='fa fa-times pointer' onClick={_ => dialogs.clear()} />
                </div>
                <ReportContainer
                    filterParameters={data.data}
                    workId={props.workId}
                    reportName={data.name} />
            </div>)}
    </div>
}
