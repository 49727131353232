import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as MachineriesStore from '../../store/machineries';
import { ViewMachineries } from '@components/machineries/ViewMachineries';
import { isMachineryPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...MachineriesStore.plainActions,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isMachineryPropertyGroupType),
        modules: state.modules.moduleManager,
    }),
    MachineriesStore.actionCreators,
)(ViewMachineries as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any