import * as React from 'react';

import { classNames } from 'primereact/utils';

export interface IProps {
    title: string;
    className?: string;
    containerClassName?: string;
    children: any;

    collapsible?: boolean;
    closed?: boolean;
}

export function FieldsBox({collapsible, children, className, closed, title, containerClassName}: IProps) {
    const [isDown, setIsDown] = React.useState<boolean>(!closed);
    const toggle = () => setIsDown(b => !b);

    return <div className={classNames('fields-box', className)}>
        <div className={classNames('title', {'pointer': collapsible})} onClick={toggle}>
            {collapsible && <i
                className={classNames(
                    'pi',
                    {'pi-angle-down': isDown, 'pi-angle-right': !isDown})}></i>}
            {title}
        </div>
        <div className={containerClassName ?? 'c sm pd g-10'}>
            {(!collapsible || isDown) && children}
        </div>
    </div>
}
