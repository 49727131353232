import * as React from 'react';

import {
    ConfirmDialog, Message, useDataTable, useFormDialog, useLoading, useTranslation
} from '@components';
import { IWorkFunction, IWorker } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { WorkFunctionForm } from './WorkFunctionForm';
import { ManageManyToManyRelations } from '../common/ManageManyToManyRelations';

export interface IProps {
    workId: number;
}

export function AdminWorkFunction(props: IProps){
    const { t } = useTranslation();

    const [error, setError] = React.useState<string|undefined>();
    const [workFunctions, setWorkFunctions] = React.useState<IWorkFunction[]>([]);
    const loading = useLoading(true);

    const formDialog = useFormDialog({
        editTitle: t('Edit work function'),
        addTitle: t('Add work function'),
    });
    // workers
    const workersDialog = useFormDialog({
        editTitle:t('Work function workers')
    });

    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IWorkFunction|undefined>();

    const initialize = loading.wrap(async () => {
        const res = await Actions.getWorkFunctions(props.workId);

        setWorkFunctions(res.data.work.work_functions);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const saveWorkFunction = loading.wrap(async (w: IWorkFunction) => {
        await Actions.saveWorkFunction(w);
        await initialize();

        formDialog.clear();
    });

    const removeWorkFunction = loading.wrap(async (id: number, workId: number) => {
        setError(undefined);

        workId = props.workId;
        await Actions.removeWorkFunction(id, workId);
        await initialize();

        formDialog.clear();
    });

    const dataTable = useDataTable({
        data: workFunctions,
        className: 'e',
        loading,
        columns: [{field:'id', className:'td-sm'}, 'name'],
        actions: [
            {icon: 'sitemap', tooltip: t('Edit'), onClick: workersDialog.showEdit},
            {icon: 'pencil',  tooltip: t('Edit'), onClick: formDialog.showEdit},
            {icon: 'trash',   tooltip: t('Delete'), onClick: setShowConfirmRemove}
        ]
    });

    // add Worker
    const addWorkFunctionHasWorker = async (worker: IWorker, workFunction: IWorkFunction) => {
        loading.start('workers');

        const resp = await Actions.saveWorkFunctionHasWorker(props.workId, workFunction.id, worker.id);
        if (resp.hasValue) {
            workersDialog.clear();
        }
        else{
            workersDialog.setEditError(
                t('Cannot add worker relation'));
        }

        loading.stop();
    }

    // remove Worker
    const removeWorkFunctionHasWorker = async (worker: IWorker, workFunction: IWorkFunction) => {
        loading.start('workers');

        const resp = await Actions.removeWorkFunctionHasWorker(props.workId, workFunction.id, worker.id);
        if (!resp.hasValue){
            workersDialog.setEditError(
                t('Cannot remove worker relation'));
        }

        loading.stop();
    }

    return <div className='he c'>
        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeWorkFunction(showConfirmRemove.id, showConfirmRemove.workId)} />}

        {workersDialog.render((workFunction: IWorkFunction, {error}: {error:string}) =>
            <ManageManyToManyRelations
                error={error}
                source={workFunction}
                columns={[
                    'name'
                ]}
                onAdd={(d:IWorker) => addWorkFunctionHasWorker(d, workFunction)}
                onRemove={(d: IWorker) => removeWorkFunctionHasWorker(d, workFunction)}
                loadTargets={async () => {
                    const resp = await Actions.getWorkers(props.workId);
                    return resp.data.work.workers;
                }}
                loadRelations={() => Actions.getWorkFunctionWorkers(props.workId, workFunction.id)} />)}

        {formDialog.render((data: IWorkFunction) =>
            <WorkFunctionForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveWorkFunction}
                footer={formDialog.Footer()} />)}

        {dataTable()}

        <div className='r sm pd footer'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />
            <button className=''
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add')}
            </button>
        </div>
    </div>
}