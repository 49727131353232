import * as React from 'react';

import { classNames, useDialogs, useLoading, useMemoized, useTranslation } from '@components';
import { flatten } from '@utils';
import { DocumentViewer } from '@components/viewers/DocumentViewer';
import { IOption, IUserIdentity } from '@models';
import {
    IRequirement, IRequirementGroup, IRequirementIncidence, IRequirementType,
    IRequirementValidationData, RequirementStatusTypes
} from '@models/requirements';
import { unique } from '@utils';
import { RequirementDocumentInfo } from './RequirementDocumentInfo';
import './ValidateAllRequirements.scss';
import { AcceptRequirement } from './AcceptRequirement';
import RejectRequirementContainer from '@containers/requirements/RejectRequirementContainer';
import { requirementCanBeValidated } from '@store/actions/requirements';

type ValidateT = (workId: number, requirementId: number, validate: boolean, data: IRequirementValidationData, fileResourceId?: string | undefined,
    fileResourceName?: string | undefined) => Promise<IOption<boolean>>;

type saveRequirementIncidenceT = (
    workId: number,
    data: IRequirementIncidence,
    fileResourceId: string | undefined,
    fileResourceName: string | undefined) => Promise<IOption<number>>;

type GetDocumentT = (workId: number, documentId: number) => Promise<IOption<string>>;

export interface IProps {
    getDocumentInfo: GetDocumentT;
    requirementJobs?: any;
    requestCancel: Function;
    requirements: IRequirement[];
    requirementTypes: IRequirementType[];
    requirementGroups: IRequirementGroup[];
    selectedRequirementId?: number;
    validateRequirement: ValidateT;
    loadMessages: Function;
    user: IUserIdentity;
    workId: number;
}

function RequirementDocumentsImpl({ requirement }: { requirement: IRequirement }) {
    const { t } = useTranslation();

    const selectedDocument = requirement.documents?.[0];
    var lastVersion = selectedDocument?.document.versions!.sort((a, b) => b.id - a.id)[0];

    if (selectedDocument) {
        const documentUrl = `/api/files/${requirement.workId}/document/${selectedDocument.document.id}/version/${lastVersion?.id}`;
        return <DocumentViewer
            className='document-viewer'
            url={documentUrl}
            mimeType={selectedDocument.document.mimeType} />
    }
    else {
        return <div>{t('No documents')}</div>
    }
}

const RequirementDocuments = React.memo(
    RequirementDocumentsImpl,
    (prev, next) => prev.requirement.id == next.requirement.id);

export function ValidateAllRequirements(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();

    const [pendingRequirements, setPendingRequirements] = React.useState<IRequirement[]>([]);
    const [selectedRequirement, setSelectedRequirement] = React.useState<IRequirement | undefined>();
    const [validatedRequirements, setValidatedRequirements] = React.useState<number[]>([]);
    const [rejectedRequirements, setRejectedRequirements] = React.useState<number[]>([]);

    const dialogs = useDialogs();

    React.useEffect(() => {
        if (selectedRequirement === undefined) {
            const r = props.requirements.find(r => r.id == props.selectedRequirementId);
            setSelectedRequirement(r);
        }
    }, [props.selectedRequirementId, props.requirements]);

    const resolveRequirementType = useMemoized((id: number) => {
        const allRequirementTypes = flatten(props.requirementGroups.map(g => g.requirementTypes ?? []));
        return allRequirementTypes.find(r => r.id == id);
    });

    const requirementIsPending = (r: IRequirement) => {
        const state = r.status.find(s => s.isCurrent);

        return state?.statusTypeId == RequirementStatusTypes.PENDING
            && !validatedRequirements.includes(r.id)
            && !rejectedRequirements.includes(r.id);
    };

    React.useEffect(() => {
        const pendingRequirements = unique(
            props.requirements.filter(requirementIsPending))
            .filter(r => validatedRequirements.indexOf(r.id) < 0
                && rejectedRequirements.indexOf(r.id) < 0);
        const a = pendingRequirements
            .map(r => ({ requirement: r, requirementType: resolveRequirementType(r.requirementTypeId) }));
        const b = a.sort((x, y) =>
            x.requirementType?.resourceType == y.requirementType?.resourceType
                ? y.requirementType?.orderValue - x.requirementType?.orderValue
                : x.requirementType?.resourceType - y.requirementType?.resourceType);

        setPendingRequirements(b.map(x => x.requirement));

        const selected = props.requirements.find(r => r.id == props.selectedRequirementId);

        if (pendingRequirements.length > 0 && selected === undefined && selectedRequirement === undefined) {
            setSelectedRequirement(pendingRequirements[0]);
        }
    }, [props.requirements, validatedRequirements]);


    const reject = (req: IRequirement) => {
        const rt = resolveRequirementType(req.requirementTypeId);
        dialogs.show('reject', [req, rt]);
    }
    const validate = (req: IRequirement) => {
        const rt = resolveRequirementType(req.requirementTypeId);
        dialogs.show('validate', [req, rt]);
    }

    const findRequirementIndex = (requirements: IRequirement[], req: IRequirement) => {
        for (let i = 0; i < requirements.length; i++) {
            if (req.id == requirements[i].id) {
                return i;
            }
        }

        return -1;
    }

    const doValidate = loading.wrap(async (req: IRequirement, data: IRequirementValidationData) => {
        const i = findRequirementIndex(pendingRequirements, req);
        const _ = await props.validateRequirement(req.workId, req.id, true, data);

        const nextIndex = i >= pendingRequirements.length - 1
            ? 0
            : i + 1;
        const nextRequirement = nextIndex < pendingRequirements.length
            ? pendingRequirements[nextIndex]
            : undefined;
        /*
         *         console.log('VALIDATE: ', pendingRequirements, req);
         *         console.log('VALIDATE REQUIREMENT: CURRENT INDEX', i, nextIndex, nextRequirement);
         *  */
        setValidatedRequirements(r => r.concat([req.id]));
        setSelectedRequirement(nextRequirement?.id == req.id ? undefined : nextRequirement);

        // props.loadMessages();

        dialogs.clear();
    });

    const onRejected = (req: IRequirement) => {
        const i = findRequirementIndex(pendingRequirements, req);
        const nextIndex = i >= pendingRequirements.length - 1
            ? 0
            : i + 1;
        const nextRequirement = nextIndex < pendingRequirements.length
            ? pendingRequirements[nextIndex]
            : undefined;
        /* console.log('REJECT: ', pendingRequirements, req);
         * console.log('REJECT: CURRENT INDEX', i, nextIndex, nextRequirement);
         */
        setRejectedRequirements(r => r.concat([req.id]));
        setSelectedRequirement(nextRequirement?.id == req.id ? undefined : nextRequirement);

        // props.loadMessages();

        dialogs.clear();
    }

    const allValidated = pendingRequirements.length === 0;
    const canNotValidate =
        selectedRequirement == undefined
        || loading.isLoading()
        || validatedRequirements.includes(selectedRequirement.id)
        || rejectedRequirements.includes(selectedRequirement.id);

    const total = pendingRequirements.length;
    const currentIndex = selectedRequirement ? pendingRequirements.indexOf(selectedRequirement) : 0;

    const canGoForward = currentIndex < total;
    const canGoBackward = currentIndex > 0;

    const goBackward = () => {
        const r = currentIndex - 1;
        if (r >= 0) {
            setSelectedRequirement(pendingRequirements[r]);
        }
    }

    const goForward = () => {
        const r = currentIndex + 1;
        if (r < pendingRequirements.length) {
            setSelectedRequirement(pendingRequirements[r]);
        }
    }

    return <div className='ValidateAllRequirements'>
        {dialogs.render('reject', { title: t('Reject'), className: 'g pd', maskClassName: 'z-10002' }, ([req, rt]: [IRequirement, IRequirementType]) =>
            <RejectRequirementContainer
                loading={loading.isLoading()}
                requestClose={dialogs.clear}
                onRejected={onRejected}
                requirement={req}
                requirementType={rt}
                requirementCanBeValidated={requirementCanBeValidated}
                user={props.user} />)}

        {dialogs.render('validate', { title: t('Validate'), className: 'g pd', maskClassName: 'z-10002' }, ([req, rt]: [IRequirement, IRequirementType]) =>
            <AcceptRequirement
                loading={loading.isLoading()}
                requestClose={dialogs.clear}
                requestAccept={doValidate}
                requirement={req}
                requirementType={rt}
                workId={props.workId}
                requirementCanBeValidated={requirementCanBeValidated}
                user={props.user}/>)}

        {selectedRequirement && <div className='title'>
            {resolveRequirementType(selectedRequirement.requirementTypeId)?.title}
        </div>}
        <div className='r fx-1'>
            <div className='list'>
                <span className='pending-requirements-title'>{pendingRequirements.length} {t('requirements.pending')}</span>
                <div className='pending-requirements-container'>
                    {pendingRequirements.map((p, i) =>
                        <div
                            key={i}
                            className={classNames('row',
                                {
                                    selected: selectedRequirement?.id === p.id,
                                    validated: validatedRequirements.includes(p.id),
                                    rejected: rejectedRequirements.includes(p.id),
                                })}
                            onClick={() => setSelectedRequirement(p)}>
                            {(p as any).__resourceName && <span className='resource-name'>{(p as any).__resourceName}</span>}
                            {validatedRequirements.includes(p.id) &&
                                <i className='pi pi-check-circle sm mr-right' />}
                            {rejectedRequirements.includes(p.id) &&
                                <i className='pi pi-exclamation-circle sm mr-right' />}
                            {resolveRequirementType(p.requirementTypeId)?.title ?? p.title}
                        </div>)}
                </div>
                <span className='e'></span>
                {selectedRequirement
                    && selectedRequirement?.documents
                    && <RequirementDocumentInfo
                        requirement={selectedRequirement}
                        jobId={props.requirementJobs?.[selectedRequirement.id]}
                        document={selectedRequirement.documents[0]} />}
            </div>
            <div className='requirement'>
                <div className='body'>
                    {allValidated && !loading.isLoading() &&
                        <div className='all-done'>
                            {t('requirements.all.validated')}
                            <button onClick={() => props.requestCancel()}>{t('Close')}</button>
                        </div>}
                    {selectedRequirement &&
                        <RequirementDocuments
                            key={selectedRequirement.id}
                            requirement={selectedRequirement} />}
                </div>

                <div className='footer r'>
                    <div id='validate-requirements-left'></div>
                    <span className='e'></span>
                    {loading.render()}
                    <div className='button-group'>
                        <button
                            className='btn btn-transparent'
                            disabled={!canGoBackward}
                            onClick={goBackward}
                            title={t('requirements.previous')}>
                            <i className='fas fa-arrow-left' />
                        </button>
                        <button
                            className='btn btn-transparent'
                            disabled={!canGoForward}
                            onClick={goForward}
                            title={t('requirements.next')}>
                            <i className='fas fa-arrow-right' />
                        </button>
                    </div>
                    {props.requestCancel && <button onClick={() => props.requestCancel()}>
                        {t('Cancel')}
                    </button>}
                    {!allValidated && !canNotValidate && <>
                        <button
                            className='danger'
                            disabled={canNotValidate}
                            onClick={() => reject(selectedRequirement!)}>
                            {t('Deny')}
                        </button>
                        <button
                            className='success'
                            disabled={canNotValidate}
                            onClick={() => validate(selectedRequirement!)}>
                            {t('Validate')}
                        </button>
                    </>}
                </div>
            </div>
        </div>
    </div>
}
