import { redirectTo } from '@utils';

export { redirectTo };

type IJobOpts = {
    jobHasContractorId?: number;
    parentId?: number;
    // si un contratista quiere acceder a los recursos
    // de sus subcontratistas
    relationId?: number;
}

type GoToOpts = {
    reload?: boolean;
}

const toQuery = (opts: any) => {
    return Object
        .keys(opts)
        .filter(k => opts[k] != undefined)
        .map(k => `${k}=${opts[k]}`)
        .join('&');
}

// JOB
export const goToJobs = (workId: number) =>
    redirectTo(`/work/${workId}/jobs`);

export const goToJob = (workId: number, jobId: number, opts: IJobOpts = {}) =>
    redirectTo(`/work/${workId}/jobs/${jobId}/data?${toQuery(opts)}`);

export const goToJobCard = (workId: number, jobId: number, opts: IJobOpts = {}) =>
    redirectTo(jobCardRoute(workId, jobId, opts));

export const jobCardRoute = (workId: number, jobId: number, opts: IJobOpts = {}) =>
    `/work/${workId}/jobs/${jobId}/card?${toQuery(opts)}`;

export const goToJobDocumentation = (workId: number, jobId: number, opts: IJobOpts = {}) =>
    redirectTo(`/work/${workId}/jobs/${jobId}/requirements?${toQuery(opts)}`);

export const goToJobAddContractor = (workId: number, jobId: number, parentId?: number) =>
    redirectTo(`/work/${workId}/jobs/${jobId}/add/contractor?parentId=${parentId}`);

export const goToJobCreateRequirement = (
    workId: number,
    jobId: number,
    resourceType: number | undefined = undefined,
    resourceId: number | undefined = undefined,
    statusType: number | undefined = undefined) =>
    redirectTo(jobCreateRequirementRoute(workId, jobId, resourceType, resourceId, statusType));

export const goToJobResourceCreateRequirement = (
    workId: number,
    jobId: number,
    resourceType: number | undefined = undefined,
    resourceId: number | undefined = undefined,
    statusType: number | undefined = undefined) =>
    redirectTo(jobCreateResourceRequirementRoute(workId, jobId, resourceType, resourceId, statusType));

export const goToJobContractors = (workId: number, jobId: number, loadInvitations: boolean = true) =>
    redirectTo(jobContractorsRoute(workId, jobId, loadInvitations));

export const jobContractorsRoute = (workId: number, jobId: number, loadInvitations: boolean = true) =>
    `/work/${workId}/jobs/${jobId}/contractors?loadInvitations=${loadInvitations}`;

export const goToJobSubContractors = (workId: number, jobId: number, opts: IJobOpts = {}) =>
    redirectTo(`/work/${workId}/jobs/${jobId}/contractors?${toQuery(opts)}`);

export const goToJobMachineries = (workId: number, jobId: number, opts: IJobOpts = {}) =>
    redirectTo(jobMachineriesRoute(workId, jobId, opts));

export const jobMachineriesRoute = (workId: number, jobId: number, opts: IJobOpts = {}) =>
    `/work/${workId}/jobs/${jobId}/machineries?${toQuery(opts)}`;

export const goToJobHasMachinery = (workId: number, jobId: number, jobHasMachineryId: number) =>
    redirectTo(`/work/${workId}/jobs/${jobId}/machinery/${jobHasMachineryId}`);

export const goToJobNotifyInformationLoaded = (workId: number, jobId: number) =>
    redirectTo(jobNotifyInformationLoadedRoute(workId, jobId));

export const goToJobRequirements = (workId: number, jobId: number, opts: GoToOpts = {}) =>
    redirectTo(jobRequirementsRoute(workId, jobId), opts);

export const goToCommunications = (workId: number) =>
    redirectTo(`/work/${workId}/communications`);

export const goToJobWorkers = (
    workId: number,
    jobId: number,
    opts: IJobOpts = {}) => {
    redirectTo(jobWorkersRoute(workId, jobId, opts));
};

export const jobWorkersRoute = (
    workId: number,
    jobId: number,
    opts: IJobOpts = {}) =>
    `/work/${workId}/jobs/${jobId}/workers?${toQuery(opts)}`;

export const goToJobWorker = (workId: number, jobId: number, jobHasWorkerId: number) =>
    redirectTo(routeJobHasWorker(workId, jobId, jobHasWorkerId));

export const routeJobHasWorker = (workId: number, jobId: number, jobHasWorkerId: number) =>
    `/work/${workId}/jobs/${jobId}/worker/${jobHasWorkerId}`;

export const routeJobAddContractorWorker = (workId: number, jobId: number, contractorId: number) =>
    `/work/${workId}/jobs/${jobId}/workers/add?contractor=${contractorId}`;

export const goToJobAddContractorWorker = (workId: number, jobId: number, contractorId: number) =>
    redirectTo(routeJobAddContractorWorker(workId, jobId, contractorId));

export const goToJobInformationLoaded = (workId: number, jobId: number) =>
    redirectTo(`/work/${workId}/jobs/${jobId}/information-loaded`);

export const goToJobInformationLoadedControl = (workId: number, jobId: number) =>
    redirectTo(jobInformationLoadedControlRoute(workId, jobId));

export const goToJobEditContractor = (workId: number, jobId: number, jobHasContractorId: number) =>
    redirectTo(jobHasContractorRoute(workId, jobId, jobHasContractorId));

export const goToJobEditContractorRequirements = (workId: number, jobId: number, jobHasContractorId: number) =>
    redirectTo(jobHasContractorRequirementsRoute(workId, jobId, jobHasContractorId));

export const goToJobEditMachineryRequirements = (workId: number, jobId: number, jobHasMachineryId: number) =>
    redirectTo(jobHasMachineryRequirementRoute(workId, jobId, jobHasMachineryId));

export const goToJobEditWorkerRequirements = (workId: number, jobId: number, jobHasWorkerId: number) =>
    redirectTo(jobHasWorkerRequirementsRoute(workId, jobId, jobHasWorkerId));

export const jobHasContractorRequirementsRoute = (workId: number, jobId: number, id: number) =>
    `/work/${workId}/jobs/${jobId}/jobContractor/${id}/requirements?jobHasContractorId=${id}`;

export const jobHasContractorRoute = (workId: number, jobId: number, id: number) =>
    `/work/${workId}/jobs/${jobId}/contractor/${id}`;

export const jobHasMachineryRoute = (workId: number, jobId: number, id: number) =>
    `/work/${workId}/jobs/${jobId}/machinery/${id}`;

export const jobHasMachineryRequirementRoute = (workId: number, jobId: number, id: number) =>
    `/work/${workId}/jobs/${jobId}/machinery/${id}/requirements`;

export const jobHasWorkerRoute = (workId: number, jobId: number, id: number) =>
    `/work/${workId}/jobs/${jobId}/worker/${id}`;

export const jobHasWorkerRequirementsRoute = (workId: number, jobId: number, id: number) =>
    `/work/${workId}/jobs/${jobId}/worker/${id}/requirements`;

export const jobRequirementsRoute = (workId: number, jobId: number) =>
    `/work/${workId}/jobs/${jobId}/requirements`;

export const jobCreateRequirementRoute = (
    workId: number,
    jobId: number,
    resourceType: number | undefined = undefined,
    resourceId: number | undefined = undefined,
    statusType: number | undefined = undefined,
) => {
    const args = [
        ['resourceType', resourceType],
        ['resourceId', resourceId],
        ['statusType', statusType],
    ].filter(r => r[1] != undefined).map(r => `${r[0]}=${r[1]}`).join('&');

    return `/work/${workId}/jobs/${jobId}/upload-requirement?${args}`;
}

export const jobCreateResourceRequirementRoute = (
    workId: number,
    jobId: number,
    resourceType: number | undefined = undefined,
    resourceId: number | undefined = undefined,
    statusType: number | undefined = undefined,
) => {
    const args = [
        ['resourceType', resourceType],
        ['resourceId', resourceId],
        ['statusType', statusType]
    ].filter(r => r[1] != undefined).map(r => `${r[0]}=${r[1]}`).join('&');

    return `/work/${workId}/jobs/${jobId}/create-requirement?${args}`;
}

export const jobNotifyInformationLoadedRoute = (workId: number, jobId: number) =>
    `/work/${workId}/jobs/${jobId}/notify-information-loaded`;

export const jobInformationLoadedRoute = (workId: number, jobId: number) =>
    `/work/${workId}/jobs/${jobId}/information-loaded`;

export const jobInformationLoadedControlRoute = (workId: number, jobId: number) =>
    `/work/${workId}/jobs/${jobId}/information-loaded-control`;

// machineries
export const goToMachineryRequirements = (workId: number, machineryId: number) =>
    redirectTo(machineryRequirementsRoute(workId, machineryId));

export const machineryRequirementsRoute = (workId: number, machineryId: number) =>
    `/work/${workId}/machineries/${machineryId}/requirements`;

export const goToMachineries = (workId: number) =>
    redirectTo(machineriesRoute(workId));

export const goToMachinery = (workId: number, machineryId: number) =>
    redirectTo(machineryRoute(workId, machineryId));

export const machineriesRoute = (workId: number) =>
    `/work/${workId}/machineries`;

export const machineryRoute = (workId: number, machineryId: number) =>
    `/work/${workId}/machineries/${machineryId}`;

// workers
export const goToWorkerRequirements = (workId: number, workerId: number) =>
    redirectTo(workerRequirementsRoute(workId, workerId));
export const workerRequirementsRoute = (workId: number, workerId: number) =>
    `/work/${workId}/workers/${workerId}/requirements`;

export const goToWorker = (workId: number, workerId: number) =>
    redirectTo(workerRoute(workId, workerId));
export const workerRoute = (workId: number, workerId: number) =>
    `/work/${workId}/workers/${workerId}`;

export const goToWorkers = (workId: number) =>
    redirectTo(workersRoute(workId));
export const workersRoute = (workId: number) =>
    `/work/${workId}/workers`;

// contractors
export const goToContractors = (workId: number) =>
    redirectTo(contractorsRoute(workId));
export const contractorsRoute = (workId: number) =>
    `/work/${workId}/contractors`;

export const goToContractor = (workId: number, contractorId: number) =>
    redirectTo(contractorRoute(workId, contractorId));
export const contractorRoute = (workId: number, contractorId: number) =>
    `/work/${workId}/contractors/${contractorId}`;

export const goToContractorRequirements = (workId: number, contractorId: number) =>
    redirectTo(contractorRequirementsRoute(workId, contractorId));
export const contractorRequirementsRoute = (workId: number, contractorId: number) =>
    `/work/${workId}/contractors/${contractorId}/requirements`;

export const goToContractorAccessData = (workId: number, contractorId: number) =>
    redirectTo(contractorAccessDataRoute(workId, contractorId));
export const contractorAccessDataRoute = (workId: number, contractorId: number) =>
    `/work/${workId}/contractors/${contractorId}/access-data`;

// subcontractors
export const subContractorsRoute = (workId: number) =>
    `/work/${workId}/subcontractors`;
export const goToSubContractors = (workId: number) =>
    redirectTo(subContractorsRoute(workId));

export default {
    redirectTo,
    jobContractorsRoute,
    jobHasContractorRoute,
    jobHasMachineryRoute,
    jobHasWorkerRoute,
    jobCreateRequirementRoute,
    jobInformationLoadedRoute,
    jobInformationLoadedControlRoute,
    jobRequirementsRoute,
    goToJob,
    goToJobCard,
    jobCardRoute,
    goToJobs,
    goToJobAddContractor,
    goToJobDocumentation,
    goToJobEditContractor,
    goToJobEditContractorRequirements,
    goToJobContractors,
    goToJobSubContractors,
    goToJobCreateRequirement,
    goToJobInformationLoaded,
    goToJobMachineries,
    jobMachineriesRoute,
    goToJobNotifyInformationLoaded,
    goToJobInformationLoadedControl,
    goToJobRequirements,
    goToJobWorkers,
    jobWorkersRoute,
    // machineries
    goToMachineryRequirements,
    machineryRequirementsRoute,
    goToMachinery,
    machineryRoute,
    // workers
    goToWorkerRequirements,
    workerRequirementsRoute,
    goToWorker,
    workerRoute,
    goToWorkers,
    workersRoute,
    // contractors
    goToContractors,
    contractorsRoute,
    goToContractor,
    contractorRoute,
    goToContractorRequirements,
    contractorRequirementsRoute,
    subContractorsRoute,
    goToSubContractors,
    // communications
    goToCommunications
}
