import * as React from 'react';

import { classNames, useConfirm, useTranslation } from '@components';
import { MessageIcon } from './MessageIcon';
import { IMessage, translateNotificationIcon, messageTitle } from '@models/notifications';
import { IOption, ISecurity, IWork } from '@models';
import { NotificationMessageDisplayName } from './NotificationMessageDisplayName';
import { NotificationMessageEffect } from './NotificationMessageEffect';
import * as DateUtils from '@utils/date-utils';

import './NotificationsMessages.scss';

type ClearMessageF = (id: string) => Promise<IOption<boolean>>;

export interface IProps {
    clearMessage: ClearMessageF;
    messages: IMessage[];
    requestClose: Function;
    security: ISecurity;
    work: IWork;
    workId: number;
}

function NotificationMessage({clearMessage, message, onSelect} : {clearMessage: Function, message: IMessage, onSelect: Function}) {
    const { t } = useTranslation();

    const confirm = useConfirm({
        message: t('notifications.message.close-confirm'),
        accept: () => clearMessage(message.id),
    });

    return <div className={classNames('message', message.severity, `notification-type-${message.notificationType}`)}>
        <div className='body'>
            {message.icon &&
                <i className={translateNotificationIcon(message.icon)} />}
            <MessageIcon message={message} />
            <span className='message-date'>{DateUtils.format(message.date, 'd-m-y h:i')}</span>
            <span onClick={() => onSelect(message)}>
                {messageTitle(message)}
            </span>
            <span className='e' />
            <span
                className='close-handle'
                onClick={e => confirm(message, e)}
                title={t('notifications.message.close')}>
                <i className='pi pi-times' />
            </span>
        </div>
        <NotificationMessageDisplayName message={message} />
    </div>
}

export function NotificationsMessages(props: IProps) {
    const [selectedMessage, setSelectedMessage] = React.useState<IMessage|undefined>();

    return <div className='notifications-messages-container p-messages'>
        {selectedMessage &&
            <NotificationMessageEffect
                message={selectedMessage}
                requestCancel={() => setSelectedMessage(undefined)}
                resolveMessage={props.clearMessage}
                security={props.security}
                workId={props.workId}
                work={props.work} />}

        {props.messages.map(m =>
            <NotificationMessage
                clearMessage={props.clearMessage}
                key={m.id}
                message={m}
                onSelect={setSelectedMessage} />)}
    </div>
}
