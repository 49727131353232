import * as React from 'react';

import {
    ConfirmDialog, Message, useDataTable, useFormDialog, useLoading, useTranslation
} from '@components';
import { IWorkerType } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { WorkerTypeForm } from './WorkerTypeForm';

export interface IProps {
    workId: number;
}

export function AdminWorkerType(props: IProps){
    const { t } = useTranslation();

    const [error, setError] = React.useState<string|undefined>();
    const [workerTypes, setWorkerTypes] = React.useState<IWorkerType[]>([]);
    const loading = useLoading(true);
    const formDialog = useFormDialog({
        editTitle: t('Edit worker type'),
        addTitle: t('Add worker type'),
    });
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IWorkerType|undefined>();

    const initialize = loading.wrap(async() => {
        const res = await Actions.getWorkerTypes(props.workId);

        setWorkerTypes(res.data.work.workerTypes);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const saveWorkerType = loading.wrap(async (w: IWorkerType) => {
        await Actions.saveWorkerType(w);
        await initialize();

        formDialog.clear();
    });

    const removeWorkerType = loading.wrap(async (workId: number, id: number) => {
        setError(undefined);

        workId = props.workId;
        await Actions.removeWorkerType(workId, id);
        await initialize();

        formDialog.clear();
    })

    const dataTable = useDataTable({
        data: workerTypes,
        className: 'e',
        loading,
        columns: [{field: 'id', className:'td-sm'}, 'name'],
        actions: [
            { icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit},
            { icon: 'trash', tooltip: t('Delete'), onClick: setShowConfirmRemove}
        ]
    });

    return <div className='he c'>
        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeWorkerType(showConfirmRemove.workId, showConfirmRemove.id)} />}

        {formDialog.render((data: IWorkerType) =>
            <WorkerTypeForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveWorkerType}
                footer={formDialog.Footer()} /> )}

        {dataTable()}
        <div className='r sm pd footer'>
            {error && <Message severity='error' text={error}/>}
            <span className='e'></span>
            <button className='p-button'
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right'/>
                {t('Add')}
            </button>
        </div>
    </div>
}