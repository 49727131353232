import * as React from 'react';

import {
    Select, useTranslation, useLoading, useMessage, useStringState, useNumberState, useObjectState, F, useWorkFormSettings , useSearch
} from '@components';
import {
    IJobHasMachinery, IJob, IMachinerySubType, IMachineryType,
    IPropertyGroupType, PropertyGroupObjectType, IWork, ISecurity
} from '@models';
import { DynamicProperties } from '@components/user/DynamicProperties';

import './AddJobHasMachinery.scss';

export interface IProps {
    hasPermission: Function;
    job: IJob;
    machineryTypes: IMachineryType[],
    onChange: Function;
    saveJobHasMachinery: Function;
    propertyGroupTypes: IPropertyGroupType[];
    workId: number;
    work: IWork;
    security: ISecurity;
    jobHasContractorId: number;
}

export function AddJobHasMachinery(props: IProps) {
    const { t } = useTranslation();
    const messages = useMessage({
        successMessage: t('Machinery added successfully'),
    });
    const loading = useLoading();

    const settings = useWorkFormSettings({ work: props.work, scope: 'machinery' });

    const [subTypes, setSubTypes] = React.useState<IMachinerySubType[]>([]);

    const machineName = useStringState();
    const machineCode = useStringState();
    const prefixNumber = useStringState();
    const licenseNumber = useStringState();
    const suffixLicenseNumber = useStringState();

    const selectedMachineryType = useNumberState();
    const selectedSubType = useNumberState();
    const addRecord = useObjectState({ properties: [] });

    React.useEffect(() => {
        selectedSubType.set(undefined);

        if (selectedMachineryType.value) {
            const mt = props.machineryTypes.find(mt => mt.id == selectedMachineryType.value);
            setSubTypes(mt?.subTypes ?? []);
        }
        else {
            setSubTypes([]);
        }
    }, [selectedMachineryType.value]);

    const doAdd = loading.wrap(async (..._: any) => {
        const newRecord: IJobHasMachinery = {
            ...addRecord.value,
            jobId: props.job.id,
            startDate: new Date(),
            machineryName: machineName.value,
            machineryCode: machineCode.value,
            machineryPrefixLicenseNumber: prefixNumber.value,
            machineryLicenseNumber: licenseNumber.value,
            machinerySuffixLicenseNumber: suffixLicenseNumber.value,
            machineryTypeId: selectedMachineryType.value,
            machinerySubTypeId: selectedSubType.value,
            jobHasContractorId : selectedJobHasContractor.value,
        };

        const resp = await props.saveJobHasMachinery(props.workId, props.job.id, newRecord, selectedJobHasContractor.value);
        messages.set(resp);

        if (resp.error == undefined && resp.hasValue) {
            selectedMachineryType.clear();
            selectedSubType.clear();
            machineCode.clear();
            machineName.clear();
            prefixNumber.clear();
            licenseNumber.clear();
            suffixLicenseNumber.clear();
            setTimeout(() => {
                props.onChange();
            }, 1000);
        }
    });

    const canAdd = (subTypes.length <= 0 || selectedSubType.hasValue())
        && selectedMachineryType.hasValue()
        && (machineCode.value != '')
        && (machineName.value != '')
        && (prefixNumber.value != '')
        && (licenseNumber.value != '')
        && (suffixLicenseNumber.value != '');

        type SearchR = {
            parentContractorId?: number;
            parentContractorName?: string;
            jobHasContractorId: number;
        }

        const selectedContractor = useNumberState();
        const selectedJobHasContractor = useNumberState(props.jobHasContractorId);
        const [parentContractors, setParentContractors] = React.useState<SearchR[]>([]);

        const searchContractorHasContracts = useSearch<SearchR>({
            search: 'contractorHasContracts.list',
            normalizeKeys: true,
            workId: props.work.id,
            filters: {
                contractorId: selectedContractor.value,
                jobId: props.job.id,
            }
        });

        React.useEffect(() => {
            searchContractorHasContracts.doSearch();
        }, [selectedContractor.value]);

        const contractors = [
            { id: undefined, name: t('Filter by contractor') } as any
        ].concat(props.job.contractors.map(c => ({ ...c, name: c.contractor.name })));

        React.useEffect(() => {
            if(searchContractorHasContracts.value != undefined && searchContractorHasContracts.value.length > 0){
                const parentContractors = searchContractorHasContracts.value.map((c: any) => ({
                    parentContractorId: c.parentContractorId,
                    parentContractorName: calculateLevel(c),
                    jobHasContractorId: c.jobHasContractorId,
                }));
                setParentContractors(parentContractors);
            }else{
                setParentContractors([]);
            }
        }, [searchContractorHasContracts.value]);

        const calculateLevel = (jobHasContractor : SearchR) => {
            let level = 0;
            let parentContractorId = jobHasContractor.parentContractorId;
            while (parentContractorId != undefined) {
                let parentContractor = contractors.find(c => c.id == parentContractorId);

                if (parentContractor == undefined) {
                    parentContractorId = undefined;
                } else {
                    parentContractorId = parentContractor.parentId
                    level++;
                }
            }

            level--;

            return level === -1? t('main contractor') : `${jobHasContractor.parentContractorName} ${t('level')} ${level}`;
        }

    return <div className='c g-20'>
        <div className='form-header'>
            {t('Add new machine')}
        </div>
        <div className={'c sm pd g-20 e'}>
            <Select
                options={props.machineryTypes}
                optionLabel='name'
                optionValue='id'
                placeholder={t('Select machinery type')}
                value={selectedMachineryType.value}
                onChange={selectedMachineryType.set} />

            {subTypes.length > 0 &&
                <Select
                    options={subTypes}
                    optionLabel='name'
                    optionValue='id'
                    placeholder={t('Select machinery subtype')}
                    value={selectedSubType.value}
                    onChange={selectedSubType.set} />}
            {settings.show('name') &&
                <input
                    type="text"
                    onChange={machineName.set}
                    placeholder={t('Machinery.Name')}
                    value={machineName.value} />}
            {settings.show('code') &&
                <input
                    type='text'
                    onChange={machineCode.set}
                    placeholder={t('Machinery code')}
                    value={machineCode.value} />}

            {!props.security.isContractor() && props.jobHasContractorId == undefined &&
                <Select
                    options={contractors}
                    optionLabel='name'
                    optionValue='contractorId'
                    placeholder={t('Select contractor')}
                    value={selectedContractor.value}
                    onChange={selectedContractor.set} />}

            {loading.render()}

            {props.jobHasContractorId == undefined && selectedContractor.hasValue() &&
                <Select
                    options={parentContractors}
                    optionLabel='parentContractorName'
                    optionValue='jobHasContractorId'
                    placeholder={t('Select parent contractor')}
                    value={selectedJobHasContractor.value}
                    onChange={selectedJobHasContractor.set} />
            }

            {/* license plate */}
            <F label={t('License plate')}>
                <div className='r sm pd'>
                    <input
                        type="text"
                        onChange={prefixNumber.set}
                        placeholder={t('First letter')}
                        value={prefixNumber.value}
                        className='flat-right' />
                    <input
                        type="text"
                        onChange={licenseNumber.set}
                        placeholder={t('License number')}
                        value={licenseNumber.value}
                        className='flat e' />
                    <input
                        type="text"
                        onChange={suffixLicenseNumber.set}
                        placeholder={t('Second letter')}
                        value={suffixLicenseNumber.value}
                        className='flat-left' />
                </div>
            </F>

            <DynamicProperties
                object={addRecord.value}
                onChange={addRecord.set('properties')}
                objectType={PropertyGroupObjectType.JobHasMachinery}
                propertyGroupTypes={props.propertyGroupTypes} />

            <div className='e' />

            {messages.render()}
        </div>

        <div className='r sm pd-right pd-bottom'>
            <span className='e' />

            {loading.render()}
            <button
                className='primary'
                disabled={!canAdd}
                onClick={doAdd}>
                {t('Add')}
            </button>
        </div>
    </div>
}