import * as React from 'react';
import { IReportDefinition, IReportOutput } from '@models/reports';
import { ISecurity } from '@models';
import { EChart, useLoading, useTranslation } from '@components';
import { search } from '@store/actions/search';

export interface IProps {
    report: IReportDefinition;
    output: IReportOutput;
    security: ISecurity;
    workId: number;
}

export function ReportOutputBarChartComponent(props: IProps) {
    const loading = useLoading(true);
    const { t } = useTranslation();
    const [options, setOptions] = React.useState<any>({});
    const [data, setData] = React.useState<any[]>([]);

    const initialize = loading.wrap(async () => {
        const r = await search(props.workId, props.output.searchName, {});
        const rows = r.value;
        setData(rows);

        const serieLabels = rows.map((r: any) => t(r.name));
        const serieValues = rows.map((r: any) => r.count);

        setOptions({
            aria: {
                decal: {
                    show: true
                }
            },
            tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b} : {c} ({d}%)'
                formatter: '{b} : {c}'
            },
            xAxis: {
                type: 'category',
                data: serieLabels
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: serieValues,
                    type: 'bar'
                }
            ]
        });
    });

    React.useEffect(() => {
        if (props.output.autoStart) {
            initialize();
        }
    }, [props.output]);

    return <div className={'c he e'}>
        <EChart
            options={options}
            height={400} />
    </div>
}