import * as React from 'react';

import { IRequirementType, IRequirementTypeSuggestion } from '@models/requirements';
import { IOption } from '@models';
import { useConfirm, useDataTable, useForm, useRemoteData, useTranslation, ValidationBuilder } from '@components';

type GetF = (workId: number, id: number) => Promise<IRequirementType>;
type SaveF = (workId: number, suggestion: IRequirementTypeSuggestion) => Promise<IOption<number>>;
type RemoveF = (workId: number, id: number) => Promise<IOption<boolean>>;

export interface IProps {
    getRequirementType: GetF;
    onClose: Function;
    onSuccess: Function;
    saveRequirementTypeSuggestion: SaveF;
    removeRequirementTypeSuggestion: RemoveF;
    requirementTypeId: number;
    workId: number;
}

enum FormType {
    LIST = 1,
    ADD = 2,
}

export function RequirementTypeSuggestions(props: IProps) {
    const { t } = useTranslation();

    const [formType, setFormType] = React.useState<FormType>(FormType.LIST);

    const data = useRemoteData<IRequirementType>(
        props.getRequirementType,
        {
            parameters: [props.workId, props.requirementTypeId],
        });

    const onDelete = data.loading.wrap(async (suggestion: IRequirementTypeSuggestion) => {
        await props.removeRequirementTypeSuggestion(props.workId, suggestion.id!);
        await data.query();
    });

    const confirmDelete = useConfirm({
        message: t('Are you sure to delete the element ?'),
        accept: onDelete,
    });

    const dataTable = useDataTable<IRequirementTypeSuggestion>({
        data: data.value?.suggestions ?? [],
        columns: [
            'name'
        ],
        actions: [
            {icon: 'trash', className: 'td-sm', onClick: confirmDelete }
        ]
    });

    const addForm = useForm<IRequirementTypeSuggestion>({
        initialValues: {
            name: '',
            requirementTypeId: props.requirementTypeId,
        },
        validate: d => ValidationBuilder.create(d).notEmpty('name').build(),
    });

    const onCancel = () => {
        if (formType === FormType.LIST) {
            props.onClose();
        }
        else {
            setFormType(FormType.LIST);
        }
    }

    const onSave = data.loading.wrap(async () => {
        const _ = await props.saveRequirementTypeSuggestion(props.workId, addForm.values);
        data.query();
        setFormType(FormType.LIST);
    });

    return <div className='c'>
        {data.renderLoading()}
        {formType === FormType.LIST &&
            <div className=''>
                {dataTable()}
            </div>}

        {formType === FormType.ADD &&
            <div className='sm pd'>
                {addForm.input('name', {placeholder: t('Suggestion')})}
                {addForm.errorBox()}
            </div>}

        <div className='footer r r-end'>
            <button onClick={onCancel}>{t('Cancel')}</button>
            {formType === FormType.LIST &&
                <button
                    className='primary'
                    onClick={() => setFormType(FormType.ADD)}>{t('Add')}</button>}
            {formType === FormType.ADD &&
                <button
                    className='primary'
                    disabled={addForm.isInvalid() || data.isLoading()}
                    onClick={() => onSave()}>{t('Save')}</button>}
        </div>
    </div>
}