import * as React from 'react';

import {
    useTranslation,
    useForm, G
} from '@components';
import {
    IWorker, ISecurity, IWork, ModuleManager
} from '@models';
import './RestrictWorker.scss';

interface IProps {
    security: ISecurity;
    moduleManager: ModuleManager;
    work: IWork;
    worker?: IWorker;
    restrictWorker: Function;
    handleToast: Function;
    onClose: { clearDialogs : Function, reload: Function};
}

interface IFormValues {
    workId: number;
    name: string;
    surname: string;
    code: string;
    contractorId: number;
    applicantName: number;
    applicantEmail: string;
    reason: string;
    remarks: string;
    date: string;
}

export function RestrictWorker(props: IProps) {

    const { t } = useTranslation();

    const [disabled, setDisabled] = React.useState(false);

    const form = useForm<IFormValues>({
        initialValues: {
            workId: props.work.id,
            name: props.worker?.name ?? '', //Se mantiene este campo por si el trabajador es borrado de la plataforma
            surname: props.worker?.surname ?? '', //Se mantiene este campo por si el trabajador es borrado de la plataforma
            code: props.worker?.code ?? '', //Se mantiene este campo por si el trabajador es borrado de la plataforma
            contractorId: props.worker?.contractorId ?? 0,
            applicantName: props.security.user?.id ?? 0,
            applicantEmail: props.security.user?.email ?? '',
            reason: '',
            remarks: '',
            date: new Date().toISOString().split('T')[0]
        },

        onSubmit: (data: IFormValues) => {
            handleSave();
        }
    })

    const handleSave = async () => {
        setDisabled(true);
        const resp = await props.restrictWorker(
            form.values.workId,
            props.worker?.id,
            form.values.name,
            form.values.surname,
            form.values.code,
            form.values.contractorId,
            form.values.applicantName,
            form.values.applicantEmail,
            form.values.reason,
            form.values.remarks
        );

        if (resp.restrictWorker.isError === false && typeof resp.restrictWorker.value == 'number') {
            props.handleToast(t('Worker restricted successfully'), true);
            props.onClose.reload(true);
            props.onClose.clearDialogs();
        } else {
            props.handleToast(t('Error restricting worker'), false);
            props.onClose.clearDialogs();
        }
    }



    return (
        <div className='restrict-worker-dialog'>
            <G label={t('Name')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={props.worker?.name}
                />
            </G>
            <G label={t('Surname')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={form.values.surname}
                />
            </G>
            <G label={t('workerCode')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={props.worker?.code}
                />
            </G>
            <G label={t('Contractor')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={props.worker?.contractorName}
                />
            </G>
            <G label={t('Applicant')}>
                <input
                    disabled={false}
                    className='c'
                    type='text'
                    onChange={(e) => form.values.applicantName = e.target.value}
                />
            </G>
            <G label={t('Applicant email')}>
                <input
                    disabled={false}
                    className='c'
                    type='text'
                    onChange={(e) => form.values.applicantEmail = e.target.value}
                />
            </G>
            <G label={t('Date')}>
                <input
                    disabled={false}
                    className='c'
                    type='date'
                    defaultValue={form.values.date}
                    onChange={(e) => form.values.date = e.target.value}
                />
            </G>
            <G label={t('Motive')} className='restriction-reason'>
                <textarea
                    disabled={false}
                    className='c'
                    onChange={(e) => form.values.reason = e.target.value}
                />
            </G>
            <G label={t('Remarks')} className='restriction-remarks'>
                <textarea
                    disabled={false}
                    className='c'
                    onChange={(e) => form.values.remarks = e.target.value}
                />
            </G>
            <div className='c' />
            <div className='r'>
                <span className='e' />

                <button
                    className='primary'
                    disabled={disabled}
                    onClick={form.handleSubmit}>
                    {t('Save')}
                </button>
            </div>
        </div>
    )
}