import * as RequirementActions from './actions/requirements';

export const plainActions = {
    createFreeRequirement: RequirementActions.createFreeRequirement,
    fulfillRequirement: RequirementActions.fulfillRequirement,
    getRequirement: RequirementActions.getRequirement,
    getRequirementsFromResources: RequirementActions.getRequirementsFromResources,
    getRequirements: RequirementActions.getRequirements,
    getRequirementIncidences: RequirementActions.getRequirementIncidences,
    getRequirementNotes: RequirementActions.getRequirementNotes,
    saveRequirementIncidence: RequirementActions.saveRequirementIncidence,
    saveRequirementNote: RequirementActions.saveRequirementNote,
    saveRequirementStatusNote: RequirementActions.saveRequirementStatusNote,
    validateRequirement: RequirementActions.validateRequirement,
    forceResourceStatus: RequirementActions.forceResourceStatus,
    requirementMeetsDependencies: RequirementActions.requirementMeetsDependencies,
    removeRequirementFile: RequirementActions.removeRequirementFile,
    requirementCanBeValidated: RequirementActions.requirementCanBeValidated,
    removeFreeRequirement: RequirementActions.removeFreeRequirement,
}
