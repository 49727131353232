import { get as i18n } from '../i18n';

export interface IDocument {
    id: number;
    name: string;
    description?: string;
    userId: number;
    documentTypeId?: number;
    workId: number;
    folderId?: number;
    versions?: IDocumentVersion[];

    mimeType?: string;
    size?: number;
    dateTime: Date;
    tags?: string;
    title?: string;
    hasActiveVersion: boolean;

    temporalResource?: string;

    areaDownload?: boolean;

    sortOrder?: number;
    code?: string;
    edition?: string;
    year?: number;
    uploadDateTime?: string;
    documentKind?: DocumentKind;
}

export interface IDocumentVersion {
    id: number;
    name: string;
    documentId: number;
    userId: number;
    mimeType?: string;
    size?: number;
    dateTime: Date;
}

export interface IFolder {
    id?: number;
    workId: number;
    name: string;
    description?: string;
    parentFolderId?: number;
    userId?: number;
    dateTime?: string;
    folderKind?: FolderKind;

    sortOrder?: number;
}

export interface IDocumentLog {
    id: number;
    workId: number;
    userId: number;
    documentId: number;
    documentLogTypeId: number;
    dateTime?: string;
}

export enum DocumentLogType {
    VIEW = 1,
    CREATE = 2,
    UPDATE = 3,
    CREATE_FILE = 4,
    UPDATE_FILE = 5
}

export enum DocumentKind {
    INTERNAL = 1,
    CONTRACTOR = 2,
    ALL = 3,
}

export enum FolderKind {
    INTERNAL = 1,
    CONTRACTOR = 2,
    ALL = 3,
}

export const documentKindOptions = [
    { id: DocumentKind.INTERNAL, name: i18n('document.kind.internal') },
    { id: DocumentKind.CONTRACTOR, name: i18n('document.kind.contractor') },
    { id: DocumentKind.ALL, name: i18n('document.kind.all') },
];

export const folderKindOptions = [
    { id: FolderKind.INTERNAL, name: i18n('folder.kind.internal') },
    { id: FolderKind.CONTRACTOR, name: i18n('folder.kind.contractor') },
    { id: FolderKind.ALL, name: i18n('folder.kind.all') },
];

export const documentLogTypes = [
    { id: DocumentLogType.VIEW, name: i18n('document.view') },
    { id: DocumentLogType.CREATE, name: i18n('document.logs.create') },
    { id: DocumentLogType.UPDATE, name: i18n('document.logs.update') },
    { id: DocumentLogType.CREATE_FILE, name: i18n('document.logs.create-file') },
    { id: DocumentLogType.UPDATE_FILE, name: i18n('document.logs.update-file') },
]

export const documentIcons: any = {
    'pdf': 'fas fa-file-pdf',
    'jpeg': 'fas fa-image',
    'png': 'fas fa-image',
    'jpg': 'fas fa-image',
    'rar': 'fas file-archive',
    'zip': 'fas file-archive',
    'tar': 'fas file-archive',
    'xls': 'fas file-excel',
    'xlsx': 'fas file-excel',
    '*': 'fas fa-file',
};
