import * as React from 'react';

type DispatcherF = (value: any) => void;

export default function useSynchronizedDispatch(dispatcher: DispatcherF, delay: number = 200) {
    const r = React.useRef(new SynchronizedDispatch(dispatcher, delay));

    return r.current;
}

export class SynchronizedDispatch {
    private value?: any;
    private ts: number;
    private sended: boolean;

    constructor(
        private dispatchEvent: DispatcherF,
        private delay: number = 2) {
        this.ts = new Date().getTime();
        this.value = undefined;

        this.enqueue = this.enqueue.bind(this);
        this.sended = false;
    }

    private enqueue() {
        const nts = new Date().getTime();
        if (nts - this.ts > this.delay) {
            if (this.sended) {
                return;
            }
            this.sended = true;
            this.dispatchEvent(this.value);
        }
        else {
            setTimeout(this.enqueue, this.delay);
        }
    }

    public append(value: any) {
        const ts = new Date().getTime();

        this.sended = this.sended && (JSON.stringify(value) == JSON.stringify(this.value));

        this.value = value;
        setTimeout(this.enqueue, this.delay);

        this.ts = ts;
    }
}
