import * as React from 'react';

import { IReportDefinition } from '@models/reports';
import { IContractor, IJob, IResourceStatusType, ISecurity, IWork, IWorkspace, jobStatusTypes } from '@models';
import { ReportOutputComponent } from '@components/reports/ReportOutputComponent';
import { useLoading } from '@utils/hooks';
import { getReport, getReports } from '@store/actions/reports';
import { getJobs } from '@store/actions/user';
import { ModuleManager } from '@models';

export interface IProps {
    contractors: IContractor[];
    filterParameters: any;
    reportName: string;
    report: IReportDefinition;
    security: ISecurity;
    workId: number;
    resourceStatusTypes: IResourceStatusType[];
    workspaces: IWorkspace[];
    getJobReferences: Function;
    moduleManager: ModuleManager;
    work: IWork;
}

export function ReportComponent(props: IProps) {
    const loading = useLoading(true);
    const [report, setReport] = React.useState<IReportDefinition>(props.report);
    const [jobs, setJobs] = React.useState<IJob[]>([]);

    const loadReferences = loading.wrap(async () => {
        const j = await props.getJobReferences(props.workId);
        setJobs(j.data.work.jobs.map((j: IJob) => {
            const job: Partial<IJob> = { id: j.id, name: `${j.code} - ${j.name}` };
            return job;
        }));
    });

    const initialize = loading.wrap(async () => {
        if (jobs.length == 0) {
            await loadReferences();
        }
        const r = await getReport(props.workId, props.reportName);
        setReport(r);
    });

    React.useEffect(() => {
        initialize();
    }, [props.reportName]);

    const filterStatusTypes = props.moduleManager.filterDependency('job.statusTypes', {
        security: props.security,
        work: props.work,
    });

    const jobStatuses = jobStatusTypes.filter(filterStatusTypes);

    const years = (): { name: any, id: number }[] => {
        const currentYear = new Date().getFullYear();
        return [{ name: currentYear, id: currentYear }, { name: (currentYear - 1), id: currentYear - 1 }];
    }

    return <div className={'r he'}>
        {loading.renderBox()}
        {!loading.isLoading() && (report.outputs ?? []).map((o, i) => {
            return <ReportOutputComponent
                filtersContext={{
                    contractor: props.contractors,
                    statusTypes: props.resourceStatusTypes,
                    jobs: jobs,
                    workspace: props.workspaces,
                    jobStatusTypes: jobStatuses,
                    month: [{ name: 1, id: 1 }, { name: 2, id: 2 }, { name: 3, id: 3 }, { name: 4, id: 4 }, { name: 5, id: 5 }, { name: 6, id: 6 }, { name: 7, id: 7 }, { name: 8, id: 8 }, { name: 9, id: 9 }, { name: 10, id: 10 }, { name: 11, id: 11 }, { name: 12, id: 12 }],
                    year: years(),
                }}
                filterParameters={props.filterParameters}
                key={i}
                report={report}
                output={o}
                security={props.security}
                workId={props.workId} />;
        })}
    </div>;
}
