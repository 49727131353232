import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as WorkersStore from '../../store/workers';
import * as RequirementsStore from '../../store/requirements';
import { ViewWorkers } from '../../components/workers/ViewWorkers';
import { isWorkerPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...WorkersStore.plainActions,
        ...RequirementsStore.plainActions,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isWorkerPropertyGroupType),
        moduleManager: state.modules.moduleManager,
    }),
    WorkersStore.actionCreators,
)(ViewWorkers as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any