import * as React from 'react';

import { useForm, useLoading, useMessage, useTranslation, ValidationBuilder } from '@components';
import {
    IRequirement, IRequirementNote, IRequirementStatus, IRequirementStatusNote, IRequirementValidationData,
} from '@models/requirements';
import { IOption, IUserIdentity } from '@models';

type saveRequirementNoteT = (workId: number, data: IRequirementNote) => Promise<IOption<number>>;
type saveRequirementStatusNoteT = (workId: number, data: IRequirementStatusNote) => Promise<IOption<number>>;
type validateRequirementT = (
    workId: number,
    requirementId: number,
    validate: boolean,
    validationData: IRequirementValidationData
) => Promise<IOption<number>>;

export interface IProps {
    note?: IRequirementNote;
    requirement: IRequirement;
    status?: IRequirementStatus;
    saveRequirementNote: saveRequirementNoteT;
    validateRequirement: validateRequirementT;
    saveRequirementStatusNote: saveRequirementStatusNoteT;
    onClose: Function;
    onSuccess: Function;
    user: IUserIdentity;
}

export function AddRequirementNote(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const defaultNote: Partial<IRequirementStatusNote> = {
        requirementId: props.requirement.id,
        remarks: '',
        createdById: props.user.id,
        requirementStatusId: props.status?.id
    };

    const form = useForm({
        initialValues: defaultNote,
        validateOnMount: true,
        validate: ValidationBuilder.new()
            .notEmpty('remarks')
            .lift()
    });

    const doAdd = loading.wrap(async () => {
        const res = await props.saveRequirementStatusNote(props.requirement.workId, form.values);
        /*
        *[version 0.1]*
        const res = await props.saveRequirementNote(props.requirement.workId, form.values);
        *[version 1.0]*
        const res = await props.validateRequirement(
            props.requirement.workId,
            props.requirement.id,
            true,
            {
                remarks: form.values.remarks,
                skipExpirationDate: true
            });
        */
        message.set(res);
        if (res.hasValue) {
            props.onSuccess(form.values);
        }
    });

    return <div className='c'>
        <div className='c md pd'>
            {form.textarea('remarks', {rows: 10, autoFocus: true})}
        </div>
        {form.errorBox()}
        <div className='footer r r-end'>
            {message.render()}
            <button disabled={loading.isLoading()} onClick={_ => props.onClose()}>{t('Cancel')}</button>
            <button disabled={loading.isLoading() || form.isInvalid()} onClick={doAdd} className='primary'>{t('Add')}</button>
        </div>
    </div>
}
