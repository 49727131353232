import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import * as ContractorsStrore from '../../store/contractors';
import * as RequirementsStore from '../../store/requirements';

import { JobComponent } from '../../components/jobs/JobComponent';
import { ModuleManager } from '@modules';
import * as U from '@utils';

export default connect(
    (state: ApplicationState) => ({
        work: state.user.work,
        selectedJobHasContractor: state.jobs.selectedJobHasContractor,
        selectedJobHasContractorId: U.getQueryParameter(window.location.search, 'jobContractor', { map: parseInt }),
        moduleManager: state.modules.moduleManager,
        ...state.identity,
        ...JobsStore.plainActions,
        ...ContractorsStrore.plainActions,
        ...RequirementsStore.plainActions,
    }),
    JobsStore.actionCreators,
)(JobComponent as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
