import * as React from 'react';

import { useDataTable, useRemoteData, useTranslation, useLoading } from '@components';
import { IRequirement, IRequirementNote, IRequirementStatus, IRequirementStatusNote, RequirementStatusTypes } from '@models/requirements';
import DateUtils from '@utils/date-utils';
import { flatten } from '@utils';
import dateUtils from '@utils/date-utils';

export interface IProps {
    getRequirement: Function;
    requirement: IRequirement;
    targetId: number;
    targetType: number;
}

interface IRemarkRow {
    dateTime: Date;
    remarks: string;
}

export function ViewRequirementNotes(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();

    const isCompleted = React.useRef(false);
    const data = React.useRef<IRemarkRow[]>([]);

    const initialize = loading.wrap(async () => {
        loading.start();
        isCompleted.current = false;
        const c: IRequirement = props.requirement;
        const completeNotes: IRemarkRow[] = [];
        c.status.forEach((s: IRequirementStatus) => {
            if (s.remarks != undefined && s.remarks != '' && s.statusTypeId != RequirementStatusTypes.NOT_VALIDATED) {
                 completeNotes.push({dateTime: s.dateTime, remarks: s.remarks});
            }
            if (s.notes != undefined && s.notes.length > 0) {
                completeNotes.push(...s.notes.map((n: IRequirementStatusNote) => ({dateTime: n.creationDate, remarks: n.remarks})));
            }
        });
        data.current = completeNotes.sort((a, b) => a.dateTime > b.dateTime ? -1 : 1);
        isCompleted.current = true;
        loading.stop();
    });

    const dataTable = useDataTable({
        columns: [
            { title: t('Date'), className: 'td-md', render: d => DateUtils.formatLocalDate(d.dateTime) },
            { title: t('Note'), render: d => d.remarks },
        ],
        data: data.current,
        loading: !isCompleted.current,
    });

    React.useEffect(() => {
        initialize();
    }, []);

    return <div>
        <div>
            <div>
                {loading.isLoading() && loading.render()}
                {!loading.isLoading() && dataTable()}
            </div>
            <div className='e'></div>
        </div>
    </div>
}