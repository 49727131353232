import * as React from 'react';

import { Portal, useTranslation } from '@components';
import { redirectTo } from '@utils';
import { IWork } from '@models';

export interface IProps {
    children?: any;
    work: IWork;
}

export function WorkToolbar({children, work}: IProps) {
    const { t } = useTranslation();

    return <Portal container='#breadcrumb-right'>
        <div className='md pd-right r v-center g-20'>
            {children}

            <i title={t('Events')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/events`)}
                className='pointer fas fa-code'/>

            <i title={t('Modules')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/modules`)}
                className='pointer fas fa-box'/>

            <i title={t('Jobs')}
               onClick={_ => redirectTo(`/admin/work/${work.id}/jobs`)}
               className='pointer'>J</i>

            <i title={t('Contractors')}
               onClick={_ => redirectTo(`/admin/work/${work.id}/contractors`)}
               className='pointer'>C</i>

            <i title={t('Machineries')}
               onClick={_ => redirectTo(`/admin/work/${work.id}/machineries`)}
               className='pointer'>M</i>

            <i title={t('Workers')}
               onClick={_ => redirectTo(`/admin/work/${work.id}/workers`)}
               className='pointer'>T</i>

            <i title={t('Validations')}
               className='pointer fas fa-exclamation-triangle'
               onClick={_ => redirectTo(`/admin/work/${work.id}/validations`)}></i>

            <i title={t('Notifications')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/notifications`)}
                className='pointer fas fa-comment-alt'></i>
            <i
                title={t('Users')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/users`)}
                className='pi pi-users pointer' />
            <i
                title={t('Departments')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/departments`)}
                className='pointer'>D</i>
            <i
                title={t('Requirements')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/requirements`)}
                className='pointer'>R</i>
            <i
                title={t('Configuration')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/configuration`)}
                className='pointer'>P</i>
            <i
                title={t('Permissions')}
                onClick={_ => redirectTo(`/admin/work/${work.id}/permissions`)}
                className='pi pi-lock pointer'></i>
        </div>
    </Portal>
}