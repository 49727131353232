import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as RequirementsStore from '../../store/requirements';
import { UploadRequirement } from '@components/requirements/UploadRequirement';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...RequirementsStore.plainActions,
    }),
    {},
)(UploadRequirement as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
