import * as React from 'react';

import { useForm, useLoading, useMessage, useTranslation } from '@components';
import { IJob, IJobHasContractor } from '@models';
import dateUtils from '@utils/date-utils';

export interface IProps {
    children: Function;
    job: IJob;
    jobHasContractor: IJobHasContractor;
    saveJobHasContractor: Function;
    onClose: Function;
    onSuccess: Function;
    workId: number;
    isDate: boolean;
}

export function EditJobHasContractorFields(props: IProps) {
    const loading = useLoading();
    const messages = useMessage();
    const { t } = useTranslation();

    const form = useForm<Partial<IJobHasContractor>>({
        initialValues: {
            id: props.jobHasContractor.id,
            jobId: props.job.id,
            startDate: props.jobHasContractor.startDate,
            jobsDescription: props.jobHasContractor.jobsDescription,
            contractorId: props.jobHasContractor.contractorId,
            contactName: props.jobHasContractor.contactName,
            contactSurname: props.jobHasContractor.contactSurname,
            contactEmail: props.jobHasContractor.contactEmail,
            contactPhone: props.jobHasContractor.contactPhone,
            shipmentNumber: props.jobHasContractor.shipmentNumber,
            properties: props.jobHasContractor.properties ?? [],
        },
    });

    const doSave = loading.wrap(async () => {
        const res = await props.saveJobHasContractor(props.workId, form.values);
        messages.set(res);

        if (res.hasValue) {
            props.onSuccess();
        }
    });

    var today = new Date();

    const formIsValid = () => {
        if (((dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.startDate as any)) >= 0) &&
            (dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.endDate as any)) <= 0 )) && props.isDate){
            return true;
        }
        else if (!props.isDate) {
            return true;
        }
        else {
            return false;
        }
    }

    return <div>
        <div className='c'>
            {props.children(form)}
        </div>
        {!((dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.startDate as any)) >= 0) &&
        (dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.endDate as any)) <= 0)) && props.isDate &&
            <small className='p-error'>{t('La fecha no puede ser anterior a la del día de hoy o a las fecha de inicio y fin del contrato')}</small>
        }
        {messages.renderIfError()}
        <div className='footer r r-end'>
            {loading.render()}
            <button onClick={() => props.onClose()} disabled={loading.isLoading()}>{t('Cancel')}</button>
            <button onClick={doSave} disabled={(loading.isLoading() || !formIsValid())} className='primary'>{t('Save')}</button>
        </div>
    </div>
}