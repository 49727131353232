import * as React from 'react';

export interface IProps {
    onPaste?: Function;
    children?: any;
}

export function InputPasteHandler(props: IProps) {
    const containerEl = React.useRef<any>();

    const handlePasteEvent = (event: any) => {
	const w = window as any;

	const data = (event.clipboardData || w.clipboardData).getData('text');
	if (props.onPaste) {
	    props.onPaste(data);
	}
	event.preventDefault();
    }

    React.useEffect(() => {
	if (containerEl.current) {
	    containerEl.current.addEventListener('paste', handlePasteEvent);

	    return () => containerEl.current.removeEventListener('paste', handlePasteEvent);
	}
    }, [containerEl.current]);

    return (<div ref={containerEl}>
	{props.children}
    </div>);
}
