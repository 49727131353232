import history from '../AppHistory';
import Hooks from './hooks';
import { formatLocalDate, formatMinutes, timeToMinutes } from './date-utils';
import { KeyCodes, keyIsEnter } from './key-utils';
import { interpolate, format, strConcat, strJoin, zeroPad, zeroRightPad } from './string-utils';
import { formatFileSize } from './file-utils';

const DEBUG = true;

export const __d = (...args: any[]) => {
    if (DEBUG) {
        console.log(...args);
    }
}

export const unique = (data: any[], property: string = 'id') => {
    const uniqueIndexes: any[] = [];
    const res: any[] = [];
    for (const o of data) {
        const id = o[property];
        if (!uniqueIndexes.includes(id)) {
            res.push(o);
            uniqueIndexes.push(id);
        }
    }

    return res;
}

// file utils
export { formatFileSize };

// date utils
export { formatLocalDate, formatMinutes, timeToMinutes };

// string utils
export { interpolate, format, strConcat, strJoin, zeroPad, zeroRightPad };

export const redirectToExternal = (url: string) => {
    window.location.href = url;
}

export const redirectToExternalNewTab = (url: string) => {
    window.open(url, '_blank');
}

export const goBack = () => {
    history.goBack();
}

type RedirectOpts = {
    reload?: boolean;
}

export const redirectTo = (url: string, opts: RedirectOpts = {}) => {
    if (opts?.reload ?? false) {
        redirectToExternal(url);
    }
    else {
        history.push(url);
    }
}

export const delay = (ms: number) => {
    return new Promise((resolve, _) => {
        setTimeout(resolve, ms);
    });
}

export const getGraphqlError = (res: any, op: string | undefined = undefined) => {
    if (res.errors && res.errors.length > 0) {
        return JSON.stringify(res.errors[0]);
    }
    else if (op) {
        return res.data[op].error;
    }
}

export function flatten<T>(data: T[][]): T[] {
    return data.reduce((acc, t) => acc.concat(t), []);
}

export function downloadFileFromUrl(url: string, name: string) {
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const getIntParams = (o: any) => {
    const res: any = {};
    for (const k of Object.keys(o)) {
        res[k] = parseInt(o[k]);
    }

    return res;
}

interface IQueryParameterOptions<T> {
    defaultValue?: T,
    map?: (_: string) => T | undefined,
};

export function getIntQueryParameters(url: string) {
    const parametersStr = url.startsWith('?') ? url.substr(1) : url;
    const parametersArr = parametersStr.split('&');
    const parameters: any = {};

    for (const k of parametersArr) {
        const [name, value] = k.split('=');
        const res = parseInt(value);
        if (res != undefined && !isNaN(res)) {
            parameters[name] = res;
        }
    }

    return parameters;
}

export function getQueryParameter<T>(url: string, parameterName: string, options: IQueryParameterOptions<T> = {}) {
    const parametersStr = url.startsWith('?') ? url.substr(1) : url;
    const parametersArr = parametersStr.split('&');
    const parameters: any = {};
    for (const k of parametersArr) {
        const [name, value] = k.split('=');
        parameters[name] = options.map ? options.map(value) : value;
    }

    return parameters[parameterName] ?? options.defaultValue;
}

type WithName = {
    name: string;
} | string;

export function nameOf(n: WithName | undefined) {
    return typeof n === 'string' ? n : n?.name;
}

export default {
    delay,
    downloadFileFromUrl,
    flatten,
    getIntParams,
    getQueryParameter,
    getIntQueryParameters,
    getGraphqlError,
    goBack,
    nameOf,
    redirectTo,
    redirectToExternal,
    // file utils
    formatFileSize,
    // date utils
    formatLocalDate,
    formatMinutes,
    timeToMinutes,
    // string
    format,
    interpolate,
    strConcat,
    strJoin,
    zeroPad,
    zeroRightPad,
    // hooks
    onNotification: Hooks.onNotification,
    //
    __d,
    KeyCodes,
    keyIsEnter,
    unique,
}
