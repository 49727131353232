import * as React from 'react';

import * as I18n from '../../i18n';
import { G, Select, useTranslation, InputSwitch } from '@components';
import { IPropertyGroupType } from '@models';
import { useObjectState } from '@utils/hooks';
import './PropertyGroupTypeForm.scss';

export interface IProps {
    footer: any;
    isInternal?: boolean;
    objectType: number;
    loading?: boolean;
    onChange?: Function;
    workId: number;
}

export const dispositionTypes = [
    {id: 'embed', name: I18n.get('Embed')},
    {id: 'fluid', name: I18n.get('Fluid')},
    {id: 'column', name: I18n.get('Column')},
    {id: 'grid-2', name: I18n.get('Grid')},
];

export function PropertyGroupTypeForm(props: IProps) {
    const { t } = useTranslation();

    const data = useObjectState<IPropertyGroupType>({
        name: '',
        objectType: props.objectType,
        title: '',
        isInternal: props.isInternal,
        workId: props.workId,
        containerType: 'fluid',
        hidable: false,
        mandatory: false,
        // isolated: false,
    }, {
        onChange: props.onChange,
    });

    const isValid =
        data.value.name != '' && data.value.title != '' && !props.loading;

    return <div className='c'>
        <div className='form-1 l200 sm pd'>
            <G label={t('Name')}>
                <input autoFocus type='text' value={data.field('name')} onChange={data.set('name')} />
            </G>
            <G label={t('Title')}>
                <input type='text' value={data.field('title')} onChange={data.set('title')} />
            </G>
            <G label={t('Description')}>
                <input type='text' value={data.field('description')} onChange={data.set('description')} />
            </G>
            <G label={t('Disposition type')}>
                <Select
                    options={dispositionTypes}
                    optionLabel='name'
                    optionValue='id'
                    value={data.field('containerType')}
                    onChange={data.set('containerType')} />
            </G>
            {/* <G label={t('Isolated')} className='isolated-properties'>
                <InputSwitch key={data.field('isolated')} checked={data.field('isolated')} onChange={data.set('isolated')} disabled={props.objectType != 6} />
            </G> */}
        </div>
        {props.footer({disabled: !isValid})}
    </div>
}