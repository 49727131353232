import * as React from 'react';

import {
    ButtonSet, Column, DataTable, Dialog, Loading, LoadingBox,
    onNotification, useMemoized, useTranslation,
} from '@components';
import { IConstants, ICountry, INotification, IUserIdentity, IWork, NotificationType } from '@models';
import AdminWorkUsersContainer from '@containers/admin/AdminWorkUsersContainer';
import { WorkForm, isValid } from './WorkForm';
import { redirectTo } from '@utils';

export interface IProps {
    constants: IConstants;
    countries: ICountry[];
    loading: boolean;
    loadingMessage?: string;
    loadingNotifications: INotification[];
    notifications: INotification[];
    selectWork: Function;
    saveWork: Function;
    users: IUserIdentity[];
    works: IWork[];
}

export default function AdminWorks(props: IProps) {
    const { t } = useTranslation();
    const [showEdit, setShowEdit] = React.useState<IWork | undefined>();
    const [showEditUsers, setShowEditUsers] = React.useState<IWork | undefined>();
    const [showAdd, setShowAdd] = React.useState<boolean>(false);
    const [addData, setAddData] = React.useState<IWork>({
        id: 0,
        name: '',
        users: [],
        countryId: 0,
        workspaces: [],
        workShifts: [],
        permissions: [],
        settings: "",
        legalForms: [],
    });

    const loading = props.loadingNotifications.length > 0;

    const countryName = useMemoized((i: number) =>
        props.countries.find(c => c.id == i)?.name);

    const AddFooter = () => {
        return <div className='r footer'>
            <span className='e'></span>
            <ButtonSet>
                <button
                    onClick={_ => setShowAdd(false)}>
                    {t('Cancel')}
                </button>
                <button
                    className='primary'
                    type='submit'
                    disabled={!isValid(addData)}>
                    {loading && <Loading />}
                    {t('Save')}
                </button>
            </ButtonSet>
        </div>
    }

    onNotification({ ctx: 'adminworks', type: NotificationType.SUCCESS },
        props.notifications,
        (n: INotification) => {
            if (n.action === 'edit') {
                setShowEdit(undefined);
            }
            else if (n.action === 'add') {
                setShowAdd(false);
            }
        });

    const EditFooter = () => {
        return <div className='r footer'>
            <span className='e'></span>
            <ButtonSet>
                <button
                    onClick={_ => setShowEdit(undefined)}>
                    {t('Cancel')}
                </button>
                <button
                    type='submit'
                    className='primary'
                    disabled={!isValid(showEdit!)}>
                    {loading && <Loading />}
                    {t('Save')}
                </button>
            </ButtonSet>
        </div>
    }

    const Header = () => {
        return <div className='r'>
            <span className='e'></span>
            <button
                className='tool-btn'
                disabled={loading || showAdd}
                onClick={_ => setShowAdd(true)}>
                <i className='pi pi-plus' />
                {t('Add work')}
            </button>
        </div>
    }

    const cancelEvent = (e: any | undefined) => {
        e?.preventDefault();
        e?.stopPropagation();
    }

    const configureWork = (workId: number, e: any | undefined = undefined) => {
        props.selectWork(workId, false);
        redirectTo(`/admin/work/${workId}`);
        e?.preventDefault();
        e?.stopPropagation();
    }

    return <div>
        {loading && <Loading />}
        {props.loading && <LoadingBox message={props.loadingMessage} />}

        <DataTable
            className='table'
            value={props.works}
            scrollable
            rowClassName={_ => ({ 'pointer': true})}
            rowHover
            onRowClick={e => configureWork(e.data.id)}
            header={<Header />}
        >
            <Column header={t('Id')} headerClassName='td-sm' bodyClassName='td-sm' field={'id'} />
            <Column header={t('Name')} field={'name'} />
            <Column header={t('Description')} field={'description'} />
            <Column header={t('Country')}
                body={w => countryName(w.countryId)} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={u => <i title={t('Edit')}
                    onClick={e => { setShowEdit(u); cancelEvent(e); }}
                    className='pi pi-pencil pointer' />} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={u => <i title={t('Configuration')}
                    onClick={e => configureWork(u.id, e)}
                    className='pi pi-cog pointer' />} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={u => <i title={t('Works')}
                    onClick={e => { setShowEditUsers(u); cancelEvent(e); }}
                    className='pi pi-users pointer' />} />
        </DataTable>

        {showAdd && <Dialog
            header={t('Add work')}
            visible={true}
            onHide={() => setShowAdd(false)}>
            <WorkForm
                data={addData}
                footer={<AddFooter />}
                countries={props.countries}
                notifications={props.notifications.filter(a => a.action === 'add')}
                onChange={setAddData}
                onSubmit={(data: IWork) => props.saveWork(data)} />
        </Dialog>}

        {showEdit && <Dialog
            header={t('Edit work')}
            visible={true}
            onHide={() => setShowEdit(undefined)}>
            <WorkForm
                data={showEdit}
                footer={<EditFooter />}
                countries={props.countries}
                notifications={props.notifications.filter(a => a.action === 'edit')}
                onChange={setShowEdit}
                onSubmit={(data: IWork) => props.saveWork(data)} />
        </Dialog>}

        {showEditUsers && <Dialog
            header={t('Edit work users')}
            visible
            onHide={() => setShowEditUsers(undefined)}>
            <AdminWorkUsersContainer
                onCancel={() => setShowEditUsers(undefined)}
                work={showEditUsers} />
        </Dialog>}
    </div>
}
