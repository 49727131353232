import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Identity from '../store/identity';
import * as App from '../store/app';
import { ModuleManager } from '@modules';

import * as NotificationsStore from '../store/notifications';

import NavMenu from '../components/NavMenu';


type Props =
    Identity.IdentityState;

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        user: state.identity?.user,
        menuItems: state.modules?.menuItems ?? [],
        messages: state.notifications?.messages ?? [],
        workId: state.user?.work?.id,
        notifications: state.notifications?.notifications?.filter(n => n.ctx === 'global') ?? [],
        communications: state.user?.communications ?? [],
        moduleManager: state.modules.moduleManager,
        work: state.user?.work,
    }),
    NotificationsStore.actionCreators,
)(NavMenu as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
