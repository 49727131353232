import { IJob, IUserIdentity, IWork, IWorkPermission } from "../models";
import { evalAndParse } from "./expressions";

export const isAdmin = (user: IUserIdentity | undefined) => {
    return user && user.roles.indexOf('admin') >= 0;
}

export const isNotAdmin = (user: IUserIdentity | undefined) => {
    return !(isAdmin);
}

export interface IHasPermissionOpts {
    user?: IUserIdentity;
    work?: IWork;
    job?: IJob;
    explicit?: boolean;
}

const evaluatePermission = (p: IWorkPermission, ctx: any) => {
    const res = evaluatePermissionImpl(p, ctx);
    // console.log(`evaluate permission result ${p.action}`, p, ctx, res);

    return res;
}

const evaluatePermissionImpl = (p: IWorkPermission, ctx: any) => {
    if (p.expression) {
        return evalAndParse(p.expression, ctx);
    }
    else if (p.roles) {
        return p.roles.split(',').find(r => ctx.user.roles.includes(r)) != undefined;
    }
    else if (p.policies) {
        return p.policies.split(',').find(r => ctx.user.policies.includes(r)) != undefined;
    }
    else {
        return false;
    }
}

export const hasPermission = (perm: string, opts: IHasPermissionOpts) => {
    const permissions = opts.work?.permissions ?? [];
    const perms = permissions.filter(p => p.action == perm);

    if (perms.length > 0) {
        const s = perms.find(p => evaluatePermission(p, {
            user: opts.user,
            work: opts.work,
            workId: opts.work?.id,
            jobId: opts.job?.id,
            job: opts.job,
            identity: opts.user,
        }));

        return s != undefined;
    }
    else {
        return !opts.explicit;
    }
}

export default {
    hasPermission,
    isAdmin,
    isNotAdmin,
}
