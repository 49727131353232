import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { ManageJobWorkers } from '../../components/jobs/ManageJobWorkers';

export default connect(
    (state: ApplicationState, props: any) => ({
        ...state.identity,
        ...state.user,
        ...JobsStore.plainActions,
        ...state.jobs,
        workSettings: state.user.work?.settingsObj,
        jobHasContractorId: props.jobHasContractorId ?? state.jobs.selectedJobHasContractor?.id,
        moduleManager: state.modules.moduleManager,
    }),
    JobsStore.actionCreators,
)(ManageJobWorkers as any) as any;
