import * as React from 'react';

import { AdminWorkWorkspaces } from './AdminWorkWorkspaces';
import { AdminWorkShifts } from './AdminWorkShifts';
import { Card, Dialog, Loading, onNotification, Portal, useTranslation } from '@components';
import { IConstants, ICountry, IMachineryType, INotification, IWork, NotificationType } from '@models';
import * as Actions from '@store/actions/admin';
import { WorkForm } from './WorkForm';

import './EditWork.scss';
import AdminWorkUsersContainer from '@containers/admin/AdminWorkUsersContainer';
import { AdminWorkI18NResources } from './AdminWorkI18NResources';
import { AdminActivityType } from './AdminActivityType';
import { AdminLegalForm } from './AdminLegalForm';
import { AdminWorkFunction } from './AdminWorkFunction';
import { WorkToolbar } from './WorkToolbar';
import { AdminMachineryType } from './AdminMachineryType';
import { AdminWorkerDoucmentType } from './AdminWorkerDocumentType';
import { AdminWorkerType } from './AdminWorkerType';

export interface IProps {
    constants: IConstants;
    countries: ICountry[];
    loadWorks: Function;
    machineryTypes: IMachineryType[];
    notifications: INotification[];
    work: IWork;
}

const prepareWork = (data: IWork) => {
    return {
        id: data.id,
        name: data.name,
        description: data.description,
        countryId: data.countryId,
    } as IWork;
}

export default function EditWork(props: IProps) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showEditUsers, setShowEditUsers] = React.useState<IWork|undefined>();
    const [work, setWork] = React.useState<IWork>(props.work);

    const { t } = useTranslation();

    onNotification({type: NotificationType.SUCCESS, ctx: 'adminworkusers'}, props.notifications, () => {
        setShowEditUsers(undefined);
    });

    const saveWork = async (data: IWork) => {
        setLoading(true);
        await Actions.saveWork(prepareWork(data));
        props.loadWorks();

        setLoading(false);
    }

    const WorkFormFooter = (p: any) => {
        return <div className='r sm pd footer'>
            <span className='e' />
            {loading && <Loading />}
            <button className='p-button'
                disabled={loading}>
                {t('Save')}
            </button>
        </div>
    }

    return (<div className='r g-30 md pd fwrap EditWork'>
        <WorkToolbar work={props.work} />
        <Card className='slim title f' header={t('Work data')}>
            <div style={{marginTop: '20px'}}></div>
            <WorkForm
                notifications={props.notifications}
                data={work}
                countries={props.countries}
                onChange={(d: IWork) => setWork(d)}
                onSubmit={saveWork}
                footer={<WorkFormFooter />} />
        </Card>
        <Card className='slim title f' header={t('Workspaces')}>
            <AdminWorkWorkspaces constants={props.constants} work={props.work} />
        </Card>
        <Card className='slim title f' header={t('Work shifts')}>
            <AdminWorkShifts work={props.work} />
        </Card>
        <Card className='slim title f' header={t('Machinery types')}>
            <AdminMachineryType workId={props.work.id} />
        </Card>
        {/* <Card className='slim title f' header={t('Machineries')}>
            <WorkMachineries
                machineryTypes={props.machineryTypes}
                workId={props.work.id} />
        </Card> */}
        <Card className='slim title f' header={t('Internationalization')}>
            <AdminWorkI18NResources workId={props.work.id} />
        </Card>
        <Card className='slim title f' header={t('Activity Types')}>
            <AdminActivityType workId={props.work.id} />
        </Card>
        <Card className='slim title f' header={t('Legal Forms')}>
            <AdminLegalForm workId={props.work.id} />
        </Card>
        <Card className='slim title f' header={t('Document types')}>
            <AdminWorkerDoucmentType workId={props.work.id} />
        </Card>
        <Card className='slim title f' header={t('Worker types')}>
            <AdminWorkerType workId={props.work.id} />
        </Card>
        <Card className='slim title f' header={t('Work Functions')}>
            <AdminWorkFunction workId={props.work.id} />
        </Card>
        {showEditUsers && <Dialog
            header={t('Edit work users')}
            visible
            onHide={() => setShowEditUsers(undefined)}>
            <AdminWorkUsersContainer
                onCancel={() => setShowEditUsers(undefined)}
                work={showEditUsers} />
        </Dialog>}
    </div>);
}