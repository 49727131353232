import * as React from 'react';
import { Route } from 'react-router';

import { IMachinery } from '@models';
import { DataLoader, useTranslation } from '@components';
import { MachineryRequirements } from '@components/machineries/MachineryRequirements';
import EditMachineryFormContainer from '@containers/machineries/EditMachineryFormContainer';
import { getIntParams } from '@utils';

import { getMachinery } from '@store/actions/machineries';

export interface IProps {
    children?: any;
}

const WrappedMachineryRequirements = (props: any) => {
    const { t } = useTranslation();
    const { machineryid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => getMachinery(workid, machineryid)}>
        {(machinery: IMachinery) => <div>
            <div className='center requirements-title'>
                {t('Machinery requirements')}
            </div>
            <div className='md pd r g-20'>
                <MachineryRequirements
                    machineryId={machineryid}
                    machineryName={machinery.prefixLicenseNumber + machinery.licenseNumber! + machinery.suffixLicenseNumber}
                    workId={workid} />
            </div>
        </div>}
    </DataLoader>
}

const WrappedMachineryForm = (props: any) => {
    const { machineryid, workid } = getIntParams(props.match.params);
    return <DataLoader provider={() => getMachinery(workid, machineryid)}>
        {m => <EditMachineryFormContainer
                    machinery={m}
                    machineryId={machineryid}
                    machineryName={m.prefixLicenseNumber + m.licenseNumber! + m.suffixLicenseNumber}
                    workId={workid} />}
    </DataLoader>
}

export function MachineryRoutes(props: IProps) {
    return <>
        <Route
            exact
            path='/work/:workid/machineries/:machineryid/requirements'
            component={WrappedMachineryRequirements} />
        <Route
            exact
            path='/work/:workid/machineries/:machineryid'
            component={WrappedMachineryForm} />
    </>;
}