import * as React from 'react';

import { IWork } from '@models';


interface IUseWorkFormSettings {
    work: IWork,
    scope: string
}

export const useWorkFormSettings = (props: IUseWorkFormSettings) => {
    const settings = React.useRef<any>(props.work.settingsObj ?? {});

    const hiddenFields: string[] = (settings.current[props.scope + 'HiddenFields'] as string[]) ?? [];

    const show = (field: string) => {
        return hiddenFields.includes(field) === false;
    }

    return {
        show,
    }
}