import * as React from 'react';

import {
    useTranslation,
    useSearch, useDataTable
} from '@components';


interface IProps {
    workId: number,
    removeRestrictedContractor: Function,
    handleToast: (message: string, success: boolean) => void,
}

interface IResult {
    applicantEmail: string,
    applicantName: string,
    code: string,
    contractorContactPerson: string,
    contractorEmail: string,
    contractorPhone: string,
    date: string,
    deactivatedAt?: string,
    id: number,
    isActive: boolean,
    legalFormId: number,
    legalFormName: string,
    name: string,
    providerCode: string,
    reason: string,
    remarks: string
    workId: number
}

export default function RestrictedContractors(props: IProps) {

    const { t } = useTranslation();

    const contractors = useSearch<IResult>({
        workId: props.workId,
        search: 'contractors/restricted',
        filters: {
            workId: props.workId,
        },
    });

    React.useEffect(() => {
        contractors.doSearch();
    }, []);

    const handleRemove = async (workId: number, id: number,) => {
        const resp = await props.removeRestrictedContractor(workId, id);

        if (resp.isError === false) {
            props.handleToast(t('contractor has been unrestricted'), true);
            contractors.doSearch();
        }
        else {
            props.handleToast(t('Error while unrestricting contractor'), false);
        }
    }

    const getActions = () => {
        return [
            {
                title: t('Unrestrict'),
                disabled: (row: IResult) => !row.isActive,
                icon: 'fa fa-unlock',
                onClick: (row: IResult) => {
                    handleRemove(row.workId, row.id);
                }
            }
        ];
    }

    const showDate = (date: string) => {
        return new Date(date).toLocaleDateString();
    }

    const dataTable = useDataTable({
        className: ``,
        tooltip: true,
        columns:
            [
                {
                    title: t('Date'),
                    render: d => <span title={showDate(d.date)}>{showDate(d.date)}</span>,
                    field: 'date',
                    className: 'td-md',
                    sortKey: 'date',
                },
                {
                    title: t('In effect'),
                    render: d => <span title={`${d.isActive ? t('Yes') : showDate(d.deactivatedAt!)}`}>{`${d.isActive ? t('Yes') : showDate(d.deactivatedAt!)}`}</span>,
                    field: 'isActive',
                    className: 'td-md',
                    sortKey: 'isActive',
                },
                {
                    title: t('Contractor.Name'),
                    field: 'name',
                    className: 'td-md left',
                    sortKey: 'name',
                },
                {
                    title: t('CIF'),
                    field: 'code',
                    className: 'td-md left',
                    sortKey: 'code',
                },
                {
                    title: t('Legal form'),
                    field: 'legalFormName',
                    className: 'td-md left',
                    sortKey: 'legalFormName',
                },
                {
                    title: t('Provider code'),
                    field: 'providerCode',
                    className: 'td-md left',
                    sortKey: 'providerCode',
                },
                {
                    title: t('Contact person'),
                    field: 'contractorContactPerson',
                    className: 'td-md left',
                    sortKey: 'contractorContactPerson',
                },
                {
                    title: t('contractor.invitation.email'),
                    field: 'contractorEmail',
                    className: 'td-md left',
                    sortKey: 'contractorEmail',
                },
                {
                    title: t('Applicant'),
                    field: 'applicantName',
                    className: 'td-md left',
                    sortKey: 'applicantName',
                },
                {
                    title: t('Applicant email'),
                    field: 'applicantEmail',
                    className: 'td-md left',
                    sortKey: 'applicantEmail',
                },
                {
                    title: t('Reason'),
                    field: 'reason',
                    className: 'td-md left',
                    sortKey: 'code',
                },
                {
                    title: t('Remarks'),
                    field: 'remarks',
                    className: 'td-md left',
                    sortKey: 'code',
                },
            ],
        actions: [
            {
                headerClassName: 'td-sm',
                actions: getActions()
            }
        ],
        data: contractors.value,
    });

    return (
        <div>
            {dataTable()}
        </div>
    );

}