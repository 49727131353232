import { IJob, IJobHasDepartment, ISecurity } from '@models';
import { IDocument, IDocumentVersion } from '@models/documents';
import { i18n } from '@components';

export enum RequirementTypeKind {
    Mandatory = 1,
    Optional = 2,
}

export enum RequirementTargetType {
    Contractor = 1,
    JobHasContractor = 2,
    Worker = 3,
    JobHasWorker = 4,
    Machinery = 5,
    JobHasMachinery = 6,
    JobHasDepartment = 7,
    Job = 8,
}

export enum RequirementValidationType {
    NOT_EMPTY = 1,
    SIGNED_PDF = 2,
    REQUIRE_ALL = 3,
    ITA = 4,
}

export enum RequirementStatusFlag {
    FILE_REMOVED = 1,
    EXPIRED = 2,
}

export const requirementTargetTypes = [
    { id: RequirementTargetType.Contractor, name: i18n('Contractor') },
    { id: RequirementTargetType.JobHasContractor, name: i18n('JobHasContractor') },
    { id: RequirementTargetType.Machinery, name: i18n('Machinery') },
    { id: RequirementTargetType.JobHasMachinery, name: i18n('JobHasMachinery') },
    { id: RequirementTargetType.Worker, name: i18n('Worker') },
    { id: RequirementTargetType.JobHasWorker, name: i18n('JobHasWorker') },
    { id: RequirementTargetType.JobHasDepartment, name: i18n('JobHasDepartment') },
    { id: RequirementTargetType.Job, name: i18n('Job') }
];

type IRequirementCanUploadOpts = {
    hasAccessJobInformation?: boolean;
    requirementType?: IRequirementType;
}

export function requirementCanUpload(
    r: IRequirement,
    targetType: RequirementTargetType,
    security: ISecurity,
    opts: IRequirementCanUploadOpts = {}): boolean {
    const isContractorResource = [
        RequirementTargetType.Contractor,
        RequirementTargetType.Machinery,
        RequirementTargetType.Worker,
        RequirementTargetType.JobHasContractor,
        RequirementTargetType.JobHasMachinery,
        RequirementTargetType.JobHasWorker,
    ].indexOf(targetType) >= 0;

    const isJobContractorResource = [
        RequirementTargetType.JobHasContractor,
        RequirementTargetType.JobHasMachinery,
        RequirementTargetType.JobHasWorker,
    ].indexOf(targetType) >= 0;

    const current = r.status.find(s => s.isCurrent);
    const requirementStatusRestriction =
        current?.statusTypeId == RequirementStatusTypes.NOT_LOADED
        || current?.statusTypeId == RequirementStatusTypes.NOT_VALIDATED
        || current?.statusTypeId == RequirementStatusTypes.PENDING
        || current?.statusTypeId == RequirementStatusTypes.VALIDATED; // NOTE: aunque esté validado dejamos subir un nuevo archivo

    if (security.isContractor() && isJobContractorResource && opts.requirementType?.requireJobInformationAccess) {
        return requirementStatusRestriction && (opts.hasAccessJobInformation ?? false);
    }
    else if (isContractorResource) {
        return requirementStatusRestriction && security.isContractor();
    }
    else {
        return requirementStatusRestriction && security.isWorker();
    }
}

export function requirementHaveIncidences(r: IRequirement) {
    const current = r.incidences?.find(i => i.id);
    return current?.id;
}

export function requirementHaveNotes(r: IRequirement) {
    const current = r.notes?.find(n => n.id);
    return current?.id;
}

export function requirementCanBeValidated(r: IRequirement) {
    const current = r.status.find(s => s.isCurrent);

    return current?.statusTypeId == RequirementStatusTypes.PENDING
        || current?.statusTypeId == RequirementStatusTypes.VALIDATED
        || current?.statusTypeId == RequirementStatusTypes.NOT_VALIDATED
        || current?.statusTypeId == RequirementStatusTypes.NOT_LOADED; // NOTE: No dejaba validar si estaba caducado
}

export enum RequirementStatusTypes {
    NOT_LOADED = 1,
    PENDING = 2,
    VALIDATED = 3,
    NOT_VALIDATED = 4,
}

export interface IRequirementTypeKind {
    id: number;
    name: string;
    description?: string;
}

export interface IRequirementExpirationType {
    id: number;
    name: string;
    description?: string;
}

export interface IRequirementExpirationType {
    id: number;
    name: string;
    description?: string;
}

export interface IRequirementTypeDocumentRestriction {
    id?: number;
    name: string;
    title: string;
    requirementTypeId: number;
    isActive: boolean;

    excludeMimeTypes?: string;
    mimeTypes?: string;
    limitFileSize?: number;
    regexp?: string;
}

export interface IRequirementTypeValidationHolder {
    id?: number;
    requirementTypeId: number;

    isBlocking?: boolean;
    validationOrder?: number;

    policy?: string;
    appUserId?: number;
    departmentId?: number;
    workFunctionId?: number;
    jobResponsible?: boolean;
    onlyNotifications?: boolean;
    jobRso?: boolean;
    jobImpliedRso?: boolean;
    optional?: boolean;
}

export interface IRequirementTypeSuggestion {
    id?: number;
    name: string;
    requirementTypeId: number;
}

export enum RequirementTypeDependencyType {
    RequirementMustHaveBeenRead = 1,
    RequirementMustHaveBeenValidated = 2,
    IBO = 3,
}

export interface IRequirementTypeDependency {
    id?: number;
    requirementTypeId: number;
    dependsOnRequirementId?: number;
    dependencyType?: RequirementTypeDependencyType;

    dependsOnRequirement?: IRequirementType;
    propagate?: boolean;
}

export interface IRequirementType {
    id?: number;
    workId: number;
    name: string;
    title?: string;
    description?: string;
    requirementGroupId: number;
    targetType: number;
    kindId: number;
    expression?: string;
    isActive: boolean;
    isShared?: boolean;
    isPublic?: boolean;
    policy?: boolean;
    expirationTypeId?: number;
    expirationValue?: number;
    expirationThreshold?: number;
    expirationRequired?: boolean;
    validationTypeId?: number;
    isResourcePicture?: boolean;

    criteria?: string;
    template?: string;
    color?: string;
    orderValue?: number;
    notifyContractors?: boolean;
    notifyTitle?: string;
    notifyPolicy?: string;
    informationChargedNotifyPolicy?: string;
    notifyResponsible?: boolean;
    requireJobInformationAccess?: boolean;
    messageTemplateId?: number;

    dependencies?: IRequirementTypeDependency[];
    documentRestrictions?: IRequirementTypeDocumentRestriction[];
    suggestions?: IRequirementTypeSuggestion[];
    validationHolders?: IRequirementTypeValidationHolder[];

    billingTypeId?: number;
    usersExcludedChargedNotifications?: number[];
}

export interface IRequirementStatusType {
    id: number;
    name: string;
    description?: string;
}

export interface IRequirementStatusNote {
    id?: number;
    requirementId: number;
    requirementStatusId?: number;
    remarks: string;
    creationDate: Date;
    createdById?: number;
}

export interface IRequirementValidationType {
    id: number;
    name: string;
}

export interface IRequirementGroup {
    id?: number;
    name: string;
    title: string;
    description?: string;
    orderValue?: number;
    isActive: boolean;
    workId: number;
    legend?: string;

    color?: string;
    style?: string;
    icon?: string;

    requirementTypes?: IRequirementType[];
}

export interface IRequirementStatus {
    id: number;
    dateTime: Date;
    requirementId: number;
    statusTypeId: number;
    createdById: number;
    isCurrent?: boolean;
    flag?: number;
    validatedBy?: number;
    remarks?: string;
    displayDateTime?: Date;
    notes?: IRequirementStatusNote[];
}

export interface IRequirementValidation {
    id: number;
    appUserId: number;
    dateTime: Date;
    isCurrent?: boolean;
    validated?: boolean;
    validationHolderId?: number;
}

export interface IRequirement {
    id: number;
    creationDate: Date;
    workId: number;
    requirementTypeId: number;
    requirementType?: IRequirementType;

    isShared?: boolean;
    isExpired?: boolean;
    expirationDate?: Date;
    expirationThreshold?: number;

    title?: string;
    remarks?: string;
    color?: string;

    documents?: IRequirementHasDocument[];
    incidences: IRequirementIncidence[];
    notes: IRequirementNote[];

    status: IRequirementStatus[];

    validations: IRequirementValidation[];

    hasNotes?: boolean;
    hasIncidences?: boolean;
    inProgress?: boolean;

    mandatory?: boolean;
}

export interface IRequirementIncidence {
    id?: number;
    requirementId: number;
    creationDate: Date;
    createdById?: number;
    remarks: string;
    documentVersionId?: number;
}

export interface IRequirementNote {
    id?: number;
    requirementId: number;
    creationDate: Date;
    createdById?: number;
    remarks: string;
}

export interface IRequirementStatusNote {
    id?: number;
    requirementId: number;
    requirementStatusId?: number;
    remarks: string;
    creationDate: Date;
    createdById?: number;
}

export interface IRequirementHasDocument {
    id: number;
    dateTime: Date;
    createdById: number;
    createdBy?: string;
    documentRestrictionId?: number;
    document: IDocument;
}

export interface IRequirementFulfillDocument {
    name: string;
    resourceId: string | File;
    fileSize?: number;
    mimeType?: string;
    requirementTypeDocumentRestrictionId?: number;
}

export interface IRequirementValidationData {
    remarks: string;
    expirationDate?: Date;
    expirationThreshold?: number;
    fileResourceId?: string;
    fileResourceName?: string;
    skipExpirationDate?: boolean;
}

export function fulfillDocumentIsFile(r: string | File): r is File {
    return typeof (r) === 'object';
}

export interface IRequirementFulfillData {
    documents: IRequirementFulfillDocument[],
    remarks?: string,
    color?: string,
    isShared?: boolean,
    departmentId?: number,
    jobResponsibleAsHolder?: boolean,
    requirementId?: number,
    title?: string,
    workId: number,
}

export const userIsValidationHolder = (
    holder: IRequirementTypeValidationHolder,
    security: ISecurity,
    ctx: CanValidateContext | undefined = undefined) => {
    return ((holder.appUserId !== null && holder.appUserId === security.user?.id)
        || (holder.departmentId !== null && security.user?.departments?.includes(holder.departmentId!))
        || (holder.workFunctionId !== null && security.user?.workFunctions?.includes(holder.workFunctionId!))
        || (holder.jobResponsible != true && security.getWorkerIds().includes(ctx?.mainDepartment?.responsibleId ?? 0)))
        ?? false;
}

export type CanValidateContext = {
    job?: IJob;
    mainDepartment?: IJobHasDepartment;
}

export const userCanValidateRequirement = (
    requirementType: IRequirementType,
    security: ISecurity,
    ctx: CanValidateContext | undefined = undefined) => {
    const a = ((requirementType.validationHolders?.length ?? 0) === 0);
    const b = (requirementType.validationHolders?.find(h => userIsValidationHolder(h, security, ctx)) != undefined);

    return security.isWorker() && (a || b);
}

export interface IRequirementNotification {
    workId: number;
    requirementId: number;
    resourceType: number;
    resourceId: number;
}

export const FreeRequirementType = 0;
