import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import { ManageContractors } from '../../components/contractors/ManageContractors';
import { isJobHasContractorPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...ContractorsStore.plainActions,
        modules: state.modules.moduleManager,
        constants: state.constants,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isJobHasContractorPropertyGroupType)
    }),
    ContractorsStore.actionCreators,
)(ManageContractors as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
