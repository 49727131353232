import * as React from 'react';

import {
    Message,
    Select,
    useConfirm,
    useDataTable, useLoading, useNumberState, useTranslation, useResolveName, useSearch
} from '@components';
import { departmentIsProductive, IDepartment, IJob, IWorker, IJobHasDepartment, IWork } from '@models';

export interface IProps {
    departments: IDepartment[];
    getJobDepartments: Function;
    job: IJob;
    readonly?: boolean;
    removeJobHasDepartment: Function;
    saveJobHasDepartment: Function;
    work: IWork;
}

function DepartmentSelector({ departments, readonly, relation, onChange }: {
    departments: IDepartment[], readonly?: boolean, relation: IJobHasDepartment, onChange: Function
}) {
    const loading = useLoading();
    const selectedDepartment = useNumberState(relation.departmentId);
    const selectedWorker = useNumberState(relation.responsibleId);

    const departmentResolver = useResolveName(departments);

    const [workers, setWorkers] = React.useState<IWorker[]>([]);
    const workerResolver = useResolveName(workers, { name: 'fullName' });

    React.useEffect(() => {
        const dep = departments.find(d => d.id == selectedDepartment.value);
        const values = (dep?.workers ?? [])
            .map(w => ({ ...w, fullName: `${w.name} ${w.surname ?? ''}` }));
        setWorkers(values);
    }, [selectedDepartment.value]);

    const update = (field: 'departmentId' | 'responsibleId') => {
        return loading.wrap(async (ev: any) => {
            if (field === 'departmentId') {
                selectedDepartment.set(ev);
            }
            else {
                selectedWorker.set(ev);
            }
            const rel = {
                id: relation.id,
                dateTime: relation.dateTime,
                jobId: relation.jobId,
                departmentId: relation.departmentId,
                responsibleId: relation.responsibleId,
                [field]: parseInt(ev.target.value)
            };
            await onChange(rel);
        });
    }

    return <div className='r'>
        {readonly && <>
            <input
                type='text'
                className='flat e'
                readOnly
                value={departmentResolver(selectedDepartment.value)} />
            <input
                type='text'
                className='flat e'
                readOnly
                value={workerResolver(selectedWorker?.value)} />
        </>}
        {!readonly && <>
            <Select
                className='flat e'
                options={departments}
                optionLabel='name'
                optionValue='id'
                onChange={update('departmentId')}
                value={selectedDepartment.value}></Select>
            <Select
                className='flat e'
                options={workers}
                optionLabel='fullName'
                optionValue='id'
                onChange={update('responsibleId')}
                value={selectedWorker.value}></Select>
        </>}
        {loading.render()}
    </div>
}

export function ManageJobDepartments(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const [error, setError] = React.useState<string | undefined>();
    const [showAdd, setShowAdd] = React.useState<boolean>(false);
    const [workers, setWorkers] = React.useState<IWorker[]>([]);
    const selectedDepartment = useNumberState();
    const selectedWorker = useNumberState();

    const doRemoveJobHasDepartment = loading.wrap(async (relation: IJobHasDepartment) => {
        setError(undefined);

        const resp = await props.removeJobHasDepartment(props.work.id, props.job.id, relation.id);
        if (!resp.hasValue) {
            setError(resp.error);
        }

        await refresh();
    });

    const availableResponsibles = useSearch<any>({
        workId: props.work.id,
        search: 'jobList/oblique.responsibles',
        filters: {
            workId: props.work.id,
        },
    });

    const [data, setData] = React.useState<IJobHasDepartment[]>(props.job.departments);
    const confirmRemove = useConfirm({
        message: t('Are you sure to remove the selected department relation?'),
        accept: doRemoveJobHasDepartment
    });

    React.useEffect(() => {
        const dep = props.departments.find(d => d.id == selectedDepartment.value);
        setWorkers(
            (dep?.workers ?? [])
                .filter(w => availableResponsibles.value.find(r => r.id == w.id) != undefined)
                .map((w: IWorker) => ({ ...w, fullName: `${w.name} ${w.surname}` })));
        selectedWorker.set(undefined);
    }, [selectedDepartment.value]);

    const updateRelation = loading.wrap(async (rel: IJobHasDepartment) => {
        setError(undefined);

        const resp = await props.saveJobHasDepartment(props.work.id, props.job.id, rel);
        if (!resp.hasValue) {
            setError(resp.error);
        }

        await refresh();
    });

    const refresh = async () => {
        const resp = await props.getJobDepartments(props.work.id, props.job.id);
        setData(resp);
    }

    const toggleAdd = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShowAdd(v => !v);
    }

    const dataTable = useDataTable({
        className: 'transparent',
        columns: [
            {
                render: (jr: IJobHasDepartment) =>
                    <DepartmentSelector
                        key={jr.id}
                        readonly={props.readonly}
                        departments={props.departments.filter(d => d.isOblique != true)}
                        onChange={updateRelation}
                        relation={jr} />
            },
            {
                className: 'td-sm',
                render: (jr: IJobHasDepartment) => jr.isMain
                    ? <i className='pi pi-circle-on' title={t('Main department')} />
                    : null
            },
        ],
        data: data,
        actions: [
            {
                icon: 'trash',
                tooltip: t('Remove job department'),
                onClick: confirmRemove,
                disabled: (r: IJobHasDepartment) => r.isMain || props.readonly
            }
        ]
    });

    const doSaveJobHasDepartment = loading.wrap(async (..._: any) => {
        setError(undefined);

        const relation = {
            jobId: props.job.id,
            departmentId: selectedDepartment.value,
            responsibleId: selectedWorker.value,
            dateTime: new Date(),
        };

        const resp = await props.saveJobHasDepartment(props.work.id, props.job.id, relation);
        if (!resp.hasValue) {
            setError(resp.error);
        }

        setShowAdd(false);
        await refresh();
    });

    const departments = props.departments.filter(departmentIsProductive);

    return <div>
        {dataTable()}
        {showAdd && <div className='c box sm mr light-shadow'
            style={{ position: 'absolute', minWidth: '573px' }}>
            <div className='r'>
                <Select
                    className='e flat'
                    optionLabel={'name'}
                    optionValue={'id'}
                    options={departments}
                    value={selectedDepartment.value}
                    onChange={selectedDepartment.set} />
                <Select
                    className='e flat'
                    optionLabel={'fullName'}
                    optionValue={'id'}
                    options={workers}
                    value={selectedWorker.value}
                    onChange={selectedWorker.set} />
            </div>
            <div className='r sm pd-top'>
                <button className='slim flat' onClick={toggleAdd}>
                    {t('Cancel')}
                </button>
                <div className='e'></div>
                <button
                    className='slim flat primary'
                    disabled={!(selectedWorker.hasValue() && selectedDepartment.hasValue())}
                    onClick={doSaveJobHasDepartment}>
                    <i className='pi pi-plus'></i>
                </button>
            </div>
        </div>}

        {!props.readonly &&
            <div className='r sm mr'>
                <span className='e'></span>
                <button
                    onClick={toggleAdd}
                    className='slim flat'>
                    <i className='pi pi-plus'></i>
                    {t('Add department')}
                </button>
            </div>}
        {error && <div className='sm mr'><Message className='e' severity='error' text={t(error)} /></div>}
    </div>
}
