import { m, q } from './graphql';
import { pushTemporalResource } from './documents';
import { IRequirementFulfillData, fulfillDocumentIsFile, IRequirementFulfillDocument, IRequirementIncidence, IRequirementNote, IRequirementValidationData, IRequirement, IRequirementStatusNote } from '@models/requirements';
import { ensureType } from './utils';
import { ResourceType } from '@models';

export async function validateRequirement(
    workId: number,
    requirementId: number,
    validate: boolean,
    validationData: IRequirementValidationData,
    fileResourceId: string | undefined,
    fileResourceName: string | undefined) {
    return m(`mutation ValidateRequirement($workId: Int!, $requirementId: Int!, $validate: Boolean!, $validationData: RequirementValidationDataGraphInputType!, $fileResourceId: String, $fileResourceName: String) {
        validateRequirement(workId: $workId, requirementId: $requirementId, validate: $validate, data: $validationData, fileResourceId: $fileResourceId, fileResourceName: $fileResourceName) {
            value, hasValue, error, isError
        }
    }`,
        { workId, requirementId, validate, validationData, fileResourceId, fileResourceName },
        { returnPath: 'validateRequirement' });
}

export async function getRequirements(
    workId: number,
    targetType: number,
    targetId: number) {
    return q(`query GetRequirements($workId: Int!, $targetType: Int!, $targetId: Int!) {
        work(id: $workId) {
            requirements(targetType: $targetType, targetId: $targetId) {
                id, creationDate, workId, requirementTypeId,
                isExpired, isShared,
                hasIncidences, hasNotes,
                expirationDate, departmentId, contractorId,
                machineryId, workerId,
                jobHasContractorId, jobHasMachineryId, jobHasWorkerId,
                jobHasDepartmentId,
                title, remarks, color,
                inProgress, mandatory,
                requirementType {
                    id,
                    dependencies {
                        dependsOnRequirementId, dependencyType, propagate
                    },
                    isResourcePicture
                }
                status {
                    id, dateTime, statusTypeId, createdById, isCurrent, flag, validatedBy, remarks, displayDateTime, notes {
                    id, remarks, creationDate, createdById, remarks
                    }
                },
                documents {
                    id, dateTime, documentRestrictionId, createdBy, document {
                        dateTime,
                        id, name, description, hasActiveVersion, mimeType, size, versions {
                            id, name, mimeType, size, dateTime, userId
                        }
                    }
                },
                incidences {
                    id, remarks, creationDate, createdById, documentVersionId
                },
                notes {
                    id, remarks, creationDate, createdById
                },
                validations {
                    id, appUserId, dateTime, isCurrent, validated, validationHolderId
                }
            }
        }
    }`,
        { workId, targetId, targetType },
        { returnPath: 'work.requirements' });
}

export async function getRequirement(
    workId: number,
    targetType: number,
    targetId: number,
    id: number
) {
    return q(`query GetRequirement($workId: Int!, $targetType: Int!, $targetId: Int!, $id: Int!) {
        work(id: $workId) {
            requirement(targetType: $targetType, targetId: $targetId, id: $id) {
                id, creationDate, workId, requirementTypeId, isShared,
                hasIncidences, hasNotes,
                expirationDate, departmentId, contractorId,
                inProgress, color,
                status {
                    id, dateTime, statusTypeId, createdById, isCurrent, flag, validatedBy, remarks, displayDateTime,
                },
                documents {
                    id, dateTime, documentRestrictionId, createdBy, document {
                        id, name, description, mimeType, size, versions {
                            id, name, mimeType, size, dateTime, userId
                        }
                    }
                },
                incidences {
                    id, remarks, creationDate, createdById, documentVersionId,
                },
                notes {
                    id, remarks, creationDate, createdById
                }
            }
        }
    }`, { workId, targetId, targetType, id }, { returnPath: 'work.requirement' });
}

type RequirementFromResource = {
    requirementId: number,
    resourceType: number,
    resourceId: number,
}

export async function getRequirementsFromResources(
    workId: number,
    requirementIds: RequirementFromResource[]) {
    return q(`query GetRequirementsFromResources($workId: Int!, $requirementIds: [RequirementResourceIdGraphType]) {
        work(id: $workId) {
            requirementsFromResources(requirementIds: $requirementIds) {
                id, creationDate, workId, requirementTypeId, isShared,
                hasIncidences, hasNotes, title,
                expirationDate, departmentId, contractorId,
                inProgress, mandatory, color,
                status {
                    id, dateTime, statusTypeId, createdById, isCurrent, flag, validatedBy, remarks, displayDateTime,
                },
                documents {
                    id, dateTime, documentRestrictionId, createdBy, document {
                        id, name, description, mimeType, size, dateTime, versions {
                            id, name, mimeType, size, dateTime, userId
                        }
                    }
                },
                incidences {
                    id, remarks, creationDate, createdById
                },
                notes {
                    id, remarks, creationDate, createdById
                }
            }
        }
    }`, { workId, requirementIds }, { returnPath: 'work.requirementsFromResources' });
}

export async function createFreeRequirement(
    workId: number,
    jobId: number,
    requirementData: IRequirementFulfillData,
    resourceType: number | undefined = undefined,
    resourceId: number | undefined = undefined,
    statusType: number | undefined = undefined,
) {
    // generamos los temporal resources id
    const documents: IRequirementFulfillDocument[] = [];
    for (const r of requirementData.documents) {
        if (fulfillDocumentIsFile(r.resourceId)) {
            const data = await pushTemporalResource(r.resourceId);
            documents.push({ ...r, resourceId: data.value });
        } else {
            documents.push({ ...r });
        }
    }

    const data = { ...requirementData, documents };

    return m(`mutation CreateFreeRequirement(
        $workId: Int!,
        $jobId: Int!,
        $data: FulfillRequirementGraphInputType!,
        $resourceType: Int,
        $resourceId: Int,
        $statusType: Int,
        ) {
        createFreeRequirement(
            workId: $workId,
            jobId: $jobId,
            data: $data,
            resourceType: $resourceType,
            resourceId: $resourceId,
            statusType: $statusType
        ) {
            hasValue, value, error, isError
        }
    }`, {
        workId, jobId, data, resourceType, resourceId, statusType,
    }, {
        returnPath: 'createFreeRequirement'
    });
}

export async function fulfillRequirement(
    workId: number,
    requirementId: number,
    requirementData: IRequirementFulfillData) {
    // generamos los temporal resources id
    const documents: IRequirementFulfillDocument[] = [];
    for (const r of requirementData.documents) {
        if (fulfillDocumentIsFile(r.resourceId)) {
            const data = await pushTemporalResource(r.resourceId);
            documents.push({ ...r, resourceId: data.value });
        } else {
            documents.push({ ...r });
        }
    }

    const data = { ...requirementData, documents };

    return m(`mutation FulfillRequirement($workId: Int!, $requirementId: Int!, $data: FulfillRequirementGraphInputType!) {
        fulfillRequirement(workId: $workId, requirementId: $requirementId, data: $data) {
            hasValue, value, error, isError
        }
    }`, {
        workId, requirementId, data
    }, {
        returnPath: 'fulfillRequirement'
    });
}

export async function saveRequirementIncidence(
    workId: number,
    inputData: IRequirementIncidence,
    fileResourceId: string | undefined = undefined,
    fileResourceName: string | undefined = undefined) {
    const data = ensureType<IRequirementIncidence>(inputData);

    return m(`mutation SaveRequirementIncidence($workId: Int!, $data: RequirementIncidenceGraphInputType!, $fileResourceId: String, $fileResourceName: String) {
        saveRequirementIncidence(workId: $workId, data: $data, fileResourceId: $fileResourceId, fileResourceName: $fileResourceName) {
            hasValue, value, error
        }
    }`,
        { workId, data, fileResourceId, fileResourceName },
        { returnPath: 'saveRequirementIncidence' });
}

export async function saveRequirementNote(workId: number, inputData: IRequirementNote) {
    const data = ensureType<IRequirementNote>(inputData);

    return m(`mutation SaveRequirementNote($workId: Int!, $data: RequirementNoteGraphInputType!) {
        saveRequirementNote(workId: $workId, data: $data) {
            hasValue, value, error
        }
    }`,
        { workId, data },
        { returnPath: 'saveRequirementNote' });
}

export async function saveRequirementStatusNote(workId: number, inputData: IRequirementStatusNote) {
    const data = ensureType<IRequirementStatusNote>(inputData);

    return m(`mutation SaveRequirementStatusNote($workId: Int!, $data: RequirementStatusNoteGraphInputType!) {
        saveRequirementStatusNote(workId: $workId, data: $data) {
            hasValue, value, error
        }
    }`,
        { workId, data },
        { returnPath: 'saveRequirementStatusNote' });
}

export async function getRequirementIncidences(workId: number, requirementId: number, targetType: number, targetId: number) {
    return q(`query GetRequirementIncidences($workId: Int!, $requirementId: Int!, $targetType: Int!, $targetId: Int!) {
        work(id: $workId) {
            requirement(id: $requirementId, targetType: $targetType, targetId: $targetId) {
                incidences {
                    id, requirementId, remarks, creationDate, documentVersionId
                }
            }
        }
    }`, { workId, requirementId, targetType, targetId },
        { returnPath: 'work.requirement.incidences' });
}

export async function getRequirementNotes(workId: number, requirementId: number, targetType: number, targetId: number) {
    return q(`query GetRequirementNotes($workId: Int!, $requirementId: Int!, $targetType: Int!, $targetId: Int!) {
        work(id: $workId) {
            requirement(id: $requirementId, targetType: $targetType, targetId: $targetId) {
                notes {
                    id, requirementId, remarks, creationDate
                },
                status {
                    id, requirementId, remarks, dateTime
                }
            }
        }
    }`, { workId, requirementId, targetType, targetId },
        { returnPath: 'work.requirement.notes' });
}

export async function forceResourceStatus(workId: number, resourceType: ResourceType, resourceId: number, validated: boolean, date?: string) {
    const data = { workId, resourceType, resourceId, validated, forcedStatusUntilDate: date };

    const resp = await m(`mutation ForceResourceStatus($workId: Int!, $resourceType: Int!, $resourceId: Int!, $validated: Boolean!, $forcedStatusUntilDate: String) {
        forceResourceStatus(workId: $workId, resourceType: $resourceType, resourceId: $resourceId, validated: $validated, untilDate: $forcedStatusUntilDate) {
            hasValue, value, error, isError
        }
    }`, data);

    return resp?.data.forceResourceStatus;
}

export async function requirementMeetsDependencies(workId: number, requirementId: number, jobId?: number) {
    return q(`query RequirementMeetsDependencies($workId: Int!, $requirementId: Int!, $jobId: Int) {
        work(id: $workId) {
            requirementMeetsDependencies(requirementId: $requirementId, jobId: $jobId) {
                hasValue, value, error, isError
            }
        }
     }`, { workId, requirementId, jobId }, { returnPath: 'work.requirementMeetsDependencies' });
}

export async function requirementCanBeValidated(workId: number, requirementId: number) {
    return q(`query RequirementCanBeValidated($workId: Int!, $requirementId: Int!) {
        work(id: $workId) {
            requirementCanBeValidated(id: $requirementId)
        }
     }`, { workId, requirementId }, { returnPath: 'work.requirementCanBeValidated' });
}

export async function removeRequirementFile(requirement: IRequirement) {
    return m(`mutation RemoveRequirementFile($workId: Int!, $requirementId: Int!) {
        removeRequirementFile(workId: $workId, id: $requirementId) {
            hasValue, value, error, isError
        }
    }`, { workId: requirement.workId, requirementId: requirement.id }, { returnPath: 'removeRequirementFile' });
}

export async function removeFreeRequirement(requirement: IRequirement) {
    return m(`mutation RemoveFreeRequirement($workId: Int!, $requirementId: Int!) {
        removeFreeRequirement(workId: $workId, id: $requirementId) {
            hasValue, value, error, isError
        }
    }`, { workId: requirement.workId, requirementId: requirement.id }, { returnPath: 'removeFreeRequirement' });
}
