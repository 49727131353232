import * as React from 'react';

import { Dropdown as Select } from 'primereact/dropdown';
import { useLoading } from '@utils/hooks';

export function InlineSelect({options, optionLabel, optionValue, object, field, save}: {
    options: any[], optionLabel?: string, optionValue?: string,
    object: any, field: string, save: Function
}) {
    const loading = useLoading();

    const update = loading.wrap(async (ev: any) => {
        object[field] = ev.target.value;

        await save(object);
    });

    return <div className='r'>
        <Select
            options={options}
            optionLabel={optionLabel ?? 'name'}
            optionValue={optionValue ?? 'id'}
            className='e transparent'
            value={object[field]}
            onChange={update} />
        {loading.render()}
    </div>;
}
