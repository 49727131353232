import * as React from 'react';

import './GraphQlUi.css';

export interface IProps {
    src: string;
}

export default function GraphQlUi(props: IProps) {
    return <iframe className='g-container' src={'/ui/' + props.src}></iframe>
}