import * as React from 'react';

import {
    useDataTable, useSearch, useTranslation, Tag, useLoading
} from '@components';

import DateUtils from '@utils/date-utils';
import { IJob, IJobHasContractor, ISecurity, IUserIdentity } from '@models';
import { IContractor } from '@models/resources';

export interface IProps {
    jobId: number;
    workId: number;
    identity: IUserIdentity;
    job: IJob;
    getContractorFromAppUser: Function;
    getJobContractors: Function;
    security: ISecurity;
}

export function JobContractorSubRequests(props: IProps) {
    const { t } = useTranslation();

    const loading = useLoading();

    const userIsSubcontractor = React.useRef(false);

    const jobHasContractor = React.useRef<IJobHasContractor>();
    const [canRender, setCanRender] = React.useState<boolean>(false);

    const initialize = loading.wrap(async () => {
        const contractorUserData = await props.getContractorFromAppUser(props.workId);

        const jobContractorsData = await props.getJobContractors(props.workId, props.jobId);

        const subContractors = await jobContractorsData.filter((x: IJobHasContractor) => x.parentId !== null);

        const isSubcontractor = await subContractors.some((jc: IJobHasContractor) => jc.contractorId === (contractorUserData?.id ?? 0));

        const jobContractorData = await jobContractorsData.find((jc: IJobHasContractor) => jc.contractorId === (contractorUserData?.id ?? 0));
        userIsSubcontractor.current = isSubcontractor && props.security.isContractor();
        jobHasContractor.current = jobContractorData;

        setCanRender(props.security.isContractor()
            ? jobHasContractor.current != null
            : true);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const requestSearch = useSearch({
        workId: props.workId,
        search: 'jobs/subcontractor.requests',
        filters: {
            jobId: props.jobId,
            workId: props.workId,
            contractorId: userIsSubcontractor ? (jobHasContractor.current?.parentId) : null,
        }
    });

    const invitationSearch = useSearch({
        workId: props.workId,
        search: 'jobs/subcontractor.invitations.contractors',
        filters: {
            jobId: props.jobId,
            workId: props.workId,
            jobHasContractorId: userIsSubcontractor ? (jobHasContractor.current?.parentId) : null,
        }
    });

    const [data, setData] = React.useState<any>([]);

    const renderStatus = (d: any) => {
        if (d.acceptedByContractor || d.accepted) {
            return <Tag severity={'success'}>{t('job.contractor-request.accepted-by-contractor')}</Tag>;
        }
        else if (d.acceptedByWork) {
            return <Tag severity={'warning'}>{t('job.contractor-request.accepted-by-work')}</Tag>;
        }
        else if (d.accepted == null && d.jobId == null) {
            return <Tag>{t('Pending') + ' (' + t('Invitation') + ')'}</Tag>;
        }
        else if ((d.rejected && d.reject != null) || (!d.accepted && d.accepted != null)) {
            return <Tag severity={'danger'}>{t('Rejected')}</Tag>;
        }
        else {
            return <Tag>{t('Pending')}</Tag>
        }
    }

    function filteredInvitations() {
        let parentIds: any[] = [];
        parentIds.push(jobHasContractor.current?.id ?? undefined);
        invitationSearch.value.map((x: any) => parentIds.includes(x.parentId) ? parentIds.push(x.id) : undefined);
        requestSearch.value.map((x: any) => parentIds.includes(x.parentId) ? parentIds.push(x.id) : undefined);

        if (requestSearch.isCompleted && invitationSearch.isCompleted) {
            const filteredRequests = requestSearch
                .value
                .filter((i: any) =>
                    jobHasContractor.current
                        ? i.sourceContractorId == jobHasContractor.current?.contractor.id
                        || parentIds.includes(i.parentId)
                        || jobHasContractor.current?.contractorId == i.targetContractorId
                        : true);
            const filteredInvitations = invitationSearch
                .value
                .filter((i: any) =>
                    jobHasContractor.current
                        ? i.sourceId == jobHasContractor.current?.contractor.id
                        || parentIds.includes(i.parentId)
                        : true);
            const concated: any[] = filteredRequests
                .concat(filteredInvitations)
                .sort((a: any, b: any) => b.dateTime.localeCompare(a.dateTime));
            return concated;
        }
        else {
            return [];
        }
    }

    React.useEffect(() => {
        setData(filteredInvitations());
    }, [requestSearch.value, invitationSearch.value, jobHasContractor.current]);

    const dataTable = useDataTable({
        columns: [
            { title: t('Date'), className: 'td-datetime-sm', field: 'dateTime', delegate: 'date' },
            { title: t('job.contractor-request.source'), render: (d: any) => jobHasContractor ? d.sourceId == jobHasContractor.current?.id || d.sourceContractorId == jobHasContractor.current?.contractorId ? <b>{d.sourceName}</b> : d.sourceName : d.sourceName },
            { title: t('job.contractor-request.target'), render: (d: any) => jobHasContractor ? d.targetContractorId == jobHasContractor.current?.id ? <b>{d.targetName}</b> : d.targetName : d.targetName },
            {
                title: t('Job'),
                render: (d: any) => <span className='r g-10 v-center'>
                    <Tag value={d.jobCode ?? d.code} />
                    <span title={d.jobName}>{d.jobName}</span>
                </span>
            },
            { title: t('Status'), className: 'td-datetime-sm', render: renderStatus },
        ],
        data: data,
        loading: requestSearch.isCompleted && invitationSearch.isCompleted,
    });

    return <div className='he c'>
        {canRender && dataTable()}
    </div>;
}
