import {IWork} from "@models";
import {RequirementTargetType} from "@models/requirements";
import {flatten} from "@utils";

export const getTargetEntityName = (target: RequirementTargetType) => {
    const targets = {
        [RequirementTargetType.Contractor]: 'Contractor',
        [RequirementTargetType.JobHasContractor]: 'JobHasContractor',
        [RequirementTargetType.JobHasDepartment]: 'JobHasDepartment',
        [RequirementTargetType.JobHasMachinery]: 'JobHasMachinery',
        [RequirementTargetType.JobHasWorker]: 'JobHasWorker',
        [RequirementTargetType.Machinery]: 'Machinery',
        [RequirementTargetType.Worker]: 'Worker',
        [RequirementTargetType.Job]: 'Job',
    };

    return targets[target];
}

export const getReferenceValues = (p: string|undefined, work: IWork, constants: any) => {
    if (p?.endsWith('MachineryTypeId')) {
        return constants.machineryTypes;
    }
    else if (p?.endsWith('MachinerySubTypeId')) {
        const m = constants.machineryTypes;
        const s = m.map((m: any) => m.subTypes.map((s: any) => ({...s, name: m.name + ': ' + s.name})));

        return flatten(s);
    }
    else if (p?.endsWith('WorkShiftId')) {
        return constants.workShifts;
    }
    else if (p?.endsWith('WorkspaceId')) {
        return constants.workspaces;
    }
    else if (p?.endsWith('LegalFormId') || p === 'LegalFormId' || p === 'Contractor.LegalFormId') {
        return constants.legalForms;
    }
    else if (p?.endsWith('WorkerTypeId')) {
        return constants.workerTypes;
    }
    else if (p?.endsWith('ActivityTypeId')) {
        return constants.activityTypes;
    }
    else if (p?.endsWith('CountryId')) {
        return constants.countries;
    }
    else if (p?.endsWith('CountyId')) {
        const countries = constants.countries;
        const s = flatten(countries.map((c: any) => c.regions.map((r: any) =>
            ({...r, name: c.name + ': ' + r.name})
        )));

        const res = flatten(s.map((r: any) =>
                r.counties.map((c: any) =>
                    ({...c, name: r.name + ': ' + c.name}))));

        return res;
    }
    else if (p?.endsWith('RegionId')) {
        const countries = constants.countries;
        const s = countries.map((c: any) => c.regions.map((r: any) =>
            ({...r, name: c.name + ': ' + r.name})
        ));

        return flatten(s);
    }
    else {
        return [];
    }
}

export const propertyIsString = (propertyName: string) => {
    return !propertyName.includes("Id");
}

export const propertyOperatorName = (id: PropertyOperator) => {
    return allOperators.find(o => o.id === id)?.name ?? '?';
}

export const propertyTitle = (target: RequirementTargetType, name: string) => {
    if (name == 'JobHasContractor.ParentId' || (target == RequirementTargetType.JobHasContractor && name === 'ParentId')) {
        return 'contractor.issubcontractor';
    }
    else {
        return name;
    }
}

export const propertyIsReference = (name: string|undefined) => {
    const references = [
        'MachineryTypeId', 'MachinerySubTypeId',
        'Machinery.MachineryTypeId', 'Machinery.MachinerySubTypeId',
        'CountryId', 'CountyId', 'RegionId',
        'LegalFormId', 'ActivityTypeId',
        'Contractor.LegalFormId', 'Contractor.ActivityTypeId',
        'Job.ActivityTypeId', 'Job.WorkShiftId',
        'Job.WorkspaceId',
        'Worker.WorkerTypeId', 'WorkerTypeId',
        'Worker.Contractor.LegalFormId',
    ];
    return name != undefined && references.includes(name);
}

export const propertyIsBoolean = (name: string|undefined) => {
    return name && ['IsMain'].includes(name);
}

export const propertyIsCheckExistence = (name: string|undefined) => {
    return name?.includes('ParentId');
}

export enum Operator {
    AND = 1,
    OR  = 2
}

export enum PropertyOperator {
    EQUAL               = 1,
    IN                  = 2,
    LESSER_THAN         = 3,
    GREATER_THAN        = 4,
    LESSER_EQUAL_THAN   = 5,
    GREATER_EQUAL_THAN  = 6,
    CONTAINS            = 7,
    NOT_EQUAL           = 8,
    TRUE                = 9,
    FALSE               = 10,
    IS_NULL             = 11,
    IS_NOT_NULL         = 12,
}

export const getCommonProperties = (targetType: RequirementTargetType) => {
    if (targetType === RequirementTargetType.Worker) {
        return [
            'Name', 'Surname', 'Code', 'Remarks', 'WorkerTypeId', 'LegalFormId',
            'Contractor.LegalFormId',
        ];
    }
    else if (targetType === RequirementTargetType.JobHasWorker) {
        return [
            'StartDate',
            'Worker.Name', 'Worker.Surname', 'Worker.Code', 'Worker.Remarks',
            'Worker.WorkerTypeId',
            'Worker.LegalFormId',
            'Worker.Contractor.LegalFormId',
            'Job.ActivityTypeId',
            'Job.WorkShiftId',
            'Job.WorkspaceId',
            'JobHasContractor.ParentId',
        ];
    }
    else if (targetType === RequirementTargetType.Contractor) {
        return [
            'Name', 'Code', 'Remarks', 'LegalFormId', 'ActivityTypeId',
            'Address', 'PostalCode', 'CountryId', 'RegionId', 'CountyId'
        ];
    }
    else if (targetType === RequirementTargetType.JobHasContractor) {
        return [
            'Contractor.Name', 'Contractor.Code', 'Contractor.Remarks',
            'Contractor.LegalFormId', 'Contractor.ActivityTypeId',
            'Contractor.Address', 'Contractor.PostalCode',
            'Contractor.CountryId', 'Contractor.RegionId',
            'Contractor.CountyId', 'JobsDescription',
            'StartDate', 'EndDate',
            'ParentId',
            'Job.ActivityTypeId',
            'Job.WorkShiftId',
            'Job.WorkspaceId'
        ];
    }
    else if (targetType === RequirementTargetType.Machinery) {
        return [
            'Name', 'Code', 'Description', 'MachineryTypeId', 'MachinerySubTypeId',
        ];
    }
    else if (targetType === RequirementTargetType.JobHasMachinery) {
        return [
            'Machinery.Name', 'Machinery.Code', 'Machinery.Description',
            'Machinery.MachineryTypeId', 'Machinery.MachinerySubTypeId',
            'Job.ActivityTypeId',
            'Job.WorkShiftId',
            'Job.WorkspaceId',
            'JobHasContractor.ParentId',
        ];
    }
    else if (targetType === RequirementTargetType.JobHasDepartment) {
        return [
            'DepartmentId', 'ResponsibleId' , 'DateTime', 'EndDateTime', 'IsMain',
            'Department.Name',
            'Job.ActivityTypeId',
            'Job.WorkShiftId',
            'Job.WorkspaceId'
        ];
    }
    else {
        return [];
    }
}

export const allOperators = [
    {id: PropertyOperator.EQUAL, name: 'expressions.operator.Equal'},
    {id: PropertyOperator.GREATER_EQUAL_THAN, name: 'expressions.operator.GreaterEqualThan'},
    {id: PropertyOperator.GREATER_THAN, name: 'expressions.operator.GreaterThan'},
    {id: PropertyOperator.IN, name: 'expressions.operator.In'},
    {id: PropertyOperator.LESSER_EQUAL_THAN, name: 'expressions.operator.LesserEqualThan'},
    {id: PropertyOperator.LESSER_THAN, name: 'expressions.operator.LesserThan'},
    {id: PropertyOperator.CONTAINS, name: 'expressions.operator.Contains'},
    {id: PropertyOperator.NOT_EQUAL, name: 'expressions.operator.NotEqual'},
    {id: PropertyOperator.TRUE, name: 'expressions.operator.True'},
    {id: PropertyOperator.FALSE, name: 'expressions.operator.False'},
    {id: PropertyOperator.IS_NULL, name: 'expressions.operator.IsNull'},
    {id: PropertyOperator.IS_NOT_NULL, name: 'expressions.operator.IsNotNull'}
];