import * as React from 'react';

import * as Actions from '@store/actions/resources';
import { associateAppUserToWorker, getUsers, getUserById } from '@store/actions/admin';
import { IDepartment, INotification, IPropertyGroupType, IUserIdentity, IWorker, IWorkFunction, PropertyGroupObjectType, IWorkspace, ILegalForm, IWorkerWithRelations } from '@models';
import { ManageRelations } from '../common/ManageRelations';
import { DynamicProperties } from '@components/user/DynamicProperties';
import {
    G,
    NotificationsBox,
    TabPanel,
    TabView,
    useForm,
    useFormDialog,
    useLoading,
    useTranslation,
    ValidationBuilder,
} from '@components';

import './WorkForm.css';
import { ManageManyToManyRelations } from '@components/common/ManageManyToManyRelations';
import { ManagePolicies } from '@components/common/ManagePolicies';
import { IUserFormData, isValidUser } from './UserForm';
import UserFormContainer from '@containers/admin/UserFormContainer';
import { SaveUserPolicies } from '@store/actions/user';

type IWorkerWithAppUserData = IWorker & {
    appUserId?: number;
    userEmail?: string;
    policies?: string[];
}

export interface IProps {
    data?: IWorkerWithAppUserData;
    workId: number;
    footer?: any;
    notifications: INotification[];
    onChange?: Function;
    onSubmit: Function;
    propertyGroupTypes: IPropertyGroupType[];
    workspaces: IWorkspace[];
    legalForms: ILegalForm[];
}

const RenderCreateAppUserRelation = ({ worker, workId, onCreated, role }: {
    onCreated: Function;
    workId: number;
    worker: IWorker;
    role?: string;
}) => {
    const { t } = useTranslation();
    const loading = useLoading(false);

    const [data, setData] = React.useState<IUserFormData>({
        roles: [role].filter(r => r != undefined) as string[],
        policies: [],
    });

    const createAndAssociateUser = loading.wrap(async () => {
        await associateAppUserToWorker(workId, worker.id, data);
        onCreated();
    });

    return <div className='c'>
        {loading.renderBox()}
        {!loading.isLoading() &&
            <UserFormContainer
                data={data}
                onChange={setData}
                onSubmit={console.log} />}

        <div className='footer r'>
            <span className='e' />
            <button
                className='p-button'
                disabled={!isValidUser(data)}
                onClick={createAndAssociateUser}>
                {t('Create user')}
            </button>
        </div>
    </div>
}

enum FormType {
    Edit = 0,
    AppUser = 1
}

export function WorkerForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const [error, setError] = React.useState<string | undefined>();
    const [editAccess, setEditAccess] = React.useState<IUserIdentity | undefined>();
    const [formType, setFormType] = React.useState<FormType>(FormType.Edit);
    const [policies, setPolicies] = React.useState<string[]>(props.data?.policies ?? []);

    const createAppUserDialog = useFormDialog({
        portal: 'body',
        editTitle: t('Create new user'),
    });
    const appUsersDialog = useFormDialog({
        editTitle: t('Associate app user')
    });


    const validateWorker = (data: IWorkerWithRelations) => {
        return ValidationBuilder
            .create(data)
            .notEmpty('name')
            .notEmptyIf(props.workId != 21, 'surname')
            .notEmptyIf(props.workId == 21, 'code')
            .build();
    }

    const form = useForm<IWorkerWithRelations>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            isActive: true,
            surname: props.data?.surname ?? '',
            code: props.data?.code ?? '',
            phone: props.data?.phone ?? '',
            workspaceId: props.data?.workspaceId ?? 0,
            // legalFormId: props.data?.legalFormId,
            remarks: props.data?.remarks ?? '',
            workId: props.workId,
            properties: props.data?.properties
        },
        validate: validateWorker,
        validateOnMount: true,
        onSubmit: (data: IWorkerWithRelations) => {
            savePolicies();
            props.onSubmit(data);
            return { ok: true };
        },
        // onChange: props.onChange,
    });

    //save policies
    const savePolicies = async () => {
        const userId = props.data?.appUserId;
        if (userId) {
            const res = await SaveUserPolicies(userId, policies);
            if (res.value == false || res.isError == true) {
                setError(t('Cannot change policies to user'));
            }
        } else {
            setError(t('Cannot change policies to user'));
        }
    }

    //add policy
    const addPolicyToAppUser = (policy: string) => {
        const newPolicies = [...(policies ?? []), policy];
        setPolicies(newPolicies);
    }

    //remove policy
    const RemovePolicyToAppUser = async (policy: string) => {
        const newPolicies = (policies ?? []).filter(p => p !== policy);
        setPolicies(newPolicies);
    }


    //add Department
    const addDepartmentHasWorker = async (department: IDepartment, worker: IWorker) => {
        loading.start('departments');

        const resp = await Actions.saveDepartmentHasWorker(props.workId, department.id, worker.id);
        if (!resp.hasValue) {
            setError(t('Cannot add department relation'));
        }

        loading.stop('departments');
    }

    // remove Department
    const removeDepartmentHasWorker = async (department: IDepartment, worker: IWorker) => {
        loading.start('departments');

        const resp = await Actions.removeDepartmentHasWorker(props.workId, department.id, worker.id);
        if (!resp.hasValue) {
            setError(t('Cannot remove department relation'));
        }

        loading.stop('departments');
    }

    // add Workspace
    const addWorkerHasWorkspace = async (workspace: IWorkspace, worker: IWorker) => {
        loading.start('workspaces');

        const resp = await Actions.saveWorkerHasWorkspace(props.workId, worker.id, workspace.id);
        if (!resp.hasValue) {
            setError(t('Cannot add workspace relation'));
        }

        loading.stop('workspaces');
    }

    const removeWorkerHasWorkspace = async (workspace: IWorkspace, worker: IWorker) => {
        loading.start('workspaces');

        const resp = await Actions.removeWorkerHasWorkspace(props.workId, worker.id, workspace.id);
        if (!resp.hasValue) {
            setError(t('Cannot remove workspace relation'));
        }

        loading.stop('workspaces');
    }

    // add Work Function
    const addWorkFunctionHasWorker = async (workFunction: IWorkFunction, worker: IWorker) => {
        loading.start('work functions');

        const resp = await Actions.saveWorkFunctionHasWorker(props.workId, workFunction.id, worker.id);
        if (!resp.hasValue) {
            setError(t('Cannot add work function relation'));
        }

        loading.stop();
    }

    //remove Work Function
    const removeWorkFunctionHasWorker = async (workFunction: IWorkFunction, worker: IWorker) => {
        loading.start('work functions');

        const resp = await Actions.removeWorkFunctionHasWorker(props.workId, workFunction.id, worker.id);
        if (!resp.hasValue) {
            setError(t('Cannot remove work function relation'));
        }
        loading.stop();
    }

    const associateExistingUser = loading.wrap(async (worker: IWorker, user: IUserIdentity) => {
        await associateAppUserToWorker(props.workId, worker.id, {
            id: user.id,
            userName: user.userName,
        });
        await props.onChange?.();
    });

    const requestEditUser = loading.wrap(async (appUserId: number) => {
        const user = await getUserById(appUserId);
        setEditAccess(user);
        setFormType(FormType.AppUser);
    });

    const saveAppUser = loading.wrap(async (data: IUserIdentity) => {
        setFormType(FormType.Edit);
    });


    const cancelAppUserEdit = () => {
        setEditAccess(undefined);
        setFormType(FormType.Edit);
    }

    const onSystemUserCreated = () => {
        createAppUserDialog.clear();
        props.onChange?.();
    }

    return <div>
        <div className='r'>
            {formType === FormType.AppUser && editAccess &&
                <UserFormContainer
                    data={editAccess}
                    footer={<div className='r r-end'>
                        <button onClick={cancelAppUserEdit}>{t('Cancel')}</button>
                        <button type='submit' className='primary'>{t('Save')}</button>
                    </div>}
                    onSubmit={saveAppUser} />}

            {createAppUserDialog.render((w: IWorker) =>
                <RenderCreateAppUserRelation
                    onCreated={onSystemUserCreated}
                    worker={w}
                    role={'worker'}
                    workId={props.workId} />)}

            {formType === FormType.Edit && <form onSubmit={form.handleSubmit} className='e'>
                <div className='r'>
                    <div className='md pd form-1 l200 e'>
                        <G label={t('Name')}>
                            {form.input('name', { autoFocus: true })}
                        </G>
                        <G label={t('Surname')}>
                            {form.input('surname')}
                        </G>
                        <G label={t('License number')}>
                            {form.input('code')}
                        </G>
                        <G label={t('Phone')}>
                            {form.input('phone')}
                        </G>
                        <G label={t('Remarks')}>
                            {form.textarea('remarks')}
                        </G>
                        <DynamicProperties
                            className='e sm mr-left'
                            object={form.values}
                            onChange={props => form.setFieldValue('properties', props)}
                            isInternal={true}
                            objectType={PropertyGroupObjectType.JobHasWorker}
                            propertyGroupTypes={props.propertyGroupTypes} />
                    </div>

                    {props.data?.id != undefined &&
                        <TabView className='no-padding slim'>
                            <TabPanel header={t('Workspaces')}>
                                <ManageManyToManyRelations
                                    error={error}
                                    source={props.data}
                                    columns={[
                                        'name'
                                    ]}
                                    onAdd={(d: IWorkspace) => addWorkerHasWorkspace(d, props.data!)}
                                    onRemove={(d: IWorkspace) => removeWorkerHasWorkspace(d, props.data!)}
                                    loading={loading.isLoading('workspaces')}
                                    loadTargets={async () => {
                                        const resp = await Actions.getWorkspaces(props.workId);
                                        return resp.data.work.workspaces;
                                    }}
                                    loadRelations={() => Actions.getWorkerHasWorkspaces(props.workId, props.data!.id)} />
                            </TabPanel>
                            <TabPanel header={t('Departaments')}>
                                <ManageManyToManyRelations
                                    error={error}
                                    source={props.data}
                                    columns={[
                                        'name'
                                    ]}
                                    onAdd={(d: IDepartment) => addDepartmentHasWorker(d, props.data!)}
                                    onRemove={(d: IDepartment) => removeDepartmentHasWorker(d, props.data!)}
                                    loading={loading.isLoading('departments')}
                                    loadTargets={async () => {
                                        const resp = await Actions.getDepartments(props.workId);
                                        return resp.data.work.departments;
                                    }}
                                    loadRelations={() => Actions.getWorkerDepartments(props.workId, props.data!.id)} />
                            </TabPanel>
                            <TabPanel header={t('Functions')}>
                                <ManageManyToManyRelations
                                    error={error}
                                    source={props.data!}
                                    columns={[
                                        'name'
                                    ]}
                                    onAdd={(w: IWorkFunction) => addWorkFunctionHasWorker(w, props.data!)}
                                    onRemove={(w: IWorkFunction) => removeWorkFunctionHasWorker(w, props.data!)}
                                    loading={loading.isLoading('work_functions')}
                                    loadTargets={async () => {
                                        const resp = await Actions.getWorkFunctions(props.workId);
                                        return resp.data.work.work_functions;
                                    }}
                                    loadRelations={() => Actions.getWorkerWorkFunctions(props.workId, props.data!.id)} />
                            </TabPanel>
                            <TabPanel header={t('Policies')}>
                                <ManagePolicies
                                    source={policies}
                                    addPolicy={addPolicyToAppUser}
                                    removePolicy={RemovePolicyToAppUser}
                                    hasAssociatedUser={props.data.appUserId} />
                            </TabPanel>
                            <TabPanel header={t('Access')}>
                                {props.data?.appUser != undefined &&
                                    <div className='c'>
                                        <div className='sm pd r center v-center g-20'>
                                            <i className='far fa-envelope'></i>
                                            <strong>{props.data?.userEmail}</strong>
                                        </div>
                                        <div className='border-top r r-end sm pd'>
                                            <i
                                                className='pi pi-pencil pointer'
                                                onClick={_ => requestEditUser(props.data!.appUserId!)}></i>
                                        </div>
                                    </div>}
                                {props.data?.appUser == undefined &&
                                    <>
                                        <div className='r center'>
                                            <span className='mutted'>
                                                {t('Associate app user')}
                                            </span>
                                        </div>
                                        <ManageRelations
                                            columns={[
                                                { field: 'userName', title: t('User') },
                                                { field: 'email', title: t('Email') }
                                            ]}
                                            createText={t('Create new user')}
                                            load={() => getUsers().then(r => r.data.users)}
                                            onRequestCreate={(w: IWorker) => {
                                                appUsersDialog.clear();
                                                createAppUserDialog.showEdit(w);
                                            }}
                                            onSelect={(user: IUserIdentity) => {
                                                associateExistingUser(props.data!, user);
                                            }}
                                            source={props.data!} />
                                    </>}
                            </TabPanel>
                        </TabView>}
                </div>
                {formType === FormType.Edit && props.footer != undefined && props.footer}
            </form>}
        </div>
        <div className='errors-container'>
            {form.errorBox()}
            <NotificationsBox notifications={props.notifications} />
        </div>
    </div>
}