import * as React from 'react';

import { InputSwitch, Message, classNames, useTranslation, R, Tooltip, Portal, Select } from '@components';
import { IPropertyGroup, IPropertyGroupType, IPropertyType, PropertyGroupObjectType, PropertyTypes } from '@models/works';
import { useObjectState } from '@utils/hooks';

import './DynamicProperties.scss';

export interface IProps {
    className?: string;
    embedPortal?: string;
    isInternal?: boolean;
    inline?: boolean;
    labelClass?: string;
    object: any;
    objectType: PropertyGroupObjectType;
    onChange?: (props: IPropertyGroup[]) => void;
    propertyGroupTypes: IPropertyGroupType[];
    readonly?: boolean;
    style?: any;
    hideContractorProperties?: boolean;
}

function DynamicProperty({ labelClass, object, onChange, property, readonly, value }: {
    labelClass?: string,
    object: any,
    onChange: Function,
    property: IPropertyType,
    readonly?: boolean,
    value: any
}) {
    const { t } = useTranslation();

    const tooltipKey = property.name + '_tooltip';

    const renderTooltip = () => {
        if (property.help) {
            return <>
                <Tooltip target={'.' + tooltipKey} mouseTrack mouseTrackLeft={10} />
                <i
                    data-pr-tooltip={property.help}
                    className={classNames('fas fa-info', tooltipKey)} />
            </>
        }
        else {
            return null;
        }
    }

    if (property.typeId === PropertyTypes.BOOL) {
        return <div className='r bool-property'>
            <InputSwitch
                disabled={readonly}
                inputId={property.name}
                checked={value?.[property.name] ?? false}
                onChange={e => onChange({ ...value, [property.name]: e.value })} />
            <label className={labelClass} htmlFor={property.name}>{t(property.title)}</label>
            <span className='e' />
            {renderTooltip()}
        </div>;
    }
    else if (property.typeId === PropertyTypes.TEXT) {
        return <R label={t(property.title)} labelClassName={labelClass} className={'text-property'}>
            <input
                type='text'
                placeholder={t(property.placeholder || property.title)}
                readOnly={readonly}
                value={value?.[property.name] ?? ''}
                onChange={e => onChange({ ...value, [property.name]: e.target.value })}
            />
            <span className='e' />
            {renderTooltip()}
        </R>;
    }
    else if (property.typeId ===  PropertyTypes.TEXTAREA) {
        return <R label={t(property.title)} labelClassName={labelClass} className={'textarea-property'}>
            <textarea
                placeholder={t(property.placeholder || property.title)}
                readOnly={readonly}
                value={value?.[property.name] ?? ''}
                onChange={e => onChange({ ...value, [property.name]: e.target.value })}
            />
            <span className='e' />
            {renderTooltip()}
        </R>;
    }
    else if (property.typeId === PropertyTypes.BOOLTEXT) {
        const textPropertyName = property.name + '_text';

        return <div className='r booltext-property'>
            <InputSwitch
                disabled={readonly}
                inputId={property.name}
                checked={value?.[property.name]}
                onChange={e => onChange({ ...value, [property.name]: e.value })} />
            <label className={labelClass} htmlFor={property.name}>{t(property.title)}</label>
            {value?.[property.name] &&
                <input
                    type='text'
                    readOnly={readonly}
                    placeholder={property.placeholder
                        ? t(property.placeholder)
                        : t(property.title)}
                    value={value[textPropertyName] ?? ''}
                    onChange={e => onChange({ ...value, [textPropertyName]: e.target.value })}
                    style={{ width: "50%" }} />}
            <span className='e' />
            {renderTooltip()}
        </div>;
    }
    if (property.typeId === PropertyTypes.SELECT) {
        return <div className='r'>
            <Select
                options={property.selectOptions!.split(',')}
                disabled={readonly}
                inputId={property.name}
                value={value?.[property.name]}
                onChange={e => onChange({ ...value, [property.name]: e.value })} />
            <label className={labelClass} htmlFor={property.name}>{t(property.title)}</label>
            <span className='e' />
            {renderTooltip()}
        </div>;
    }
    else {
        return <Message
            severity={'error'}
            text={`Not implemented: ${property.typeId}`} />
    }
}

const parseProperty = (prop: IPropertyGroup) => {
    if (prop && !prop.valueObj) {
        prop.valueObj = JSON.parse(prop.value);
    }
    return prop;
}

const findProperty = (object: any, groupId: number) => {
    return parseProperty(
        object.properties?.find((p: IPropertyGroup) => p.propertyGroupTypeId == groupId));
}

function DynamicPropertiesGroup({
    groupClassName,
    isInternal,
    group,
    labelClass,
    object,
    objectType,
    onChange,
    readonly,
    style,
}: {
    groupClassName?: string,
    isInternal?: boolean,
    group: IPropertyGroupType,
    labelClass?: string,
    object: any, objectType: PropertyGroupObjectType, onChange?: Function,
    readonly?: boolean, style?: any,
}) {
    const { t } = useTranslation();

    const objectRelationProperty =
        objectType == PropertyGroupObjectType.Job
            ? 'jobId'
            : objectType == PropertyGroupObjectType.Contractor
                ? 'contractorId'
                : objectType == PropertyGroupObjectType.JobHasContractor
                    ? (object.contractorId ? 'jobHasContractorId' : 'contractorId')
                    : objectType == PropertyGroupObjectType.Machinery
                        ? 'machineryId'
                        : objectType == PropertyGroupObjectType.JobHasMachinery
                            ? (object.machineryId ? 'jobHasMachineryId' : 'machineryId')
                            : objectType == PropertyGroupObjectType.JobHasContractorRequest
                                ? 'jobHasContractorRequestId'
                                : objectType == PropertyGroupObjectType.Workspace
                                    ? 'workspaceId'
                                    : objectType == PropertyGroupObjectType.JobHasWorker
                                        ? (object.workerId ? 'jobHasWorkerId' : 'workerId')
                                        : 'unknown';

    const findPropertyByGroup = (id: number) => {
        return findProperty(object, id) ?? {
            [objectRelationProperty]: object.id,
            propertyGroupTypeId: id,
            valueObj: {},
            isInternal: isInternal,
        };
    }

    const data = useObjectState(
        findPropertyByGroup(group.id!), {
        onChange
    });

    React.useEffect(() => {
        data.reset(findPropertyByGroup(group.id!));
    }, [object]);

    const containerClassName = group.containerType === 'fluid'
        ? 'r properties'
        : group.containerType === 'column'
            ? 'c properties'
            : group.containerType === 'embed'
                ? 'e properties'
                : 'grid-2 properties';

    return <div
        className={classNames(group.styleClassName, groupClassName ?? 'Properties')}
        style={{ ...group.styleObj, ...style }}>
        {group.containerType != 'embed' && <h2>{t(group.title)}</h2>}
        <div className={containerClassName}>
            {group.properties?.
                sort((a, b) => (a.propertyOrder ?? 1110) - (b.propertyOrder ?? 1110)).
                map((p, i) =>
                    <DynamicProperty
                        key={i}
                        labelClass={labelClass}
                        object={object}
                        property={p}
                        readonly={readonly}
                        value={data.field('valueObj')}
                        onChange={data.set('valueObj')} />)}
        </div>
    </div>
}

export function DynamicProperties(props: IProps) {
    const doOnChange = (propertyGroup: IPropertyGroup) => {
        const containsGroup = props
            .object
            .properties?.find((p: IPropertyGroup) =>
                p.propertyGroupTypeId === propertyGroup.propertyGroupTypeId) != undefined;

        const groups = containsGroup
            ? props.object.properties?.map((p: IPropertyGroup) =>
                p.propertyGroupTypeId == propertyGroup.propertyGroupTypeId
                    ? propertyGroup
                    : p)
            : (props.object.properties ?? []).concat([propertyGroup]);

        props.onChange && props.onChange(groups);
    }

    return <div className={classNames(props.className, 'DynamicProperties')} style={props.style}>
        {props.propertyGroupTypes
            .filter(g => props.isInternal == undefined || g.isInternal == props.isInternal)
            .map((p, i) => {
                if (props.hideContractorProperties != undefined ? props.hideContractorProperties && p.hidable : p.hidable) {
                    return null;
                }
                if (p.containerType === 'embed' && !props.inline) {
                    return <Portal container={props.embedPortal ?? '#embed-properties'} key={p.id}>
                        <DynamicPropertiesGroup
                            key={i}
                            groupClassName={'EmbedProperties'}
                            isInternal={props.isInternal}
                            labelClass={props.labelClass}
                            group={p}
                            object={props.object}
                            objectType={props.objectType}
                            onChange={doOnChange}
                            readonly={props.readonly} />
                    </Portal>
                } else {
                    return <DynamicPropertiesGroup
                        key={i}
                        groupClassName={p.containerType === 'embed' ? 'EmbedProperties' : undefined}
                        isInternal={props.isInternal}
                        labelClass={props.labelClass}
                        group={p}
                        object={props.object}
                        objectType={props.objectType}
                        onChange={doOnChange}
                        readonly={props.readonly} />;
                }
            })}
    </div>
}
