import * as React from 'react';

import { classNames } from 'primereact/utils';

export interface IProps {
    className?: string;
    suffix: string;
    children: any;
}

export function InputGroup(props: IProps) {
    return <div className={classNames('p-inputgroup', props.className)}>
        {props.children}
        {props.suffix &&
            <span className='p-inputgroup-addon'>
                <i className={'pi pi-' + props.suffix} />
            </span>}
    </div>
}