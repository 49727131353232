import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';

import { AdminWorkRequirements } from '@components/admin/AdminWorkRequirements';

export default connect(
    (state: ApplicationState) => ({
        ...Admin.plainActions,
        requirementExpirationTypes: state.constants.requirementExpirationTypes,
        users: state.admin.users,
    }),
    Admin.actionCreators,
)(AdminWorkRequirements as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
