import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as User from '../../store/user';

import { WorkLoader } from '../../components/user/WorkLoader';

export default connect(
    (state: ApplicationState) => ({
        work: state.user.work,
        moduleManager: state.modules.moduleManager,
    }),
    User.actionCreators,
)(WorkLoader as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
