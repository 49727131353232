import {
    IContractorInvitation, IContractorInvitationRegisterData, IJobHasContractor, IPaginateOptions,
    ISubContractorRequest, ISubMainContractorRequest
} from '../../models';
import { m, q } from './graphql';
import { IContractor } from '@models/resources';
import { ensureType, formatProperty } from './utils';
import { IUserFormData } from '@components/admin/UserForm';
import { search } from './search';

export async function createContractorInvitation(workId: number, data: IContractorInvitation) {
    const resp = await m(`mutation CreateContractorInvitation($workId: Int!, $data: ContractorInvitationGraphInputType!) {
        sendContractorInvitation(workId: $workId, invitation: $data) {
            value, hasValue, error
        }
    }`, { workId, data });

    return resp?.data.sendContractorInvitation;
}

export async function registerContractorInvitation(data: IContractorInvitationRegisterData) {
    const inputData = {
        ...data,
        properties: data.properties.map(formatProperty(data.workId))
    };

    return await m(`mutation RegisterContractorInvitation($data: ContractorInvitationRegisterDataGraphInputType!) {
        registerContractorInvitation(data: $data) {
            value, hasValue, error
        }
    }`, { data: inputData }, { returnPath: 'registerContractorInvitation' });
}

export async function getWorkConstants(workId: number) {
    return await q(`query GetWorkConstants($workId: Int!) {
        readonlyWork(id: $workId) {
            activityTypes { id, name },
            legalForms {
                id, name, hideContractorProperties, workerTypes{
                    id, workerType{
                        id, name
                    }
                }
            },
            countries {
                id, name, code,
                regions {
                    id, name,
                    counties {
                        id, name, code
                    }
                }
            },
            workSettings {
                acceptSubContractorRequests, allowAlwaysNotifyInformationLoaded,
                contractorHiddenFields, jobEncodingPrefix,
                jobHiddenFields, machineryHiddenFields, mainContractorCanFulfillSubs,
                rememberSubContractorAcceptances, workerHiddenFields
            },
        }
    }`, { workId }, { returnPath: 'readonlyWork' });
}

export function getContractorInvitations(workId: number) {
    const result = q(`query GetContractorInvitations($workId: Int!) {
        work(id: $workId) {
            contractorInvitations {
                id, createdBy, dateTime,
                email, subject, accepted, acceptedDate, contractorId,
                startDate, jobsDescription, shipmentNumber, name, isActive
            }
        }
    }`, { workId }, {
        returnPath: 'work.contractorInvitations',
    });

    return result;
}

export async function getContractorInvitation(token: string) {
    const resp = await q(`query GetContractorInvitation($token: String!) {
        contractorInvitation(token: $token) {
            id, workId, jobId, email,
            startDate, jobsDescription, shipmentNumber, name,
            parentJobHasContractorId, isActive,
            propertyGroupTypes {
                id, name, title, styleClassName, description, containerType, style, objectType, mandatory, hidable,
                properties {
                    id, name, title, typeId, placeholder, help, parentId, propertyOrder
                }
            },
        }
    }`, { token });

    return resp?.data.contractorInvitation;
}

export async function getContractors(workId: number, opts: Partial<IPaginateOptions> = {}) {
    const paginateOpts = {
        page: 0,
        limit: 10,
        ...opts,
    };
    return q(`query GetContractors($workId: Int!, $paginateOpts: PaginateOptionsGraphInputType!) {
        work(id: $workId) {
            paginateContractors(options: $paginateOpts) {
                page, total, limit,
                data {
                    id, name, code, legalFormId, activityTypeId, address,
                    postalCode, countryId, regionId, countyId,
                    currentStatusType, currentStatusDate, forcedStatusUntilDate,
                    providerCode, phone,
                    properties {
                        id, propertyGroupTypeId, jobHasContractorId, value
                    }
                }
            }
        }
    }`, { workId, paginateOpts }, { returnPath: 'work.paginateContractors' });
}

export async function getContractorFromAppUser(workId: number) {
    return q(`query GetContractorFromAppUser($workId: Int!) {
        work(id: $workId) {
            contractorFromAppUser
            {
                id, name, code, phone, remarks, address, regionId, countyId, countryId, postalCode,
                workId, legalFormId, activityTypeId, otherActivityType
                currentStatusType, currentStatusDate, providerCode, city, contactPerson
                properties {
                    id, propertyGroupTypeId, contractorId, jobHasContractorId, value
                }
            }
        }
    }`, { workId }, { returnPath: 'work.contractorFromAppUser' });
}

export async function getJobHasContractorFromAppUser(workId: number, jobId: number) {
    const searchName = 'contractors/currentJobHasContractor';
    const filters = { jobId };

    const res = await search<IJobHasContractor>(workId, searchName, {
        normalizeKeys: true,
        filters,
    });

    return res.value[0];
}

export async function getJobHasContractors(workId: number, jobId: number) {

    const searchName = 'contractors/currentJobHasContractor';
    const filters = { jobId };

    const res = await search<IJobHasContractor>(workId, searchName, {
        normalizeKeys: true,
        filters,
    });

    return res;
}

export async function getJobHasContractorFromId(workId: number, id: number) {

    const searchName = 'contractors/jobHasContractor';
    const filters = { id };

    const res = await search<IJobHasContractor>(workId, searchName, {
        normalizeKeys: true,
        filters,
    });

    return res.value[0] ?? undefined;
}

export async function getContractor(workId: number, id: number) {
    return q(`query GetContractor($workId: Int!, $id: Int!) {
        work(id: $workId) {
            contractor(id: $id) {
                id, name, code, legalFormId, activityTypeId, otherActivityType, address,
                postalCode, countryId, regionId, countyId, remarks,
                currentStatusType, currentStatusDate, forcedStatusUntilDate, phone, providerCode, city, contactPerson, creationDate
                properties {
                    id, propertyGroupTypeId, contractorId, jobHasContractorId, value
                }
            }
        }
    }`,
        { workId, id },
        { returnPath: 'work.contractor' });
}

export async function saveContractor(workId: number, rawData: IContractor) {
    const data = ensureType<IContractor>({
        ...rawData,
        properties: (rawData.properties ?? []).map(formatProperty(workId)),
    }, {
        exclude: ['currentStatusType', 'currentStatusDate'],
    });

    return m(`
mutation SaveContractor($data: ContractorInputType!) {
    saveContractor(contractor: $data) {
        isError, error, value, hasValue
    }
}
    `, { data }, { returnPath: 'saveContractor' });
}

export async function getSubContractorRequestsAsWorker(workId: number) {
    return q(`query GetSubContractorRequests($workId: Int!) {
        work(id: $workId) {
            subContractorRequests {
                id, workId,
                sourceContractor { id, name, code },
                targetContractor { id, name, code },
                jobsDescription, shipmentNumber, dateTime,
                acceptedByContractor, acceptedByContractorDateTime,
                acceptedByWork, acceptedByContractorDateTime,
                rejected, rejectedDateTime, rejectedReason,
                job { id, name, code, departments { departmentId, responsibleId, isMain } }
            }
        }
    }`, { workId }, { returnPath: 'work.subContractorRequests' });
}

export async function getSubContractorRequestsAsTarget(workId: number) {
    return q(`query GetSubContractorRequestsAsTarget($workId: Int!) {
        work(id: $workId) {
            targetSubContractorRequests {
                id, workId,
                sourceContractor { id, name, code },
                targetContractor { id, name, code },
                jobsDescription, shipmentNumber, dateTime,
                acceptedByContractor, acceptedByContractorDateTime,
                acceptedByWork, acceptedByContractorDateTime,
                rejected, rejectedDateTime, rejectedReason,
                job { id, name, code }
            }
        }
    }`, { workId }, { returnPath: 'work.targetSubContractorRequests' });
}

export async function saveSubContractorRequest(workId: number, inputRequest: ISubContractorRequest) {
    const request = {
        ...inputRequest,
        properties: (inputRequest.properties ?? []).map(formatProperty(workId))
    };

    return m(`mutation SaveSubContractorRequest($workId: Int!, $request: SubContractorRequestGraphInputType!) {
        saveSubContractorRequest(workId: $workId, request: $request) {
            hasValue, value, error
        }
    }`, { workId, request }, {
        returnPath: 'saveSubContractorRequest'
    });
}

export async function saveSubMainContractorRequest(workId: number, request: ISubMainContractorRequest) {
    return m(`mutation SaveSubMainContractorRequest($workId: Int!, $request: SubMainContractorRequestGraphInputType!) {
        saveSubMainContractorRequest(workId: $workId, request: $request) {
            hasValue, value, error
        }
    }`, { workId, request }, {
        returnPath: 'saveSubMainContractorRequest'
    });
}

export async function acceptSubContractorRequest(workId: number, subContractorRequestId: number, reason: string | undefined = undefined) {
    return m(`mutation AcceptSubContractorRequest($workId: Int!, $subContractorRequestId: Int!, $reason: String) {
        acceptSubContractorRequest(workId: $workId, subContractorRequestId: $subContractorRequestId, reason: $reason) {
            hasValue, value, error
        }
    }`, { workId, subContractorRequestId, reason }, {
        returnPath: 'acceptSubContractorRequest'
    });
}

export async function rejectSubContractorRequest(workId: number, subContractorRequestId: number, reason: string | undefined = undefined) {
    return m(`mutation RejectSubContractorRequest($workId: Int!, $subContractorRequestId: Int!, $reason: String) {
        rejectSubContractorRequest(workId: $workId, subContractorRequestId: $subContractorRequestId, reason: $reason) {
            hasValue, value, error
        }
    }`, { workId, subContractorRequestId, reason }, {
        returnPath: 'rejectSubContractorRequest'
    });
}

export async function removeContractorAppUser(workId: number, userId: number, contractorId: number) {
    const resp = await m(`mutation RemoveContractorAppUser($workId: Int!, $userId: Int!, $contractorId: Int!) {
        removeContractorAppUser(workId: $workId, userId: $userId, contractorId: $contractorId) {
            hasValue, value, error
        }
    }`, { workId, userId, contractorId });

    return resp?.data.removeContractorAppUser;
}

export async function createWorkContractorAppUser(workId: number, contractorId: number, data: IUserFormData) {
    return await m(`mutation CreateWorkContractorAppUser($workId: Int!, $contractorId: Int!, $data: UserInputType!) {
        createWorkContractorAppUser(workId: $workId, contractorId: $contractorId, data: $data) {
            hasValue, value, error
        }
    }`, { workId, contractorId, data }, { returnPath: 'createWorkContractorAppUser' });
}

export async function getWorkUsers(workId: number) {
    const resp = await q(`query GetWorkUsers($workId: Int!) {
        work(id: $workId) {
            users {
                id, userName, email
            }
        }
    }`, { workId });

    return resp.data.work.users;
}

// GET CONTRACTOR_HAS_APP_USERS => workId, contractorId
export async function getContractorAppUsers(workId: number, contractorId: number) {
    const resp = await q(`query GetContractorAppUsers($workId: Int!, $contractorId: Int!) {
        work(id: $workId) {
            contractor(id: $contractorId) {
                appUsers {
                    id, userName, email
                }
            }
        }
    }`, { workId, contractorId });

    return resp.data.work.contractor.appUsers;
}

export async function invalidateContractor(workId: number, contractorId: number) {
    return m(`mutation InvalidateContractor($workId: Int!, $contractorId: Int!) {
        invalidateContractor(workId: $workId, id: $contractorId) {
            hasValue, value, error
        }
    }`, { workId, contractorId }, { returnPath: 'invalidateContractor' });
}

export function reactivateContractor(workId: number, id: number) {
    return m(`
mutation ReactivateContractor($workId: Int!, $id: Int!) {
    reactivateContractor(workId: $workId, id: $id) {
        isError, error, value
    }
}
    `, { workId, id }, { returnPath: 'reactivateContractor' });
}

export function restrictContractor(workId: number, name: string, code: string, legalFormId: number, providerCode: string, contractorContactPerson: string, contractorEmail: string, contractorPhone: string, contractorId: number, applicantName: string, applicantEmail: string, reason: string, remarks: string) {
    return m(`
mutation RestrictContractor($workId: Int!, $name: String!, $code: String!, $legalFormId: Int!, $providerCode: String!, $contractorContactPerson: String!, $contractorEmail: String!, $contractorPhone: String!, $contractorId: Int!,$applicantName: String!, $applicantEmail: String!, $reason: String!, $remarks: String!) {
    restrictContractor(workId: $workId, name: $name, code: $code, legalFormId: $legalFormId, providerCode: $providerCode, contractorContactPerson: $contractorContactPerson, contractorEmail: $contractorEmail, contractorPhone: $contractorPhone, contractorId: $contractorId, applicantName: $applicantName, applicantEmail: $applicantEmail, reason: $reason, remarks: $remarks) {
        isError, error, value
    }
}
    `, { workId, name, code, legalFormId, providerCode, contractorContactPerson, contractorEmail, contractorPhone, contractorId, applicantName, applicantEmail, reason, remarks }, { returnPath: 'restrictContractor' });
}

export function removeRestrictedContractor(workId: number, id: number) {
    return m(`
mutation RemoveRestrictedContractor($workId: Int!, $id: Int!) {
    removeRestrictedContractor(workId: $workId, id: $id) {
        isError, error, value
    }
}
    `, { workId, id }, { returnPath: 'removeRestrictedContractor' });
}
