import * as React from 'react';
import { connect } from 'react-redux';
import LoginPanel from '@containers/auth/LoginContainer';
import { useTranslation } from '@components';

import './Home.scss';

const Images = () => {
    return <img src='/img/login_5.png' alt='gstore'/>;
}

const Bottom = () => {
    return <img src='/img/ilustracion.svg' alt='gstore'/>;
}



export default function Home(){
    const { t } = useTranslation();
    {document.getElementById('container')!.className = "height"}
    {document.getElementById('root')!.className = "clip"}
    return <div id='home'>
        <div className='r top'>
            <Images/>
            <LoginPanel style={{paddingBottom: '40px'}}/>
        </div>
        <div className='middle'>
            <span>{t('home.text')}</span>
            <img src='/img/login_4.png' alt="gstore" />
        </div>
        <div className='r bottom'>
            <Bottom />
        </div>
    </div>
}
