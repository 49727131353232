import * as React from 'react';

import { CreateWorkContractorAppUser } from './CreateWorkContractorAppUser';
import { useConfirm, useDataTable, useFormDialog, useLoading, useRemoteData, useTranslation } from '@components';
import { ManageRelations } from '@components/common/ManageRelations';
import { IContractor, ISecurity, IUserIdentity, IWork } from '@models';

type GetContractorF = (workId: number, id: number) => Promise<IContractor>;

export interface IProps {
    contractor: IContractor;
    createWorkContractorAppUser: Function;
    getWorkUsers: Function;
    getContractorAppUsers: Function;
    hasPermission: Function;
    work: IWork;
    removeContractorAppUser: Function;
    contractorId: number;
    getContractor: GetContractorF;
    security: ISecurity;
}

function ContractorAccessDataForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const [users, setUsers] = React.useState<IUserIdentity[]>([]);
    const [data, setData] = React.useState<IUserIdentity[]>([]);

    const doRemoveAppUserRelation = loading.wrap(async (rel: IUserIdentity) => {
        await props.removeContractorAppUser(
            props.work.id,
            rel.id,
            props.contractorId
        );
        await initialize();
    });

    const initialize = loading.wrap(async () => {
        const userResp = await props.getWorkUsers(props.work.id);
        setUsers(userResp);
        const resp = await props.getContractorAppUsers(
            props.work.id,
            props.contractor.id);
        setData(resp);
        setUsers(userResp);
    });

    const appUsersDialog = useFormDialog({
        addTitle: t('Associate app user')
    });

    const createAppUser = useFormDialog({
        addTitle: t('Create app user'),
    });

    const confirmRemove = useConfirm({
        message: t('Are you sure to remove the app user associated with this contractor?'),
        accept: doRemoveAppUserRelation,
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const dataTable = useDataTable({
        columns: [
            {title: 'Username', render: (d: IUserIdentity) => d.userName},
            {title: 'Email', render: (d: IUserIdentity) => d.email},
        ],
        actions: [
            (props.security.isGestor())
            ?   {icon: 'trash', onClick: confirmRemove, tooltip: t('Remove app user relation')}
            :   undefined
        ],
        data
    })

    const renderAppUserRelations = () => {
        return <ManageRelations
            columns={[{field: 'userName'}, {field:'email'}]}
            createText={t('Create new user')}
            load={() => users}
            onRequestCreate={(w: IContractor) => {
                appUsersDialog.clear();
                createAppUser.showAdd();
                initialize();
            }}
            onSelect={async (user: IUserIdentity) => {
                appUsersDialog.clear();
                await associateAppUser(user);
                initialize();
            }}
            source={props.contractor}/>
    }

    const associateAppUser = async (user: IUserIdentity|number) => {
        const appUserId = typeof(user) === 'number' ? user : user.id;
        const resp = await props.createWorkContractorAppUser(
            props.work.id,
            props.contractorId,
            {
                id: appUserId,
            }
        )
        appUsersDialog.clear();
        await initialize();
    }

    const renderCreateAppUser = () => {
        return <CreateWorkContractorAppUser
            createWorkContractorAppUser={props.createWorkContractorAppUser}
            contractor={props.contractor}
            onCancel={() => createAppUser.clear()}
            onSuccess={(_: any) => initialize() && createAppUser.clear()}
            work={props.work} />
    }

    return <div className='he c'>
        <div className='form-header'>
            {t('Edit contractor access data')}
        </div>
        <div className='r'>
            {loading.renderBox()}
            {dataTable()}
            {appUsersDialog.render(renderAppUserRelations)}
            {createAppUser.render(renderCreateAppUser)}
        </div>
        <span className='e' />
        {props.security.hasPermission('contractors.access.create') &&
            <div className='r sm pd-btm pd-right'>
                <span className='e' />
                <div className='p-buttonset'>
                    <button className='primary' onClick={() => appUsersDialog.showAdd()}>{t('Associate app user')}</button>
                </div>
            </div>
        }
    </div>
}

export function WorkContractorAccessData(props: IProps) {
    const data = useRemoteData<IContractor>(props.getContractor, {
            parameters: [props.work.id, props.contractorId]
        });
    return <div>
        {data.renderLoading()}
        {data.value && <ContractorAccessDataForm
            {...props}
            contractor={data.value} />}
    </div>
}