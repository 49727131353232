import * as React from 'react';

import { useResolveName, useSearch, useTranslation, Timeline, classNames } from '@components';
import { DocumentLogType, IDocumentLog, documentLogTypes } from '@models/documents';
import DateUtils from '@utils/date-utils';
import { IWork } from '@models';

export interface IProps {
    workId: number;
    documentId: number;
    work: IWork;
}

type SearchRow = IDocumentLog & { userName: string };

export function DocumentLogs(props: IProps) {
    const { t } = useTranslation();
    const resolveLogType = useResolveName(documentLogTypes);

    const filters = {
        documentId: props.documentId
    };
    const data = useSearch<SearchRow>({
        search: 'documents.logs',
        workId: props.workId,
        normalizeKeys: true,
        filters,
    });

    const icons: { [key: number]: string | undefined } = {
        [DocumentLogType.UPDATE]: 'pi pi-pencil',
        [DocumentLogType.CREATE]: 'pi pi-info-circle',
        [DocumentLogType.UPDATE_FILE]: 'pi pi-upload',
        [DocumentLogType.VIEW]: 'pi pi-download',
        [DocumentLogType.CREATE_FILE]: 'pi pi-file',
    }

    const getIcon = (r: SearchRow) => {
        return icons[r.documentLogTypeId] ?? 'pi pi-question';
    }

    const documentLog2Event = (r: SearchRow) => ({
        id: r.id,
        date: r.dateTime,
        className: 'danger',
        icon: getIcon(r),
        status: r.documentLogTypeId,
        eventName: resolveLogType(r.documentLogTypeId),
        appUserId: r.userId,
        kind: 'documentLog',
        userName: r.userName,
    });

    const showEvent = (r: SearchRow) =>
        [
            DocumentLogType.CREATE,
            DocumentLogType.UPDATE,
            DocumentLogType.CREATE_FILE,
            DocumentLogType.UPDATE_FILE,
        ]
        .includes(r.documentLogTypeId);

    const logEvents = (data.value ?? []).filter(showEvent).map(documentLog2Event);

    return <div className={'lg pd we'}>
        {data.renderLoading()}
        <Timeline
            opposite={item => <>
                <strong>{item.eventName}</strong>
                {item.leftIcon && <i className={classNames(item.leftIcon, 'sm mr-right')} />}
                {item.displayDate &&
                    <span className='sm pd-left mutted'>{DateUtils.format(item.displayDate)}</span>}
            </>}
            content={item => <>
                <small className='p-text-secondary'>{DateUtils.formatDateTimeFromUtc(item.date)}</small>
                <span className='lg pd'>{item.userName}</span>
            </>}
            marker={(item) => <i className={item.icon} />}
            value={logEvents} />
    </div>
}