import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import { ViewContractorInvitations } from '@components/contractors/ViewContractorInvitations';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...ContractorsStore.plainActions,
        moduleManager: state.modules.moduleManager,
    }),
    {}
)(ViewContractorInvitations as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
