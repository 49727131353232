import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as DocumentActions from '../../store/actions/documents';
import * as RequirementsStore from '../../store/requirements';
import * as NotificationsStore from '@store/notifications';
import { ValidateAllRequirements } from '../../components/requirements/ValidateAllRequirements';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...DocumentActions,
        ...RequirementsStore.plainActions,
        requirementGroups: state.user.requirementGroups,
        requirementStatusTypes: state.user.requirementStatusTypes,
        workId: state.work.work?.id,
    }),
    {
        ...NotificationsStore.actionCreators,
    },
)(ValidateAllRequirements as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
