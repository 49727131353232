import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '@store/contractors';
import { EditContractorForm } from '@components/contractors/EditContractorForm';
import { isJobHasContractorPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState, props: any) => ({
        workId: props.workId ?? parseInt(props.match.params.workid),
        contractorId: props.contractorId ?? parseInt(props.match.params.contractorid),
        work: state.user.work,
        ...state.identity,
        ...state.user,
        ...ContractorsStore.plainActions,
        modules: state.modules.moduleManager,
        resourceStatusTypes: state.user.resourceStatusTypes,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isJobHasContractorPropertyGroupType),
        constants: state.constants,
    }),
    ContractorsStore.actionCreators,
)(EditContractorForm as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
