import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { JobHasResourceRequirements } from '@components/jobs/JobHasResourceRequirements';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        ...state.identity,
        ...state.user,
        workSettings: state.user.work?.settingsObj,
    }),
    JobsStore.actionCreators,
)(JobHasResourceRequirements as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
