import * as React from 'react';

type Predicate = (source: any, ctx: any) => boolean;

interface Signal {
    field: string;
    source: string;
    type: 'show' | 'none';
    when: Predicate;
}

const parsePredicate = (source: string|undefined) => {
    if (source) {
        const body = 'const a = ' + source + '; return a;';
        const fn = new Function('value', 'ctx', body);
        return fn;
    }
    else {
        return null;
    }
}

const createSources = (source: string, ctx: string) => {
    const signals: Signal[] = [];
    try {
        const data = JSON.parse(atob(source));

        for (const field of Object.keys(data[ctx])) {
            const raw = data[ctx][field];
            const signal = {
                field,
                source: raw.source,
                type: raw.type,
                when: parsePredicate(raw.when),
            };

            signals.push(signal as Signal);
        }
    }
    catch (e) {
        console.error(e);
        console.log('error creating source signals', source);
    }

    return signals;
}

export default class SignalManager {
    private signals: Signal[] = [];

    private hiddenFields: string[] = [];

    static create(source: string|undefined, ctx: string) {
        return React.useRef(new SignalManager(source, ctx));
    }

    constructor(source: string|undefined, ctx: string) {
        this.signals = source
            ? createSources(source, ctx)
            : [];
    }

    setValues(values: any, ctx: any = {}) {
        this.hiddenFields = [];
        // procesamos las senhales que ocultan/muestran campos
        const visibilitySignals = this.signals.filter(s => s.type === 'show');
        for (const v of visibilitySignals) {
            const field = v.field;
            let show = false;
            try {
                show = v.when(values[v.source], {...values, ...ctx});
            }
            catch (e) {
                console.log(e);
            }

            if (!show) {
                this.hiddenFields.push(field);
            }
        }

        return this;
    }

    fieldIsVisible(field: string|undefined) {
        return field == undefined || !this.hiddenFields.includes(field);
    }
}
