import * as React from 'react';

import { getUserFilters } from '@store/actions/search';
import {
    ISecurity, IUserSearchFilter, UserSearchFilterType
} from '@models';
import {
    Button, InputSwitch, OverlayPanel, onChange, useLoading, useTranslation
} from '@components';
import {
    storageGet, storageSet
} from '@utils/storage';
import InputEventDispatcher from './InputEventDispatcher';
import { delay } from '@utils';
import './useSearchFilters.scss';

export interface IProps {
    workId: number;
    name: string;
    references?: any;
    onChange?: Function;
    security?: ISecurity;
    persist?: boolean;
    awaitForApply?: boolean;
    awaitForDoSearch?: boolean;
    getXml?: Function;
}

function FilterInputTextOnEnter(props: any) {
    const [value, setValue] = React.useState<string>(props.value || '');

    const onKeyPress = (e: any) => {
        if (e.key == 'Enter') {
            props.onChange(value);
        }
    }

    return <input
        type='text'
        value={value}
        onKeyPress={onKeyPress}
        onChange={e => setValue(e.target.value)} />
}

function FilterInputText(props: any) {
    const [value, setValue] = React.useState<string>(props.value || '');

    const dispatcher = React.useRef(
        new InputEventDispatcher(
            props.onChange,
            setValue
        ));

    return <input
        type='text'
        value={value}
        onChange={e =>
            dispatcher.current.append(e)
        } />
}

export default function useSearchFilters(props: IProps) {
    const getLocalStorageKey = () => {
        return `filters_${props.name}_${props.workId}`;
    }

    const recoverStorage = () => {
        const data = storageGet(getLocalStorageKey(), {});
        return data;
    }

    const [filters, setFilters] = React.useState<IUserSearchFilter[]>([]);
    const [filterValues, setFilterValues] = React.useState<any>(
        recoverStorage()
    );
    const [temporaryFilterValues, setTemporaryFilterValues] = React.useState<any>([]);

    const [defaultFilterValues, setDefaultFilterValues] = React.useState<any>({});
    const [error, setError] = React.useState<string | undefined>();
    const [searchOrder, setSearchOrder] = React.useState<boolean>(false);

    const returnSearchOrderToFalse = () => {
        setSearchOrder(false)
    }

    const propagate = React.useRef<boolean>(false);
    const loading = useLoading();
    const { t } = useTranslation();

    const buttonRef = React.useRef<any>(null);

    const canApplyFilter = (f: IUserSearchFilter) => {
        return (f.excludeRole && !props.security?.hasRole(f.excludeRole))
            || (!f.excludeRole && f.requireRole && props.security?.hasRole(f.requireRole))
            || (!f.excludeRole && !f.requireRole);
    }

    const initialize = loading.wrap(async () => {
        try {
            const storeKey = getLocalStorageKey();
            const state: any = props.persist
                ? storageGet<any>(storeKey, {})
                : {};
            const rawFs = await getUserFilters(props.workId, props.name);
            const fs = rawFs.filter(canApplyFilter);
            const values: any = {};
            const defaultValues: any = {};
            for (const f of fs) {
                if (f.type == UserSearchFilterType.REFERENCE && f.default == 'current_year') {
                    const currentYear = new Date().getFullYear();
                    f.default = currentYear;
                }
                else if (f.type == UserSearchFilterType.REFERENCE && f.default == 'current_month') {
                    const currentMonth = new Date().getMonth();
                    f.default = currentMonth;
                }
                else {
                    defaultValues[f.name] = f.default;
                    values[f.name] = state[f.name] ?? f.default;
                }
            }
            setFilterValues(values);
            setTemporaryFilterValues(values);
            setDefaultFilterValues(defaultValues);
            setFilters(fs);
            setTimeout(() => propagate.current = true, 700);

            buttonRef.current = null;
        }
        catch (_) {
            setError(t('search.filters.notfound'));
        }
    });

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        if (props.onChange && propagate.current) {
            props.onChange(filterValues);
        }

        if (props.persist) {
            const key = getLocalStorageKey();
            storageSet(key, filterValues);
        }
    }, [filterValues]);

    const applyFilters = () => {
        setShowPanel(false);
        setFilterValues({ ...temporaryFilterValues });
    };

    const doSearch = () => {
        setSearchOrder(true);
        setShowPanel(false);
    }

    const renderFilterComponent = (f: IUserSearchFilter) => {
        if (f.type === UserSearchFilterType.BOOL) {
            return <InputSwitch
                checked={filterValues[f.name] || temporaryFilterValues[f.name]}
                onChange={e => {
                    if (props.awaitForApply) {
                        setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: e.value });
                    }
                    else {
                        setFilterValues((v: any) => ({ ...v, [f.name]: e.target.value }));
                    }
                }} />
        }
        else if (f.type === UserSearchFilterType.TEXT) {
            return <FilterInputTextOnEnter
                value={temporaryFilterValues[f.name] ?? filterValues[f.name] ?? ''}
                onChange={(v: string) => {
                    if (props.awaitForApply) {
                        setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: v });
                    }
                    else {
                        setFilterValues((values: any) => ({ ...values, [f.name]: v }));
                    }
                }
                } />
        }
        else if (f.type === UserSearchFilterType.REFERENCE || f.type === UserSearchFilterType.MULTI_REFERENCE) {
            const referenceValues = props.references?.[f.name] ?? [];
            const values = () => {
                if (f.type === UserSearchFilterType.REFERENCE) {
                    if (temporaryFilterValues[f.name] != filterValues[f.name]) {
                        return temporaryFilterValues[f.name] ?? filterValues[f.name] ?? '';
                    }
                    else {
                        return filterValues[f.name] ?? '';
                    }
                }
                else {
                    if (temporaryFilterValues[f.name] != filterValues[f.name]) {
                        return temporaryFilterValues[f.name] ?? filterValues[f.name] ?? [];
                    }
                    else {
                        return filterValues[f.name] ?? [];
                    }
                }
            }
            const filterValue = values();
            if ((props.workId == 23 || props.workId == 22) && referenceValues.length == 0) {
                return null
            }
            return <select
                value={filterValue}
                onChange={e => {
                    const v = e.target.value;
                    if (f.type === UserSearchFilterType.MULTI_REFERENCE) {
                        if ((filterValue != undefined && filterValue.includes(+v))) {
                            const newValue = filterValue.filter((x: any) => x != +v);
                            if (props.awaitForApply) {
                                setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: newValue });
                            }
                            else {
                                setFilterValues((fv: any) => ({ ...fv, [f.name]: filterValue[f.name].filter((x: any) => x != +v) }));
                            }
                        }
                        else if (filterValue == undefined) {
                            if (props.awaitForApply) {
                                setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: [+v] });
                            }
                            else {
                                setFilterValues((fv: any) => ({ ...fv, [f.name]: [+v] }));
                            }
                        }
                        else {
                            if (props.awaitForApply) {
                                setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: [...filterValue, +v] });
                            }
                            else {
                                setFilterValues((fv: any) => ({ ...fv, [f.name]: [...filterValue, +v] }));
                            }
                        }
                    } else {
                        if (props.awaitForApply) {
                            setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: +v });
                        }
                        else {
                            setFilterValues((fv: any) => ({ ...fv, [f.name]: +v }));
                            //? setFilterValues((fv: any) => ({ ...fv, [f.name]: filterValues[f.name] != undefined ? [...filterValues[f.name], v] : [v] }));
                        }
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }} multiple={f.type === UserSearchFilterType.MULTI_REFERENCE}>
                <option></option>
                {referenceValues
                    .sort((a: any, b: any) => typeof a.name == "number" && typeof b.name == "number" ? a.name - b.name :
                        f.shouldTranslate == false ? a.name?.localeCompare(b.name) : t(a.name).localeCompare(t(b.name))
                    )
                    .map((r: any, i: number) => {
                        return <option key={i} value={+r.id}>{f.shouldTranslate == false ? r.name : t(r.name)}</option>
                    }
                    )}
            </select>;
        }
        else if (f.type === UserSearchFilterType.DATE) {
            return <input
                type='date'
                value={temporaryFilterValues[f.name] ?? filterValues[f.name]}
                onChange={e => {
                    const value = e.target.value;
                    if (props.awaitForApply) {
                        setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: value });
                    }
                    else {
                        setFilterValues((v: any) => ({ ...v, [f.name]: value }))
                    }
                }} />
        }
        else if (f.type === UserSearchFilterType.DATE_RANGE) {
            return <div>
                <input
                    type='date'
                    className='flat-right'
                    value={temporaryFilterValues[f.name].length ?? filterValues[f.name]?.length ? temporaryFilterValues[f.name][0] ?? filterValues[f.name][0] : ''}
                    onChange={e => {
                        const value = e.target.value;
                        let oldValue = temporaryFilterValues[f.name] ?? filterValues[f.name];
                        if (oldValue == null) {
                            oldValue = [null, null];
                        }
                        oldValue[0] = value;
                        if (props.awaitForApply) {
                            temporaryFilterValues({ ...temporaryFilterValues, [f.name]: oldValue });
                        }
                        else {
                            setFilterValues((v: any) => ({ ...v, [f.name]: oldValue }))
                        }
                    }} />

                <input
                    type='date'
                    className='flat-left'
                    value={temporaryFilterValues[f.name].length ?? filterValues[f.name]?.length ? temporaryFilterValues[f.name][1] ?? filterValues[f.name][1] : ''}
                    onChange={e => {
                        const value = e.target.value;
                        let oldValue = temporaryFilterValues[f.name] ?? filterValues[f.name];
                        if (oldValue == null) {
                            oldValue = [null, null];
                        }
                        oldValue[1] = value;
                        if (props.awaitForApply) {
                            setTemporaryFilterValues({ ...temporaryFilterValues, [f.name]: oldValue });
                        }
                        else {
                            setFilterValues((v: any) => ({ ...v, [f.name]: oldValue }))
                        }
                    }} />
            </div>
        }
        else {
            return <strong>TODO</strong>
        }
    }

    const renderFilterRow = (f: IUserSearchFilter) => {

        const filterComponent = renderFilterComponent(f);
        if ((props.workId === 23 || props.workId == 22) && !filterComponent) { return null }

        return <div className='r' key={f.name}>
            <span className='info'>
                {f.help && <Button
                    icon='pi pi-info'
                    tooltip={f.help}
                    className='p-button-rounded  p-button-text p-button-sm' />}
            </span>
            <label>{t(f.title)}</label>
            {filterComponent}
        </div>
    }

    const clearFilters = (e: any) => {
        setShowPanel(false);
        panelRef.current.hide(e);
        setFilterValues(defaultFilterValues);
    }

    const closePanel = (e: any) => {
        panelRef.current.hide(e);
        setShowPanel(false);
    }

    const render = () => {
        return <div className='c search-filters'>
            {loading.renderBox()}
            {error === undefined &&
                <div className='filters-container'>
                    {filters.map && filters.map(f => renderFilterRow(f))}
                </div>}
            {error !== undefined &&
                <div className='error'>
                    <i className='fas fa-exclamation-triangle' />
                    {error}
                </div>}

            <div className='footer'>
                <button onClick={closePanel} style={{ color: "black" }}>
                    {t('Close')}
                </button>
                <span className='e' />
                {props.awaitForApply &&
                    <button onClick={applyFilters} style={{ color: "black" }}>
                        {t('search.filters.apply')}
                    </button>}
                {props.awaitForDoSearch &&<button onClick={doSearch} style={{ color: "black" }}>
                        {t('search.filters.apply')}
                    </button>
                }
                <button onClick={clearFilters} style={{ color: "black" }}>
                    {t('search.filters.clear')}
                </button>
            </div>
        </div>
    };

    const [showPanel, setShowPanel] = React.useState<boolean>(false);

    const panelRef = React.useRef<any>();

    function RenderAsButton({ className }: { className?: string } = {}) {
        className = className || 'fas fa-filter pointer filter-btn';
        let hasFilters = false;

        for (const i in defaultFilterValues) {
            if (defaultFilterValues[i] != null && defaultFilterValues[i] != undefined && defaultFilterValues[i] != '') {
                hasFilters = true;
                break;
            }
        }

        if (!hasFilters) {
            for (const i in filterValues) {
                if (filterValues[i] != null && filterValues[i] != undefined && filterValues[i] != '') {
                    hasFilters = true;
                    break;
                }
            }
        }

        if (filters.length > 0) {
            return <div className='search-filters'>
                {props.getXml && <i title = {t('paginator.export')}
                    className={'pointer fas fa-file-excel filters-export'}
                    style={{ color: 'green' }}
                    onClick={(e) => {
                        props.getXml && props.getXml();
                    }} />}
                <i title={t('search.filter')}
                    className={className}
                    style={{ color: hasFilters ? "yellow" : undefined }}
                    onClick={(e) => {
                        if (props.awaitForApply || props.awaitForDoSearch) {
                            setShowPanel(a => !a)
                        } else {
                            panelRef.current.toggle(e);
                        }
                    }} />

                {showPanel && (props.awaitForApply || props.awaitForDoSearch) && <div className='p-overlaypanel p-component p-overlaypanel-enter-done overlay-panel p-overlaypanel-content'>
                    {render()}
                </div>}

                <OverlayPanel
                    className='no-padding'
                    ref={panelRef}>
                    {render()}
                </OverlayPanel>
            </div>;
        }
        else {
            return <strong></strong>;
        }
    }


    const renderAsButton = ({ className }: { className?: string } = {}) => {
        if (buttonRef.current == null) {
            const instance = <RenderAsButton className={className} />;
            buttonRef.current = instance;
        }

        React.useEffect(() => {
            return () => {
                buttonRef.current = null;
            }
        }, [filterValues]);

        return <RenderAsButton className={className} />
    }

    const Component = React.memo(
        RenderAsButton,
        (a: any, b: any) => {
            return true;
        });

    const setFilter = (name: string, value: any) => {
        setFilterValues((f: any) => ({ ...f, [name]: value }));
    }

    const hasFilters = () => {
        const keys = Object.keys(filterValues);
        const values = keys.map(k => filterValues[k]).filter(r => r != undefined);
        return values.length > 0;
    }

    return {
        signature: JSON.stringify(filterValues),
        Component: Component,
        loading,
        filters: filterValues,
        searchOrder,
        returnSearchOrderToFalse,
        hasFilters,
        mergeFilters: (v: any) => ({ ...filterValues, ...v }),
        merge: (v: any) => ({ ...filterValues, ...v }),
        render: render,
        setFilter,
        renderAsButton: renderAsButton,
    }
}
