import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';

import { AdminWorkDepartments } from '@components/admin/AdminWorkDepartments';

export default connect(
    (state: ApplicationState) => ({
        ...Admin.plainActions,
    }),
    Admin.actionCreators,
)(AdminWorkDepartments as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
