import * as React from 'react';

import * as signalR from '@microsoft/signalr';

export interface IUseSignalRProps<T> {
    key?: string;
    method: string;
    onMessage: (data: T) => void;
    endpoint?: string;
}

const __connections: any = {};
const __connectionHandlers: any = {};

const startSignalNotifications = async (signalConnection: signalR.HubConnection) => {
    try {
        await signalConnection.start();
    }
    catch (err) {
        console.log(err);
        setTimeout(() => startSignalNotifications(signalConnection), 5000);
    }
};

function getConnection(endpoint: string, method: string) {
    const key = endpoint + method;
    if (!__connections[key]) {
        const conn = new signalR.HubConnectionBuilder()
            .withUrl(endpoint)
            .withAutomaticReconnect()
            .build();
        __connections[key] = conn;

        // console.log('GET CONNECTION (register)', key);

        conn.on(method, rawMessage => {
            // console.log('USE SIGNALR (register): ', rawMessage, key);
            const handlers = __connectionHandlers[key] ?? [];
            for (const h of handlers) {
                h(rawMessage);
            }
        });

        conn.onclose(async () => {
            // console.log('USE SIGNALR (register) CLOSE');
            await startSignalNotifications(conn);
        });

        conn.start();
    }

    return __connections[endpoint];
}

type Handler<T> = (message: T) => void;

function registerHandler<T>(
    endpoint: string,
    method: string,
    handler: Handler<T>,
    uniqueKey: string | undefined) {
    // console.log(`register handler ${uniqueKey}`, endpoint, method, handler);
    const key = endpoint + method;
    if (!__connectionHandlers[key]) {
        __connectionHandlers[key] = [];
    }
    __connectionHandlers[key].push(handler);
}

function unregisterHandler<T>(
    endpoint: string,
    method: string,
    handler: Handler<T>,
    uniqueKey: string | undefined) {
    // console.log(`unregister handler: ${uniqueKey}`, endpoint, method);
    const key = endpoint + method;
    if (!__connectionHandlers[key]) {
        __connectionHandlers[key] = [];
    }
    __connectionHandlers[key] = __connectionHandlers[key].filter((h: any) => h != handler);
}

export function useSignalR<T>(props: IUseSignalRProps<T>) {
    const endpoint = props.endpoint ?? '/notifications';
    const conn = getConnection(endpoint, props.method);

    React.useEffect(() => {
        registerHandler(endpoint, props.method, props.onMessage, props.key);

        return () => unregisterHandler(endpoint, props.method, props.onMessage, props.key);
    }, []);

    return conn;
}
