import * as React from 'react';

import { R, ValidationBuilder, useForm, useLoading, useMessage, useTranslation, useSearch, useSearchFirst } from '@components';
import { IJob, IJobStatus, IOption, JobStatusType } from '@models';
import { m } from '@store/actions/graphql';

type SaveT = (workId: number, jobId: number, statusTypeId: number, remarks: string) => Promise<IOption<boolean>>;

export interface IProps {
    job: IJob;
    onCancel: Function;
    onSuccess: Function;
    saveJobStatus: SaveT;
    workId: number;
}

type Authorization = {
    jobId: number,
    remarks?: string;
    statusTypeId: JobStatusType,
}

export function JobReactivateForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const msg = useMessage();

    const lastStatusSearch = useSearchFirst<IJobStatus>({
        workId: props.workId,
        search: 'jobs/lastJobStatus',
        filters: {
            jobId: props.job.id
        },
    });

    const lastStatus = lastStatusSearch.value;

    const form = useForm<Authorization>({
        initialValues: {
            jobId: props.job.id,
            remarks: '',
            statusTypeId: lastStatus?.jobStatusTypeId!,
        }
    });

    React.useEffect(() => {
        if (lastStatusSearch.value) {
            form.setFieldValue('statusTypeId', lastStatusSearch.value.jobStatusTypeId);
        }
    }, [ lastStatusSearch.value ]);

    const doSave = loading.wrap(async () => {
        const res = await props.saveJobStatus(
            props.workId,
            form.values.jobId,
            form.values.statusTypeId,
            form.values.remarks);

        msg.set(res);

        if (res.hasValue) {
            props.onSuccess();
        }
    });

    return <div className={'c we'}>
        <div className={'footer r r-end'}>
            {msg.renderIfError()}
            {loading.render()}
            {props.onCancel &&
                <button disabled={loading.isLoading()} onClick={() => props.onCancel()}>{t('Cancel')}</button>}
            {props.onSuccess &&
                <button className={'primary'}
                    disabled={loading.isLoading()} onClick={doSave}>{t('Save')}</button>}
        </div>
    </div>
}