import * as React from 'react';
import { Route } from 'react-router';

import { DataLoader, useTranslation } from '@components';
import { WorkerRequirements } from '@components/workers/WorkerRequirements';
import EditWorkerFormContainer from '@containers/workers/EditWorkerFormContainer';
import { IWorker } from '@models';
import { getIntParams } from '@utils';

import { getWorker } from '@store/actions/workers';

export interface IProps {
    children?: any;
    clearWorkerForm: Function;
    worker: IWorker;
}

const WrappedWorkerRequirements = (props: any) => {
    const { t } = useTranslation();
    const { workerid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => getWorker(workid, workerid)}>
        {w => <div>
            <div className='center requirements-title'>
                {t('Worker requirements')}
            </div>
            <div className='md pd r g-20'>
                <WorkerRequirements
                    workerId={workerid}
                    workerName={w.name + ' ' + w.surname + ' - ' + w.code}
                    workId={workid} />
            </div>
        </div>}
    </DataLoader>
}

const WrappedWorkerForm = (props: any) => {
    const { t } = useTranslation();
    const { workerid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => getWorker(workid, workerid)}>
        {w => <div>
            <div className='md pd r g-20'>
                <EditWorkerFormContainer
                    worker={w}
                    workerId={workerid}
                    workerName={w.name + ' ' + w.surname + ' - ' + w.code}
                    workId={workid} />
            </div>
        </div>}
    </DataLoader>
}

export function WorkerRoutes(props: IProps) {
    return <>
        <Route
            path='/work/:workid/workers/:workerid/requirements'
            component={WrappedWorkerRequirements} />
        <Route
            exact
            path='/work/:workid/workers/:workerid'
            component={WrappedWorkerForm} />
    </>;
}
