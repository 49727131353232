import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ConstantsStore from '../../store/constants';
import * as App from '../../store/app';

import { AdminDocumentTypes, IProps } from '../../components/admin/AdminDocumentTypes';
import { NotificationType } from '../../models';

type Props =
    App.IAppState &
    IProps;

export default connect(
    (state: ApplicationState) => ({
        documentTypes: state.constants.documentTypes,
        notifications: state.notifications.notifications
            .filter(n => n.ctx === 'admindocumenttypes'),
        loading: state.notifications.notifications
            .filter(n => n.ctx === 'admindocumenttypes' && n.type === NotificationType.LOADING),
        loadingMessage: state.app.loadingMessage
    }),
    ConstantsStore.actionCreators,
)(AdminDocumentTypes as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
