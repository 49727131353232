import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import  * as ContractorsStore from '@store/contractors';
import { WorkContractorAccessData } from '@components/contractors/WorkContractorAccessData';
import { hasPermission } from '@utils/security';

export default connect(
    (state: ApplicationState, params: any) => ({
        ...ContractorsStore.plainActions,
        contractorId: params.contractorId ?? parseInt(params.match?.params?.contractorid),
        workId: params.workId ?? parseInt(params.match?.params?.workId),
        ...state.identity,
        ...state.user,
        ...ContractorsStore.plainActions,
        hasPermission: (p: string) => hasPermission(p, {
            user: state.identity?.user,
            explicit: true,
        }),
    }),
    ContractorsStore.actionCreators,
)(WorkContractorAccessData as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any