import { get as i18n } from '../i18n';
import * as DateUtils from '@utils/date-utils';

export enum NotificationType {
    NOTIFICATION,
    ERROR,
    LOADING,
    PROGRESS,
    SUCCESS,
}

export interface INotification {
    action?: string;
    ctx?: string;
    delay?: number;
    id: number;
    message: string;
    onComplete?: Function;
    progress?: number;
    type: NotificationType;
}

export enum MessageType {
    MESSAGE = 1,
    JOB_HAS_CONTRACTOR_REQUEST = 2,
    PENDING_REQUIREMENT = 3,
    /**
     * El requerimiento esta pendiente
     * data: { workId: int, resourceType: int, resourceId: int, requirementId: int, requirementTypeId: int, requirementName: string, resourceName: string }
     */
    JOB_CREATED = 4,
    SUBCONTRACTOR_REQUEST = 5,
    JOB_INFORMATION_LOADED = 6,
    REQUIREMENT_VALIDATED = 7,
    REQUIREMENT_INCIDENCE = 8,
    INVALID_ITA = 9,
    SUBCONTRACTOR_REQUEST_ACCEPTED = 10,
    SUBCONTRACTOR_REQUEST_REJECTED = 11,
    JOB_FINALIZED = 12,
    RESOURCE_AUTHORIZED = 13,
    RESOURCE_REJECTED = 14,
    JOB_AUTHORIZED = 15,
    JOB_REVOKED = 16,
    REQUIREMENT_EXPIRED = 17,
    RELOAD_NOTIFICATIONS = 18,
    MODULE_MESSAGE = 19,
    REQUIREMENT_NOTE = 20,
    REQUIREMENT_NEAR_EXPIRATION = 21,
}

export const messageTypes = [
    {id: MessageType.MESSAGE, name: 'messages.type.message'},
    {id: MessageType.JOB_HAS_CONTRACTOR_REQUEST, name: 'messages.type.job_has_contractor_request'},
    {id: MessageType.PENDING_REQUIREMENT, name: 'messages.type.pending_requirement'},
    {id: MessageType.JOB_CREATED, name: 'messages.type.job_created'},
    {id: MessageType.SUBCONTRACTOR_REQUEST, name: 'messages.type.subcontractor_request'},
    {id: MessageType.JOB_INFORMATION_LOADED, name: 'messages.type.job_information_loaded'},
    {id: MessageType.REQUIREMENT_VALIDATED, name: 'messages.type.requirement_validated'},
    {id: MessageType.REQUIREMENT_INCIDENCE, name: 'messages.type.requirement_incidence'},
    {id: MessageType.INVALID_ITA, name: 'messages.type.invalid_ita'},
    {id: MessageType.SUBCONTRACTOR_REQUEST_ACCEPTED, name: 'messages.type.subcontractor_request_accepted'},
    {id: MessageType.SUBCONTRACTOR_REQUEST_REJECTED, name: 'messages.type.subcontractor_request_rejected'},
    {id: MessageType.JOB_FINALIZED, name: 'messages.type.job_finalized'},
    {id: MessageType.RESOURCE_AUTHORIZED, name: 'messages.type.resource_authorized'},
    {id: MessageType.RESOURCE_REJECTED, name: 'messages.type.resource_rejected'},
    {id: MessageType.JOB_AUTHORIZED, name: 'messages.type.job_authorized'},
    {id: MessageType.JOB_REVOKED, name: 'messages.type.job_revoked'},
    {id: MessageType.REQUIREMENT_EXPIRED, name: 'messages.type.requirement_expired'},
    {id: MessageType.MODULE_MESSAGE, name: 'messages.type.module_message'},
    {id: MessageType.REQUIREMENT_NOTE, name: 'messages.type.requirement_note'},
    {id: MessageType.REQUIREMENT_NEAR_EXPIRATION, name: 'messages.type.requirement_near_expiration'},
];

export interface IMessage {
    id: string;
    message: string;
    date?: Date;
    data?: any;
    notificationType: number;

    icon?: string;
    severity?: string;
}

export const parseMessage = (message: IMessage) => {
    return {...message, data: JSON.parse(message.data)};
}

const __icons: {[key: string]: string} = {
    'message': 'fas fa-comment-alt',
    'info': 'fas fa-info',
    'warning': 'fas fa-exclamation-triangle',
    'error': 'fas fa-exclamation',
    'success': 'fas fa-check-circle'
}

export const translateNotificationIcon = (icon: string) => {
    return __icons[icon] ?? 'pi-exclamation-circle';
}

export const messageTitle = (message: IMessage) => {
    if (message.notificationType === MessageType.PENDING_REQUIREMENT && !message.message) {
        return i18n('notifications.requirement.pending');
    }
    else if (message.notificationType === MessageType.PENDING_REQUIREMENT) {
        return message.message + ' ';
    }
    else {
        return message.message;
    }
}
