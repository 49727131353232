import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { CommmunicationForm } from '@components/communications/CommunicationForm';
import * as UserStore from '@store/user';
import * as DocumentsStore from '@store/documents';
import * as Actions from '@store/actions/resources'

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        workspaces: state.user.workspaces,
        ...UserStore.plainActions,
        ...DocumentsStore.plainActions,
        ...Actions.getDepartments
    }),
    {},
)(CommmunicationForm as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
