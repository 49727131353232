import * as React from 'react';

import {
    useLoading, useRemoteData, useDataTable, useDialogs, useTranslation,
    useResolveName, useConfirm,
    useForm,
} from '@components';
import {
    getNotificationResourceTypes, getResourceTypes, IOption, IWork
} from '@models';
import {
    getNotificationMessageActions, INotificationMessage, INotificationTemplate
} from '@models/works';

import './NotificationMessages.scss';
import { NotificationMessageForm } from './NotificationMessageForm';

type GetNotificationMessagesF = (workId: number) => Promise<INotificationMessage[]>;
type GetNotificationTemplatesF = (workId: number) => Promise<INotificationTemplate[]>;
type RemoveNotificationMessageF = (workId: number, id: number) => Promise<IOption<boolean>>;
type SaveNotificationMessageF = (workId: number, data: INotificationMessage) => Promise<IOption<number>>;

type INotificationMessageRow = INotificationMessage & {
    actionName: string;
}

export interface IProps {
    getNotificationMessages: GetNotificationMessagesF;
    getNotificationTemplates: GetNotificationTemplatesF;
    removeNotificationMessage: RemoveNotificationMessageF;
    saveNotificationMessage: SaveNotificationMessageF;
    work: IWork;
}

type Filters = {
    email?: boolean;
    action?: number;
    resourceType?: number;
    template?: number;
    restrictResource?: number;
}

export function AdminWorkMessages(props: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const loading = useLoading();

    const actions = React.useRef(getNotificationMessageActions(t));
    const resourceTypes = React.useRef(getNotificationResourceTypes(t));
    const resourceTypeRestrict = React.useRef(getResourceTypes(t));

    const resolveAction = useResolveName(actions.current);
    const resolveResourceType = useResolveName(resourceTypes.current);
    const resolveRestrictResourceType = useResolveName(resourceTypeRestrict.current);
    const resolveDepartment = useResolveName(props.work.departments ?? []);

    const filtersForm = useForm<Filters>({
        initialValues: {},
    });

    const templates = useRemoteData<INotificationTemplate[]>(
        props.getNotificationTemplates,
        {
            parameters: [props.work.id]
        });

    const messages = useRemoteData<INotificationMessage[]>(
        props.getNotificationMessages,
        {
            parameters: [props.work.id]
        });

    const [filteredMessages, setFilteredMessages] = React.useState(messages.value ?? []);

    const filterMessage = (m: INotificationMessage, filters: Filters) => {
        const predicates = [
            [filters.email != true, m.byMail == true],
            [filters.action == undefined, m.action == filters.action],
            [filters.resourceType == undefined, m.resourceType == filters.resourceType],
            [filters.template == undefined, m.notificationTemplateId == filters.template],
            [filters.restrictResource == undefined, m.restrictToResourceType == filters.restrictResource],
        ];

        return predicates
            .reduce((a, b) => a && (b[0] == true || b[1] == true), true);
    };

    React.useEffect(() => {
        setFilteredMessages(
            (messages.value ?? [])
                .filter(m => filterMessage(m, filtersForm.values)));
    }, [messages.value, filtersForm.values]);

    const doRemove = loading.wrap(async (r: INotificationMessage) => {
        await props.removeNotificationMessage(props.work.id, r.id!);
        messages.query();
    });

    const confirmDelete = useConfirm({
        accept: doRemove,
        message: t('Are you sure to delete the element ?'),
    });

    const dataTable = useDataTable<INotificationMessageRow>({
        columns: [
            { title: 'Action', render: r => r.actionName, sortKey: 'actionName', },
            {
                title: 'ResourceType', render: r => {
                    const resourceType = resolveResourceType(r.resourceType);
                    const department = r.restrictToResourceId;
                    if (department) {
                        const departmentName = resolveDepartment(department);
                        const value = resourceType + ' (' + departmentName + ')';

                        return <span title={value}>{value}</span>;
                    }
                    else {
                        return resourceType;
                    }
                }, sortKey: 'resourceType',
            },
            { title: 'Template', render: r => r.notificationTemplate?.name, sortKey: 'notificationTemplateName' },
            {
                title: 'Restrict resource',
                field: 'restrictToResourceType',
                render: r => resolveRestrictResourceType(r.restrictToResourceType),
                sortable: true
            },
            { title: 'Email', field: 'byMail', delegate: 'boolean', className: 'td-md', sortable: true },
        ],
        actions: [
            {
                icon: 'pencil',
                onClick: r => dialogs.showFromEvent('edit', r)()
            },
            { icon: 'trash', onClick: confirmDelete }
        ],
        data: filteredMessages
            .map(m => ({
                ...m,
                notificationTemplateName: m.notificationTemplate?.name,
                actionName: resolveAction(m.action),
            }))
            .sort((a, b) => a.actionName.localeCompare(b.actionName))
    });

    const doSave = loading.wrap(async (r: INotificationMessage) => {
        const _ = await props.saveNotificationMessage(props.work.id, r);
        await messages.query();
        dialogs.clear();
    });

    return <div className='NotificationMessages'>
        <div className="filters r g-10">
            <div className='filter sm'>
                <span className='filter-label'>{t('Email')}</span>
                {filtersForm.checkBox('email')}
            </div>
            <div className='filter'>
                <span className='filter-label'>{t('Action')}</span>
                {filtersForm.select('action', {
                    options: actions.current, blank: true, blankText: t('Action'), filter: true
                })}
            </div>
            <div className='filter'>
                <span className='filter-label'>{t('ResourceType')}</span>
                {filtersForm.select('resourceType', {
                    options: resourceTypes.current, blank: true, blankText: t('ResourceType'),
                })}
            </div>
            <div className='filter'>
                <span className='filter-label'>{t('Template')}</span>
                {filtersForm.select('template', {
                    options: templates.value ?? [], blank: true, blankText: t('Template')
                })}
            </div>
            <div className='filter'>
                <span className='filter-label'>{t('Email')}</span>
                {filtersForm.select('restrictResource', { options: resourceTypes.current, blank: true })}
            </div>
        </div>
        {templates.renderLoading()}
        {messages.renderLoading()}

        {dialogs.render('edit', { title: t('Edit message'), className: 'pd g' }, (d: INotificationMessage) =>
            <NotificationMessageForm
                data={d}
                isLoading={loading.isLoading()}
                onCancel={() => dialogs.clear()}
                templates={templates.value}
                onSubmit={doSave}
                showFooter={true}
                departments={props.work.departments ?? []}
                workId={props.work.id} />)}

        {dialogs.render('add', { title: t('Add message'), className: 'pd g' }, (_: any) =>
            <NotificationMessageForm
                isLoading={loading.isLoading()}
                onCancel={() => dialogs.clear()}
                templates={templates.value}
                onSubmit={doSave}
                showFooter={true}
                departments={props.work.departments ?? []}
                workId={props.work.id} />)}
        {dataTable()}

        <div className='r r-end footer'>
            <button className='primary' onClick={dialogs.showFromEvent('add')}>
                <i className='fa fa-plus'></i>
                {t('Add notification message')}
            </button>
        </div>
    </div>
}
