import * as React from 'react';

import { classNames, useConfirm, useTranslation, useSearchFilters, Tag, useSearch } from '@components';
import { IOption, ISecurity, IWork } from '@models';
import {
    messageTypes, IMessage, translateNotificationIcon, messageTitle, MessageType
} from '@models/notifications';
import { NotificationMessageDisplayName } from './NotificationMessageDisplayName';
import { NotificationMessageEffect } from './NotificationMessageEffect';
import { MessageIcon } from './MessageIcon';
import  NotificationMessage  from './NotificationMessage';
import * as DateUtils from '@utils/date-utils';

import './NotificationsCenter.scss';
import { currentLocale } from '../../i18n';

type ResolveMessageF = (id: string) => Promise<IOption<boolean>>;
type ResolveMessagesF = (ids: string[]) => Promise<IOption<boolean>>;

export interface IProps {
    className?: string;
    messages: IMessage[];
    loadMessages: Function;
    resolveMessage: ResolveMessageF;
    resolveMessages: ResolveMessagesF;
    security: ISecurity;
    work: IWork;
    workId: number;
}



type IMessageWithSearch = IMessage & {
    jobSearch: string;
    contractorSearch: string;
    messageSearch: string;
}

const filterMessage = (message: IMessageWithSearch, filters: any) => {
    return (filters.messageType == undefined || message.notificationType == filters.messageType)
        && (filters.job == null || filters.job == '' || message.jobSearch.includes(filters.job.toLowerCase()))
        && (filters.contractor == null || filters.contractor == '' || message.contractorSearch.includes(filters.contractor.toLowerCase()))
        && (filters.message == null || filters.message == '' || message.messageSearch.includes(filters.message.toLowerCase()))
        && (message.message != null)
        && (message.message != '')
        && (message.notificationType == MessageType.RELOAD_NOTIFICATIONS);
}

const filterMessages = (messages: IMessageWithSearch[], filters: any) => {
    return messages.filter(m => filterMessage(m, filters));
}

const contractorProperties = ['ContractorName', 'ContractorCode'];
const jobProperties = ['JobCode', 'JobName', 'JobDescription'];

const parseMessageProps = (data: any, propNames: string[]) => {
    const props = propNames.map(p => data[p] ?? '').join('').toLowerCase();

    return props ?? '';
}

const parseMessages = (messages: IMessage[]) => {
    return messages.map(m => ({
        ...m,
        jobSearch: parseMessageProps(m.data ?? {}, jobProperties),
        contractorSearch: parseMessageProps(m.data ?? {}, contractorProperties),
        messageSearch: m.message?.toLowerCase(),
    }));
}

const excludeNotificationTypes = [
    MessageType.INVALID_ITA,
    MessageType.RESOURCE_AUTHORIZED,
    MessageType.RESOURCE_REJECTED,
    // MessageType.REQUIREMENT_EXPIRED,
];

export function NotificationsCenter(props: IProps) {
    const { t } = useTranslation();

    const confirm = useConfirm({
        message: t('notifications.messages.close-confirm'),
        accept: () => doRemoveCheckedMessages(),
    });

    const originalMessages = React.useRef<IMessageWithSearch[]>(
        parseMessages(props.messages));

    const [messages, setMessages] = React.useState<IMessageWithSearch[]>(originalMessages.current);
    const [selectedMessage, setSelectedMessage] = React.useState<IMessage | undefined>();
    const [checkedMessages, setCheckedMessages] = React.useState<IMessage[]>([]);

    const filterMessageTypes = (messageTypes : { id: MessageType; name: string;}[] ): { id: MessageType; name: string; }[] => {

        const result = messageTypes;

        switch (props.workId) {
            case 21:
                return result.filter(m => !excludeNotificationTypes.includes(m.id));
            case 23:
                return result.filter(m => m.id != MessageType.MESSAGE);
            default:
                return result;
        }
    }

    const filters = useSearchFilters({
        name: 'notifications/list',
        workId: props.workId ?? 0,
        references: {
            messageType: filterMessageTypes(messageTypes)
        },
        persist: true
    });

    const notificationsSearch = useSearch({
        workId: props.workId,
        search: 'notifications/userNotifications',
        lazy: true,
        filters : {
            ...filters.filters,
            workId: props.workId,
            userId: props.security.user?.id ?? 0
        }
    });

    React.useEffect(() => {
        if(filters.hasFilters()){
            if(filters.filters.messageType == 0){
                filters.filters.messageType = undefined;
            }
            notificationsSearch.doSearch();
        }
    }, [filters.filters]);

    React.useEffect(() => {
        if (notificationsSearch.value && filters.hasFilters()) {
            const searchValue : IMessage[] = notificationsSearch.value as IMessage[];
            setMessages(parseMessages(searchValue)
                .sort((a, b) =>
                    new Date(b.date!).getTime() - new Date(a.date!).getTime()));
        }else{
            if (props.workId != null) {
                setMessages(originalMessages.current
                    .filter(om => om.data.WorkId == props.workId)
                    .sort((a, b) =>
                        new Date(b.date!).getTime() - new Date(a.date!).getTime()));
                    } else {
                        setMessages(originalMessages.current
                            .sort((a, b) =>
                                new Date(b.date!).getTime() - new Date(a.date!).getTime()));
                    }
        }
    }, [notificationsSearch.value]);

    React.useEffect(() => {
        props.loadMessages();
    }, []);

    React.useEffect(() => {
        const original = parseMessages(props.messages);
        originalMessages.current = original;
        if (filters.hasFilters()) {
            setMessages(filterMessages(original, filters.filters));
        }
        else {
            setMessages(original
                .sort((a, b) =>
                    new Date(b.date!).getTime() - new Date(a.date!).getTime()));
        }
    }, [props.messages]);

    const doRemoveCheckedMessages = () => {
        props.resolveMessages(checkedMessages.map(m => m.id));
    }

    const doCheckAllMessages = () => {
        if (checkedMessages.length == messages.length) {
            setCheckedMessages([]);
        }
        else {
            setCheckedMessages(messages);
        }
    }

    const messagesToRender = messages
        .filter(m => m.notificationType != MessageType.RELOAD_NOTIFICATIONS);

    return <div className={classNames(props.className, 'notifications-center')}>
        <div className='notifications-title'>
            {t('Notifications')}
            <Tag
                className='small sm mr-left'
                value={messagesToRender.length} />
            <img src="/img/works/notifications.png" alt="gstore" />
            <span className='e' />
            <div className='search-filters' style={{ padding: "10px" }}>
                <i onClick={doCheckAllMessages} className="fas fa-check-double" title={t('notifications.remove.all')} style={{ color: "#717171" }} />
            </div>
            <div className='search-filters' style={{ padding: "10px" }}>
                <i className='fas fa-trash' onClick={checkedMessages.length == 0 ? undefined : confirm} title={t('notifications.remove')} style={{ color: "#717171" }} />
            </div>
            {filters.renderAsButton()}
        </div>

        <div className='notifications'>
            {selectedMessage &&
                <NotificationMessageEffect
                    message={selectedMessage}
                    requestCancel={() => setSelectedMessage(undefined)}
                    resolveMessage={props.resolveMessage}
                    security={props.security}
                    workId={props.workId}
                    work={props.work} />}
            {messagesToRender.map(m =>
                <NotificationMessage
                    key={m.id}
                    message={m}
                    resolveMessage={props.resolveMessage}
                    onSelect={setSelectedMessage}
                    checked={checkedMessages.includes(m)}
                    onCheck={() => setCheckedMessages(checkedMessages.includes(m) ? checkedMessages.filter(r => r != m) : [...checkedMessages, m])}
                />)}
        </div>
    </div>
}
