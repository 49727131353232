import { Action, Reducer } from 'redux';
import { ApplicationState } from '.';
import {
    IDepartment,
    IActivityType,
    ILegalForm,
    IMachineryType,
    IWork,
    IWorkspace,
    IWorkShift,
    parseWork,
    IContractType,
    IWorker,
    IResourceStatusType, IPreventiveResource, IPropertyGroupType, ICommunication
} from '@models';
import { IRequirementGroup, IRequirementStatusType, IRequirementValidationType } from '@models/requirements';
import * as App from './app';
import * as UserActions from './actions/user';
import * as ProfileActions from './actions/profile';
import * as ResourceActions from './actions/resources';
import { actionCreators as N } from './notifications';
import { actionCreators as Modules } from './modules';
import t, * as I18n from '../i18n';
import { redirectTo } from '@utils';
import { IWorkerType, IWorkerDocumentType, IContractor } from '@models/resources';
import { IReportDefinition } from "@models/reports";
import { getReports } from "./actions/reports";

export interface IUserState {
    version: number,
    works: IWork[],
    work?: IWork,
    reports: IReportDefinition[],
    workers: IWorker[];
    propertyGroupTypes: IPropertyGroupType[];
    activityTypes: IActivityType[];
    legalForms: ILegalForm[];
    departments: IDepartment[];
    machineryTypes: IMachineryType[];
    workerTypes: IWorkerType[];
    workerDocumentTypes: IWorkerDocumentType[];
    workspaces: IWorkspace[];
    workShifts: IWorkShift[];
    contractTypes: IContractType[];
    preventiveResources: IPreventiveResource[];
    requirementGroups: IRequirementGroup[];
    requirementStatusTypes: IRequirementStatusType[];
    requirementValidationTypes: IRequirementValidationType[];
    resourceStatusTypes: IResourceStatusType[];
    contractors: IContractor[];
    communications: ICommunication[];
}

// types
enum Types {
    Initialize = '[USER] INITIALIZE',
    OnInitialize = '[USER] ON INITIALIZE',
    SelectWork = '[USER] SELECT WORK',
    SelectWorkResources = '[USER] SELECT WORK RESOURCES',
    PendingCommunications = '[USER] PENDING COMMUNICATIONS',
    ValidateCommunication = '[USER] VALIDATE COMMUNICATION',
    ContractorsLoaded = '[USER] CONTRACTORS LOADED',
}

// actions
export interface InitializeAction {
    type: Types.Initialize;
}

export interface OnInitializeAction {
    type: Types.OnInitialize;
    data: Partial<IUserState>;
}

export interface SelectWorkAction {
    type: Types.SelectWork;
    data: IWork;
}

export interface SetPendingCommunicationsAction {
    type: Types.PendingCommunications;
    data: ICommunication[];
}

export interface ValidateCommunicationAction {
    type: Types.ValidateCommunication;
    data: number;
}

export interface SelectWorkResourcesAction {
    type: Types.SelectWorkResources;
    reports: IReportDefinition[];
    departments: IDepartment[];
    activityTypes: IActivityType[];
    propertyGroupTypes: IPropertyGroupType[];
    legalForms: ILegalForm[];
    machineryTypes: IMachineryType[];
    workerTypes: IWorkerType[];
    workerDocumentTypes: IWorkerDocumentType[];
    workers: IWorker[];
    workspaces: IWorkspace[];
    workShifts: IWorkShift[];
    contractTypes: IContractType[];
    preventiveResources: IPreventiveResource[];
    requirementGroups: IRequirementGroup[];
    requirementStatusTypes: IRequirementStatusType[];
    requirementValidationTypes: IRequirementValidationType[];
    resourceStatusTypes: IResourceStatusType[];
    contractors: IContractor[];
}

export interface ContractorsLoadedAction {
    type: Types.ContractorsLoaded;
    value: IContractor[];
}

export type KnownAction = InitializeAction | OnInitializeAction
    | SelectWorkAction | SelectWorkResourcesAction
    | SetPendingCommunicationsAction | ValidateCommunicationAction
    | ContractorsLoadedAction
    | { type: undefined };

const onInitialize = (data: Partial<IUserState>) => {
    return {
        type: Types.OnInitialize,
        data
    };
}

const loadWorks = async (dispatch: Function) => {
    dispatch(N.startLoading({
        ctx: 'user.works',
    }));
    const resp = await UserActions.getWorks();

    const works = resp.data.works.map(parseWork);

    dispatch(onInitialize(resp.data));
    dispatch(N.stopLoading({
        ctx: 'user.works',
    }));

    return works;
}

export const plainActions = {
    createHelpDeskItem: UserActions.createHelpDeskItem,
    saveWorker: ResourceActions.saveWorker,
    changePassword: ProfileActions.changePassword,
    changeUserPassword: ProfileActions.changeUserPassword,
    recoverPassword: ProfileActions.recoverPassword,
    getJobs: UserActions.getJobs,
    getJobReferences: UserActions.getJobReferences,
    saveJob: UserActions.saveJob,
    removeJob: UserActions.removeJob,
    saveCommunication: UserActions.saveCommunication,
    getCommunicationReceivers: UserActions.getCommunicationReceivers,
    getCommunication: UserActions.getCommunication,
}

export const actionCreators = {
    loadContractors: (workId: number) => async (dispatch: Function) => {
        const contractors = await UserActions.getContractors(workId);

        dispatch({
            type: Types.ContractorsLoaded,
            value: contractors,
        });
    },
    loadWorks: () => (dispatch: Function) => {
        loadWorks(dispatch);
    },
    loadMessages: () => (dispatch: Function) => {
        dispatch(N.loadMessages());
    },
    validateCommunication: (id: number) => async (dispatch: Function) => {
        const resp = await UserActions.validateCommunication(id);

        if (resp.hasValue) {
            dispatch({
                type: Types.ValidateCommunication,
                data: id,
            });
        }
    },
    selectWork: (workId: number, redirect: boolean = true) => async (dispatch: Function, getState: () => ApplicationState) => {
        const state = getState();

        dispatch(App.actionCreators.startLoading({
            message: t.getFixedT('es')('Loading work')
        }));
        const resp = await UserActions.getWork(workId);
        // insertamos las claves de internacionalizacion de la obra
        I18n.installResources(resp.data.work.workI18NResources);

        const work = parseWork(resp.data.work);

        dispatch(Modules.initializeWork(work, resp.data.work.workI18NResources));

        const reports = await getReports(workId);

        dispatch({
            type: Types.SelectWorkResources,
            reports: reports,
            activityTypes: resp.data.work.activities,
            legalForms: resp.data.work.legalForms,
            departments: resp.data.work.departments,
            machineryTypes: resp.data.work.machineryTypes,
            workerTypes: resp.data.work.workerTypes,
            workerDocumentTypes: resp.data.work.workerDocumentTypes,
            workspaces: resp.data.work.workspaces,
            workShifts: resp.data.work.workShifts,
            workers: resp.data.work.workers,
            propertyGroupTypes: work.propertyGroupTypes,
            contractTypes: resp.data.work.contractTypes,
            preventiveResources: resp.data.work.preventiveResources,
            requirementGroups: resp.data.work.requirementGroups,
            requirementStatusTypes: resp.data.work.requirementStatusTypes,
            requirementValidationTypes: resp.data.work.requirementValidationTypes,
            resourceStatusTypes: resp.data.work.resourceStatusTypes,
            contractors: resp.data.work.contractors
        });
        dispatch({
            type: Types.SelectWork,
            data: work,
        });
        dispatch(App.actionCreators.stopLoading());
        if (state.identity?.security?.isGestorOrWorker() ?? false) {
            dispatch(actionCreators.loadContractors(workId));
        }

        // si el usuario tiene comunicaciones abiertas redireccionamos
        if (resp.data.communications.length > 0) {
            dispatch({
                type: Types.PendingCommunications,
                data: resp.data.communications,
            });
            redirectTo(`/work/${workId}/communications/current`);
        }
        else if (redirect) {
            redirectTo(`/work/${workId}/`);
        }
    },
    initialize: () => async (dispatch: Function, getState: () => ApplicationState) => {
        dispatch(App.actionCreators.startLoading({
            message: t.getFixedT('es')('Initializing ...')
        }));
        const resp = await UserActions.initialize();
        dispatch(onInitialize({ ...resp.data, version: new Date().getTime() }));
        dispatch(App.actionCreators.stopLoading());

        const works = resp.data.works.map(parseWork);

        if (works.length == 1) {
            const work = works[0];
            const redirect = window.location.pathname == '/';
            dispatch(actionCreators.selectWork(work.id, redirect));
        }

        dispatch(N.loadMessages());
    }
};

export const reducer: Reducer<IUserState> = (state: IUserState | undefined, incomingAction: Action): IUserState => {
    if (state === undefined) {
        return {
            activityTypes: [],
            departments: [],
            propertyGroupTypes: [],
            legalForms: [],
            workers: [],
            reports: [],
            machineryTypes: [],
            workerTypes: [],
            workerDocumentTypes: [],
            workspaces: [],
            workShifts: [],
            contractTypes: [],
            preventiveResources: [],
            version: 0,
            works: [],
            requirementGroups: [],
            requirementStatusTypes: [],
            requirementValidationTypes: [],
            resourceStatusTypes: [],
            contractors: [],
            communications: [],
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case Types.PendingCommunications:
            return { ...state, communications: action.data };
        case Types.ValidateCommunication:
            return { ...state, communications: state.communications.filter(c => c.id != action.data) };
        case Types.OnInitialize:
            return { ...state, ...action.data };
        case Types.ContractorsLoaded:
            return { ...state, contractors: action.value };
        case Types.SelectWorkResources:
            return {
                ...state,
                reports: action.reports,
                activityTypes: action.activityTypes,
                departments: action.departments,
                propertyGroupTypes: action.propertyGroupTypes,
                legalForms: action.legalForms,
                machineryTypes: action.machineryTypes,
                workerTypes: action.workerTypes,
                workerDocumentTypes: action.workerDocumentTypes,
                workspaces: action.workspaces,
                workShifts: action.workShifts,
                contractTypes: action.contractTypes,
                workers: action.workers,
                preventiveResources: action.preventiveResources,
                requirementGroups: action.requirementGroups,
                requirementStatusTypes: action.requirementStatusTypes,
                requirementValidationTypes: action.requirementValidationTypes,
                resourceStatusTypes: action.resourceStatusTypes,
                contractors: action.contractors
            };
        case Types.SelectWork:
            return { ...state, work: action.data, };
        default:
            return state;
    }
};
