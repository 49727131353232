import * as React from 'react';

import { redirectTo } from '../../utils';
import { Portal } from './Portal';

export interface IProps {
    children?: any;
    onClick?: Function;
    redirectTo?: string;
    text?: string;
}

export function BreadcrumbItem(props: IProps) {
    const performOnClick = (..._: any) => {
        if (props.onClick) {
            props.onClick();
        }

        if (props.redirectTo) {
            redirectTo(props.redirectTo);
        }
    }

    return (<Portal container='#user-breadcrumb'>
        <ul>
            <li className='pointer'
                onClick={performOnClick}>
                {props.text}
                {props.children}
            </li>
        </ul>
    </Portal>);
}