export enum ReportOutputType {
    Table = 0,
    BarChart = 1,
    PieChart = 2,
}

export interface IReportColumn {
    name: string;
    title?: string;
    className?: string;
    delegate?: string;
    style?: string;
    hidden?: boolean;
}

export interface IReportOutputAction {
    action: string;
    title?: string;
    parameter?: string;
    icon?: string;
}

export interface IReportDefinition {
    name: string;
    title: string;
    hidden?: boolean;
    icon?: string;
    className?: string;

    outputs: IReportOutput[];
}

export interface IReportOutput {
    searchName: string;
    autoStart?: boolean;
    outputType: ReportOutputType;
    columns?: IReportColumn[];
    actions?: IReportOutputAction[];
    canExport?: boolean;
}
