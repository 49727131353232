import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as WorkersStore from '@store/workers';
import { EditWorker } from '@components/workers/EditWorker';
import { isContractorWorkerPropertyGroupType } from '@models';
import * as RequirementsStore from '@store/requirements';

export default connect(
    (state: ApplicationState, props: any) => ({
        workId: props.workId ?? parseInt(props.match.params.workid),
        workerId: props.workerId ?? parseInt(props.match.params.workerid),
        ...state.identity,
        ...state.user,
        ...WorkersStore.plainActions,
        ...RequirementsStore.plainActions,
        moduleManager: state.modules.moduleManager,
        requirementGroups: state.user.requirementGroups,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isContractorWorkerPropertyGroupType),
        resourceStatusTypes: state.user.resourceStatusTypes,
    }),
    WorkersStore.actionCreators,
)(EditWorker as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
