import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';

import { AdminWorkConfiguration, IProps } from '../../components/admin/AdminWorkConfiguration';

export default connect(
    (state: ApplicationState) => ({
        ...Admin.plainActions,
    }),
    Admin.actionCreators,
)(AdminWorkConfiguration as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
