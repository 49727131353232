import * as React from 'react';

import { LoadingBox } from '@components';
import { IUserIdentity } from '@models';

export interface IProps {
    children: any;
    loading: boolean;
    loadingMessage: string;
    users: IUserIdentity[];
    //
    initialize: Function;
}

export function AdminLoader(props: IProps) {
    React.useEffect(() => {
        if (props.users.length === 0) {
            props.initialize();
        }
    }, []);

    if (props.loading) {
        return <LoadingBox message={props.loadingMessage} />
    }
    else {
        return props.children;
    }
}