import { Action, Reducer } from 'redux';

import { IMessage, MessageType, INotification, NotificationType } from '../models';
import * as Actions from './actions/notifications';
import { ApplicationState } from '../store';

export interface INotificationsState {
    notifications: INotification[];
    messages: IMessage[];
}

enum Types {
    OnMessage = '[NOTIFICATIONS] ON MESSAGE',
    ClearMessage = '[NOTIFICATIONS] CLEAR MESSAGE',
    ClearMessages = '[NOTIFICATIONS] CLEAR MESSAGES',
    SetMessagesAction = '[NOTIFICATIONS] SET MESSAGES',
    NotificationAdd = '[NOTIFICATIONS] ADD',
    NotificationSuccess = '[NOTIFICATIONS] SUCCESS',
    ClearErrorsAction = '[NOTIFICATIONS] CLEAR ERROR',
    RemoveNotificationAction = '[NOTIFICATIONS] REMOVE NOTIFICATION ACTION',
    StopLoadingAction = '[NOTIFICATIONS] STOP LOADING',
}

export interface AddNotificationAction {
    type: Types.NotificationAdd;
    notification: INotification;
}

export interface ClearErrorNotificationAction {
    type: Types.ClearErrorsAction;
    ctx?: string;
    action?: string;
}

export interface SetMessagesAction {
    type: Types.SetMessagesAction;
    messages: IMessage[];
}

export interface RemoveNotificationAction {
    type: Types.RemoveNotificationAction;
    id: number;
}

export interface StopLoadingAction {
    type: Types.StopLoadingAction;
    ctx?: string;
    action?: string;
}

export interface SuccessAction {
    type: Types.NotificationSuccess;
    ctx?: string;
    action?: string;
    message?: string;
    id?: number;
}

export interface OnMessage {
    type: Types.OnMessage,
    message: IMessage
};

export interface ClearMessage {
    type: Types.ClearMessage,
    id: string
};

export interface ClearMessages {
    type: Types.ClearMessages,
    ids: string[]
};

export type KnownAction =
    AddNotificationAction
    | ClearErrorNotificationAction
    | StopLoadingAction
    | RemoveNotificationAction
    | SuccessAction
    | OnMessage
    | ClearMessage
    | ClearMessages
    | SetMessagesAction;

const excludeMatchingNotifications = (
    e: Partial<INotification>,
    notifications: INotification[],
    type: NotificationType) => {

    return notifications.filter(n => {
        if (e.id) {
            return n.id != e.id;
        }
        else if (e.ctx && e.action && n.type == type) {
            return n.ctx != e.ctx || n.action != e.ctx;
        }
        else if (e.ctx && n.type == type) {
            return n.ctx != e.ctx;
        }
        else {
            return true;
        }
    });
}

const createNotification = (notif: Partial<INotification>, dispatch: Function) => {
    const id = (new Date().getTime());
    const n = { ...notif, id };

    if (n.delay) {
        setTimeout(() => {
            dispatch({
                type: Types.RemoveNotificationAction,
                id: n.id
            });

            if (n.onComplete) {
                n.onComplete();
            }
        }, n.delay);
    }

    dispatch({
        type: Types.NotificationAdd,
        notification: n
    });
}

export const actionCreators = {
    success: (notif: Partial<INotification>) => (dispatch: Function) => {
        dispatch({
            type: Types.NotificationSuccess,
            ctx: notif.ctx,
            action: notif.action,
            message: notif.message,
        });
    },
    clearErrors: (notif: Partial<INotification>) => (dispatch: Function) => {
        dispatch({
            type: Types.ClearErrorsAction,
            ctx: notif.ctx,
            action: notif.action,
        });
    },
    startLoading: (notif: Partial<INotification>) => (dispatch: Function) => {
        createNotification({
            ...notif,
            type: NotificationType.LOADING
        }, dispatch);
    },
    stopLoading: (notif: Partial<INotification>) => (dispatch: Function) => {
        dispatch({
            type: Types.StopLoadingAction,
            ctx: notif.ctx,
            action: notif.action,
        });
    },
    add: (notif: Partial<INotification>) => (dispatch: Function) => {
        createNotification(notif, dispatch);
    },
    error: (notif: Partial<INotification>) => (dispatch: Function) => {
        createNotification({ ...notif, type: NotificationType.ERROR }, dispatch);
    },
    remove: (id: number) => (dispatch: Function) => {
        dispatch({
            type: Types.RemoveNotificationAction,
            id
        });
    },
    removeNotification: (id: number) => (dispatch: Function) => {
        dispatch({
            type: Types.RemoveNotificationAction,
            id
        });
    },
    addMessage: (message: IMessage) => (dispatch: Function, getState: () => ApplicationState) => {
        const state = getState();
        if (message.data?.WorkId == undefined
            || state.user.work?.id == undefined
            || message.data?.WorkId == state.user.work?.id) {
            dispatch({
                type: Types.OnMessage,
                message,
            });
        }
    },
    loadMessages: () => async (dispatch: Function, getState: () => ApplicationState) => {
        const state = getState();
        const messages = await Actions.getMessages() ?? [];
        const selectedWork = state.user.work;

        // const filteredMessages = messages;
        const filteredMessages = messages
            .filter((m: IMessage) => m.notificationType != MessageType.RELOAD_NOTIFICATIONS
                && (m.data?.WorkId == undefined
                    || state.user.work?.id == undefined
                    || m.data?.WorkId == selectedWork?.id));

        dispatch({
            type: Types.SetMessagesAction,
            messages: filteredMessages,
        });
    },
    clearMessage: (id: string) => async (dispatch: Function) => {
        await Actions.resolveNotification(id);

        dispatch({
            type: Types.ClearMessage,
            id,
        });
    },
    clearMessages: (ids: string[]) => async (dispatch: Function) => {
        await Actions.resolveNotifications(ids);

        dispatch({
            type: Types.ClearMessages,
            ids,
        });
    }
};

export const reducer: Reducer<INotificationsState> = (state: INotificationsState | undefined, incomingAction: Action): INotificationsState => {
    if (state === undefined) {
        return { notifications: [], messages: [], };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case Types.OnMessage:
            return { ...state, messages: state.messages.concat([action.message]) };

        case Types.ClearMessage:
            return { ...state, messages: state.messages.filter(m => m.id != action.id) };

        case Types.ClearMessages:
            return { ...state, messages: state.messages.filter(m => action.ids.indexOf(m.id) == -1) };

        case Types.SetMessagesAction:
            return { ...state, messages: action.messages };

        case Types.NotificationSuccess:
            const notifs = excludeMatchingNotifications(
                { ctx: action.ctx, action: action.action },
                state.notifications,
                NotificationType.SUCCESS);

            return {
                messages: state.messages,
                notifications: notifs.concat({
                    id: action.id ?? (new Date().getTime()),
                    message: action.message ?? '',
                    ctx: action.ctx,
                    action: action.action,
                    type: NotificationType.SUCCESS,
                })
            }

        case Types.NotificationAdd:
            return {
                messages: state.messages,
                notifications:
                    state
                        .notifications
                        .concat(action.notification)
            };
        case Types.ClearErrorsAction:
            return {
                messages: state.messages,
                notifications:
                    excludeMatchingNotifications(
                        { ctx: action.ctx, action: action.action },
                        state.notifications,
                        NotificationType.ERROR)
            }
        case Types.RemoveNotificationAction:
            return {
                messages: state.messages,
                notifications:
                    state
                        .notifications
                        .filter(n => n.id != action.id)
            };
        case Types.StopLoadingAction:
            return {
                messages: state.messages,
                notifications:
                    excludeMatchingNotifications(
                        { ctx: action.ctx, action: action.action },
                        state.notifications,
                        NotificationType.LOADING)
            };
        default:
            return state;
    }
};
