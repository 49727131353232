import * as React from 'react';

import {
    ConfirmDialog, Message, useDataTable, useFormDialog, useLoading, useMessage, useTranslation
} from '@components';
import { IWorkerDocumentType } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { WorkerDocumentTypeForm } from './WorkerDocumentTypeForm';

export interface IProps {
    workId: number;
}

export function AdminWorkerDoucmentType(props: IProps) {
    const { t } = useTranslation();
    const msg = useMessage();

    const [error, setError] = React.useState<string|undefined>();
    const [workerDocumentTypes, setWorkerDocumentTypes] = React.useState<IWorkerDocumentType[]>([]);
    const loading = useLoading(true);
    const formDialog = useFormDialog({
        editTitle: t('Edit worker document type'),
        addTitle: t('Add worker document type'),
    });
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IWorkerDocumentType|undefined>();

    const initialize = loading.wrap(async() => {
        const res = await Actions.getWorkerDocumentTypes(props.workId);

        setWorkerDocumentTypes(res.data.work.workerDocumentTypes);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const saveWorkerDocumentType = loading.wrap(async (w: IWorkerDocumentType) => {
        const res = await Actions.saveWorkerDocumentType(w);
        msg.set(res);
        await initialize();

        formDialog.clear();
    });

    const removeWorkerDocumentType = loading.wrap(async (workId: number, id: number) => {
        setError(undefined);

        workId = props.workId;
        const res = await Actions.removeWorkerDocumentType(workId, id);
        msg.set(res);
        await initialize();

        formDialog.clear();
    });

    const dataTable = useDataTable({
        data: workerDocumentTypes,
        className: 'e',
        loading,
        columns: [{field: 'id', className:'td-sm'}, 'name'],
        actions: [
            { icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit},
            { icon: 'trash', tooltip: t('Delete'), onClick: setShowConfirmRemove}
        ]
    });

    return <div className='he c'>
        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeWorkerDocumentType(showConfirmRemove.workId, showConfirmRemove.id)} />}

        {formDialog.render((data: IWorkerDocumentType) =>
            <WorkerDocumentTypeForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveWorkerDocumentType}
                footer={formDialog.Footer()} /> )}

        {dataTable()}
        <div className='r sm pd footer'>
            {error && <Message severity='error' text={error}/>}
            {msg.renderIfError()}
            <span className='e'></span>
            <button className='p-button'
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right'/>
                {t('Add')}
            </button>
        </div>
    </div>
}