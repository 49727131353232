import * as React from 'react';

import {
    BreadcrumbItem, ValidationBuilder, useDataTable,
    usePaginator, useTranslation,
    useMessage, useLoading, useForm, G, SplitButton,
    FieldsBox,
    useResolveName,
    useWorkFormSettings,
    useConfirm,
    useSearchPaginate,
    useSearchFilters,
    Tag,
    useInputSearch,
    Tooltip,
    useToast,
    useDialogs
} from '@components';

import {
    IContractor, ISecurity, IWork, IPropertyGroupType,
    IActivityType, ILegalForm, IConstants, IResourceStatusType, IUserIdentity, ResourceType, IOption
} from '@models';
import CreateContractorInvitationContainer from '@containers/contractors/CreateContractorInvitationContainer';
import ViewContractorInvitationsContainer from '@containers/contractors/ViewContractorInvitationsContainer';

import './ManageContractors.scss';
import { ResourceStatus } from '@components/common/ResourceStatus';
import { contractorsRoute, goToContractor, goToContractorRequirements } from '@components/routes';
import { ModuleManager } from '@modules';
import AddContractor from './AddContractor';
import RestrictedContractors from './RestrictedContractors';
import RestrictContractor from './RestrictContractor';

type InvalidateContractorF = (workId: number, id: number) => Promise<IOption<boolean>>;

export interface IProps {
    activityTypes: IActivityType[];
    constants: IConstants;
    modules: ModuleManager;
    contractor: IContractor;
    getContractors: Function;
    legalForms: ILegalForm[];
    invalidateContractor: InvalidateContractorF;
    propertyGroupTypes: IPropertyGroupType[];
    resourceStatusTypes: IResourceStatusType[];
    saveContractor: Function;
    security: ISecurity;
    work: IWork;
    appUsers: IUserIdentity[];
    reactivateContractor: Function;
    removeRestrictedContractor: Function;
    restrictContractor: Function;
}


enum FormType {
    List = 0,
    CreateInvitation = 1,
    ViewInvitations = 2,
    CreateContractor = 3,
    RestrictedContractors = 4
}

type IContractorResult = IContractor & {
    properties: any;
    email?: string | string[];
    validatedCount?: number;
    totalCount?: number;
}

const parseContractor = (p: IContractorResult) => {
    const properties = p.properties ? JSON.parse(p.properties) : ({});
    return { ...p, properties };
}

export function ManageContractorsImpl(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();
    const dialogs = useDialogs();

    const settings = useWorkFormSettings({ work: props.work, scope: 'contractor' });

    const filterKey = `filters.contractors[workId=${props.work.id}]`;

    const filter = useInputSearch({ filterKey });
    const [formType, setFormType] = React.useState<FormType>(FormType.List);

    const toXml = () => paginateData.exportToXls(
        userFilters.merge({ name: filter.value, work_id: props.work.id }),
        t('contractors.export.filename'), true)

    const userFilters = useSearchFilters({
        workId: props.work.id,
        name: 'contractors/list',
        references: {
            workspace: props.work.workspaces
        },
        persist: true,
        getXml: toXml
    });

    React.useEffect(() => {
        const keyFilters = Object.keys(userFilters.filters);
        keyFilters.forEach((key: string) => {
            if (userFilters.filters[key] == false && key !== 'active') {
                userFilters.setFilter(key, null);
            } else if (key == 'active' && userFilters.filters[key] == null) {
                userFilters.setFilter('active', true);
            }
        });
    }, [userFilters.filters]);

    const paginateData = useSearchPaginate<IContractorResult>({
        search: 'contractors/list',
        workId: props.work.id,
        map: parseContractor,
        filters: userFilters.merge({ name: filter.value, work_id: props.work.id }),
        dependencies: [filter.value, userFilters.filters],
        lazy: true,
        limit: props.work.id == 21 ? 100 : 10,
    });

    const invalidateContractor = loading.wrap(async (contractor: IContractor) => {
        await props.invalidateContractor(props.work.id, contractor.id);
        initialize();
    });

    const confirmInvalidate = useConfirm({
        message: t('contractors.invalidate.confirm'),
        accept: invalidateContractor,
    });

    const doActivateContractor = loading.wrap(async (i: IContractor) => {
        await props.reactivateContractor(props.work.id, i.id);
        await initialize();
    });

    const confirmActivate = useConfirm({
        message: t('resource.activate.contractor.confirm'),
        accept: doActivateContractor
    });


    const initialize = loading.wrap(async () => {
        setFormType(FormType.List);
        await paginateData.doSearch();
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const selectContractor = (contractor: IContractor) => {
        goToContractor(props.work.id, contractor.id!);
    }

    const loadRequirements = (contractor: IContractor) => {
        goToContractorRequirements(props.work.id, contractor.id!);
    }

    const resolveLegalForm = useResolveName(props.legalForms, { translate: true });
    const resolveActivityType = useResolveName(props.activityTypes, { translate: true });

    const renderStatus = (contractor: IContractor) =>
        <ResourceStatus
            currentStatusType={contractor.currentStatusType!}
            resourceId={contractor.id}
            resourceType={ResourceType.Contractor}
            workId={props.work.id} />;

    const renderLegalForm = (c: IContractor) => {
        const legalForm = resolveLegalForm(c.legalFormId);
        return <div>
            <span title={legalForm}>{legalForm}</span>
        </div>;
    }

    const renderActivityType = (c: IContractor) => {
        return <div>
            <span>{resolveActivityType(c.activityTypeId)}</span>
        </div>
    }

    const renderEmail = (c: IContractorResult) => {

        return <div>
            {Array.isArray(c.email) ? c.email.map((email: string, index: number) => {
                if (index == c.email!.length - 1) {
                    return <span className={`manage-contractor-${props.work.id}`} title={`${email}`} key={index}>{email}</span>
                }
                else {
                    return <span className={`manage-contractor-${props.work.id}`} title={`${email}`} key={index}>{email}, </span>
                }
            }) : <span className={`manage-contractor-${props.work.id}`} title={`${c.email}`}>{c.email}</span>}
        </div>
    };

    const toast = useToast();

    const handleToast = (message: string, success: boolean) => {
        if (success) {
            toast.show(message)
        }
        else {
            toast.error(message)
        }
    }


    const table = useDataTable<IContractorResult>({
        columns: [
            { title: t('Name'), render: d => <span className={`manage-contractor-${props.work.id}`} title={d.name}>{d.name}</span>, className: 'td-lg', sortKey: 'name' },
            ...props.modules.injectDataColumns('manageContractors.list', { workId: props.work.id }),
            {
                title: t('Email'), render: c => renderEmail(c),
                className: 'td-md center important', sortKey: 'email'
            },
            settings.show('legalFormId')
                ? { title: t('Legal form'), render: renderLegalForm, className: 'td-md', sortKey: 'legalFormName' }
                : undefined,
            { title: t('contractor.code'), field: 'code', className: 'td-md codes', sortKey: 'code' },
            settings.show('activityTypeId')
                ? { title: t('Activity type'), render: renderActivityType, className: 'td-md' }
                : undefined,
            { title: t('Provider code'), field: 'providerCode', className: 'td-md codes' },
            { title: t('Phone'), field: 'phone', className: 'td-md codes' },
            props.work.id != 19
                ? {
                    title: 'Documentation', className: 'td-indicator indicator',
                    render: d =>
                        <span onClick={() => goToContractorRequirements(props.work.id, d.id)} className='pointer'>
                            <Tag value={`${d?.validatedCount ?? 0}/${d?.totalCount ?? 0}`} />
                        </span>,
                }
                : undefined,
            settings.show('currentStatusTypeId')
                ? { title: 'Status', field: 'currentstatustype', render: renderStatus, className: 'td-md' }
                : undefined
            // ...columnProperties,
        ],
        actions: [
            {
                actions: [
                    // TODO: Controlar quién puede ver los datos del contratista - Item #906
                    { title: t('View data'), onClick: selectContractor },
                    { title: t('Requirements'), onClick: loadRequirements },
                    props.security.hasPermission('contractors.force.status') ?
                        {
                            title: t('contractors.invalidate'),
                            disabled: r => r.isActive == false,
                            onClick: confirmInvalidate,
                        } : undefined,
                    props.security.hasPermission('contractors.force.status')
                        ? { title: t('resource.activate'), disabled: r => r.isActive == true, onClick: confirmActivate }
                        : undefined,
                    //TODO: ¿Eliminar contratistas? - Item #906
                    (props.security.hasPermission('restrict.resources'))
                        ? { title: t('restrict.contractor'), onClick: r => dialogs.show('restrict-contractor', { workId: props.work.id, contractorId: r.id }) }
                        : undefined
                ]
            }
        ],
        tableStyle: {height: 'fit-content'},
        data: paginateData.data,
        onSort: (key, order) => paginateData.sortBy(key, order),
        denyLocalSort: true,
    });

    const paginator = usePaginator<IContractorResult>({
        data: paginateData.value,
        onChange: (page, limit) =>
            paginateData.doSearch(userFilters.merge({ name: filter.value, work_id: props.work.id }), page, limit),
        onExport: toXml,
    });

    const getActions = () => {
        const actions = [];
        const security = props.security;

        actions.push({
            label: t('contractors.list'),
            command: () => setFormType(FormType.List),
        });

        if (security.hasPermission('contractors.add')) {
            actions.push({
                label: t('contractors.add'),
                command: () => setFormType(FormType.CreateContractor),
            });
        }

        if (security.hasPermission('contractors.invite')) {
            actions.push({
                label: t('contractors.invite'),
                command: () => setFormType(FormType.CreateInvitation),
            });
            if (security.hasPermission('contractors.invitations.list')) {
                actions.push({
                    label: t('contractors.invitations.list'),
                    command: () => setFormType(FormType.ViewInvitations),
                });
            }
        }

        if (props.security.hasPermission('restrict.resources')) {
            actions.push({
                label: t('restrict.contractors.add'),
                command: () => setFormType(FormType.RestrictedContractors),
            });
        }


        return actions;
    }

    const actions = getActions();


    return <div className='ManageContractors'>
        {toast.render()}
        <BreadcrumbItem
            redirectTo={contractorsRoute(props.work.id)}
            onClick={() => setFormType(FormType.List)}
            text={t('Contractors')} />

        {confirmInvalidate}

        <div className='h100p c he'>
            <div className='c he r '>
                <div className={`p-inputgroup toolbar render-${formType}-${props.work.id}`} >
                    <SplitButton
                        className='flat'
                        label={t('Actions')}
                        model={actions} />
                    {filter.render()}
                    {userFilters.renderAsButton()}
                    {loading.renderBox()}
                    <span className='p-inputgroup-addon'>
                        <i className='pi pi-search' />
                    </span>
                </div>
                {formType === FormType.List && <>

                    {dialogs.render('restrict-contractor', {
                        title: t('restrict.contractor.add'),
                        className: 'g pd',
                        style: { width: '60vw' }
                    }, (r: any) =>
                        <RestrictContractor
                            {...props}
                            selectedContractorId={r.contractorId}
                            data={paginateData.data}
                            restrictContractor={props.restrictContractor}
                            handleToast={handleToast}
                            onClose={dialogs.clear}
                        />
                    )}
                    <div>
                        {table()}
                    </div>
                    <div className='e' />
                    {paginator()}
                </>}

                {formType === FormType.CreateInvitation && <>
                    <div className='md mr pd '>
                        <h2 className='box-title'>{t('contractors.invite.message')}</h2>
                        <CreateContractorInvitationContainer
                            onCancel={() => setFormType(FormType.List)}
                            onCreate={() => setFormType(FormType.List)} />
                    </div>
                </>}

                {formType === FormType.ViewInvitations && <>
                    {/* //TODO: Controlar quién puede ver las invitaciones a contratistas - Item #906 */}
                    <ViewContractorInvitationsContainer />
                </>}

                {formType === FormType.CreateContractor && <>
                    <div className='md mr pd box'>
                        <AddContractor
                            constants={props.constants}
                            security={props.security}
                            activityTypes={props.activityTypes}
                            legalForms={props.legalForms}
                            onChange={initialize}
                            propertyGroupTypes={props.propertyGroupTypes}
                            saveContractor={props.saveContractor}
                            workId={props.work.id}
                            fieldValidations={props.work.validations!}
                            work={props.work} />
                    </div>
                </>}
                {formType === FormType.RestrictedContractors && <>
                    <RestrictedContractors
                        workId={props.work.id}
                        removeRestrictedContractor={props.removeRestrictedContractor}
                        handleToast={handleToast}
                    />
                </>}
            </div>
        </div>
    </div>
}


export const ManageContractors = React.memo(ManageContractorsImpl, (prev, next) => {
    return prev.work.id === next.work.id;
});