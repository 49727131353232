import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import WorkerProfile from '../components/WorkerProfile';
import * as UserStore from '../store/user';
import * as WorkerStore from '../store/workers';

export default connect(
    (state: ApplicationState) => ({
        constants: state.constants,
        workId: state.user?.work?.id,
        user: state.identity?.user,
        ...UserStore.plainActions,
        ...WorkerStore.plainActions,
    }),
    {},
)(WorkerProfile as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
