import * as React from 'react';

import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { RequirementTargetType } from '@models/requirements';

export interface IProps {
    workerId: number;
    workerName: string;
    workId: number;
}

export function WorkerRequirements(props: IProps) {
    return <EditRequirements
        targetId={props.workerId}
        targetTitle={props.workerName}
        targetType={RequirementTargetType.Worker}
        workId={props.workId} />
}