import * as React from 'react';

import {
    Message, useConfirm, useDialogs, useFormDialog, useLoading, useTranslation, useTreeTable
} from '@components';
import { IMachineryType, IMachinerySubType } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { MachineryTypeForm } from './MachineryTypeForm';
import { MachinerySubTypeForm } from './MachinerySubTypeForm';

export interface IProps {
    workId: number;
}

export function AdminMachineryType(props: IProps){
    const { t } = useTranslation();

    const [error, setError] = React.useState<string|undefined>();
    const [machineryTypes, setMachineryTypes] = React.useState<IMachineryType[]>([]);
    const loading = useLoading(true);
    const dialogs = useDialogs();

    const confirmRemove = useConfirm({
        message: t('Are you sure to delete the element ?'),
        accept: (obj: any) => {
            if (obj.__typename === 'MachineryType') {
                removeMachineryType(props.workId, obj.id)
            } else {
                removeMachinerySubType(props.workId, obj.parentId, obj.id)
            }
        }
    });

    const initialize = loading.wrap(async() => {
        const res = await Actions.getMachineryTypes(props.workId);

        setMachineryTypes(res.data.work.machineryTypes);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const saveMachineryType = loading.wrap(async (m: IMachineryType) => {
        await Actions.saveMachineryType(m);
        await initialize();

        dialogs.clear();
    });

    const removeMachineryType = loading.wrap(async (workId: number, id: number) => {
        setError(undefined);

        workId = props.workId;

        await Actions.removeMachineryType(workId, id);
        await initialize();
    });

    // MACHINERY SUBTYPE ACTIONS
    const saveMachinerySubType = loading.wrap(async (m: IMachinerySubType) => {
        await Actions.saveMachinerySubType(props.workId, m);
        await initialize();

        dialogs.clear();
    });

    const removeMachinerySubType = loading.wrap(async (workId: number, parentId: number, id: number) => {
        setError(undefined);

        workId = props.workId;

        await Actions.removeMachinerySubType(workId, parentId, id);
        await initialize();
    });

    const dataTable = useTreeTable({
        data: machineryTypes,
        childrenProperty: 'subTypes',
        columns: [
            { title: 'Name', field: 'name', className: ' e' },
        ],
        actions: [
            { icon: 'fa fa-plus', headerClassName: 'td-sm', onClick: (r: any) => dialogs.show('add-subtype', r), disabled: (r: any) => r.__typename != 'MachineryType'},
            {
                icon: 'pencil',
                tooltip: t('Edit'),
                headerClassName: 'td-sm',
                onClick: (r: any) => {
                    if (r.__typename === 'MachineryType') {
                        dialogs.show('edit-machinery-type', r);
                    }
                    else {
                        dialogs.show('edit-machinery-subtype', r);
                    }
                }
            },
            {
                icon: 'trash',  tooltip: t('Delete'), onClick: confirmRemove, headerClassName: 'td-sm',
            }
        ]
    });

    return <div className='he c'>
        {dialogs.render('add-subtype', { title: t('Add machinery subtype') }, (mt: IMachineryType) => <span>
            <MachinerySubTypeForm
                onSubmit={saveMachinerySubType}
                parentId={mt.id}
                workId={props.workId} />
        </span>)}

        {dialogs.render('edit-machinery-subtype', {title: t('Edit machinery subtype')}, (data: IMachinerySubType) =>
            <MachinerySubTypeForm
                data={data}
                parentId={data.parentId}
                workId={props.workId}
                onSubmit={saveMachinerySubType} />)}

        {dialogs.render('edit-machinery-type', {title: t('Edit machinery type')}, (data: IMachineryType) =>
            <MachineryTypeForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveMachineryType} />)}
        {dialogs.render('add-machinery-type', { title: t('Add machinery type') }, (data: IMachineryType) =>
            <MachineryTypeForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveMachineryType} />)
        }

        <div className='e'>
            {dataTable()}
        </div>

        <div className='r sm pd footer'>
            {error && <Message severity='error' text={error} />}
            <span className='e'></span>
            <button className='p-button'
                onClick={() => dialogs.show('add-machinery-type')}>
                <i className='pi pi-plus sm pd-right'/>
                {t('Add')}
            </button>
        </div>
    </div>
}