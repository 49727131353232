import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { RequirePermission } from '../../components/custom/RequirePermission';
import { hasPermission } from '../../utils/security';

type Props = {
    explicit?: boolean;
}

export default connect(
    (state: ApplicationState, ownProps: any) => ({
        ...state.identity,
        hasPermission: (p: string, ctx: any = {}) => hasPermission(p, {
            user: state.identity?.user,
            work: state.user.work,
            explicit: ownProps.explicit !== false,
            ...ctx
        }),
    }),
    {},
)(RequirePermission as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
