import * as React from 'react';

import { Loading, Message, Select, useLoading, useNumberState, useTranslation } from '@components';

type IColumn = string | Function | { title?: string; field: string; body?: Function; };

export interface IProps<S, D> {
    columns: IColumn[];
    error?: string;
    loading?: boolean;
    loadRelations: Function; // -> Promise<D[]>|D[]
    loadTargets: Function;

    onAdd: Function;
    onRemove: Function;

    source: S;

    targetLabel?: string;
    targetValue?: string;
}

export function ManageManyToManyRelations<S, D>(props: IProps<S, D>) {
    const { t } = useTranslation();

    const loading = useLoading(false);
    const [relations, setRelations] = React.useState<D[]>([]);
    const [targets, setTargets] = React.useState<D[]>([]);
    const [requestRemove, setRequestRemove] = React.useState<D|undefined>();
    const selectedTarget = useNumberState();

    const initializeTargets = () => {
        const res = props.loadTargets();

        if (res.then) {
            res.then(setTargets);
        }
        else {
            setTargets(res);
        }
    }

    const initialize = () => {
        const res = props.loadRelations();

        if (res.then) {
            res.then(setRelations);
        }
        else {
            setRelations(res);
        }
    }

    React.useEffect(() => {
        initialize();
        initializeTargets();
    }, []);

    const doRemove = loading.wrap(async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        await props.onRemove(requestRemove);

        await initialize();

        setRequestRemove(undefined);
    });

    const renderHeader = (l: IColumn, i: number) =>
        <th key={i} className='header sm pd'>
            {typeof(l) === 'string' && t(l)}
            {typeof(l) === 'object' && l.title && t(l.title)}
        </th>;

    const renderRow = (l: IColumn, i: number, d: any) => {
        return <td key={i} className='text-ellision'>
            {typeof(l) === 'string' && <span>{d[l]}</span>}
            {typeof(l) === 'object' && l.body == undefined && d[l.field]}
            {typeof(l) === 'object' && l.body != undefined && l.body(d)}
        </td>;
    }

    const doAdd = loading.wrap(async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        await props.onAdd(
            targets.find((t: any)=> t[props.targetValue || 'id'] == selectedTarget.value));
        initialize();
    });

    return <div>
        {props.error &&
            <Message severity='error' text={props.error} />}

        {loading.render()}

        {requestRemove && <div className='bg-danger md pd'>
            <div className='bg-danger'>{t('Are you sure to delete the relation?')}</div>

            <div className='r md mr-top'>
                <div className='e'></div>
                <button onClick={() => setRequestRemove(undefined)}>
                    {t('Cancel')}
                </button>
                <button className='danger' onClick={doRemove}>
                    {t('Delete')}
                </button>
            </div>
        </div>}

        <table className='table bordered w-100p'>
            <thead>
                <tr>
                    {props.columns.map((l, i) => renderHeader(l, i))}
                    <th className='header sm pd'></th>
                </tr>
            </thead>
            <tbody>
                {relations.map((d, i) =>
                    <tr
                        key={i}>
                        {props.columns.map((l, i) => renderRow(l, i, d))}
                        <td className='td-sm center'>
                            <i className='pointer pi pi-trash'
                                onClick={() => setRequestRemove(d)}></i>
                        </td>
                    </tr>)}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={props.columns.length + 1}>

                        <div className='r we'>
                            <Select options={targets}
                                className='e'
                                optionLabel={props.targetLabel ?? 'name'}
                                optionValue={props.targetValue ?? 'id'}
                                value={selectedTarget.value}
                                onChange={selectedTarget.set}
                                filter />
                            <button
                                className='pi-button'
                                disabled={!selectedTarget.hasValue()}
                                onClick={doAdd}>
                                {t('Add')}
                            </button>
                            {props.loading && <Loading />}
                        </div>

                    </td>
                </tr>
            </tfoot>
        </table>
    </div>;
}