import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';
import * as App from '../../store/app';

import { AdminWorkUsers, IProps } from '../../components/admin/AdminWorkUsers';
import { NotificationType } from '../../models';

type Props =
    Admin.IAdminState &
    App.IAppState &
    IProps;

export default connect(
  (state: ApplicationState) => ({
      users: state.admin.users,
      notifications: state.notifications.notifications
        .filter(n => n.ctx === 'adminworkusers'),
      loadingNotifications: state.notifications.notifications
        .filter(n => n.ctx === 'adminworkusers' && n.type === NotificationType.LOADING),
      loading: state.app.loading,
      loadingMessage: state.app.loadingMessage
  }),
  Admin.actionCreators,
)(AdminWorkUsers as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
