import * as React from 'react';
import { useHistory } from 'react-router-dom';

const SessionChecker = () => {

    const history = useHistory();

    React.useEffect(() => {

        const checkSession = () => {

        const loginTime = sessionStorage.getItem('loginTime');

        if(loginTime){
            const currentTime = Date.now();
            const diff = currentTime - parseInt(loginTime);
            const sessionDuration = 1800000;

            if(diff > sessionDuration){
                sessionStorage.removeItem('loginTime');
                window.location.href = '/';
                window.location.reload();

            }
        }
    }

    const intervalId = setInterval(checkSession, 60000);

    return () => clearInterval(intervalId);

    }, [history]);


    return <></>;
};

export default SessionChecker;