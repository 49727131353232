import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ReportComponent } from '@components/reports/ReportComponent';

import * as User from '@store/user';

export default connect(
    (state: ApplicationState, props: any) => {
        const reportName = props.reportName || props.match?.params?.reportName;

        return {
            reportName: reportName,
            report: state.user.reports.find(r => r.name == reportName),
            workId: props.workId ?? parseInt(props.match?.params?.workid),
            ...state.user,
            ...state.identity,
            ...User.plainActions,
            resourceStatusTypes: state.user.resourceStatusTypes,
            workspace: state.user.workspaces,
            filterParameters: props.filterParameters ?? [],
            moduleManager: state.modules.moduleManager,
        };
    },
)(ReportComponent as any) as any;
