import * as React from 'react';

import { IUserIdentity } from '@models';

export interface IProps {
    children: any;
    exclude?: string;
    role?: string;
    oneOf?: string[];
    user: IUserIdentity;
}

export function RequireRole({children, oneOf, user, role, exclude}: IProps) {
    if (oneOf != undefined && oneOf.find(o => user.roles.includes(o)) != undefined) {
        return children;
    }
    if (exclude != undefined && !user.roles.includes(exclude)) {
        return children;
    }
    else if (role != undefined && user.roles.includes(role)) {
        return children;
    }
    else {
        return null;
    }
}
