import * as React from 'react';

import { InputSwitch, useLoading, useTranslation } from '@components';
import { IWork, IModuleInfo, IWorkHasModule } from '@models';
import { WorkToolbar } from '../WorkToolbar';

export interface IProps {
    availableModules: IModuleInfo[];
    saveWorkHasModule: Function;
    work: IWork;
}

type ModuleRelation = {
    workHasModule: IWorkHasModule,
    moduleInfo: IModuleInfo,
}

export function ManageWorkModules(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();

    const [data, setData] = React.useState<ModuleRelation[]>([]);

    React.useEffect(() => {
        const relations = props.work.workHasModules ?? [];
        const allModules = props.availableModules ?? [];

        const workRelations = allModules
            .map(m => {
                const r = relations.find(r => r.moduleName == m.id);
                return {
                    moduleInfo: m,
                    workHasModule: {
                        workId: props.work.id,
                        moduleName: m.id,
                        isActive: r?.isActive ?? false,
                    },
                };
            });
        setData(workRelations);
    }, [props.availableModules, props.work]);


    const saveModuleRelation = loading.wrap((raw: ModuleRelation) => {
        props.saveWorkHasModule(props.work.id, raw.workHasModule);
    });

    const toggleModuleRelation = (raw: ModuleRelation) => {
        const newRel = {...raw, workHasModule: {
                ...raw.workHasModule,
                isActive: !(raw.workHasModule?.isActive ?? false)
            }
        };

        saveModuleRelation(newRel);

        return newRel;
    }

    const toggleWorkHasModule = (raw: ModuleRelation) => {
        return (e: any) => {
            setData(d =>
                d.map(r => r.moduleInfo.id == raw.moduleInfo.id
                    ? toggleModuleRelation(raw)
                    : r));
        }
    }

    return <div className='c e md pd'>
        <WorkToolbar work={props.work} />

        <div className='page-subtitle'>
            <strong>{t('work.modules')}</strong>
            {loading.render()}
        </div>

        <div className='list-container margin-auto'>
            <table className='table p-datatable p-component'>
                <thead className='p-datatable-thead'>
                    <tr>
                        <th></th>
                        <th>{t('Name')}</th>
                        <th>{t('EntryPoint')}</th>
                        <th>{t('Description')}</th>
                        <th>{t('Version')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((d, k) => <tr key={k} className='relation-row'>
                        <td className='sm pd'>
                            <InputSwitch
                                checked={d.workHasModule.isActive}
                                onChange={toggleWorkHasModule(d)} />
                        </td>
                        <td className='sm pd'>{d.moduleInfo.id}</td>
                        <td className='sm pd'>{d.moduleInfo.name}</td>
                        <td className='sm pd'>{d.moduleInfo.description}</td>
                        <td className='sm pd'>{d.moduleInfo.version}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}
