import * as React from 'react';

import { ICountry, INotification, IWork } from '@models';
import {
    G,
    NotificationsBox,
    useForm,
    useTranslation,
    ValidationBuilder
} from '@components';

import './WorkForm.css';

export interface IProps {
    countries: ICountry[];
    data: IWork;
    footer?: any;
    notifications: INotification[];
    onChange: Function;
    onSubmit: Function;
}

export const validateWork = (data: IWork) => {
    return ValidationBuilder.create(data)
        .notEmpty('name', 'Name is required')
        .notEmpty('countryId', 'Country is required')
        .build();
}

export const isValid = (data: IWork) => {
    return Object.keys(validateWork(data)).length == 0;
}

const prepareData = (work: IWork) => {
    return {
        id: work.id,
        name: work.name,
        description: work.description,
        countryId: work.countryId,
    }
}

export function WorkForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<IWork>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            description: props.data?.description ?? '',
            settings: props.data?.settings ?? '',
            countryId: props.data?.countryId ?? 0,
            permissions: [],
            users: [],
            workspaces: [],
            workShifts: [],
            legalForms: [],
        },
        validate: validateWork,
        onSubmit: (data: IWork) => {
            props.onSubmit(prepareData(data));
            return { ok: true };
        },
        onChange: props.onChange,
    });

    return <div className='form-1 l200'>
        <form onSubmit={form.handleSubmit} className='c work-form-container'>
            <div className='c md pd g-30'>
                <G label={t('Name')}>
                    {form.input('name')}
                </G>
                <G label={t('Description')}>
                    {form.input('description')}
                </G>
                <G label={t('Country')}>
                    {form.select('countryId', props.countries)}
                </G>
            </div>
            <div className='errors-container'>
                {form.errorBox()}
                <NotificationsBox notifications={props.notifications} />
            </div>

            {props.footer != undefined && props.footer}
        </form>
    </div>
}
