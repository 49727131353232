import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import * as UserStore from '@store/user';
import { isJobHasContractorPropertyGroupType } from '../../models';
import { UserConfiguration } from '@components/user/UserConfiguration';



export default connect(
    (state: ApplicationState, params: any) => ({
        ...state.identity,
        ...UserStore.plainActions,
        contractorId: params.contractorId ?? parseInt(params.match?.params?.contractorid),
        workId: params.workId ?? parseInt(params.match?.params?.workid),
        ...state.identity,
        ...state.user,
        ...ContractorsStore.plainActions,
        constants: state.constants,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isJobHasContractorPropertyGroupType),
        resourceStatusTypes: state.user.resourceStatusTypes,
    }),
    UserStore.actionCreators,
)(UserConfiguration as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
