import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';
import * as App from '../../store/app';

import AdminWorks, { IProps } from '../../components/admin/AdminWorks';
import { NotificationType } from '../../models';

type Props =
    Admin.IAdminState &
    App.IAppState &
    IProps;

export default connect(
    (state: ApplicationState) => ({
        countries: state.constants.countries,
        constants: state.constants,
        users: state.admin.users,
        works: state.admin.works,
        notifications: state.notifications.notifications
            .filter(n => n.ctx === 'adminworks'),
        loadingNotifications: state.notifications.notifications
            .filter(n => n.ctx === 'adminworks' && n.type === NotificationType.LOADING),
        loading: state.app.loading,
        loadingMessage: state.app.loadingMessage
    }),
    Admin.actionCreators,
)(AdminWorks as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
