import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import * as User from '../../store/user';
import * as MachineriesStore from '@store/machineries';
import { MachineryJobsDialog } from '@components/machineries/MachineryJobsDialog';

export default connect(
    (state: ApplicationState) => ({
        ...state.user,
        ...state.identity,
        ...JobsStore.plainActions,
        ...User.plainActions,
        ...MachineriesStore.plainActions,
        moduleManager: state.modules.moduleManager,
    }),
    JobsStore.actionCreators,
)(MachineryJobsDialog as any) as any; // CHANGE