import * as React from 'react';

import { MultiSelect, useTranslation } from '@components';
import { INotification, IWork, NotificationType } from '@models';
import { IUserData } from '@models/identity';
import { onNotification } from '@utils/hooks';

export interface IProps {
    notifications: INotification[];
    onCancel: Function;
    users: IUserData[];
    saveWork: Function;
    work: IWork;
}

const createUserInput = (u: IUserData) => ({
    id: u.id!,
    userName: u.userName,
});

const createWorkInput = (w: IWork) => ({
    id: w.id,
    name: w.name,
    description: w.description,
    countryId: w.countryId
})

export function AdminWorkUsers(props: IProps) {
    const { t } = useTranslation();

    const [selectedUsers, setSelectedUsers] = React.useState(
        props.work.users.map(u => u.id));

    const [loading, setLoading] = React.useState<boolean>(false);

    onNotification({type: NotificationType.SUCCESS, ctx: 'adminworkusers'}, props.notifications, () => {
        setLoading(false);
    });

    const save = () => {
        const users = props.users
            .filter(u => selectedUsers.indexOf(u.id!) >= 0)
            .map(createUserInput);
        props.saveWork({...createWorkInput(props.work), users }, {ctx: 'adminworkusers'});
    }

    return <div className='c he'>
        <MultiSelect
            className='w-100p'
            id='users'
            value={selectedUsers}
            onChange={e => setSelectedUsers(e.value)}
            filter={true}
            options={props.users}
            optionLabel={'userName'}
            optionValue={'id'} />

        <div className='footer md pd-top'>
            <div className='r'>
                <div className='e'></div>
                <span className='p-buttonset'>
                    <button
                        onClick={_ => props?.onCancel()}
                        disabled={loading}>
                        {t('Cancel')}
                    </button>
                    <button
                        className='primary'
                        disabled={loading}
                        onClick={save}>
                        {t('Save')}
                    </button>
                </span>
            </div>
        </div>
    </div>
}
