import * as React from 'react';

import {useForm, useLoading, useMessage, useTranslation, MultiSelect} from '@components';
import {IRequirementType, RequirementTargetType} from '@models/requirements';
import {IPropertyGroupType, IWork, PropertyGroupObjectType} from '@models';
import {__d, flatten} from '@utils';
import {IExpression, Invocation, parse} from '@utils/expressions';
import {
    allOperators,
    getCommonProperties,
    getReferenceValues,
    getTargetEntityName,
    Operator,
    propertyIsBoolean,
    propertyIsCheckExistence,
    propertyIsReference,
    propertyIsString,
    PropertyOperator,
    propertyOperatorName,
    propertyTitle,
} from './RequirementConstants';

import './EditRequirementTypeExpressions.scss';

export interface IProps {
    constants: any;
    onClose: Function;
    onSuccess: Function;
    requirementType: IRequirementType;
    saveRequirementType: Function;
    work: IWork;
}

interface IPropertyExpression {
    property: string;
    operator: PropertyOperator;
    value: string | number | boolean | undefined;
    __display?: string;
}

interface IExpressionFragment {
    id: number;
    operator: Operator;
    expressions: IPropertyExpression[];
}

const matchTargetType = (a: PropertyGroupObjectType, b: RequirementTargetType) => {
    return (a == PropertyGroupObjectType.Contractor && b == RequirementTargetType.Contractor)
        || (a == PropertyGroupObjectType.JobHasContractor && b == RequirementTargetType.JobHasContractor)
        || (a == PropertyGroupObjectType.JobHasContractor && b == RequirementTargetType.Contractor)
        || (a == PropertyGroupObjectType.JobHasMachinery && b == RequirementTargetType.JobHasMachinery)
        || (a == PropertyGroupObjectType.JobHasWorker && b == RequirementTargetType.JobHasWorker)
        || (a == PropertyGroupObjectType.Machinery && b == RequirementTargetType.Machinery)
        || (a == PropertyGroupObjectType.JobHasMachinery && b == RequirementTargetType.Machinery)
        || (a == PropertyGroupObjectType.JobHasWorker && b == RequirementTargetType.Worker);
}

const matchJobTargetType = (a: PropertyGroupObjectType, b: RequirementTargetType) => {
        return (a == PropertyGroupObjectType.Job && b == RequirementTargetType.JobHasContractor)
        || (a == PropertyGroupObjectType.Job && b == RequirementTargetType.JobHasWorker)
        || (a == PropertyGroupObjectType.Job && b == RequirementTargetType.JobHasMachinery)
        ;
}

const getJobDynamicProperties = (targetType: RequirementTargetType, props: IPropertyGroupType[]) => {
    const g = props.filter(p => matchJobTargetType(p.objectType, targetType));
    return flatten(g.map(a => a.properties ?? []));
}

const getDynamicProperties = (targetType: RequirementTargetType, props: IPropertyGroupType[]) => {
    const g = props.filter(p => matchTargetType(p.objectType, targetType));
    return flatten(g.map(a => a.properties ?? []));
}

const propertyOperatorToCode = (o: PropertyOperator, propertyName: string) => {
    const isString = propertyIsString(propertyName);
    if (o === PropertyOperator.EQUAL) {
        return ["==", '', ''];
    }
    else if (o === PropertyOperator.GREATER_THAN) {
        return [">", '', ''];
    }
    else if (o === PropertyOperator.LESSER_THAN) {
        return ["<", '', ''];
    }
    else if (o === PropertyOperator.GREATER_EQUAL_THAN) {
        return [">=", '', ''];
    }
    else if (o === PropertyOperator.LESSER_EQUAL_THAN) {
        return ["<=", '' , ''];
    }
    else if (o === PropertyOperator.CONTAINS && isString) {
        return ['Contains', '("', '")'];
    }
    else if (o === PropertyOperator.CONTAINS) {
        return ['Contains', '(', ')'];
    }
    else if (o === PropertyOperator.NOT_EQUAL) {
        return ['!=', '', ''];
    }
    else {
        return ['', '', ''];
    }
}

const INVOCATION_NAME_EXP = new RegExp(/\(\"([\d|\w|\W|_|-]+)\"\)/);

const propertyToCode = (p: IPropertyExpression, targetEntityName: string) => {
    const propIsBoolean = p.operator === PropertyOperator.TRUE
        || p.operator === PropertyOperator.FALSE;

    const resourcePrefix = (p.property.includes('.Job.') || p.property.startsWith('Job.'))
        ? '.Job'
        : targetEntityName === 'JobHasMachinery'
        ? '.Machinery'
        : targetEntityName === 'JobHasContractor'
        ? '.Contractor'
        : targetEntityName === 'JobHasWorker'
        ? '.Worker'
        : '';

    if ([PropertyOperator.IS_NOT_NULL, PropertyOperator.IS_NULL].includes(p.operator)) {
        const op = p.operator === PropertyOperator.IS_NULL ? '==' : '!=';
        const prefix = p.property.includes(targetEntityName + '.')
            ? ''
            : `${targetEntityName}.`;
        return `${prefix}${p.property} ${op} null`;
    }
    else if (p.property.includes('Property.') && propIsBoolean) {
        const name = p.property.split('Property.')[1];
        const v = p.operator === PropertyOperator.TRUE
            ? 'true'
            : 'false';
        return `${targetEntityName}${resourcePrefix}.GetBooleanProperty("${name}") == ${v}`;
    }
    else if (p.property.includes('Property.')) {
        const name = p.property.split('.')[2];
        return `${targetEntityName}${resourcePrefix}.GetBooleanProperty("${name}")`;
    }
    else {
        const [op, s, e] = propertyOperatorToCode(p.operator, p.property);
        const value = p.value;
        const sep = s != '' ? '.' : '';
        const prefix = p.property.includes(targetEntityName + '.')
            ? ''
            : `${targetEntityName}.`;
        return `${prefix}${p.property}${sep}${op}${s}${value}${e}`
    }
}

const expressionToCode = (f: IExpressionFragment, targetEntityName: string) => {
    const op = f.operator === Operator.AND ? ' && ' : ' || ';

    const properties = f.expressions.map(p => propertyToCode(p, targetEntityName));

    return '(' + properties.join(op) + ')';
}

const expressionsToCode = (fragments: IExpressionFragment[], target: RequirementTargetType) => {
    const targetName = getTargetEntityName(target);
    const source = [];
    for (const f of fragments) {
        if (f.expressions) {
            __d('expressionsToCode', targetName, f.expressions, expressionToCode(f, targetName));
            source.push(expressionToCode(f, targetName));
        }
    }

    return source.join(' && ');
}

const getPropertyNameWithoutPath = (n: string) => {
    const p = n.split('.');

    return p[p.length - 1];
}

function ExpressionFragment({
    constants,
    fragment,
    onChangeOperator,
    onPropertiesChanged,
    requestRemove,
    requirementType,
    propertyGroupTypes,
    work,
}: {
    constants: any,
    fragment: IExpressionFragment,
    onChangeOperator: Function,
    onPropertiesChanged: Function,
    requestRemove: Function,
    requirementType: IRequirementType,
    propertyGroupTypes: IPropertyGroupType[],
    work: IWork,
}) {
    const { t } = useTranslation();

    const [selectedProperty, setSelectedProperty] = React.useState<string|undefined>();
    const [selectedOperator, setSelectedOperator]  = React.useState<number|undefined>();
    const [operators, setOperators] = React.useState<any[]>([]);
    const [isReference, setIsReference] = React.useState<boolean>(false);
    const [referenceValues, setReferenceValues] = React.useState<any[]>([]);
    const [selectedReference, setSelectedReference] = React.useState<number|undefined>();
    const [selectedReferenceName, setSelectedReferenceName] = React.useState<string|undefined>();
    const [selectedValue, setSelectedValue] = React.useState<string|undefined>();

    const [properties, setProperties] = React.useState<IPropertyExpression[]>(fragment.expressions);
    const [displayValues, setDisplayValues] = React.useState<any>({});

    const isBooleanProp =
        selectedOperator === PropertyOperator.TRUE
        || selectedOperator === PropertyOperator.FALSE
        || selectedOperator === PropertyOperator.IS_NULL
        || selectedOperator === PropertyOperator.IS_NOT_NULL;

    React.useEffect(() => {
        const r = referenceValues.find(r => r.id == selectedReference);
        setSelectedReferenceName(r?.name);
    }, [selectedReference]);

    React.useEffect(() => {
        const dvs: any = {};
        for (const i in properties) {
            const p = properties[i];
            const n = getPropertyNameWithoutPath(p.property);
            if (propertyIsReference(n) && p.__display == undefined && displayValues[i] == undefined) {
                const values = getReferenceValues(n, work, constants);
                const v = values.find((a: any) => a.id == p.value)?.name;

                if (v) {
                    dvs[i] = v;
                }
            }
        }
        if (Object.keys(dvs).length > 0) {
            setDisplayValues(dvs);
        }
    }, [properties]);

    React.useEffect(() => {
        const isRef = propertyIsReference(selectedProperty);
        const isExst = propertyIsCheckExistence(selectedProperty);
        const isDyn = selectedProperty?.includes('Property');
        setIsReference(isRef);

        if (propertyIsBoolean(selectedProperty)) {
            setOperators([
                {id: PropertyOperator.TRUE, name: 'expressions.operator.True'},
                {id: PropertyOperator.FALSE, name: 'expressions.operator.False'},
            ]);
            setSelectedOperator(PropertyOperator.TRUE);
        }
        else if (isDyn) {
            setOperators([
                {id: PropertyOperator.TRUE, name: 'expressions.operator.True'},
                {id: PropertyOperator.FALSE, name: 'expressions.operator.False'},
            ]);
            setSelectedOperator(PropertyOperator.TRUE);
        }
        else if (isExst) {
            setOperators([
                {id: PropertyOperator.IS_NULL, name: 'expressions.operator.IsNull'},
                {id: PropertyOperator.IS_NOT_NULL, name: 'expressions.operator.IsNotNull'},
            ]);
            setSelectedOperator(PropertyOperator.IS_NULL);
        }
        else if (isRef) {
            setOperators([
                {id: PropertyOperator.EQUAL, name: 'expressions.operator.Equal'},
                {id: PropertyOperator.NOT_EQUAL, name: 'expressions.operator.NotEqual'}
            ]);
            setSelectedOperator(PropertyOperator.EQUAL);
            setReferenceValues(getReferenceValues(selectedProperty, work, constants));
        }
        else {
            setOperators(allOperators);
        }
    }, [selectedProperty]);

    const commonProperties = getCommonProperties(requirementType.targetType);
    const dynamicProperties = getDynamicProperties(requirementType.targetType, propertyGroupTypes);
    const jobDynamicProperties = getJobDynamicProperties(requirementType.targetType, propertyGroupTypes);

    const addProperty = (..._: any[]) => {
        const newProperty: IPropertyExpression = isReference
            ? { property: selectedProperty!, operator: selectedOperator!, value: selectedReference, __display: selectedReferenceName }
            : propertyIsBoolean(selectedProperty!)
            ? { property: selectedProperty!, operator: PropertyOperator.EQUAL, value: selectedOperator === PropertyOperator.TRUE }
            : { property: selectedProperty!, operator: selectedOperator!, value: selectedValue };

        setSelectedProperty(undefined);
        setSelectedOperator(undefined);
        setSelectedReference(undefined);
        setSelectedValue(undefined);

        __d('Add property', newProperty);

        const newProperties = properties.concat([newProperty]);
        setProperties(newProperties);

        onPropertiesChanged(newProperties);
    }

    const removePropertyByIndex = (indx: number) => {
        const v = properties.filter((_, i) => i != indx);
        setProperties(v);

        onPropertiesChanged(v);
    }

    const dynamicPropertyPrefix =
        requirementType.targetType == RequirementTargetType.JobHasContractor
        ? 'Contractor.'
        : requirementType.targetType == RequirementTargetType.JobHasWorker
        ? 'Worker.'
        : requirementType.targetType == RequirementTargetType.JobHasMachinery
        ? 'Machinery.'
        : '';

    return <div className='c expression-fragment'>
        <div className='r v-center header'>
            <select
                value={fragment.operator}
                onChange={e => onChangeOperator(parseInt(e.target.value))}>
                <option value={Operator.AND}>{t('expressions.and')}</option>
                <option value={Operator.OR}>{t('expressions.or')}</option>
            </select>
            <div className='e' />
            <i className='pi pi-trash pointer sm pd-right' onClick={() => requestRemove(fragment)} />
        </div>

        <div className='c md pd we'>
            <table className='table we'>
                <thead>
                    <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {properties.map((p, i) => <tr key={i}>
                        <td>{t(propertyTitle(requirementType.targetType, p.property))}</td>
                        <td className='center'>{t(propertyOperatorName(p.operator))}</td>
                        <td className='center'>
                            {typeof(p.value) === 'boolean' && <span>{JSON.stringify(p.value)}</span>}
                            {p.__display && p.__display}
                            {!p.__display && displayValues[i] &&
                                <span>{displayValues[i]}</span>}
                            {!p.__display && !displayValues[i] && p.value}
                        </td>
                        <td className='td-sm center'>
                            <i className='pi pi-trash pointer' onClick={() => removePropertyByIndex(i)} />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>

        <div className='r r-end footer'>
            <select value={selectedProperty ?? ''} onChange={e => setSelectedProperty(e.target.value)}>
                <option>
                    {t('expressions.select-property')}
                </option>
                {commonProperties.map((p, i) =>
                    <option value={p} key={i}>{t(propertyTitle(requirementType.targetType, p))}</option>)}
                {dynamicProperties.map((p, i) =>
                    <option value={`${dynamicPropertyPrefix}Property.${p.name}`} key={i}>{t(p.title)} ({p.name})</option>)}
                {jobDynamicProperties.map((p, i) =>
                    <option value={`Job.Property.${p.name}`} key={i}>{t('Job')}: {t(p.title)} ({p.name})</option>)}
            </select>
            <select value={selectedOperator ?? ''}
                 onChange={e => setSelectedOperator(parseInt(e.target.value))}>
                <option>{t('expressions.select-operator')}</option>
                {operators.map((o, i) =>
                    <option key={i} value={o.id}>{t(o.name)}</option>)}
            </select>
            {isReference &&
                <select value={selectedReference ?? ''}
                    onChange={e => setSelectedReference(parseInt(e.target.value))}>
                    <option>{t('expressions.select-value')}</option>
                    {referenceValues.map((r, i) =>
                        <option value={r.id} key={i}>{r.name}</option>)}
                </select>}
            {!isReference && !isBooleanProp &&
                <input
                    type='text'
                    placeholder={t('expressions.set-value')}
                    value={selectedValue ?? ''}
                    onChange={e => setSelectedValue(e.target.value)} /> }
            <button
                disabled={!selectedOperator || !selectedProperty}
                onClick={addProperty}>
                <i className='pi pi-plus' />
            </button>
        </div>
    </div>
}

const astGetPropertyName = (ast: IExpression) => {
    if (ast.type === 'path') {
        const name = (ast.values ?? [])
            .filter((a: any) => typeof(a) === 'string').join('.');

        return name;
    }
    else {
        return '???';
    }
}

const parseOperator = (name: string|undefined) => {
    if (name === 'Contains') {
        return PropertyOperator.CONTAINS;
    }
    else {
        return PropertyOperator.EQUAL;
    }
}

const astGetPropertyOperator = (ast: IExpression) => {
    if (ast.type === 'path') {
        const operator = (ast.values ?? [])
            .find((a: any) => a && a.type && a.type == 'invocation') as Invocation;

        return parseOperator(operator?.name);
    }
    else {
        return PropertyOperator.LESSER_EQUAL_THAN;
    }
}

const astGetInvocation = (ast: IExpression) => {
    const i = (ast.values ?? [])
        .find((v: any) => v && v.type && v.type === 'invocation') as Invocation;

    return i;
}

const astNodeToExpression = (ast: IExpression) => {
    const propertyName = astGetPropertyName(ast);
    const operator = astGetPropertyOperator(ast);
    const invocation = astGetInvocation(ast);
    const p: IPropertyExpression = {
        property: propertyName,
        operator: operator,
        value: invocation?.args?.value ?? '',
    };

    return p;
}

const parseAstOperator = (op: string) => {
    const map: any = {
        '==': PropertyOperator.EQUAL,
        '!=': PropertyOperator.NOT_EQUAL,
    };

    return map[op] ?? PropertyOperator.EQUAL;
}

const astHasBooleanPropInvocation = (ast: IExpression) => {
    const res = (ast.values ?? [])
        .find((i: any) => i && i.type == 'invocation' && i.name == 'GetBooleanProperty');

    return res != undefined;
}

const astGetPath = (ast: IExpression) => {
    return (ast.values ?? [])
        .filter((i: any) => typeof(i) === 'string')
        .join('.');
}

const astToFragments: (ast: IExpression, isRoot?: boolean) => IExpressionFragment[] =
    (ast: IExpression, isRoot: boolean = false) => {
    if (ast.type === 'binaryop'
        && (
            (ast.left?.op !== ast.op && ast.left?.op === 'AND') ||
            (ast.right?.op !== ast.op && ast.right?.op === 'AND') ||
            (ast.left?.op !== ast.op && ast.left?.op === 'OR') ||
            (ast.right?.op !== ast.op && ast.right?.op === 'OR')
        )
        && (ast.op === 'AND' || ast.op === 'OR')
        && isRoot) {
        const left = astToFragments(ast.left!, false);
        const right = astToFragments(ast.right!, false);

        return left.concat(right);
    }
    const root: IExpressionFragment = {
        id: new Date().getTime() * Math.random(),
        operator: Operator.AND,
        expressions: [],
    };
    if (ast.type === 'path') {
        const e = astNodeToExpression(ast);
        // return IPropertyExpression
        root.expressions.push(e);
    }
    else if (ast.type === 'binaryop' && astHasBooleanPropInvocation(ast.left!)) {
        const value = ast.right?.value === true;
        const i = astGetInvocation(ast.left!);
        const propertyName = i.args.value!;
        const propertyPath = astGetPath(ast.left!);
        const operator = value ? PropertyOperator.TRUE : PropertyOperator.FALSE;

        root.expressions.push({
            property: `${propertyPath}.Property.${propertyName}`,
            operator,
            value: '',
        });
    }
    else if (ast.type === 'binaryop' && (ast.op === '==' || ast.op === '!=')) {
        const property = (ast.left!.values ?? []).join('.');
        const valueIsNull = ast.right!.type === 'path'
            && (ast.right!.values?.length ?? 0) >= 1
            && ast.right!.values![0] === 'null';
        const op = valueIsNull
            ? (parseAstOperator(ast.op) == PropertyOperator.EQUAL
                ? PropertyOperator.IS_NULL
                : PropertyOperator.IS_NOT_NULL)
            : parseAstOperator(ast.op);

        root.expressions.push({
            property,
            operator: op,
            value: ast.right!.value as any
        });
    }
    else if (ast.type === 'binaryop') {
        const { left, op, right } = ast;
        root.operator = op === 'AND' ? Operator.AND : Operator.OR;

        const a = astToFragments(left as IExpression);
        const b = astToFragments(right as IExpression);

        for (const e of a) {
            for (const x of e.expressions) {
                root.expressions.push(x);
            }
        }

        for (const e of b) {
            for (const x of e.expressions) {
                root.expressions.push(x);
            }
        }
    }

    return [root];
}

export function EditRequirementTypeExpression(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const [fragments, setFragments] = React.useState<IExpressionFragment[]>([]);
    const [selectedOperator, setSelectedOperator] = React.useState<Operator>(Operator.OR);

    const form = useForm({
        initialValues: props.requirementType,
    });

    const parseAndSetExpression = (expression: string) => {
        const ast = parse(expression);
        __d('ast', ast, expression);
        setFragments(astToFragments(ast, true));
    }

    React.useEffect(() => {
        if (props.requirementType?.expression) {
            try {
                parseAndSetExpression(props.requirementType.expression);
            }
            catch (e) {
                console.error(e);
            }
        }
    }, [props.requirementType]);

    const doSave = loading.wrap(async () => {
        if (form.values.expression && form.values.expression.trim() == '()') {
            form.values.expression = null;
        }
        const res = await props.saveRequirementType(props.requirementType.workId, form.values);
        message.set(res);

        if (res.hasValue) {
            props.onSuccess(form.values);
        }
    });

    const appendFragment = (...args: any[]) => {
        const newFragment = {
            id: (new Date().getTime() * Math.random()),
            operator: selectedOperator,
            expressions: [],
        };
        setFragments(f => f.concat([newFragment]));
    }

    const removeFragment = (f: IExpressionFragment) => {
        setFragments(a => a.filter(b => b.id != f.id));
    }

    const updateFragment = (f: IExpressionFragment, p: IPropertyExpression[], op: Operator|undefined = undefined) => {
        const newFragment = {...f, expressions: p};
        if (op) {
            newFragment.operator = op;
        }
        __d('Update fragment', newFragment);
        const newFragments = fragments.map(i => i == f ? newFragment : i);
        setFragments(newFragments);
    }

    React.useEffect(() => {
        try {
            form.setFieldValue('expression',
                expressionsToCode(fragments, props.requirementType.targetType));
        }
        catch (e) {
            form.setFieldValue('expression', null);
        }
    }, [ fragments ]);

    return <div className='c EditRequirementTypeExpressions'>
        <div className='c md pd'>
            <div className='r'>
                <div className='c e'>
                    {fragments.length === 0 &&
                        <span className='expressions-add-message center'>
                            {t('expressions.add-logic-operator')}
                        </span>}
                    {fragments.map((f, i) =>
                        <ExpressionFragment
                            key={i}
                            constants={props.constants}
                            fragment={f}
                            onChangeOperator={(o: Operator) => updateFragment(f, f.expressions, o)}
                            onPropertiesChanged={(p: IPropertyExpression[]) => updateFragment(f, p)}
                            propertyGroupTypes={props.work.propertyGroupTypes ?? []}
                            requestRemove={removeFragment}
                            requirementType={props.requirementType}
                            work={props.work} />)}
                </div>
                <div className='sm pd-left e'>
                    {form.textarea('expression', { rows: 10 })}
                </div>
            </div>
        </div>
        <div className='footer r r-end'>
            <div className='r r-end sm pd-left'>
                <select value={selectedOperator} onChange={e => setSelectedOperator(parseInt(e.target.value))}>
                    <option value={Operator.AND}>{t("expressions.and")}</option>
                    <option value={Operator.OR}>{t("expressions.or")}</option>
                </select>
                <button onClick={appendFragment}><i className='pi pi-plus' /></button>
            </div>
            <div className='e' />
            <button disabled={loading.isLoading()} onClick={_ => props.onClose()}>{t('Cancel')}</button>
            <button disabled={loading.isLoading()} onClick={doSave} className='primary'>{t('Save')}</button>
        </div>
    </div>
}
