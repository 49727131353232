import { IElementHasProperties, IPropertyGroup } from '@models';

export const POST = async (url: string, data: any) => {
    try {
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (resp.status === 200) {
            const r = await resp.json();

            return {
                ok: true,
                value: r
            }
        }
        else if (resp.status == 500) {
            const r = await resp.text();
            const message = r && r.length > 0 && r.includes('at')
                ? r.split(' at ')[0]
                : r;
            return {
                error: true,
                message: message,
            };
        }
        else {
            const r = await resp.json();
            return {
                error: true,
                message: r && r.message ? r.message : resp.statusText
            }
        }
    }
    catch (e) {
        return {
            error: true,
            message: (e as any).toString(),
        }
    }
}

export const GET = async (url: string) => {
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (resp.status === 200) {
            const r = await resp.json();

            return {
                ok: true,
                value: r
            }
        }
        else {
            const r = await resp.json();
            return {
                error: true,
                message: r && r.message ? r.message : resp.statusText
            }
        }
    }
    catch (e) {
        return {
            error: true,
            message: (e as any).toString(),
        }
    }
}

export const parseProperty = (v: IPropertyGroup) => {
    return {
        ...v,
        valueObj: v.value ? JSON.parse(v.value) : {}
    }
}

export function parseProperties<T>(v: T & IElementHasProperties) {
    const properties = v.properties
        ? v.properties.map(parseProperty)
        : [];

    return { ...v, properties };
}

export const formatProperty = (workId: number) => {
    return (v: IPropertyGroup) => {
        return {
            id: v.id,
            workId: workId,
            jobId: v.jobId,
            isInternal: v.isInternal,
            propertyGroupTypeId: v.propertyGroupTypeId,
            value: JSON.stringify(v.valueObj),
        };
    }
}

interface IEnsureTypeOpts {
    exclude?: string|string[];
    include?: string|string[];
    ints?: string[];
}

export function ensureType<T>(a: any, opts: IEnsureTypeOpts = {}): T {
    const res: any = {};
    const excludeProperties: string[] = (typeof(opts.exclude) === 'string'
        ? [opts.exclude]
        : opts.exclude) ?? [];
    const includeProperties: string[] = (typeof(opts.include) === 'string'
        ? [opts.include]
        : opts.include) ?? [];

    for (const k of Object.keys(a)) {
        if (!k.startsWith('_') && !excludeProperties.includes(k) && (
            includeProperties.length === 0 || includeProperties.includes(k)
        )) {
            res[k] = a[k];
        }
    }

    if (opts.ints != undefined) {
        for (const k of opts.ints) {
            if (res[k] && typeof(res[k]) === 'string') {
                res[k] = parseInt(res[k]);
            }
        }
    }

    return res as T;
}

export default {
    GET,
    POST,
    formatProperty,
    parseProperty,
    parseProperties,
    ensureType,
}
