import * as React from 'react';

import { classNames, useTranslation } from '@components';
import './ImageViewer.scss';

export interface IProps {
    className?: string;
    url: string;
}

export function ImageViewer(props: IProps) {
    const { t } = useTranslation();

    return <div className={classNames('ImageViewer', props.className)}>
        <img src={props.url} />
    </div>
}