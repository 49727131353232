import * as React from 'react';

import { Loading } from './Loading';

type DataRenderF = (data: any) => any;

export function DataLoader({children, provider}: {children: DataRenderF, provider: Function}) {
    const [data, setData] = React.useState(undefined);

    React.useEffect(() => {
        provider().then(setData);
    }, [provider]);

    if (data) {
        return children(data);
    }
    else {
        return <Loading />;
    }
}