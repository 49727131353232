import * as React from 'react';

import { R, ValidationBuilder, useForm, useTranslation, useLoading, useMessage, Message } from '@components';
import { IRequirement, IRequirementIncidence, IRequirementType, IRequirementValidationData } from '@models/requirements';
import { IOption, IUserIdentity } from '@models';
import { pushTemporalResource } from '@store/actions/documents';

type RequirementCanBeValidatedF = (workId: number, requirementId: number) => Promise<boolean>;
// type RejectT = (requirement: IRequirement, data: IRequirementValidationData, fileResourceId: string|undefined,
//     fileResourceName: string|undefined) => Promise<IOption<number>>;

type saveRequirementIncidenceT = (
    workId: number,
    data: IRequirementIncidence,
    fileResourceId: string | undefined,
    fileResourceName: string | undefined) => Promise<IOption<number>>;

export interface IProps {
    loading?: boolean;
    incidence?: IRequirementIncidence;
    requestClose: Function;
    // requestReject: RejectT;
    saveRequirementIncidence: saveRequirementIncidenceT;
    onRejected?: Function;
    requirement: IRequirement;
    requirementType: IRequirementType;
    user: IUserIdentity;
    requirementCanBeValidated: RequirementCanBeValidatedF;
}

export function RejectRequirement(props: IProps) {
    const { t } = useTranslation();
    const fileRef = React.useRef<any>();
    const loading = useLoading();
    const [selectedFile, setSelectedFile] = React.useState<File | undefined>();
    const message = useMessage();
    const [canBeValidated, setCanBeValidated] = React.useState(false);

    const initialize = loading.wrap(async () => {
        const canBeValidated = await props.requirementCanBeValidated(
            props.requirement.workId,
            props.requirement.id);
        setCanBeValidated(canBeValidated || props.user.roles.includes('gestor'));
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const defaultIncidence: IRequirementIncidence = {
        requirementId: props.requirement.id,
        creationDate: new Date(),
        remarks: '',
        createdById: props.user.id,
    };

    const form = useForm<IRequirementValidationData>({
        initialValues: props.incidence ?? defaultIncidence,
        validateOnMount: true,
        validate: ValidationBuilder
            .new()
            .notEmpty('remarks', 'requirements.incidence.remarks-mandatory')
            .lift()
    });

    const doReject = loading.wrap(async () => {
        const fileResourceId = await getFileResourceId(selectedFile);
        const fileResourceName = await getFileResourceName(selectedFile);
        // await props.requestReject(props.requirement, form.values, fileResourceId, fileResourceName);
        const res = await props.saveRequirementIncidence(props.requirement.workId, form.values, fileResourceId, fileResourceName);
        message.set(res);
        if (props.onRejected) {
            props.onRejected(props.requirement);
        }
        props.requestClose();
    });

    const doSelectFile = () => {
        fileRef.current.click();
    }

    const onFileSelected = (event: any) => {
        const file = event.target.files[event.target.files.length - 1];
        setSelectedFile(file);
    }

    const getFileResourceId = async (file: File | undefined) => {
        if (file) {
            const res = await pushTemporalResource(file);
            // TODO: controlar posibles errores enviando el fichero al servidor
            return res.value;
        }
        else {
            return undefined;
        }
    };

    const getFileResourceName = async (file: File | undefined) => {
        if (file) {
            return file.name;
        }
        else {
            return undefined;
        }
    };

    const suggestions = (props.requirementType?.suggestions ?? []).map(s => s.name);

    return <div>
        <div className='form-1 l200 sm pd'>
            <R label={t('requirements.remarks')}>
                {form.autoCompleteTextArea('remarks', { autoFocus: true, suggestions, style: { minWidth: '400px', minHeight: '150px' } })}
            </R>
        </div>
        <div className='footer r r-end'>
            <button disabled={props.loading} style={{ alignItems: 'center' }} onClick={doSelectFile}>
                <i className="pi pi-upload pointer" style={{ paddingRight: '8px', verticalAlign: 'top' }} />
                <span style={{ verticalAlign: 'text-bottom' }}>{t('Upload file')}</span>
            </button>
            {selectedFile && <span style={{ color: 'green', alignItems: 'center' }}>{selectedFile.name}
                <i className='pi pi-file' style={{ verticalAlign: 'center', paddingBottom: '1px' }}></i>
            </span>}
            <input onChange={onFileSelected} ref={fileRef} type='file' style={{ display: 'none' }} />
            <hr style={{ opacity: '0%' }} />
            {!canBeValidated &&
            <div className='sm pd text-sm r'>
                <Message
                    className='e'
                    severity={'error'}
                    text={t('requirement.canNotBeValidated')} />
            </div>}
            <button disabled={props.loading} onClick={() => props.requestClose()}>{t('Cancel')}</button>
            {canBeValidated && <button disabled={props.loading || form.isInvalid()} onClick={() => doReject()} className='danger'>{t('Reject')}</button>}
        </div>
    </div>
}
