import * as React from 'react';

import * as PdfJS from 'pdfjs-dist';
PdfJS.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min.js';

import { delay } from '@utils';
import { Loading, classNames, useCallbackState, useLoading, useMessage, useTranslation } from '@components';

import './PdfViewer.scss';

export interface IProps {
    className?: string;
    url: string;
}

enum FitMode {
    NOFIT = 1,
    WIDTH = 2,
}

function Page({ doc, rotation, page, fitMode, containerRect, zoom }: {
    doc: any, page: any, rotation?: number, fitMode: FitMode, containerRect: any, zoom?: any
}) {
    const canvasRef = React.useRef<any>();
    const isRendering = React.useRef<boolean>(false);
    const loading = useLoading();

    const context = React.useRef<any>();

    const clearCanvas = () => {
        context.current?.clearRect(0, 0, canvasRef.current?.width, canvasRef.current?.height);
        context.current?.beginPath();
        context.current?.rect(0, 0, canvasRef.current?.width, canvasRef.current?.height);
    }

    const hideCanvas = () => {
        if (canvasRef.current) {
            canvasRef.current.style.display = 'none';
        }
    }

    const showCanvas = () => {
        if (canvasRef.current) {
            canvasRef.current.style.display = 'block';
        }
    }

    const renderPage = async (page: any) => {
        isRendering.current = true;
        let scale = fitMode === FitMode.NOFIT
            ? 1 * zoom
            : (canvasRef.current.width / page.view[2]);

        let viewport = page.getViewport({ scale: scale, rotation: rotation });
        if (rotation) {
            viewport.rotation = rotation;
        }

        const canvas = canvasRef.current;
        if (!context.current) {
            context.current = canvas.getContext('2d');
        }

        clearCanvas();

        if (fitMode === FitMode.WIDTH) {
            canvas.width = page.view[2] * scale;
        }
        else {
            canvas.width = canvas.parentElement.clientWidth;
        }

        const pageHeight = Math.max(page.view[3], page.view[2]);

        if ((rotation ?? viewport.rotation) % 180 == 90) {
            canvas.height = pageHeight * scale;
        }
        else {
            canvas.height = pageHeight * scale;
        }

        await page.render({
            canvasContext: context.current,
            viewport,
        });
        showCanvas();
        setTimeout(() => {
            isRendering.current = false;
            loading.stop();
        }, 1000);
    }

    const tryToRenderPage = async (page: any) => {
        loading.start();
        hideCanvas();
        clearCanvas();

        while (isRendering.current) {
            await delay(400);
        }
        renderPage(page);
    }

    React.useEffect(() => {
        tryToRenderPage(page);
    }, [doc, page, rotation, fitMode, zoom]);

    return <div>
        {/**
            loading.renderModal({ text: t('document.loading') }) **/}
        <canvas ref={canvasRef}></canvas>
    </div>
}

function base64PDFToBlobUrl(blob: any) {
    const url = URL.createObjectURL(blob);
    return url;
}

function PdfViewerImpl(props: IProps) {
    const [data, setData] = React.useState<string>('');
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        fetch(props.url + '/view.pdf')
            .then(response => response.blob())
            .then(blob => {
                setData(base64PDFToBlobUrl(blob));
                setLoading(false);
            });
    }, [props.url]);

    return (<div className={classNames('PdfViewer', props.className)}>
        {loading && <Loading /> }
        {!loading &&
            <embed
                src={data}
                width={"100%"}
                height={"100%"}
                type='application/pdf'></embed>}
    </div>);
}

export const PdfViewer = React.memo(PdfViewerImpl, (prev, next) => prev.url == next.url);

function PdfViewerImplOld(props: IProps) {
    const { t } = useTranslation();

    const [pages, setPages] = React.useState<any[]>([]);

    const docRef = React.useRef<any>();
    const containerRef = React.useRef<any>();
    const canvasContainerRef = React.useRef<any>();
    const [fitMode, setFitMode] = React.useState<FitMode>(FitMode.NOFIT);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(1);
    const [rotation, setRotation] = React.useState<number>(0);
    const [zoom, setZoom] = React.useState<number>(1);
    const [loading, setLoading] = useCallbackState(true);

    const messages = useMessage();

    React.useEffect(() => {
    }, []);

    const loadAllPages = async (doc: any, total: number, url: string) => {
        const pgs: any = [];

        for (let i = 0; i < total; i++) {
            const page = await doc.getPage(i + 1);
            page.url = url;
            pgs.push(page);
        }

        return pgs;
    }

    const initialize = async (url: string) => {
        try {
            setLoading(true);
            setPages([]);
            setFitMode(FitMode.NOFIT);

            messages.clear();
            const doc = await PdfJS.getDocument({url, disableFontFace: false, useSystemFonts: true}).promise;
            docRef.current = doc;

            const allPages = await loadAllPages(doc, doc.numPages, url);

            setTotalPages(doc.numPages);
            setZoom(1);

            setLoading(false, () => {
                setPages(allPages);
            });
        }
        catch (e) {
            console.log(e);
            messages.setError('Error rendering pdf');
        }
    }

    React.useEffect(() => {
        initialize(props.url);
    }, [props.url]);

    const toggleRotate = () => {
        setRotation(r => r + 90 % 360);
    };

    const zoomIn = () => {
        setZoom(z => z * 1.4);
    };

    const zoomOut = () => {
        setZoom(z => z / 1.4);
    };

    return <div ref={containerRef} className={classNames('PdfViewer', props.className)}>
        <div className='toolbar'>
            <span className='e'></span>
            <span className='pages-text'>{currentPage} / {totalPages}</span>
            <span className='e'></span>

            <div className='button-group'>
                <button
                    title={t('documents.rotate')}
                    onClick={() => toggleRotate()}>
                    <i className='fas fa-sync-alt'></i>
                </button>
                <button
                    className={classNames('fit-l', { selected: fitMode == FitMode.NOFIT })}
                    onClick={() => setFitMode(FitMode.NOFIT)}
                    title={t('Adjust height')}>
                    <i className='fas fa-arrows-alt-v'></i>
                </button>
                <button
                    onClick={() => zoomIn()}
                    title={t('Zoom In')}
                    disabled={fitMode == FitMode.WIDTH}
                    hidden={fitMode == FitMode.WIDTH}>
                    <i className="fas fa-search-plus"></i>
                </button>
                <button
                    onClick={() => zoomOut()}
                    title={t('Zoom Out')}
                    disabled={fitMode == FitMode.WIDTH}
                    hidden={fitMode == FitMode.WIDTH}>
                    <i className="fas fa-search-minus"></i>
                </button>
                <button
                    className={classNames('fit-r', { selected: fitMode == FitMode.WIDTH })}
                    onClick={() => setFitMode(FitMode.WIDTH)}
                    title={t('Adjust width')}>
                    <i className='fas fa-arrows-alt-h'></i>
                </button>
            </div>
        </div>
        <div className='canvas-container' ref={canvasContainerRef}>
            {/** <strong className='debug-label'>{props.url}</strong> */}
            {!loading && pages.map((p, i) => {
                const key = props.url + '_' + i;

                return <Page
                    fitMode={fitMode}
                    containerRect={containerRef.current}
                    doc={docRef.current}
                    rotation={rotation}
                    page={p}
                    key={key}
                    zoom={zoom} />
            })}
            {messages.hasError() && <div className='md pd mr center'>
                {messages.render()}
            </div>}
        </div>
    </div>;
}
