import * as React from 'react';

import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { DynamicProperties } from '@components/user/DynamicProperties';
import {
    FieldsBox,
    G,
    Panel,
    Portal,
    RequirePermission,
    useDialogs,
    useForm,
    useLoading,
    useRemoteData,
    useResolveCountryAndRegion,
    useResolveName,
    useToast,
    useTranslation,
} from '@components';
import {
    IActivityType,
    IConstants,
    IJob,
    IJobHasContractor,
    ILegalForm,
    IPropertyGroup,
    IPropertyGroupType,
    IWork,
    PropertyGroupObjectType,
    ResourceType
} from '@models';
import { RequirementTargetType } from '@models/requirements';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import { ViewResourceHistory } from '@components/requirements/ViewResourceHistory';
import { redirectTo } from '@utils';
import { ResourceStatus } from '@components/common/ResourceStatus';

export interface IProps {
    activityTypes: IActivityType[];
    constants: IConstants;
    contractor: IJobHasContractor;
    getJobHasContractor: (workId: number, jobId: number, relationId: number) => Promise<IJobHasContractor>,
    job: IJob;
    legalForms: ILegalForm[];
    onCancel: Function;
    saveJobHasContractor: Function;
    propertyGroupTypes: IPropertyGroupType[];
    work: IWork;
}

type FormData = Partial<IJobHasContractor>;

enum FormType {
    Data = 0,
    AccessData = 1,
    Requirements = 2,
}

export function EditJobHasContractor(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();
    const toast = useToast();

    const jobHasContractor = useRemoteData<IJobHasContractor>(
        props.getJobHasContractor, {
        parameters: [props.work.id, props.job.id, props.contractor.id]
    });

    const [formType, setFormType] = React.useState<FormType>(FormType.Data);

    const dialogs = useDialogs();
    const activityType = useResolveName(props.activityTypes);
    const legalForm = useResolveName(props.legalForms);
    const countryRegion = useResolveCountryAndRegion(props.constants.countries);

    const save = loading.wrap(async (req: FormData) => {
        const resp = await props.saveJobHasContractor(props.work.id, req);
        if (resp.hasValue) {
            toast.show(t('Contractor saved successfully'));
            return { ok: true };
        }
        else {
            return { ok: false, message: resp.error };
        }
    });

    const doSave = (..._: any) => {
        save(form.values);
    }

    const form = useForm<FormData>({
        initialValues: {
            id: props.contractor.id,
            jobId: props.job.id,
            startDate: props.contractor.startDate,
            jobsDescription: props.contractor.jobsDescription,
            contractorId: props.contractor.contractorId,
            contactName: props.contractor.contactName,
            contactSurname: props.contractor.contactSurname,
            contactEmail: props.contractor.contactEmail,
            contactPhone: props.contractor.contactPhone,
            shipmentNumber: props.contractor.shipmentNumber,
            properties: props.contractor.properties ?? [],
        },
        onSubmit: function(data: FormData) {
            return save(data);
        }
    });

    const showRequirements = () => {
        redirectTo(`/work/${props.work.id}/jobs/${props.job.id}/contractor/${props.contractor.id}/requirements`);
    }

    return <div className='c he'>
        <Portal container='#job-actions'>
            <Panel className='yellow sub-section section' header={<div className={'c center g-10'}>
                <div className='r we'>
                    <i className='fas fa-id-card-alt' />
                    <span className='e' />
                    <ResourceStatus
                        currentStatusType={props.contractor.currentStatusType!}
                        resourceId={props.contractor.id}
                        resourceType={ResourceType.JobHasContractor}
                        workId={props.work.id} />
                </div>
                <span className='section-title'>{props.contractor.contractor.name}</span>
            </div>}>
                <div className={'r fwrap r-end'}>
                    <RequirePermission permission='jobs.forceStatus'>
                        <button onClick={dialogs.showFromEvent('force-validation')}>
                            <i className='fas fa-check-square' />
                            <span className='e'>{t('Validate') + ' ' + t('Contractor')}</span>
                        </button>
                    </RequirePermission>
                    <button onClick={() => setFormType(FormType.Data)}>
                        <i className='fas fa-list' />
                        <span className='e'>{t('View data')}</span>
                    </button>
                    <button onClick={() => setFormType(FormType.Requirements)}>
                        <i className='fas fa-folder' />
                        <span className='e'>{t('Requirements')}</span>
                    </button>
                </div>
            </Panel>
        </Portal>

        <Portal container='#user-breadcrumb'>
            <ul>
                <li>{props.contractor.contractor.name}</li>
            </ul>
        </Portal>
        {dialogs.render('force-validation', { title: t('Force resource validation'), className: 'g pd' }, () =>
            <ForceValidationResourceContainer
                currentStatusType={props.contractor.currentStatusType}
                workId={props.work.id}
                requestClose={dialogs.clear}
                resourceType={ResourceType.JobHasContractor}
                resourceId={props.contractor.id} />
        )}
        {dialogs.render('view-history', { title: t('View history'), className: 'g pd' }, () =>
            <ViewResourceHistory
                requestClose={dialogs.clear}
                resourceId={props.contractor.id}
                resourceType={ResourceType.JobHasContractor}
                workId={props.work.id} />
        )}

        {jobHasContractor.renderLoading()}

        {toast.render()}

        {formType == FormType.Data && <>
            <div className='form-header'>
                {t('Edit contractor')}
            </div>
            <div className='r'>
                <div className='form-1 l200 lg pd e'>
                    <G label={t('Name')}>
                        <span className='value'>{props.contractor.contractor.name}</span>
                    </G>
                    <G label={t('Code')}>
                        <span className='value'>{props.contractor.contractor.code}</span>
                    </G>
                    <G label={t('Legal form')}>
                        <span className='value'>{legalForm(props.contractor.contractor.legalFormId)}</span>
                    </G>
                    <G label={t('Activity type')}>
                        <span className='value'>{activityType(props.contractor.contractor.activityTypeId)}</span>
                    </G>
                    <G label={t('JobsDescription')}>
                        {form.input('jobsDescription')}
                    </G>
                    <G label={t('StartDate')}>
                        {form.input('startDate', { type: 'date' })}
                    </G>
                    <G label={t('Provider code')}>
                        {form.input('shipmentNumber')}
                    </G>
                    <span></span>
                    <div id='embed-properties'></div>
                    <div className='r'>
                        <FieldsBox title={t('Address')} collapsible>
                            <G label={t('Address')}>
                                <span className='value'>{props.contractor.contractor.address}</span>
                            </G>
                            <G label={t('Region')}>
                                <span className='value'>{countryRegion(props.contractor.contractor)}</span>
                            </G>
                            <G label={t('Postal code')}>
                                <span className='value'>{props.contractor.contractor.postalCode}</span>
                            </G>
                        </FieldsBox>

                        <FieldsBox title={t('Contact data')} collapsible>
                            <G label={t('Name')}>
                                {form.input('contactName')}
                            </G>
                            <G label={t('Surname')}>
                                {form.input('contactSurname')}
                            </G>
                            <G label={t('Email')}>
                                {form.input('contactEmail')}
                            </G>
                            <G label={t('Phone')}>
                                {form.input('contactPhone')}
                            </G>
                        </FieldsBox>
                    </div>
                </div>

                <div className='sm pd-top pd-right e'>
                    <DynamicProperties
                        propertyGroupTypes={props.propertyGroupTypes}
                        object={props.contractor}
                        objectType={PropertyGroupObjectType.JobHasContractor}
                        embedPortal={'#embed-properties'}
                        onChange={(v: IPropertyGroup[]) => form.setFieldValue('properties', v)} />
                </div>
            </div>
            <div className='e' />
            <div className='r md pd'>
                <span className='e' />
                <div className='p-buttonset'>
                    <button
                        onClick={() => props.onCancel()}>
                        {t('Cancel')}
                    </button>
                    <button
                        disabled={loading.isLoading()}
                        onClick={doSave}
                        className='primary'>
                        {t('Save')}
                    </button>
                </div>
            </div>
        </>}

        {formType == FormType.Requirements && <div className='md pd'>
            {jobHasContractor.value &&
                <EditRequirements
                    job={props.job}
                    targetId={jobHasContractor.value.id}
                    targetTitle={jobHasContractor.value.contractor?.name}
                    targetType={RequirementTargetType.JobHasContractor}
                    workId={props.work.id} />}
        </div>}
    </div>
}
