import * as React from 'react';

import { BreadcrumbItem, G, ValidationBuilder, useForm, useLoading, useTranslation, useMessage, } from '@components';
import { IOption, ISecurity, IDepartment } from '@models';
import { IRequirementFulfillData, RequirementTargetType } from "@models/requirements";
import {
    goToJobEditContractorRequirements, goToJobEditMachineryRequirements, goToJobEditWorkerRequirements
} from '../routes';

import './CreateRequirement.scss';
import RemoteHtml from '@components/custom/RemoteHtml';

type CreateT = (
    workId: number,
    jobId: number,
    data: IRequirementFulfillData,
    resourceType: number | undefined,
    resourceId: number | undefined,
    statusType: number | undefined
) => Promise<IOption<number>>;

type CreateData = {
    title: string,
    remarks?: string,
    jobId: number,
    isShared: boolean,
    workId: number,
    color?: string,
    departmentId?: number,
}

export interface IProps {
    createFreeRequirement: CreateT;
    jobId: number;
    security: ISecurity;
    workId: number;
    departments: IDepartment[];

    resourceType?: number;
    resourceId?: number;
    statusType?: number;
}

export function CreateRequirement(props: IProps) {
    const loading = useLoading();
    const msg = useMessage();
    const { t } = useTranslation();

    let color: string | undefined = undefined;

    const form = useForm<CreateData>({
        initialValues: {
            jobId: props.jobId,
            title: '',
            remarks: '',
            isShared: false,
            workId: props.workId,
        },
        validateOnMount: true,
        validate: ValidationBuilder.new().notEmpty('title').lift(),
    });

    const cancel = () => {
        if (props.resourceType == RequirementTargetType.JobHasContractor) {
            goToJobEditContractorRequirements(props.workId, props.jobId, props.resourceId!);
        }
        else if (props.resourceType == RequirementTargetType.JobHasWorker) {
            goToJobEditWorkerRequirements(props.workId, props.jobId, props.resourceId!);
        }
        else if (props.resourceType == RequirementTargetType.JobHasMachinery) {
            goToJobEditMachineryRequirements(props.workId, props.jobId, props.resourceId!);
        }
    }

    const createRequirement = loading.wrap(async () => {
        const res = await props.createFreeRequirement(
            props.workId,
            props.jobId,
            {
                title: form.values.title,
                remarks: form.values.remarks,
                color: color,
                departmentId: form.values.responsible,
                requirementId: 0,
                workId: props.workId,
                isShared: form.values.isShared,
                documents: [],
            },
            props.resourceType,
            props.resourceId,
            props.statusType
        );
        msg.set(res);

        if (props.resourceType == RequirementTargetType.JobHasContractor) {
            goToJobEditContractorRequirements(props.workId, props.jobId, props.resourceId!);
        }
        else if (props.resourceType == RequirementTargetType.JobHasWorker) {
            goToJobEditWorkerRequirements(props.workId, props.jobId, props.resourceId!);
        }
        else if (props.resourceType == RequirementTargetType.JobHasMachinery) {
            goToJobEditMachineryRequirements(props.workId, props.jobId, props.resourceId!);
        }
    });

    function departmentColorPortovesme(departmentId: number): string {
        switch (departmentId) {
            case 114:
                //Sicurezza - Orange
                color = "#ff9b00"
                break;
            case 115:
                //Terzi - Green
                color = "#008000";
                break;
            case 116:
                //HSE Terzi - Blue
                color = "#0000ff";
                break;
            case 122:
                //Responsabile Unità di Produzione - Light Green
                color = "#00ff00"
                break;
            case 119:
                //Appalti - Purple
                color = "#8000ff"
                break;
            default:
                color = "#000000";
                break;
        }
        return color;
    }

    return <div className={'CreateRequirement c we he'}>
        <BreadcrumbItem text={t('Create requirement')} />

        <div className={'c e'}>
            <div className={'md pd e r g-20'}>
                <div className='c g-20 e form-1 l200'>
                    <G label={t('Title')}>
                        {form.input('title', { autoFocus: true })}
                    </G>
                    {/** #1145: PESSMA ocultar notas al crear requerimientos libres
                    <G label={t('Remarks')}>
                        {form.textarea('remarks')}
                        </G>
                     */}
                    <div>
                        {props.workId == 21 && <><G label={t('Review Responsible')}>
                            {form.select('responsible', props.departments.filter(d => d.id == 115 || d.id == 116 || d.id == 114 || d.id == 122 || d.id == 119).sort((a, b) => a.id - b.id))}
                            {form.values.responsible != undefined && <>
                                <div className='color-dot' style={{ backgroundColor: departmentColorPortovesme(form.values.responsible) }} />
                            </>}
                        </G></>}
                    </div>
                    <div className='r r-end'>
                        <label style={{ width: '400px' }}>{t('Shared requirement?')}</label>
                        {form.checkBox('isShared')}
                    </div>
                    <div className='center legend'>
                        <RemoteHtml silent resource={'Requirements/' + props.workId + '/Legend.html'} useI18n />
                    </div>
                </div>
            </div>
        </div>

        {msg.renderIfError()}

        <div className={'r r-end sm pd'}>
            {loading.render()}
            <div className='p-buttonset'>
                <button onClick={cancel}>{t('Cancel')}</button>
                <button
                    className={'primary'}
                    disabled={form.isInvalid()}
                    onClick={createRequirement}>{t('Create')}</button>
            </div>
        </div>
    </div>
}
