import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as User from '@store/user';
import * as Job from '@store/jobs';

import { Jobs } from '@components/user/Jobs';
import { ModuleManager } from '@models';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...User.plainActions,
        ...Job.plainActions,
        moduleManager: state.modules.moduleManager,
        activityTypes: state.user.activityTypes,
        contractTypes: state.user.contractTypes,
        departments: state.user.departments,
        modules: state.modules.modules,
        work: state.user.work,
        workers: state.user.workers,
        workspaces: state.user.workspaces,
        workShifts: state.user.workShifts,
        resourceStatusTypes: state.user.resourceStatusTypes,
        jobs: []
    }),
    {
        ...User.actionCreators,
        clearSelectedJob: Job.actionCreators.clearSelectedJob,
    },
)(Jobs as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
