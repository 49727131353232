import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Identity from '../../store/identity';
import * as UserStore from '@store/user';

import LoginPanel, { IProps } from '../../components/auth/LoginPanel';

type Props =
    Identity.IdentityState
  & typeof Identity.actionCreators
  & IProps;

export default connect(
  (state: ApplicationState) => ({
    identity: state.identity,
    ...UserStore.plainActions,
  }),
  Identity.actionCreators
)(LoginPanel as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
