import * as React from 'react';
import { IFrontendModule, RenderComponent } from '../../../../GStore.Modules/FrontEnd';
import { IdentityState } from '../store/identity';

export default class ModuleManager {
    // TODO: hacer identity obligatorio, borrando las instancias manuales del moduleManager,
    // utilizando solo la instancia del store
    private id: String = 'init';

    constructor(
        private modules: IFrontendModule[],
        private identity: IdentityState | null = null,
        aId: String | null = null
    ) {
        this.id = aId ?? this.id;
    }

    public getModule(name: string) {
        return this.modules.find(m => m.name == name);
    }

    public getId() {
        return this.id;
    }

    public componentProperties<T>(key: string, defaultValue: T | undefined = undefined) {
        const filterModules = this
            .modules
            .filter(m => m.componentProperties);

        return filterModules
            .reduce(
                (value: any, module: IFrontendModule) =>
                    value ? value : module.componentProperties[key] as T,
                defaultValue);
    }

    public useComponentProperties<T>(key: string, defaultValue: T | undefined = undefined) {
        const [value, setValue] = React.useState(defaultValue);

        React.useEffect(() => {
            setValue(this.componentProperties(key, defaultValue));
        }, [this.modules]);

        return value;
    }

    public renderComponent<T>(
        key: string,
        ctx: any,
        originalComponent: React.FunctionComponent<T>,
        force: boolean = false) {
        const module: RenderComponent | undefined = this
            .modules
            .map(m => m.renderComponent?.(key, ctx))
            .find(m => m !== undefined && m !== null);
        const component = module?.(ctx);

        // no devolvemos el componente original
        // salvo que ya esten cargados los modulos o el parametro force == true
        if (this.id != 'init' || force) {
            return component ?? originalComponent(ctx);
        }
        else {
            return <img
                style={{ width: '32px', height: '32px' }}
                src='/img/loading.gif' />;
        }
    }

    public filterDependency<T>(
        key: string,
        ctx: any | undefined = {}) {
        const dependencyCtx = {
            ...ctx,
            identity: this.identity,
        };

        const filterModules = this
            .modules
            .filter(m => m.filterDependency);

        return (d: T) =>
            filterModules
                .reduce(
                    (value: boolean, module: IFrontendModule) =>
                        value && module.filterDependency!(key, dependencyCtx)(d),
                    true);
    }

    public injectComponents(
        key: string,
        ctx: any,
        dependencies: any) {
        for (const m of this.modules) {
            m.injectComponent?.(key, ctx, dependencies);
        }
    }

    public requirePermissionMemo(
        key: string,
        ctx: any,
        defaultValue: boolean | undefined,
        deps: any[],
    ) {
        const [value, setValue] = React.useState(defaultValue);

        const load = async () => {
            const p = await this.requirePermission(key, ctx, defaultValue);
            setValue(p);
        };

        React.useEffect(() => {
            load();
        }, deps);

        return value;
    }

    public async requirePermission(key: string, ctx: any, defaultValue: boolean | undefined) {
        const filterModules = this
            .modules
            .filter(m => m.hasPermission);

        if (filterModules != null && filterModules.length > 0) {
            let res = true;
            for (const f of filterModules) {
                const moduleRes = await awaitIfPromise(f.hasPermission!(key, ctx, defaultValue));
                res = res && moduleRes;
            }
            return res;
        }
        else {
            return defaultValue;
        }
    }

    public async hasPermission(key: string, ctx: any) {
        const filterModules = this
            .modules
            .filter(m => m.hasPermission);

        let res = true;
        for (const f of filterModules) {
            res = res && await awaitIfPromise(f.hasPermission!(key, ctx, undefined));
        }

        return res;
    }

    public injectDataColumns<T>(name: string, ctx: T) {
        // consultamos a los módulos si tienen alguna columna que mostrar
        const columns = [];

        for (const m of this.modules) {
            if (m.injectDataColumns) {
                columns.push(...m.injectDataColumns(name, ctx));
            }
        }

        return columns;
    }

    public injectAction<T>(name: string, ctx: T) {
        for (const m of this.modules) {
            if (m.injectAction) {
                return m.injectAction(name, ctx);
            }
        }
    }

    public format<T>(key: string, data: T, ctx: any | undefined = undefined) {
        const filterModules = this
            .modules
            .filter(m => m.format != null);

        if (filterModules != null) {
            let res = true;
            for (const f of filterModules) {
                const moduleRes = f.format!(key, data, ctx);
                if (moduleRes) {
                    return moduleRes;
                }
            }
        }

        return undefined;
    }

    public validateData<T>(key: string, data: T, ctx: any | undefined = undefined) {
        const filterModules = this
            .modules
            .filter(m => m.validateData != null);

        if (filterModules != null) {

            for (const f of filterModules) {
                const moduleRes = f.validateData!(key, data, ctx);
                if (moduleRes?.valueOf) {
                    return moduleRes;
                }
            }
        }

        return true;
    }

    public async getMenuItems(ctx: any) {
        const items = [];

        for (const m of this.modules) {
            if (m.getMenuItems) {
                const receivedItems = await awaitIfPromise(m.getMenuItems(ctx));
                items.push(...receivedItems);
                // items.push(...m.getMenuItems(ctx));
            }
        }

        return items;
    }
}

function awaitIfPromise<T>(arg0: T | Promise<T>): Promise<T> {
    if (arg0 instanceof Promise) {
        return arg0;
    }
    else {
        return Promise.resolve(arg0);
    }
}
