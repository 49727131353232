import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import HelpDeskSupport from '../components/HelpDeskSupport';
import * as UserStore from '../store/user';

export default connect(
    (state: ApplicationState) => ({
        workId: state.user?.work?.id,
        ...UserStore.plainActions,
    }),
    {},
)(HelpDeskSupport as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
