import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as NotificationsStore from '@store/notifications';

import { NotificationsCenter } from '@components/notifications/NotificationsCenter';

export default connect(
  (state: ApplicationState) => ({
    messages: state.notifications.messages,
    ...state.identity,
    workId: state.work.work?.id ?? state.user.work?.id
  }),
  {
    loadMessages: NotificationsStore.actionCreators.loadMessages,
    resolveMessage: NotificationsStore.actionCreators.clearMessage,
    resolveMessages: NotificationsStore.actionCreators.clearMessages,
  },
)(NotificationsCenter as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
