import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';

import { Loading } from '../../components';

const Loader = (props: any) => {
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        const jobId = props.jobId ?? parseFloat(props.match.params.jobid);
        if (!props.job || props.job.id != jobId) {
            setLoading(true);
            props.loadJob(props.work?.id, jobId);
        }
        else if (props.job?.id == jobId) {
            setLoading(false);
        }
    }, [props.id, props.work, props.job]);

    if (loading) {
        return <Loading />;
    }
    else {
        return React.cloneElement(props.children, { job: props.job });
    }
}

export default connect(
    (state: ApplicationState) => ({
        work: state.user.work,
        job: state.jobs.selectedJob,
    }),
    JobsStore.actionCreators,
)(Loader as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
