import { Action, Reducer } from 'redux';
import * as Actions from './actions/app';
import * as Constants from './constants';
import * as Identity from './identity';

export interface IAppState {
    loading: boolean;
    loadingCtx?: string;
    loadingMessage?: string;
}

const stopLoadingType = '[APP] STOP LOADING';
const startLoadingType = '[APP] START LOADING';

export interface StopLoadingAction {
    type: '[APP] STOP LOADING';
}
export interface StartLoadingAction {
    type: '[APP] START LOADING';
    ctx?: string;
    message?: string;
}

export type KnownAction = StartLoadingAction | StopLoadingAction | {type: undefined};

const stopLoading = () => ({ type: stopLoadingType });
const startLoading = (action: Partial<StartLoadingAction>) => ({
    ...action,
    type: startLoadingType
});

export const actionCreators = {
    startLoading,
    stopLoading,
    initialize: () => async (dispatch: Function) => {
        const resp = await Actions.initialize();

        if (resp.data && resp.data.user) {
            dispatch(Identity.actionCreators.setUser(resp.data.user));
        }
        if (resp.data) {
            dispatch(Constants.actionCreators.initialize({
                countries: resp.data.countries,
                documentTypes: resp.data.documentTypes,
                requirementExpirationTypes: resp.data.requirementExpirationTypes,
                requirementTypeKinds: resp.data.requirementTypeKinds,
            }));
        }
        dispatch(stopLoading());
    }
};

export const reducer: Reducer<IAppState> = (state: IAppState | undefined, incomingAction: Action): IAppState => {
    if (state === undefined) {
        return { loading: true };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case startLoadingType:
            return { ...state, loading: true, loadingCtx: action.ctx, loadingMessage: action.message };
        case stopLoadingType:
            return { ...state, loading: false, loadingCtx: undefined, loadingMessage: undefined };
        default:
            return state;
    }
};
