import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import UserProfile from '../components/UserProfile';
import * as UserStore from '../store/user';

export default connect(
    (state: ApplicationState) => ({
        workId: state.user?.work?.id,
        user: state.identity?.user,
        security: state.identity?.security,
        ...UserStore.plainActions,
    }),
    {},
)(UserProfile as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
