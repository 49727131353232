import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as MachineriesStore from '@store/machineries';
import { EditMachineryForm } from '@components/machineries/EditMachineryForm';
import { isMachineryPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState, props: any) => ({
        workId: props.workId ?? parseInt(props.match.params.workid),
        machineryId: props.machineryId ?? parseInt(props.match.params.machineryId),
        ...state.identity,
        ...state.user,
        ...MachineriesStore.plainActions,
        moduleManager: state.modules.moduleManager,
        resourceStatusTypes: state.user.resourceStatusTypes,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isMachineryPropertyGroupType),
    }),
    MachineriesStore.actionCreators,
)(EditMachineryForm as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
