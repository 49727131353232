import * as React from 'react';

import { G, Loading, Message, useForm, useTranslation, ValidationBuilder } from '@components';
import { folderKindOptions } from '@models/documents';

export interface IProps {
    folderId?: number;
    loading?: boolean;
    onCancel?: Function;
    onSave?: Function;
    workId: number;
}

type IFileFormData = {
    name: string;
    title?: string;
    description?: string;
    folderId?: number;
    workId: number;
    code?: string;
    edition?: string;
    year?: number;
}

export function UploadFileForm(props: IProps) {
    const { t } = useTranslation();
    const form = useForm<IFileFormData>({
        initialValues: {
            name: '',
            title: '',
            description: '',
            code: '',
            edition: '',
            year: undefined,
            folderId: props.folderId,
            workId: props.workId,
        },
        validate: d => ValidationBuilder
            .create(d)
            .notEmpty('title')
            // .custom('You must select one file', () => selectedFile.current != null)
            .build(),
    });

    const selectedFile = React.useRef<File | undefined>();

    const handleFile = (e: any) => {
        e.persist();
        const files = e.target.files;
        if (files.length > 0) {
            selectedFile.current = files[0];
            form.setFieldValue('name', files[0].name);
        }
    }

    const handleSave = (...e: any[]) => {
        props.onSave?.(form.values, selectedFile.current);
    }

    return <div>
        <div className={'c md pd g-20 l300'}>
            <G label={t('document.name')}>
                {form.span('name', { autoFocus: true })}
            </G>
            <G label={t('Title')}>
                {form.input('title')}
            </G>
            <G label={t('document.code')}>
                {form.input('code')}
            </G>
            <G label={t('Edition')}>
                {form.input('edition')}
            </G>
            <G label={t('Year')}>
                {form.input('year', { type: 'number' })}
            </G>
            <G label={t('Order')}>
                {form.input('sortOrder', { type: 'number' })}
            </G>
            <G label={t('document.permissions')}>
                {form.select('folderKind', {
                    blank: true,
                    options: folderKindOptions,
                })}
            </G>
            <G label={t('File')}>
                <div className={'sm pd e'}>
                    <input type={'file'} onChange={handleFile} />
                </div>
            </G>

            {props.loading && <div className={'sm pd e'}>
                <Loading />
            </div>}

            {form.isInvalid() && <div className={'sm pd e'}>
                <Message severity={'error'}>{'Invalid form'}</Message>
            </div>}

            {form.errorBox()}
        </div>

        <div className={'footer r r-end'}>
            {props.onCancel && <button onClick={() => props.onCancel?.()}>{t('Cancel')}</button>}
            {props.onSave && <button
                className={'primary'}
                disabled={props.loading || form.isInvalid()}
                onClick={handleSave}>{t('Save')}</button>}
        </div>
    </div>
}
