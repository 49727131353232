import * as React from 'react';

import './TogglePanel.scss';

export interface IProps {
    bodyClassName?: string;
    children?: any;
    className?: string;
    isOpen?: boolean;
    title: string;
    titleIcon?: string;
}

export function TogglePanel(props: IProps) {
    const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen ?? false);

    return <div className={'Toggler ' + props.className}>
        <div className={'title'} onClick={_ => setIsOpen(a => !a)}>
            <i className={isOpen ? 'fas fa-caret-down' : 'fas fa-caret-right'} />
            {props.titleIcon &&
                <i className={props.titleIcon} />}
            <span>{props.title}</span>
        </div>

        {isOpen &&
            <div className={'body ' + props.bodyClassName}>
                {props.children}
            </div>}
    </div>
}