import * as React from 'react';

import {
    ConfirmDialog, Message, useDataTable, useFormDialog, useLoading, useTranslation
} from '@components';
import { ILegalForm } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { LegalFormForm } from './LegalFormForm';

export interface IProps {
    workId: number;
}

export function AdminLegalForm(props: IProps) {
    const { t } = useTranslation();

    const [error, setError] = React.useState<string | undefined>();
    const [legalForms, setLegalForms] = React.useState<ILegalForm[]>([]);
    const [workerTypes, setWorkerTypes] = React.useState<ILegalForm[]>([]);
    const loading = useLoading(true);
    const formDialog = useFormDialog({
        editTitle: t('Edit legal form'),
        addTitle: t('Add legal form'),
    });
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<ILegalForm | undefined>();

    const initialize = loading.wrap(async () => {
        const res = await Actions.getLegalForms(props.workId);
        const workerTypes = await Actions.getWorkerTypes(props.workId);

        setLegalForms(res.data.work.legalForms);
        setWorkerTypes(workerTypes.data.work.workerTypes);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const saveLegalForm = loading.wrap(async (w: ILegalForm) => {
        await Actions.saveLegalForm(w);
        await initialize();

        formDialog.clear();
    });

    const removeLegalForm = loading.wrap(async (id: number, workId: number) => {
        setError(undefined);

        workId = props.workId;
        await Actions.removeLegalForm(id, workId);
        await initialize();

        formDialog.clear();
    });

    const dataTable = useDataTable({
        data: legalForms,
        loading,
        className: 'e',
        columns: [
            { field: 'id', className: 'td-sm' },
            'name',
            { field: 'workerTypes', render: (w: ILegalForm) => w.workerTypes?.map(wt => wt.workerType.name).join(', '), title: t('Worker types') },
            {
                field: 'hideContractorProperties', title: t('hideContractorProperties'), render: (l: ILegalForm) => {
                    if (l.hideContractorProperties) {
                        return <div className='center'>
                            <i className='pi pi-check' />
                        </div>
                    }
                    return <div className='center'>
                        <i className='pi pi-ban' />
                    </div>
                }
            }
        ],
        actions: [
            { icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit },
            { icon: 'trash', tooltip: t('Delete'), onClick: setShowConfirmRemove }
        ]
    });

    return <div className='he c'>
        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeLegalForm(showConfirmRemove.id, showConfirmRemove.workId)} />}

        {formDialog.render((data: ILegalForm) =>
            <LegalFormForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveLegalForm}
                footer={formDialog.Footer()}
                workerTypes={workerTypes} />)}

        {dataTable()}

        <div className='r sm pd footer'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />
            <button className='p-button'
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add')}
            </button>
        </div>
    </div>
}