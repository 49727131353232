import * as React from 'react';

import { useForm, useLoading, useTranslation } from '@components';
import { IMachinerySettings, IWork } from '@models';
import { WorkToolbar } from '../WorkToolbar';

export interface IProps {
    work: IWork;
    saveMachinerySettings: Function;
}

export function ManageMachinerySettings(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();

    const machinerySettings = props.work.machinerySettings ?? {
        showGeneralIndicators: false,
        subTypeMandatory: false,
        onlyLicenseNumber: false,
        workId: props.work.id
    };

    const form = useForm<IMachinerySettings>({
        initialValues: machinerySettings,
    });

    const doSave = loading.wrap(async () => {
        await props.saveMachinerySettings(props.work.id, form.values);
    });

    return <div className='c e md pd'>
        <WorkToolbar work={props.work} />

        <strong className='page-subtitle'>{t('machinery.settings')}</strong>

        <div className='c e sm pd g-20'>
            <div className='r g-20 v-center'>
                {form.checkBox('showGeneralIndicators')}
                <label htmlFor='showGeneralIndicators'>
                    {t('machinery.settings.showGeneralIndicators')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('subTypeMandatory')}
                <label htmlFor='subTypeMandatory'>
                    {t('machinery.settings.subTypeMandatory')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('onlyLicenseNumber')}
                <label htmlFor='onlyLicenseNumber'>
                    {t('machinery.settings.onlyLicenseNumber')}
                </label>
            </div>
            <div className='e'></div>
        </div>
        <div className='r r-end'>
            {loading.render()}
            <button
                className='primary'
                disabled={loading.isLoading()}
                onClick={_ => doSave()}>{t('Save')}</button>
        </div>
    </div>;
}
