import * as React from 'react';

import { useLoading } from '@utils/hooks';

export function InlineInputText({className, style, object, field, save}: {className: string, style?: any, object: any, field: string, save: Function}) {
    const loading = useLoading();

    const update = loading.wrap(async (ev: any) => {
        object[field] = ev.target.value;

        await save(object);
    });

    return <div className={className || 'r'} style={style}>
        <input
            type='text'
            className='e transparent'
            value={object[field]}
            onChange={update} />
        {loading.render()}
    </div>;
}
