import * as React from 'react';

import { IRequirement, IRequirementIncidence, IRequirementType, IRequirementValidationData } from '@models/requirements';
import { Message, R, useForm, useLoading, useMessage, useTranslation } from '@components';

import './ValidateRequirement.scss';
import { IOption, IUserIdentity, ModuleManager } from '@models';
import { RequirementDocumentInfo } from './RequirementDocumentInfo';
import dateUtils from '@utils/date-utils';

type RequirementCanBeValidatedF = (workId: number, requirementId: number) => Promise<boolean>;

type ValidateF = (
    workId: number,
    requirementId: number,
    validate: boolean,
    data: IRequirementValidationData,
) => Promise<IOption<boolean>>;

type saveRequirementIncidenceT = (
    workId: number,
    data: IRequirementIncidence,
    fileResourceId: string|undefined,
    fileResourceName: string|undefined) => Promise<IOption<number>>;

export interface IProps {
    requestClose: Function;
    requirement: IRequirement;
    requirementType: IRequirementType;
    requirementCanBeValidated: RequirementCanBeValidatedF;
    moduleManager: ModuleManager;
    showInfo?: boolean;
    validateRequirement: ValidateF;
    saveRequirementIncidence: saveRequirementIncidenceT;
    user: IUserIdentity;
    workId: number;
}

type ValidateRequirementModuleProps = {
    disableDenyWithValidation?: boolean;
}

export function ValidateRequirement(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading(true);
    const messages = useMessage();

    const [canBeValidated, setCanBeValidated] = React.useState(false);

    const initialize = loading.wrap(async () => {
        const canBeValidated = await props.requirementCanBeValidated(
            props.requirement.workId,
            props.requirement.id);
        setCanBeValidated(canBeValidated || props.user.roles.includes('gestor'));
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const form = useForm<IRequirementValidationData>({
        initialValues: {
            remarks: '',
            expirationThreshold: props.requirementType.expirationThreshold,
            expirationDate: dateUtils.toUtc(props.requirement.expirationDate ?? undefined),
        },
    });

    const validate = loading.wrap(async (valid: boolean = true) => {
        // #275 Si se rechaza el requerimiento las observaciones son obligatorias
        if (!valid && !(form.values.remarks && form.values.remarks != '')) {
            messages.setError(t('requirements.incidence.remarks-mandatory'));
            return;
        }
        if(!valid){
            form.values.requirementId = props.requirement.id;
            form.values.remarks = form.values.remarks;
            form.values.createdById = props.user.id;
            form.values.creationDate = new Date();
            form.values.expirationDate = undefined;
            form.values.expirationThreshold = undefined;
            form.values.workId = undefined;
            const resp = await props.saveRequirementIncidence(props.requirement.workId, form.values, undefined, undefined);
            if (resp.hasValue && resp.value) {
                props.requestClose(true);
            }
        }
        else {
            const resp = await props.validateRequirement(
                props.requirement.workId,
                props.requirement.id,
                valid,
                form.values);
            messages.set(resp);
            if (resp.hasValue && resp.value) {
                props.requestClose(true);
            }
        }
    });

    const deleteDate = () => {
        form.setFieldValue('expirationDate', undefined);
    }

    const suggestions = (props.requirementType?.suggestions?.map(s => s.name) ?? []);

    const componentProperties = props
        .moduleManager
        .useComponentProperties<ValidateRequirementModuleProps>('requirements.validate');

    // #1242
    // ciertas obras (ej. Azsa) pueden deshabilitar
    // la posibilidad de rechazar los requerimientos
    // desde el formulario de validacion
    const canBeDenied = canBeValidated
        && !(componentProperties?.disableDenyWithValidation ?? false);

    return <div className='c ValidateRequirement'>
        <div className='r'>
            <div className='sm pd form-1 l200'>
                {canBeValidated && <>
                    <R label={t('requirements.observations')}>
                        {form.autoCompleteTextArea(
                            'remarks',
                            {autoFocus: true, suggestions, style: {minWidth: '400px', minHeight: '150px'}})}
                    </R>
                    <div className='ValidateDate'>
                    <R label={t('Expiration date')}>
                        {form.calendar('expirationDate')}
                        <i className='pi pi-trash pointer' title={t('delete.calendar')}
                        onClick={deleteDate}/>
                    </R>
                    </div>
                    <R label={t('Tolerance')}>
                        {form.input('expirationThreshold', {type: 'number'})}
                    </R>
                </>}
            </div>

            {props.showInfo
                && props.requirement.documents
                && props.requirement.documents?.length > 0
                &&
                <RequirementDocumentInfo
                    document={props.requirement.documents?.[0]}
                    requirement={props.requirement}/>}
        </div>

        {messages.render()}

        {!canBeValidated &&
            <div className='sm pd text-sm r'>
                <Message
                    className='e'
                    severity={'error'}
                    text={t('requirement.canNotBeValidated')} />
            </div>}

        <div className='footer r r-end'>
            {loading.render()}
            <button disabled={loading.isLoading()} onClick={() => props.requestClose()}>
                {t('Close')}
            </button>
            {canBeDenied && 
                <button disabled={loading.isLoading()} className='danger' onClick={() => validate(false)}>
                    {t('Deny')}
                </button>}
            {canBeValidated &&
                <button disabled={loading.isLoading()} className='success' onClick={() => validate(true)}>
                    {t('Validate')}
                </button>}
        </div>
    </div>;
}
