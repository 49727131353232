import * as React from 'react';

import { IDepartment } from '@models';
import {
    Chips,
    G,
    ValidationBuilder,
    useForm,
    useTranslation,
    useLoading,
} from '@components';

export interface IProps {
    data?: IDepartment;
    saveDepartment: Function;
    saveDepartmentPolicy: Function;
    removeDepartmentPolicy: Function;
    workId: number;
    onCancel?: Function;
    onSaved: Function;
}

export function DepartmentForm(props: IProps) {
    const { t } = useTranslation();

    const loading = useLoading();
    const [policies, setPolicies] = React.useState<string[]>(props.data?.policies ?? []);

    const form = useForm<IDepartment>({
        initialValues: {
            id: props.data?.id ?? 0,
            code: props.data?.code,
            name: props.data?.name ?? '',
            description: props.data?.description ?? '',
            isOblique: props.data?.isOblique ?? false,
            notifyJobCreation: props.data?.notifyJobCreation ?? false,
            workId: props.workId,
        },
        validateOnMount: true,
        validate: (data: IDepartment) => {
            return ValidationBuilder.create(data).notEmpty('name').build();
        },
    });

    const doSave = loading.wrap(async () => {
        await props.saveDepartment(form.values);
        props.onSaved();
    });

    const onAddPoliciy = loading.wrap(async (e: any) => {
        await props.saveDepartmentPolicy(props.workId, props.data?.id ?? 0, e.value);
        setPolicies([...policies, e.value]);
    });

    const onRemovePolicy = loading.wrap(async (e: any) => {
        const value = e.value[0];
        await props.removeDepartmentPolicy(props.workId, props.data?.id ?? 0, value);
        setPolicies(policies.filter(p => p != value));
    });

    return <div>
        <div className='md pd mrg c form-1 l200'>
            <G label={t('Code')}>
                {form.input('code', { autoFocus: true })}
            </G>
            <G label={t('Name')}>
                {form.input('name', { autoFocus: true })}
            </G>
            <G label={t('Description')}>
                {form.input('description')}
            </G>
            <G label={t('Oblique')}>
                {form.checkBox('isOblique', { containerClassName: 'right' })}
            </G>
            <G label={t('notifyJobCreation')}>
                {form.checkBox('notifyJobCreation', { containerClassName: 'right' })}
            </G>
            {(props.data?.id ?? 0) > 0 && <G label={t('Policies')}>
                <div className='c'>
                    <Chips
                        className='e-ch'
                        separator=','
                        value={policies}
                        onAdd={onAddPoliciy}
                        onRemove={onRemovePolicy} />
                </div>
            </G>}
        </div>

        <div className='footer r r-end'>
            <button onClick={() => props.onCancel?.()}>{t('Cancel')}</button>
            <button disabled={loading.isLoading() || form.isInvalid()}
                onClick={() => doSave()} className='primary'>
                {t('Save')}
            </button>
        </div>
    </div>
}
