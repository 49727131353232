import * as React from 'react';

import { IContractor, IWork } from '@models';
import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { RequirementTargetType } from '@models/requirements';
import { useLoading } from '@components';

type ContractorFromAppUserF = (workId: number) => Promise<IContractor>;

export interface IProps {
    getContractorFromAppUser: ContractorFromAppUserF;
    work: IWork;
}

export function ContractorRequirements(props: IProps) {
    const [contractor, setContractor] = React.useState<IContractor|undefined>();

    const loading = useLoading();

    const getContractor = loading.wrap(async () => {
        const c = await props.getContractorFromAppUser(props.work.id);

        setContractor(c);
    });

    React.useEffect(() => {
        getContractor();
    }, [props.work]);

    return <div className='md pd'>
        {loading.renderBox()}

        {contractor &&
            <EditRequirements
                targetType={RequirementTargetType.Contractor}
                targetId={contractor.id}
                targetTitle={contractor.name}
                workId={props.work.id} />}
    </div>
}