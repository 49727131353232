import * as React from 'react';

import { useTranslation, Tag, } from '@components';
import { IMessage, MessageType, ResourceType } from '@models';

export interface IProps {
    message: IMessage;
}

export function MessageIcon({ message }: IProps) {
    const { t } = useTranslation();

    if (message.notificationType === MessageType.JOB_CREATED) {
        return <Tag value={t('messages.job.created')} />;
    }
    else if (message.notificationType === MessageType.JOB_INFORMATION_LOADED) {
        return <Tag value={t('messages.type.job_information_loaded')} />;
    }
    else if (message.notificationType === MessageType.PENDING_REQUIREMENT
        && message.data.ResourceType === ResourceType.Worker) {
        return <Tag value={t('requirement.notification.document.worker')} />;
    }
    else if (message.notificationType === MessageType.PENDING_REQUIREMENT
        && message.data.ResourceType === ResourceType.JobHasWorker) {
        return <Tag value={t('requirement.notification.document.worker')} />;
    }
    else if (message.notificationType === MessageType.PENDING_REQUIREMENT
        && message.data.ResourceType === ResourceType.Machinery) {
        return <Tag value={t('requirement.notification.document.machinery')} />;
    }
    else if (message.notificationType === MessageType.PENDING_REQUIREMENT
        && message.data.ResourceType === ResourceType.JobHasMachinery) {
        return <Tag value={t('requirement.notification.document.machinery')} />;
    }
    else if (message.notificationType === MessageType.PENDING_REQUIREMENT
        && message.data.ResourceType === ResourceType.Contractor) {
        return <Tag value={t('requirement.notification.document.contractor')} />;
    }
    else if (message.notificationType === MessageType.PENDING_REQUIREMENT
        && message.data.ResourceType === ResourceType.JobHasContractor) {
        return <Tag value={t('requirement.notification.document.contractor')} />;
    }
    else if (message.notificationType === MessageType.REQUIREMENT_VALIDATED) {
        return <Tag className={message.data.Severity ?? 'success'} value={t(message.data.NotificationTitle ?? 'requirement.validated.title')} />;
    }
    else if (message.notificationType === MessageType.INVALID_ITA) {
        return <Tag className={'g'} value={t('requirement.notification.invalid-ita.title')} />;
    }
    else if (message.notificationType === MessageType.REQUIREMENT_INCIDENCE) {
        return <Tag className={'danger'} value={t('requirement.incidence')} />;
    }
    else if (message.notificationType === MessageType.SUBCONTRACTOR_REQUEST) {
        return <Tag className={'warning'} value={t('subcontractor.request.message-title')} />;
    }
    else if (message.notificationType === MessageType.SUBCONTRACTOR_REQUEST_ACCEPTED) {
        return <Tag className={'success'} value={t('subcontractor.request.message-title')} />;
    }
    else if (message.notificationType === MessageType.SUBCONTRACTOR_REQUEST_REJECTED) {
        return <Tag className={'danger'} value={t('subcontractor.request.message-title')} />;
    }
    else if (message.notificationType === MessageType.JOB_FINALIZED) {
        return <Tag className={'finalized'} value={t('job.notification.finalized.title')} />;
    }
    else if (message.notificationType === MessageType.RESOURCE_AUTHORIZED) {
        return <Tag className={'success'} value={t('notifications.resource-authorized.title')} />;
    }
    else if (message.notificationType === MessageType.RESOURCE_REJECTED) {
        return <Tag className={'danger'} value={t('notifications.resource-rejected.title')} />;
    }
    else if (message.notificationType === MessageType.JOB_AUTHORIZED) {
        return <Tag className={'success'} value={t('notifications.job-authorized.title')} />;
    }
    else if (message.notificationType === MessageType.JOB_REVOKED) {
        return <Tag className={'danger'} value={t('notifications.job-revoked.title')} />;
    }
    else if (message.notificationType === MessageType.REQUIREMENT_EXPIRED) {
        return <Tag className={'danger'} value={t('requirement.expired')} />;
    }
    else if (message.notificationType === MessageType.MODULE_MESSAGE) {
        return <Tag value={t(message.data.Tag)} />;
    }
    else if (message.notificationType === MessageType.REQUIREMENT_NEAR_EXPIRATION) {
        return <Tag className={'danger'} value={t('requirement.near.expiration')} />;
    }
    else {
        return null;
    }
}