import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import * as JobsStore from '../../store/jobs';
import { WorkSubContractorRequests } from '../../components/work/WorkSubContractorRequests';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...ContractorsStore.plainActions,
        ...JobsStore.plainActions,
        moduleManager: state.modules.moduleManager,
    }),
    {}
)(WorkSubContractorRequests as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
