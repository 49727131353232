import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { ManageJobMachineries } from '../../components/jobs/ManageJobMachineries';
import { hasPermission } from '../../utils/security';
import { isMachineryPropertyGroupType } from '../../models';

export default connect(
    (state: ApplicationState, props: any) => ({
        ...JobsStore.plainActions,
        ...state.identity,
        ...state.user,
        ...state.jobs,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isMachineryPropertyGroupType),
        hasPermission: (p: string, explicit: boolean | undefined = undefined) => hasPermission(p, {
            user: state.identity?.user,
            work: state.user.work,
            job: state.jobs.selectedJob,
            explicit: explicit ?? true,
        }),
        resourceStatusTypes: state.user.resourceStatusTypes,
        workSettings: state.user.work?.settingsObj,
        contractorId: props.contractorId,
        jobHasContractorId: props.jobHasContractorId ?? state.jobs.selectedJobHasContractor?.id,
        moduleManager: state.modules.moduleManager,
    }),
    JobsStore.actionCreators,
)(ManageJobMachineries as any) as any;
