import * as React from 'react';

import { classNames } from 'primereact/utils';
import SignalManager from '@components/common/SignalManager';

export interface IProps {
    className?: string;
    name?: string;
    label: string;
    children: any;
    signals?: SignalManager;
}

export function G({className, children, label, name, signals}: IProps) {
    return <>
        {(signals == undefined || signals?.fieldIsVisible(name)) &&
        <div className={classNames('inputgroup', className)}>
            <label>{label}</label>
            {children}
        </div>}
    </>;
}
