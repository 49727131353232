import * as React from 'react';

import { ISecurity } from '@models';
import { Card, G, Tag, useForm, useLoading, useMessage, useTranslation, ValidationBuilder } from '@components';
import { IChangePasswordData } from '@models/forms';

import './ChangePassword.scss';

export interface IProps {
    changePassword: Function;
    security: ISecurity;
}

const validateUserData = (data: IChangePasswordData) => {
    return ValidationBuilder.create(data)
        .notEmpty('password', 'Password is required')
        .equalTo('passwordConfirmation', 'password', 'Passwords do not match')
        .build();
}

export function ChangePassword(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();

    const messages = useMessage();
    const form = useForm<IChangePasswordData>({
        validateOnMount: true,
        initialValues: {
            userId: props.security.user?.id,
            password: '',
            passwordConfirmation: '',
        },
        loading: loading,
        validate: validateUserData,
    });

    const doChange = loading.wrap(async (..._: any) => {
        const resp = await props.changePassword({
            ...form.values,
            userId: props.security.user?.id,
        });
        messages.set(resp, { successMessage: t('Password changed successfully') });
    });

    return <div className='c center lg mr ChangePassword'>
        <Card header={t('Change user password')} className='wx-600 bg-header pd-header'>
            <div className='form-1 l400 c'>
                <div className='md mr pd mutted r center g-20' style={{marginBottom: '40px'}}>
                    {t('Changing password for user')} <Tag className='gr'>{props.security.user?.email}</Tag>
                </div>
                <G label={t('Password')}>
                    {form.input('password', { type: 'password' })}
                </G>
                <G label={t('Password confirmation')}>
                    {form.input('passwordConfirmation', { type: 'password' })}
                </G>
            </div>
            <div className='lg mr-top pd-top border-top r'>
                <div className='e' />
                {loading.render()}
                <button
                    className='primary'
                    disabled={form.isInvalid()}
                    onClick={doChange}>{t('Change password')}</button>
            </div>
            {messages.render()}
        </Card>
    </div>
}