import { IOption, IPaginateResult, IPaginateSort, IUserSearchFilter } from '@models';
import { delay } from '@utils';

export const DEFAULT_LIMIT = 10;

type Options = {
    normalizeKeys?: boolean,
    filters?: any;
    exportColumns?: string[];
    page?: number;
    limit?: number;
    sortBy?: IPaginateSort[];
}
type OptionsWithFilters = Options | any;

function isOptions(a: any): a is Options {
    return a && (a.normalizeKeys !== undefined || a.filters);
}

const defaultOptions: Options = {
    page: 0,
    limit: DEFAULT_LIMIT,
};

const keyToCamelCase = (k: string) => {
    return k.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

const normalizeOjb = (data: any) => {
    const resp: any = {};

    for (const k of Object.keys(data)) {
        resp[keyToCamelCase(k)] = data[k];
    }

    return resp;
}

const normalizeKeys = (data: any[]) => {
    return data ? data.map(normalizeOjb) : data;
}

export async function search<T>(workId: number, name: string, rawOptions: OptionsWithFilters = {}): Promise<IOption<T[]>> {
    const rawFilters = isOptions(rawOptions) ? rawOptions.filters : rawOptions;
    const options = isOptions(rawOptions) ? rawOptions : defaultOptions;

    const filters: any[] = [];
    if (rawFilters != null) {
        for (const f of Object.keys(rawFilters)) {
            if (rawFilters[f] != undefined && (typeof (rawFilters[f]) != 'number' || !isNaN(rawFilters[f]))) {
                filters.push({
                    name: f,
                    value: rawFilters[f] + '',
                });
            }
        }
    }

    const request = {
        name,
        filters,
        exportColumns: options.exportColumns,
        order: options.sortBy,
    };
    const r = await fetch(`/api/search/${workId}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            ['Accept']: 'application/json',
            ['Content-Type']: 'application/json'
        }
    });
    const resp = await r.json();

    if (options.normalizeKeys) {
        return { ...resp, value: normalizeKeys(resp.value) };
    }
    else {
        return resp;
    }
}

export async function searchExportToXls(workId: number, name: string, rawOptions: OptionsWithFilters = {}, language?: string): Promise<Blob> {
    const rawFilters = isOptions(rawOptions) ? rawOptions.filters : rawOptions;
    const options = isOptions(rawOptions) ? rawOptions : defaultOptions;

    const filters: any[] = [];
    if (rawFilters != null) {
        for (const f of Object.keys(rawFilters)) {
            if (rawFilters[f] != undefined && (typeof (rawFilters[f]) != 'number' || !isNaN(rawFilters[f]))) {
                filters.push({
                    name: f,
                    value: rawFilters[f] + '',
                });
            }
        }
    }

    const request = {
        name,
        filters,
        language,
        exportColumns: options.exportColumns,
    };
    const r = await fetch(`/api/search/${workId}/export/xls`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            ['Content-Type']: 'application/json'
        }
    });
    const resp = await r.blob();
    return resp;
}

export async function paginate<T>(workId: number, name: string, rawOptions: OptionsWithFilters = {}): Promise<IOption<IPaginateResult<T>>> {
    const rawFilters = isOptions(rawOptions) ? rawOptions.filters : rawOptions;
    const options = isOptions(rawOptions) ? rawOptions : defaultOptions;

    const filters: any[] = [];
    if (rawFilters != null) {
        for (const f of Object.keys(rawFilters)) {
            if (rawFilters[f] != undefined && (typeof (rawFilters[f]) !== 'number' || !isNaN(rawFilters[f]))) {
                filters.push({
                    name: f,
                    value: rawFilters[f] + '',
                });
            }
        }
    }

    const request = {
        name,
        filters,
        page: options.page,
        limit: options.limit,
        order: options.sortBy,
    };

    const r = await fetch(`/api/search/${workId}/paginate`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            ['Accept']: 'application/json',
            ['Content-Type']: 'application/json'
        }
    });
    const resp = await r.json();

    if (options.normalizeKeys && resp.hasValue) {
        return { ...resp, value: { ...resp.value, data: normalizeKeys(resp.value.data) } };
    }
    else {
        return resp;
    }
}

export async function getUserFilters(workId: number, name: string): Promise<IUserSearchFilter[]> {
    const url = `/api/search/${workId}/filters`;
    const body = JSON.stringify({
        name,
    });

    const r = await fetch(url, {
        method: 'POST',
        body,
        headers: {
            ['Accept']: 'application/json',
            ['Content-Type']: 'application/json',
        }
    });

    if (r.status === 200) {
        const resp = await r.json();
        return resp;
    }
    else {
        throw 'not found';
    }
}