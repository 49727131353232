import * as React from 'react';

import { INotification, IUserIdentity, IWork, IWorkPermission } from '@models';
import { commonPermissions } from '../../security';
import * as Expressions from '@utils/expressions';
import {
    classNames,
    InputText,
    InputTextarea,
    Message,
    MultiSelect,
    NotificationsBox,
    Select,
    TabPanel,
    TabView,
    useForm,
    useLoading,
    useTranslation,
    ValidationBuilder,
} from '@components';

import './PermissionForm.scss';
import { IJob } from '../../../../../GStore.Modules/FrontEnd/GStore.WebApp/ClientApp/src/models';

export interface IProps {
    availableRoles: string[];
    users: IUserIdentity[];
    works: IWork[];
    data?: IWorkPermission;
    workId: number;
    footer?: any;
    notifications?: INotification[];
    onChange?: Function;
    onSubmit?: Function;
    getJobs?: Function;
}

export const validatePermission = (data: IWorkPermission|undefined) => {
    return ValidationBuilder.create(data).notEmpty('action').build();
}

export const isValid = (data: IWorkPermission|undefined) => {
    return Object.keys(validatePermission(data)).length == 0;
}

function TestExpression({expression, users, works, getJobs}: {
    expression: string, users: IUserIdentity[], works: IWork[], getJobs?: Function
}) {
    const loading = useLoading(false);
    const { t } = useTranslation();

    const [error, setError] = React.useState<string|undefined>();
    const [result, setResult] = React.useState<any>();
    const [teUser, setTeUser] = React.useState<number|undefined>();
    const [teWork, setTeWork] = React.useState<number|undefined>();
    const [teJob, setTeJob] = React.useState<number|undefined>();
    const [containsJob, setContainsJob] = React.useState<boolean>(false);
    const [jobs, setJobs] = React.useState<any[]>([]);


    React.useEffect(() => {
        setContainsJob(expression.includes('Job.'));
    }
    , [expression]);

    const initializeJobs = loading.wrap(async () => {
        const jobs = await getJobs!(teWork);

        setJobs(jobs.data.work.jobs);
    });

    React.useMemo(() => {
        if (getJobs && teWork != undefined && containsJob) {
            initializeJobs();
        }
    }, [teWork]);

    const testExpression = loading.wrap((e: any = null) => {
        setError(undefined);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        try {
            const work = works.find(w => w.id == teWork);
            const expr = Expressions.parse(expression);
            const r = Expressions.evaluate(expr, {
                identity: users.find(u => u.id == teUser),
                work,
                workId: work?.id,
                job: jobs.find(j => j.id == teJob),
                jobId: teJob,
            });
            if (expr) {
                setResult(r);
            }
            else {
                setError(t('Invalid expression'));
            }
        }
        catch (e) {
            setError((e as any).message);
        }
    });

    return <div className='c TestExpression'>
        <h1>{t('Test expression')}</h1>

        <div className='r'>
            <Select
                className='flat'
                value={teUser}
                onChange={e => setTeUser(e.value)}
                options={users}
                optionLabel={'userName'}
                filter={true}
                optionValue={'id'} />
            <Select
                className='flat'
                value={teWork}
                onChange={e => setTeWork(e.value)}
                options={works}
                filter={true}
                optionLabel={'name'}
                optionValue={'id'} />
            <Select
                className='flat'
                value={teJob}
                onChange={e => setTeJob(e.value)}
                options={jobs}
                filter={true}
                optionLabel={'name'}
                optionValue={'id'} />
            <span className='e'></span>
            <button
                disabled={teUser == undefined || teWork == undefined || (containsJob && teJob == undefined)}
                onClick={testExpression}>
                {t('Test')}
            </button>
            {loading.render()}
        </div>
        {error && <Message severity='error' text={error} />}
        {!error && result != undefined && <Message severity='success' text={JSON.stringify(result)} />}
    </div>
}

export function PermissionForm(props: IProps){
    const { t } = useTranslation();
    const loading = useLoading(false);
    const [activePanel, setActivePanel] = React.useState<number>(0);

    const form = useForm<IWorkPermission>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            action: props.data?.action ?? '',
            expression: props.data?.expression ?? '',
            roles: props.data?.roles ?? '',
            policies: props.data?.policies ?? '',
            workId: props.workId,
        },
        validate: validatePermission,
        onSubmit: (data: IWorkPermission) => {
            if (props.onSubmit) {
                props.onSubmit(data);
            }
            return {ok: true};
        },
        onChange: props.onChange,
    });

    const predefinedPermissions =
        commonPermissions.map(p => ({
            id: p,
            name: t(p) + ' (' + p + ')'
        }));

    return <div>
        <form onSubmit={form.handleSubmit} className='c'>
            <div className='r lg pd-top'>
                <div className='e c sm pd g-30'>
                    {form.floatField('action', t('Action'), () =>
                        <Select
                            id='action'
                            className='e'
                            optionLabel='name'
                            optionValue='id'
                            options={predefinedPermissions}
                            editable
                            filter={true}
                            value={form.values.action}
                            onChange={form.handleChange} />)}
                </div>
            </div>
            <div className='c sm pd g-30 w-100'>
                <TabView activeIndex={activePanel} onTabChange={(e) => setActivePanel(e.index)}>
                    <TabPanel header={t('Expression')}>
                        {form.field('expression', t('Expression'), () =>
                            <InputTextarea
                                id='expression'
                                value={form.values.expression}
                                onChange={form.handleChange}
                                autoFocus
                                className={'w-100p ' + classNames({ 'p-invalid': !form.isFormFieldValid('expression') })}/>)}
                        <TestExpression
                            users={props.users}
                            works={props.works}
                            getJobs={props.getJobs}
                            expression={form.values.expression} />
                    </TabPanel>
                    <TabPanel header={t('Role')}>
                        <MultiSelect
                            id='roles'
                            className='w-100p'
                            options={props.availableRoles}
                            value={form.values.roles?.split(',').filter((r: string) => r)??[]}
                            onChange={e => form.setFieldValue('roles', e.value.join(','))}
                            />
                    </TabPanel>
                    <TabPanel header={t('Policy')}>
                        <InputText
                            id='policies'
                            className='w-100p'
                            placeholder={t('Policy')}
                            type='text'
                            value={form.values.policies}
                            onChange={form.handleChange} />
                    </TabPanel>
                </TabView>
            </div>
            <div className='errors-container'>
                {form.errorBox()}
                {props.notifications &&
                    <NotificationsBox notifications={props.notifications} />}
            </div>
            {props.footer != undefined && props.footer}
        </form>
    </div>
}
