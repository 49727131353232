import * as React from 'react';

import { G, ValidationBuilder, useForm, useTranslation } from '@components';
import { IDepartment, IWork, IWorker, IWorkspace } from '@models';

export interface IProps {
    worker?: IWorker;
    departments: IDepartment[];
    onCancel: Function;
    onSaveClicked: Function;
    workspaces: IWorkspace[];
    work: IWork;
    disabled?: boolean;
}

export function WorkerForm(props: IProps) {
    const { t } = useTranslation();
    const form = useForm({
        initialValues: props.worker ?? {
            workId: props.work.id,
            name: '',
            code: '',
            surname: '',
            phone: '',
            isActive: true,
        },
        validateOnMount: true,
        validate: ValidationBuilder.create()
            .notEmpty('name')
            .notEmpty('surname')
            .notEmpty('code')
            .lift()
    });

    return <div className='c'>
        <div className='c sm pd form-1 l200'>
            <G label={t('name')}>
                {form.input('name')}
            </G>
            <G label={t('surname')}>
                {form.input('surname')}
            </G>
            <G label={t('Code')}>
                {form.input('code')}
            </G>
            <G label={t('Phone')}>
                {form.input('phone')}
            </G>
            <G label={t('Departments')}>
                {form.multiselect('departments', props.departments)}
            </G>
            <G label={t('Workspaces')}>
                {form.multiselect('workspaces', props.workspaces)}
            </G>
        </div>

        <div className='r r-end footer'>
            <button onClick={() => props.onCancel()}>{t('Cancel')}</button>
            <button className='primary'
                disabled={form.isInvalid() || props.disabled}
                onClick={() => props.onSaveClicked(form.values)}>
                {t('Save')}
            </button>
        </div>
    </div>;
}
