import { Action, Reducer } from 'redux';
import { ICountry, IDocumentType } from '../models';
import { IRequirementExpirationType, IRequirementTypeKind } from '../models/requirements';
import * as AdminActions from './actions/admin';
import { actionCreators as N } from './notifications';

export interface IConstantsState {
    countries: ICountry[];
    documentTypes: IDocumentType[];
    requirementTypeKinds: IRequirementTypeKind[];
    requirementExpirationTypes: IRequirementExpirationType[];
}

const setcountriestype = '[CONSTANTS] SET COUNTRIES';
const setdocumenttypes = '[CONSTANTS] SET DOCUMENT TYPES';
const initializetype = '[CONSTANTS] INITIALIZE';

export interface SetCountriesAction {
    type: '[CONSTANTS] SET COUNTRIES';
    countries: ICountry[];
}

export interface InitializeAction {
    type: '[CONSTANTS] INITIALIZE';
    value: IConstantsState;
}

export interface SetDocumentTypesAction {
    type: '[CONSTANTS] SET DOCUMENT TYPES';
    documentTypes: IDocumentType[];
}

export type KnownAction = SetDocumentTypesAction | SetCountriesAction | InitializeAction | {type: undefined};

const loadDocumentTypes = async (dispatch: Function) => {
    const resp = await AdminActions.getDocumentTypes();
    const types = resp.data.documentTypes
        .filter((d: IDocumentType) => d.isActive);

    dispatch({
        type: setdocumenttypes,
        documentTypes: types,
    });
}

export const actionCreators = {
    setCountries: (countries: ICountry[]) => (dispatch: Function) => {
        dispatch({
            type: setcountriestype,
            countries,
        });
    },
    loadDocumentTypes: () => (dispatch: Function) => {
        loadDocumentTypes(dispatch);
    },
    saveDocumentType: (dt: IDocumentType) => async (dispatch: Function) => {
        dispatch(N.startLoading({
            ctx: 'admindocumenttypes',
            action: 'save',
        }));
        await AdminActions.saveDocumentType(dt);
        await loadDocumentTypes(dispatch);

        dispatch(N.success({
            ctx: 'admindocumenttypes'
        }));

        dispatch(N.stopLoading({
            ctx: 'admindocumenttypes',
            action: 'save',
        }));
    },
    removeDocumentType: (id: number) => async (dispatch: Function) => {
        dispatch(N.startLoading({
            ctx: 'admindocumenttypes',
            action: 'remove',
        }));
        await AdminActions.removeDocumentType(id);
        await loadDocumentTypes(dispatch);

        dispatch(N.success({
            ctx: 'admindocumenttypes'
        }));

        dispatch(N.stopLoading({
            ctx: 'admindocumenttypes',
            action: 'remove',
        }));
    },
    initialize: (value: IConstantsState) => (dispatch: Function) => {
        dispatch({
            type: initializetype,
            value,
        })
    }
};

export const reducer: Reducer<IConstantsState> = (state: IConstantsState | undefined, incomingAction: Action): IConstantsState => {
    if (state === undefined) {
        return {
            countries: [],
            documentTypes: [],
            requirementExpirationTypes: [],
            requirementTypeKinds: [],
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case setcountriestype:
            return { ...state, countries: action.countries };
        case initializetype:
            return { ...state, ...action.value };
        case setdocumenttypes:
            return { ...state, documentTypes: action.documentTypes };
        default:
            return state;
    }
};
