import { IAuthRequest } from "../../models";
import { q } from './graphql';
import * as HTTP from './utils';

export async function login(auth: IAuthRequest) {
    const resp = await HTTP.POST('/api/users/authenticate', auth);

    if (resp && !resp.error) {
        return {
            ok: true,
            value: await getUser(),
            message: undefined,
        };
    }
    return resp;
}

export function getUser() {
    return q(`
query InitializeUser {
    user {
        id, userName, email, roles, policies,
        departments, workFunctions,
        workerIds, obliqueDepartments, contractorIds
    }
}`, {}, { returnPath: 'user' });
}

export default {
    getUser,
    login,
}
