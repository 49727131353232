import * as React from 'react';

import { TabPanel, useSearch, useTranslation } from '@components';
import useContractorFromAppUser from '@components/custom/useContractorFromAppUser';
import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { IJob, IJobHasContractor, ISecurity } from '@models';
import { RequirementTargetType } from '@models/requirements';

export interface IProps {
    contractors: IJobHasContractor[];
    job: IJob;
    jobId: number;
    security: ISecurity;
    workId: number;
    selectedJobHasContractor?: IJobHasContractor;
}

type IParentRow = {
    parentJobHasContractorId: number,
    parentHierarchyLevel: number,
    parentContractorName: string,
    parentContractorId: number,
}

export function getTabs(props: IProps) {
    const { t } = useTranslation();
    const contractorId = useContractorFromAppUser(props.workId, props.security);

    const [contractor, setContractor] = React.useState<IJobHasContractor | undefined>();

    const parents = useSearch<IParentRow>({
        workId: props.workId,
        search: 'jobs/subcontractors_hierarchy',
        normalizeKeys: true,
        filters: {
            jobId: props.jobId,
            workId: props.workId,
            jobHasContractorId: props.selectedJobHasContractor?.id ?? 0,
        },
    });

    React.useEffect(() => {
        const mc = props.contractors.find(c => c.contractorId == contractorId);
        if (props.selectedJobHasContractor != undefined) {
            setContractor(props.selectedJobHasContractor);
            parents.doSearch();
        }
        else if (mc && props.selectedJobHasContractor == null) {
            setContractor(mc);
        }
    }, [contractorId, props.contractors, props.selectedJobHasContractor]);

    const tabHeader = (p: IParentRow, id: number) => {
        return (opts: any) =>
            <a className='p-tabview-nav-link' onClick={opts.onClick}>
                <span className='p-tabview-title'>
                    <span className='contractor-chip'>{p.parentHierarchyLevel}</span>
                    {p.parentContractorName}
                </span>
            </a>;
    }

    if (parents.value) {
        return parents.value.map((p, id) =>
                <TabPanel headerTemplate={tabHeader(p, id)} key={id}>
                    <EditRequirements
                        key={'mainContractorRequirements'}
                        readOnly={true}
                        job={props.job}
                        targetId={p.parentJobHasContractorId}
                        targetType={RequirementTargetType.JobHasContractor}
                        workId={props.workId}
                        skipMirrorDependencies={true} />
                </TabPanel>);
    }
    else if (contractor) {
        return [<TabPanel headerTemplate={t('requirements.main-contractor')}>
            <EditRequirements
                key={'mainContractorRequirements'}
                readOnly={true}
                job={props.job}
                targetId={contractor.parentId}
                targetType={RequirementTargetType.JobHasContractor}
                workId={props.workId} />
        </TabPanel>];
    }
    else {
        return [];
    }
}

export function JobRequirementsSubContractor(props: IProps) {
    return <>
        {getTabs(props)}
    </>;
}
