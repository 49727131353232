import * as React from 'react';
import { Route } from 'react-router';

import ContractorRequirements from '@containers/contractors/ContractorRequirementsContainer';
import ManageSubContractorRequestsContainer from '@containers/contractors/ManageSubContractorRequestsContainer';

export interface IProps {}

export function ContractorModule(props: IProps) {
    return <>
        <Route
            exact
            path='/work/:workid/contractor/subcontractorrequests'
            component={ManageSubContractorRequestsContainer} />
        <Route
            exact
            path='/work/:workid/contractor/requirements'
            component={ContractorRequirements} />
    </>;
}