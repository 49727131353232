import * as React from 'react';

import { IMachinerySubType } from '@models';
import {
    classNames,
    InputText,
    useForm,
    useTranslation,
    ValidationBuilder,
} from '@components';

import './WorkForm.css';

export interface IProps {
    data?: IMachinerySubType;
    parentId: number;
    onChange?: Function;
    onSubmit: Function;
    workId: number;
}

export const validateMachinerySubType = (data: IMachinerySubType) => {
    return ValidationBuilder.create(data).notEmpty('name').build();
}

export const isValid = (data: IMachinerySubType) => {
    return Object.keys(validateMachinerySubType(data)).length == 0;
}

export function MachinerySubTypeForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<IMachinerySubType>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            parentId: props.parentId,
        },
        validate: validateMachinerySubType,
        onSubmit: (data: IMachinerySubType) => {
            props.onSubmit(data);
            return {ok: true};
        },
        onChange: props.onChange,
    });

    return <div>
        <form onSubmit={form.handleSubmit}
            className='p-fluid'>
            <div className='c md pd g-30'>
                {form.floatField('name', t('Name'), () =>
                    <InputText
                    id='name'
                    type='text'
                    value={form.values.name}
                    onChange={form.handleChange}
                    autoFocus
                    className={classNames({ 'p-invalid': !form.isFormFieldValid('name')})}/>
                )}
            </div>
            <div className='errors-container'>
                {form.errorBox()}
            </div>
            <div className='e' />

            <div className='r'>
                <span className='e' />
                <button
                    className='primary'
                    onClick={() => props.onSubmit}>
                    {t('Add')}
                </button>
            </div>
        </form>
    </div>
}