import * as React from 'react';

import { IWork } from '@models';
import { IRequirement } from '@models/requirements';
import { DocumentViewer } from '@components/viewers/DocumentViewer';

import './ViewRequirementDocument.scss';

type GetRequirementF = (
    workId: number,
    targetType: number,
    targetId: number,
    id: number
) => Promise<IRequirement>;

export interface IProps {
    documentId: number;
    getRequirement: GetRequirementF;
    requirementId: number;
    work: IWork;
    versionId?: number;
}

export function ViewRequirementDocument(props: IProps) {
    const [req, setReq] = React.useState<IRequirement|undefined>();
    const [docUrl, setDocUrl] = React.useState<string|undefined>();
    const [mimeType, setMimeType] = React.useState<string|undefined>();

    const loadRequirement = async () => {
        const req = await props.getRequirement(props.work.id, 0, 0, props.requirementId);
        setReq(req);

        if (req) {
            const documentUrl = props.versionId
                ? `/api/files/${req.workId}/document/${req.documents?.[0].document.id}/version/${props.versionId}`
                : `/api/files/${req.workId}/document/${req.documents?.[0].document.id}`;
            setMimeType(req.documents?.[0].document.mimeType);
            setDocUrl(documentUrl);
        }
    };

    React.useEffect(() => {
        loadRequirement();
    }, []);

    return <div className='ViewRequirementDocument'>
        {docUrl &&
            <DocumentViewer
                mimeType={mimeType}
                url={docUrl} />}
    </div>
}