import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as JobsStore from '@store/jobs';
import * as RequirementsStore from '@store/requirements';

import { CreateRequirement } from '@components/jobs/CreateRequirement';

export default connect(
    (state: ApplicationState) => ({
        ...state.user,
        ...state.identity,
        ...JobsStore.plainActions,
        ...RequirementsStore.plainActions,
        ...state.work.work?.departments,
    }),
)(CreateRequirement as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
