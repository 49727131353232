import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as DocumentsStore from '@store/documents';
import * as RequirementsStore from '@store/requirements';
import { ViewRequirementDocument } from '@components/requirements/ViewRequirementDocument';
import { getQueryParameter } from '@utils';

export default connect(
    (state: ApplicationState, ownProps: any) => ({
        ...state.identity,
        ...state.user,
        ...DocumentsStore.plainActions,
        work: state.user.work,
        requirementId: parseInt(ownProps.match.params.requirementid),
        documentId: parseInt(ownProps.match.params.documentid),
        ...RequirementsStore.plainActions,
        versionId: getQueryParameter(
            window.location.search,
            'versionId',
            { map: parseInt }),
    }),
    {}
)(ViewRequirementDocument as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
