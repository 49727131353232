import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as MachineriesStore from '../../store/machineries';
import { isMachineryPropertyGroupType } from '@models';
import { EditMachinery } from '@components/machineries/EditMachinery';

export default connect(
    (state: ApplicationState, props: any) => ({
        workId: props.workId ?? parseInt(props.match.params.workid),
        machineryId: props.machineryId ?? parseInt(props.match.params.machineryid),
        ...state.identity,
        ...state.user,
        ...MachineriesStore.plainActions,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isMachineryPropertyGroupType),
        resourceStatusTypes: state.user.resourceStatusTypes,
    }),
    MachineriesStore.actionCreators,
)(EditMachinery as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any