import { connect } from 'react-redux';
import { ApplicationState } from '@store';
import * as Admin from '@store/admin';

import { ManageWorkEvents } from '@components/admin/events/ManageWorkEvents';


export default connect(
  (state: ApplicationState) => ({
    ...Admin.plainActions
  }),
  Admin.actionCreators,
)(ManageWorkEvents as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
