import * as React from 'react';

import { ISecurity, IWork, IUserIdentity, ModuleManager, ICommunication } from '@models';
import './Work.scss';
import NotificationsCenter from '@containers/notifications/NotificationsCenterContainer';
import { Card, ContextMenu, useTranslation } from '@components';
import { redirectTo } from '@utils';

import { getReports } from '@store/actions/reports';
import { IReportDefinition } from "@models/reports";
import { storageClear } from '@utils/storage';

export interface IProps {
    work: IWork;
    selectJobs: Function;
    security: ISecurity;
    user: IUserIdentity;
    moduleManager: ModuleManager;
    rm?: any;
    cm?: any;
    communications: ICommunication[];
}

export function Work(props: IProps) {
    const { t } = useTranslation();
    const [reportItems, setReportItems] = React.useState<any[]>([]);

    React.useEffect(() => {
        storageClear(k => k.startsWith('filters'));
    }, []);

    const fillReportItems = (items: IReportDefinition[]) => {
        setReportItems(items
            .filter(r => !r.hidden)
            .map(r => {
                return {
                    label: t(r.title),
                    icon: r.icon,
                    command: () => redirectTo(`/work/${props.work.id}/reports/${r.name}`),
                };
            }));
    }

    React.useEffect(() => {
        if (props.work.id) {
            getReports(props.work.id).then(fillReportItems);
        }
    }, [props.work.id]);

    React.useEffect(() => {
        // no redirigir en caso de que ya estemos en communications
        if (props.communications?.length > 0 && !window.location.pathname.includes('communications/current')) {
            // redirigir a communications current
            redirectTo('/work/' + props.work?.id + '/communications/current');
        }
    }, [props.communications, window.location.pathname]);


    const cm = React.useRef<any>();
    const rm = React.useRef<any>();

    const accessControlMenu = [
        { label: t('Machinery'), command: () => redirectTo(`/work/${props.work.id}/access-control/machineries`) },
        { label: t('Workers'), command: () => redirectTo(`/work/${props.work.id}/access-control/workers`) },
    ];

    return <div className='r h-center g-20 he'>
        <ContextMenu model={accessControlMenu} ref={cm} />
        <ContextMenu model={reportItems} ref={rm} />
        <NotificationsCenter className='e' />
        <div className='e c WorkContainer'>
            {props.security.isContractor() && <div className='actions-title'>
                <span>{t('screen.contractors')}</span>
                <img src="/img/screen/title.png" alt="gstore" />
            </div>}
            {props.security.isWorker() && <div className='actions-title'>
                <span>{t('screen.workers')}</span>
                <img src="/img/screen/title.png" alt="gstore" />
            </div>}
            {props.security.isGestor() && <div className='actions-title'>
                <span>{t('screen.gestor')}</span>
                <img src="/img/screen/title.png" alt="gstore" />
            </div>}
            <WorkMenu {...props} />
        </div>
        <WorkUtils {...{ ...props, cm, rm }} />
    </div>
}

function WorkUtilsImpl(props: IProps) {
    const { t } = useTranslation();
    const cm = React.useRef<any>();
    const rm = React.useRef<any>();

    const [reportItems, setReportItems] = React.useState<any[]>([]);

    React.useEffect(() => {
        storageClear(k => k.startsWith('filters'));
    }, []);

    const fillReportItems = (items: IReportDefinition[]) => {
        setReportItems(items
            .filter(r => !r.hidden)
            .map(r => {
                return {
                    label: t(r.title),
                    icon: r.icon,
                    command: () => redirectTo(`/work/${props.work.id}/reports/${r.name}`),
                };
            }));
    }

    React.useEffect(() => {
        if (props.work.id) {
            getReports(props.work.id).then(fillReportItems);
        }
    }, [props.work.id]);

    const accessControlMenu = [
        { label: t('Machinery'), command: () => redirectTo(`/work/${props.work.id}/access-control/machineries`) },
        { label: t('Workers'), command: () => redirectTo(`/work/${props.work.id}/access-control/workers`) },
    ];

    return (
        <>
            <ContextMenu model={accessControlMenu} ref={cm} />
            <ContextMenu model={reportItems} ref={rm} />
            {(props.security.isGestorOrWorker() || props.security.isContractor()) ? <>

                <div className='WorkContainer' style={{ backgroundColor: 'white' }}>
                    <div className='actions-title'>
                        <img src="/img/screen/utilities.png" className='icon' alt="gstore" />
                        <span style={{ paddingLeft: '5%' }}>{t('UTILITIES')}</span>
                    </div>
                    <div className='WorkContainer'>
                        <div className='c g-50 Work e'>
                            <div onClick={ev => cm.current.show(ev)}>
                                <Card className='card-access-control pointer'>
                                    <img src="img/screen/access_control.png" alt="" />
                                    <div className='title'>
                                        <span className='name'>{t('screen.access.control')}</span>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div className='c g-50 Work e'>
                            {/* <div onClick={() => redirectTo(`/work/${props.work.id}/reports`)}> */}
                            <div onClick={ev => rm.current.show(ev)}>
                                <Card className='card-reports pointer'>
                                    <img className="icon" src="img/screen/reports.png" alt="" />
                                    <div className='title'>
                                        <span className='name'>{t('Reports')}</span>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div className='c g-50 Work e'>
                            <div onClick={() => redirectTo(`/work/${props.work.id}/documents`)}>
                                <Card className='card-download-area pointer'>
                                    <img className="icon" src="img/screen/download_area.png" alt="" />
                                    <div className='title'>
                                        <span className='name'>{t('Download area')}</span>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </>
                : <></>}
        </>);
}

function WorkUtils(props: IProps) {
    return props
        .moduleManager
        .renderComponent<IProps>('WorkUtils', { ...props, getReports, storageClear }, WorkUtilsImpl);
}

function WorkMenuImpl(props: IProps) {
    const { t } = useTranslation();

    return (
        props.security.isGestorOrWorker() ? <>
            <div>
                <div className='c g-50 Work e'>
                    <div onClick={() => redirectTo(`/work/${props.work.id}/jobs`)}>
                        <Card className='card-job pointer'>
                            <img className="icon" src="img/screen/jobs.png" alt="" />
                            <div className='title'>
                                <span className='name'>{t('Jobs')}</span>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className='c g-50 Work e' style={{ margin: "5px 0px 0px 0px" }}>
                    <div onClick={() => redirectTo(`/work/${props.work.id}/contractors`)}>
                        <Card className='card-contractors pointer'>
                            <img className="icon" src="img/screen/contractors.png" alt="" />
                            <div className='title'>
                                <span className='name'>{t('Contractors')}</span>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card className='card-work'>
                            <div className='client-img'>
                                <img src={props.work.settingsObj?.screenImage ?? '/img/screen/soltec.png'} alt='gstore' />
                            </div>
                        </Card>
                    </div>
                    <div onClick={() => redirectTo(`/work/${props.work.id}/subcontractorrequests`)}>
                        <Card className='card-subc pointer'>
                            <img className="icon" src="img/screen/sub_req.png" alt="" />
                            <div className='title'>
                                <span className='name'>{t('Subcontractors request')}</span>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className='c g-50 Work e' style={{ margin: "5px 0px 0px 0px" }}>
                    <div onClick={() => redirectTo(`/work/${props.work.id}/workers`)}>
                        <Card className='card-workers pointer'>
                            <img className="icon" src="img/screen/workers.png" alt="" />
                            <div className='title'>
                                <span className='name'>{t('Workers')}</span>
                            </div>
                        </Card>
                    </div>
                    <div onClick={() => redirectTo(`/work/${props.work.id}/machineries`)}>
                        <Card className='card-machinery pointer'>
                            <img className="icon" src="img/screen/machinery.png" alt="" />
                            <div className='title'>
                                <span className='name'>{t('Machineries')}</span>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
            :
            <>
                <div>
                    <div className='c g-50 Work e'>
                        <div onClick={() => redirectTo(`/work/${props.work.id}/contractor/requirements`)}>
                            <Card className='card-documents pointer'>
                                <img style={{ marginLeft: "6px" }} src="img/screen/documents.png" alt="" />
                                <div className='title'>
                                    <span className='name'>{t('Documents')}</span>
                                </div>
                            </Card>
                        </div>
                        <div onClick={() => redirectTo(`/work/${props.work.id}/jobs`)}>
                            <Card className='card-job-contractors pointer'>
                                <img className="icon" src="img/screen/jobs.png" alt="" />
                                <div className='title'>
                                    <span className='name'>{t('Jobs')}</span>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className='c g-50 Work e' style={{ margin: "5px 0px 0px 0px" }}>
                        <div onClick={() => redirectTo(`/work/${props.work.id}/subcontractors`)}>
                            <Card className='card-contractors pointer'>
                                <img className="icon" src="img/screen/contractors.png" alt="" />
                                <div className='title'>
                                    <span className='name'>{t('Subcontractors')}</span>
                                </div>
                            </Card>
                        </div>
                        <div>
                            <Card className='card-work'>
                                <div className='client-img'>
                                    <img src={props.work.settingsObj?.screenImage ?? '/img/screen/soltec.png'} alt='gstore' />
                                </div>
                            </Card>
                        </div>
                        <div onClick={() => redirectTo(`/work/${props.work.id}/contractor/subcontractorrequests`)}>
                            <Card className='card-subc pointer'>
                                <img className="icon" src="img/screen/sub_req.png" alt="" />
                                <div className='title'>
                                    <span className='name'>{t('Subcontractors request')}</span>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className='c g-50 Work e' style={{ margin: "5px 0px 0px 0px" }}>
                        <div onClick={() => redirectTo(`/work/${props.work.id}/workers`)}>
                            <Card className='card-workers pointer'>
                                <img className="icon" src="img/screen/workers.png" alt="" />
                                <div className='title'>
                                    <span className='name'>{t('Workers')}</span>
                                </div>
                            </Card>
                        </div>
                        <div onClick={() => redirectTo(`/work/${props.work.id}/machineries`)}>
                            <Card className='card-machinery pointer'>
                                <img className="icon" src="img/screen/machinery.png" alt="" />
                                <div className='title'>
                                    <span className='name'>{t('Machineries')}</span>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </>
    )
}

function WorkMenu(props: IProps) {
    return props
        .moduleManager
        .renderComponent<IProps>('WorkMenu', props, WorkMenuImpl);
}
