import * as React from 'react';
import { Route } from 'react-router';

import { userContainer } from '@containers/index';
import { Machineries } from './accessControl/Machineries';
import { Workers } from './accessControl/Workers';

export interface IProps {
    children?: any;
}

export function WorkAccessControlRoutes(props: IProps) {
    return <>
        <Route
            path='/work/:workid/access-control/machineries'
            component={userContainer(Machineries)} />

        <Route
            path='/work/:workid/access-control/workers'
            component={userContainer(Workers)} />
    </>;
}