import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';
import * as App from '../../store/app';

import { AdminLoader, IProps } from '../../components/admin/AdminLoader';

type Props =
    Admin.IAdminState &
    App.IAppState &
    IProps;

export default connect(
  (state: ApplicationState) => ({
      users: state.admin.users,
      loading: state.app.loading,
      loadingMessage: state.app.loadingMessage
  }),
  Admin.actionCreators,
)(AdminLoader as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
