import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Route } from 'react-router';

import Layout from '@components/Layout';
import Home from '@containers/HomeContainer';
import AdminDocumentTypesContainer from '@containers/admin/AdminDocumentTypesContainer';
import AdminUsers from '@containers/admin/AdminUsersContainer';
import AdminLoader from '@containers/admin/AdminLoaderContainer';
import AdminRoutesWrapper from './components/admin/AdminRoutesWrapper';
import AdminWorkLoader from '@containers/admin/AdminWorkLoader';
import EditWorkContainer from '@containers/admin/EditWorkContainer';
import AdminWorks from '@containers/admin/AdminWorksContainer';
import AdminDevelGraphqlUi from '@components/admin/devel/GraphQlUi';
import AdminWorkDepartments from '@containers/admin/AdminWorkDepartmentsContainer';
import AdminWorkPermissions from '@containers/admin/AdminWorkPermissionsContainer';
import AdminWorkWorkers from '@containers/admin/AdminWorkWorkersContainer';
import AdminWorkConfiguration from '@containers/admin/AdminWorkConfigurationContainer';
import RegisterContractorContainer from '@containers/contractors/RegisterContractorContainer';
import ResetPasswordContainer from '@containers/identity/ResetPasswordContainer';
import ChangePasswordContainer from '@containers/user/ChangePasswordContainer';
import UserConfigurationContainer from '@containers/user/UserConfigurationContainer';
import AdminWorkRequirementsContainer from '@containers/admin/AdminWorkRequirementsContainer';
import AdminWorkNotifications from '@containers/admin/AdminWorkNotificationsContainer';
import AdminWorkValidations from '@containers/admin/AdminWorkValidationsContainer';
import AdminWorkContractorsSettings from '@containers/admin/contractors/ManageContractorSettingsContainer';
import AdminWorkMachineriesSettings from '@containers/admin/machineries/ManageMachinerySettingsContainer';
import AdminWorkWorkersSettings from '@containers/admin/workers/ManageWorkerSettingsContainer';
import AdminWorkJobsSettings from '@containers/admin/jobs/ManageJobSettingsContainer';
import AdminWorkModules from '@containers/admin/modules/ManageWorkModulesContainer';
import AdminWorkEvents from '@containers/admin/events/ManageWorkEventsContainer';
import ModuleViewContainer from '@containers/modules/ModuleViewContainer';
import ModuleViewRedirectionContainer from '@containers/modules/ModuleViewRedirectionContainer';
import WorkLoader from '@containers/user/WorkLoaderContainer';
import UserProfileContainer from '@containers/UserProfileContainer';

const ModuleViewWrapper = (props: any) => {
    return <WorkLoader {...props}>
        <ModuleViewContainer {...props} />
    </WorkLoader>
}

const UserProfileWrapper = (props: any) => {
    return <WorkLoader {...props}>
        <UserProfileContainer {...props} />
    </WorkLoader>
}

const AdminWrapper = (props: any) => {
    return <AdminLoader {...props}>
        {props.children}
    </AdminLoader>
}

const AdminDocumentTypesWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminDocumentTypesContainer />
</AdminWrapper>

const AdminUsersWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminUsers />
</AdminWrapper>

const AdminWorkWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <EditWorkContainer {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorksWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorks />
</AdminWrapper>

const AdminWorkPermissionsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkPermissions {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkRequirementsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkRequirementsContainer {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkConfigurationWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkConfiguration {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkDepartmentsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkDepartments {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkNotificationsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkNotifications {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkValidationsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkValidations {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkUsersWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkWorkers {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkContractorsSettingsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkContractorsSettings {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkMachineriesSettingsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkMachineriesSettings {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkWorkersSettingsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkWorkersSettings {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkJobSettingsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkJobsSettings {...props} />
    </AdminWorkLoader>
</AdminWrapper>;

const AdminWorkModulesWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkModules {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminWorkEventsWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminWorkLoader {...props}>
        <AdminWorkEvents {...props} />
    </AdminWorkLoader>
</AdminWrapper>

const AdminDevelVoyagerWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminDevelGraphqlUi src='voyager' />
</AdminWrapper>

const AdminDevelGraphqlUiWrapper = (props: any) => <AdminWrapper {...props}>
    <AdminDevelGraphqlUi src='graphiql' />
</AdminWrapper>

const AdminRoutes = (props: any) => {
    return <AdminRoutesWrapper {...props}>
        <Route exact path='/admin/users' component={AdminUsersWrapper} />
        <Route exact path='/admin/configuration/documentTypes' component={AdminDocumentTypesWrapper} />
        <Route exact path='/admin/works' component={AdminWorksWrapper} />
        <Route exact path='/admin/work/:id' component={AdminWorkWrapper} />
        <Route exact path='/admin/work/:id/permissions' component={AdminWorkPermissionsWrapper} />
        <Route exact path='/admin/work/:id/configuration' component={AdminWorkConfigurationWrapper} />
        <Route exact path='/admin/work/:id/requirements' component={AdminWorkRequirementsWrapper} />
        <Route exact path='/admin/work/:id/departments' component={AdminWorkDepartmentsWrapper} />
        <Route exact path='/admin/work/:id/notifications' component={AdminWorkNotificationsWrapper} />
        <Route exact path='/admin/work/:id/validations' component={AdminWorkValidationsWrapper} />
        <Route exact path='/admin/work/:id/users' component={AdminWorkUsersWrapper} />
        <Route exact path='/admin/work/:id/contractors' component={AdminWorkContractorsSettingsWrapper} />
        <Route exact path='/admin/work/:id/machineries' component={AdminWorkMachineriesSettingsWrapper} />
        <Route exact path='/admin/work/:id/workers' component={AdminWorkWorkersSettingsWrapper} />
        <Route exact path='/admin/work/:id/jobs' component={AdminWorkJobSettingsWrapper} />
        <Route exact path='/admin/work/:id/modules' component={AdminWorkModulesWrapper} />
        <Route exact path='/admin/work/:id/events' component={AdminWorkEventsWrapper} />
        <Route exact path='/admin/devel/graphqlui' component={AdminDevelGraphqlUiWrapper} />
        <Route exact path='/admin/devel/schema' component={AdminDevelVoyagerWrapper} />
    </AdminRoutesWrapper>
}

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/admin' component={AdminRoutes} />
        <Route path='/work' component={Home} />
        <Route path='/contractors/register/:token' component={RegisterContractorContainer} />
        <Route path='/user/configuration' component={UserConfigurationContainer} />
        <Route path='/user/password' component={ChangePasswordContainer} />
        <Route path="/user/:workid/profile" component={UserProfileWrapper} />
        <Route path='/identity/recover/:token' component={ResetPasswordContainer} />
        <Route path='/modules/:module/:view' exact component={ModuleViewRedirectionContainer} />
        <Route path='/modules/:workid/:module/:view' component={ModuleViewWrapper} />
        {/* <Route path='/fetch-data/:startDateIndex?' component={FetchData} /> */}
    </Layout>
);

(window as any).React = React;
(window as any).ReactDOM = ReactDOM;
