import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import * as JobsStore from '@store/jobs';
import { JobAddWorker } from '@components/jobs/JobAddWorker';
import { isWorkerPropertyGroupType } from '@models';
import * as U from '@utils';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        propertyGroupTypes: state.user.work?.propertyGroupTypes?.filter(isWorkerPropertyGroupType),
        contractorId: state.jobs.selectedJobHasContractor?.contractorId,
        jobHasContractorId: U.getQueryParameter(
            window.location.search,
            'jobContractor', { map: parseInt }
        ) ?? state.jobs.selectedJobHasContractor?.id,
        ...state.identity?.security,
        ...state.identity,
        ...state.jobs,
        moduleManager: state.modules.moduleManager,
    }),
    JobsStore.actionCreators,
)(JobAddWorker as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
