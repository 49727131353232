import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as RequirementsStore from '../../store/requirements';
import { ForceValidationResource } from '../../components/requirements/ForceValidationResource';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...RequirementsStore.plainActions
    }),
    {},
)(ForceValidationResource as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any