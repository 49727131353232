import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

import * as UsersStore from '../../store/user';
import Module from '../../components/user/Module';

export default connect(
    (state: ApplicationState) => ({
        communications: state.user.communications,
        selectedJob: state.jobs?.selectedJob,
        work: state.user.work,
        version: state.user.version,
        loading: state.app.loading,
        loadingMessage: state.app.loadingMessage
    }),
    UsersStore.actionCreators,
)(Module as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
