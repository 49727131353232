import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '../../store/admin';

import { IWork } from '../../models';
import { Loading } from '../../components';

const Loader = (props: any) => {
    const [work, setWork] = React.useState<IWork|undefined>();
    const [loading, setLoading] = React.useState<boolean>(true);

    const loadWork = () => {
        const id = props.match.params.id;
        const w = props.works.find((x: IWork) => x.id == id);
        if (w) {
            setWork(w);
            setLoading(false);
        }
        else {
            props.loadWorks();
            props.selectWork(parseInt(id));
        }
    }

    React.useEffect(() => {
        if (!work || work.id != props.id) {
            loadWork();
        }
    }, [props.workId, props.works]);

    if (loading) {
        return <Loading />;
    }
    else {
        return React.cloneElement(props.children, { work: work });
    }
}

export default connect(
    (state: ApplicationState) => ({
        works: state.admin.works,
    }),
    Admin.actionCreators,
)(Loader as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
