import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '@store/contractors';
import * as AdminStore from '@store/actions/admin';
import { RegisterContractor } from '@components/contractors/RegisterContractor';

export default connect(
    (_: ApplicationState, props: any) => ({
        getContractorInvitation: ContractorsStore.plainActions.getContractorInvitation,
        getWorkConstants: ContractorsStore.plainActions.getWorkConstants,
        registerContractorInvitation: ContractorsStore.plainActions.registerContractorInvitation,
        token: props.match.params.token,
        getWork: AdminStore.getWork,
    }),
    {}
)(RegisterContractor as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
