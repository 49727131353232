import * as React from 'react';

import { useForm, useLoading, useToast, useTranslation, F, ValidationBuilder } from '@components';
import { CountryRegionSelect } from '@components/common/CountryRegionSelect';
import useContractorFromAppUser from '@components/custom/useContractorFromAppUser';
import { DynamicProperties } from '@components/user/DynamicProperties';
import { IContractor, IConstants, IOption, IPropertyGroupType, ISecurity, IUserIdentity, PropertyGroupObjectType, ModuleManager } from '@models';
import { goBack } from '@utils';

import './UserProfile.scss';

type SaveContractorT = (workId: number, data: IContractor) => Promise<IOption<boolean>>;

export interface IProps {
    constants: IConstants;
    getContractorFromAppUser: Function;
    propertyGroupTypes: IPropertyGroupType[];
    saveContractor: SaveContractorT;
    security: ISecurity;
    user: IUserIdentity;
    workId: number;
    moduleManager: ModuleManager;
}

function ContractorUserProfileImpl(props: IProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const loading = useLoading(true);

    const contractorId = useContractorFromAppUser(props.workId, props.security);
    const [contractor, setContractor] = React.useState<IContractor | undefined>();

    const form = useForm<Partial<IContractor>>({
        initialValues: {},
        validate: ValidationBuilder
            .create()
            .notEmpty('name')
            .notEmpty('code')
            .lift(),
    });

    const initialize = loading.wrap(async (workId: number) => {
        const data = await props.getContractorFromAppUser(workId);

        setContractor(data);
        form.setFieldValues(data);
    });

    React.useEffect(() => {
        initialize(props.workId);
    }, [props.workId]);

    const updateRegion = ({ countryId, regionId, countyId }: any) => {
        form.setFieldValue('countryId', countryId);
        form.setFieldValue('regionId', regionId);
        form.setFieldValue('countyId', countyId);
    }

    const doSave = loading.wrap(async () => {
        const res = await props.saveContractor(props.workId, form.values);

        if (res.hasValue && res.value) {
            toast.show(t('data.success'));
        }
        else {
            toast.error(res.error ?? t('data.error'));
        }

        await initialize(props.workId);
    });

    return (<div>
        {loading.renderBox()}

        {toast.render()}

        {!loading.isLoading() && <div className='profile-container'>
            <div className="header">
                {contractor?.name}
            </div>
            <div className="data-container">
                <div className='data'>
                    <F label={t('name')}>
                        {form.input('name', { autoFocus: true })}
                    </F>
                    <F label={t('Email')}>
                        <input type='text' readOnly value={props.user?.email} />
                    </F>
                    <F label={t('contractor.code')}>
                        {form.input('code')}
                    </F>
                    <F label={t('Phone')}>
                        {form.input('phone')}
                    </F>
                    <F label={t('Remarks')}>
                        {form.textarea('remarks', { rows: 8 })}
                    </F>
                </div>
                <div className="data">
                    <F label={t('Address')}>
                        {form.input('address')}
                    </F>
                    <F label={t('Region')}>
                        <CountryRegionSelect
                            countries={props.constants.countries}
                            data={form.values}
                            onChange={updateRegion} />
                    </F>

                    <F label={t('PostalCode')}>
                        {form.input('postalCode')}
                    </F>

                    {contractor &&
                        <div id={'embed-properties'}>
                            <DynamicProperties
                                className='e sm mr-left'
                                inline
                                object={contractor}
                                onChange={v => form.setFieldValue('properties', v)}
                                embedPortal={'#embed-properties'}
                                objectType={PropertyGroupObjectType.JobHasContractor}
                                propertyGroupTypes={props.propertyGroupTypes} />
                        </div>}

                </div>
            </div>
            <div className="r footer-actions">
                <button onClick={_ => goBack()}>{t('Cancel')}</button>
                <span className="e"></span>
                <button
                    className="primary"
                    disabled={loading.isLoading() || form.isInvalid()}
                    onClick={_ => doSave()}>{t('Save')}</button>
            </div>
        </div>}
    </div>);
}


export default function ContractorUserProfile(props: IProps) {

    return props
        .moduleManager
        .renderComponent<IProps>(
            'ContractorUserProfile',
            { ...props },
            ContractorUserProfileImpl,
            true);
}