import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as RequirementsStore from '@store/requirements';
import { ViewRequirementHistory } from '@components/requirements/ViewRequirementHistory';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...RequirementsStore.plainActions,
        appUsers: state.user.work?.users,
        requirementGroups: state.user.requirementGroups,
        requirementStatusTypes: state.user.requirementStatusTypes,
    }),
    {},
)(ViewRequirementHistory as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
