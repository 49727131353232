import * as React from 'react';

import {
    Portal,
    useDialogs,
    useLoading, useDataTable, useSearch, useTranslation,
} from '@components';
import { IDepartment, IWork, IWorker, IWorkerWithRelations, IWorkspace } from '@models';
import ManageWorkerAppUser from '@containers/users/ManageWorkerAppUserContainer';
import { WorkerForm } from './WorkerForm';

export interface IProps {
    departments: IDepartment[];
    saveWorker: Function;
    workspaces: IWorkspace[];
    work: IWork;
}

type Row = {
    id: number;
    name: string;
    surname: string;
    remarks?: string;
    code: string;
    phone?: string;
    departmentNames: string;
    workspaceNames: string;
    userEmail?: string;
    appUser?: number;
}

export function WorkUsers(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();

    const dialogs = useDialogs();

    const data = useSearch<Row>({
        workId: props.work.id,
        search: 'gestor/workUsers',
        normalizeKeys: true,
        filters: {
            workId: props.work.id,
        }
    });

    const dataTable = useDataTable({
        columns: [
            { title: t('Name'), render: d => d.name },
            { title: t('Surname'), field: 'surname' },
            { title: t('Phone'), field: 'phone' },
            { title: t('Code'), field: 'code' },
            { title: t('Workspaces'), field: 'workspaceNames' },
            { title: t('Departments'), field: 'departmentNames' },
            { title: t('UserName'), field: 'userEmail' }
        ],
        actions: [
            { icon: 'pi pi-pencil', onClick: r => dialogs.show('edit', r) },
            { icon: 'pi pi-lock', onClick: r => dialogs.show('app-user', r) },
        ],
        data: data.value,
        loading: data.loading,
    });

    const doSave = loading.wrap(async (worker: IWorkerWithRelations) => {
        const input = {
            id: worker.id,
            name: worker.name,
            code: worker.code,
            surname: worker.surname,
            departments: worker.departments,
            workspaces: worker.workspaces,
            phone: worker.phone,
            workId: worker.workId,
            isActive: worker.isActive,
        };
        const res = await props.saveWorker(input);

        data.doSearch();
        dialogs.clear();
    });

    const refresh = () => {
        dialogs.clear();
        data.doSearch();
    }

    return <div className='c he'>
        <Portal container='#breadcrumb-right'>
            <button onClick={() => dialogs.show('add')}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add user')}
            </button>
        </Portal>

        {loading.render()}

        {dialogs.render('add', {title: t('rrhh.worker.add')}, () =>
            <WorkerForm departments={props.departments}
                        onCancel={dialogs.clear}
                        onSaveClicked={doSave}
                        disabled={loading.loading}
                        workspaces={props.workspaces}
                        work={props.work} />)}

        {dialogs.render('edit', {title: t('rrhh.worker.edit')}, (r: IWorkerWithRelations) =>
            <WorkerForm departments={props.departments}
                        onCancel={dialogs.clear}
                        onSaveClicked={doSave}
                        disabled={loading.loading}
                        worker={r}
                        workspaces={props.workspaces}
                        work={props.work} />)}

        {dialogs.render('app-user', {title: t('rrhh.worker.manageappuser')}, (r: any) =>
            <ManageWorkerAppUser worker={r}
                        work={props.work}
                        onCancel={dialogs.clear}
                        onSuccess={refresh} />)}
        {dataTable()}
    </div>
}
