import * as React from 'react';

import { BreadcrumbItem, G, ValidationBuilder, useForm, useLoading, useTranslation, useMessage, } from '@components';
import { IOption, ISecurity } from '@models';
import { IRequirementFulfillData, IRequirementFulfillDocument, RequirementTargetType } from "@models/requirements";
import { goToJobEditContractorRequirements, goToJobEditMachineryRequirements, goToJobEditWorkerRequirements, goToJobMachineries, goToJobRequirements } from '../routes';
import { formatFileSize } from '@utils';

import './CreateRequirement.scss';

type CreateT = (
    workId: number,
    jobId: number,
    data: IRequirementFulfillData,
    resourceType: number | undefined,
    resourceId: number | undefined,
    statusType: number | undefined) => Promise<IOption<number>>;

type CreateData = {
    title: string,
    remarks?: string,
    jobId: number,
    workId: number,
}

export interface IProps {
    createFreeRequirement: CreateT;
    jobId: number;
    security: ISecurity;
    workId: number;

    resourceType?: number;
    resourceId?: number;
    statusType?: number;
}

export function UploadRequirement(props: IProps) {
    const loading = useLoading();
    const msg = useMessage();
    const { t } = useTranslation();

    const [data, setData] = React.useState<IRequirementFulfillDocument | undefined>();

    const form = useForm<CreateData>({
        initialValues: {
            jobId: props.jobId,
            title: '',
            remarks: '',
            workId: props.workId,
        },
        validateOnMount: true,
        validate: ValidationBuilder.new().notEmpty('title').lift(),
    });

    const cancel = () => goToJobRequirements(props.workId, props.jobId);

    const fileToData = (f: any) => {
        const doc: IRequirementFulfillDocument = {
            resourceId: f,
            name: f.name,
            fileSize: f.size,
            mimeType: f.type,
        }
        setData(doc);
    }

    const onSelectFile = (e: any) => {
        const files = e.target.files;
        if (files.length > 0) {
            const file = files[files.length - 1];

            fileToData(file);
        }
    }

    const onDrop = (e: any) => {
        e.persist();
        e.preventDefault();
        e.stopPropagation();

        const dt = e.dataTransfer;

        if (dt.files.length > 0) {
            const f = dt.files[0];
            fileToData(f);
        }

        return false;
    }

    const allowDrop = (e: any) => {
        e.preventDefault();
    }

    const createRequirement = loading.wrap(async () => {
        const res = await props.createFreeRequirement(
            props.workId,
            props.jobId,
            {
                title: form.values.title,
                remarks: form.values.remarks,
                requirementId: 0,
                workId: props.workId,
                documents: data ? [data] : [],
            },
            props.resourceType,
            props.resourceId,
            props.statusType
        );
        msg.set(res);

        if (props.resourceType == RequirementTargetType.JobHasContractor) {
            goToJobEditContractorRequirements(props.workId, props.jobId, props.resourceId!);
        }
        else if (props.resourceType == RequirementTargetType.JobHasWorker) {
            goToJobEditWorkerRequirements(props.workId, props.jobId, props.resourceId!);
        }
        else if (props.resourceType == RequirementTargetType.JobHasMachinery) {
            goToJobEditMachineryRequirements(props.workId, props.jobId, props.resourceId!);
        }
        else if (res.hasValue) {
            goToJobRequirements(props.workId, props.jobId);
        }
    });

    const canCreateRequirement =
        form.isValid() && (data != undefined || props.statusType != undefined);

    return <div className={'CreateRequirement c we he'}>
        <BreadcrumbItem text={t('Create job requirement')} />

        <div className={'c e'}>
            <div className='md pd e r g-20'>
                <div className='c g-20 e form-1 l200'>
                    <G label={t('Title')}>
                        {form.input('title', { autoFocus: true })}
                    </G>
                    {/** #1145: PESSMA ocultar notas al crear requerimientos libres

                    <G label={t('Remarks')}>
                        {form.textarea('remarks')}
                        </G> **/}
                </div>

                <div className={'files-area'} onDrop={onDrop} onDragOver={allowDrop}>
                    {t('Drop or select files here')}
                    <i className={'fas fa-file-upload'} />

                    <input type={'file'} onChange={onSelectFile} />
                </div>
            </div>

            {data != undefined && <div className={'selected-file c center g-20 box'}>
                <div className={'r'}>
                    <label>{t('Selected file')}</label>
                    <span className={'value'}>{data.name}</span>
                </div>

                <div className={'r'}>
                    <label>{t('File size')}</label>
                    <span className={'value'}>{formatFileSize(data.fileSize)}</span>
                </div>
            </div>}
        </div>

        {msg.renderIfError()}

        <div className={'r r-end sm pd'}>
            {loading.render()}
            <div className='p-buttonset'>
                <button onClick={cancel}>{t('Cancel')}</button>
                <button className={'primary'}
                    disabled={!canCreateRequirement}
                    onClick={createRequirement}>{t('Create')}</button>
            </div>
        </div>
    </div>
}
