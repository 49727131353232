import { m } from './graphql';
import { IChangePasswordData, IRecoverPasswordData } from '@models/forms';

export async function changePassword(data: IChangePasswordData) {
    return m(`mutation ChangePassword($data: ChangePasswordDataGraphInputType!) {
        changePassword(data: $data) {
            hasValue, value, isError, error
        }
    }`, { data }, { returnPath: 'changePassword' });
}

export async function changeUserPassword(data: IChangePasswordData) {
    return m(`mutation ChangeUserPassword($data: ChangePasswordDataGraphInputType!) {
        changeUserPassword(data: $data) {
            hasValue, value, isError, error
        }
    }`, { data }, { returnPath: 'changeUserPassword' });
}

export async function recoverPassword(data: IRecoverPasswordData) {
    return m(`mutation RecoverPassword($data: RecoverPasswordDataGraphInputType!) {
        recoverPassword(data: $data) {
            hasValue, value, isError, error
        }
    }`, { data }, { returnPath: 'recoverPassword' });
}