import * as React from 'react';

import {
    useDataTable, useLoading, useTranslation, useConfirm, useFormDialog
} from '@components';
import { PermissionForm } from './PermissionForm';
import { IDepartment, IRole, IUserIdentity, IWork, IWorkPermission } from '@models';
import { WorkToolbar } from './WorkToolbar';

export interface IProps {
    availableRoles: IRole[];
    loadWorkPermissions: Function;
    removeWorkPermission: Function,
    saveWorkPermission: Function;
    users: IUserIdentity[];
    work: IWork;
    works: IWork[];
    departments: IDepartment[];
    getJobs: Function;
}

export function AdminWorkPermissions({
    availableRoles,
    loadWorkPermissions, removeWorkPermission, saveWorkPermission, users, work, works, getJobs
}: IProps) {
    const loading = useLoading(true);
    const { t } = useTranslation();
    const dialog = useFormDialog({
        addTitle: t('Add permission'),
        editTitle: t('Edit permission')
    });
    const [permissions, setPermissions] = React.useState<IWorkPermission[]>([]);
    const confirm = useConfirm({
        message: t('Are you sure to delete the permission ?'),
    });

    const initialize = loading.wrap(async () => {
        const perms = await loadWorkPermissions(work.id);
        setPermissions(perms);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const renderPermissionTitle = (p: IWorkPermission) => {
        return `${t(p.action)} (${p.action})`;
    }

    const table = useDataTable({
        actions: [
            {tooltip: t('Edit'), icon: 'pencil', onClick: dialog.showEdit},
            {tooltip: t('Remove'), icon: 'trash', onClick: (p: IWorkPermission, e: any) =>
                confirm({event: e, accept: () => performRemovePermission(p)})}],
        columns: [{render: renderPermissionTitle}, 'roles', 'policies', 'expression'],
        data: permissions,
    });

    const performSavePermission = async (perm: IWorkPermission) => {
        await saveWorkPermission(perm);
        await initialize();
        dialog.clear();
    }

    const performRemovePermission = async (p: IWorkPermission) => {
        await removeWorkPermission(work.id, p.id);
        await initialize();
    }

    return <div className='h100p c he sc'>
        <WorkToolbar work={work}>
            <button
                className='tool-btn'
                onClick={() => dialog.showAdd()}>
                <i className='pi pi-plus'></i>
                {t('Add permission')}
            </button>
        </WorkToolbar>

        {loading.renderBox({className: 'md pd', text: t('Loading permissions')})}
        {dialog.render((data: IWorkPermission) =>
            <PermissionForm
                availableRoles={availableRoles.map(r => r.name)}
                users={users}
                works={works}
                data={data}
                workId={work.id}
                onSubmit={performSavePermission}
                getJobs={getJobs}
                footer={<dialog.Footer />} />)}
        {table()}
    </div>
}