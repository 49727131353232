import * as React from 'react';

import {
    useTranslation,
    useForm, G
} from '@components';
import {
    IMachinery, ISecurity, IWork, ModuleManager, IMachineryType
} from '@models';
import './RestrictMachinery.scss';

interface IProps {
    security: ISecurity;
    moduleManager: ModuleManager;
    work: IWork;
    selectedMachineryId?: number;
    data: any[];
    restrictMachinery: Function;
    handleToast: Function;
    onClose: Function;
    machineryTypes: IMachineryType[];
}

interface IFormValues {
    workId: number;
    name: string;
    licenseNumber: string;
    code: string;
    contractorId: number;
    applicantName: string;
    applicantEmail: string;
    reason: string;
    remarks: string;
    date: string;
    machineryTypeId: number;
}

export function RestrictMachinery(props: IProps) {

    const { t } = useTranslation();

    const machinery = props.data.find(x => x.id === props.selectedMachineryId);

    const form = useForm<IFormValues>({
        initialValues: {
            machineryTypeId: machinery?.machineryTypeId ?? 0,
            workId: props.work.id,
            name: machinery?.name ?? ' ', //Se mantiene este campo por si el trabajador es borrado de la plataforma
            licenseNumber: machinery?.licenseNumber ?? '', //Se mantiene este campo por si el trabajador es borrado de la plataforma
            code: machinery?.code ?? ' ', //Se mantiene este campo por si el trabajador es borrado de la plataforma
            contractorId: machinery?.workId ?? 0,
            applicantName: props.security.user?.userName ?? '',
            applicantEmail: props.security.user?.email ?? '',
            reason: '',
            remarks: '',
            date: new Date().toISOString().split('T')[0]
        },

        onSubmit: (data: IFormValues) => {
            handleSave();
        }
    })

    const handleSave = async () => {
        const resp = await props.restrictMachinery(
            form.values.workId,
            machinery?.id,
            machinery?.machineryTypeId,
            machinery?.name ?? '',
            machinery?.licenseNumber ?? '',
            machinery?.code ?? '',
            machinery?.contractorId,
            form.values.applicantName,
            form.values.applicantEmail,
            form.values.reason,
            form.values.remarks
        );

        if (resp.isError === false && typeof resp.value == 'number') {
            props.handleToast(t('Machinery restricted successfully'), true);
            props.onClose();
        } else {
            props.handleToast(t('Error restricting machinery'), false);
            props.onClose();
        }
    }

    return (
        <div className='restrict-machinery-dialog'>
            <G label={t('Machinery type')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={props.machineryTypes.find(x => x.id === machinery?.machineryTypeId)?.name}
                />
            </G>
            <G label={t('Name')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={machinery?.name}
                />
            </G>
            <G label={t('License number')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={machinery?.licenseNumber}
                />
            </G>
            <G label={t('machinery.codes')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={machinery?.code}
                />
            </G>
            <G label={t('Contractor')}>
                <input
                    disabled={true}
                    className='c'
                    type='text'
                    value={machinery?.contractorName}
                />
            </G>
            <G label={t('Applicant')}>
                <input
                    disabled={false}
                    className='c'
                    type='text'
                    onChange={(e) => form.values.applicantName = e.target.value}
                    required
                />
            </G>
            <G label={t('Applicant email')}>
                <input
                    disabled={false}
                    className='c'
                    type='email'
                    onChange={(e) => form.values.applicantEmail = e.target.value}
                    required
                />
            </G>
            <G label={t('Date')}>
                <input
                    disabled={false}
                    className='c'
                    type='date'
                    defaultValue={form.values.date}
                    onChange={(e) => form.values.date = e.target.value}
                    required
                />
            </G>
            <G label={t('Motive')} className='restriction-reason'>
                <textarea
                    disabled={false}
                    className='c'
                    onChange={(e) => form.values.reason = e.target.value}
                />
            </G>
            <G label={t('Remarks')} className='restriction-remarks'>
                <textarea
                    disabled={false}
                    className='c'
                    onChange={(e) => form.values.remarks = e.target.value}
                />
            </G>
            <div className='c' />
            <div className='r'>
                <span className='e' />

                <button
                    className='primary'
                    disabled={form.isInvalid()}
                    onClick={form.handleSubmit}>
                    {t('Save')}
                </button>
            </div>
        </div>
    );
}
