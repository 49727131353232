import * as React from 'react';

import { classNames, useDataTable, useSearch, useTranslation } from '@components';
import { IRequirement, RequirementStatusTypes } from '@models/requirements';

import './ViewRequirementValidations.scss';
import DateUtils from '@utils/date-utils';
import { IJob } from '@models';

export interface IProps {
    job?: IJob;
    requirement: IRequirement;
    workId: number;
}

type ValidationRow = {
    validationHolderType: string;
    validationHolderName?: string;
    validatedState: string;
    validatedDate?: string | Date;
    optional?: boolean;
    departmentId?: number;
    jobImpliedRso?: boolean;
}

export function ViewRequirementValidations(props: IProps) {
    const { t } = useTranslation();

    const data = useSearch<ValidationRow>({
        workId: props.workId,
        search: 'requirements/validations',
        filters: {
            requirementId: props.requirement.id,
            workId: props.workId,
        }
    });

    const currentStatus = props.requirement.status.find(s => s.isCurrent);
    const isValid = currentStatus?.statusTypeId == RequirementStatusTypes.VALIDATED;

    const validations: ValidationRow[] = data
        .value
        .filter(v => v.optional != true || v.validatedDate != undefined)
        .map(v => {
            // SHAMEFUL HACK:
            // si el estado del requerimiento es validado,
            // entonces el estado de la validación es el mismo (fix migracion)
            // y simulamos que todos los participantes han validado (en la anterior aplicacion
            // no existia el concepto de validacion múltiple)
            if (isValid && currentStatus && DateUtils.parseDate(currentStatus.dateTime)?.getFullYear() < 2024) {

                if(v.validatedDate && DateUtils.parseDate(v.validatedDate)?.getFullYear() >= 2024 ){
                    return v;
                }else {
                    return {
                        ...v,
                        validatedDate: currentStatus?.dateTime,
                        validatedState: 'requirement.validation.validated',
                    };
                }
            }
            else {
                return v;
            }
        });

    const renderState = (r: ValidationRow) => {
        if (r.validatedDate != undefined) {
            return <div className={classNames("r", "v-center", "g-20", r.validatedState)}>
                <span>{t(r.validatedState)}</span>
                <span className="e"></span>
                <span>{DateUtils.format(r.validatedDate)}</span>
            </div>
        }
        else {
            return <div className={classNames("g-20", r.validatedState)}>{t(r.validatedState)}</div>
        }
    }

    const renderValidationHolderName = (r: ValidationRow) => {
        if (r.validationHolderName != undefined && r.validationHolderName != ' ') {
            return r.validationHolderName;
        }
        else if (r.jobImpliedRso && props.job != undefined) {
            const d = props.job?.departments.find(d => d.departmentId == r.departmentId);
            return d?.responsible?.name + ' ' + d?.responsible?.surname;
        }
        else {
            return '';
        }
    }

    const table = useDataTable<ValidationRow>({
        columns: [
            {
                className: 'center',
                field: 'validationHolderType',
                render: (n: ValidationRow) => t(n.validationHolderType),
            },
            {
                className: 'center',
                field: 'validationHolderName',
                render: (n: ValidationRow) => renderValidationHolderName(n),
                title: 'Name',
            },
            {
                className: 'td-state',
                title: 'State',
                render: renderState,
            },
        ],
        data: validations,
    });

    return <div className='view-requirement-validations'>
        {data.renderLoading()}
        {table()}
    </div>
}
