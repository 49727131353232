import * as React from 'react';

import { Timeline, useMessage, useTranslation } from '@components';
import DateUtils from '@utils/date-utils';
import './ViewResourceHistory.scss';
import { ResourceType } from '@models';
import { search } from '@store/actions/search';

type ResourceStatusRow = {
    date: Date;
    userName: string;
    statusTypeName: string;
}

export interface IProps {
    requestClose?: Function;
    resourceId: number;
    resourceType: ResourceType;
    workId: number;
}

export function ViewResourceHistory(props: IProps) {
    const { t } = useTranslation();

    const messages = useMessage();
    const [data, setData] = React.useState<ResourceStatusRow[]>([]);

    const initialize = async () => {
        const filters = {
            resourceType: props.resourceType,
            resourceId: props.resourceId,
        }
        let data;

        const resp = await search<ResourceStatusRow>(props.workId, 'resources/resourceStatus.list', filters);
        if(resp.hasValue){
            data = resp.value;
        }

        if([ResourceType.Contractor, ResourceType.Worker, ResourceType.Machinery, ResourceType.JobHasContractor, ResourceType.JobHasWorker, ResourceType.JobHasMachinery].includes(props.resourceType)){
            const restrictions = await search<ResourceStatusRow>(props.workId, `resources/resourceRestriction.${props.resourceType}.list`, filters);
            data = data?.concat(restrictions.value);
            data = data?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        }
        messages.set(resp);

        if (data) {
            setData(data);
        }
    }

    React.useEffect(() => {
        initialize();
    }, []);

    return <div className='c ViewResourceHistory'>
      {messages.renderIfError()}
      <div className='c sm pd body'>
        <Timeline
            opposite={item => <>
              {t(item.statusTypeName)}
            </>}
            content={item => <small className="p-text-secondary">{DateUtils.formatDateTimeFromUtc(item.date)} - {item.userName}</small>}
            marker={<i className='pi pi-circle-off' />}
            value={data}/>
      </div>

      {props.requestClose &&
       <div className='footer r r-end'>
             <button onClick={() => props.requestClose?.()}>{t('Close')}</button>
       </div>}
    </div>;
}
