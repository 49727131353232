import { zeroPad } from "./string-utils";

const isDmy = (s: string) => {
    const sep = s.includes('/') ? '/' : '-';
    const parts = s.split(sep);
    return parts.length > 1 && parts[2]?.length == 4;
}

export const parseDate = (s: string | Date) => {
    if (s && typeof (s) === 'object' && s.getTime()) {
        return s;
    }
    else if (s && typeof (s) === 'string' && isDmy(s)) {
        const sep = s.includes('/') ? '/' : '-';
        const parts = s.split(sep);
        return new Date(
            parseInt(parts[2]),
            parseInt(parts[1]) - 1,
            parseInt(parts[0]),
        );
    }
    else if (typeof (s) === 'string') {
        return new Date(s);
    }
    else {
        return s;
    }
}

export const dateIsLessOrEqual = (a: Date, b: Date) =>
    compareDates(a, b) <= 0;

export const compareDates = (a: Date, b: Date) => {
    if (a == undefined || b == undefined) {
        return 0;
    }
    if (a.getFullYear() > b.getFullYear()) {
        return 1;
    }
    else if (a.getFullYear() < b.getFullYear()) {
        return -1;
    }
    else if (a.getMonth() > b.getMonth()) {
        return 1;
    }
    else if (a.getMonth() < b.getMonth()) {
        return -1;
    }
    else if (a.getDate() > b.getDate()) {
        return 1;
    }
    else if (a.getDate() < b.getDate()) {
        return -1;
    } else if (a.getHours() < b.getHours()) {
        return -1;
    } else if (a.getHours() > b.getHours()) {
        return 1;
    } else if (a.getMinutes() < b.getMinutes()) {
        return -1;
    } else if (a.getMinutes() > b.getMinutes()) {
        return 1;
    } else if (a.getSeconds() < b.getSeconds()) {
        return -1;
    } else if (a.getSeconds() > b.getSeconds()) {
        return 1;
    } else {
        return 0;
    }
}

export const dateIsTodayOrFuture = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return compareDates(date, today) >= 0;
}

export const format = (input: Date | string | undefined, f: string = 'd-m-y') => {
    const patterns = f.toLowerCase().split('');

    if (input == undefined) {
        return '';
    }

    const d = typeof (input) === 'string'
        ? parseDate(input)
        : input;

    if (d.toString().includes("Invalid")) {
        return '';
    }

    return patterns.reduce((r, p) => {
        if (p === 'd') {
            r.push(zeroPad(d.getDate(), 2));
        }
        else if (p === 'm') {
            r.push(zeroPad(d.getMonth() + 1, 2));
        }
        else if (p === 'y') {
            r.push(zeroPad(d.getFullYear(), 2));
        }
        else if (p === 'h') {
            r.push(zeroPad(d.getHours(), 2));
        }
        else if (p === 'i') {
            r.push(zeroPad(d.getMinutes(), 2));
        }
        else if (p === 's') {
            r.push(zeroPad(d.getSeconds(), 2));
        }
        else {
            r.push(p.toUpperCase());
        }

        return r;
    }, [] as any[]).join('');
}

export const formatFullTime = (input: Date | string | undefined, f: string = 'd-m-y h:i:s') => {
    const patterns = f.toLowerCase().split('');

    if (input == undefined) {
        return '';
    }

    const d = typeof (input) === 'string'
        ? parseDate(input)
        : input;

    return patterns.reduce((r, p) => {
        if (p === 'd') {
            r.push(zeroPad(d.getDate(), 2));
        }
        else if (p === 'm') {
            r.push(zeroPad(d.getMonth() + 1, 2));
        }
        else if (p === 'y') {
            r.push(zeroPad(d.getFullYear(), 2));
        }
        else if (p === 'h') {
            r.push(zeroPad(d.getHours(), 2));
        }
        else if (p === 'i') {
            r.push(zeroPad(d.getMinutes(), 2));
        }
        else if (p === 's') {
            r.push(zeroPad(d.getSeconds(), 2));
        }
        else {
            r.push(p.toUpperCase());
        }

        return r;
    }, [] as any[]).join('');
}

export const formatLocalDate = (date: Date | string, style = 'd/m/y h:i:s') => {
    const d = typeof (date) === 'string' ? new Date(date) : date;
    d.setTime(d.getTime() + d.getTimezoneOffset());

    return format(d, style);
}

export const formatMinutes = (minutes: number) => {
    if (minutes > 0) {
        const hours = Math.floor(minutes / 60);
        const mints = (minutes - hours * 60);

        return `${zeroPad(hours, 2)}:${zeroPad(mints, 2)}`;
    }
    else {
        return '';
    }
}

export const timeToMinutes = (data: string | number) => {
    if (typeof (data) === 'string') {
        const [h, m] = data.split(':');
        return parseInt(h) * 60 + parseInt(m);
    }
    else {
        return data;
    }
}

export const toUtc = (data: string | Date | undefined) => {
    if (data != undefined) {
        const d = typeof (data) === 'string'
            ? parseDate(data)
            : data;

        const off = d.getTimezoneOffset() * 60000;
        const t = d.getTime();
        const nd = new Date(t + off);
        return nd;
    }
    else {
        return data;
    }
}

type FromUtcT = (data: string | Date) => Date;

export const fromUtc: FromUtcT = (data: string | Date) => {
    const hasTimezone = typeof (data) === 'string'
        ? data?.endsWith('Z')
        : false;

    if (hasTimezone && typeof (data) === 'string') {
        return fromUtc(data.substring(0, data.length - 1));
    }

    const d = typeof (data) === 'string'
        ? parseDate(data)
        : data;

    const off = d.getTimezoneOffset() * 60000;
    const t = d.getTime();
    const nd = new Date(t - off);
    return nd;
}

export const formatFromUtc = (data: string | Date | undefined, f: string = 'd-m-y') => {
    if (data != undefined) {
        return format(toUtc(data), f);
    }
    else {
        return '';
    }
}

export const formatDateTimeFromUtc = (data: string | Date | undefined, f: string = 'd-m-y h:i:s') => {
    if (data != undefined) {
        return format(fromUtc(data), f);
    }
    else {
        return '';
    }
}

export const toIso8601 = (data: string | Date | undefined) => {
    if (data != undefined) {
        return format(data, 'y-m-dTh:i:sZ');
    }
    else {
        return '';
    }
}

//Determines if a date or string is in date format
export const isDate = (data: string | Date) => {
    const d = typeof (data) === 'string'
        ? parseDate(data)
        : data;

    return d instanceof Date;
}

function strNow(interval: number = 0) {
    const date = new Date();
    date.setTime(date.getTime() + interval);

    return format(date, 'y-m-d');
}

const oneDayMs = 24 * 60 * 60 * 1000;

export default {
    format,
    formatFromUtc,
    formatDateTimeFromUtc,
    formatFullTime,
    formatLocalDate,
    formatMinutes,
    parseDate,
    timeToMinutes,
    compareDates,
    dateIsLessOrEqual,
    dateISTodayOrFuture: dateIsTodayOrFuture,
    toUtc,
    fromUtc,
    strNow,
    oneDayMs,
    toIso8601,
    isDate,
}
