import * as React from 'react';

import { search } from '@store/actions/search';
import { delay } from '@utils';
import { useLoading, useMessage } from '@utils/hooks';

export interface IProps {
    delay?: number;
    search: string;
    filters?: any;
    workId: number;
    normalizeKeys?: boolean;
    lazy?: boolean;
    map?: Function;
    dependencies?: any;
}

export default function useSearch<T>(props: IProps) {
    const [data, setData] = React.useState<T[]>([]);
    const loading = useLoading();
    const messages = useMessage();
    const [isCompleted, setIsCompleted] = React.useState(false);

    const initialize = loading.wrap(async (filters: any = undefined) => {
        const resp = await search<T>(props.workId, props.search, {
            normalizeKeys: props.normalizeKeys ?? true,
            filters: filters ?? props.filters,
        });

        if (props.delay) {
            await delay(props.delay);
        }

        messages.set(resp);
        const rawData = (resp.hasValue && props.map)
            ? resp.value.map((r, i) => props.map!(r, i))
            : resp.value;

        setIsCompleted(true);
        setData(rawData);

        return rawData;
    });

    React.useMemo(() => {
        if (!props.lazy) {
            initialize();
        }
    }, props.dependencies ?? []);

    const clear = () => {
        setData([]);
    }

    return {
        isCompleted,
        value: data,
        loading,
        messages,
        renderLoading: loading.render,
        clear,
        doSearch: (filters: any = undefined) => initialize(filters),
    };
}
