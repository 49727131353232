import * as React from 'react';

import {
    IContractor, ISecurity,
} from '@models';
import { getContractorFromAppUser } from '@store/actions/contractors';

export default function useContractorFromAppUser(workId: number, security: ISecurity) {
    const [selfContractorId, setSelfContractorId] = React.useState<number|undefined>();

    React.useEffect(() => {
        if (security.isContractor()) {
            getContractorFromAppUser(workId)
                .then((u: IContractor) => setSelfContractorId(u?.id));
        }
    }, [workId]);

    return selfContractorId;
}
