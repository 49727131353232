import * as React from 'react';

import { G, Tag, useConfirm, useForm, useLoading, useMessage, useRemoteData, useTranslation } from '@components';
import {
    IRequirementTypeDocumentRestriction, IRequirementGroup, IRequirementType
} from '@models/requirements';

export interface IProps {
    getRequirementType: (workId: number, id: number) => Promise<IRequirementTypeDocumentRestriction>,
    onClose: Function;
    onSuccess: Function;
    removeWorkRequirementTypeDependency: Function;
    removeRequirementTypeDocumentRestriction: Function;
    saveWorkRequirementTypeDependency: Function;
    saveRequirementTypeDocumentRestriction: Function;
    requirementGroups: IRequirementGroup[];
    requirementType: IRequirementType;
}

const commonMimeTypes = [
    {id: 'doc,docx', name: 'Word'},
    {id: 'xls,xlsx', name: 'Excel'},
    {id: 'pdf', name: 'Pdf'},
    {id: 'rar', name: 'Rar'},
    {id: 'zip', name: 'Zip'},
    {id: 'jpeg, jpg', name: 'JPG'},
    {id: 'png', name: 'PNG'},
];

type IRequirementTypeDocumentRestrictionM = IRequirementTypeDocumentRestriction & {
    __mimeTypes: string[];
    __excludeMimeTypes: string[];
}

function AddDocumentRestriction({
    onSuccess,
    requirementType,
    saveRequirementTypeDocumentRestriction,
    workId,
}: {
    onSuccess: Function,
    requirementType: IRequirementType,
    saveRequirementTypeDocumentRestriction: Function,
    workId: number,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const messages = useMessage();

    const form = useForm<IRequirementTypeDocumentRestrictionM>({
        initialValues: {
            isActive: true,
            name: '',
            title: '',
            requirementTypeId: requirementType.id!,
            __excludeMimeTypes: [],
            __mimeTypes: []
        },
        loading: loading,
    });

    const doSave = loading.wrap(async () => {
        const obj = form.values;
        obj.mimeTypes = obj.__mimeTypes.join(',');
        obj.excludeMimeTypes = obj.__excludeMimeTypes.join(',');

        const res = await saveRequirementTypeDocumentRestriction(workId, requirementType.id, obj);
        messages.set(res);

        if (res.hasValue) {
            onSuccess();
        }
    });

    return <div className='c'>
        <div className='md pd form-1 l200'>
            <G label={t('Name')}>
                {form.input('name')}
            </G>
            <G label={t('Title')}>
                {form.input('title')}
            </G>
            <G label={t('File types')}>
                {form.multiselect('__mimeTypes', commonMimeTypes)}
            </G>
            <G label={t('Exclude file types')}>
                {form.multiselect('__excludeMimeTypes', commonMimeTypes)}
            </G>
            <G label={t('Regular expression')}>
                {form.input('regexp')}
            </G>
        </div>
        {messages.render()}
        <div className='footer r r-end'>
            {loading.render()}
            <button
                disabled={form.isInvalid()}
                className='primary'
                onClick={doSave}>{t('Save')}</button>
        </div>
    </div>
}

function DocumentRestrictionRepresentation({restriction}: {restriction: IRequirementTypeDocumentRestriction}) {
    const { t } = useTranslation();
    const style = ['success', 'info', 'warn', 'error'];
    const restrictions: ('mimeTypes'| 'excludeMimeTypes' |'regexp'|'limitFileSize')[] = ['mimeTypes', 'excludeMimeTypes' ,'regexp', 'limitFileSize'];
    const reps = restrictions
        .filter(r => restriction[r])
        .map((r, i) => <Tag severity={style[i]} value={`${t(r)}: ${restriction[r]}`} key={i} />);

    return <div className='r g-10'>
        {reps}
    </div>
}

enum FormType {
    List = 1,
    Add  = 2,
}

export function EditRequirementTypeDocumentRestrictions(props: IProps) {
    const { t } = useTranslation();
    const [formType, setFormType] = React.useState<FormType>(FormType.List);
    const requirementType = useRemoteData<IRequirementType>(
        props.getRequirementType,
        {
            parameters: [props.requirementType.workId, props.requirementType.id],
        });
    const loading = useLoading();
    const message = useMessage();

    const documentRestrictions = (requirementType.value?.documentRestrictions ?? []).filter(r => r.isActive);
    const reload = () => {
        requirementType.query();
        setFormType(FormType.List);
    }

    const doRemove = loading.wrap(async (id: number) => {
        const resp = await props.removeRequirementTypeDocumentRestriction(
            props.requirementType.workId,
            props.requirementType.id,
            id);
        message.set(resp);
        reload();
    });

    const confirm = useConfirm({
        message: t('Are you sure to remove this document restriction?'),
        accept: doRemove,
    });

    const onCancel = () => {
        if (formType === FormType.Add) {
            setFormType(FormType.List);
        }
        else {
            props.onClose();
        }
    }

    return <div className='c he'>
        <div className='c he e'>
            {formType === FormType.List && <>
                <table className='table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentRestrictions.length == 0 && <tr>
                            <td colSpan={4}>
                                <div className='center lg mr pd'>
                                    <span className='mutted'>
                                        {t('There is no document restrictions')}
                                    </span>
                                </div>
                            </td>
                        </tr>}
                        {documentRestrictions.map((d, i) => <tr key={i}>
                            <td className='sm pd'>{d.name}</td>
                            <td className='sm pd'>{d.title}</td>
                            <td className='sm pd'>
                                <DocumentRestrictionRepresentation restriction={d} />
                            </td>
                            <td className='td-sm'>
                                <i
                                    className='pi pi-trash pointer'
                                    onClick={e => confirm(d.id, e)}></i>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </>}
            {formType === FormType.Add &&
                <AddDocumentRestriction
                    onSuccess={reload}
                    requirementType={props.requirementType}
                    saveRequirementTypeDocumentRestriction={props.saveRequirementTypeDocumentRestriction}
                    workId={props.requirementType.workId} />}
        </div>
        <div className='footer r r-end sm pd-left'>
            {message.render()}
            <span className='e'></span>
            <button onClick={onCancel}>{t('Cancel')}</button>
            {formType === FormType.List &&
                <button className='primary' onClick={() => setFormType(FormType.Add)}>{t('Add')}</button>}
        </div>
    </div>
}