import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as NotificationsStore from '@store/notifications';
import * as RequirementsStore from '@store/requirements';
import * as UserStore from '@store/user';

import { ValidateRequirementsFromNotificationMessages } from '@components/notifications/ValidateRequirementsFromNotificationMessages';

export default connect(
    (state: ApplicationState) => ({
        ...RequirementsStore.plainActions,
        ...state.identity,
        work: state.user.work,
        messages: state.notifications.messages,
    }),
    {
        loadWork: UserStore.actionCreators?.selectWork,
        resolveMessage: NotificationsStore.actionCreators?.clearMessage,
    },
)(ValidateRequirementsFromNotificationMessages as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
