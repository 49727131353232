import { Action, Reducer } from 'redux';
import { ICountry, IWork } from '../models';

export interface IWorkState {
    work?: IWork;
}

export type KnownAction = {type: undefined};

export const actionCreators = {
};

export const reducer: Reducer<IWorkState> = (state: IWorkState | undefined, incomingAction: Action): IWorkState => {
    if (state === undefined) {
        return { };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        default:
            return state;
    }
};
