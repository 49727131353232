import * as React from 'react';

import { ImageViewer } from './ImageViewer';
import { PdfViewer } from './PdfViewer';
import { useTranslation, Chip } from '@components';

export interface IProps {
    className?: string;
    mimeType?: string;
    url: string;
}

const isImage   = (mime?: string) => mime?.startsWith('image/');
const isPdf     = (mime?: string) => mime?.startsWith('application/pdf');

export function hasViewer(mime?: string) {
    return isImage(mime) || isPdf(mime);
}

export function DocumentViewer(props: IProps) {
    const { t } = useTranslation();
    if (isImage(props.mimeType)) {
        return <ImageViewer
            className={props.className}
            url={props.url} />;
    }
    else if (isPdf(props.mimeType)) {
        return <PdfViewer className={props.className} url={props.url} />;
    }
    else {
        return <div className='c center v-center he lg pd g-20'>
            {t('documentPreviewNotSupported')}

            <Chip className='danger' label={props.mimeType} />

            <a href={props.url} target='_blank' className='r g-10' rel='noopener noreferrer'>
                <i className='pi pi-arrow-down' />
                {t('Download file')}
            </a>
        </div>;
    }
}