import * as React from 'react';

import AdminNotificationTemplates from '@containers/admin/AdminNotificationTemplatesContainer';
import AdminWorkMessages from '@containers/admin/AdminWorkMessagesContainer';
import { IWork } from '@models';
import { WorkToolbar } from './WorkToolbar';

import { TabPanel, TabView, useTranslation } from '@components';

export interface IProps {
    work: IWork;
}

export function AdminWorkNotifications(props: IProps) {
    const { t } = useTranslation();

    return (<div className='c md pd'>
        <WorkToolbar work={props.work} />

        <TabView>
            <TabPanel header={t('Messages')}>
                <AdminWorkMessages work={props.work} />
            </TabPanel>
            <TabPanel header={t('Templates')}>
                <AdminNotificationTemplates work={props.work} />
            </TabPanel>
        </TabView>
    </div>)
}

