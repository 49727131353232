import * as React from 'react';

import { IConstants, IWorkspace, countriesToNode, IPropertyGroupType, PropertyGroupObjectType } from '@models';
import {
    G,
    TreeSelect,
    useForm,
    useTranslation,
    ValidationBuilder
} from '@components';
import { DynamicProperties } from '@components/user/DynamicProperties';

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    data?: IWorkspace;
    footer?: any;
    onChange?: Function;
    onSubmit: Function;
    workId: number;
    constants: IConstants;
}

export const validateWorkspace = (data: any) => {
    return ValidationBuilder.create(data)
        .notEmpty('name', 'Name is required')
        .build();
}

export const isValid = (data: IWorkspace) => {
    return Object.keys(validateWorkspace(data)).length == 0;
}

const getSelectedNodes = (w: IWorkspace|undefined) =>
    w ? [w.countryId, w.regionId, w.countyId].filter(d => d != undefined)
      : undefined;

export function WorkspaceForm(props: IProps) {
    const { t } = useTranslation();
    const countryNodes = countriesToNode(props.constants.countries);

    const [selectedRegionNode, setSelectedRegionNode] =
        React.useState<any>(getSelectedNodes(props.data));

    const onSelectedRegionNodeChanged = (ev: any) => {
        setSelectedRegionNode(ev.value);
        const [countryId, regionId, countyId] =
            ev.value
                .concat([undefined, undefined, undefined])
                .slice(0, 3);

        form.setFieldValue('countryId', countryId);
        form.setFieldValue('regionId', regionId);
        form.setFieldValue('countyId', countyId);
    }

    const form = useForm<Partial<IWorkspace>>({
        initialValues: {
            description: props.data?.description ?? '',
            id: props.data?.id ?? 0,
            isActive: true,
            name: props.data?.name ?? '',
            workId: props.workId,
            countryId: props.data?.countryId,
            countyId: props.data?.countyId,
            regionId: props.data?.regionId,

            contact: props.data?.contact ?? '',
            address: props.data?.address ?? '',
            location: props.data?.location ?? '',
            postalCode: props.data?.postalCode ?? '',
            phone: props.data?.phone ?? '',
            fax: props.data?.fax ?? '',
            email: props.data?.email ?? '',
            properties: props.data?.properties,
        },
        validate: validateWorkspace,
        onSubmit: (data: IWorkspace) => {
            props.onSubmit(data);
            return { ok: true };
        },
        onChange: props.onChange,
    });

    React.useEffect(() => {
        form.setFieldValue('properties', props.data?.properties);
    }, [props.data]);

    return <div>
        <form onSubmit={form.handleSubmit}>
            <div className='form-1 l200 md pd'>
                <G label={t('Name')}>
                    {form.input('name', { autoFocus: true })}
                </G>
                <G label={t('Description')}>
                    {form.input('description')}
                </G>
                <G label={t('Region')}>
                    <TreeSelect
                        options={countryNodes}
                        value={selectedRegionNode}
                        onChange={onSelectedRegionNodeChanged} />
                </G>
                {/* CAMPOS NUEVOS */}
                <G label={t('Contact')}>
                    {form.input('contact')}
                </G>
                <G label={t('Address')}>
                    {form.input('address')}
                </G>
                <G label={t('Location')}>
                    {form.input('location')}
                </G>
                <G label={t('Postal code')}>
                    {form.input('postalCode')}
                </G>
                <G label={t('Phone')}>
                    {form.input('phone')}
                </G>
                <G label={t('Fax')}>
                    {form.input('fax')}
                </G>
                <G label={t('Email')}>
                    {form.input('email')}
                </G>
                <DynamicProperties
                    className='e sm'
                    object={form.values}
                    onChange={props => {
                        form.setFieldValue('properties', props);
                    }}
                    objectType={PropertyGroupObjectType.Workspace}
                    propertyGroupTypes={props.propertyGroupTypes} />
            </div>
            <div className='errors-container'>
                {form.errorBox()}
            </div>

            {props.footer != undefined && <div className='footer'>{props.footer}</div>}
        </form>
    </div>
}