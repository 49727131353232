import * as React from 'react';

import { FormikValues, useFormik } from 'formik';
import { FloatField, FormField } from './FormField';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { InputGroup } from './InputGroup';
import { Message } from 'primereact/message';
import { Editor } from 'primereact/editor';
import { InputSwitch, MultiSelect, Select, useTranslation } from '..';
import { Dropdown } from 'primereact/dropdown';
import { InputPasteHandler } from './InputPasteHandler';
import * as DateUtils from '@utils/date-utils';
import { currentLocale } from '../../i18n';
import { observeValue } from '@utils/hooks';

export interface IUseFormProps<T> {
    initialValues: T;
    validate?: (_: T) => any;
    onSubmit?: Function;
    onChange?: Function;
    validateOnMount?: boolean,
    readonly?: boolean;
    loading?: any;
    validateOnChange?: boolean;
}

interface IInputOpts {
    autoFocus?: boolean;
    type?: string;
    title?: string;
    containerClassName?: string;
    className?: string;
    containerStyle?: any;
    placeholder?: string;
    style?: any;
    defaultValue?: any;
    onBlur?: Function;
    ref?: any;
    map?: Function;
    readOnly?: boolean;
}

type ICalendarOpts = IInputOpts & {
    dateFormat?: string;
}

type ITextareaInputOpts =
    IInputOpts & {
        rows?: number;
        suggestions?: string[];
        length?: number;
        readonly?: boolean;
    };

interface ISelectOpts {
    options: any[];
    optionLabel?: string;
    optionValue?: string;
    placeholder?: string;
    containerClassName?: any;
    containerStyle?: any;
    onBlur?: Function;
    style?: any;
    blank?: boolean;
    blankText?: string;
    filter?: boolean;
}

export function useForm<T extends FormikValues>(props: IUseFormProps<T> = { initialValues: {} as T }) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | undefined>(undefined);

    const { t } = useTranslation();

    const form: any = useFormik({
        initialValues: props.initialValues,
        validate: (data) => {
            if (props.validate && !props.readonly) {
                return props.validate(data);
            }
            else {
                return undefined;
            }
        },
        onSubmit: async (data) => {
            if (props.onSubmit && !props.readonly) {
                const resp = await props.onSubmit(data);
                if (resp.ok) {
                    setError(undefined);
                }
                else {
                    setError(t(resp.message));
                }
                setLoading(false);
            }
            else {
                setError(undefined);
                setLoading(false);
            }
        },
        validateOnChange: props.validateOnChange,
    });

    React.useEffect(() => {
        if (props.validateOnMount && props.validate && !props.readonly) {
            form.setErrors(props.validate(props.initialValues));
        }
    }, []);

    const hasError = () =>
        Object.keys(form.errors).length > 0;

    const isFormFieldValid = (name: string) => {
        return !form.errors[name];
    }

    const getFormErrorMessage = (name: string) => {
        const isDate = name.toLowerCase().includes('date');
        const className = isDate ? 'p-error date' : 'p-error';
        return !isFormFieldValid(name) && <small className={className}>{form.errors[name]}</small>;
    };

    const handleChange = (ev: any, opts: any | undefined = undefined) => {
        const res = form.handleChange(ev);

        if (props.onChange) {
            const value = opts?.map
                ? opts!.map(ev.target.value)
                : (opts?.type == 'number')
                    ? parseFloat(ev.target.value)
                    : ev.target.value;

            const data = { ...form.values, [ev.target.id]: value };
            props.onChange(data);
        }
        return res;
    }

    const hasChanges = () => {
        return JSON.stringify(props.initialValues) != JSON.stringify(form.values);
    }

    const field = (name: string, title: string, comp: Function) => {
        return <FormField
            id={name}
            title={title}
            className='lg'
            errorMessage={getFormErrorMessage(name)}
            labelClassName={classNames({ 'p-error': !isFormFieldValid(name) })}>
            {comp()}
        </FormField>
    }

    const floatField = (name: string, title: string, comp: Function) => {
        return <FloatField
            id={name}
            title={title}
            className='lg'
            errorMessage={getFormErrorMessage(name)}
            labelClassName={classNames({ 'p-error': !isFormFieldValid(name) })}>
            {comp()}
        </FloatField>
    }

    const suffixField = (name: string, suffix: string, comp: Function) => {
        return <FormField
            id={name}
            title=''
            errorMessage={getFormErrorMessage(name)}
            labelClassName={classNames({ 'p-error': !isFormFieldValid(name) })}>
            <InputGroup suffix={suffix}>
                {comp()}
            </InputGroup>
        </FormField>;
    }

    const errorBox = () => {
        if (error)
            return <Message severity={'error'} text={t(error)} />
        else
            return null;
    }

    const inputs = (names: string[], opts: { [key: string]: IInputOpts }) => {
        return <div
            className={classNames('r we', opts['*']?.containerClassName)}
            style={opts['*']?.containerStyle}>
            {names.map(name =>
                <div className={classNames('c', opts[name]?.containerClassName)}
                    style={opts[name]?.containerStyle}
                    key={name}>
                    <input
                        id={name}
                        readOnly={props.readonly}
                        type={opts[name]?.type ?? 'text'}
                        style={opts[name]?.style}
                        ref={opts[name]?.ref}
                        value={form.values[name] ?? ''}
                        onChange={handleChange}
                        onBlur={e => opts[name]?.onBlur && opts[name]?.onBlur?.(e)}
                        placeholder={opts[name]?.placeholder}
                        autoFocus={opts[name]?.autoFocus}
                        className={classNames('e', opts[name]?.className, { 'p-invalid': !isFormFieldValid(name) })} />
                    {getFormErrorMessage(name)}
                </div>)}
        </div>
    }

    const input = (name: string, opts: IInputOpts = {}) => {
        if (opts.type == 'date') {
            const setDateFromEvent = (data: string) => {
                form.setFieldValue(name, DateUtils.format(data, 'y-m-d'))
            }

            return (<InputPasteHandler
                onPaste={setDateFromEvent}>
                <div className={classNames('c', opts.containerClassName)}
                    style={opts.containerStyle}>
                    <input
                        id={name}
                        readOnly={props.readonly || opts.readOnly}
                        type={opts.type ?? 'text'}
                        style={opts.style}
                        ref={opts.ref}
                        value={form.values[name] ?? ''}
                        onChange={e => handleChange(e, opts)}
                        onBlur={e => opts.onBlur && opts.onBlur(e)}
                        placeholder={opts.placeholder}
                        autoFocus={opts.autoFocus}
                        className={classNames('e', opts.className, { 'p-invalid': !isFormFieldValid(name) })} />
                    {getFormErrorMessage(name)}
                </div>
            </InputPasteHandler>);
        }
        else {
            return (<div
                className={classNames('c', opts.containerClassName)}
                style={opts.containerStyle}>
                <input
                    id={name}
                    readOnly={props.readonly || opts.readOnly}
                    type={opts.type ?? 'text'}
                    style={opts.style}
                    ref={opts.ref}
                    value={form.values[name] ?? ''}
                    onChange={e => handleChange(e, opts)}
                    onBlur={e => opts.onBlur && opts.onBlur(e)}
                    placeholder={opts.placeholder}
                    autoFocus={opts.autoFocus}
                    className={classNames('e', opts.className, { 'p-invalid': !isFormFieldValid(name) })} />
                {getFormErrorMessage(name)}
            </div>);
        }
    }

    const editor = (name: string, opts: IInputOpts = {}) => {
        return <div
            className={classNames('c', opts.containerClassName)}
            style={opts.containerStyle}>
            <Editor
                id={name}
                onTextChange={ev => form.setFieldValue(name, ev.htmlValue)}
                value={form.values[name] ?? ''} />
        </div>;
    }

    const checkBox = (name: string, opts: IInputOpts = {}) => {
        return <div className={classNames('c', opts.containerClassName)} style={opts.containerStyle}>
            <InputSwitch
                id={name}
                name={name}
                disabled={props.readonly}
                checked={form.values[name] ?? false}
                onChange={handleChange}
                className={classNames({ 'p-invalid': !isFormFieldValid(name) })} />
            {getFormErrorMessage(name)}
        </div>
    }

    const autoCompleteTextArea = (name: string, inputOpts: ITextareaInputOpts | string[] = {}) => {
        const opts: ITextareaInputOpts = inputOpts.length
            ? { suggestions: inputOpts } as ITextareaInputOpts
            : inputOpts as ITextareaInputOpts;

        return <div className='c' style={opts.containerStyle}>
            <Dropdown
                options={opts.suggestions}
                className='flat-bottom no-bottom-border'
                onChange={e => form.setFieldValue(name, e.target.value)}
            />
            <textarea
                id={name}
                value={form.values[name] ?? ''}
                onChange={handleChange}
                className={classNames('e', 'no-top-border', 'flat-top', { 'p-invalid': !isFormFieldValid(name) })}
                style={opts.style}
                autoFocus={opts.autoFocus}></textarea>
            {/* <AutoComplete
                id={name}
                dropdown
                minLength={0}
                readOnly={props.readonly}
                value={form.values[name] ?? ''}
                onChange={handleChange}
                completeMethod={() => opts.suggestions}
                autoFocus={opts.autoFocus}
                suggestions={opts.suggestions}
                className={classNames('e',  { 'p-invalid': !isFormFieldValid(name) })}></AutoComplete> */}
            {getFormErrorMessage(name)}
        </div>
    }

    const autoComplete = (name: string, inputOpts: ITextareaInputOpts | string[] = {}) => {
        const opts: ITextareaInputOpts = inputOpts.length
            ? { suggestions: inputOpts } as ITextareaInputOpts
            : inputOpts as ITextareaInputOpts;

        return <div className='c' style={opts.containerStyle}>
            <AutoComplete
                id={name}
                readOnly={props.readonly}
                value={form.values[name] ?? ''}
                onChange={handleChange}
                completeMethod={() => opts.suggestions}
                autoFocus={opts.autoFocus}
                suggestions={opts.suggestions}
                className={classNames('e', { 'p-invalid': !isFormFieldValid(name) })}></AutoComplete>
            {getFormErrorMessage(name)}
        </div>
    }

    const textarea = (name: string, opts: ITextareaInputOpts = {}) => {
        return <div className='c' style={opts.containerStyle}>
            <textarea
                style={{ ...opts.style, resize: 'vertical', minHeight: '40px' }}
                id={name}
                readOnly={opts.readOnly || props.readonly}
                rows={opts.rows}
                value={form.values[name] ?? ''}
                placeholder={opts.placeholder}
                onChange={handleChange}
                autoFocus={opts.autoFocus}
                className={classNames({ 'p-invalid': !isFormFieldValid(name) })}></textarea>
            {getFormErrorMessage(name)}
        </div>
    }

    const calendar = (name: string, opts: ICalendarOpts = {}) => {
        const setDateFromEvent = (data: string) => {
            form.setFieldValue(name, DateUtils.parseDate(data))
        }

        return (<InputPasteHandler onPaste={setDateFromEvent}>
            <div className='c' style={opts.containerStyle}>
                <Calendar id={name}
                    locale={currentLocale()}
                    value={form.values[name] ?? ''}
                    dateFormat={opts.dateFormat ?? 'dd/mm/yy'}
                    onChange={(e) => {
                        const date = e.value as Date;
                        if (date == null) {
                            return null;
                        }
                        const utc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
                        handleChange({
                            target: e.target,
                            value: utc
                        });
                    }}
                    showIcon />
                {getFormErrorMessage(name)}
            </div>
        </InputPasteHandler>);
    }

    const select = (name: string, inputOpts: ISelectOpts | any[]) => {
        const opts: ISelectOpts = ("options" in inputOpts)
            ? inputOpts as ISelectOpts
            : { options: inputOpts } as ISelectOpts;

        const key = opts.optionValue ?? 'id';
        const label = opts.optionLabel ?? 'name';

        const resolve = (value: any) => {
            if (value) {
                return opts.options.find(o => o[key] == form.values[name])?.[label];
            }
            else {
                return opts.placeholder;
            }
        }

        function isWritable<T extends Object>(obj: T, key: keyof T) {
            const desc = Object.getOwnPropertyDescriptor(obj, key) || {}
            return Boolean(desc.writable)
        }

        const isSimple = opts.options.length > 0
            && typeof (opts.options[0]) === 'string';

        const options = opts.blank
            ? [{ [label]: opts.blankText, }].concat(opts.options)
            : isSimple
                ? opts.options.map(o => ({ [key]: o, [label]: o }))
                : opts.options;

        options.map(o => {
            const iw = isWritable(o, 'name')
            if (iw) {
                o.name = t(o.name);
            }
        });

        return <div className={classNames('c', opts.containerClassName)} style={opts.containerStyle}>
            {props.readonly &&
                <input type='text' readOnly value={resolve(form.values[name])} />}
            {!props.readonly && <Select
                id={name}
                options={options}
                placeholder={opts.placeholder}
                optionLabel={label}
                optionValue={key}
                filter={opts.filter}

                value={form.values[name]}
                onChange={handleChange}
                onBlur={e => opts.onBlur && opts.onBlur(e)}
                className={classNames('e', { 'p-invalid': !isFormFieldValid(name) })} />}
            {getFormErrorMessage(name)}
        </div>
    }

    const multiselect = (name: string, inputOpts: ISelectOpts | any[], filter?: boolean) => {
        const opts: ISelectOpts = ("options" in inputOpts)
            ? inputOpts as ISelectOpts
            : { options: inputOpts } as ISelectOpts;

        const key = opts.optionValue ?? 'id';
        const label = opts.optionLabel ?? 'name';

        const resolve = (value: any) => {
            if (value) {
                return opts.options.find(o => o[key] == form.values[name])?.[label];
            }
            else {
                return opts.placeholder;
            }
        }

        const isSimple = opts.options.length > 0
            && typeof (opts.options[0]) === 'string';

        const options = opts.blank
            ? [{ [label]: opts.blankText, }].concat(opts.options)
            : isSimple
                ? opts.options.map(o => ({ [key]: o, [label]: o }))
                : opts.options;

        return <div className={classNames('c', opts.containerClassName)} style={opts.containerStyle}>
            {props.readonly &&
                <input type='text' readOnly value={resolve(form.values[name])} />}
            {!props.readonly && <MultiSelect
                id={name}
                options={options}
                placeholder={opts.placeholder}
                optionLabel={label}
                optionValue={key}
                value={form.values[name]}
                onChange={handleChange}
                filter={filter ?? false}
                onBlur={e => opts.onBlur && opts.onBlur(e)}
                className={classNames('e', { 'p-invalid': !isFormFieldValid(name) })} />}
            {getFormErrorMessage(name)}
        </div>
    }


    const span = (name: string, opts: IInputOpts = {}) => {
        return <div className={classNames('c', opts.containerClassName)}>
            <input type='text' readOnly value={form.values[name] ?? opts.defaultValue} />
        </div>
    }

    const clear = () => {
        const data = form.values;
        for (const k of Object.keys(data)) {
            form.setFieldValue(k, null);
        }
        if (props.initialValues) {
            for (const k of Object.keys(props.initialValues)) {
                form.setFieldValue(k, (props.initialValues as any)[k]);
            }
        }
    }

    const setFieldValues = (data: any) => {
        form.resetForm();
        if (!props.readonly) {
            for (const k of Object.keys(data)) {
                form.setFieldValue(k, data[k]);
            }
        }
    }

    const isLoading = () => {
        if (props.loading && typeof (props.loading) === 'boolean') {
            return props.loading;
        }
        else if (props.loading && props.loading.isLoading) {
            return props.loading.isLoading();
        }
        else {
            return false;
        }
    }

    const isInvalid = () => hasError() || isLoading();

    const isValid = () => !isInvalid();

    type GetF = <R>(name: string) => R;
    const get: GetF = (name: string) => form.values[name];

    function observe<T>(name: string, handler: (_: T) => void) {
        return observeValue(handler, form.values[name]);
    }

    return {
        autoComplete,
        autoCompleteTextArea,
        calendar,
        checkBox,
        clear,
        editor,
        errorBox,
        field,
        floatField,
        form,
        get,
        getFormErrorMessage,
        handleChange: handleChange,
        handleSubmit: form.handleSubmit,
        hasChanges,
        hasError,
        input,
        inputs,
        isFormFieldValid,
        isInvalid,
        isValid,
        multiselect,
        observe,
        select,
        setFieldValue: form.setFieldValue,
        setFieldValues,
        span,
        suffixField,
        textarea,
        values: form.values,
    }
}
