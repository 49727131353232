import * as React from 'react';

import {
    Select,
    useConfirm,
    useLoading,
    useMemoized,
    useMessage,
    useRemoteData,
    useResolveName,
    useTranslation
} from '@components';
import { IWork } from '@models';
import {
    IRequirementGroup,
    IRequirementType,
    RequirementTargetType,
    RequirementTypeDependencyType,
    requirementTargetTypes
} from '@models/requirements';
import { flatten } from '@utils';

export interface IProps {
    getWorkRequirementGroups: Function;
    onClose: Function;
    onSuccess: Function;
    removeWorkRequirementTypeDependency: Function;
    saveWorkRequirementTypeDependency: Function;
    selectedTargetType: number|undefined;
    requirementGroups: IRequirementGroup[];
    requirementType: IRequirementType;
    work: IWork;
}

enum DependencyOptions {
    NONE = 0,
    PROPAGATE = 1,
}

export function EditRequirementTypeDependencies(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const allRequirementGroups = useRemoteData<IRequirementGroup[]>(
        props.getWorkRequirementGroups,
        {
            parameters: [ props.work.id ],
        });

    const allDependencyOptions = React.useRef([
        {id: DependencyOptions.NONE, name: t('Select an option')},
        {id: DependencyOptions.PROPAGATE, name: t('Propagate validation')},
    ]);

    const allRequirementDependencyTypes = React.useRef([
        {id: RequirementTypeDependencyType.RequirementMustHaveBeenRead, name: t('RequirementMustHaveBeenRead')},
        {id: RequirementTypeDependencyType.RequirementMustHaveBeenValidated, name: t('RequirementMustHaveBeenValidated')},
        {id: RequirementTypeDependencyType.IBO, name: t('requirements.dependency.ibo')},
    ]);

    const [selectedDependencyType, setSelectedDependencyType] =
        React.useState<RequirementTypeDependencyType>(RequirementTypeDependencyType.RequirementMustHaveBeenValidated);
    const [dependencyOptions, setDependencyOptions] = React.useState<DependencyOptions>(DependencyOptions.NONE);

    const [allRequirements, setAllRequirements] = React.useState<IRequirementType[]>(
        flatten(
            props.requirementGroups.map(g => g.requirementTypes ?? []))
        .filter(r => r.isActive && r.id != props.requirementType.id)
    );
    const [selected, setSelected] = React.useState<number|undefined>();

    const resolveDependency = useMemoized((id: number) => {
        const d = allRequirements.find(d => d.id == id);
        return d?.title;
    }, [ allRequirements ]);
    const resolveDependencyType = useResolveName(allRequirementDependencyTypes.current);
    const dependencies = props.requirementType.dependencies ?? [];

    const doAddRequirement = loading.wrap(async () => {
        if (selected || selectedDependencyType === RequirementTypeDependencyType.IBO) {
            const propagate = dependencyOptions === DependencyOptions.PROPAGATE;

            const obj = {
                id: undefined,
                requirementTypeId: props.requirementType.id!,
                dependsOnRequirementId: selected,
                dependencyType: selectedDependencyType,
                propagate: propagate,
            };
            const res = await props.saveWorkRequirementTypeDependency(
                props.requirementType.workId,
                obj);
            message.set(res);

            if (res.hasValue) {
                obj.id = res.value;
                props.onSuccess({
                    ...props.requirementType,
                    dependencies: dependencies.concat([obj])
                }, false);
            }

            setDependencyOptions(DependencyOptions.NONE);
        }
    });

    const doRemove = loading.wrap(async (id: number) => {
        const res = await props.removeWorkRequirementTypeDependency(
            props.requirementType.workId,
            id);
        message.set(res);
        if (res.hasValue) {
            props.onSuccess({
                ...props.requirementType,
                dependencies: dependencies.filter(d => d.id != id)
            }, false);
        }
    });

    const confirm = useConfirm({
        message: t('Are you sure to remove this dependency?'),
        accept: doRemove,
    });

    const getRequirementTitle = (d: IRequirementType) => {
        const targetTypeName = requirementTargetTypes.find(r => r.id === d.targetType)?.name;
        return `${d?.name} - ${targetTypeName}: ${d?.title}`;
    }

    React.useEffect(() => {
        if (props.selectedTargetType === RequirementTargetType.JobHasContractor
            && allRequirementGroups.value != undefined) {
            const groups = flatten(allRequirementGroups.value.map(g => g.requirementTypes ?? []))
                .filter(r =>
                    r.id != props.requirementType.id
                    && (
                        r.targetType === RequirementTargetType.Contractor
                        || r.targetType === RequirementTargetType.JobHasContractor
                        || r.targetType === RequirementTargetType.Job
                        || r.targetType === RequirementTargetType.JobHasDepartment)
                    )
                .map(r => ({...r, title: getRequirementTitle(r) })).sort((a, b) => a.title.localeCompare(b.title));
            setAllRequirements(groups);
        }
        else if (props.selectedTargetType === RequirementTargetType.JobHasWorker
            && allRequirementGroups.value != undefined) {
            const groups = flatten(allRequirementGroups.value.map(g => g.requirementTypes ?? []))
                .filter(r =>
                    r.id != props.requirementType.id
                    && (
                        r.targetType === RequirementTargetType.Worker
                        || r.targetType === RequirementTargetType.JobHasWorker
                        || r.targetType === RequirementTargetType.Job
                        || r.targetType === RequirementTargetType.JobHasContractor
                        || r.targetType === RequirementTargetType.JobHasDepartment)
                    )
                .map(r => ({...r, title: getRequirementTitle(r) })).sort((a, b) => a.title.localeCompare(b.title));
            setAllRequirements(groups);
        }
        else if (props.selectedTargetType === RequirementTargetType.JobHasMachinery
            && allRequirementGroups.value != undefined) {
            const groups = flatten(allRequirementGroups.value.map(g => g.requirementTypes ?? []))
                .filter(r =>
                    r.id != props.requirementType.id
                    && (
                        r.targetType === RequirementTargetType.Machinery
                        || r.targetType === RequirementTargetType.JobHasMachinery
                        || r.targetType === RequirementTargetType.Job
                        || r.targetType === RequirementTargetType.JobHasContractor
                        || r.targetType === RequirementTargetType.JobHasDepartment)
                    )
                .map(r => ({...r, title: getRequirementTitle(r) })).sort((a, b) => a.title.localeCompare(b.title));
            setAllRequirements(groups);
        }
        else if (allRequirementGroups.value != undefined) {
            const groups = flatten(allRequirementGroups.value.map(g => g.requirementTypes ?? []))
                .filter(r => r.id != props.requirementType.id)
                .map(r => ({...r, title: getRequirementTitle(r) }))
                .sort((a, b) => a.title.localeCompare(b.title));
            setAllRequirements(groups);
        }
    }, [ allRequirementGroups.value ]);

    return <div className='c'>
        {allRequirementGroups.renderLoading()}
        {allRequirementGroups.hasValues() &&
            <div className='c md pd'>
                <table className='table'>
                    <thead>
                    <tr>
                        <th/>
                        <th/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {dependencies.map((d, i) =>
                        <tr key={i}>
                            <td className={'center sm pd'}>{resolveDependency(d.dependsOnRequirementId)}</td>
                            <td className={'center sm pd'}>{resolveDependencyType(d.dependencyType)}</td>
                            <td className={'center sm pd'}>
                                {d.propagate
                                    && <strong>{t('Propagate validation')}</strong>}
                            </td>
                            <td className='td-sm'>
                                <i
                                    className='pi pi-trash pointer'
                                    onClick={e => confirm(d.id, e)}/>
                            </td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={4}>
                            <div className={'footer border md pd c'}>
                                <div className={'sm pd'}><strong>{t('Add requirement dependency')}</strong></div>
                                <div className='sm pd bg-gray r r-end'>
                                    {loading.renderBox()}
                                    <div className="p-inputgroup">
                                        <Select
                                            options={allRequirements.filter(d => d.isActive)}
                                            optionLabel='title'
                                            optionValue='id'
                                            value={selected}
                                            filter
                                            filterBy={'name'}
                                            onChange={e => setSelected(e.target.value)}/>
                                        <Select
                                            options={allRequirementDependencyTypes.current}
                                            optionLabel='name'
                                            optionValue='id'
                                            value={selectedDependencyType}
                                            onChange={e => setSelectedDependencyType(e.target.value)}
                                        />
                                        <Select
                                            options={allDependencyOptions.current}
                                            optionLabel='name'
                                            optionValue='id'
                                            value={dependencyOptions}
                                            onChange={e => setDependencyOptions(e.target.value)}/>

                                        <span className="p-inputgroup-addon">
                                            <i className="pointer pi pi-plus" onClick={_ => doAddRequirement()}/>
                                        </span>

                                        {message.render()}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        }
        <div className='footer r r-end'>
            <button onClick={() => props.onClose()}>{t('Cancel')}</button>
        </div>
    </div>;
}