import { Action, Reducer } from 'redux';
import {
} from '../models';
import * as WorkerActions from './actions/workers';
import * as ResourcesActions from './actions/resources';
import { actionCreators as N } from './notifications';

export interface IWorkerState {
}

// types
enum Types {
}

export type KnownAction = { type: undefined };

export const plainActions = {
    getWorkers: WorkerActions.getWorkers,
    getWorker: WorkerActions.getWorker,
    saveWorker: WorkerActions.saveWorker,
    reactivateWorker: WorkerActions.reactivateWorker,
    removeWorker: WorkerActions.removeWorker,
    restrictWorker: WorkerActions.restrictWorker,
    removeRestrictedWorker: WorkerActions.removeRestrictedWorker
}

export const actionCreators = {
};

export const reducer: Reducer<IWorkerState> = (state: IWorkerState | undefined, incomingAction: Action): IWorkerState => {
    if (state === undefined) {
        return {};
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        default:
            return state;
    }
};