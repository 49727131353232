import * as React from 'react';

import {
    BreadcrumbItem, ValidationBuilder, useDataTable,
    usePaginator, useTranslation,
    useMessage, useLoading, useForm, G, SplitButton,
    FieldsBox,
    useResolveName,
    useWorkFormSettings,
} from '@components';

import {
    IContractor, ISecurity, IWork, IPropertyGroupType, PropertyGroupObjectType,
    ICountry,
    IActivityType, ILegalForm, IConstants, IResourceStatusType, IUserIdentity, ResourceType, IFieldValidation, PropertyTypes, IOption, IPropertyGroup,
} from '@models';
import { DynamicProperties } from '@components/user/DynamicProperties';
import { CountryRegionSelect } from '@components/common/CountryRegionSelect';


import './ManageContractors.scss';

const ADD_PERMISSION = 'contractors.add';

const validateContractorF = (fieldValidations: IFieldValidation[] | undefined, work: IWork) => {
    return ValidationBuilder
        .new()
        .notEmpty('name', 'Name is required')
        .notEmptyIf(work.contractorSettings?.legalFormMandatory == true, 'legalFormId', 'Legal form is required')
        .notEmpty('code', 'Code is required')
        .notEmpty('address', 'Address is required')
        .notEmptyIf(work.id != 21, 'otherActivityType', 'Activity type is required')
        .notEmptyIf(work.id != 21, 'city', 'City is required')
        .notEmptyIf(work.id != 21, 'contactPerson', 'Contact person is required')
        .notEmpty('countryId', 'Country is required')
        .notEmptyIf(work.id != 21, 'phone', 'Phone is required')
        .notEmptyIf(work.id != 21, 'postalCode', 'Postal Code is required')
        .withFieldValidations(ResourceType.Contractor, fieldValidations)
        .lift();
}

export default function AddContractor({
    constants,
    activityTypes, legalForms, onChange, propertyGroupTypes, saveContractor, workId, security, fieldValidations, work
}: {
    activityTypes: IActivityType[],
    constants: IConstants,
    legalForms: ILegalForm[],
    onChange: Function,
    propertyGroupTypes: IPropertyGroupType[],
    saveContractor: Function,
    workId: number,
    security: ISecurity,
    fieldValidations: IFieldValidation[],
    work: IWork
}) {
    const { t } = useTranslation();
    const messages = useMessage({
        successMessage: t('Contractor added successfully'),
        autoClear: true,
    });

    const [selectedLegalForm, setSelectedLegalForm] = React.useState<ILegalForm>();

    const loading = useLoading();

    const validateContractor = validateContractorF(fieldValidations, work);

    const settings = useWorkFormSettings({ work: work, scope: 'contractor' });

    const form = useForm({
        initialValues: {
            properties: [{
                "value": "{}",
                "valueObj": {}
            }],
            workId: workId,
            isActive: true,
        },
        validateOnMount: true,
        validate: validateContractor
    });

    const calculateFormPropertiesAreValid = () => {

        const mandatoryPropertyIds: number[] = [];
        const mandatoryPropertyNames: string[] = [];

        //Si existen el formulario, evaluamos las propiedades dinámicas acordes a las existentes
        if (form.values.properties != undefined && form.values.properties.length > 0) {
            let hiddens: number = 0;
            if (propertyGroupTypes != undefined && propertyGroupTypes.length > 0) {
                const boolTextNames: string[] = [];
                propertyGroupTypes.map(g => {
                    // ¿Está ocultada esta propiedad por la forma legal y la propiedad hidable del grupo de propiedades?
                    const isHidden = selectedLegalForm?.hideContractorProperties && g.hidable;
                    if (!isHidden) {
                        if (g.mandatory) {
                            // Si el grupo de propiedades no está oculto y es obligatorio -> cargamos su ID y los nombres de sus propiedades
                            mandatoryPropertyIds.push(g.id!);
                            if (g.properties != undefined && g.properties.length > 0) {
                                g.properties.map(p => {
                                    mandatoryPropertyNames.push(p.name);
                                    // Si alguna de las propiedades es de tipo BoolText, la almacenamos
                                    if (p.typeId == PropertyTypes.BOOLTEXT) {
                                        boolTextNames.push(p.name);
                                    }
                                })
                            }
                        }
                    }
                    else {
                        // Si está ocultada, ya sea por:
                        //      1- El grupo de propiedades no es ocultable
                        //      2- La forma legal no oculta las propiedades
                        // -> La contabilizamos
                        hiddens += 1;
                    }
                });
                if (mandatoryPropertyIds != undefined && mandatoryPropertyIds.length > 0) {
                    const properties: any[] = [];

                    // Evaluamos cuáles son las propiedades del formulario que son obligatorias
                    form.values.properties.map((p: any) => {
                        mandatoryPropertyIds.map(m => {
                            if (p.propertyGroupTypeId == m) {
                                properties.push(p);
                            }
                        })
                    });
                    if (properties != undefined && properties.length > 0) {
                        const values: boolean[] = []

                        properties.map(p => {
                            // Si cualquiera de estas propiedades tiene valor, lo evaluamos
                            if (p.valueObj != "{}") {
                                mandatoryPropertyNames.map(m => {
                                    // Evaluamos si los BoolTexts hallados tienen valor de texto
                                    if (boolTextNames.includes(m)) {
                                        boolTextNames.map(b => {
                                            if (b == m) {
                                                const valueText = p.valueObj[m + "_text"];
                                                if (valueText == "" || valueText == undefined) {
                                                    values.push(false);
                                                }
                                                else {
                                                    values.push(true);
                                                }
                                            }
                                        })
                                    }
                                    // Obtenemos su valor teniendo en cuenta que undefined es false
                                    else {
                                        const value = p.valueObj[m];
                                        if (value != undefined) {
                                            values.push(value);
                                        }
                                        else {
                                            values.push(false);
                                        }
                                    }
                                })
                            }
                            else {
                                values.push(false);
                            }
                        });
                        // Si existe algún true en el array, entonces el grupo de propiedades es válido
                        if (values.some(v => v)) {
                            propertiesInvalid.current = false;
                        }
                        else {
                            if (hiddens < propertyGroupTypes.length) {
                                propertiesInvalid.current = true;
                            }
                        }
                    }
                }
                if (hiddens == propertyGroupTypes.filter(g => g.mandatory).length) {
                    propertiesInvalid.current = false;
                }
            }
        }
    }

    const calculateProperties = () => {
        const values: IPropertyGroup[] = form.values.properties;
        let newValues: IPropertyGroup[] = [];
        if (selectedLegalForm?.hideContractorProperties) {
            values.map((p: IPropertyGroup) => {
                if (propertyGroupTypes != undefined && propertyGroupTypes.find(g => g.id == p.propertyGroupTypeId)?.hidable) {
                    newValues = values.filter(v => v.propertyGroupTypeId == undefined && v.propertyGroupTypeId != p.propertyGroupTypeId && v.value != "{}");
                }
            });
            return newValues;
        }
        newValues = values.filter(v => v.propertyGroupTypeId != undefined && v.value != "{}")

        return newValues;
    }

    const doAdd = loading.wrap(async (..._: any) => {

        const contractorData: IContractor = {
            activityTypeId: form.values.activityTypeId,
            code: form.values.code,
            legalFormId: form.values.legalFormId,
            name: form.values.name,
            properties: calculateProperties(),
            workId: workId,
            address: form.values.address,
            countryId: form.values.countryId,
            countyId: form.values.countyId,
            remarks: form.values.remarks,
            id: 0,
            providerCode: form.values.providerCode,
            postalCode: form.values.postalCode,
            city: form.values.city,
            contactPerson: form.values.contactPerson,
            phone: form.values.phone,
            otherActivityType: form.values.otherActivityType,
        }

        const resp = await saveContractor(workId, contractorData);

        let respMessage = resp;
        if (respMessage.error == 'restricted.contractor.message') {
            respMessage.error = `${t('restricted.contractor.message')} ${form.values.code}`;
        }

        messages.set(respMessage);

        if (resp.hasValue) {
            form.clear();
            onChange(true);
        }
    });

    const propertiesInvalid = React.useRef<boolean>();

    const userCanAdd = security.hasPermission(ADD_PERMISSION, false);

    const canAdd = form.isValid() && userCanAdd;

    const updateRegion = ({ countryId, regionId, countyId }: any) => {
        form.setFieldValue('countryId', countryId);
        form.setFieldValue('regionId', regionId);
        form.setFieldValue('countyId', countyId);
    }

    function loadRegions(workId: number) {
        let countries: ICountry[] = [];
        constants.countries.forEach(c => countries.push(Object.assign({}, c)));
        if (workId != 21) {
            for (let c in countries) {
                if (countries[c].regions.length > 0) {
                    countries[c].regions = [];
                }
            }
        }
        return countries;
    }

    React.useEffect(() => {
        if (legalForms != undefined) {
            const lf = legalForms.find(l => l.id == form.values.legalFormId);
            if (lf != undefined) {
                setSelectedLegalForm(lf);
            }
        }
    }, [form.values.legalFormId])

    React.useEffect(() => {
        calculateFormPropertiesAreValid();
    }, [form.values.properties, selectedLegalForm])

    if (userCanAdd) {
        return <div className='c lg pd form-1 l200 he'>
            <G label={t('Contractor.Name')}>
                {form.input('name')}
            </G>
            <G label={workId == 21 ? t('contractor.code') : t('CIF')}>
                {form.input('code')}
            </G>
            {work.contractorSettings?.legalFormMandatory == true
                ? <G label={t('Legal form')}>
                    {form.select('legalFormId', { options: legalForms, filter: true })}
                </G>
                : undefined
            }
            <G label={t('Provider code')}>
                {form.input('providerCode')}
            </G>
            {settings.show('activityTypeId') &&
                <G label={t('Activity type')}>
                    {form.select('activityTypeId', activityTypes)}
                </G>}

            {workId != 21 &&
                <G label={t('Activity type')}>
                    {form.input('otherActivityType')}
                </G>}
            <FieldsBox title={t('Address')} collapsible>
                <G label={t('Address')}>
                    {form.input('address')}
                </G>
                <G label={t('Postal code')}>
                    {form.input('postalCode')}
                </G>
                <G label={workId == 21 ? t('Region') : t('Country')}>
                    <div className='c'>
                        <CountryRegionSelect
                            countries={loadRegions(workId)}
                            data={form.values}
                            onChange={updateRegion} />
                        {form.getFormErrorMessage('countryId')}
                    </div>
                </G>
                <G label={t('City')}>
                    {form.input('city')}
                </G>
                {workId != 21 &&
                    <G label={t('Contact person')}>
                        {form.input('contactPerson')}
                    </G>}
                <G label={t('Phone')}>
                    {form.input('phone')}
                </G>
            </FieldsBox>
            <G label={t('Remarks')} className='he'>
                {form.textarea('remarks', { rows: 5, style: { resize: 'vertical', minHeight: '85px' }, containerStyle: { height: '100%' } })}
            </G>
            <div id={'embed-properties'} >
                <DynamicProperties
                    object={form.values}
                    onChange={p => form.setFieldValue('properties', p)}
                    hideContractorProperties={selectedLegalForm?.hideContractorProperties}
                    objectType={PropertyGroupObjectType.JobHasContractor}
                    propertyGroupTypes={propertyGroupTypes} />
                {propertiesInvalid.current && <small className='p-error right-align'>{t('dynamic.properties.error')}</small>}
            </div>


            {form.errorBox()}
            {messages.render()}

            <div className='r'>
                <span className='e' />
                {loading.render()}
                <button
                    className='primary'
                    disabled={!canAdd || propertiesInvalid.current}
                    onClick={doAdd}>
                    {t('Add')}
                </button>
            </div>
        </div >
    }
    else {
        return null;
    }
}