import * as React from 'react';

import { BreadcrumbItem, useRemoteData } from '@components';
import { JobPanelActions } from '@components/jobs/JobPanelActions';
import { goToJob, goToJobCard } from '@components/routes';
import { IContractor, IJob, IJobHasContractor, IJobStatus, ISecurity, IWork, ModuleManager } from '@models';
import './JobComponent.scss';
import JobRoutes from '@containers/jobs/JobRoutes';

type GetJobStatusT = (workId: number, jobId: number) => Promise<IJobStatus[]>;

export interface IProps {
    getJobStatus: GetJobStatusT;
    job: IJob;
    loadJob: Function;
    security: ISecurity;
    clearSelectedContractor: Function;
    selectedContractor?: IContractor;
    selectedJobHasContractor?: IJobHasContractor;
    selectedJobHasContractorId?: number;
    selectJobHasContractor: Function;
    work: IWork;
    getContractorFromAppUser: Function;
    getRequirements: Function;
    moduleManager: ModuleManager;
}

export function JobComponent(props: IProps) {
    if (!props.job) {
        return null;
    }

    // if (props.selectedJobHasContractor == undefined && props.selectedJobHasContractorId) {
    //     props.selectJobHasContractor(props.job.contractors.find(c => c.id == props.selectedJobHasContractorId));
    // }

    const status = useRemoteData<IJobStatus[]>(
        props.getJobStatus, { parameters: [props.work.id, props.job.id] });

    const loadData = async () => {
        await status.query();
        goToJobCard(props.work.id, props.job.id);
    }

    return <div className='JobComponent'>
        <BreadcrumbItem
            text={props.job.code}
            onClick={() => loadData()} />

        <JobRoutes job={props.job} work={props.work} />

        <JobPanelActions
            job={props.job}
            jobSettings={props.work.jobSettings}
            jobStatus={status.value ?? []}
            clearSelectedContractor={props.clearSelectedContractor}
            selectedJobHasContractor={props.selectedJobHasContractor ?? undefined}
            reload={loadData}
            security={props.security}
            getContractorFromAppUser={props.getContractorFromAppUser}
            workId={props.work.id}
            work={props.work}
            moduleManager={props.moduleManager}
            getRequirements={props.getRequirements}
            selectedContractor={undefined}>
        </JobPanelActions>
    </div>;
}
