import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from './';

import { isDebug } from '../Constants';

function logger(store: any) {
    return function dispatchAndLog(dispatch: any) {
        console.log('prev state', store.getState())
        return (action: any) => {
            console.log('action', action);
            dispatch(action);
            console.log('new state', store.getState());
        };
    }
}

export default function configureStore(history: History, initialState?: ApplicationState) {
    const middleware: any = [
        thunk,
        // isDebug ? logger : undefined,
        routerMiddleware(history)
    ].filter(identity => identity !== undefined);

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
