import * as React from 'react';

export interface IProps {
    label: string;
    labelClassName?: string;
    children: any;
}

export function F({label, labelClassName, children}: IProps) {
    return <React.Fragment>
        <label className={labelClassName}>{label}</label>
        {children}
    </React.Fragment>
}