import * as React from 'react';

import { useForm, useMessage, useTranslation, G, ValidationBuilder, SplitButton } from '@components';
import { createFieldValidation, fieldValidationResourceTypes, IFieldValidation, ResourceType } from '@models';

import './FieldValidationForm.scss';

export interface IProps {
    data?: IFieldValidation;
    onCancel?: Function;
    onSave?: Function;
    workId: number;
}

const validate = (d: Partial<IFieldValidation>) =>
    ValidationBuilder.create(d)
        .notEmpty('name')
        .notEmpty('resourceType')
        .notEmpty('field')
        .notEmpty('errorMessage')
        .build();

const decodeExpression = (e: string|undefined) => {
    if (e && e != '') {
        return atob(e);
    }
    else {
        return undefined;
    }
}

const encodeExpression = (e: string|undefined) => {
    if (e && e != '') {
        return btoa(e);
    }
    else {
        return undefined;
    }
}

export function FieldValidationForm(props: IProps) {
    const { t } = useTranslation();
    const messages = useMessage();

    const [expression, setExpression] = React.useState<string>(
        decodeExpression(props.data?.value) ?? '');
    const [result, setResult] = React.useState<string|undefined>();
    const [testExpression, setTestExpression] = React.useState<string>('');

    const form = useForm<Partial<IFieldValidation>>({
        initialValues: props.data ?? { workId: props.workId },
        validate,
    });

    React.useEffect(() => {
        form.setFieldValue('value', encodeExpression(expression));
    }, [expression]);

    const DNI = `
i => {
    let numero, let_, letra;
    const dniRegexp = /^[XYZ]?\\d{5,8}[A-Z]$/;

    const dni = i.code.toUpperCase();

    if (dniRegexp.test(dni) === true) {
        numero = dni.substr(0, dni.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);

        let_ = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero+1);
        if (letra != let_) {
            return false;
        } else {
            return true;
        }
    }
    else {
        return false;
    }
}
`;

    const CIF = `i => {
        const cif = i.code.toUpperCase();

        if (!cif || cif.length < 4) {
            return false;
        }
        else {
            return true;
        }
    }`;

    //STOP!!!!

    const actions = [
        { label: t('DNI'), command: () => setExpression(DNI)},
        { label: t('CIF'), command: () => setExpression(CIF)}
    ]

    const doTestExpression = () => {
        try {
            const f = createFieldValidation(form.values.value);
            const res = f.call(null, JSON.parse(testExpression));
            setResult(JSON.stringify(res));
            messages.set({hasValue: true, value: res});
        }
        catch (e) {
            console.log(e);
            messages.setError(e + '');
        }
    }

    return <div className={'FieldValidationForm'}>
        <div className={'r md pd'}>
            <div className={'form-1 l200 md pd c'}>
                <G label={t('name')}>
                    {form.input('name', { autoFocus: true })}
                </G>
                <G label={t('Field')}>
                    {form.input('field')}
                </G>
                <G label={t('ResourceType')}>
                    {form.select('resourceType', fieldValidationResourceTypes)}
                </G>
                <G label={t('ErrorMessage')}>
                    {form.input('errorMessage', { autoFocus: true })}
                </G>
            </div>
            <div className={'c validation-block'}>
                <div className='header'>
                    <strong>{t('Validation')}</strong>
                    <span className={'e'} />
                    <SplitButton className='transparent' model={actions}/>
                </div>
                <textarea
                    value={expression}
                    onChange={e => setExpression(e.target.value)} />
            </div>
        </div>
        <div className={'test-block r md pd border-top'}>
            <span className={'e'} />
            <input
                className={'flat-right br-l'}
                type='text'
                placeholder={t('test.input.expression')}
                value={testExpression}
                onChange={e => setTestExpression(e.target.value)} />

            <button className={'button y flat-left br-r'} onClick={doTestExpression}>
                {t('test')}
            </button>
        </div>

        <div className='md pd center'>
            {messages.render()}
        </div>

        {result && <pre className={'result'}>
            {result}
        </pre>}

        <div className={'footer r r-end'}>
            {props.onCancel &&
                <button onClick={() => props.onCancel?.()}>{t('Cancel')}</button>}
            {props.onSave &&
                <button className='primary'
                        onClick={() => props.onSave?.(form.values)}>{t('Save')}</button>}
        </div>
    </div>
}