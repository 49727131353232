import * as React from 'react';

import { useForm, useLoading, useMessage, useTranslation } from '@components';
import { ResourceType } from '@models/resources';
import { ISecurity, ResourceStatusType } from '@models';

export interface IProps {
    workId: number;
    requestClose: Function;
    resourceType: ResourceType,
    resourceId: number,
    forceResourceStatus: Function,
    security: ISecurity,
    currentStatusType?: number,
}

type ForceStatusData = {
    untilDate?: Date,
}

export function ForceValidationResource(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const messages = useMessage();
    const form = useForm<ForceStatusData>({
        initialValues: {}
    });

    const validate = loading.wrap(async (valid: boolean = true) => {
        const resp = await props.forceResourceStatus(
            props.workId,
            props.resourceType,
            props.resourceId,
            valid,
            form.values.untilDate);
        messages.set(resp);

        if (resp.hasValue && resp.value) {
            props.requestClose(true);
        }
    });

    const showAuthorize = props.currentStatusType == undefined
        || [ResourceStatusType.FORCED_REVOKED,
        ResourceStatusType.NOT_AUTHORIZED].includes(props.currentStatusType);

    const showRevoke = props.currentStatusType == undefined
        || [ResourceStatusType.FORCED_AUTHORIZED,
        ResourceStatusType.AUTHORIZED].includes(props.currentStatusType);

    return <div className='c'>
        <div className='r footer r-end'>
            {loading.render()}
            <button onClick={() => props.requestClose(true)}>
                {t('Close')}
            </button>
            {showRevoke &&
                <button className='danger' onClick={() => validate(false)}>
                    {t('Deauthorize')}
                </button>}
            {showAuthorize &&
                <button className='success' onClick={() => validate(true)}>
                    {t('Authorize')}
                </button>}
        </div>
    </div>
}
