import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

import { Loading } from '@components';
import { redirectTo } from '@utils';

const Loader = (props: any) => {
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        const work = props.state.user.work;
        if (work) {
            setLoading(false);
            redirectTo(`/modules/${work.id}/${props.moduleName}/${props.moduleView}`);
        }
    }, [props.state.work.work]);

    if (loading) {
        return <Loading />;
    }
    else {
        return null;
    }
}

export default connect(
    (state: ApplicationState, props: any) => ({
      state,
      moduleName: props.match.params.module,
      moduleView: props.match.params.view,
      params: props,
    }),
    { },
  )(Loader as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any