import * as React from 'react';

import { R, useForm, useTranslation, useLoading, Message } from '@components';
import { IRequirement, IRequirementType, IRequirementValidationData } from '@models/requirements';
import { IOption, IUserIdentity } from '@models';
import dateUtils from '@utils/date-utils';

type AcceptT = (requirement: IRequirement, data: IRequirementValidationData) => Promise<IOption<boolean>>;
type RequirementCanBeValidatedF = (workId: number, requirementId: number) => Promise<boolean>;

export interface IProps {
    loading?: boolean;
    requestAccept: AcceptT;
    requestClose: Function;
    requirement: IRequirement;
    requirementType: IRequirementType;
    workId: number;
    requirementCanBeValidated: RequirementCanBeValidatedF;
    user: IUserIdentity;
}

export function AcceptRequirement(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading(true);
    const [canBeValidated, setCanBeValidated] = React.useState(false);

    const initialize = loading.wrap(async () => {
        const canBeValidated = await props.requirementCanBeValidated(
            props.requirement.workId,
            props.requirement.id);
        setCanBeValidated(canBeValidated || props.user.roles.includes('gestor'));
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const form = useForm<IRequirementValidationData>({
        initialValues: {
            remarks: '',
            expirationThreshold: (props.requirementType?.expirationThreshold),
            expirationDate: dateUtils.toUtc(props.requirement.expirationDate ?? undefined),
        },
    });

    const doAccept = () => {
        props.requestAccept(props.requirement, form.values);
    }

    const suggestions = (props.requirementType?.suggestions ?? []).map(s => s.name);

    const deleteDate = () => {
        form.setFieldValue('expirationDate', undefined);
    }

    return <div>
        <div className='form-1 l200 sm pd'>
            <R label={t('requirements.observations')}>
                {form.autoCompleteTextArea(
                    'remarks',
                    {suggestions, style: {minWidth: '400px', minHeight: '150px'}})}
            </R>
            <div className='ValidateDate'>
            <R label={t('Expiration date')}>
                {form.calendar('expirationDate')}
                <i className='pi pi-trash pointer' title={t('delete.calendar')}
                        onClick={deleteDate}/>
            </R>
            </div>
            <R label={t('Tolerance')}>
                {form.input('expirationThreshold', {type: 'number'})}
            </R>
        </div>
        {!canBeValidated &&
            <div className='sm pd text-sm r'>
                <Message
                    className='e'
                    severity={'error'}
                    text={t('requirement.canNotBeValidated')} />
            </div>}
        <div className='footer r r-end'>
            <button onClick={() => props.requestClose()}>{t('Cancel')}</button>
            {canBeValidated && <button disabled={props.loading} onClick={() => doAccept()} className='success'>{t('Validate')}</button>}
        </div>
    </div>
}
