import * as React from 'react';

import { useForm, useLoading, useToast, useTranslation, ValidationBuilder, G } from '@components';
import { delay, redirectTo } from '@utils';
import { IChangePasswordData } from '@models/forms';

export interface IProps {
    token: string;
    changeUserPassword: Function;
}

type RecoverData = {
    email: string,
    password: string,
    passwordConfirm: string,
    userName: string
}

export function RecoverPassword(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();
    const toast = useToast();

    const form = useForm<RecoverData>({
        initialValues: {
            email: '',
            password: '',
            passwordConfirm: '',
            userName: '',
        },
        validateOnMount: true,
        validate: data => ValidationBuilder.create(data)
            .notEmpty('email')
            .email('email')
            .notEmpty('password')
            .minLength('password', 4)
            .notEmpty('userName')
            .match('passwordConfirm', data.password, 'Passwords do not match')
            .build()
    });

    const changePassword = loading.wrap(async () => {
        const data: IChangePasswordData = {
            email: form.values.email,
            token: window.location.href.split('recover/')[1],
            password: form.values.password,
            userName: form.values.userName
        };

        const resp = await props.changeUserPassword(data);
        form.setFieldValue('email', '');
        if (resp.isError) {
            toast.error(t(resp.error));
        }
        else {
            toast.show(t('Password changed successfully'));
        await delay(2000);
        redirectTo('/');
        }
    });

    return <div className='c md pd center'>
        {toast.render()}
        <div className='form-1 l400'>
            <strong className='g'>{t('password.reset')}</strong>
            <G label={t('Email')}>
                {form.input('email', {type: 'email'})}
            </G>
            <G label={t('Username')}>
                {form.input('userName')}
            </G>
            <G label={t('Password')}>
                {form.input('password', {type: 'password'})}
            </G>
            <G label={t('Password confirmation')}>
                {form.input('passwordConfirm', {type: 'password'})}
            </G>
            <div className='r r-end'>
                {loading.render()}
                <button
                    disabled={form.isInvalid()}
                    className='primary'
                    onClick={changePassword}>{t('Change password')}</button>
            </div>
        </div>
    </div>
}