import * as React from 'react';

import { searchExportToXls } from '@store/actions/search';
import {
    ConfirmDialog, Message, useConfirm, useDataTable, useFormDialog, useInputSearch, useLoading, useMessage, useSearch, useSearchFilters, useTranslation
} from '@components';
import { IPropertyGroupType, isRRHHPropertyGroupType, IWork } from '@models';
import { IDepartment, IWorker, IWorkerWithRelations } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { WorkerForm } from './WorkerForm';
import { WorkToolbar } from './WorkToolbar';
import './AdminWorkWorkers.scss'
import i18next from 'i18next';
import { downloadBlob } from '@utils/file-utils';

type WorkerRow = IWorker & {
    departments: string;
    userEmail: string;
    workspaces: string;
    userName?: string;
    policies?: string[];
};

export interface IProps {
    loadUsers?: Function;
    propertyGroupTypes: IPropertyGroupType[];
    work: IWork;
    reactivateWorker: Function;
    getWorkDepartments: Function;
}

export function AdminWorkWorkers(props: IProps) {
    const { t } = useTranslation();

    const propertyGroupTypes =
        props.work?.propertyGroupTypes?.filter(isRRHHPropertyGroupType) ?? [];

    const [departments, setDepartments] = React.useState<IDepartment[]>([]);

    const workspaces = props.work.workspaces;
    const legalForms = props.work.legalForms;

    const workId = props.work.id;

    const [error, setError] = React.useState<string | undefined>();
    const loading = useLoading(true);
    const formDialog = useFormDialog<IWorker>({
        editTitle: t('rrhh.worker.edit'),
        addTitle: t('rrhh.worker.add'),
    });
    const message = useMessage();
    const [showConfirmDeactivate, setShowConfirmDeactivate] = React.useState<IWorker | undefined>();
    const [showConfirmActivate, setShowConfirmActivate] = React.useState<IWorker | undefined>();
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IWorker | undefined>();

    const userFilters = useSearchFilters({
        workId: workId,
        name: 'admin.workers',
        references: {
            workspaces: workspaces,
            departments: departments,
        },
        persist: true,
    });

    const initialize = loading.wrap(async () => {
        const departments = await props.getWorkDepartments(workId);
        setDepartments(departments ?? []);
        data.doSearch();
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const refresh = async () => {
        await initialize();
        if (formDialog.currentEdit) {
            const s = data.value.find(c => c.id == formDialog.currentEdit?.id);
            formDialog.showEdit(s!);
            formDialog.clear();
        }

        if (props.loadUsers) {
            props.loadUsers();
        }
    }

    const saveWorker = async (w: IWorkerWithRelations) => {
        const res = await Actions.saveWorker(w);
        message.set(res);
        await initialize();

        formDialog.clear();
    }

    const removeWorker = async (id: number, workId: number, remove: boolean) => {
        setError(undefined);
        await Actions.removeWorker(id, workId, remove);
        await initialize();

        formDialog.clear();
    }

    const filterKey = `filters.admin.work.workers[workId=${workId}]`;

    const filter = useInputSearch({ filterKey });

    const data = useSearch<WorkerRow>({
        workId: workId,
        search: 'admin.workers',
        normalizeKeys: true,
        filters: userFilters.merge({ name: filter.value, workId: workId }),
        dependencies: [filter.value, userFilters.filters],
    });

    const exportToXls = async (filters: any, filename: string | undefined = undefined) => {
        const resp = await searchExportToXls(workId, 'admin.workers', filters, i18next.languages[0]);
        downloadBlob(resp, filename ?? (`admin.workers.${workId}` + '.xlsx') ?? 'export.xlsx');
    }

    const doActivateWorker = loading.wrap(async (w: WorkerRow) => {
        await props.reactivateWorker(w.workId, w.id);
        await refresh();
    });

    const dataTable = useDataTable({
        data: data.value,
        className: 'e fixed-header-t36 fixed-table',
        columns: [
            {
                className: 'td-md', sortKey: 'id', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.id.toString()}>{w.id}</span>
                    </div>
                }
            },
            {
                title: 'Name', className: 'center', sortKey: 'name', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.name}>{w.name}</span>
                    </div>
                }
            },
            {
                title: 'Surname', className: 'center', sortKey: 'surname', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.surname}>{w.surname}</span>
                    </div>
                }
            },
            {
                title: 'Phone', className: 'td-md', sortKey: 'phone', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.phone}>{w.phone}</span>
                    </div>
                }
            },
            {
                title: 'License number', className: 'td-lg', sortKey: 'code', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.code}>{w.code}</span>
                    </div>
                }
            },
            {
                title: 'Workspaces', className: 'center', sortKey: 'workspaces', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.workspaces}>{w.workspaces}</span>
                    </div>
                }
            },
            {
                title: 'Departments', className: 'center', sortKey: 'departments', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.departments}>{w.departments}</span>
                    </div>
                }
            },
            {
                title: 'Policies', className: 'center', sortKey: 'policies', render: (w: WorkerRow) => {

                    return <div>
                        {w.policies && <span title={w.policies?.join(', ')}>{w.policies?.join(', ')}</span>}
                    </div>
                }
            },
            {
                title: 'UserName', sortKey: 'userName', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.userName}>{w.userName}</span>
                    </div>
                }
            },
            {
                title: 'Email', sortKey: 'userEmail', render: (w: WorkerRow) => {
                    return <div>
                        <span title={w.userEmail}>{w.userEmail}</span>
                    </div>
                }
            },
        ],
        actions: [
            { icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit },
            {
                icon: (w: WorkerRow) => w.isActive ? 'fas fa-eye-slash' : 'fas fa-check',
                tooltip: (w: WorkerRow) => w.isActive ? t('resource.deactivate') : t('resource.activate'),
                onClick: (w: WorkerRow) => w.isActive ? setShowConfirmDeactivate(w) : setShowConfirmActivate(w)
            },
            { icon: 'fas fa-trash', tooltip: t('Remove'), onClick: (w: WorkerRow) => setShowConfirmRemove(w) },
        ]
    });

    return <div className='c-sc'>
        <WorkToolbar work={props.work}>
            {loading.render()}
            <button
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right' />
                {t('rrhh.worker.add')}
            </button>
            <button
                onClick={() => exportToXls(userFilters.merge({ workId: props.work.id }))}>
                <i className='pi pi-file-excel sm pd-right' />
                {t('Export to Excel')}
            </button>
        </WorkToolbar>
        {message.renderIfError()}

        {showConfirmDeactivate && <ConfirmDialog
            visible
            onHide={() => setShowConfirmDeactivate(undefined)}
            message={`${t('Are you sure to deactivate the element ?')} ${showConfirmDeactivate.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmDeactivate(undefined)}
            accept={() => removeWorker(showConfirmDeactivate.id, showConfirmDeactivate.workId, false)} />}

        {showConfirmActivate && <ConfirmDialog
            visible
            onHide={() => setShowConfirmActivate(undefined)}
            message={`${t('reactivate.user.confirm.message')} ${showConfirmActivate.name} ${showConfirmActivate.surname ?? ''} ?`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Reactivate')}
            reject={() => setShowConfirmActivate(undefined)}
            accept={() => doActivateWorker(showConfirmActivate)} />}

        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmDeactivate(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmDeactivate(undefined)}
            accept={() => removeWorker(showConfirmRemove.id, showConfirmRemove.workId, true)} />}

        {formDialog.render((data: IWorker) =>
            <WorkerForm
                data={data}
                workId={workId}
                notifications={[]}
                propertyGroupTypes={propertyGroupTypes}
                onSubmit={saveWorker}
                onChange={refresh}
                footer={formDialog.Footer()}
                workspaces={workspaces}
                legalForms={legalForms} />)}

        {error && <Message severity='error' text={error} />}


        <div className='h100p c he'>
            <div className='c he r'>
                {<div className='p-inputgroup toolbar'>
                    {filter.render()}
                    <userFilters.Component />
                </div>}
            </div>
        </div>
        {dataTable()}
    </div>
}
