import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { addLocale } from 'primereact/api';

const es = require('./i18n/es.json');
const en = require('./i18n/en.json');
const it = require('./i18n/it.json');

const createOriginalResources = () => ({
    it: {
        translation: it
    },
    en: {
        translation: en
    },
    es: {
        translation: es
    }
}) as any;

const loadResources = (resources: any) => {
    let locale = navigator.language;
    let langCookie = document.cookie.split(';').find((item) => item.includes('lang'));

    if (langCookie) {
        locale = langCookie.split('=')[1];
    } else if (locale) {
        const language = locale.split('-')[0];
        locale = language;
    }
    else {
        locale = 'es';
    }

    i18n.use(initReactI18next).init({
        resources,
        lng: locale,
        interpolation: {
            escapeValue: false
        }
    });
}

const resources = createOriginalResources();
loadResources(resources);

const get = (...keys: string[]) => {
    const r = i18n.getDataByLanguage(i18n.language)?.translation;

    return keys.reduce((acc, key) => {
        return acc + ' ' + (r ? (r[key] ?? key) : key);
    }, '');
}

const installResources = (resources: any, moduleKeys: any | undefined = undefined) => {
    const newResources = createOriginalResources();

    for (const l of Object.keys(newResources)) {
        const keys = resources.filter((r: any) => r.language == l);
        if (keys.length > 0) {
            const newLangResources: any = {};
            for (const k of keys) {
                newLangResources[k.name] = k.value;
            }
            newResources[l].translation = {
                ...newResources[l].translation,
                ...newLangResources
            };
        }
    }

    if (moduleKeys != undefined) {
        for (const l of Object.keys(moduleKeys)) {
            const keys = moduleKeys[l];
            const newLangResources: any = {};
            for (const k in keys) {
                newLangResources[k] = keys[k];
            }
            newResources[l].translation = {
                ...newResources[l].translation,
                ...newLangResources
            };
            //            console.log('install module keys', newLangResources);
        }
    }

    loadResources(newResources);
}

const languages = ['es', 'en', 'it'];
const defaultLanguage = 'es';

const currentLocale = () => i18n.language;

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
});

addLocale('it', {
    firstDayOfWeek: 1,
    dayNames: ['domenica', 'lunedi', 'martedi', 'miercoledi', 'giovedi', 'venerdi', 'sabato'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mie', 'gio', 'ven', 'sab'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'G', 'V', 'S'],
    monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
    monthNamesShort: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
    today: 'Oggi',
    clear: 'Chiaro'
});

addLocale('it', {
    firstDayOfWeek: 1,
    dayNames: ['domenica', 'lunedi', 'martedi', 'miercoledi', 'giovedi', 'venerdi', 'sabato'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mie', 'gio', 'ven', 'sab'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'G', 'V', 'S'],
    monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
    monthNamesShort: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
    today: 'Oggi',
    clear: 'Chiaro'
});

export {
    currentLocale,
    defaultLanguage,
    get,
    installResources,
    languages,
}

export default i18n;
