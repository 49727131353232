import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as Admin from '@store/admin';

import { AdminWorkWorkers } from '@components/admin/AdminWorkWorkers';
import * as WorkersStore from '../../store/workers';

export default connect(
  (state: ApplicationState) => ({
    ...WorkersStore.plainActions,
    ...Admin.plainActions,
  }),
  Admin.actionCreators,
)(AdminWorkWorkers as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
