import * as React from 'react';

import { useDialogs } from '@utils/hooks';
import RemoteHtml from './custom/RemoteHtml';
import "./cookies.scss";
import { locale } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import { redirectToExternalNewTab } from '@utils';
import { currentLocale } from '../i18n';
export interface IProps { }

const COOKIE_NAME = 'gstore-cookie-policy';

export default function Cookies(props: IProps) {
    const [showCookies, setShowCookies] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const dialogs = useDialogs();

    const locale = currentLocale();

    const acceptPrivacyConset = async () => {
        const resp = await fetch('/api/privacy/accept');
        if (resp.status == 200) {
            setShowCookies(false);
        }
    }

    const showInfo = () => {
        locale == 'es' ?
        redirectToExternalNewTab('https://cae-gstore.com/politica-de-cookies/') :
        dialogs.show('info');
    }

    // Cuando cargamos el componente buscamos la cookie del consentimiento de privacidad
    React.useEffect(() => {
        const c = !document.cookie.includes(COOKIE_NAME);
        setShowCookies(c);
    }, []);

    if (showCookies) {
        return <div id="cookiesBanner">
            <h3>{t('cookie.message')}</h3>
            {dialogs.render('info', { title: t('cookie.politic.title') }, () => <RemoteHtml resource='CookiePolicy.html' useI18n />)}
            <div className='buttonContainer'>
                <button onClick={showInfo}>{t('cookie.info.button')}</button>
                <button className="primary" onClick={acceptPrivacyConset}>{t('Accept')}</button>
            </div>
        </div>
    }
    else {
        return null;
    }
}