import * as React from 'react';

import {
    BreadcrumbItem, Panel, useRemoteData, useTranslation, useToast,
    useLoading, useDialogs, Portal, RequirePermission
} from '@components';
import {
    IActivityType, IConstants, IContractor, ILegalForm, IPropertyGroupType, IResourceStatusType, ISecurity, IWork,
    ResourceType
} from '@models';
import { goToContractor, goToContractorAccessData, goToContractorRequirements, goToContractors } from '@components/routes';
import './EditContractor.scss';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import { ViewResourceHistory } from '@components/requirements/ViewResourceHistory';
import { ModuleManager } from '@modules';

type GetContractorF = (workId: number, id: number) => Promise<IContractor>;

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    legalForms: ILegalForm[];
    activityTypes: IActivityType[];
    constants: IConstants;
    children?: any;
    getContractor: GetContractorF;
    work: IWork;
    modules: ModuleManager;
    contractor: IContractor;
    contractorId: number;
    security: ISecurity;
    saveContractor: Function;
    resourceStatusTypes: IResourceStatusType[];
    showRequirements?: boolean;
}

function EditContractorInner(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const toast = useToast();
    const dialogs = useDialogs();

    props.modules.injectComponents(
        'contractor.edit',
        props,
        [props.contractorId]);

    return <div className='EditContractorContainer'>
        <BreadcrumbItem
            text={t('Contractors')}
            onClick={() => goToContractors(props.work.id)} />

        <BreadcrumbItem
            text={props.contractor.name} />

        <Portal container={'#breadcrumb-right'}>
            {loading.render()}
        </Portal>

        {toast.render()}

        {props.contractor &&
            <div className='form-container'>
                {props.children}

                <div className='actions'>
                    <Panel header={<div className={'r v-center'}>
                        <i className="fas fa-id-card-alt" />
                        <span className='e' />
                        <span>{props.contractor.name}</span>
                    </div>} className='buttons'>
                        <div id="edit-contractor-panel-before" style={{ margin: "auto" }}></div>
                        <div className='c'>
                            <button className='plain rounded'
                                onClick={() => goToContractor(props.work.id, props.contractorId)}>
                                <i className='fas fa-table' />
                                <span className='e'>{t('View contractor data')}</span>
                            </button>

                            <RequirePermission permission='contractors.access' explicit={false}>
                                <button className='plain rounded' onClick={() => goToContractorAccessData(props.work.id, props.contractorId)}>
                                    <i className='fas fa-user-lock' />
                                    <span className='e'>{t('Access data')}</span>
                                </button>
                            </RequirePermission>
                            <button className='plain rounded' onClick={() => goToContractorRequirements(props.work.id, props.contractorId)}>
                                <i className='fas fa-folder' />
                                <span className='e'>{t('View requirements')}</span>
                            </button>
                        </div>
                    </Panel>
                    {dialogs.render('force-validation', { title: t('Force resource validation'), className: 'g pd' }, () =>
                        <ForceValidationResourceContainer
                            workId={props.work.id}
                            requestClose={dialogs.clear}
                            currentStatusType={props.contractor.currentStatusType}
                            resourceType={ResourceType.Contractor}
                            resourceId={props.contractor.id} />
                    )}
                    {dialogs.render('view-history', { title: t('View history'), className: 'g pd' }, () =>
                        <ViewResourceHistory
                            requestClose={dialogs.clear}
                            resourceId={props.contractor.id}
                            resourceType={ResourceType.Contractor}
                            workId={props.work.id} />
                    )}
                </div>
            </div>}
    </div>
}

export function EditContractor(props: IProps) {
    const data = useRemoteData<IContractor>(props.getContractor, {
        parameters: [props.work.id, props.contractorId]
    });

    return <div className='EditContractorContainer'>
        {data.renderLoading()}
        {data.value && <EditContractorInner
            {...props}
            showRequirements={props.showRequirements}
            contractor={data.value} />}
    </div>
}
