import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
// import { CreateContractorInvitation } from '../../components/contractors/CreateContractorInvitation';
import CreateContractorInvitation from '../../components/contractors/CreateContractorInvitation';
import * as NotificationsStore from '../../store/notifications';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...ContractorsStore.plainActions,
        work: state.user.work,
        moduleManager: state.modules.moduleManager
    }),
    {...ContractorsStore.actionCreators,
        ...NotificationsStore.actionCreators,
    }
)(CreateContractorInvitation as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
