import * as React from 'react';

import { useKeyListener, useTranslation } from '@components';
import { storageGet, storageSet } from '@utils/storage';

import InputEventDispatcher from './InputEventDispatcher';

type InputSearchFilterCallback = (_: string | undefined) => void;

type InputSearchFilterProps = {
    filterKey: string;
    onChange?: InputSearchFilterCallback;
    title?: string;
    focus?: boolean;
}

export function InputSearchFilter(props: InputSearchFilterProps) {
    const { t } = useTranslation();
    const searchInput = React.useRef<any>();

    const [filter, setFilter] = React.useState<string | undefined>(storageGet<string | undefined>(props.filterKey, ''));

    const dispatcher = React.useRef(
        new InputEventDispatcher(
            props.onChange!,
            setFilter,
        ));

    React.useEffect(() => {
        storageSet(props.filterKey, filter);
    }, [filter]);

    if (props.focus) {
        useKeyListener({
            key: 'f',
            keyCtrl: true,
            handle: () => {
                searchInput.current.focus();
            }
        });
    }

    return <input
        ref={searchInput}
        className='e flat'
        // onChange={e => setFilter(e.target.value)}
        onChange={e => dispatcher.current.append(e)}
        type='search'
        value={filter}
        placeholder={t(props.title ?? 'Search')} />
}

export function useInputSearch(props: InputSearchFilterProps) {
    const [filter, setFilter] = React.useState<string | undefined>(storageGet<string | undefined>(props.filterKey, ''));

    const onInput = (search: string | undefined) => {
        setFilter(search);
        props.onChange?.(search);
    }

    const render = () => {
        return <InputSearchFilter {...props} onChange={onInput} />;
    }

    return {
        render: render,
        value: filter,
    }
}
