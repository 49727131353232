import * as React from 'react';

import Constants from '../../Constants';
import {
    Select, useTranslation, useLoading, useMessage, useNumberState, useObjectState, useSearch
} from '@components';
import {
    IJobHasMachinery, IJob, IMachineryType,
    IPropertyGroupType, PropertyGroupObjectType, IMachinery, ISecurity,
    IJobHasContractor, IWork, IUserIdentity, IMachinerySubType,
    ModuleManager,
} from '@models';
import { DynamicProperties } from '@components/user/DynamicProperties';
import { strConcat, getQueryParameter, unique } from '@utils';

export interface IProps {
    contractorId?: number;
    jobHasContractorId?: number;
    hasPermission: Function;
    machineryTypes: IMachineryType[];
    job: IJob;
    onChange: Function;
    moduleManager: ModuleManager;
    propertyGroupTypes: IPropertyGroupType[];
    saveJobHasMachinery: Function;
    searchMachineries: Function;
    security: ISecurity;
    workId: number;
    work: IWork;
    getJobContractors: Function;
    user: IUserIdentity;
    selectedJobHasContractor?: IJobHasContractor;
}

const jobContractor: number = getQueryParameter<number>(
    window.location.search,
    'jobContractor');

type IMachineryRow = IMachinery & {
    jobHasContractorId?: number;
}

export function SelectJobHasMachinery(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const selectedMachineryType = useNumberState();
    const selectedMachinerySubType = useNumberState();
    const selectedMachinery = useNumberState();
    const selectedJobHasContractor = useNumberState();
    const [machineries, setMachineries] = React.useState<IMachineryRow[]>([]);
    const selectedContractor = useNumberState();
    const addRecord = useObjectState({ properties: [] });

    const contractorCurrent = React.useRef<IJobHasContractor>();
    const [parentContractors, setParentContractors] = React.useState<SearchR[]>([]);
    const [availableSubTypes, setAvailableSubTypes] = React.useState<IMachinerySubType[]>([]);

    const subContractors = React.useRef<IJobHasContractor[]>([]);

    React.useEffect(() => {
        if (props.contractorId != undefined) {
            selectedJobHasContractor.set(props.contractorId);
        }

        if (jobContractor != undefined) {
            selectedJobHasContractor.value = jobContractor;
        }
    }, []);

    React.useEffect(() => {
        if (props.jobHasContractorId != undefined) {
            const event: Event = new Event('any');
            Object.defineProperty(event, 'target', { writable: true, value: { value: props.jobHasContractorId } });
            selectedJobHasContractor.set(event);
        }
    }, [props.jobHasContractorId]);

    // #1126: Maquinas a pessma, cada una la suya
    const canAddSubMachineries = (contractorId: number) => {
        return props.security.isContractor()
            ? (props.selectedJobHasContractor?.contractorId == contractorId
                || (props.work.contractorSettings?.canAddSubMachineries ?? false))
            : true;
    }

    const existingMachineries = useSearch<IJobHasMachinery>({
        workId: props.workId,
        search: 'jobs/existingMachineries',
        filters: {
            jobId: props.job.id,
            workId: props.workId,
        },
    });

    const messages = useMessage({
        successMessage: t('Machinery added successfully'),
        autoClear: true,
    });

    type SearchR = {
        parentContractorId?: number;
        parentContractorName?: string;
        jobHasContractorId: number;
    }

    const searchContractorHasContracts = useSearch<SearchR>({
        search: 'contractorHasContracts.list',
        normalizeKeys: true,
        workId: props.work.id,
        filters: {
            contractorId: selectedContractor.value,
            jobId: props.job.id,
        },
        lazy: true,
        dependencies: [selectedContractor.value],
    });

    React.useEffect(() => {
        if (selectedContractor.value != undefined && !props.jobHasContractorId) {
            searchContractorHasContracts.doSearch({
                contractorId: selectedContractor.value,
                jobId: props.job.id,
            });
        }
    }, [selectedContractor.value]);

    React.useEffect(() => {
        if (searchContractorHasContracts.value != undefined && searchContractorHasContracts.value.length > 0) {
            const parentContractors = searchContractorHasContracts.value.map((c: any) => ({
                parentContractorId: c.parentContractorId,
                parentContractorName: calculateLevel(c),
                jobHasContractorId: c.jobHasContractorId,
            }));
            setParentContractors(parentContractors);
        } else {
            setParentContractors([]);
        }
    }, [searchContractorHasContracts.value]);

    const calculateLevel = (jobHasContractor: SearchR) => {
        let level = 0;
        let parentContractorId = jobHasContractor.parentContractorId;
        while (parentContractorId != undefined) {
            let parentContractor = contractors.find(c => c.id == parentContractorId);

            parentContractorId = parentContractor.parentId
            level++;
        }

        level--;

        return level === -1 ? t('main contractor') : `${jobHasContractor.parentContractorName} ${t('level')} ${level}`;
    }

    const contractors = [
        { id: undefined, name: t('Filter by contractor') } as any
    ].concat(props.job.contractors.map(c => ({ ...c, name: c.contractor.name })));

    const currentContractor = selectedContractor.hasValue()
        ? contractors.find(c => c.contractorId == selectedContractor.value)
        : contractors.find(c => c.id == props.jobHasContractorId);

    const findMachineries = loading.wrap(async (
        machineryTypeId: number | undefined,
        subTypeId: number | undefined = undefined) => {

        selectedMachinery.clear();
        const searchContractor: any = currentContractor;
        if (machineryTypeId && searchContractor?.contractor != undefined) {
            let result: IMachinery[] = [];
            const contractors = subContractors
                .current
                .concat([searchContractor]);

            for (let i = 0; i < contractors.length; i++) {
                const contractorId = contractors[i].contractorId;
                const jobHasContractorId = contractors[i].id;

                if (canAddSubMachineries(contractorId)) {
                    const resp = await props.searchMachineries(
                        props.workId,
                        null,
                        machineryTypeId,
                        contractorId,
                        props.job.id);

                    const machineriesList = resp
                        .map((m: IMachinery) => {
                            const licenseNumber = strConcat(
                                m.prefixLicenseNumber,
                                m.licenseNumber,
                                m.suffixLicenseNumber);
                            const hasName = m.name && m.name != '';
                            const hasCode = m.code && m.code != '';
                            let uniqueName = hasName
                                ? m.name
                                : hasCode
                                    ? m.code
                                    : licenseNumber;

                            if (props.work.settingsObj?.mainContractorCanFulfillSubs
                                || (props.work.contractorSettings?.canAddSubMachineries ?? false)) {
                                uniqueName = `[${m.contractor?.name}] ${uniqueName}`;

                                if (Constants.isDebug) {
                                    uniqueName = `${uniqueName} (id = ${m.id})`;
                                }
                            }
                            return { ...m, name: uniqueName, jobHasContractorId: jobHasContractorId };
                        });

                    result = result.concat(machineriesList);
                }
            }

            const excludeMachineries = existingMachineries
                .value.filter(j =>
                    j.jobHasContractorId == props.selectedJobHasContractor?.id
                    || props.selectedJobHasContractor == undefined)
                .map(j => j.machineryId);

            result = unique(result)
                .filter(m => !excludeMachineries.includes(m.id))
                .map(m => ({...m, name: props.moduleManager.format('machinery', m) ?? m.name }));

            // .filter(m => m.contractorId === contractorCurrent.current?.contractorId
            //     || m.contractorId === currentContractor?.contractorId);

            if (subTypeId != undefined) {
                result = result.filter(m => m.machinerySubTypeId == subTypeId);
            }

            setMachineries(result);
        }
        else {
            setMachineries([]);
        }
    });

    const initialize = async () => {
        const contractors: IJobHasContractor[] = await props.getJobContractors(props.workId, props.job.id);
        const selfJobHasContractor = props.selectedJobHasContractor;
        contractorCurrent.current = selfJobHasContractor;

        const subcontractors = contractors.filter(c => c.parentId == selfJobHasContractor?.id);
        const subcontractorIds = subcontractors.map(c => c.contractorId);
        const deepSubcontractors: any = [];
        contractors.map(c => {
            if (subcontractorIds.includes(c.parentId ?? 0)) {
                deepSubcontractors.push(c);
            }
        });
        subContractors.current = subcontractors.concat(deepSubcontractors);
    }

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        findMachineries(selectedMachineryType.value, selectedMachinerySubType.value);
        const mt = props.machineryTypes.find(mt => mt.id == selectedMachineryType.value);
        if (mt != undefined) {
            setAvailableSubTypes(mt
                .subTypes?.sort((a: IMachinerySubType, b: IMachinerySubType) => a.name.localeCompare(b.name)) ?? []);
        }
    }, [selectedMachineryType.value, selectedContractor.value, selectedMachinerySubType.value]);

    const doAdd = loading.wrap(async (..._: any) => {
        // const jobHasContractorId = props.selectedJobHasContractor?.id;
        const m = machineries
            .find(d => d.id == selectedMachinery.value)!;

        const newRecord: IJobHasMachinery = {
            ...addRecord.value,
            jobId: props.job.id,
            startDate: new Date(),
            machineryId: m.id,
            jobHasContractorId: m.jobHasContractorId ?? selectedJobHasContractor.value ?? jobContractor,
        };

        const resp = await props.saveJobHasMachinery(
            props.workId,
            props.job.id,
            newRecord,
            selectedJobHasContractor.value || jobContractor);

        messages.set(resp);

        if (messages.isSuccess()) {
            selectedMachineryType.clear();
            selectedMachinery.clear();
        }

        if (resp.error == undefined && resp.hasValue) {
            setTimeout(() => {
                props.onChange();
            }, 1000);
        }
    });

    const canAdd = selectedMachineryType.hasValue() && selectedMachinery.hasValue();

    const machineryTypes = props
        .machineryTypes
        .sort((a: IMachineryType, b: IMachineryType) => a.name.localeCompare(b.name));

    return <div className='c g-20'>
        <div className='form-header'>
            {t('Select machinery')}
        </div>

        <div className={'md pd c g-20 e'}>
            <Select
                options={machineryTypes}
                optionLabel='name'
                optionValue='id'
                placeholder={t('Select machinery type')}
                value={selectedMachineryType.value}
                onChange={selectedMachineryType.set} />

            {availableSubTypes.length > 0 &&
                <Select
                    options={availableSubTypes}
                    optionLabel='name'
                    optionValue='id'
                    placeholder={t('Select machinery subtype')}
                    value={selectedMachinerySubType.value}
                    onChange={selectedMachinerySubType.set} />}

            {!props.security.isContractor() && props.jobHasContractorId == undefined &&
                <Select
                    options={contractors}
                    optionLabel='name'
                    optionValue='contractorId'
                    placeholder={t('Select contractor')}
                    value={selectedContractor.value}
                    onChange={selectedContractor.set} />}

            {(props.jobHasContractorId == undefined && selectedContractor.hasValue()) &&
                <Select
                    options={parentContractors}
                    optionLabel='parentContractorName'
                    optionValue='jobHasContractorId'
                    placeholder={t('Select parent contractor')}
                    value={selectedJobHasContractor.value}
                    onChange={selectedJobHasContractor.set} />
            }

            <Select
                options={machineries}
                filter
                optionLabel='name'
                optionValue='id'
                emptyMessage={t('job.machineries.no-data')}
                placeholder={t('Select machinery')}
                value={selectedMachinery.value}
                onChange={selectedMachinery.set} />

            {selectedMachinery.hasValue() &&
                <DynamicProperties
                    object={addRecord}
                    onChange={addRecord.set('properties')}
                    objectType={PropertyGroupObjectType.JobHasMachinery}
                    propertyGroupTypes={props.propertyGroupTypes} />}

            <div className='e' />

            {messages.render()}
        </div>
        <div className='r sm pd-right pd-bottom'>
            <span className='e' />
            {loading.renderBox()}

            <button
                className='primary'
                disabled={!canAdd}
                onClick={doAdd}>
                {t('Add')}
            </button>
        </div>
    </div>
}
