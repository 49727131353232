import * as React from 'react';

import { useForm, useLoading, useTranslation } from '@components';
import { IContractorSettings, IWork } from '@models';
import { WorkToolbar } from '../WorkToolbar';

export interface IProps {
    work: IWork;
    saveContractorSettings: Function;
}

export function ManageContractorSettings(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();

    const contractorSettings = props.work.contractorSettings ?? {
        showGeneralIndicators: false,
        legalFormMandatory: false,
        jhcStartDateMandatory: false,
        jhcDescriptionMandatory: false,
        cifCantBeRepeated: false,
        canAddSubWorkers: false,
        canAddSubMachineries: false,
        workId: props.work.id
    };

    const form = useForm<IContractorSettings>({
        initialValues: contractorSettings,
    });

    const doSave = loading.wrap(async () => {
        await props.saveContractorSettings(props.work.id, form.values);
    });

    return <div className='c e md pd'>
        <WorkToolbar work={props.work} />

        <strong className='page-subtitle'>{t('contractor.settings')}</strong>

        <div className='c e sm pd g-20'>
            <div className='r g-20 v-center'>
                {form.checkBox('showGeneralIndicators')}
                <label htmlFor='showGeneralIndicators'>
                    {t('contractor.settings.showGeneralIndicators')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('legalFormMandatory')}
                <label htmlFor="legalFormMandatory">
                    {t('contractor.settings.legalFormMandatory')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('jhcStartDateMandatory')}
                <label htmlFor="jhcStartDateMandatory">
                    {t('contractor.settings.startDateMandatory')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('jhcDescriptionMandatory')}
                <label htmlFor="jhcDescriptionMandatory">
                    {t('contractor.settings.descriptionMandatory')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('cifCantBeRepeated')}
                <label htmlFor="cifCantBeRepeated">
                    {t('contractor.settings.cifRepeatable')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('canAddSubWorkers')}
                <label htmlFor="canAddSubWorkers">
                    {t('contractor.settings.canAddSubWorkers')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('canAddSubMachineries')}
                <label htmlFor="canAddSubMachineries">
                    {t('contractor.settings.canAddSubMachineries')}
                </label>
            </div>
            <div className='e'></div>
        </div>

        <div className='r r-end'>
            {loading.render()}
            <button
                className='primary'
                disabled={loading.isLoading()}
                onClick={_ => doSave()}>{t('Save')}</button>
        </div>
    </div>;
}
