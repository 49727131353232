import { Action, Reducer } from 'redux';
import {
} from '../models';
import * as ContractorActions from './actions/contractors';
import { actionCreators as N } from './notifications';

export interface IContractorState {
}

// types
enum Types {
}

export type KnownAction = { type: undefined };

export const plainActions = {
    invalidateContractor: ContractorActions.invalidateContractor,
    getContractorFromAppUser: ContractorActions.getContractorFromAppUser,
    createContractorInvitation: ContractorActions.createContractorInvitation,
    getContractorInvitation: ContractorActions.getContractorInvitation,
    getContractorInvitations: ContractorActions.getContractorInvitations,
    getContractors: ContractorActions.getContractors,
    getContractor: ContractorActions.getContractor,
    getWorkConstants: ContractorActions.getWorkConstants,
    registerContractorInvitation: ContractorActions.registerContractorInvitation,
    saveContractor: ContractorActions.saveContractor,
    acceptSubContractorRequest: ContractorActions.acceptSubContractorRequest,
    rejectSubContractorRequest: ContractorActions.rejectSubContractorRequest,
    saveSubContractorRequest: ContractorActions.saveSubContractorRequest,
    saveSubMainContractorRequest: ContractorActions.saveSubMainContractorRequest,
    getSubContractorRequestsAsTarget: ContractorActions.getSubContractorRequestsAsTarget,
    getSubContractorRequestsAsWorker: ContractorActions.getSubContractorRequestsAsWorker,
    removeContractorAppUser: ContractorActions.removeContractorAppUser,
    createWorkContractorAppUser: ContractorActions.createWorkContractorAppUser,
    getWorkUsers: ContractorActions.getWorkUsers,
    getContractorAppUsers: ContractorActions.getContractorAppUsers,
    reactivateContractor: ContractorActions.reactivateContractor,
    restrictContractor: ContractorActions.restrictContractor,
    removeRestrictedContractor: ContractorActions.removeRestrictedContractor,
}

export const actionCreators = {
};

export const reducer: Reducer<IContractorState> = (state: IContractorState | undefined, incomingAction: Action): IContractorState => {
    if (state === undefined) {
        return {};
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        default:
            return state;
    }
};
