import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as RequirementsStore from '@store/requirements';
import { ViewRequirementNotes } from '@components/requirements/ViewRequirementNotes';

export default connect(
    (state: ApplicationState) => ({
        ...RequirementsStore.plainActions,
    }),
    {},
)(ViewRequirementNotes as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any