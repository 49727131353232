import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { ManageJobDepartments } from '@components/jobs/ManageJobDepartments';

export default connect(
    (state: ApplicationState) => ({
        departments: state.user.departments,
        work: state.user.work,
        ...JobsStore.plainActions,
    }),
    JobsStore.actionCreators,
)(ManageJobDepartments as any) as any;