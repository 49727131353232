import * as React from 'react';

import { OverlayPanel, useTranslation, useMemoized, useRemoteData, useResolveName } from '@components';
import DateUtils from '@utils/date-utils';
import { IJob, IJobSettings, IJobStatus, IUserIdentity, JobStatusType, jobStatusTypes } from '@models';

import './JobStatus.scss';

type GetJobStatusT = (workId: number, jobId: number) => Promise<IJobStatus[]>;

export interface IProps {
    getJobStatus: GetJobStatusT;
    job: IJob;
    jobStatus?: IJobStatus | IJobStatus[];
    users: IUserIdentity[];
    workId: number;
    jobSettings?: IJobSettings;
}

function jobStatusIsArray(j: any): j is IJobStatus[] {
    return j && j.length;
}

export function JobStatus(props: IProps) {
    const { t } = useTranslation();
    const panelRef = React.useRef<any>();
    const status = useRemoteData<IJobStatus[]>(props.getJobStatus, {
        lazy: true,
        parameters: [props.workId, props.job.id]
    });

    const [currentStatus, setCurrentStatus] = React.useState<IJobStatus | undefined>();

    const resolveStatusType = useResolveName(jobStatusTypes);
    const resolveUserName = useMemoized((id: number) => {
        return props.users.find(u => u.id === id)?.userName;
    }, [props.users]);

    React.useEffect(() => {
        const jobStatus = props.jobStatus && jobStatusIsArray(props.jobStatus)
            ? props.jobStatus
            : props.jobStatus
                ? [props.jobStatus]
                : undefined;
        const allStatus = status.value ?? jobStatus ?? [];
        if (allStatus) {
            setCurrentStatus(allStatus.find(s => s.isCurrent));
        }
        else {
            setCurrentStatus(undefined);
        }
    }, [status.value, props.jobStatus]);

    const showHistory = (e: any) => {
        panelRef.current.toggle(e);
    }

    const allStatus = status.value ?? [props.jobStatus] ?? [];

    const loadAllStatus = async () => {
        if (!status.hasValues()) {
            await status.query();
        }
    }

    const statusDefaultName = (props.jobSettings?.skipInformationLoadedState ?? false)
        && currentStatus?.jobStatusTypeId == JobStatusType.AUTHORIZED
        ? t('job.status.inprogress')
        : resolveStatusType(currentStatus?.jobStatusTypeId);

    const azsa = currentStatus?.jobStatusTypeId == JobStatusType.AUTHORIZED
        ? t('job.status.authorized')
        : currentStatus?.jobStatusTypeId == JobStatusType.INFORMATION_LOADED
            ? t('job.status.informationLoaded')
            : statusDefaultName;

    // TODO: Remove this key
    const statusName = props.workId != 23 ? statusDefaultName : azsa;

    return <div className={'JobStatus'}>
        {status.renderLoading()}

        {currentStatus != undefined && <>
            <div className={'job-status job-status-' + currentStatus.jobStatusTypeId}
                onClick={showHistory}>
                {statusName}
            </div>

            <OverlayPanel onShow={loadAllStatus} ref={panelRef} className={'no-padding'}>
                <table
                    className={'table no-border lg'}
                    style={{ minWidth: '400px' }}>
                    <tbody>
                        {allStatus.map((c, i: number) =>
                            <tr key={i}>
                                <td>
                                    <i className={'fas fa-user sm pd-right'} />
                                    {resolveUserName(c.userId)}
                                </td>
                                <td className={'mutted center'}>
                                    {DateUtils.format(c.dateTime)}
                                </td>
                                <td className={'job-status-' + c.jobStatusTypeId}>
                                    {resolveStatusType(c.jobStatusTypeId)}
                                </td>
                            </tr>)}
                    </tbody>
                </table>
            </OverlayPanel>
        </>}
    </div>
}
