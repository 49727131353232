import * as React from 'react';

import { ISecurity, IWork } from '@models';

export interface IProps {
    security: ISecurity;
    work: IWork;
}

export function WorkReports(props: IProps) {
    return <div>
    </div>
}