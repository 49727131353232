import * as React from 'react';

import {
    BreadcrumbItem, useToast, Panel, Portal, useLoading, useTranslation, useRemoteData,
    useDialogs, useSearch, useConfirm,
} from '@components';
import {
    IWorker, IPropertyGroupType, ISecurity,
    IWork, ResourceType, IResourceStatusType,
    ModuleManager,
} from '@models';

import './EditWorker.scss';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import { ViewResourceHistory } from '@components/requirements/ViewResourceHistory';
import { IWorkerType, IWorkerDocumentType, IContractor } from '@models/resources';
import { WorkerRoutes } from './WorkerRoutes';
import { goToWorker, goToWorkers, goToWorkerRequirements } from '@components/routes';
import { EditWorkerForm } from './EditWorkerForm';
import { IRequirementGroup } from '@models/requirements';

type GetWorkerF = (workId: number, id: number) => Promise<IWorker>;

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    getWorker: GetWorkerF;
    hideActions?: boolean;
    moduleManager: ModuleManager;
    work: IWork;
    worker: IWorker;
    workerId: number;
    security: ISecurity;
    saveWorker: Function;
    reactivateWorker: Function;
    workerTypes: IWorkerType[];
    workerDocumentTypes: IWorkerDocumentType[];
    resourceStatusTypes: IResourceStatusType[];
    requirementGroups: IRequirementGroup[];
    getRequirements: Function;
    getRequirement: Function;
}

enum FormType {
    Data = 1,
    None = 2,
}

function EditWorkerInner(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const [formType, setFormType] = React.useState<FormType>(FormType.Data);
    const toast = useToast();
    const dialogs = useDialogs();

    const doReactivateWorker = loading.wrap(async () => {
        await props.reactivateWorker(props.work.id, props.worker.id);
        goToWorkers(props.work.id);
    });

    const confirmActivate = useConfirm({
        message: t('resource.activate.worker.confirm'),
        accept: (_: any) => doReactivateWorker(),
    });

    const contractorData = useSearch<IContractor>({
        workId: props.work.id,
        search: 'workers/contractor.get',
        filters: {
            contractorId: props.worker.contractorId,
        }
    });

    const contractor = contractorData.value && contractorData.value.length > 0
        ? contractorData.value[0]
        : undefined;

    React.useEffect(() => {
        if (window.location.href.indexOf('requirements') == -1) {
            setFormType(FormType.Data);
        }
        else {
            setFormType(FormType.None);
        }
    }, [window.location.href]);

    const clearWorkerForm = () => setFormType(FormType.None);
    const loadRequirements = (w: IWorker) => {
        clearWorkerForm();
        goToWorkerRequirements(props.work.id, w.id);
    }

    const loadData = (w: IWorker) => {
        setFormType(FormType.Data);
        goToWorker(props.work.id, w.id);
    }

    return <div className='EditWorkerContainer'>
        <BreadcrumbItem
            text={t('Workers')}
            onClick={() => goToWorkers(props.work.id)} />

        <BreadcrumbItem
            text={props.worker.name + ' ' + props.worker.surname}
            onClick={() => {
                setFormType(FormType.Data);
                goToWorker(props.work.id, props.worker.id);
            }} />

        <Portal container={'#breadcrumb-right'}>
            {loading.render()}
        </Portal>

        {toast.render()}

        {props.worker &&
            <div className='form-container'>
                {formType === FormType.None &&
                    <WorkerRoutes
                        clearWorkerForm={clearWorkerForm}
                        worker={props.worker} />}
                {formType == FormType.Data &&
                    <EditWorkerForm {...props} />}

                <div className='actions'>
                    {contractor && <div className='panel-contractor'>
                        <div className='r title-box'>
                            <i className="fas fa-id-card-alt" />
                            <span className='title'>{t('Contractor')}</span>
                        </div>
                        <span className='contractor-name'>{contractor.name}</span>
                    </div>}

                    <Panel header={<div className='r v-center'>
                        <i className='fas fa-user-friends' />
                        <span className='e' />
                        {props.worker?.name + " " + props.worker?.surname}
                    </div>} className='buttons'>
                        <div className='c'>
                            <button onClick={() => loadData(props.worker)}>
                                <i className='fas fa-table' />
                                <span className='e'>{t('View worker data')}</span>
                            </button>
                            <button onClick={() => loadRequirements(props.worker)}>
                                <i className='fas fa-folder' />
                                <span className='e'>{t('View requirements')}</span>
                            </button>
                            {props.security.isGestorOrContractor() && props.worker.isActive === false &&
                                <button onClick={confirmActivate}>
                                    <i className='fas fa-check' />
                                    <span className='e'>{t('resource.activate')}</span>
                                </button>}
                            {props.moduleManager.renderComponent('worker.main.actions', props, () => null)}
                        </div>
                    </Panel>
                    {dialogs.render('force-validation', { title: t('Force resource validation'), className: 'g pd' }, () =>
                        <ForceValidationResourceContainer
                            currentStatusType={props.worker.currentStatusType}
                            workId={props.work.id}
                            requestClose={dialogs.clear}
                            resourceType={ResourceType.Worker}
                            resourceId={props.worker.id} />
                    )}
                    {dialogs.render('view-history', { title: t('View history'), className: 'g pd' }, () =>
                        <ViewResourceHistory
                            requestClose={dialogs.clear}
                            resourceId={props.worker.id}
                            resourceType={ResourceType.Worker}
                            workId={props.work.id} />
                    )}
                </div>
            </div>}
    </div>
}

export function EditWorker(props: IProps) {
    const data = useRemoteData<IWorker>(props.getWorker, {
        parameters: [props.work.id, props.workerId]
    });

    return <div className='EditWorkerContainer'>
        {data.renderLoading()}
        {data.value && <EditWorkerInner
            {...props}
            worker={data.value} />}
    </div>
}
