import * as React from 'react';

import * as Actions from '@store/actions/admin';
import {
    Column, ConfirmDialog, DataTable, Dialog, Loading, Message, useMemoized, useTranslation,
} from '@components';
import { IConstants, IWork, IWorkspace } from '@models';
import { getGraphqlError } from '@utils';
import { WorkspaceForm } from './WorkspaceForm';
import { isWorkspacePropertyGroupType } from '@models/works';

export interface IProps {
    work: IWork;
    constants: IConstants;
}

export function AdminWorkWorkspaces(props: IProps) {
    const { t } = useTranslation();
    const [error, setError] = React.useState<string|undefined>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [workspaces, setWorkspaces] = React.useState<IWorkspace[]>(
        props.work.workspaces ?? []
    );

    // add
    const [showAdd, setShowAdd] = React.useState<boolean>(false);
    // edit
    const [showEdit, setShowEdit] = React.useState<IWorkspace|undefined>();
    // remove
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IWorkspace|undefined>();

    const formatWorkspaceRegions = useMemoized((countryId: number, regionId: number, countyId: number) => {
        const country = props.constants.countries.find(c => c.id == countryId);
        const region = country?.regions.find(r => r.id == regionId);
        const county = region?.counties.find(c => c.id == countyId);

        return [country, region, county]
            .filter(d => d != undefined)
            .map(d => d!.name)
            .join(', ');
    });

    const loadWorkspaces = async (workId: number) => {
        setLoading(true);

        const w = await Actions.getWork(workId);
        const workspaces = w.data.work.workspaces;
        setWorkspaces(workspaces);

        if (showEdit) {
            const currentWorkspace = workspaces.find((w: IWorkspace) => w.id == showEdit.id);
            setShowEdit(currentWorkspace);
        }

        setLoading(false);
    }
    const removeWorkspace = async (id: number) => {
        setLoading(true);
        setError(undefined);

        const resp = await Actions.removeWorkspace(id);
        if (resp.data.removeWorkspace?.value) {
            await loadWorkspaces(props.work.id);
        }
        else {
            setError(getGraphqlError(resp, 'removeWorkspace'));
        }
        setShowConfirmRemove(undefined);
        setLoading(false);
    }
    const saveWorkspace = async (w: IWorkspace) => {
        setLoading(true);
        await Actions.saveWorkspace(w);
        await loadWorkspaces(props.work.id);
        setShowAdd(false);
        setShowEdit(undefined);
        setLoading(false);
    }

    const AddEditFooter = (p: any) => {
        return <div className='r'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />
            {loading && <Loading />}
            <div className='p-buttonset'>
                <button
                    onClick={() => p.edit ? setShowEdit(undefined) : setShowAdd(false)}>
                    {t('Cancel')}
                </button>
                <button
                    className='primary'
                    disabled={loading}>
                    {t('Save')}
                </button>
            </div>
        </div>
    }

    const ListFooter = (p: any) => {
        return <div className='r sm pd footer'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />
            <span className='e'/>
            <button className='p-button'
                onClick={_ => setShowAdd(true)}>
                <i className='pi pi-plus sm pd-right'/>
                {t('Add')}
            </button>
        </div>
    }

    const propertyGroupTypes =
        props.work?.propertyGroupTypes?.filter(isWorkspacePropertyGroupType) ?? [];

    return (<div className='c h-100p'>
        {showAdd && <Dialog
            header={t('Add workspace')}
            onHide={() => setShowAdd(false)}
            visible>
            <WorkspaceForm
                propertyGroupTypes={propertyGroupTypes}
                constants={props.constants}
                footer={<AddEditFooter />}
                onSubmit={(d: IWorkspace) => saveWorkspace(d)}
                workId={props.work.id} />
        </Dialog>}

        {showEdit && <Dialog
            header={t('Edit workspace')}
            onHide={() => setShowEdit(undefined)}
            visible>
            <WorkspaceForm
                propertyGroupTypes={propertyGroupTypes}
                constants={props.constants}
                data={showEdit}
                footer={<AddEditFooter edit />}
                onSubmit={(d: IWorkspace) => saveWorkspace(d)}
                workId={props.work.id} />
        </Dialog>}

        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeWorkspace(showConfirmRemove.id)} />}

        <DataTable
            className='table'
            scrollable
            loading={loading}
            value={workspaces}>
            <Column header={t('Id')} headerClassName='td-sm' bodyClassName='td-sm' field='id' />
            <Column header={t('Name')} field='name' />
            <Column header={t('Description')} field='description' />
            <Column header={t('Region')}
                body={d => formatWorkspaceRegions(d.countryId, d.regionId, d.countyId)} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={(d: IWorkspace) =>
                    <i className='pi pi-pencil pointer'
                        title={t('Editar')}
                        onClick={() => setShowEdit(d)} />} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={(d: IWorkspace) =>
                    <i className='pi pi-trash pointer'
                        title={t('Borrar')}
                        onClick={() => setShowConfirmRemove(d)} />} />
        </DataTable>

        <span className='e' />
        <ListFooter />
    </div>);
}