import * as React from 'react';
import { createPortal } from 'react-dom';

export interface IProps {
    container: string;
    children: any;
    delay?: number;
}

const getContainer = (n: string) => {
    if (n.startsWith('#')) {
        return document.getElementById(n.substring(1));
    }
    else {
        return document.querySelector(n);
    }
}

export function Portal(props: IProps) {
    const container = getContainer(props.container);

    const [time, setTime] = React.useState<number>(0);

    React.useEffect(() => {
        if (props.delay) {
            setTimeout(() => setTime(new Date().getTime()), props.delay);
        }
    }, [props.delay]);

    if (container) {
        return createPortal(props.children, container, `${time}`);
    }
    else {
        return null;
    }
}