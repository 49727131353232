import * as React from 'react';

import { IWork } from '@models';
import { IRequirementTypeValidationHolder } from '@models/requirements';
import { R, useForm, useRemoteData, useTranslation } from '@components';

type GetWorkResourcesF = (id: number) => Promise<IWork>;

export interface IProps {
    getWorkResources: GetWorkResourcesF;
    onChange: Function;
    validationHolder: IRequirementTypeValidationHolder;
    workId: number;
}

export function RequirementTypeValidationHolderForm(props: IProps) {
    const { t } = useTranslation();

    const work = useRemoteData<IWork>(props.getWorkResources, { parameters: [props.workId] });

    const form = useForm<IRequirementTypeValidationHolder>({
        initialValues: props.validationHolder,
        onChange: props.onChange,
    });

    return <div className='c'>
        {work.renderLoading()}
        <div className='form-1 l200 md pd'>
            <R label={t('requirement.holder.optional')}>
                {form.checkBox('optional')}
            </R>

            <R label={t('Is blocking')}>
                {form.checkBox('isBlocking')}
            </R>
            <R label={t('ValidationHolder.OnlyNotifications')}>
                {form.checkBox('onlyNotifications')}
            </R>
            <R label={t('Validation order')}>
                {form.input('validationOrder', { type: 'number', map: parseInt })}
            </R>
            <R label={t('job.responsible')}>
                {form.checkBox('jobResponsible')}
            </R>
            <R label={t('job.rso.implied')}>
                {form.checkBox('jobImpliedRso')}
            </R>
            <R label={t('User')}>
                {form.select('appUserId', { blank: true, options: work.value?.users ?? [], optionLabel: 'userName' })}
            </R>
            <R label={t('Department')}>
                {form.select('departmentId', { blank: true, options: work.value?.departments ?? [] })}
            </R>
            <R label={t('Work function')}>
                {form.select('workFunctionId', { blank: true, options: work.value?.workFunctions ?? [] })}
            </R>
        </div>
    </div>
}
