import * as React from 'react';

import {
    useForm,
    useTranslation,
    ValidationBuilder,
} from '@components';
import { goToCommunications } from '@components/routes';
import {
    ICommunicationFile, IDepartment, IJob, IWork, IWorkspace,
    IUserIdentity,
} from '@models';
import { RequirementTargetType } from '@models/requirements';
import { useDialogs, useLoading, useMessage } from '@utils/hooks';
import DateUtils from '@utils/date-utils';

import './CommunicationForm.scss';
import dateUtils from '@utils/date-utils';

export interface IProps {
    footer?: any;
    workspaces: IWorkspace[];
    work: IWork;
    getJobs: Function;
    departments: IDepartment[];
    saveCommunication: Function;
    getCommunicationReceivers: Function;
    pushTemporalResource: Function;
}


export function CommmunicationForm(props: IProps) {
    // referencia al input file
    const inputFile = React.useRef<any>();

    const { t } = useTranslation();
    const loading = useLoading();
    const dialogs = useDialogs();
    const messages = useMessage();

    // trabajos filtrados por workspace
    const [jobs, setJobs] = React.useState<IJob[]>([]);
    // todos los trabajos
    const [allJobs, setAllJobs] = React.useState<IJob[]>([]);

    // relaciones de la comunicacion (ficheros, trabajos, departamentos y workspaces)
    const [selectedFiles, setSelectedFiles] = React.useState<ICommunicationFile[]>([]);
    const [selectedJobs, setSelectedJobs] = React.useState<number[]>([]);
    const [selectedDepartments, setSelectedDepartments] = React.useState<number[]>([]);
    const [selectedWorkspaces, setSelectedWorkspaces] = React.useState<number[]>([]);

    const todayOffset = new Date();
    todayOffset.setDate(todayOffset.getDate() - 1);

    const validateForm = (data: any) => {
        return ValidationBuilder.create(data)
            .notEmpty('title', t('Title is required'))
            .notEmpty('description', t('Description is required'))
            .notEmpty('startDate', t('startDate is required'))
            .notEmpty('endDate', t('endDate is required'))
            .notEmpty('users', t('Users is required'))
            .build();
    }

    const isValid = (data: any) => {
        return Object.keys(validateForm(data)).length == 0;
    }

    const form = useForm<Partial<any>>({
        initialValues: {
        },
        validate: validateForm,
        validateOnMount: true,
    });

    // activamos el input file para que el usuario seleccione un fichero
    const fireAddFile = () => {
        inputFile.current.click();
    }

    // borramos uno de los ficheros seleccionados
    const removeSelectedFile = (f: ICommunicationFile) => {
        setSelectedFiles(fs => fs.filter(r => r != f));
    }

    // cuando el usuario selecciona un documento lo almacenamos como recurso temporal
    // y lo anhadimos a la lista selectedFiles (ficheros seleccionados para la comunicación)
    const onSelectDocument = loading.wrap(async (e: any) => {
        const file = e.target.files![e.target.files?.length! - 1];
        const resourceId = await props.pushTemporalResource(file);
        if (resourceId.hasValue) {
            setSelectedFiles(f => [
                {
                    fileName: file.name,
                    fileSize: file.size,
                    temporalResourceId: resourceId.value,
                },
                ...f
            ]);
        }
        else {
            messages.set(resourceId);
        }
    });

    // almacenamos todos los trabajos
    //TODO: intentar evitar esto
    const initialize = async () => {
        const jobs = await props.getJobs(props.work.id);
        setAllJobs(jobs.data.work.jobs);
    }

    React.useEffect(() => {
        initialize();
    }, []);

    const cannotSave = () => {
        if (form.values.startDate != null) {
            return loading.isLoading() || form.isInvalid()
                || form.values.endDate < form.values.startDate
                || form.values.endDate == null
                || form.values.startDate == null
                || dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), todayOffset) < 0;
        }
        else {
            return loading.isLoading() || form.isInvalid()
                || form.values.endDate < form.values.startDate
                || form.values.endDate == null
                || form.values.startDate == null
        }
    };

    form.observe('workspaces', setSelectedWorkspaces);
    form.observe('users', setSelectedDepartments);
    form.observe('jobs', setSelectedJobs);

    // si cambia el workspace filtramos los trabajos (jobs/setJobs) que puede seleccionar el usuario
    React.useEffect(() => {
        if (selectedWorkspaces.length > 0 && allJobs.length > 0) {
            const selectedJobs = allJobs.filter((j: any) => selectedWorkspaces.includes(j.workspaceId ?? 0));
            setJobs(selectedJobs);
        }
        else {
            setJobs([]);
        }
    }, [selectedWorkspaces, allJobs]);

    // crea un objeto ICommunication a partir de la información del formulario
    // procesando los archivos/departamentos/trabajos ...
    const getCommunication = () => {
        return {
            title: form.values.title ?? '',
            description: form.values.description ?? '',
            fromDate: form.values.startDate ?? new Date(),
            toDate: form.values.endDate ?? new Date(),
            workId: props.work.id,
            isRecursive: form.values.recursive ?? false,
            jobs: selectedJobs.map(id => ({
                jobId: id,
            })),
            targetType: selectedDepartments.includes(0)
                ? RequirementTargetType.Contractor
                : undefined,
            departments: selectedDepartments
                .filter(id => id > 0) // excluimos el departamento virtual que identifica a contratistas
                .map(id => ({
                    departmentId: id
                })),
            workspaces: selectedWorkspaces.map(id => ({
                workspaceId: id,
            })),
            files: selectedFiles,
        };
    }

    const doAdd = loading.wrap(async (..._: any) => {
        const data = getCommunication();

        const resp = await props.saveCommunication(data);
        messages.set(resp);

        if (resp.hasValue) {
            goToCommunications(props.work.id);
        }
        else {
            messages.set(resp);
        }
    });

    // previsualizamos los posibles destinatarios
    const previewReceivers = loading.wrap(async () => {
        const c = getCommunication();
        const receivers = await props.getCommunicationReceivers(c);
        dialogs.show('receivers', receivers);
    });

    // incluimos un departamento virtual para identificar a los contratistas (targetType == 1)
    const availableDepartments = [
        { id: 0, name: t('Contractors') },
        ...props.departments,
    ];

    // listado de receivers de la comunicacion
    const renderReceivers = (receivers: IUserIdentity[]) => {
        if (receivers.length == 0) {
            return <div className='md pd center'>
                <strong>
                    {t('communications.receivers.empty')}
                </strong>
            </div>
        }
        else {
            receivers.sort((a, b) => a.userName.localeCompare(b.userName));
            return <div className='relative'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th className='header fixed-0'>{t('username')}</th>
                            <th className='header fixed-0'>{t('email')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receivers.map((r) => <tr key={r.id}>
                            <td>{r.userName}</td>
                            <td>{r.email}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>;
        }
    }


    const checkStartDate = (value: any) => {
        if (value != null) {
            if (dateUtils.dateIsLessOrEqual(dateUtils.parseDate(value), todayOffset)) {
                return <small className="p-error">{t('communications.startDate.error')}</small>
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    const checkEndDate = (value: any) => {
        if (value < form.values.startDate) {
            return <small className="p-error">{t('communications.endDate.error')}</small>
        }
        else {
            return null;
        }
    }

    return <div className='CommunicationForm'>
        {dialogs.render(
            'receivers',
            { title: (receivers: IUserIdentity[]) => t('communications.receivers') + ` (${receivers.length})` },
            renderReceivers)}

        <form onSubmit={form.handleSubmit}>
            <div className='communication-form'>
                <div className='fields-grid'>
                    <label style={{ textAlign: 'center' }}>{t('Title')}</label>
                    {form.input('title', { containerClassName: 'span-5', autoFocus: true })}
                    <label style={{ textAlign: 'center' }}>{t('StartDate')}</label>
                    <div>
                        {form.input('startDate', { type: 'date' })}
                        {checkStartDate(form.values.startDate)}
                    </div>
                    <label style={{ textAlign: 'center' }}>{t('EndDate')}</label>
                    <div>
                        {form.input('endDate', { type: 'date' })}
                        {checkEndDate(form.values.endDate)}
                    </div>
                    <label style={{ textAlign: 'center' }}>{t('Recursive')}</label>
                    {form.checkBox('recursive')}
                    <label style={{ textAlign: 'center' }}>{t('Users')}</label>
                    {form.multiselect('users', availableDepartments, true)}
                    <div style={{display: "grid", justifyItems: "center"}}>
                    <label style={{ textAlign: 'center' }}>{t('Workspace')}</label>
                    <small className="p-error">{t('communication.workspaces')}</small>
                    </div>
                    {form.multiselect('workspaces', props.workspaces, true)}
                    <label style={{ textAlign: 'center' }}>{t('Jobs')}</label>
                    {form.multiselect('jobs', jobs, true)}
                </div>
                <div className='description-files-container'>
                    {form.editor('description', { containerClassName: 'description-container' })}

                    <div className='files-container'>
                        <span className='title'>
                            {t('communications.files')}
                        </span>
                        {selectedFiles.map(f => <div className='file-row'>
                            <i className='fa fa-trash'
                                onClick={_ => removeSelectedFile(f)} />
                            <strong>{f.fileName}</strong>
                        </div>)}
                    </div>
                </div>
                <div className='errors-container'>
                    {form.errorBox()}
                </div>

                {/** FOOTER */}
                <div className='form-footer'>
                    {/* <button className='button'
                        onClick={fireAddFile}>
                        <i className='fa fa-file' />
                    </button> */}
                    <input
                        ref={inputFile}
                        type='file'
                        onChange={onSelectDocument} />
                    <span className='e'></span>
                    {loading.render()}
                    {props.footer != undefined && <div className='footer'>{props.footer}</div>}

                    <div className='p-buttonset'>
                        <button
                            className='secondary'
                            onClick={previewReceivers}>
                            {t('communications.view.receivers')}
                        </button>

                        <button
                            className='primary'
                            disabled={cannotSave()}
                            onClick={doAdd}>
                            {t('Create')}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
}