import * as React from 'react';

import {
    Portal, RequirePermission, useRemoteData, useTranslation,
} from '@components';
import useContractorFromAppUser from '@components/custom/useContractorFromAppUser';
import {
    goToJobResourceCreateRequirement,
} from '@components/routes';
import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { ISecurity, IWorkSettings } from '@models';
import { RequirementStatusTypes, RequirementTargetType } from '@models/requirements';
import { IJob, IJobHasContractor, IJobLog, JobLogType } from '@models/jobs';
import * as WorkerActions from '@store/actions/workers';
import { search } from '@store/actions/search';

type GetJobLogsT = (workId: number, jobId: number) => Promise<JobLogType[]>;

export interface IProps {
    jobId: number;
    job: IJob;
    selectContractor: Function;
    getJobLogs: GetJobLogsT;
    resourceId: number;
    resourceType: number;
    resourceTitle: string;
    siblingId?: number;
    siblingType?: number;
    siblingContractorId?: number;
    siblingJobHasContractorId?: number;
    security: ISecurity;
    workId: number;
    workSettings: IWorkSettings;
}

export function JobHasResourceRequirements(props: IProps) {
    const { t } = useTranslation();

    const jobLogs = useRemoteData<IJobLog[]>(props.getJobLogs, {
        parameters: [props.workId, props.jobId]
    });

    // const [readOnly, setReadOnly] = React.useState<boolean>(true);
    const [hasAccessJobInformation, setHasAccessJobInformation] = React.useState<boolean>(false);
    const [resourceContractor, setResourceContractor] = React.useState<IJobHasContractor|undefined>();

    const selfContractorId = useContractorFromAppUser(props.workId, props.security);

    const findJobHasContractor = async (resourceType: number, resourceId: number) => {
        if (resourceType == RequirementTargetType.JobHasWorker) {
            const jobHasWorker = await search<any>(
                props.workId,
                'jobs/job_has_worker', {
                    filters: {
                        jobId: props.jobId,
                        jobHasWorkerId: resourceId,
                    },
                    normalizeKeys: true,
                });
            const jobHasContractorId = jobHasWorker.value?.[0]?.jobHasContractorId;
            const jobHasC = props
                .job
                .contractors
                .find(c => c.id == jobHasContractorId);
            setResourceContractor(jobHasC);
        }
        else if (resourceType == RequirementTargetType.JobHasMachinery) {
            const jobHasM = await search<any>(
                props.workId,
                'jobs/job_has_machinery', {
                    filters: {
                        jobId: props.jobId,
                        jobHasMachineryId: resourceId,
                    },
                    normalizeKeys: true,
                });
            const jobHasContractorId = jobHasM.value?.[0]?.jobHasContractorId;
            const jobHasC = props
                .job
                .contractors
                .find(c => c.id == jobHasContractorId);
            setResourceContractor(jobHasC);
        }
    }

    React.useEffect(() => {
        findJobHasContractor(props.resourceType, props.resourceId);
    }, [ props.resourceType, props.resourceId ]);

    React.useEffect(() => {
        if (jobLogs.value) {
            const jobStatus = props.job?.currentStatus?.jobStatusTypeId;
            const logs = jobLogs.value;
            const haveReadInformation = logs
                .find(l =>
                    l.userId == props.security.user?.id
                    && l.jobLogType == JobLogType.ACCESS_JOB_INFORMATION);

            setHasAccessJobInformation(haveReadInformation != null);
        }
    }, [jobLogs.value]);

    const createRequirement = () => {
        goToJobResourceCreateRequirement(
            props.workId,
            props.jobId,
            props.resourceType,
            props.resourceId,
            RequirementStatusTypes.NOT_LOADED);
    }

    const resourceIsContractor = props.resourceType == RequirementTargetType.JobHasContractor;

    const contractor = resourceIsContractor
        ? props.job.contractors.find(c => c.id == props.resourceId)?.contractor
        : undefined;

    const mainDepartment = props.job.departments
        .find(r => r.isMain == true);

    const canSave = (props.security.hasPermission('jobs.edit')
        || props.security.hasWorker(mainDepartment?.responsibleId));

    const canAddWorker = canSave || props.security.isContractor();

    const [isReadonly, setIsReadonly] = React.useState<boolean>(true);

    const setContractorReadonly = () => {
        const isR = props.security.isContractor() && props.workSettings?.mainContractorCanFulfillSubs != true
            ? !(selfContractorId == props.siblingId)
            : false;
        setIsReadonly(isR);
    }

    const setWorkerReadonly = async () => {
        if (props.security.isContractor()) {
            const worker = await WorkerActions.getWorker(props.workId, props.siblingId ?? 0);
            const isR = props.security.isContractor() && props.workSettings?.mainContractorCanFulfillSubs != true
                ? !(selfContractorId == worker.contractorId)
                : false;
            setIsReadonly(false);
        }
        else {
            setIsReadonly(false);
        }
    }

    const setMachineryReadonly = async () => {
        // if (props.security.isContractor()) {
        //     const machinery = await MachineryActions.getMachinery(props.workId, props.siblingId ?? 0);
        //     const isR = props.security.isContractor() && props.workSettings?.mainContractorCanFulfillSubs != true
        //         ? !(selfContractorId == machinery.contractorId)
        //         : false;
        setIsReadonly(false);
        // }
        // else {
        setIsReadonly(false);
        // }
    }

    React.useEffect(() => {
        if (props.resourceType == RequirementTargetType.JobHasContractor) {
            setContractorReadonly();
        }
        else if (props.resourceType == RequirementTargetType.JobHasWorker) {
            setWorkerReadonly();
        }
        else if (props.resourceType == RequirementTargetType.JobHasMachinery) {
            setMachineryReadonly();
        }
        else {
            setIsReadonly(false);
        }
    }, [props.resourceType, props.resourceId, selfContractorId]);

    const permsCtx = {
        jobHasContractor: resourceContractor,
    };

    return <>
        {jobLogs.renderLoading()}
        <Portal container={'#job-actions'}>
            <RequirePermission permission='jobHasResource.createRequirement' explicit>
                <button onClick={createRequirement}>
                    <i className='fas fa-plus' />
                    <span className='e'>{t('Create requirement')}</span>
                </button>
            </RequirePermission>
        </Portal>

        <EditRequirements
            hasAccessJobInformation={hasAccessJobInformation}
            jobId={props.jobId}
            ctx={permsCtx}
            job={props.job}
            readOnly={isReadonly}
            targetId={props.resourceId}
            targetType={props.resourceType}
            targetTitle={props.resourceTitle}
            siblingContractorId={props.siblingContractorId}
            siblingJobHasContractorId={props.siblingJobHasContractorId}
            siblingId={props.siblingId}
            siblingType={props.siblingType}
            workId={props.workId} />
    </>;
}
