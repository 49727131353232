import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as RequirementsStore from '../../store/requirements';
import { EditRequirements } from '../../components/requirements/EditRequirements';
import * as ContractorsStore from '../../store/contractors';
import * as JobsStore from '../../store/jobs';
import * as WorkersStore from '../../store/workers';
import * as MachineriesStore from '../../store/machineries';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...RequirementsStore.plainActions,
        ...ContractorsStore.plainActions,
        ...JobsStore.plainActions,
        ...WorkersStore.plainActions,
        ...MachineriesStore.plainActions,
        appUsers: state.user.work?.users,
        requirementGroups: state.user.requirementGroups,
        requirementStatusTypes: state.user.requirementStatusTypes,
        jobId: state.jobs.selectedJob?.id,
        moduleManager: state.modules.moduleManager,
    }),
    {},
)(EditRequirements as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
