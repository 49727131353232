import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import { EditContractor } from '../../components/contractors/EditContractor';
import { isJobHasContractorPropertyGroupType } from '../../models';
import { ModuleManager } from '@modules';

export default connect(
    (state: ApplicationState, params: any) => ({
        contractorId: params.contractorId ?? parseInt(params.match?.params?.contractorid),
        workId: params.workId ?? parseInt(params.match?.params?.workid),
        ...state.identity,
        ...state.user,
        ...ContractorsStore.plainActions,
        modules: state.modules.moduleManager,
        constants: state.constants,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isJobHasContractorPropertyGroupType),
        resourceStatusTypes: state.user.resourceStatusTypes,
    }),
    ContractorsStore.actionCreators,
)(EditContractor as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
