import * as React from 'react';
import {
    useTranslation, useLoading, useDataTable, useFormDialog, ConfirmDialog,
} from '@components';
import { IDocumentType, INotification, NotificationType } from '@models';
import { onNotification } from '@utils/hooks';
import { DocumentTypeForm } from './DocumentTypeForm';

export interface IProps {
    loading: boolean,
    documentTypes: IDocumentType[];
    notifications: INotification[];
    removeDocumentType: Function;
    saveDocumentType: Function;
}

export function AdminDocumentTypes(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading(props.loading);
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IDocumentType|undefined>();
    const formDialog = useFormDialog({
        editTitle: t('Edit document type'),
        addTitle: t('Add document type'),
    });

    onNotification(
        {ctx: 'admindocumenttypes', type: NotificationType.SUCCESS},
        props.notifications,
    () => {
        formDialog.clear();
    });

    const dataTable = useDataTable({
        data: props.documentTypes,
        className: 'e',
        columns: [{field: 'id', className: 'td-sm'}, 'name', 'description'],
        actions: [
            {icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit},
            {icon: 'trash',  tooltip: t('Delete'), onClick: setShowConfirmRemove}
        ]
    });

    const Header = () => {
        return <div className='r table-toolbar'>
            <button
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right'></i>
                {t('Add')}
            </button>
        </div>
    };

    return (<div className='c he'>
        {loading.renderBox()}

        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => props.removeDocumentType(showConfirmRemove.id)} />}

        {formDialog.render((data: IDocumentType) =>
            <DocumentTypeForm
                data={data}
                notifications={[]}
                onSubmit={props.saveDocumentType}
                footer={formDialog.Footer()} />)}

        {dataTable({
            header: <Header />
        })}
    </div>);
}