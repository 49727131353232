import * as React from 'react';

import { useForm, useLoading, useMessage, useTranslation, ValidationBuilder } from '@components';
import { IRequirement, IRequirementIncidence } from '@models/requirements';
import { IOption, IUserIdentity } from '@models';
import { pushTemporalResource } from '@store/actions/documents';

type saveRequirementIncidenceT = (
    workId: number,
    data: IRequirementIncidence,
    fileResourceId: string|undefined,
    fileResourceName: string|undefined) => Promise<IOption<number>>;

export interface IProps {
    incidence?: IRequirementIncidence;
    requirement: IRequirement;
    saveRequirementIncidence: saveRequirementIncidenceT;
    onClose: Function;
    onSuccess: Function;
    user: IUserIdentity;
}

export function AddRequirementIncidence(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();
    const fileRef = React.useRef<any>();
    const [selectedFile, setSelectedFile] = React.useState<File|undefined>();

    const defaultIncidence: IRequirementIncidence = {
        requirementId: props.requirement.id,
        creationDate: new Date(),
        remarks: '',
        createdById: props.user.id,
    };

    const form = useForm({
        initialValues: props.incidence ?? defaultIncidence,
        validateOnMount: true,
        validate: ValidationBuilder.new()
            .notEmpty('remarks', 'requirements.incidence.remarks-mandatory')
            .lift()
    });

    const getFileResourceId = async (file: File|undefined) => {
        if (file) {
            const res = await pushTemporalResource(file);
            // TODO: controlar posibles errores enviando el fichero al servidor
            return res.value;
        }
        else {
            return undefined;
        }
    };

    const getFileResourceName = async (file: File|undefined) => {
        if(file) {
            return file.name;
        }
        else {
            return undefined;
        }
    };

    const doAdd = loading.wrap(async () => {
        const fileResourceId = await getFileResourceId(selectedFile);
        const fileResourceName = await getFileResourceName(selectedFile);
        const res = await props.saveRequirementIncidence(
            props.requirement.workId,
            form.values,
            fileResourceId,
            fileResourceName)
        message.set(res);
        if (res.hasValue) {
            props.onSuccess(form.values);
        }
    });

    const doSelectFile = () => {
        fileRef.current.click();
    }

    const onFileSelected = (event: any) => {
        const file = event.target.files[event.target.files.length - 1];
        setSelectedFile(file);
    }

    return <div className='c'>
        <div className='c md pd'>
            {form.textarea('remarks', {rows: 10, autoFocus: true})}
        </div>
        {form.errorBox()}
        <div className='footer r r-end'>
            {message.render()}
            <button disabled={loading.isLoading() } style={{alignItems: 'center'}} onClick={doSelectFile}>
                <i className="pi pi-upload pointer" style={{paddingRight: '8px', verticalAlign: 'top'}}/>
                <span style={{verticalAlign: 'text-bottom'}}>{t('Upload file')}</span>
            </button>
            {selectedFile && <span style={{color: 'green', alignItems: 'center'}}>{selectedFile.name}
                <i className='pi pi-file' style={{verticalAlign: 'center', paddingBottom: '1px'}}></i>
            </span>}
            <input onChange={onFileSelected} ref={fileRef} type='file' style={{display: 'none'}}/>
            <hr style={{opacity: '0%'}}/>
            <button disabled={loading.isLoading()} onClick={_ => props.onClose()}>{t('Cancel')}</button>
            <button disabled={loading.isLoading() || form.isInvalid()} onClick={doAdd} className='danger'>{t('Add')}</button>
        </div>
    </div>
}