import * as React from 'react';

import { Card, useTranslation } from '@components';
import NotificationsCenter from '@containers/notifications/NotificationsCenterContainer';
import { IWork } from '@models';

import './SelectWork.scss';

export interface IProps {
    works: IWork[];
    selectWork: Function;
}

const WorkCard = ({ work, selectWork }: {
    work: IWork,
    selectWork: Function,
}) => {
    // return <div style={{width:'30%'}} onClick={_ => selectWork(work.id)}>
    //         <div className='work-card'>
    //             <img src={work.settingsObj?.clientImage ?? '/img/works/soltec.png'} alt='gstore'/>
    //         </div>
    //     </div>;

    var workClass = `card-select-work pointer work-${work.id}`;
    var isMain = work.id === 19 || work.id === 21 || work.id === 22 || work.id === 23;
    return <div className='pointer hover-work' onClick={_ => selectWork(work.id)}><Card className={isMain ? workClass : 'card-select-work pointer work-22'} style={{ margin: '30px', borderRadius: '0px' }}>
        <img src={work.settingsObj?.clientImage ?? '/img/screen/soltec.png'} />
        <div className="title">
            <span className='name'>{work.name}</span>
        </div>
    </Card></div>
}

export function SelectWork(props: IProps) {
    const { t } = useTranslation();

    return <div className='SelectWork g-20 r h-center'>
        <NotificationsCenter className='e'/>

        <div className='e lg pd'>
            <div className='works-title e'>
                <span>{t('Clients')}</span>
                <img src="/img/works/clients.png" alt="gstore" />
            </div>
            <div className='works e'>
                {(props.works ?? []).map((w, i) =>
                    <WorkCard
                        work={w}
                        key={i}
                        selectWork={props.selectWork} />)}
            </div>
        </div>
        {/* <div className='e lg pd'>
            <div className='works-title e'>
                <span>{t('Clients')}</span>
                <img src="/img/works/clients.png" alt="gstore" />
            </div>
        </div> */}
    </div>
}