import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as ConstantsStore from '../../../store/constants';
import * as AdminStore from '../../../store/admin';

import { RequirementTypeSuggestions } from '../../../components/admin/requirementTypes/RequirementTypeSuggestions';

export default connect(
    (state: ApplicationState) => ({
        ...AdminStore.plainActions,
    }),
    {},
)(RequirementTypeSuggestions as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
