import * as React from 'react';

import {
    BreadcrumbItem, Tag,
    useConfirm,
    useDataTable, useLoading, useSearch, useTranslation,
    useScheduler, useSignalR, useDialogs, useSearchFilters, Portal, usePermissions, onChange, Tooltip
} from '@components';
import {
    IJob, IJobHasWorker, IResourceStatusType, ISecurity,
    IUserIdentity, IWork, IWorkSettings, IWorker, ModuleManager, ResourceType
} from '@models';
import EditJobHasWorkerContainer from '@containers/jobs/EditJobHasWorkerContainer';

import './ManageJobWorkers.scss';
import { redirectTo } from '@utils';
import { IWorkerDocumentType, IWorkerType, ResourceStatusType, resourceStatusTypes } from '@models/resources';
import { ResourceStatus } from '@components/common/ResourceStatus';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import { IRequirementNotification } from '@models/requirements';
import { IJobHasContractor } from '@models';

export interface IProps {
    activateJobHasWorker: Function;
    jobHasContractorId?: number;
    getJobHasWorkers: Function;
    job: IJob;
    jobHasWorker?: IJobHasWorker;
    relationId?: number;
    resourceStatusTypes: IResourceStatusType[];
    security: ISecurity;
    work: IWork;
    workSettings: IWorkSettings;
    workers: IWorker[];
    removeJobHasWorker: Function;
    deleteJobHasWorker: Function;
    refreshWorkers: Function;
    appUsers: IUserIdentity[];
    workerTypes: IWorkerType[];
    workerDocumentTypes: IWorkerDocumentType[];
    selectedJobHasContractor?: IJobHasContractor;
    moduleManager: ModuleManager;
}

enum FormType {
    List = 0,
    Worker = 1,
}

type IJobHasWorkerR = IJobHasWorker & {
    workerFullName: string;
    workerCode: string;
    workerContractorName: string;
    workerContractorId: number;

    validatedCount?: number;
    totalCount?: number;
}

export function ManageJobWorkers(props: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const loading = useLoading();

    const [canRemoveState, setCanRemoveState] = React.useState<boolean>(false);

    const [canValidateState, setCanValidateState] = React.useState<boolean>(false);

    const filters = useSearchFilters({
        security: props.security,
        workId: props.work.id,
        name: 'workers/manageJobWorkers',
        persist: true,
        references: {
            resourceStatusTypes: resourceStatusTypes,
        },
    });

    const query = useSearch<IJobHasWorkerR>({
        workId: props.work.id,
        lazy: true,
        search: 'jobs/workers',
        normalizeKeys: true,
        filters: filters.merge({
            jobId: props.job.id,
            workId: props.work.id,
            jobHasContractorId: props.relationId ?? props.selectedJobHasContractor?.id ?? props.jobHasContractorId,
            onlyMainContractors: props.security.isGestorOrWorker() && props.jobHasContractorId == undefined
                ? true
                : undefined,
        }),
    });

    const [formType, setFormType] = React.useState<FormType>(
        props.jobHasWorker ? FormType.Worker : FormType.List);

    const [selectedWorker, setSelectedWorker] = React.useState<IJobHasWorker | undefined>(props.jobHasWorker);

    const doRemoveWorker = loading.wrap(async (i: IJobHasWorker) => {
        await props.removeJobHasWorker(props.work.id, props.job.id, i.id);
        props.refreshWorkers();
        await reload();
    });

    React.useEffect(() => {
        reload();
    }, [filters.signature, props.job]);

    const confirmRemove = useConfirm({
        message: t('Are you sure to finalize worker from this job?'),
        accept: doRemoveWorker
    });

    const doDeleteWorker = loading.wrap(async (i: IJobHasWorker) => {
        await props.deleteJobHasWorker(props.work.id, props.job.id, i.id);
        props.refreshWorkers();
        await reload();
    });

    const confirmDelete = useConfirm({
        message: t('Are you sure you want to remove the worker from this job?'),
        accept: doDeleteWorker
    });

    const reload = async () => {
        await query.doSearch() ?? [];
        const workers = query.value ?? [];
        if (selectedWorker) {
            const worker = workers.find(w => w.id == selectedWorker.id);
            setSelectedWorker(worker);
        }
    }

    const canRemove = props.security.hasPermission('jobHasWorker.remove')

    const calculateCanRemove = async () => {
        const res = await props.moduleManager.requirePermission('jobHasWorker.remove', {
            work: props.work,
            security: props.security
        }, canRemove);

        setCanRemoveState(res ?? false);
    };

    React.useEffect(() => {
        calculateCanRemove();
    }, []);

    const canValidate = props.security.hasPermission('jobs.resources.validate');

    const calculateCanValidate = async () => {
        const res = await props.moduleManager.requirePermission('jobs.resources.validate', {
            work: props.work,
            security: props.security
        }, canValidate);

        setCanValidateState(res ?? false);
    };

    React.useEffect(() => {
        calculateCanValidate();
    }, []);

    React.useEffect(() => {
        if (selectedWorker) {
            setFormType(FormType.Worker);
        }
        else {
            setFormType(FormType.List);
        }
    }, [selectedWorker]);

    const [workers, setWorkers] = React.useState<IJobHasWorkerR[]>([]);

    const workSettings: IWorkSettings | undefined = props.work.settingsObj;
    const HideByDefaultEnded = workSettings?.hideByDefaultEnded ?? false;

    React.useEffect(() => {
        if (HideByDefaultEnded && filters.filters.resourceStatusTypes != 3) {
            const filteredContractors = query.value.filter((c: IJobHasWorkerR) => c.currentStatusType !== 3);
            setWorkers(filteredContractors);
        } else {
            setWorkers(query.value ?? []);
        }
    }, [query.value]);

    const renderStatus = (s: IJobHasWorker) =>
        <ResourceStatus
            currentStatusType={s.currentStatusType!}
            resourceId={s.id}
            resourceType={ResourceType.JobHasWorker}
            workId={props.work.id} />;

    const reactivateJobHasWorker = loading.wrap(async (s: IJobHasWorker) => {
        await props.activateJobHasWorker(props.work.id, props.job.id, s.workerId!, s.id);
        reload();
    });

    const canShowDocumentation = (w: IJobHasWorkerR) =>
        props.security.isContractor()
            // && props.workSettings.mainContractorCanFulfillSubs == true
            ? true
            // : props.security.isContractor()
            // ? w.workerContractorId == selfContractorId
            : true;

    const perms = usePermissions(props, {}, {
        'removeWorker': 'job.workers.remove',
    });

    const createId = (name: string) => {
        return `i${name.replace(/[\s\W]+/g, '').toLowerCase()}`;
    }

    const dataTable = useDataTable({
        tooltip: true,
        columns: [
            { title: t('workerCode'), field: 'workerCode', className: 'td-md', sortKey: 'workerCode', },
            { title: t('Name'), field: 'workerFullName', sortKey: 'workerFullName', },
            {
                title: t('Contractor'),
                sortKey: 'workerContractorName',
                render: d => d.workerContractorName
                    ? <>
                        <span id={`${createId(d.workerContractorName)}`}>
                            <Tag value={d.workerContractorName} />
                        </span>
                        <Tooltip className='job_worker_tooltip' target={`#${createId(d.workerContractorName)}`}>{d.workerContractorName}</Tooltip>
                    </>
                    : null,
                className: 'center',

            },
            { title: t('AssignmentDate'), field: 'startDate', delegate: 'date', className: 'td-md', sortKey: 'startDate', },
            { title: 'currentStatusType', field: 'currentStatusType', render: renderStatus, className: 'td-md', sortKey: 'currentStatusType', },
            {
                title: t('Documentation'), className: 'td-indicator',
                render: d =>
                    canShowDocumentation(d)
                        ? <div onClick={_ => loadRequirements(d.id)} className='pointer'>
                            <Tag value={`${d.validatedCount ?? 0}/${d.totalCount ?? 0}`} />
                        </div>
                        : null
            },
            {
                className: 'td-sm',
                render: s => (s.hasDelayedRevokation && props.work.jobSettings?.showResourceIncidenceIndicator)
                    ? <i
                        className='pi pi-exclamation-triangle'
                        title={t('WorkerDelayedRevokation')} />
                    : null,
            },
        ],
        actions: [
            (canRemoveState)
                ? {
                    icon: d => d.currentStatusType == ResourceStatusType.FINALIZED
                        ? 'fas fa-redo'
                        : 'pi pi-minus-circle',
                    onClick: (d, event) => d.currentStatusType == ResourceStatusType.FINALIZED
                        ? reactivateJobHasWorker(d)
                        : confirmRemove(d, event),
                    tooltip: d => d.currentStatusType == ResourceStatusType.FINALIZED
                        ? t('Reactivate')
                        : t('Finalize job worker')
                }
                : undefined,
            (perms.get('removeWorker'))
                ? { icon: 'trash', onClick: confirmDelete, tooltip: t('Remove job worker') }
                : undefined,
            (canValidateState && !props.security.isContractor())
                ? {
                    icon: 'pi pi-check-square',
                    tooltip: t('Force resource validation'),
                    onClick: r => dialogs.showFromEvent('force-validation', r)()
                }
                : undefined,
        ],
        data: workers,
        loading: query.loading,
    });

    const loadRequirements = (id: number) => {
        redirectTo(`/work/${props.work.id}/jobs/${props.job.id}/worker/${id}/requirements`);
    }

    const scheduler = useScheduler();

    const _ = useSignalR<IRequirementNotification>({
        method: 'RequirementNotification',
        onMessage: r => {
            if (r.workId == props.work.id
                && (r.resourceType == ResourceType.JobHasWorker
                    || r.resourceType == ResourceType.Worker)) {
                // scheduler.schedule(reload, 1500);
            }
        }
    });

    return <div className='c he'>
        <Portal container='#breadcrumb-right'>
            <div>
                {filters.renderAsButton({ className: 'fas fa-filter pointer' })}
            </div>
        </Portal>
        <BreadcrumbItem
            text={t('Workers')}
            onClick={() => setFormType(FormType.List)} />

        {dialogs.render('force-validation', { title: t('Force resource validation') }, (d: IJobHasWorker) =>
            <ForceValidationResourceContainer
                currentStatusType={d.currentStatusType}
                workId={props.work.id}
                requestClose={() => {
                    dialogs.clear();
                    reload();
                }}
                resourceType={ResourceType.JobHasWorker}
                resourceId={d.id} />
        )}

        {formType === FormType.List && <>
            {dataTable()}
        </>}
        {formType === FormType.Worker && <div className='he'>
            {selectedWorker &&
                <EditJobHasWorkerContainer
                    job={props.job}
                    jobHasWorker={selectedWorker}
                    jobHasContractorId={props.selectedJobHasContractor?.id ?? props.jobHasContractorId}
                    onCancel={() => setSelectedWorker(undefined)}
                    onSuccess={() => reload()}
                    work={props.work} />}
        </div>}
    </div>
}
