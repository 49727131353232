import { IWorker } from "@models";

export enum Roles {
    ADMIN = 'admin',
    CONTRACTOR = 'contractor',
    WORKER = 'worker',
    GESTOR = 'gestor',
}

export interface IUserIdentity {
    id: number;
    userName: string;
    email: string;
    roles: string[];
    policies: string[];

    departments?: number[];
    obliqueDepartments?: number[];
    workFunctions?: number[];

    workerIds?: number[];
    contractorIds?: number[];
}

export interface IUserData {
    id?: number;
    userName: string;
    email: string;
    password: string;
    roles: string[];
}

export interface IAuthRequest {
    username: string;
    password: string;
}

export interface IRole {
    id: number;
    name: string;
}
