import * as React from 'react';

import { useForm, useTranslation, G, ValidationBuilder } from '@components';
import { IFolder, folderKindOptions } from '@models/documents';

export interface IProps {
    folder?: IFolder;
    loading?: boolean;

    onCancel?: Function;
    onSubmit?: Function;

    workId: number;
}

export function FolderForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<Partial<IFolder>>({
        initialValues: props.folder ?? { workId: props.workId, name: '' },
        validateOnMount: true,
        validate: f => ValidationBuilder.create(f).notEmpty('name').build(),
    });

    return <div className='c'>
        <div className={'c l200 g-20 md pd'}>
            <G label={t('Name')}>
                {form.input('name', { autoFocus: true })}
            </G>
            <G label={t('Order')}>
                {form.input('sortOrder', { type: 'number' })}
            </G>
            <G label={t('document.permissions')}>
                {form.select('folderKind', {
                    blank: true,
                    options: folderKindOptions,
                })}
            </G>

            {form.errorBox()}
        </div>
        <div className={'footer r r-end'}>
            {props.onCancel && <button
                onClick={() => props.onCancel?.()}
                className={'button'}>{t('Cancel')}</button>}
            {props.onSubmit && <button
                className={'button primary'}
                disabled={props.loading || form.isInvalid()}
                onClick={() => props.onSubmit?.(form.values)}>{t('Save')}</button>}
        </div>
    </div>;
}
