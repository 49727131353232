import * as React from 'react';

import { INotification, IRole, IUserIdentity, NotificationType } from '@models';
import {
    ButtonSet, Chip, Column, DataTable, Dialog, Loading,
    LoadingBox,
    useSearch,
    useTranslation,
    useKeyListener,
} from '@components';
import { onNotification, usePaginator } from '@utils/hooks';
import { IUserFormData, UserForm, isValidUser } from './UserForm';
import { keyIsEnter } from '@utils/key-utils';
import "./AdminUsers.scss"

export interface IProps {
    saveUser: Function;
    loading: boolean;
    loadingMessage?: string;
    loadingNotifications: INotification[];
    notifications: INotification[];
    users: IUserIdentity[];
    roles: IRole[];
    removeUser: Function;
}

type UserWithWorksR = IUserIdentity & {
    workNames?: string;
}

const ADMIN = '1'
const GESTOR = '60'
const NOT_ALLOWED = [ADMIN, GESTOR]

export default function AdminUsers(props: IProps) {
    const { t } = useTranslation();

    const userWorks = useSearch<UserWithWorksR>({
        workId: 0,
        search: 'admin/users'
    });

    const [users, setUsers] = React.useState<UserWithWorksR[]>(props.users.filter(u => !/\w+-remove-\d+/.test(u.userName)));
    const [searchFilter, setSearchFilter] = React.useState<string>('');
    const [showEditUser, setShowEditUser] = React.useState<IUserFormData | undefined>();
    const [showDeleteUser, setShowDeleteUser] = React.useState<IUserFormData | undefined>();
    const [showAdd, setShowAdd] = React.useState<boolean>(false);
    const [addUserData, setAddUserData] = React.useState<IUserFormData>({
        roles: [], policies: [],
    });

    const searchCtrl = React.useRef<any>();
    useKeyListener({
        key: 'f',
        keyCtrl: true,
        handle: () => {
            searchCtrl.current?.focus();
        },
    });



    const fillUsers = () => {
        if (searchFilter && searchFilter.trim() != '') {
            const term = searchFilter.toLowerCase();
            setUsers(pairUsers(props.users.filter(u =>
                u.userName.toLowerCase().includes(term)
                || u.email.toLowerCase().includes(term))));
        }
        else {
            setUsers(pairUsers(props.users));
        }
    }

    const pairUsers = (users: IUserIdentity[]) => {
        return users.map(u => {
            const works = userWorks.value.find(w => w.id === u.id);
            return { ...u, workNames: works?.workNames };
        })
    }

    React.useEffect(() => {

        if(props.users.length > 0 && userWorks.value.length > 0)
        {
            fillUsers();
        }
    }, [props.users, userWorks.value]);

    const doSearch = () => {
        fillUsers();
    }

    const loading = props.loadingNotifications.length > 0;

    const AddUserFooter = () => {
        return <div className='r'>
            <span className='e'></span>
            <ButtonSet>
                <button
                    type='button'
                    onClick={_ => setShowAdd(false)}>
                    {t('Cancel')}
                </button>
                <button
                    className='primary'
                    type='submit'
                    disabled={!isValidUser(addUserData)}>
                    {t('Save')}
                </button>
            </ButtonSet>
        </div>
    }

    onNotification({ ctx: 'adminusers', type: NotificationType.SUCCESS },
        props.notifications,
        (n: INotification) => {
            if (n.action === 'edit') {
                setShowEditUser(undefined);
            }
            else if (n.action === 'add') {
                setShowAdd(false);
            }
        });

    const EditUserFooter = () => {
        return <div className='r'>
            <span className='e' />
            <ButtonSet>
                <button
                    onClick={_ => setShowEditUser(undefined)}>
                    {t('Cancel')}
                </button>
                <button
                    type='submit'
                    disabled={!isValidUser(showEditUser!)}>
                    {t('Save')}
                </button>
            </ButtonSet>
        </div>
    }

    return <div className='c he sc adminUsers'>
        {loading && <Loading />}
        {props.loading && <LoadingBox message={props.loadingMessage} />}

        <div className='r toolbar'>
            <input
                type='search'
                ref={searchCtrl}
                className='e'
                value={searchFilter}
                onKeyDown={e => keyIsEnter(e) ? doSearch() : undefined}
                onChange={e => setSearchFilter(e.target.value)}
                onBlur={_ => doSearch()}
                placeholder={t('Search')} />
            <span className='key-shortcut' title={t('Search')}>Ctrl+F</span>
            <button
                className='tool'
                disabled={loading || showAdd}
                onClick={_ => setShowAdd(true)}>
                <i className='pi pi-plus' />
                {t('Add user')}
            </button>
        </div>

        <DataTable
            className='table'
            value={users}
            paginator
            rows={100}
            scrollable>
            <Column header={t('Id')} bodyClassName='td-sm' headerClassName='td-sm' field={'id'} />
            <Column header={t('Username')} field={'userName'} />
            <Column header={t('Email')} field={'email'} />
            <Column
                header={t('Roles')}
                body={d => <div className='r g-4'>
                    {d.roles.map((r: string, i: number) =>
                        <Chip className='small primary' key={i} label={r} />)}
                </div>} />
            <Column header={t('Policies')}
                body={d => <div className='r r-wrap g-4'>
                    {d.policies.map((r: string, i: number) =>
                        <Chip className='small primary' key={i} label={r} />)}
                </div>} />
            <Column header={t('Works')} body={d => d.workNames} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={u => <i title={t('Edit')}
                    onClick={_ => setShowEditUser(u)}
                    className='pi pi-user-edit pointer' />} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={u => <i title={t('Configuration')}
                    onClick={_ => setShowEditUser(u)}
                    className='pi pi-cog pointer' />} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={u => <i title={t('Works')}
                    onClick={_ => setShowEditUser(u)}
                    className='pi pi-list pointer' />} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={u => <i title={t('Delete')}
                    onClick={_ => setShowDeleteUser(u)}
                    className='pi pi-trash pointer' />}
                    />
        </DataTable>

        {showAdd && <Dialog
            header={t('Add user')}
            visible={true}
            onHide={() => setShowAdd(false)}>
            <UserForm
                availableRoles={props.roles.map(r => r.name).filter(r => r != 'contractor')}
                data={addUserData}
                footer={<AddUserFooter />}
                notifications={props.notifications.filter(a => a.action === 'add')}
                onChange={setAddUserData}
                onSubmit={(data: IUserFormData) => props.saveUser(data)} />
        </Dialog>}

        {showEditUser && <Dialog
            header={t('Edit user')}
            visible={true}
            onHide={() => setShowEditUser(undefined)}>
            <UserForm
                availableRoles={props.roles.map(r => r.name).filter(r => r != 'contractor')}
                data={showEditUser}
                footer={<EditUserFooter />}
                notifications={props.notifications.filter(a => a.action === 'edit')}
                onChange={setShowEditUser}
                onSubmit={(data: IUserFormData) => props.saveUser(data)} />
        </Dialog>}

        {showDeleteUser && <Dialog
            className='remove_user'
            header={t('Remove user')}
            visible={true}
            onHide={() => setShowDeleteUser(undefined)}>
            <div className='remove_user_body'>
                <div className='confirm_text'>
                    <p>{NOT_ALLOWED.includes(showDeleteUser.id!.toString()) ? t('remove.user.not_allowed.text') : t('remove.user.confirm.text')}</p>
                </div>
                <ButtonSet>
                    <button
                        onClick={_ => setShowDeleteUser(undefined)}>
                        {t('Cancel')}
                    </button>
                    {!NOT_ALLOWED.includes(showDeleteUser.id!.toString()) && <button
                        disabled={NOT_ALLOWED.includes(showDeleteUser.id!.toString())}
                        className='danger'
                        onClick={_ => {
                            setShowDeleteUser(undefined);
                            props.removeUser(+showDeleteUser.id!);
                        }}>
                        {t('Delete')}
                    </button>}
                </ButtonSet>
            </div>
        </Dialog>}
    </div>
}
