import * as React from 'react';

import { G, Message, useForm, useLoading, useTranslation, validate, validations as V } from '@components';
import { IWork, IContractor } from '@models';

export interface IProps {
    createWorkContractorAppUser: Function;
    contractor: IContractor;
    onCancel: Function;
    onSuccess: Function;
    work: IWork;
}

type FormData = {
    contractorId?: number;
    startDate: Date;
    userName: string;
    email: string;
    password1: string;
    password2: string;
};

export const validateData = (data: FormData, i18n: Function|undefined = undefined) => {
    const t = i18n ?? function(n: string) { return n; };

    let errors: any = {};
    validate(data, 'userName', errors, V.notEmpty, t('Name is required'));
    validate(data, 'email', errors, V.notEmpty, t('Email is required'));
    validate(data, 'password1', errors, V.notEmpty, t('Password is required'));
    validate(data, 'password2', errors, V.notEmpty, t('Passwords do not match'));

    return errors;
}

export function CreateWorkContractorAppUser(props: IProps) {
    const { t } = useTranslation();

    const loading = useLoading();

    const [error, setError] = React.useState<string|undefined>();

    const saveUser = loading.wrap(async (formData: FormData) => {
        setError(undefined);
        const data = {
            userName: formData.userName,
            email: formData.email,
            password: formData.password1,
        };
        const resp = await props.createWorkContractorAppUser(props.work.id, props.contractor.id, data);
        if (resp.hasValue) {
            props.onSuccess(resp.value);
        }
        else {
            setError(resp.error);
        }
    });

    const form = useForm<FormData>({
        initialValues: {
            contractorId: props.contractor.id,
            startDate: new Date(),
            userName: '',
            email: '',
            password1: '',
            password2: '',
        },
        validate: (data: FormData) => validateData(data, t),
        onSubmit: async (data: FormData) => {
            await saveUser(data);
            return { ok: true};
        }
    });

    return <form onSubmit={form.handleSubmit} className='form-1 lg pd g-10 l200'>
        <G label={t('User')}>
            {form.input('userName', { autoFocus: true })}
        </G>
        <G label={t('Email')}>
            {form.input('email', { type: 'email' })}
        </G>
        <G label={t('Password')}>
            {form.input('password1', { type: 'password' })}
        </G>
        <G label={t('Password confirmation')}>
            {form.input('password2', { type: 'password' })}
        </G>

        <div className='cols-2'>
            {loading.renderBox()}
            {error && <Message severity='error' text={error}/>}
            {form.errorBox()}
        </div>

        <div className='r cols-2'>
            <span className='e'></span>
            <div className='p-buttonset'>
                <button onClick={() => props.onCancel()}>{t('Cancel')}</button>
                <button
                    className='primary'
                    disabled={loading.isLoading()}
                    type='submit'>
                    {t('Create')}
                </button>
            </div>
        </div>
    </form>
}