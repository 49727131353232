import { IReportDefinition } from '@models/reports';

export async function getReports(workId: number): Promise<IReportDefinition[]> {
    const url = `/api/reports/${workId}/reports`;

    const r = await fetch(url, {
        method: 'GET',
        headers: {
            ['Accept']: 'application/json',
            ['Content-Type']: 'application/json',
        }
    });

    if (r.status === 200) {
        const resp = await r.json();
        return resp;
    }
    else {
        throw 'not found';
    }
}

export async function getReport(workId: number, reportName: string): Promise<IReportDefinition> {
    const url = `/api/reports/${workId}/report/${reportName}`;

    const r = await fetch(url, {
        method: 'GET',
        headers: {
            ['Accept']: 'application/json',
            ['Content-Type']: 'application/json',
        }
    });

    if (r.status === 200) {
        const resp = await r.json();
        return resp;
    }
    else {
        throw 'not found';
    }
}