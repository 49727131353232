import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '@store/contractors';
import { ContractorRequirements } from '@components/contractors/ContractorRequirements';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...ContractorsStore.plainActions,
    }),
    {},
)(ContractorRequirements as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
