import * as React from 'react';

import { Loading } from '@components/custom/Loading';
import { getModule } from '../../modules';
import { ApplicationState } from '@store';

export interface IProps {
    moduleName: string;
    moduleView: string;
    mach: any;
    params: any;
    state: ApplicationState;
}

export function ModuleView(props: any) {
    const module = getModule(props.moduleName);
    const moduleView = window.location.href.substring(
        window.location.href.indexOf(props.moduleView)
    );

    return module?.handleView({
        route: moduleView,
        state: props.state,
        params: props.params,
        security: props.state.identity.security,
    }) ?? <div className='md center pd mr'><Loading /></div>;
}
