import * as React from 'react';

import { BreadcrumbItem, useTranslation } from '@components';
import { ISecurity, IWork } from '@models';
import { WorkAccessControlRoutes } from './WorkAccessControlRoutes';

export interface IProps {
    security: ISecurity;
    work: IWork;
}

export function WorkAccessControl(props: IProps) {
    const { t } = useTranslation();

    return <>
        <BreadcrumbItem text={t('Access control')} />
        <WorkAccessControlRoutes />
    </>;
}