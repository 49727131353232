import * as React from 'react';

import {
    F, Toast,
    onNotification, useForm, useLoading, useTranslation, useWorkFormSettings,
    ValidationBuilder,
} from '@components';
import { DynamicProperties } from '../user/DynamicProperties';
import ManageJobDepartmentsContainer from '@containers/jobs/ManageJobDepartmentsContainer';
import {
    IActivityType, IContractType, IDepartment, IJob, IPreventiveResource,
    IWork, IWorkShift, IWorkspace, INotification, NotificationType,
    IPropertyGroupType, PropertyGroupObjectType, IPropertyGroup, ISecurity, JobEncodingType, ModuleManager,
} from '@models';
import { goToJobs } from '@components/routes';

import './JobData.scss';
import dateUtils from '@utils/date-utils';

export interface IProps {
    activityTypes: IActivityType[];
    contractTypes: IContractType[];
    departments: IDepartment[];
    job: IJob;
    notifications: INotification[];
    propertyGroupTypes: IPropertyGroupType[];
    onCancel: Function;
    onSuccess: Function;
    onChange: Function;
    saveJob: Function;
    security: ISecurity;
    work: IWork;
    workShifts: IWorkShift[];
    workspaces: IWorkspace[];
    preventiveResources: IPreventiveResource[];
    moduleManager: ModuleManager;
}

interface IValidateJobOpts {
    validateWorkspaces?: boolean;
}

export const validateJob = (data: IJob, validateOptions: IValidateJobOpts = {}, validateCode: boolean = false) => {
    const builder = ValidationBuilder
        .create(data)
        .notEmpty('workspaceId', 'Workspace is required')
        .notEmpty('name', 'Name is required')
        .notEmpty('startDate', 'Date is required')
        .notEmpty('endDate', 'Date is required')
        .notEmpty('description', 'Description is required');

    if (validateCode) {
        builder.notEmpty('code', 'Code is required');
    }

    return builder.build();
}

export const isValid = (data: IJob) => {
    return Object.keys(validateJob(data)).length == 0;
}

function JobDataImpl(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading(false);
    const toast = React.useRef<any>();

    const settings = useWorkFormSettings({ work: props.work, scope: 'job' });

    const codeIsManual = props.work.settingsObj?.jobEncodingType == JobEncodingType.Manual;

    onNotification({ type: NotificationType.SUCCESS }, props.notifications, (n: INotification) => {
        toast.current.show({
            severity: 'success',
            summary: t('Job saved successfully'),
        });
    });

    const saveJob = loading.wrap(async (req: IJob) => {
        await props.saveJob(props.work.id, req);
    });

    const mainDepartment = props.job.departments
        .find(r => r.isMain == true);

    const canSave = props.security.hasPermission('jobs.edit')
        || props.security.hasWorker(mainDepartment?.responsibleId) || props.security.isGestor();

    const isReadonly = props.security.isContractor() || !canSave;

    const jobPropertiesCreate = (props.security.hasPermission('job.properties') || !isReadonly);

    const propsReadonly = props.security.isContractor() || props.security.hasPolicy('editProps');

    const form = useForm<IJob>({
        validateOnMount: true,
        initialValues: {
            id: props.job?.id ?? 0,
            name: props.job?.name ?? '',
            code: props.job?.code ?? '',
            workId: props.work.id,
            workspaceId: props.job.workspaceId,
            activityTypeId: props.job.activityTypeId,
            workShiftId: props.job.workShiftId,
            departments: props.job.departments,
            contractTypeId: props.job.contractTypeId,
            contractors: props.job.contractors,
            machineries: props.job.machineries,
            properties: props.job.properties ?? [],
            description: props.job?.description,
            startDate: props.job?.startDate,
            endDate: props.job?.endDate,
            remarks: props.job?.remarks,
            preventiveResourceId: props.job.preventiveResourceId,
            isActive: props.job.isActive == true,
        },
        readonly: isReadonly,
        validate: (data: IJob) => {
            return validateJob(data, { validateWorkspaces: props.workspaces.length > 0 }, codeIsManual)
        },
        onSubmit: (data: IJob) => {
            props.onSuccess({
                id: data.id,
                name: data.name,
                code: data.code,
                workspaceId: data.workspaceId,
                workId: data.workId,
                activityTypeId: data.activityTypeId,
                workShiftId: data.workShiftId,
                contractTypeId: data.contractTypeId,
                startDate: data.startDate,
                endDate: data.endDate,
                remarks: data.remarks,
                description: data.description,
                properties: data.properties,
                isActive: data.isActive,
            });
            return { ok: true };
        },
        onChange: props.onChange,
    });

    const cancel = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        goToJobs(props.work.id);
    }

    const formIsValid = () => {
        // #701: Comprobamos que al modificar la fecha, la fecha de inicio/fin no sea anterior a la fecha ya establecida y que la fecha de inicio no sea posterior a la fecha de fin
        if (dateUtils.compareDates(dateUtils.parseDate(form.values.endDate), dateUtils.parseDate(form.values.startDate)) >= 0 &&
            dateUtils.compareDates(dateUtils.parseDate(form.values.endDate), dateUtils.parseDate(props.job.endDate!)) >= 0 &&
            dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.startDate!)) >= 0) {

            return true;

        } else {

            return false;
        }
    }

    return <form
        className={`c sm pd h100p JobData${props.work.id === 23 && ' azsaJobData'}`}
        onSubmit={form.handleSubmit}>

        <Toast ref={toast} />

        <div className='r sm pd'>
            <div className='e form-2 sm h100p form-jobData'>
                <F label={t('Workspace')}>
                    <div className='r vc'>
                        {form.select('workspaceId', {
                            options: props.workspaces,
                            containerClassName: 'e'
                        })}
                        <F label={t('JobCode')}>
                            {codeIsManual &&
                                form.input('code')}
                            {!codeIsManual && <>
                                {props.job?.code && form.span('code')}
                            </>}
                        </F>
                    </div>
                </F>
                <F label={t(`Name${props.work.id == 23 && 'Azsa'}`)}>
                    {form.input('name', { autoFocus: true })}
                </F>
                <F label={t('Departments')}>
                    <ManageJobDepartmentsContainer
                        readonly={isReadonly}
                        job={props.job} />
                </F>
                {settings.show('description') &&
                    <F label={t('Description')}>
                        {form.textarea('description')}
                    </F>}
                {settings.show('workShiftId') &&
                    <F label={t('Work shift')}>
                        {form.select('workShiftId', {
                            options: props.workShifts
                        })}
                    </F>}
                <F label={t('Entry date')}>
                    <div className='r vc'>
                        {form.input('startDate', { type: 'date', style: { width: '150px' } })}
                        <F label={t('End date')}>
                            {form.input('endDate', { type: 'date', style: { width: '150px' } })}
                        </F>
                    </div>
                </F>
                {(dateUtils.compareDates(dateUtils.parseDate(form.values.endDate), dateUtils.parseDate(form.values.startDate)) < 0) &&
                    <small style={{ paddingRight: 140 }} className='p-error center colspan-2'>{t('job.error.endDate')}</small>}
                {(dateUtils.compareDates(dateUtils.parseDate(form.values.endDate), dateUtils.parseDate(props.job.endDate!)) < 0) &&
                    <small style={{ paddingRight: 140 }} className='p-error center colspan-2'>{t('job.error.endDate.beforeActualEndDate')}</small>}
                {(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.startDate!)) < 0) &&
                    <small style={{ paddingRight: 140 }} className='p-error center colspan-2'>{t('job.error.startDate.beforeActualStartDate')}</small>}
                {props.work.id !== 23 ?
                    <div className='r vc colspan-2'>
                        {settings.show('activityTypeId') &&
                            <F label={t('Activity Types')}>
                                {form.select('activityTypeId', {
                                    options: props.activityTypes
                                })}
                            </F>}
                        {settings.show('contractTypeId') &&
                            <F label={t('Contract type')}>
                                {form.select('contractTypeId', {
                                    options: props.contractTypes
                                })}
                            </F>}
                    </div> :
                    <>
                        {settings.show('activityTypeId') &&
                            <F label={t('Activity Types')}>
                                {form.select('activityTypeId', {
                                    options: props.activityTypes
                                })}
                            </F>}
                        {settings.show('contractTypeId') &&
                            <F label={t('Contract type')}>
                                {form.select('contractTypeId', {
                                    options: props.contractTypes
                                })}
                            </F>}
                    </>
                }
                {settings.show('preventiveResourceId') &&
                    <F label={t('Preventive resource presence')}>
                        {form.select('preventiveResourceId', {
                            options: props.preventiveResources
                        })}
                    </F>}
                <F label={t('Remarks')}>
                    {form.textarea('remarks')}
                </F>
                <div id={'job-embed-properties'} />
            </div>
            <div className='c md pd-left g-20'>
                <DynamicProperties
                    className='c g-20'
                    readonly={!canSave}
                    object={form.values}
                    embedPortal={'#job-embed-properties'}
                    objectType={PropertyGroupObjectType.Job}
                    propertyGroupTypes={props.propertyGroupTypes.filter(pg => pg.objectType == PropertyGroupObjectType.Job)}
                    onChange={(v: IPropertyGroup[]) => form.setFieldValue('properties', v)} />
            </div>
        </div>
        <div className='e cols-2' />
        <div className='errors-container'>
            {form.errorBox()}
        </div>
        <div className='cols-2 r'>
            <span className='e' />
            {(!isReadonly || jobPropertiesCreate) &&
                <div className='p-buttonset'>
                    <button
                        onClick={cancel}>
                        {t('Cancel')}
                    </button>
                    {canSave &&
                        <button
                            disabled={(!(loading.isLoading() || loading == undefined) && !(form.isValid() &&
                                formIsValid()) &&
                                isValid(form.values))}
                            type='submit'
                            className='primary'
                            onClick={() => saveJob(form.values)}>
                            {t('Save')}
                        </button>}
                </div>
            }
        </div>
    </form>;
}

export function JobData(props: IProps) {
    return props
        .moduleManager
        .renderComponent<IProps>('JobData', props, JobDataImpl);
}
