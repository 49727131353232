import React from 'react';

import { Chart } from 'primereact/chart';

import { useSearch } from '@components';
import {formatFileSize} from "@utils";

type IStorageRow = {
    work: string;
    size: number;
}

export function StorageChart(props: any) {
    const data = useSearch<IStorageRow>({
        workId: 3,
        search: 'admin/disk_usage'
    });

    const [chartData, setChartData] = React.useState<any>({});

    React.useEffect(() => {
        if (data.value) {
            const labels = data.value.map(x => x.work);
            const dataset = data.value.map(x => x.size);

            const lineStylesData = {
                labels,
                datasets: [
                    {
                        backgroundColor: [
                            '#7cc057',
                            '#f6e072',
                            '#42A5F5',
                            '#7E57C2',
                            '#5d654d',
                            '#a13131',
                            '#26A69A'
                        ],
                        label: 'A',
                        data: dataset,
                        fill: false,
                        tension: .4,
                        borderColor: '#42A5F5'
                    }
                ]
            };

            setChartData(lineStylesData);
        }
        else {
            setChartData({});
        }
    }, [data.value]);

    const basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(t: any, d: any) {
                        return formatFileSize(t.raw);
                    }
                }
            },
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057',
                    beginAtZero: true,
                    callback: function(value: string, index: number) {
                        return formatFileSize(parseInt(value));
                    }
                },
                grid: {
                    color: '#ebedef'
                }
            },
        }
    };

    return <div>
        {data.renderLoading()}
        <div className="card">
            <Chart type="bar" data={chartData} options={basicOptions} />
        </div>
    </div>
}