import * as React from 'react';

import { Card, G, Tag, useForm, useLoading, useMessage, useTranslation, useWorkFormSettings, ValidationBuilder } from '@components';
import {
    IActivityType, IConstants, IContractor, IFieldValidation, ILegalForm, IPropertyGroupType, IResourceStatusType, ISecurity, IWork,
    PropertyGroupObjectType, ResourceType
} from '@models';

import './ChangePassword.scss';
import { CountryRegionSelect } from '@components/common/CountryRegionSelect';
import { saveContractor } from '@store/actions/contractors';
import { useRemoteData, useToast } from '@utils/hooks';
import { DynamicProperties } from './DynamicProperties';

type GetContractorF = (workId: number, id: number) => Promise<IContractor>;
type ContractorFromAppUserF = (workId: number) => Promise<IContractor>;

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    legalForms: ILegalForm[];
    activityTypes: IActivityType[];
    constants: IConstants;
    getContractor: GetContractorF;
    getContractorFromAppUser: ContractorFromAppUserF;
    work: IWork;
    contractor: IContractor;
    contractorId: number;
    security: ISecurity;
    saveContractor: Function;
    resourceStatusTypes: IResourceStatusType[];
    // showRequirements?: boolean;
}

enum FormType {
    Data = 1,
    Requirements = 2,
    AccessData = 3,
}

const validateUserData = (fieldValidations: IFieldValidation[] | undefined) => {
    return ValidationBuilder.new()
        .notEmpty('')
        .withFieldValidations(ResourceType.Contractor, fieldValidations)
        .lift();
}

function UserConfigurationForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const toast = useToast();
    // const dialogs = useDialogs();
    const saveContractor = loading.wrap(async (req: IContractor) => {
        const resp = await props.saveContractor(props.work.id, req);
        if (resp.hasValue) {
            toast.show(t('Contractor preferences updated'));
            return { ok: true };
        }
        else {
            return { ok: false, message: resp.error };
        }
    }
    );

    // const settings = useWorkFormSettings({work: props.work, scope: 'contractor'});

    const canSave = props.security.hasPermission('contractors.edit');

    // const validateContractor = validateUserData(props.work.validations ?? []);

    const form = useForm<IContractor>({
        readonly: !canSave,
        initialValues: {
            id: props.contractorId,
            workId: props.contractor.workId,
            name: props.contractor.name,
            code: props.contractor.code,
            address: props.contractor.address,
            postalCode: props.contractor.postalCode,
            countryId: props.contractor.countryId,
            regionId: props.contractor.regionId,
            countyId: props.contractor.countyId,
            isActive: props.contractor.isActive,
            properties: props.contractor.properties,
            phone: props.contractor.phone,
            remarks: props.contractor.remarks,
            providerCode: props.contractor.providerCode
        },
        // validate: validateContractor,
        onSubmit: (data: IContractor) => {
            return saveContractor(data);
        }
    })

    const doSave = (..._: any) => {
        return saveContractor(form.values);
    }

    return <div className='c center lg mr ChangePassword'>
        <Card header={t('Preferences')} className=' bg-header pd-header'>
            <div className='form-1 l400 c'>
                <div className='md mr pd mutted r center g-20' style={{ marginBottom: '40px' }}>
                    {t('Changing preferences')}
                </div>
                <G label={t('Place')}>
                    {form.input('place')}
                </G>
                <div style={{ display: 'flex' }}>
                    <G label={t('IVA')}>{form.input('iva')}</G><G label={t('Provider Code')}>{form.input('code')}</G>
                </div>
                <G label={t('social.region')}>
                    {form.input('social.region')}
                </G>
                <G label={t('activity')}>
                    {form.input('activity')}
                </G>
                <G label={t('address')}>{form.input('address')}
                </G>
                <div style={{ display: 'flex' }}>
                    <G label={t('postal code')}>{form.input('postalCode')}</G>  <G label={t('Province')}>{form.input('province')}</G>
                </div>
                <G label={t('Phone')}>
                    {form.input('phone')}
                </G>
                <G label={t('email')}>
                    {form.input('email')}
                </G>
                <DynamicProperties
                    className='e sm mr-left'
                    inline
                    readonly={!canSave}
                    object={props.contractor}
                    onChange={v => form.setFieldValue('properties', v)}
                    embedPortal={'#embed-properties'}
                    objectType={PropertyGroupObjectType.JobHasContractor}
                    propertyGroupTypes={props.propertyGroupTypes} />
                <G label={t('Remarks')} className='CorrectRemark'>
                    {form.textarea('remarks')}
                </G>
                {form.errorBox()}
            </div>
            <div className='lg mr-top pd-top border-top r'>
                <div className='e' />
                {loading.render()}
                {canSave && <button
                    className='primary'
                    disabled={form.isInvalid()}
                    onClick={doSave}>{t('Change preferences')}</button>}
            </div>
        </Card>
    </div>
}

export function UserConfiguration(props: IProps) {
    const data = useRemoteData<IContractor>(props.getContractor, {
        parameters: [props.work.id, props.contractorId]
    });

    return <div className='EditContractorContainer'>
        {data.renderLoading()}
        {data.value && <UserConfigurationForm
            {...props}
            // showRequirements={props.showRequirements}
            contractor={data.value} />}
    </div>
}