import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as WorkersStore from '@store/workers';
import { EditWorkerForm } from '@components/workers/EditWorkerForm';
import { isContractorWorkerPropertyGroupType } from '@models';
import * as RequirementsStore from '@store/requirements';

export default connect(
    (state: ApplicationState, props: any) => ({
        workId: props.workId ?? parseInt(props.match.params.workid),
        workerId: props.workerId ?? parseInt(props.match.params.workerid),
        ...state.identity,
        ...state.user,
        ...WorkersStore.plainActions,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isContractorWorkerPropertyGroupType),
        resourceStatusTypes: state.user.resourceStatusTypes,
        ...RequirementsStore.plainActions
    }),
    WorkersStore.actionCreators,
)(EditWorkerForm as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
