import * as React from 'react';

import {
    useTranslation,
    useLoading, useForm, usePermissions
} from '@components';
import {
    IWorker, IWork, ISecurity, IPropertyGroupType, PropertyGroupObjectType, ModuleManager,
} from '@models';
import './WorkerJobsDialog.scss'
import { DynamicProperties } from '@components/user/DynamicProperties';
import { redirectTo } from '@utils';

type GetWorkerF = (workId: number, id: number) => Promise<IWorker>;

export interface IProps {
    work: IWork;
    moduleManager: ModuleManager;
    requestClose: Function;
    security: ISecurity;
    workerId: number;
    worker: IWorker;
    getWorker: GetWorkerF;
    propertyGroupTypes: IPropertyGroupType[];
    saveWorker: Function;
}

export function ProfessionalQualificationsDialog(props: IProps) {
    const form = useForm<any>();
    const loading = useLoading();
    const worker = React.useRef<IWorker>();

    const [propertyGroup, setPropertyGroups] = React.useState<IPropertyGroupType | undefined>(
        props.propertyGroupTypes.find(x => x.name == 'Competencias Profesionales'));

    React.useEffect(() => {
        const p = props.propertyGroupTypes.find(x => x.name == 'Competencias Profesionales');
        if (p) {
            p.properties = p.properties?.sort((a, b) => a.title.localeCompare(b.title)) ?? [];
            setPropertyGroups(p);
        }
    }, [props.propertyGroupTypes]);

    const doSave = async () => {
        const data = await props.getWorker(props.work.id, props.workerId);
        const newWorker = { ...data, properties: form.values.properties };
        const _ = await props.saveWorker(props.work.id, newWorker);

        if (props.work.id != 23) {
            redirectTo(`/work/${props.work.id}/workers/${props.workerId}/requirements`);
        } else {
            props.requestClose();
        }

    };

    const initialize = loading.wrap(async () => {
        loading.start();
        const data = await props.getWorker(props.work.id, props.workerId);
        worker.current = data;
        form.setFieldValue({ properties: data.properties ?? [] });
        loading.stop();
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const { t } = useTranslation();

    const perms = usePermissions(props, {}, {
        edit: 'workers.qualifications.edit',
    });

    return <div>
        {loading.render()}
        {!loading.isLoading() && worker.current != undefined && propertyGroup != null && <>
            <DynamicProperties
                object={worker.current}
                inline
                onChange={v => form.setFieldValue('properties', v)}
                objectType={PropertyGroupObjectType.Worker}
                propertyGroupTypes={[propertyGroup]}
                readonly={!perms.get('edit')}
            />
            <div className='r we sm mr-top rounded' style={{ paddingRight: '10px' }}>
                <span className='e'></span>
                {perms.get('edit') && <button
                    className='primary'
                    onClick={doSave}>
                    {t('Save')}
                </button>}
            </div>
        </>}
    </div>
}
