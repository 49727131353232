import * as React from 'react';

import { useDialogs, useMessage, useTranslation, Portal } from '@components';
import {
    goToContractorRequirements, goToJob, goToJobContractors, goToJobEditContractor, goToJobEditContractorRequirements,
    goToJobHasMachinery, goToJobInformationLoaded, goToJobWorker
} from '@components/routes';
import ValidateRequirementsFromNotificationMessages from '@containers/notifications/ValidateRequirementsFromNotificationMessagesContainer';
import { DataLoader } from '@components/custom/DataLoader';
import ViewRequirementHistory from '@containers/requirements/ViewRequirementHistoryContainer';
import { IOption, ISecurity, ResourceType, IWork } from '@models';
import { IMessage, MessageType } from '@models/notifications';
import { IRequirement } from '@models/requirements';
import { redirectTo } from '@utils';
import * as R from '@components/routes';

import { getRequirement } from '@store/actions/requirements';

type ResolveMessageF = (id: string) => Promise<IOption<boolean>>;

export interface IProps {
    message?: IMessage;
    resolveMessage: ResolveMessageF;
    requestCancel: Function;
    security: ISecurity;
    work: IWork;
    workId: number;
}

const __resolvedMessages: string[] = [];

export function NotificationMessageEffect({
    message, requestCancel, resolveMessage,
    security, workId,
    work,
}: IProps) {
    const dialogs = useDialogs();
    const err = useMessage();
    const { t } = useTranslation();

    const goToRequirement = (data: any) => {
        if (data.ResourceType == ResourceType.Contractor) {
            R.goToContractorRequirements(data.WorkId, data.ResourceId);
        }
        else if (data.ResourceType == ResourceType.Machinery) {
            R.goToMachineryRequirements(data.WorkId, data.ResourceId);
        }
        else if (data.ResourceType == ResourceType.Worker) {
            R.goToWorkerRequirements(data.WorkId, data.ResourceId);
        }
        else if (data.ResourceType == ResourceType.JobHasContractor) {
            R.goToJobEditContractorRequirements(data.WorkId, data.JobId, data.ResourceId);
        }
        else if (data.ResourceType == ResourceType.JobHasMachinery) {
            R.goToJobEditMachineryRequirements(data.WorkId, data.JobId, data.ResourceId);
        }
        else if (data.ResourceType == ResourceType.JobHasWorker) {
            R.goToJobEditWorkerRequirements(data.WorkId, data.JobId, data.ResourceId);
        }
    }

    React.useEffect(() => {
        if (message && message.notificationType == MessageType.REQUIREMENT_EXPIRED && !__resolvedMessages.includes(message.id)) {
            // __resolvedMessages.push(message.id);
            const data = message.data;
            goToRequirement(message.data);
        }
        else if (message && message.notificationType == MessageType.REQUIREMENT_INCIDENCE && !__resolvedMessages.includes(message.id)) {
            // __resolvedMessages.push(message.id);
            const data = message.data;
            goToRequirement(message.data);
        }
        else if (message && message.notificationType == MessageType.MESSAGE && !__resolvedMessages.includes(message.id)) {
            const data = message.data;
            if (data.Route) {
                R.redirectTo(data.Route);
            }
            else if (message.data.JobId) {
                goToJob(message.data.WorkId, message.data.JobId);
            }
            else {
                requestCancel();
            }
        }
        else if (message && message.notificationType === MessageType.JOB_CREATED) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            goToJob(workId, jobId);
        }
        else if (message && message.notificationType === MessageType.REQUIREMENT_VALIDATED && message.data.JobId && message.data.JobHasContractorId) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            const jobHasContractorId = message.data.JobHasContractorId;
            goToJobEditContractorRequirements(workId, jobId, jobHasContractorId);
        }
        else if (message && message.notificationType === MessageType.REQUIREMENT_VALIDATED && message.data.JobId) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            goToJob(workId, jobId);
        }
        else if (message
            && message.notificationType === MessageType.PENDING_REQUIREMENT
            && message.data.ResourceType === ResourceType.JobHasWorker
            && message.data.JobId) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            // goToJob(workId, jobId);
        }
        else if (message
            && message.notificationType === MessageType.PENDING_REQUIREMENT
            && message.data.ResourceType === ResourceType.JobHasMachinery
            && message.data.JobId) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            // goToJob(workId, jobId);
        }
        else if (message
            && message.notificationType === MessageType.PENDING_REQUIREMENT
            && message.data.ResourceType === ResourceType.JobHasContractor
            && message.data.JobId) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            // goToJob(workId, jobId);
        }
        else if (message && message.notificationType === MessageType.INVALID_ITA && message.data.ResourceId) {
            const resourceId = message.data.ResourceId;
            const workId = message.data.WorkId;
            goToContractorRequirements(workId, resourceId);
        }
        else if (message && message.notificationType === MessageType.SUBCONTRACTOR_REQUEST_ACCEPTED
            && message.data
            && message.data.JobId) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            goToJobContractors(workId, jobId);
        }
        else if (message && message.notificationType === MessageType.REQUIREMENT_INCIDENCE && message.data.JobId) {
            const jobId = message.data.JobId;
            const workId = message.data.WorkId;
            goToJob(workId, jobId);
        }
        else if (message && message.notificationType === MessageType.SUBCONTRACTOR_REQUEST) {
            redirectTo(`/work/${workId}/contractor/subcontractorrequests`);
        }
        else if (message && message.notificationType === MessageType.SUBCONTRACTOR_REQUEST_REJECTED) {
            redirectTo(`/work/${workId}/contractor/subcontractorrequests`);
        }
        else if (message && message.notificationType === MessageType.JOB_INFORMATION_LOADED) {
            goToJobInformationLoaded(workId, message.data.JobId);
        }
        else if (message && message.notificationType === MessageType.JOB_FINALIZED) {
            goToJob(workId, message.data.JobId);
        }
        else if (message && message.notificationType === MessageType.MESSAGE) {
            goToJob(workId, message.data.JobId);
        }
        else if (message && message.notificationType === MessageType.JOB_AUTHORIZED) {

            const jobId = message.data.JobId;
            goToJob(workId, jobId);
        }
        else if (message && message.notificationType === MessageType.JOB_REVOKED) {
            const jobId = message.data.JobId;
            goToJob(workId, jobId);
        }
        else if (message && message.notificationType === MessageType.RESOURCE_AUTHORIZED) {
            const jobId = message.data.JobId;
            const resourceId = message.data.ResourceId;

            if (message.data.ResourceType === ResourceType.JobHasMachinery) {
                goToJobHasMachinery(workId, jobId, resourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasWorker) {
                goToJobWorker(workId, jobId, resourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasContractor) {
                goToJobEditContractor(workId, jobId, resourceId);
            }
            else if (message.data.ResourceType === ResourceType.Worker) {
                R.goToWorkerRequirements(workId, resourceId);
            }
            else if (message.data.ResourceType === ResourceType.Machinery) {
                R.goToMachineryRequirements(workId, resourceId);
            }
            else if (message.data.ResourceType === ResourceType.Contractor) {
                R.goToContractorRequirements(workId, resourceId);
            }
        }
        else if (message && message.notificationType === MessageType.RESOURCE_REJECTED) {
            const jobId = message.data.JobId;
            const resourceId = message.data.ResourceId;
            if (message.data.ResourceType === ResourceType.JobHasMachinery) {
                goToJobHasMachinery(workId, jobId, resourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasWorker) {
                goToJobWorker(workId, jobId, resourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasContractor) {
                goToJobEditContractor(workId, jobId, resourceId);
            }
        }
        else if (message && message.notificationType == MessageType.MODULE_MESSAGE) {
            redirectTo(message.data.URL);
        }
        else if (message && message.notificationType === MessageType.REQUIREMENT_NOTE) {
            if (message.data.ResourceType === ResourceType.JobHasWorker) {
                R.goToJobEditWorkerRequirements(workId, message.data.JobId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasMachinery) {
                R.goToJobEditMachineryRequirements(workId, message.data.JobId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasContractor) {
                goToJobEditContractorRequirements(workId, message.data.JobId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasDepartment) {
                R.goToJobRequirements(workId, message.data.JobId)
            }
            else if (message.data.ResourceType === ResourceType.Worker) {
                R.goToWorkerRequirements(workId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.Machinery) {
                R.goToMachineryRequirements(workId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.Contractor) {
                goToContractorRequirements(workId, message.data.ResourceId);
            }
        }
        else if (message && message.notificationType === MessageType.REQUIREMENT_NEAR_EXPIRATION) {
            if (message.data.ResourceType === ResourceType.JobHasWorker) {
                R.goToJobEditWorkerRequirements(workId, message.data.JobId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasMachinery) {
                R.goToJobEditMachineryRequirements(workId, message.data.JobId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasContractor) {
                goToJobEditContractorRequirements(workId, message.data.JobId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.JobHasDepartment) {
                R.goToJobRequirements(workId, message.data.JobId)
            }
            else if (message.data.ResourceType === ResourceType.Worker) {
                R.goToWorkerRequirements(workId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.Machinery) {
                R.goToMachineryRequirements(workId, message.data.ResourceId);
            }
            else if (message.data.ResourceType === ResourceType.Contractor) {
                goToContractorRequirements(workId, message.data.ResourceId);
            }
        }
    }, [message]);

    const loadRequirement = (data: any) => {
        const { WorkId, ResourceType, ResourceId, RequirementId } = data;
        if (!ResourceType || !ResourceId || !RequirementId) {
            err.setError(t('requirement.get.error'));
            return new Promise((resolve) => resolve({ error: true }));
        }
        else {
            return getRequirement(WorkId, ResourceType, ResourceId, RequirementId);
        }
    }

    const clearRequirement = () => {
        dialogs.clear();
        requestCancel();
    }

    if (message && dialogs.get('requirement.history')) {
        return dialogs.render('requirement.history', {}, (data: any) =>
            <DataLoader provider={() => loadRequirement(data)}>
                {(req: any) =>
                    <>
                        {req && !req.error &&
                            <ViewRequirementHistory
                                canDownload={true}
                                requirement={req}
                                requestClose={clearRequirement}
                                work={work} />}
                        {req.error && <div className='c'>
                            {err.render()}
                            <div className='footer r r-end'>
                                <button onClick={clearRequirement}>{t('Close')}</button>
                            </div>
                        </div>}
                    </>
                }
            </DataLoader>);
    }
    else if (message && message.notificationType === MessageType.PENDING_REQUIREMENT) {
        return <Portal container='#root'>
            <ValidateRequirementsFromNotificationMessages
                message={message}
                requestCancel={requestCancel}
                workId={message.data.WorkId} />
        </Portal>
    }
    else if (message && message.notificationType === MessageType.SUBCONTRACTOR_REQUEST) {
        return null;
    }
    else {
        return null;
    }
}