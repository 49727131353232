import * as React from 'react';

import './Module.scss';

import { useTranslation, Card, LoadingBox } from '@components';

import { LastLogs } from './logs/LastLogs';
import SampleChart from '../samples/Chart';
import { StorageChart } from './charts/StorageChart';
import { IUserIdentity } from '@models';

export interface IProps {
    loading: boolean;
    loadingMessage?: string;
    initialize: Function;
    users: IUserIdentity[];
}

export default function Module(props: IProps) {
    const { t } = useTranslation();

    React.useEffect(() => {
        props.initialize();
    }, []);

    return <div>
        {props.loading && <div className='md mg pd center'>
            <LoadingBox message={props.loadingMessage} />
        </div>}

        <div className='admin-module-cards'>
            <Card title="Logs" className='logs-card'>
                <LastLogs users={props.users} />
            </Card>
            <Card title={t('Storage')}>
                <StorageChart />
            </Card>
            <Card title="Métricas" className='metrics-card'>
                <SampleChart />
            </Card>
        </div>
    </div>
}