import * as React from 'react';

import {
    F, Message, TabPanel, TabView, Tag,
    useDataTable, useDialogs, useForm, useLoading, useMessage, useRemoteData, useSearch, useTranslation
} from '@components';
import { IContractor, IOption, ISubContractorRequest, IWork, IUserIdentity, ModuleManager } from '@models';
import './ManageSubContractorRequests.scss';

type ProcessF = (workId: number, subContractorRequestId: number, reason?: string) => IOption<number>;

export interface IProps {
    acceptSubContractorRequest: ProcessF;
    getContractorFromAppUser: Function;
    getSubContractorRequestsAsTarget: Function;
    rejectSubContractorRequest: ProcessF;
    work: IWork;
    getJob: Function;
    getContractor: Function;
    getJobHasContractor: Function;
    getJobHasContractorFromAppUser: Function;
    user?: IUserIdentity;
    moduleManager: ModuleManager;
}

function RequestState({ request }: { request: ISubContractorRequest }) {
    const { t } = useTranslation();

    if (request.rejected) {
        return <Tag value={t('Rejected request')} className='danger' />;
    }
    else if (!request.acceptedByContractor && !request.acceptedByWork) {
        return <Tag value={t('Pending')} />;
    }
    else if (request.acceptedByContractor) {
        return <Tag value={t('Accepted')} className='success' />;
    }
    else if (request.acceptedByWork) {
        return <Tag value={t('Accepted by main company')} className='info' />;
    }
    else {
        return null;
    }
}

function ProcessSubContractorRequest({
    accept, reject,
    onClose, onSuccess, request,
    workId
}: {
    accept: ProcessF, reject: ProcessF,
    onSuccess: Function,
    onClose: Function, request: ISubContractorRequest,
    workId: number,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const form = useForm({
        initialValues: request,
    });

    const doAccept = loading.wrap(async () => {
        const res = await accept(workId, request.id!);
        message.set(res);

        if (res.hasValue) {
            onSuccess();
        }
    });

    const doReject = loading.wrap(async () => {
        const res = await reject(workId, request.id!, form.get<string>('rejectedReason'));
        message.set(res);

        if (res.hasValue) {
            onSuccess();
        }
    });

    return <div className='c'>
        <div className='c md pd'>
            <Message text={t('Process subcontractor request')}></Message>
            <div className='r v-center sm pd'>
                <strong className='sm pd-right'>{request.sourceContractor?.name}</strong>
                <span>{t('Has requested you as subcontractor for the job')}</span>
                <span className='sm pd-right pd-left'>{request.job?.name}</span>
                <Tag>{request.job?.code}</Tag>
            </div>
            <TabView >
                <TabPanel header={t('Accept')}>
                    <div className='r'>
                        <div className='e' />
                        <button disabled={loading.isLoading()} onClick={doAccept}>{t('Accept request')}</button>
                    </div>
                </TabPanel>
                <TabPanel header={t('Reject')}>
                    <div className='form-1 l200'>
                        <F label={t('Reason')}>
                            {form.textarea('rejectedReason')}
                        </F>
                        <div className='r'>
                            <div className='e'></div>
                            <button disabled={loading.isLoading()} onClick={doReject} className='danger'>{t('Rejected reason')}</button>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>

        {message.render()}

        <div className='footer r'>
            {loading.render()}
            <div className='e'></div>
            <button onClick={() => onClose()}>{t('Cancel')}</button>
        </div>
    </div>
}

export function ManageSubContractorRequestsImpl(props: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const [data, setData] = React.useState<ISubContractorRequest[]>([]);

    const [selfContractorId, setSelfContractorId] = React.useState<number>(0);

    React.useEffect(() => {
        props.getContractorFromAppUser(props.work?.id).then((u: IContractor) =>
            setSelfContractorId(u?.id));
    }, [props.work?.id]);

    const prepareToProcessRequest = (r: ISubContractorRequest) => {
        dialogs.show('process', r);
    }

    const needWorkAcceptance = props.work.settingsObj?.acceptSubContractorRequests ?? false;

    const canProcessRequest = (r: ISubContractorRequest) => {
        return !r.rejected
            && !r.acceptedByContractor
            && (r.acceptedByWork || !needWorkAcceptance)
            && (r.targetContractor?.id == selfContractorId);
    }

    const query = useRemoteData<ISubContractorRequest[]>(
        props.getSubContractorRequestsAsTarget, {
        parameters: [props.work.id],
    });

    const invitationSearch = useSearch({
        workId: props.work.id,
        search: 'jobs/subcontractor.invitations.contractors',
        filters: {
            workId: props.work.id,
            contractorId: selfContractorId,
        },
        lazy: true,
    });

    const userInvitationsSearch = useSearch({
        workId: props.work.id,
        search: 'jobs/subcontractor.invitations.users',
        filters: {
            workId: props.work.id,
        },
        lazy: true,
    });

    const initialize = async () => {
        const req = await query.query();
        const invitations = await invitationSearch.doSearch();
        const userInvitations = await userInvitationsSearch.doSearch();


        const selfContractor = await props.getContractorFromAppUser(props.work?.id);
        const selfContractorId = selfContractor?.id;

        let parentIds: any[] = [];

        if (props.user?.roles.includes('Contractor')) {
            parentIds = (invitations.map((i: any) => {
                if (selfContractorId === i.sourceId) {
                    return i.jobHasContractorId;
                }

                if (selfContractor.name != null && selfContractor.name === i.targetName) {
                    return i.jobHasContractorId;
                }
            }));

            parentIds.push(selfContractorId);
        } else {
            parentIds = (invitations.map((i: any) => {
                return i.jobHasContractorId;
            }));
        }


        parentIds = parentIds.filter((i: any) => i != undefined);

        invitations.map((x: any) => {
            if (parentIds.includes(x.parentId)) {
                parentIds.push(x.jobHasContractorId);
            }
        });

        let invitationsAsRequests: ISubContractorRequest[] = [];

        for (const invitation of invitations) {
            if (parentIds.includes(invitation.jobHasContractorId)) {
                let request: ISubContractorRequest = {
                    jobsDescription: invitation.description,
                    jobId: invitation.jobId,
                    shipmentNumber: invitation.shipmentNumber,
                    sourceContractorId: invitation.sourceId,
                    sourceContractor: await props.getContractor(props.work.id, invitation.sourceId),
                    targetContractorId: 0,
                    targetContractor: invitation.targetName,
                    workId: props.work.id,
                    dateTime: invitation.dateTime,
                    acceptedByContractorDateTime: invitation.acceptedDate,
                    rejected: invitation.accepted != null ? !invitation.accepted : false,
                    acceptedByContractor: invitation.accepted,
                    job: invitation.jobName,
                    id: 0,
                    jobHasContractorId: 0
                };
                invitationsAsRequests.push(request);
            }
        }

        let userInvitationsAsRequests: ISubContractorRequest[] = [];
        for (const invitation of userInvitations) {
            if (invitation.jobId != null && (invitation.parentId && parentIds.includes(invitation.parentId) || invitation.contractorName === selfContractor?.name)) {
                let request: ISubContractorRequest = {
                    jobsDescription: invitation.description,
                    jobId: invitation.jobId,
                    shipmentNumber: invitation.shipmentNumber,
                    sourceContractorId: 0,
                    sourceContractor: invitation.source,
                    targetContractorId: 0,
                    targetContractor: invitation.contractorName,
                    dateTime: invitation.dateTime,
                    workId: props.work.id,
                    rejected: invitation.accepted != null ? !invitation.accepted : false,
                    acceptedByContractor: invitation.accepted,
                    acceptedByContractorDateTime: invitation.acceptedDate,
                    job: invitation.description,
                    id: 0,
                    jobHasContractorId: 0
                };
                userInvitationsAsRequests.push(request);
            }
        }

        setData(req.concat(invitationsAsRequests).concat(userInvitationsAsRequests));
    }

    React.useEffect(() => {
        initialize();
    }, [props.work.id]);

    const renderRequestedContractor = (r: ISubContractorRequest) => {
        const className = r.targetContractor?.id === selfContractorId
            ? 'strong'
            : undefined;
        return <span className={className}>{r.targetContractor?.name ?? r.targetContractor}</span>;
    }

    const dataTable = useDataTable({
        className: 'a-left',
        columns: [
            { title: t('Date'), sortKey: 'dateTime', className: 'td-datetime a-left', field: 'dateTime', delegate: 'date' },
            { title: t('Applicant'), sortKey: 'sourceContractor', className: 'a-left', render: r => r.sourceContractor?.name ?? r.sourceContractor },
            { title: t('Requested contractor'), sortKey: 'targetContractor', className: 'a-left', render: renderRequestedContractor },
            { title: t('Description'), sortKey: 'jobsDescription', className: 'a-left', field: 'jobsDescription' },
            { title: t('Job'), sortKey: 'job.code', className: 'a-left', render: r => r.job?.name ?? r.job },
            { title: t('State'), sortKey: 'acceptedByContractor', className: 'td-lg a-left', render: r => <RequestState request={r} /> },
        ],
        actions: [
            { icon: 'play', className: 'a-left', onClick: prepareToProcessRequest, disabled: r => !(canProcessRequest(r)) },
        ],
        data: data,
        loading: query.loading,
    });

    const refresh = () => {
        dialogs.clear();
        initialize();
    }

    return <div className='he c'>
        {dataTable()}

        {dialogs.render(
            'process',
            {},
            (data: ISubContractorRequest) =>
                <ProcessSubContractorRequest
                    accept={props.acceptSubContractorRequest}
                    reject={props.rejectSubContractorRequest}
                    workId={props.work.id}
                    onSuccess={refresh}
                    onClose={() => dialogs.clear()}
                    request={data} />)}
    </div>;
}

export function ManageSubContractorRequests(props: IProps) {
    const res = props
        .moduleManager
        .renderComponent<IProps>('WorkSubContractorRequests', { ...props }, ManageSubContractorRequestsImpl);
    return res;
}
