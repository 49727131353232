import * as React from 'react';

import {
    IRequirement, IRequirementStatus, IRequirementStatusType,
    RequirementStatusTypes, RequirementStatusFlag
} from '@models/requirements';
import {
    classNames, Timeline, useMemoized, useResolveName, useTranslation,
    useSearch,
} from '@components';
import DateUtils from '@utils/date-utils';
import './ViewRequirementHistory.scss';
import { flatten } from '@utils';
import { IUserIdentity, IWork } from '@models';
import dateUtils from '@utils/date-utils';
import { IDocumentVersion } from '@models/documents';

export interface IProps {
    requestClose: Function;
    requirement: IRequirement;
    requirementStatusTypes: IRequirementStatusType[];
    appUsers: IUserIdentity[];
    work: IWork;
    canDownload: boolean;
}

// Para ordenar los eventos de los documentos .... asociando la fecha del estado que genera la version
const associateStatusToDocument = (r: any, status: IRequirementStatus[]) => {
    // localizamos el estado mas cercano
    const documentTime = DateUtils.parseDate(r.date).getTime();
    const state = status.find(s => Math.abs(DateUtils.parseDate(s.dateTime).getTime() - documentTime) <= 1 * 1000);
    if (state) {
        return { ...r, date: state.dateTime };
    }
    else {
        return r;
    }
}

type ValidationRow = {
    validationUserId: number;
    validationHolderType: string;
    validationHolderName?: string;
    validatedState: string;
    validatedDate: string;
}

type RequirementStatusRow = IRequirementStatus & {
    workerName?: string;
}

type DocumentVersionRow = IDocumentVersion & {
    appUserName?: string;
}

export function ViewRequirementHistory(props: IProps) {
    const { t } = useTranslation();

    const requirementStatus = useSearch<RequirementStatusRow>({
        search: 'requirements/requirement_status',
        workId: props.work.id,
        normalizeKeys: true,
        filters: {
            workId: props.work.id,
            requirementId: props.requirement.id,
        },
    });

    const documentVersions = useSearch<DocumentVersionRow>({
        search: 'requirements/requirement_document_versions',
        workId: props.work.id,
        normalizeKeys: true,
        filters: {
            workId: props.work.id,
            requirementId: props.requirement.id,
        },
    });

    const resolveStatusTypeName = useResolveName(props.requirementStatusTypes);
    const resolveStatusType = (st: number, flags: number | undefined = undefined) => {
        if (flags === RequirementStatusFlag.EXPIRED) {
            return t('requirement.expired');
        }
        else {
            return t(resolveStatusTypeName(st));
        }
    };

    const userName = useMemoized((id: number | string, name: string | undefined = undefined) => {
        if (typeof id == 'string') {
            return id;
        }
        if (name != undefined) {
            return name;
        }
        else {
            const u = props.appUsers.find(u => u.id == id);

            if (u) {
                return u.userName;
            }
            else {
                return props.work.defaultEmail;
            }
        }
    });

    const validations = useSearch<ValidationRow>({
        workId: props.work.id,
        search: 'requirements/validations_all',
        filters: {
            requirementId: props.requirement.id,
            workId: props.work.id,
        },
        map: (r: ValidationRow) => {
            return {
                status: t(r.validatedState),
                className: r.validatedState,
                type: 'validation',
                // date: dateUtils.toIso8601(r.validatedDate),
                date: r.validatedDate,
                icon: r.validatedState == 'requirement.validation.pending'
                    ? 'pi pi-loading'
                    : r.validatedState == 'requirement.validation.validated'
                        ? 'pi pi-check'
                        : 'pi pi-exclamation-triangle',
                appUserId: r.validationUserId,
                appUserName: r.validationHolderName,
            }
        }
    });

    // const documentEvents: any[] = flatten(
    //     (props.requirement.documents ?? [])
    //         .map(s => versions.sort((b, a) => a.id - b.id).map((v, i) => ({ ...v, documentId: s.document.id, version: versions.length - i })) ?? []))
    //     .map((dv: any) => ({
    //         status: dv.name,
    //         version: dv.version,
    //         type: 'document',
    //         date: dv.dateTime,
    //         icon: 'pi pi-file pointer',
    //         href: `/api/files/${props.requirement.workId}/document/${dv.documentId}/version/${dv.id}`,
    //         documentId: dv.id,
    //         appUserId: dv.userId,
    //     })).map(d => associateStatusToDocument(d, props.requirement.status));

    const documentEvents: any[] =
        (documentVersions.value ?? [])
            .map((dv: any) => ({
                status: dv.name,
                version: dv.version,
                type: 'document',
                date: dv.dateTime,
                icon: 'pi pi-file pointer',
                href: `/api/files/${props.requirement.workId}/document/${dv.documentId}/version/${dv.id}`,
                documentId: dv.id,
                appUserName: dv.userName,
            })).map(d => associateStatusToDocument(d, props.requirement.status));

    const requirementStatusRows = requirementStatus.value ?? [];

    const noteEvents = useSearch({
        search: 'requirements/requirement_notes',
        workId: props.work.id,
        map: (r: any) => ({
            id: r.id,
            className: 'notes',
            date: r.date,
            status: r.status,
            type: 'note',
            icon: 'pi pi-comments',
            appUserId: r.appUserId,
        }),
        filters: {
            workId: props.work.id,
            requirementId: props.requirement.id,
        }});
        // props.requirement.status
    const legacy = requirementStatusRows
      .filter((s) => s.remarks && s.remarks != "")
      .map((n) => ({
        id: n.id,
        className:
          n.statusTypeId == RequirementStatusTypes.NOT_VALIDATED
            ? "danger"
            : "notes",
        date: n.dateTime,
        icon:
          n.statusTypeId == RequirementStatusTypes.NOT_VALIDATED
            ? "pi pi-exclamation-triangle"
            : "pi pi-comments",
        status: n.remarks,
        isCurrent: n.statusTypeId != undefined ? n.isCurrent : false,
        appUserId: n.createdById,
        associatedDocumentId:
          n.statusTypeId == RequirementStatusTypes.NOT_VALIDATED
            ? props.requirement.incidences
                .filter((i) => i.requirementId == n.requirementId)
                .filter(
                  (i) =>
                    dateUtils.fromUtc(n.dateTime).getDate() ==
                      dateUtils.fromUtc(i.creationDate).getDate() &&
                    dateUtils.fromUtc(n.dateTime).getDay() ==
                      dateUtils.fromUtc(i.creationDate).getDay() &&
                    dateUtils.fromUtc(n.dateTime).getFullYear() ==
                      dateUtils.fromUtc(i.creationDate).getFullYear() &&
                    dateUtils.fromUtc(n.dateTime).getHours() ==
                      dateUtils.fromUtc(i.creationDate).getHours() &&
                    dateUtils.fromUtc(n.dateTime).getMinutes() ==
                      dateUtils.fromUtc(i.creationDate).getMinutes()
                )
                .map((i) => i.documentVersionId)
                .shift()
            : undefined,
      }));

    const statusEvents: any[] =
        requirementStatusRows
            .map(s => ({
                id: s.id,
                status: resolveStatusType(s.statusTypeId, s.flag),
                statusTypeId: s.statusTypeId,
                flag: s.flag,
                date: s.dateTime,
                displayDate: s.displayDateTime,
                className: `status-type-${s.statusTypeId} status-type-flag-${s.flag}`,
                type: 'status',
                icon: 'pi pi-circle-off',
                appUserId: s.validatedBy,
                appUserName: s.workerName,
                isCurrent: s.isCurrent,
            }));

    const removeEvents = statusEvents
        .filter(s => s.flag == RequirementStatusFlag.FILE_REMOVED)
        .map(s => ({
            id: s.id + '_file_removed',
            className: 'file-removed',
            date: s.date,
            type: 'file-removed',
            icon: 'pi pi-trash',
            status: t('requirements.file-removed'),
            appUserId: s.appUserId,
        }));

    const events: any[] =
        flatten([statusEvents, documentEvents, removeEvents, legacy, noteEvents.value, validations.value ?? []])
            .sort((a, b) => a.date == b.date ? b.id - a.id : b.date.localeCompare(a.date));

    for (const e of events) {
        if (e.associatedDocumentId != null) {
            const docIndex = events.findIndex(d => d.documentId == e.associatedDocumentId);
            const noteIndex = events.findIndex(d => d.associatedDocumentId == e.associatedDocumentId);
            const doc = events[docIndex];
            const note = events[noteIndex];
            if (docIndex > noteIndex) {
                events[noteIndex] = doc;
                events[docIndex] = note;
            }
        }
    }

    if (events.length > 1) {
        events[0].icon != undefined ? undefined : events[0].icon = events[0].statusTypeId == RequirementStatusTypes.PENDING
            ? 'pi pi-clock'
            : events[0].statusTypeId == RequirementStatusTypes.NOT_VALIDATED
                ? 'pi pi-ban'
                : events[0].kind == 'incidence'
                    ? 'pi pi-exclamation-triangle'
                    : 'pi pi-check';
        events.forEach((e) => {
          //si tiene statusTypeId y es isCurrent es el ultimo estado
          if (e.statusTypeId != undefined && e.isCurrent == true) {
            e.last = true;
          }
          if (e.statusTypeId != undefined && !e.isCurrent && e.statusTypeId == RequirementStatusTypes.NOT_LOADED) {
            e.first = true;
          }
        });
    }

    return (
      <div className="c ViewRequirementHistory">
        <Timeline
          opposite={(item) => (
            <>
              <div className="opposite-content">
                {item.leftIcon && (
                  <i className={classNames(item.leftIcon, "sm mr-right")} />
                )}
                {item.type == "document" && props.canDownload ? (
                  <a className="document-link" href={item.href}>
                    <i className="fas fa-download" />
                    <span>
                      {item.status}
                      <i className="sm pd-left mutted">
                        {t("version")} {item.version}
                      </i>
                    </span>
                  </a>
                ) : item.last || item.first ? (
                  <strong className={item.className}>{item.status}</strong>
                ) : (
                  <span className={item.className}>{item.status}</span>
                )}
                {item.displayDate && (
                  <span className="sm pd-left mutted">
                    {DateUtils.formatDateTimeFromUtc(item.displayDate)}
                  </span>
                )}
              </div>
            </>
          )}
          content={(item) => (
            <>
              {(item.statusTypeId == RequirementStatusTypes.PENDING ||
                item.statusTypeId == RequirementStatusTypes.NOT_LOADED) &&
              item.flag != RequirementStatusFlag.EXPIRED ? (
                <small className="p-text-secondary">
                  {DateUtils.formatDateTimeFromUtc(item.date)}{" "}
                </small>
              ) : (
                <small className="p-text-secondary">
                  {DateUtils.formatDateTimeFromUtc(item.date)} -{" "}
                  {userName(item.appUserId, item.appUserName)}
                </small>
              )}
            </>
          )}
          marker={(item) => <i className={item.icon} />}
          value={events}
        />
        <div className="footer r r-end">
          <button onClick={() => props.requestClose()}>{t("Close")}</button>
        </div>
      </div>
    );
}
