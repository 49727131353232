import * as React from 'react';

interface IFloatFieldProps {
    id: string;
    title: string;
    children: any;
    type?: string;
    className?: string;
    labelClassName?: string;
    errorMessage?: false | JSX.Element;
}

export function FormField(props: IFloatFieldProps) {
    return <div className={'p-field ' + props.className}>
        {props.children}
        {props.errorMessage}
    </div>;
}

export function FloatField(props: IFloatFieldProps) {
    return <div className={'p-field ' + props.className}>
        <span className='p-float-label'>
            {props.children}
            <label
                className={props.labelClassName}
                htmlFor={props.id}>{props.title}</label>
        </span>
        {props.errorMessage}
    </div>;
}
