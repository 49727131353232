import { ISecurity, IUserIdentity } from '@models';
import { hasPermission } from '@utils/security';

export const commonPermissions: string[] = [
    'contractors.invite',
    'jobs.create',
    'jobs.remove',
    'jobs.contractors.add',
    'jobs.contractors.associateappuser',
    'jobs.contractors.edit',
    'jobs.contractors.processrequests',
    'jobs.contractors.savejobcontractorappuser',
    'jobs.contractors.request',
    'jobs.contractors.removejobcontractor',
    'jobs.contractors.removejobcontractorappuser',
    'jobs.machineries.add',
    'jobs.machineries.remove',
    'job.notify-information-loaded',
    'job.contractors.invite',
    'contractors.access',
    'contractors.access.create',
    'contractors.force.status',
    'contractors.invitations.list',
    'machineries.add',
    'workers.add',
    'workers.force.status',
    'workers.complete.remove',
    'workers.qualifications', // Ver cualificaciones de los trabajadores
    'workers.qualifications.edit', // Editar cualificaciones de los trabajadores
    'machineries.force.status',
    'machineries.complete.remove',
    'job.authorize',
    'job.contractors.remove',
    'job.workers.remove',
    'job.machineries.remove',
    'contractors.add',
    'jobs.forceStatus',
    'work.add.folder',
    'work.add.file',
    'contractors.edit', // Editar datos del contratista
    'job.addSubc', // Añadir subcontratista al trabajo
    'jobHasContractor.remove', // Borrar contratista asociado al trabajo
    'jobHasMachinery.remove', // Borrar maquinaria asociada al trabajo
    'jobHasMachinery.add', // Añadir maquinaria al trabajo
    'jobHasWorker.remove', // Borrar trabajador asociado al trabajo
    'jobHasResource.createRequirement', // Crear requerimiento libre de un recurso
    'jobHasWorker.add', // Añadir trabajador al trabajo
    'job.requirements.create', // Crear requerimiento libre del trabajo
    'job.requirements.upload', // Subir documentación en los requerimientos del trabajo
    'jobs.edit', // Editar trabajo
    'machinery.edit', // Editar datos de la maquinaria
    'requirements.upload', // Subir documentación de los requerimientos
    'requirements.note', // Comentar requerimientos
    'workers.save', // Edita y guarda trabajadores
    'job.created.notification', // Notificar creación del contrato
    'notify.after.job.created', // Notificar después de cargar la información
    'notify.req.incidence', // Notificar incidencia a quien tenga este permiso
    'job.properties',
    'patente.add', // Añadir puntuación de la patente
    'jobs.resources.validate',
    'job.revoke', // Revocar trabajo
    'job.finalize', // Finalizar trabajo
    'jobs.machineries.new.add', // Añadir maquinaria nueva al trabajo
    'restrict.resources', // Restringir recursos
];

export default class Security implements ISecurity {
    public readonly user: IUserIdentity;
    private ctx: any = {};
    private workerIds: number[] = [];
    private contractorIds: number[] | undefined = undefined;

    constructor(user: IUserIdentity) {
        this.user = user;
    }

    public getContractorIds() {
        return this.contractorIds ?? [];
    }

    public withContractorIds(ids: number[]) {
        this.contractorIds = ids;

        return this;
    }

    public getWorkerIds() {
        return this.workerIds ?? [];
    }

    public withWorkerIds(ids: number[]) {
        this.workerIds = ids;

        return this;
    }

    public setContext(ctx: any) {
        this.ctx = ctx;
    }

    public withContext(ctx: any) {
        this.ctx = {
            ...this.ctx,
            ...ctx
        };
        return this;
    }

    public hasRole(role: string) {
        return this.user.roles.includes(role);
    }

    public hasWorker(id?: number) {
        return id != undefined && this.workerIds.includes(id!);
    }

    public hasContractor(id?: number) {
        return id != undefined && (this.contractorIds?.includes(id!) ?? false);
    }

    public hasPolicy(policy: string) {
        const term = policy.toLowerCase();
        return this?.user?.policies?.map(p => p.toLowerCase())?.includes(term) ?? false;
    }

    public isAdmin() {
        return this.user?.roles?.includes('admin') ?? false;
    }

    public isContractor() {
        return this.user?.roles?.includes('contractor') ?? false;
    }

    public isWorker() {
        return this.user?.roles?.includes('worker') ?? false;
    }

    public isGestor() {
        return this.user?.roles?.includes('gestor') ?? false;
    }

    public isGestorOrWorker() {
        return this.isGestor() || this.isWorker();
    }

    public isGestorOrContractor() {
        return this.isGestor() || this.isContractor();
    }

    public hasPermission(perm: string, explicit?: boolean) {
        // console.log('permission hasPermission ??', this);
        const has = hasPermission(perm, {
            user: this.user,
            ...this.ctx,
            explicit,
        });
        return this.isGestor() || has;
    }
}

export const EMPTY_SECURITY = new Security({
    id: 0,
    userName: '',
    email: '',
    roles: [],
    policies: [],
    departments: [],
    workFunctions: [],
});
