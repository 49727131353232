import { IUserIdentity, IJob } from '@models';
// @ts-ignore
import Parser from './parser';

export type Invocation = { type: 'invocation', name: string, args: any };
export type PathSegment = string | Invocation;

export interface IExpression {
    type: string;
    op?: string;
    left?: IExpression;
    right?: IExpression;
    values?: [PathSegment];
    value?: string | boolean | number | IExpression;
    // invoke
    name?: string;
    args?: any[] | any;
}

type Ctx = any;

const isSingleValue = (expr: IExpression) =>
    expr.type === 'boolean'
    || expr.type === 'number'
    || expr.type === 'string';

const specialTerms = [
    'contains',
    'departmentIds',
    'mainDepartment',
    'impliedDepartment',
    'list',
    'isWorker',
    'isContractor',
    'isGestor',
    'responsible',
    'impliedResponsible',
    'getObliqueDepartment',
    'getProductiveDepartment',
];

const invoke = ({ name, args }: IExpression, value: any, root: any = {}) => {
    if (name === 'Contains') {
        if (typeof (value) === 'string') {
            return value?.includes(evaluate(args));
        }
        else if (value?.includes) {
            return value?.includes(evaluate(args));
        } else {
            console.error('Expressions: error invoking Contains', value, args);
            return null;
        }
    } else if (name == "List.Union" || name == 'Union') {
        const a = evaluate(args[0], root);
        const b = evaluate(args[1], root);
        return a.find((r: any) => b.includes(r)) != undefined;
    } else if (name == 'DepartmentIds' || name == 'departmentIds') {
        if (value && value.departments) {
            return value.departments.map((i: any) => typeof (i) == 'number' ? i : i.departmentId ?? i.id);
        } else {
            return false;
        }
    } else if (name == 'MainDepartment' || name == 'mainDepartment') {
        const job = value as IJob;
        if (job && job.departments) {
            return job
                .departments
                .find(d => d.isMain)?.departmentId ?? null;
        } else {
            console.log('Expressions: evaluating MainDepartment on no job', value);
            return false;
        }
    } else if (name == 'ImpliedDepartment' || name == 'impliedDepartment') {
        const job = value as IJob;
        if (job && job.departments) {
            return job
                .departments
                .find(d => d.isMain == false)?.departmentId ?? null;
        } else {
            console.log('Expressions: evaluating ImpliedDepartment on no job', value);
            return false;
        }
    } else if (name == 'Responsible' || name == 'responsible') {
        const job = value as IJob;
        if (job && job.departments) {
            return job
                .departments
                .find(d => d.isMain)?.responsibleId ?? null;
        } else {
            console.log('Expressions: evaluating responsible on no job', value);
            return false;
        }
    } else if (name == 'ImpliedResponsible' || name == 'impliedResponsible') {
        const job = value as IJob;
        if (job && job.departments) {
            return job
                .departments
                .find(d => d.isMain == false)?.responsibleId ?? null;
        } else {
            console.log('Expressions: evaluating implied responsible on no job', value);
            return false;
        }
    } else if (name == 'HasDepartment') {
        let identity = value as IUserIdentity;

        if (value == undefined) {
            identity = root.identity;
        }
        if (identity && identity.departments) {
            const needle = evaluate(args, root);
            const res = identity.departments.find((d: any) => {
                if (typeof d === 'number') {
                    return d === needle;
                }
                else if ('departmentId' in d) {
                    return d.departmentId === needle;
                } else {
                    return d.id === needle;
                }
            });
            return res != undefined;
        } else {
            console.log('Expressions: evaluating HasDepartment on identity with no departments', value, args);
            return false;
        }
    } else if (name == 'AnyDepartment') {
        let identity = value as IUserIdentity;

        if (value == undefined) {
            identity = root.identity;
        }
        if (identity && identity.departments) {
            const needles = evaluate(args, root);
            const res = identity
                .departments
                .find(d => needles.indexOf(d) >= 0) != undefined;
            return res;
        } else {
            console.log('Expressions: evaluating AnyDepartment on identity with no departments', value);
            return false;
        }
    } else if (name == 'GetObliqueDepartment' || name == 'getObliqueDepartment') {
        if (value && value.departments && root && root.work && root.work.departments) {
            const userDepartmentIds = value.departments;
            const workDepartments = root.work.departments;
            const userDepartments = workDepartments.filter((department: any) => userDepartmentIds.includes(department.id));
            return userDepartments.find((d: any) => d.isOblique)?.id ?? null;
        } else {
            console.log('Expressions: evaluating GetObliqueDepartment on no department', value);
            return false;
        }
    } else if (name == 'GetProductiveDepartment' || name == 'getProductiveDepartment') {
        if (value && value.departments && root && root.work && root.work.departments) {
            const userDepartmentIds = value.departments;
            const workDepartments = root.work.departments;
            const userDepartments = workDepartments.filter((department: any) => userDepartmentIds.includes(department.id));
            return userDepartments.find((d: any) => d.isOblique == false)?.id ?? null;
        } else {
            console.log('Expressions: evaluating GetProductiveDepartment on no department', value);
            return false;
        }
    }
    else if (name == 'HasPolicy') {
        let identity = value as IUserIdentity;

        if (value == undefined) {
            identity = root.identity;
        }

        if (identity && identity.policies) {
            const res = identity.policies.indexOf(evaluate(args)) >= 0;
            return res;
        } else {
            console.log('Expressions: evaluating HasPolicy on non identity', value);
            return false;
        }
    } else if (name == 'HasWorker') {
        let identity = value as IUserIdentity;

        if (value == undefined) {
            identity = root.identity;
        }

        if (identity && identity.workerIds) {
            const needle = evaluate(args, root);
            const res = identity.workerIds.find(d => d == needle) != undefined;
            return res;
        } else {
            console.log('Expressions: evaluating HasWorker on non identity', value);
            return false;
        }
    } else if (name?.toLowerCase() == 'isworker') {
        let identity = value as IUserIdentity;

        if (value == undefined) {
            identity = root.identity;
        }

        if (identity && identity.roles) {
            const res = identity.roles.indexOf("worker") >= 0;
            return res;
        }
        else {
            console.log('Expressions: evaluating IsWorker on non identity', value);
            return false;
        }
    } else if (name?.toLowerCase() == 'iscontractor') {
        let identity = value as IUserIdentity;

        if (value == undefined) {
            identity = root.identity;
        }

        if (identity && identity.roles) {
            const res = identity.roles.indexOf("contractor") >= 0;
            return res;
        }
        else {
            console.log('Expressions: evaluating IsContractor on non identity', value);
            return false;
        }
    } else if (name?.toLowerCase() == 'isgestor') {
        let identity = value as IUserIdentity;

        if (value == undefined) {
            identity = root.identity;
        }

        if (identity && identity.roles) {
            const res = identity.roles.indexOf("gestor") >= 0;
            return res;
        } else {
            console.log('Expressions: evaluating IsGestor on non identity', value);
            return false;
        }
    }
}

const evaluatePathSegment = (segment: PathSegment, values: any[], root: any = {}) => {
    const currentCtx = values[values.length - 1];

    if (typeof (segment) === 'string' && !specialTerms.includes(segment)) {
        return currentCtx[segment];
    }
    else if (specialTerms.includes(segment as string)) {
        return invoke({ name: segment as string, type: 'string' }, currentCtx, root);
    }
    else { // invocation
        return invoke(segment as IExpression, currentCtx, root);
    }
}

const toIdentifier = (s: string) => {
    return s.substring(0, 1).toLowerCase() + s.substring(1);
}

const evaluatePath = (values: PathSegment[], ctx: Ctx) => {
    const stack: any[] = [ctx];
    const segments: PathSegment[] = values.map(s =>
        typeof (s) === 'string' ? toIdentifier(s as string) : s);

    for (const v of segments) {
        stack.push(evaluatePathSegment(v, stack, ctx));
    }

    return stack[stack.length - 1];
}

const evaluateBinaryOperator: (expr: IExpression, ctx: Ctx) => any = (expr: IExpression, ctx: Ctx) => {
    if (expr.op === 'AND') {
        return evaluate(expr.left!, ctx) && evaluate(expr.right!, ctx);
    }
    else if (expr.op === 'OR') {
        return (evaluate(expr.left!, ctx) === true) || (evaluate(expr.right!, ctx) === true);
    }
    else if (expr.op === '==') {
        return evaluate(expr.left!, ctx) === evaluate(expr.right!, ctx);
    }
    else if (expr.op === '!=') {
        return evaluate(expr.left!, ctx) !== evaluate(expr.right!, ctx);
    }
    else if (expr.op === '<') {
        return evaluate(expr.left!, ctx) < evaluate(expr.right!, ctx);
    }
    else if (expr.op === '>') {
        return evaluate(expr.left!, ctx) > evaluate(expr.right!, ctx);
    }
    else if (expr.op === '<=') {
        return evaluate(expr.left!, ctx) <= evaluate(expr.right!, ctx);
    }
    else if (expr.op === '>=') {
        return evaluate(expr.left!, ctx) >= evaluate(expr.right!, ctx);
    }
    else if (expr.op === '+') {
        return evaluate(expr.left!, ctx) + evaluate(expr.right!, ctx);
    }
    else if (expr.op === '-') {
        return evaluate(expr.left!, ctx) - evaluate(expr.right!, ctx);
    }
    else if (expr.op === '*') {
        return evaluate(expr.left!, ctx) * evaluate(expr.right!, ctx);
    }
    else if (expr.op === '/') {
        return evaluate(expr.left!, ctx) / evaluate(expr.right!, ctx);
    }
}

export function evaluate<T>(expr: IExpression, ctx: Ctx = undefined) {
    if (isSingleValue(expr)) {
        return expr.value!;
    }
    else if (expr.type === 'binaryop') {
        return evaluateBinaryOperator(expr, ctx);
    }
    else if (expr.type === 'path') {
        return evaluatePath(expr.values!, ctx);
    }
}

export function evalAndParse(expression: string, ctx: Ctx = undefined) {
    // console.log('evalAndParse', expression, ctx);
    return evaluate(parse(expression), ctx);
}

export function parse(expression: string) {
    return Parser.parse(expression.trim()) as IExpression;
}
