import * as React from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

export interface IUseOverlayProps {
    className?: string;
    render: Function;
    requireData?: boolean;
    style?: any;
}

export function useOverlay(props: IUseOverlayProps) {
    const panelRef = React.useRef<any>();
    const [data, setData] = React.useState<any>();

    const render = () =>
        <OverlayPanel ref={panelRef} className={props.className} style={props.style ?? {minWidth: '300px'}}>
            {props.requireData === true && data != undefined && props.render(data)}
            {props.requireData != true && props.render(data)}
        </OverlayPanel>

    const show = (event: any, data: any = undefined) => {
        setData(data);
        panelRef.current.toggle(event)
    };

    const clear = (event: any = undefined) => {
        setData(undefined);
        if (panelRef.current) {
            panelRef.current.hide();
        }
    }

    return {
        clear,
        render,
        show,
    };
}
