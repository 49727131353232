import { IMachinery, IPaginateOptions } from "../../models";
import { m, q } from './graphql';
import { formatProperty } from "@store/actions/utils";

export async function getMachineries(workId: number, opts: Partial<IPaginateOptions> = {}) {
    const paginateOpts = {
        page: 0,
        limit: 10,
        ...opts,
    };
    return q(`query GetMachineries($workId: Int!, $paginateOpts: PaginateOptionsGraphInputType!) {
        work(id: $workId) {
            paginateMachineries(options: $paginateOpts) {
                page, total, limit,
                data {
                    id, name, contractorId, code, description, machineryTypeId,
                    currentStatusType, currentStatusDate, forcedStatusUntilDate, isActive,
                    prefixLicenseNumber, licenseNumber, suffixLicenseNumber,
                    contractor {
                        id, name, code
                    },
                    properties {
                        id, propertyGroupTypeId, jobHasMachineryId, value
                    }
                }
            }
        }
    }`, { workId, paginateOpts }, { returnPath: 'work.paginateMachineries' });
}

export async function getMachinery(workId: number, id: number) {
    return q(`query GetMachinery($workId: Int!, $id: Int!) {
        work(id: $workId) {
            machinery(id: $id) {
                id, name, contractorId, code, description, machineryTypeId,
                machinerySubTypeId, currentStatusType, currentStatusDate,
                forcedStatusUntilDate, isActive, prefixLicenseNumber,
                licenseNumber, suffixLicenseNumber,
                contractor {
                    id, name
                },
                properties {
                    id, propertyGroupTypeId, machineryId, jobHasMachineryId, value
                },
            }
        }
    }`,
        { workId, id },
        { returnPath: 'work.machinery' });
}

export async function saveMachinery(workId: number, rawData: IMachinery, createJobRelations: boolean = false) {
    const data = {
        ...rawData,
        properties: (rawData.properties ?? []).map(formatProperty(workId)),
    };
    return m(`
mutation SaveMachinery($data: MachineryInputType!, $createJobRelations: Boolean) {
    saveMachinery(machinery: $data, createJobRelations: $createJobRelations) {
        isError, error, value, hasValue
    }
}
    `, { data, createJobRelations }, { returnPath: 'saveMachinery' });
}

export function removeMachinery(id: number, workId: number, remove: boolean = false) {
    return m(`
mutation RemoveMachinery($id: Int!, $workId: Int!, $remove: Boolean = false) {
    removeMachinery(id: $id, workId: $workId, remove: $remove) {
        isError, error, value
    }
}
    `, { id, workId, remove }, { returnPath: 'removeMachinery' });
}

export function reactivateMachinery(workId: number, id: number) {
    return m(`
mutation ReactivateMachinery($id: Int!, $workId: Int!) {
    reactivateMachinery(id: $id, workId: $workId) {
        isError, error, value
    }
}
    `, { id, workId }, { returnPath: 'reactivateMachinery' });
}


export function restrictMachinery(workId: number, machineryId: number, machineryTypeId: number, name: string, licenseNumber: string, code: string, contractorId: number, applicantName: string, applicantEmail: string, reason: string, remarks: string) {
    return m(`
mutation RestrictMachinery($workId: Int!, $machineryId: Int!, $machineryTypeId: Int!, $name: String!, $licenseNumber: String!, $code: String!, $contractorId: Int!, $applicantName: String!, $applicantEmail: String!, $reason: String!, $remarks: String!) {
    restrictMachinery(workId: $workId, machineryId: $machineryId, machineryTypeId: $machineryTypeId, name: $name, licenseNumber: $licenseNumber, code: $code, contractorId: $contractorId, applicantName: $applicantName, applicantEmail: $applicantEmail, reason: $reason, remarks: $remarks) {
        isError, error, value
    }
}
    `, { workId, machineryId, machineryTypeId, name, licenseNumber, code, contractorId, applicantName, applicantEmail, reason, remarks }, { returnPath: 'restrictMachinery' });
}

export function removeRestrictedMachinery(workId: number, id: number) {
    return m(`
mutation RemoveRestrictedMachinery($workId: Int!, $id: Int!) {
    removeRestrictedMachinery(workId: $workId, id: $id) {
        isError, error, value
    }
}
    `, { workId, id }, { returnPath: 'removeRestrictedMachinery' });
}