import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';

import { JobRevokeForm } from '@components/jobs/JobRevokeForm';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
    }),
    JobsStore.actionCreators,
)(JobRevokeForm as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any