import * as React from 'react';

import {
    useTranslation,
    useForm,
    G,
    useResolveName,
    useLoading
} from '@components';
import {
    ISecurity, IWork
} from '@models';
import './RestrictContractor.scss';

interface IProps {
    security: ISecurity;
    selectedContractorId: number;
    data: IContractor[] | any;
    work: IWork;
    restrictContractor: Function;
    handleToast: Function;
    onClose: Function;
    legalForms: any;
}

interface IContractor {
    id: number;
    activityTypeId?: number;
    activityTypeName: string;
    address: string;
    city: string;
    code: string;
    contactPerson: string;
    countryId: number;
    countyId: number;
    creationDate: string;
    currentStatusDate: string
    currentStatusType: number
    email: string[];
    endDate?: string;
    legalFormId: number
    legalFormName: string
    name: string
    otherActivityType?: string
    phone: string
    providerCode: string
    workId: number
}


interface IFormValues {
    workId: number;
    name: string;
    code: string;
    legalFormId: number;
    providerCode: string;
    contractorContactPerson: string;
    contractorEmail: string;
    contractorPhone: string;
    contractorId: number;
    applicantName: string;
    applicantEmail: string;
    reason: string;
    remarks: string;
    date: string;
}

export default function RestrictContractor(props: IProps) {

    const { t } = useTranslation();

    const [isSaving, setIsSaving] = React.useState(false);
    const loading = useLoading(true);

    const form = useForm<IFormValues>({
        initialValues: {
            workId: props.work.id,
            name: '',
            code: '',
            legalFormId: 0,
            providerCode: '',
            contractorContactPerson: '',
            contractorEmail: '',
            contractorPhone: '',
            contractorId: props.selectedContractorId,
            applicantName: props.security.user?.userName ?? '',
            applicantEmail: props.security.user?.email ?? '',
            reason: '',
            remarks: '',
            date: new Date().toISOString().split('T')[0]
        },

        onSubmit: (data: IFormValues) => {
            handleSave();
        }
    })

    const contractor = props.data.find((x: IContractor) => x.id === props.selectedContractorId);

    const handleSave = async () => {
        setIsSaving(true);

        const timeoutPromise =  new Promise((_, reject) => {
            setTimeout(() => reject(new Error(t('restricted.contractor.workInProgress'))), 5000);
          });

          const respPromise = new Promise((resolve, reject) => {
            const resp = props.restrictContractor(
                props.work.id,
                contractor?.name,
                contractor?.code,
                contractor?.legalFormId,
                contractor?.providerCode,
                contractor?.contactPerson,
                contractor?.email[0],
                contractor?.phone,
                contractor?.id,
                form.values.applicantName,
                form.values.applicantEmail,
                form.values.reason,
                form.values.remarks
            );
            resolve(resp);
          });

        Promise.race([respPromise, timeoutPromise]).then((value : any) => {
            if (value.isError === false && typeof value.value == 'number') {
                props.handleToast(t('Contractor restricted successfully'), true);
            } else {
                props.handleToast(t('Error restricting contractor'), false);
            }
        }).catch((error) => {
            props.handleToast(error.message, true);
        }).finally(() => {
            props.onClose();
        });
    }

    const resolveLegalForm = useResolveName(props.legalForms, { translate: true });

    return (
        <div className='restrict-contractor-dialog'>
            {!isSaving? <>
                <G label={t('Contractor.Name')}>
                    <input
                        disabled={true}
                        className='c'
                        type='text'
                        value={contractor?.name}
                    />
                </G>
                <G label={t('CIF')}>
                    <input
                        disabled={true}
                        className='c'
                        type='text'
                        value={contractor?.code}
                    />
                </G>
                <G label={t('Legal form')}>
                    <input
                        disabled={true}
                        className='c'
                        type='text'
                        value={resolveLegalForm(contractor?.legalFormId)}
                    />
                </G>
                <G label={t('Provider code')}>
                    <input
                        disabled={true}
                        className='c'
                        type='text'
                        value={contractor?.providerCode}
                    />
                </G>
                <G label={t('Contact person')}>
                    <input
                        disabled={true}
                        className='c'
                        type='text'
                        value={contractor?.contactPerson}
                    />
                </G>
                <G label={t('contractor.invitation.email')}>
                    <input
                        disabled={true}
                        className='c'
                        type='text'
                        value={contractor?.email}
                    />
                </G>
                <G label={t('Phone')}>
                    <input
                        disabled={true}
                        className='c'
                        type='text'
                        value={contractor?.phone}
                    />
                </G>
                <G label={t('Applicant')}>
                    <input
                        disabled={false}
                        className='c'
                        type='text'
                        onChange={(e) => form.values.applicantName = e.target.value}
                        required
                    />
                </G>
                <G label={t('Applicant email')}>
                    <input
                        disabled={false}
                        className='c'
                        type='email'
                        onChange={(e) => form.values.applicantEmail = e.target.value}
                        required
                    />
                </G>
                <G label={t('Date')}>
                    <input
                        disabled={false}
                        className='c'
                        type='date'
                        defaultValue={form.values.date}
                        onChange={(e) => form.values.date = e.target.value}
                        required
                    />
                </G>
                <G label={t('Motive')} className='restriction-reason'>
                    <textarea
                        disabled={false}
                        className='c'
                        onChange={(e) => form.values.reason = e.target.value}
                    />
                </G>
                <G label={t('Remarks')} className='restriction-remarks'>
                    <textarea
                        disabled={false}
                        className='c'
                        onChange={(e) => form.values.remarks = e.target.value}
                    />
                </G>
                <div className='c' />
                <div className='r'>
                    <span className='e' />

                    <button
                        className='primary'
                        disabled={form.isInvalid() || isSaving}
                        onClick={form.handleSubmit}>
                        {t('Save')}
                    </button>
                </div>
            </> : loading.renderBox()

        }
        </div>
    )
}