import * as React from 'react';

import { G, ValidationBuilder, useLoading, useForm, useTranslation, Tag, Accordion, AccordionTab, InputText, classNames } from '@components';
import { IWork, IWorker } from '@models';
import { useMessage } from '@utils/hooks';
import './ManageWorkerAppUser.scss';

type IWorkerWithAppUser = IWorker & {
    userEmail?: string;
    appUser?: number;
    id?: string;
    password?: string;
    passwordConfirmation?: string;
}

export interface IProps {
    associateAppUserToWorker: Function;
    onCancel: Function;
    onSuccess: Function;
    work: IWork;
    worker: IWorkerWithAppUser;
}

type NewUser = {
    email: string;
    userName: string;
    password: string;
    passwordConfirmation: string;
}

export function ManageWorkerAppUser(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();

    const message = useMessage();

    const validate = (data: NewUser) => {
        return ValidationBuilder.create(data)
            .notEmpty('email', 'Email is required')
            .email('email',)
            .notEmpty('userName', 'Username is required')
            .notEmpty('password')
            .match('passwordConfirmation', data.password, 'Passwords do not match')
            .build()
    }

    const form = useForm<NewUser>({
        initialValues: {
            email: '',
            userName: '',
            password: '',
            passwordConfirmation: '',
        },
        validateOnMount: true,
        validate: validate,
    });

    const renderCreateUser = () => {
        return <div className={'sm pd form-1 l200'}>
            <form autoComplete={'off'} className='c sm pd g-10'>
                <G label={t('Email')}>
                    {form.input('email')}
                </G>
                <G label={t('Username')}>
                    {form.input('userName')}
                </G>
                <G label={t('Password')}>
                    {form.input('password', { type: 'password' })}
                </G>
                <G label={t('Password confirmation')}>
                    {form.input('passwordConfirmation', { type: 'password' })}
                </G>
            </form>
            <div className='errors-container center'>
                {form.errorBox()}
            </div>
        </div>;
    }

    const doCreateUser = loading.wrap(async () => {
        const user = {
            userName: form.values.userName,
            email: form.values.email,
            password: form.values.password,
            passwordConfirmation: form.values.passwordConfirmation,
            roles: ["worker"]
        };

        const resp = await props.associateAppUserToWorker(
            props.work.id,
            props.worker.id,
            user,
            true);


        message.set(resp);

        if (resp.hasValue) {
            props.onSuccess(form.values);
        }
    });

    return <div className='c'>
        <div>
            {props.worker.appUser == undefined && renderCreateUser()}
            {props.worker.appUser != null && <div className='already-created'>
                {t('rrhh.worker.email')}
                <Tag className='info' style={{ fontSize: '15px' }} >{props.worker?.userEmail}</Tag>
            </div>}
        </div>
        {message.renderIfError()}

        <div className='r r-end footer'>
            {loading.render()}
            <button onClick={() => props.onCancel()}>{t('Close')}</button>
            {props.worker.appUser == undefined &&
                <button className='primary' onClick={doCreateUser}>
                    {t('Create')}
                </button>
            }
        </div>
    </div>;
}
