import * as React from 'react';

import { ModuleManager, ISecurity, IWork } from '@models';
import * as S from '@utils/security';

type IUsePermissionsProps = {
    security: ISecurity;
    moduleManager: ModuleManager;
    work: IWork;
}

type IUsePermissionsOpts = {
    ctx?: any;
    defaults?: any;
    explicit?: boolean;
    debug?: boolean;
    dependencies?: any;
};

export interface IPermissions {
    get: (name: string) => boolean;
    isGestor: () => boolean;
    isContractor: () => boolean;
}

export function usePermissions(
    props: IUsePermissionsProps,
    opts: IUsePermissionsOpts,
    permissions: any = {}) {
    const hasPermission = (p: string, ctx: any = {}) => S.hasPermission(p, {
        user: props.security.user,
        work: props.work,
        explicit: opts.explicit !== false,
        ...ctx
    });

    const security = props.security;
    const moduleManager = props.moduleManager;

    const [values, setValues] = React.useState<any>({});
    const [id, setId] = React.useState<number>(0);

    const permissionCtx = {
        work: props.work,
        security: props.security,
        ...(opts.ctx ?? {}),
    };

    const requirePermission = async (name: string, key: string, defaultValue: any = undefined) => {
        const defaultPermission = defaultValue
            ?? hasPermission(name, permissionCtx)
            ?? false;

        const res = await moduleManager.requirePermission(
            name,
            permissionCtx,
            defaultPermission,
        );

        if(permissionCtx.security.user.roles.includes('gestor')) {
            return true;
        }

	    if (opts.debug) {
	        console.log(`## PERMISSIONS: initialize ${key}`, name, res, {
                default: defaultPermission,
                hasPermission: hasPermission(name, permissionCtx),
                ctx: permissionCtx
	        });
	    }

	    return res;
    }

    const initialize = async () => {
	    const res: any = {};
	    const keys = Object.keys(permissions);
        for (const k of keys) {
            const v = permissions[k];

            const perm = typeof(v) === 'string' ? v : v.name;
            const defaultValue = typeof(v) === 'string' ? opts.defaults?.[k] : v.default;
            const r = await requirePermission(perm, k, defaultValue);
            res[k] = r;
        }

        setValues(res);
        setId(new Date().getTime());
    }

    const get = (name: string) => {
        if (opts.debug) {
            console.log('## PERMISSIONS: get', name, values[name] ?? false, values);
        }
        return values[name] ?? false;
    }

    React.useEffect(() => {
	    initialize();
    }, [props.moduleManager.getId(), props.security, opts?.dependencies]);

    return {
        id,
        get,
        isGestor: () => props.security.isGestor(),
        isContractor: () => props.security.isContractor(),
    };
}
