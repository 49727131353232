import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ConstantsStore from '@store/constants';
import * as RequirementsStore from '@store/requirements';
import * as App from '@store/app';

import { AddRequirementNote } from '@components/requirements/AddRequirementNote';

export default connect(
    (state: ApplicationState) => ({
        ...RequirementsStore.plainActions,
        user: state.identity?.user,
    }),
    {},
    )(AddRequirementNote as any) as any;