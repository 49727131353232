import * as React from 'react';

import { ISecurity } from '@models';

export interface IProps {
    children: any;
    hasPermission: Function;
    permission: string;
    ctx: any;
    security: ISecurity;
}

export function RequirePermission({children, hasPermission, permission, security, ctx}: IProps) {
    const v = security.isGestor() || hasPermission(permission, ctx);

    if (v && children) {
        return children;
    }
    else {
        return null;
    }
}
