import * as React from 'react';

import { IWork, ModuleManager } from '@models';

export interface IProps {
    work: IWork;
    selectWork: Function;
    children: any;
    match: any;
    moduleManager: ModuleManager;
}

export function WorkLoader({ moduleManager, match, work, selectWork, children }: IProps) {
    React.useEffect(() => {
        const workId = match.params.workid;
        if ((work == undefined || work.id != workId) && workId) {
            selectWork(Number(workId), false);
        }
    }, [match.params, work]);

    React.useEffect(() => {
        if (work?.id == match.params.workid) {
            const workModules = work
                .activeModules?.filter(m => m.hasFrontend ?? false) ?? [];
            const loadedModules = workModules
                .map(m => moduleManager.getModule(m.name))
                .filter(m => m != undefined);

            setModulesLoaded(loadedModules.length == workModules.length);
        }
    }, [ moduleManager.getId(), work]);

    const [modulesLoaded, setModulesLoaded] = React.useState<boolean>(false);

    const canRender = work?.id == match.params.workid
        && modulesLoaded;

    if (canRender) {
        return children;
    }
    else {
        return null;
    }
}
