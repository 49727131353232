import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../store';

export const userContainer = (f: React.FC<any>) =>
    connect((state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        moduleManager: state.modules.moduleManager,
        work: state.user.work,
    }),
    {}
    )(f as any) as any // eslint-disable-line @typescript-eslint/no-explicit-any
;

import ManageJobDepartmentsContainer from './jobs/ManageJobDepartmentsContainer';
import HelpDeskSupport from './HelpDeskSupportContainer';

// TODO: Exportar todos los containers a modulos

export {
    ManageJobDepartmentsContainer,
    HelpDeskSupport,
};