import * as React from 'react';
import { Route } from 'react-router';

import { IContractor } from '@models';
import { DataLoader, useTranslation } from '@components';
import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { getIntParams } from '@utils';

import { getContractor } from '@store/actions/contractors';
import { RequirementTargetType } from '@models/requirements';
import EditContractorContainer from '@containers/contractors/EditContractorContainer';
import EditContractorFormContainer from '@containers/contractors/EditContractorFormContainer';
import WorkContractorAccessDataContainer from '@containers/contractors/WorkContractorAccessDataContainer';

export interface IProps {
    children?: any;
    clearContractorForm: Function;
    contractor: IContractor;
}

const WrappedContractorRequirements = (props: any) => {
    const { t } = useTranslation();
    const { contractorid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => getContractor(workid, contractorid)}>
        {(contractor: IContractor) => <div>
            <div className='center requirements-title'>
                {t('Contractor requirements')}
            </div>
            <div className='md pd r g-20'>
            <EditRequirements
                        targetType={RequirementTargetType.Contractor}
                        targetId={contractorid}
                        targetTitle={contractor?.name}
                        workId={workid} />
            </div>
        </div>}
    </DataLoader>
}

const WrappedContractorForm = (props: any) => {
    const { contractorid, workid } = getIntParams(props.match.params);

    return <DataLoader provider={() => getContractor(workid, contractorid)}>
        {c => <EditContractorFormContainer
                    contractor = {c}
                    contractorId={contractorid}
                    workId={workid} />}
    </DataLoader>
}

const WrappedContractorAccesData = (props: any) => {
    const { contractorid, workid } = getIntParams(props.match.params);

    return <WorkContractorAccessDataContainer
                    workId={workid}
                    contractorId={contractorid} />
}

export function ContractorRoutes(props: any) {
    return <>
        <EditContractorContainer {...props}>
            <Route
                path='/work/:workid/contractors/:contractorid/requirements'
                component={WrappedContractorRequirements} />
            <Route
                exact
                path='/work/:workid/contractors/:contractorid'
                component={WrappedContractorForm} />
            <Route
                exact
                path='/work/:workid/contractors/:contractorid/access-data'
                component={WrappedContractorAccesData} />
        </EditContractorContainer>
    </>;
}