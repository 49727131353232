
export const format = (value: string, ... args: any[]) => {
    return value.replace(/{(\d+)}/g, (match: any, number: number) => {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match;
    });
}

export const interpolate = (value: string, args: any) => {
    return value.replace(/{(\w+)}/g, (match: any, number: number) => {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match;
    });
}

export const zeroRightPad = (value: any, digits: number) => {
    return  value + Array(Math.max(digits - String(value).length + 1, 0)).join('0');
}

export const zeroPad = (number: number, digits: number) => {
    return Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
}

export const strConcat = (...args: any[]) => {
    return args.filter(s => s != null).join(' ');
}

export const strJoin = (join: string, ...args: any[]) => {
    return args.filter(s => s != null).join(join);
}
