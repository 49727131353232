export const isDebug =
    window.location.hostname === 'localhost'
    || window.location.hostname === 'gstore-dev.civilnova.com';

export const fileSizeLimit = 30 * 1024 * 1025;

export default {
    isDebug,
    fileSizeLimit,
}
