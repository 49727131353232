import { Machineries } from "@components/work/accessControl/Machineries";
import { Action, Reducer } from "redux";
import {
} from '../models';
import * as MachineriesActions from './actions/machineries';
import * as ResourcesActions from './actions/resources';
import { actionCreators as N } from "./notifications";

export interface IMachineryState {
}

// types
enum types {
}

export type KnownAction = { type: undefined };

// ACTIONS
export const plainActions = {
    getMachineries: MachineriesActions.getMachineries,
    getMachinery: MachineriesActions.getMachinery,
    saveMachinery: MachineriesActions.saveMachinery,
    removeMachinery: MachineriesActions.removeMachinery,
    reactivateMachinery: MachineriesActions.reactivateMachinery,
    restrictMachinery: MachineriesActions.restrictMachinery,
    removeRestrictedMachinery: MachineriesActions.removeRestrictedMachinery,
}

export const actionCreators = {
}

export const reducer: Reducer<IMachineryState> = (state: IMachineryState | undefined, incomingAction: Action): IMachineryState => {
    if (state === undefined) {
        return {};
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        default:
            return state;
    }
}