import * as React from 'react';

import { BreadCrumb, useTranslation } from '@components';
import { redirectTo } from '@utils';

const createItems = (url: string, t: Function) => {
    const items = [];

    if (url.startsWith('/admin/configuration')) {
        items.push({
            label: t('Configuration'),
            command: () => redirectTo('/admin/configuration')
        });
    }
    else if (url.startsWith('/admin/works')) {
        items.push({
            label: t('Works'),
            command: () => redirectTo('/admin/works')
        });
    }
    else if (url.startsWith('/admin/users')) {
        items.push({
            label: t('Users'),
            command: () => redirectTo('/admin/users')
        });
    }
    else if (url.startsWith('/admin/work/')) {
        items.push({
            label: t('Works'),
            command: () => redirectTo('/admin/works')
        });
        const id = url.split('/admin/work/')[1].split('/')[0];
        items.push({
            label: `${t('Work')} ${id}`,
            command: () => redirectTo(`/admin/work/${id}`)
        });
        if (url.includes('permissions')) {
            items.push({
                label: t('Permissions'),
                command: () => redirectTo(`/admin/work/${id}/permissions`)
            });
        }
        if (url.includes('/configuration')) {
            items.push({
                label: t('Configuration'),
                command: () => redirectTo(`/admin/work/${id}/configuration`)
            });
        }
    }

    return items;
}

export default function AdminRoutesWrapper(props: any) {
    const [items, setItems] = React.useState<any[]>([]);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (props.location) {
            setItems(createItems(props.location.pathname, t));
        }
    }, [props.location]);

    const home = {
        icon: 'pi pi-home',
        command: () => redirectTo('/'),
    };

    return <div className='c h100p'>
        <div className='r breadcrumb-container' style={{position: 'sticky', zIndex: 1000, top: '0px'}}>
            {items.length > 0 &&
                <BreadCrumb model={items} home={home} />}
            <div id='user-breadcrumb'></div>
            <span className='e'></span>
            <div id='breadcrumb-right'></div>
        </div>
        {props.children}
    </div>;
}