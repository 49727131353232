import * as React from 'react';

import { IDocumentType, INotification } from '@models';
import {
    G,
    NotificationsBox,
    useForm,
    useTranslation,
    ValidationBuilder,
} from '@components';

export interface IProps {
    data: IDocumentType;
    footer?: any;
    notifications: INotification[];
    onChange?: Function;
    onSubmit: Function;
}

export const validateDocumentType = (data: IDocumentType) => {
    return ValidationBuilder.create(data).notEmpty('name').build();
}

export const isValid = (data: IDocumentType) => {
    return Object.keys(validateDocumentType(data)).length == 0;
}

const prepareData = (work: IDocumentType) => {
    return {
        id: work.id,
        name: work.name,
        description: work.description,
    }
}

export function DocumentTypeForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<IDocumentType>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            description: props.data?.description ?? '',
            isActive: true,
        },
        validate: validateDocumentType, 
        onSubmit: (data: IDocumentType) => {
            props.onSubmit(prepareData(data));
            return { ok: true };
        },
        onChange: props.onChange,
    });

    return <div>
        <form onSubmit={form.handleSubmit}>
            <div className='form-1 l200 md pd g-30'>
                <G label={t('Name')}>
                    {form.input('name', { autoFocus: true })}
                </G>
                <G label={t('Description')}>
                    {form.input('description')}
                </G>
            </div>
            <div className='errors-container'>
                {form.errorBox()}
                <NotificationsBox notifications={props.notifications} />
            </div>

            {props.footer != undefined && props.footer}
        </form>
    </div>
}
