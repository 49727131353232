import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import * as JobsStore from '@store/jobs';
import { EditJobHasWorker } from '@components/jobs/EditJobHasWorker';
import { isWorkerPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...JobsStore.plainActions,
        propertyGroupTypes: state.user.work?.propertyGroupTypes?.filter(isWorkerPropertyGroupType),
        resourceStatusTypes: state.user.resourceStatusTypes,
        workerTypes: state.user.workerTypes,
        workerDocumentTypes: state.user.workerDocumentTypes,
    }),
    JobsStore.actionCreators,
)(EditJobHasWorker as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
