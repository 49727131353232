import { connect } from 'react-redux';
import { ApplicationState } from '@store';
import * as Admin from '@store/admin';

import { ManageWorkModules } from '@components/admin/modules/ManageWorkModules';


export default connect(
  (state: ApplicationState) => ({
    availableModules: state.admin.availableModules,
    ...Admin.plainActions
  }),
  Admin.actionCreators,
)(ManageWorkModules as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
