import * as React from 'react';

import { IRequirementType, IRequirementTypeValidationHolder } from '@models/requirements';
import { useConfirm, useDataTable, useMemoized, useRemoteData, useTranslation } from '@components';
import RequirementTypeValidationHolder from '@containers/admin/requirementTypes/RequirementTypeValidationHolderContainer';
import { IOption, IWork } from '@models';

type GetF = (workId: number, id: number) => Promise<IRequirementType>;
type GetWorkResourcesF = (workId: number) => Promise<IWork>;
type SaveF = (workId: number, data: IRequirementTypeValidationHolder) => Promise<IOption<number>>;
type RemoveF = (workId: number, id: number) => Promise<IOption<boolean>>;

export interface IProps {
    getRequirementType: GetF;
    getWorkResources: GetWorkResourcesF;
    onClose: Function;
    requirementType: IRequirementType;
    saveRequirementTypeValidationHolder: SaveF;
    removeRequirementTypeValidationHolder: RemoveF;
}

enum FormType {
    LIST = 1,
    ADD = 2,
    EDIT = 3,
}

export function RequirementTypeValidationHolders(props: IProps) {
    const { t } = useTranslation();

    const work = useRemoteData<IWork>(
        props.getWorkResources,
        { parameters: [props.requirementType.workId] });

    const createNewHolderRecord = () =>
        ({ requirementTypeId: props.requirementType.id! });

    const [formType, setFormType] = React.useState<FormType>(FormType.LIST);
    const [editHolderRecord, setEditHolder] = React.useState<IRequirementTypeValidationHolder | undefined>();
    const [addHolderRecord, setAddHolderRecord] = React.useState<IRequirementTypeValidationHolder>(
        createNewHolderRecord());

    React.useEffect(() => {
        if (editHolderRecord) {
            setFormType(FormType.EDIT);
        }
        else {
            setFormType(FormType.LIST);
        }
    }, [editHolderRecord]);

    const data = useRemoteData<IRequirementType>(
        props.getRequirementType,
        {
            parameters: [props.requirementType.workId, props.requirementType.id]
        });

    const doDelete = data.loading.wrap(async (holder: IRequirementTypeValidationHolder) => {
        await props.removeRequirementTypeValidationHolder(props.requirementType.workId, holder.id!);
        await data.query();
    });

    const confirmDelete = useConfirm({
        message: t('Are you sure to delete the element ?'),
        accept: doDelete,
    });

    const resolveUserName = useMemoized(
        (id: number) => work.value?.users?.find(u => u.id == id)?.userName);
    const resolveDepartment = useMemoized(
        (id: number) => work.value?.departments?.find(u => u.id == id)?.name);
    const resolveWorkFunction = useMemoized(
        (id: number) => work.value?.workFunctions?.find(u => u.id == id)?.name);

    const renderHolder = (r: IRequirementTypeValidationHolder) => {
        if (r.appUserId) {
            return resolveUserName(r.appUserId);
        }
        else if (r.jobResponsible) {
            return t('job.responsible');
        }
        else if (r.departmentId) {
            return resolveDepartment(r.departmentId);
        }
        else if (r.workFunctionId) {
            return resolveWorkFunction(r.workFunctionId);
        }
        else if (r.jobImpliedRso) {
            return t('job.rso.implied');
        }
        else {
            return r.policy;
        }
    }

    const dataTable = useDataTable<IRequirementTypeValidationHolder>({
        data: data.value?.validationHolders ?? [],
        columns: [
            { className: 'td-sm center', render: r => r.onlyNotifications ? <i className='far fa-envelope' /> : null },
            { className: 'td-sm center', render: r => r.isBlocking ? <i className='pi pi-lock' /> : null },
            { field: 'validationOrder', className: 'td-sm center' },
            { title: t('Holder'), render: renderHolder },
            { field: 'optional', delegate: 'boolean', className: 'td-md', title: 'requirement.holder.optional' },
        ],
        actions: [
            { icon: 'pencil', onClick: setEditHolder },
            { icon: 'trash', onClick: confirmDelete }
        ]
    });

    const onCancel = () => {
        if (formType === FormType.LIST) {
            props.onClose();
        }
        else {
            setEditHolder(undefined);
            setFormType(FormType.LIST);
        }
    }

    const onSaveClicked = data.loading.wrap(async () => {
        if (formType === FormType.EDIT) {
            await props.saveRequirementTypeValidationHolder(props.requirementType.workId, editHolderRecord!);
        }
        else {
            await props.saveRequirementTypeValidationHolder(props.requirementType.workId, addHolderRecord);
        }

        await data.query();

        setFormType(FormType.LIST);
    });

    return <div className='c'>
        {data.renderLoading()}

        <div className='c we he'>
            {formType === FormType.LIST &&
                dataTable()}
            {formType === FormType.ADD &&
                <RequirementTypeValidationHolder
                    workId={props.requirementType.workId}
                    validationHolder={addHolderRecord}
                    onChange={setAddHolderRecord} />}
            {formType === FormType.EDIT &&
                <RequirementTypeValidationHolder
                    workId={props.requirementType.workId}
                    validationHolder={editHolderRecord}
                    onChange={setEditHolder} />}
        </div>

        <div className='footer r r-end'>
            <button onClick={onCancel}>{t('Cancel')}</button>
            {(formType === FormType.ADD || formType == FormType.EDIT) &&
                <button className='primary' onClick={onSaveClicked}>{t('Save')}</button>}
            {formType === FormType.LIST &&
                <button className='primary' onClick={() => setFormType(FormType.ADD)}>{t('Add')}</button>}
        </div>
    </div>;
}
