import { Action, Reducer } from 'redux';

import { IJob, ISecurity, IUserIdentity, IWork } from '../models';
import * as AppStore from './app';
import Security, { EMPTY_SECURITY } from '../security';

export interface IdentityState {
    user?: IUserIdentity;
    security: ISecurity;
    workerIds: number[];
    contractorIds?: number[];
}

enum Types {
    OnLoginAction = '[IDENTITY] LOGIN',
    OnWorkSelected = '[USER] SELECT WORK',
    OnJobSelected = '[USER] ON JOB LOADED'
}

export interface OnLoginAction {
    type: Types.OnLoginAction;
    user: IUserIdentity;
    workerIds: number[];
    contractorIds: number[];
}

export interface OnWorkSelected {
    type: Types.OnWorkSelected;
    data: IWork;
}

export interface OnJobSelected {
    type: Types.OnJobSelected;
    data: IJob;
}

export type KnownAction = OnLoginAction | OnLoginAction | OnWorkSelected | OnJobSelected;

export const actionCreators = {
    setUser: (user: IUserIdentity) => (dispatch: Function) => {
        dispatch({ type: Types.OnLoginAction, user });
    },
    initializeApp: () => (dispatch: Function) => {
        AppStore.actionCreators.initialize()(dispatch);
    },
};

export const reducer: Reducer<IdentityState> = (state: IdentityState | undefined, incomingAction: Action): IdentityState => {
    if (state === undefined) {
        return { user: undefined, security: EMPTY_SECURITY, workerIds: [], contractorIds: [], };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case Types.OnLoginAction:
            // console.log('permissions on login work', action);
            return {
                user: action.user,
                security: new Security(action.user)
                    .withWorkerIds(action.user.workerIds ?? [])
                    .withContractorIds(action.user.contractorIds ?? []),
                workerIds: action.user.workerIds ?? [],
                contractorIds: action.user.contractorIds ?? [],
            };
        case Types.OnWorkSelected:
            // console.log('permissions on load work', action.data);
            return {
                ...state,
                security: new Security(state.user!)
                    .withWorkerIds(state.workerIds)
                    .withContractorIds(state.contractorIds ?? [])
                    .withContext({
                        workId: action.data.id,
                        work: action.data,
                    }),
            }
        case Types.OnJobSelected:
            // console.log('permissions set job context', action);
            return {
                ...state,
                security: state.security.withContext({
                    jobId: action.data.id,
                    job: action.data,
                }),
            }

        default:
            return state;
    }
};
