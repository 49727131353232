import { parseMessage } from '@models/notifications';
import { m, q } from './graphql';

export function resolveNotification(id: string) {
    return m(`mutation ResolveNotification($id: String!) {
        resolveNotification(id: $id) {
            error, hasValue, value
        }
    }`, { id }, { returnPath: 'resolveNotification' });
}

export function resolveNotifications(ids: string[]) {
    return m(`mutation ResolveNotifications($ids: [String!]!) {
        resolveNotifications(ids: $ids) {
            error, hasValue, value
        }
    }`, { ids }, { returnPath: 'resolveNotifications' });
}

export function getMessages() {
    return q(`query GetMessages {
        notifications {
            id, date, message, data, notificationType,
            icon, severity
        }
    }`, {}, { returnPath: 'notifications', mapEvery: parseMessage });
}