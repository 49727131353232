import * as React from 'react';

import { useLoading, useTranslation } from '@components';

type IColumn = string | Function | { title?: string; field: string; body?: Function; };

export interface IProps<T, R> {
    source: T;
    footer?: any;
    columns: IColumn[];
    load: Function;
    onSelect: Function;

    onRequestCreate?: Function;
    createText?: string;
}

export function ManageRelations<T, R>(props: IProps<T, R>) {
    const { t } = useTranslation();

    const loading = useLoading(true);
    const [listData, setListData] = React.useState<R[]>([]);
    const [rows, setRows] = React.useState<R[]>([]);
    const [filter, setFilter] = React.useState<string>('');

    const fillList = loading.wrap(() => {
        const res = props.load();
        if (res.then) {
            res.then((d: R[]) => setListData(d));
        }
        else {
            setListData(res);
        }
    });

    React.useEffect(() => {
        fillList();
    }, []);

    React.useEffect(() => {
        if (filter == '') {
            setRows(listData);
        }
        else {
            setRows(listData.filter(r => JSON.stringify(r).includes(filter)));
        }
    }, [filter, listData]);

    const renderHeader = (l: IColumn, i: number) =>
        <th key={i} className='header'>
            {typeof(l) === 'string' && l}
            {typeof(l) === 'object' && l.title && l.title}
        </th>;

    const renderRow = (l: IColumn, i: number, d: any) =>
        <td key={i} className='sm pd text-ellision'>
            {typeof(l) === 'string' && d[l]}
            {typeof(l) === 'object' && l.body == undefined && d[l.field]}
            {typeof(l) === 'object' && l.body != undefined && l.body(d)}
        </td>;

    const requestCreate = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        props.onRequestCreate && props.onRequestCreate(props.source);
    }

    return <div className='c w-100p'>
        <div className='r'>
            <div className='layout-sidebar-filter-content p-input-filled p-input-icon-left p-fluid e'>
                <i className='pi pi-search'></i>
                <input
                    type='text'
                    className='p-inputtext p-component flat'
                    onChange={e => setFilter(e.target.value)}
                    placeholder={t('Search')}
                    value={filter} />
            </div>
            {props.onRequestCreate &&
                <button
                    onClick={requestCreate}>
                    {props.createText}
                </button>}
        </div>
        <div className='r'>
            {loading.render()}

            <div className='table-scroll-container w-100p' style={{maxHeight: '60vh'}}>
                <table className='table bordered w-100p'>
                    <thead>
                        <tr>
                            {props.columns.map((l, i) => renderHeader(l, i))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((d, i) =>
                            <tr
                                className='pointer hover'
                                key={i}
                                onClick={_ => props.onSelect(d)}>
                                {props.columns.map((l, i) => renderRow(l, i, d))}
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
        <div>
            {props.footer}
        </div>
    </div>
}