import * as React from 'react';

import {
    useDataTable, useLoading, useMessage, useRemoteData, useSearch, useTranslation, Tag, usePermissions
} from '@components';
import { IMachinery, IOption, ISecurity, IWork, ModuleManager } from '@models';
import './MachineryJobsDialog.scss'

type ActivateF = (workId: number, jobId: number, machineryId: number, jobHasContractorId: number | undefined) => Promise<IOption<number>>;
type GetMachineryF = (workId: number, id: number) => Promise<IMachinery>;

export interface IProps {
    requestClose: Function;
    activateJobHasMachinery: ActivateF;
    saveJobHasMachinery: Function;
    security: ISecurity;
    machinery: IMachinery;
    machineryId: number;
    machineryTitle?: string;
    moduleManager: ModuleManager;
    getMachinery: GetMachineryF;
    work: IWork;
    removeJobHasMachinery: Function;
}

type SearchR = {
    endDate?: Date;
    jobId: number;
    jobHasMachineryId?: number;
    isRelated?: boolean;
    currentStatusType?: number;
    parentContractorName?: string;
    jobHasContractorId?: number;
    name?: string;
    code?: string;
    description?: string;
}

function MachineryJobsForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const messages = useMessage();

    const search = useSearch<SearchR>({
        search: 'contractorJobsHasMachinery.list',
        normalizeKeys: true,
        workId: props.work.id,
        filters: {
            machineryId: props.machineryId,
            contractorId: props.machinery.contractorId
        }
    });

    React.useEffect(() => {
        search.doSearch();
    }, []);

    const saveMachineryRelation = loading.wrap(async (jobId: number, jobHasContractorId: number) => {
        const data = {
            machineryId: props.machineryId,
            jobId,
        };
        const resp = await props.saveJobHasMachinery(props.work.id, jobId, data, jobHasContractorId);
        messages.set(resp);
        await search.doSearch();
    });

    const activateMachineryRelation = loading.wrap(async (jobId: number, d: SearchR) => {
        const resp = await props.activateJobHasMachinery(props.work.id, jobId, props.machineryId, d.jobHasMachineryId);
        messages.set(resp);
        await search.doSearch();
    });

    const removeMachineryRelation = loading.wrap(async (jobId: number, jobHasMachineryId: number) => {
        const resp = await props.removeJobHasMachinery(props.work.id, jobId, jobHasMachineryId);
        messages.set(resp);
        await search.doSearch();
    });

    const jobName = (d: SearchR) => {
        if (![22,23].includes(props.work.id)) { //TODO: remove magic number
            return d.name;
        } else {
            return <><Tag value={d.code} /><span className='sm pd-left' title={d.name}>{d.name}</span></>
        }
    }

    const perms = usePermissions(props, {}, {
        add: 'jobs.machineries.add',
        remove: 'jobHasMachinery.remove',
    });

    const dataTable = useDataTable<SearchR>({
        columns: [
            { title: 'Job', render: (d: any) => jobName(d) },
            {
                title: 'Description',
                render: (d: SearchR) => <span title={d.description}>{d.description}</span>,
                field: 'description',
            },
            {
                title: 'Contractor',
                field: 'parentContractorName',
                render: (d: SearchR) => <span title={d.parentContractorName}>{d.parentContractorName}</span>
            },
            {
                title: 'Associated',
                delegate: 'boolean',
                field: 'isRelated',
                className: 'td-md'
            },
            {
                title: 'End date',
                delegate: 'date',
                field: 'endDate',
                className: 'td-lg',
            },
        ],
        actions: [
            {
                actions: [
                    perms.get('add')
                        ? {
                            title: t('Associate'),
                            onClick: d => saveMachineryRelation(d.jobId, d.jobHasContractorId),
                            disabled: d => (d.isRelated == true || d.currentStatusType == 3),
                        } : undefined,
                    perms.get('add')
                        ? {
                            title: t('Reactivate'),
                            disabled: d => d.endDate == undefined,
                            onClick: d => activateMachineryRelation(d.jobId, d)
                        } : undefined,
                    perms.get('remove')
                        ? {
                            title: t('Finalize'),
                            disabled: d => (!d.isRelated || d.endDate != null),
                            onClick: d => removeMachineryRelation(d.jobId, d.jobHasMachineryId)
                        } : undefined,
                ]
            }
        ],
        data: search.value
    })

    return <div>
        {search.renderLoading()}
        {dataTable()}
        <div className='footer r r-end'>
            <button onClick={() => props.requestClose()}>{t('Close')}</button>
        </div>
    </div>
}

export function MachineryJobsDialog(props: IProps) {
    const machinery = useRemoteData<IMachinery>(props.getMachinery, {
        parameters: [props.work.id, props.machineryId]
    });

    return <div className='MachineryJobsDialog'>
        {machinery.renderLoading()}
        {machinery.value && <MachineryJobsForm
            {...props}
            machinery={machinery.value} />}
    </div>
}