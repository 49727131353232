import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as ConstantsStore from '../../../store/constants';
import * as App from '../../../store/app';
import * as Admin from '../../../store/admin';

import { EditRequirementTypeDependencies } from '@components/admin/requirementTypes/EditRequirementTypeDependencies';

export default connect(
    (state: ApplicationState) => ({
        ...Admin.plainActions,
    }),
    {},
)(EditRequirementTypeDependencies as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
