import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { AddJobHasMachinery } from '../../components/machineries/AddJobHasMachinery';
import { hasPermission } from '../../utils/security';
import { isMachineryPropertyGroupType } from '../../models';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        ...state.identity,
        ...state.user,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isMachineryPropertyGroupType),
        hasPermission: (p: string, explicit: boolean|undefined = undefined) => hasPermission(p, {
            user: state.identity?.user,
            work: state.user.work,
            job: state.jobs.selectedJob,
            explicit: explicit ?? true,
        }),
        resourceStatusTypes: state.user.resourceStatusTypes,

    }),
    JobsStore.actionCreators,
)(AddJobHasMachinery as any) as any;