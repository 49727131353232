import * as React from 'react';

import { IActivityType, IJob, ISecurity, IWork, IWorkspace, ModuleManager } from '@models';
import { JobCard } from './list/JobCard';
import { IMachineryType, IWorkerDocumentType, IWorkerType } from '@models/resources';
import useSearch from '@components/custom/useSearch';

export interface IProps {
    activityTypes: IActivityType[];
    job: IJob;
    jobId: number;
    machineryTypes: IMachineryType[];
    moduleManager: ModuleManager;
    security: ISecurity;
    work: IWork;
    workId: number;
    workerDocumentTypes: IWorkerDocumentType[];
    workerTypes: IWorkerType[];
    workspaces: IWorkspace[];
}

export function JobInfoCard(props: IProps) {
    const getSearch = () => {
        if (props.security.isContractor()) {
            return 'jobList/contractors';
        }
        else {
            return 'jobList/gestor';
        }
    }

    const data = useSearch<IJob>({
        search: getSearch(),
        workId: props.workId,
        filters: {
            jobId: props.job.id,
        }
    });

    return <div className={'r center v-center lg mr'}>
        {data.renderLoading()}
        {data.value != undefined && data.value.length > 0 &&
            <JobCard
                job={data.value[0]}
                security={props.security}
                moduleManager={props.moduleManager}
                work={props.work}
                workerDocumentTypes={props.workerDocumentTypes}
                workerTypes={props.workerTypes}
                workspaces={props.workspaces}
                activityTypes={props.activityTypes}
                machineryTypes={props.machineryTypes}
                reload={console.log}
            />}
    </div>
}