import * as React from 'react';

import { classNames, useConfirm, useTranslation, useSearchFilters, Tag, useSearch } from '@components';
import { IOption, ISecurity, IWork } from '@models';
import {
    messageTypes, IMessage, translateNotificationIcon, messageTitle, MessageType
} from '@models/notifications';
import { NotificationMessageDisplayName } from './NotificationMessageDisplayName';
import { NotificationMessageEffect } from './NotificationMessageEffect';
import { MessageIcon } from './MessageIcon';
import * as DateUtils from '@utils/date-utils';

import './NotificationsCenter.scss';
import { currentLocale } from '../../i18n';

type ResolveMessageF = (id: string) => Promise<IOption<boolean>>;
type ResolveMessagesF = (ids: string[]) => Promise<IOption<boolean>>;


export default function NotificationMessage({ message, resolveMessage, onSelect, checked, onCheck }: {
    message: IMessage, resolveMessage: ResolveMessageF, onSelect: Function,
    checked: boolean,
    onCheck: Function,
}) {
    const { t } = useTranslation();

    const confirm = useConfirm({
        message: t('notifications.message.close-confirm'),
        accept: (messageId: string) => resolveMessage(messageId),
    });

    let langRegExp = new RegExp(/(--\w{2}--)/)

    const detectLanguages: any = (input: string, acc = [], level = 1) => {
        const m = langRegExp.exec(input);

        if (m && input.length > 0) {
            const lang = m[0];
            const index = input.indexOf(lang) + lang.length;
            return detectLanguages(input.substring(index), [...acc, lang], level + 1);
        }
        else {
            return acc;
        }
    }

    const languages: string[] = detectLanguages(message.message);
    const localeIncluded = languages.map(d => d.includes(currentLocale()) ? currentLocale() : undefined).filter(d => d !== undefined)[0];

    const getNotificationByLanguage = (input: string, language: string | undefined = undefined) => {
        if (language) {
            const i = input.indexOf('--' + language + '--');
            const l = input.indexOf('--', i + 6);
            const start = i >= 0 ? i + 6 : 0;

            return input.substring(start, l >= 0 ? l : input.length);
        }
        else if (input.indexOf('--') >= 0) {
            return input.substring(0, input.indexOf('--'));
        }
        else {
            return input;
        }
    }
    message.message = getNotificationByLanguage(message.message, localeIncluded)

    return <div
        className={classNames('message', message.severity)}>
        {message.icon &&
            <i onClick={() => onSelect(message)} className={translateNotificationIcon(message.icon)} />}
        <MessageIcon message={message} />

        <span className='message-date'>{DateUtils.format(message.date, 'd-m-y h:i')}</span>

        <span onClick={() => onSelect(message)}>
            {messageTitle(message)}
        </span>

        <span onClick={() => onSelect(message)}>
            <NotificationMessageDisplayName message={message} />
        </span>

        <span className='e' />
        <input type="checkbox" checked={checked} onClick={_ => onCheck()} id={message.id} />
        <span
            className='close-handle'
            onClick={e => confirm(message.id, e)}
            title={t('notifications.message.close')}>
            <i className='pi pi-times' />
        </span>
    </div>
}