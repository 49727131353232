import * as React from 'react';

import { useDataTable, useFormDialog, useLoading, useMessage, useResolveName, useTranslation } from '@components';
import { getEventTriggerTypes, getResourceTypes, IEventTrigger, IWork } from '@models';
import { WorkToolbar } from '../WorkToolbar';

import { EventTriggerForm } from './EventTriggerForm';

export interface IProps {
    getEventTriggers: Function;
    saveEventTrigger: Function;

    work: IWork;
}

export function ManageWorkEvents(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const [eventTriggers, setEventTriggers] = React.useState<IEventTrigger[]>([]);
    const messages = useMessage();

    const resourceTypes = React.useRef(getResourceTypes(t));
    const eventTriggerTypes = React.useRef(getEventTriggerTypes(t));

    const resolveEventTriggerType = useResolveName(eventTriggerTypes.current);
    const resolveResourceType = useResolveName(resourceTypes.current);

    const formDialog = useFormDialog({
        editTitle: t('Edit'),
        addTitle: t('Add'),
    });

    const initialize = loading.wrap(async () => {
        const res = await props.getEventTriggers(props.work.id);
        setEventTriggers(res);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const table = useDataTable({
        data: eventTriggers,
        className: 'e',
        loading,
        columns: [
            {field:'id', className:'td-sm'},
            'name',
            {field: 'eventTriggerTypeId', title: 'eventTriggerType', render: (a) => resolveEventTriggerType(a.eventTriggerTypeId) },
            {field: 'resourceTypeId', title: 'resourceType', render: (a) => resolveResourceType(a.resourceTypeId) },
        ],
        actions: [
            {icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit}
        ]
    });

    const saveTrigger = loading.wrap(async (data: IEventTrigger) => {
        const res = await props.saveEventTrigger(props.work.id, data);
        messages.set(res);
        if (res.value) {
            // formDialog.clear();

            await initialize();
        }
    });

    const createElement = (tag: string) => {
        const el = document.createElement(tag);
        document.body.append(el);
        return el as any;
    }

    React.useEffect(() => {
        (window as any).require = { paths: { vs: '/js/monaco/min/vs' } };
        (window as any).define = console.log;

		createElement('script').src = '/js/monaco/min/vs/loader.js';
        createElement('script').src = '/js/monaco/min/vs/editor/editor.main.nls.js';
        createElement('script').src = '/js/monaco/min/vs/editor/editor.main.js';
    }, []);

    return <div className='he c'>
        <WorkToolbar
            work={props.work} />

        {table()}

        <link
			rel="stylesheet"
			data-name="vs/editor/editor.main"
			href="/js/monaco/min/vs/editor/editor.main.css"
		/>

        {formDialog.render((data: IEventTrigger) =>
            <EventTriggerForm
                data={data}
                workId={props.work.id}
                onSubmit={saveTrigger}
                error={messages.getError()}
                footer={formDialog.Footer()} />)}

        <div className='r sm pd footer'>
            <span className='e' />
            <button className='p-button'
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add')}
            </button>
        </div>
    </div>
}
