import * as React from 'react';

import { G, ValidationBuilder, useForm, useTranslation } from '@components';
import { INotificationTemplate } from '@models';

type OnSubmitF = (template: INotificationTemplate) => any;

export interface IProps {
    data?: INotificationTemplate;
    isLoading?: boolean;
    onCancel?: Function;
    onSubmit?: OnSubmitF;
    showFooter?: boolean;
    workId: number;
}

export function NotificationTemplateForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<INotificationTemplate>({
        initialValues: {
            id: props.data?.id,
            title: props.data?.title ?? '',
            message: props.data?.message ?? '',
            name: props.data?.name ?? '',
            workId: props.workId,
        },
        validate: d => ValidationBuilder.create(d)
            .notEmpty('name')
            .notEmpty('title')
            .notEmpty('message')
            .build(),
    });

    return <div>
        <div className='c md pd form-1 l200'>
            <G label={t('Name')}>
                {form.input('name')}
            </G>
            <G label={t('Title')}>
                {form.input('title')}
            </G>
            <G label={t('Message')}>
                {form.textarea('message', { rows: 10 })}
            </G>
            {form.errorBox()}
        </div>
        {props.showFooter && <div className='r r-end footer'>
            <button onClick={() => props.onCancel?.()}>{t('Cancel')}</button>
            <button
                className='primary'
                disabled={form.isInvalid() || props.isLoading}
                onClick={() => props.onSubmit?.(form.values)}>{t('Save')}</button>
        </div>}
    </div>
}