import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as RequirementsStore from '@store/requirements';
import { ViewRequirementIncidences } from '@components/requirements/ViewRequirementIncidences';

export default connect(
    (state: ApplicationState) => ({
        ...RequirementsStore.plainActions,
    }),
    {},
)(ViewRequirementIncidences as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any