import {
    ICountry, ICounty,
    IDocumentType,
    INode, IRegion,
    countriesToNode,
    countryToNode,
    countyToNode,
    regionToNode,
} from './common';
import ModuleManager from '../modules/ModuleManager';
import { IAuthRequest, IUserData, IUserIdentity, IRole, Roles } from './identity';
import { messageTypes, IMessage, MessageType, INotification, NotificationType } from './notifications';
import {
    IMachinery, IWorker, IWorkerWithRelations, IWorkI18NResource, IActivityType, ILegalForm,
    IDepartment, IWorkFunction, IContractor, IContractorRequest,
    IMachinerySubType, IMachineryType,
    IResourceStatus, IResourceStatusType,
    ResourceStatusType,
    ResourceType,
    departmentIsProductive,
    getNotificationResourceTypes,
    getResourceTypes,
} from './resources';
import {
    JobEncodingType,
    parseWork,
    PropertyGroupObjectType,
    IPropertyType, IPropertyGroupType, IPropertyGroup,
    IElementHasProperties,
    NotificationMessageAction,
    PropertyTypes,
    IContractorInvitation,
    IContractorInvitationRegisterData,
    IFieldValidation,
    INotificationMessage,
    INotificationTemplate,
    ISubContractorRequest,
    ISubMainContractorRequest,
    IWork, IWorkSettings, IWorkspace, IWorkShift, IWorkPermission, IContractType, AllPropertyTypes, IPreventiveResource,
    IModuleInfo,
    IWorkHasModule,
    getNotificationMessageActions,
    isJobPropertyGroupType,
    isJobHasContractorPropertyGroupType,
    isMachineryPropertyGroupType,
    isWorkerPropertyGroupType,
    isContractorWorkerPropertyGroupType,
    isRRHHPropertyGroupType,
    createFieldValidation,
    fieldValidationResourceTypes,
} from './works';
import {
    jobStatusTypes, parseJob,
    IJob, IJobStatus, IJobHasContractor, IJobHasDepartment, IJobHasContractorAppUser, IJobHasMachinery, IJobHasWorker,
    JobStatusType, JobLogType, IJobLog, rawJobStatusTypes
} from './jobs'
import { ILog } from './logs';
import { IDocument } from './documents';

export interface IConstants {
    countries: ICountry[];
}

export interface IPaginateResult<T> {
    page: number;
    total: number;
    limit: number;
    data: T[];
}

export interface IPaginateSort {
    name: string;
    asc: boolean;
}

export enum UserSearchFilterType {
    BOOL = 0,
    TEXT = 1,
    DATE = 2,
    DATE_RANGE = 3,
    REFERENCE = 4,
    INT = 5,
    FLOAT = 6,
    MULTI_REFERENCE = 7,
}

export interface IHelpDeskItemFile {
    fileName: string;
    temporalResourceId: string;
}

export interface IHelpDeskItem {
    name: string;
    email: string;
    subject: string;
    description: string;
    files?: IHelpDeskItemFile[];
    workId: number;
}

export interface ICommunicationFile {
    fileName: string;
    temporalResourceId: string;
    fileSize: number;
}

export interface ICommunication {
    id: number;

    title: string;
    fromDate: Date;
    toDate: Date;
    description: string;
    isRecursive?: boolean;
    departments: ICommunicationHasDepartment[];
    file?: ICommunicationFile;
    workId: number;
    workspaces: ICommunicationHasWorkspace[];
    jobs: ICommunicationHasJob[];
    targetType?: number;
    isActive?: boolean;

    documents?: IDocument[];
}

export interface ICommunicationHasDepartment {
    id?: number;
    communicationId?: number;
    departmentId: number;
}

export interface ICommunicationHasWorkspace {
    id?: number;
    communicationId?: number;
    workspaceId: number;
}

export interface ICommunicationHasJob {
    id?: number;
    communicationId?: number;
    jobId: number;
}

export interface ICommunicationHasPolicy {
    id?: number;
    communicationId?: number;
    policy: string;
}
export interface IUserSearchFilter {
    name: string;
    title: string;
    default?: any;
    help?: string;
    type: number;
    excludeRole?: string;
    requireRole?: string;
    shouldTranslate? : boolean;
}

export interface IPaginateOptions {
    page?: number;
    limit?: number;
    searchTerm?: string;
    orderBy?: string;
    orderAsc?: boolean;
}

export interface ISecurity {
    user?: IUserIdentity;

    hasRole: (role: string) => boolean;
    isAdmin: () => boolean;
    isGestor: () => boolean;
    isWorker: () => boolean;
    isContractor: () => boolean;
    isGestorOrWorker: () => boolean;
    isGestorOrContractor: () => boolean;
    hasPolicy: (policy: string) => boolean;

    setContext: (ctx: any) => void;
    withContext: (ctx: any) => ISecurity;

    hasWorker: (id?: number) => boolean;
    hasContractor: (id?: number) => boolean;
    getWorkerIds: () => number[];

    hasPermission: (perm: string, explicit?: boolean) => boolean;
}

export type {
    IAuthRequest,
    // common
    IContractor,
    IContractorInvitation,
    IContractorInvitationRegisterData,
    IContractorRequest,
    ICountry,
    ICounty,
    IDocumentType,
    INode,
    ILog,
    IMachinery,
    IMachineryType,
    IMachinerySubType,
    IMessage,
    INotification,
    INotificationMessage,
    INotificationTemplate,
    IElementHasProperties,
    IPropertyGroup,
    IPropertyGroupType,
    IPropertyType,
    IRegion,
    IRole,
    ISubContractorRequest,
    ISubMainContractorRequest,
    IUserData,
    IUserIdentity,
    IModuleInfo,
    IWorkHasModule,
    IWork,
    IWorker,
    IWorkerWithRelations,
    IWorkI18NResource,
    IWorkSettings,
    IWorkShift,
    IWorkPermission,
    IWorkspace,
    IActivityType,
    ILegalForm,
    IDepartment,
    IFieldValidation,
    IWorkFunction,
    IJob,
    IJobStatus,
    IJobHasContractor,
    IJobHasDepartment,
    IJobHasContractorAppUser,
    IJobHasMachinery,
    IJobHasWorker,
    IJobLog,
    IContractType,
    IPreventiveResource,
    IResourceStatus,
    IResourceStatusType,
}

export interface IOption<T> {
    value: T;
    error?: string;
    hasValue?: boolean;
    isError?: boolean;
}

export {
    AllPropertyTypes,
    JobEncodingType,
    JobLogType,
    JobStatusType,
    MessageType,
    ModuleManager,
    NotificationMessageAction,
    NotificationType,
    PropertyTypes,
    PropertyGroupObjectType,
    ResourceStatusType,
    ResourceType,
    Roles,
    createFieldValidation,
    fieldValidationResourceTypes,
    getNotificationMessageActions,
    getNotificationResourceTypes,
    getResourceTypes,
    isJobPropertyGroupType,
    isJobHasContractorPropertyGroupType,
    isMachineryPropertyGroupType,
    isContractorWorkerPropertyGroupType,
    isRRHHPropertyGroupType,
    isWorkerPropertyGroupType,
    jobStatusTypes,
    rawJobStatusTypes,
    countriesToNode,
    countryToNode,
    countyToNode,
    parseJob,
    parseWork,
    regionToNode,
    departmentIsProductive,
    messageTypes,
};

export const getMachineryName = (m: IMachinery) => {
    if (m === undefined) {
        return '';
    }
    else if (m.name && m.name != '') {
        return m.name;
    }
    else if (m.code && m.code != '') {
        return m.code;
    }
    else if (m.licenseNumber && m.licenseNumber != '') {
        return `${m.prefixLicenseNumber}/${m.licenseNumber}/${m.suffixLicenseNumber}`;
    }
    else {
        return `${m.id}`;
    }
}

export interface IJobSettings {
    id?: number;
    workId: number;
    skipInformationLoadedState: boolean;
    colorizeContractorIndicators: boolean;
    freeRequirementsAreMandatory: boolean;
    omitJobHasContractorDefaultNotification: boolean;
    showResourceIncidenceIndicator: boolean;
    showRemarksInInformationLoadedForm: boolean;
    jobHasContractorNotificationTargetPolicy?: string;
    informationLoadedNotificationPolicy?: string;
}

export interface IWorkerSettings {
    id?: number;
    workId: number;
    showGeneralIndicators: boolean;
    showWorkerImage: boolean;
    workerTypeIsMandatory: boolean;
}

export interface IMachinerySettings {
    id?: number;
    workId: number;
    showGeneralIndicators: boolean;
    subTypeMandatory: boolean;
    onlyLicenseNumber: boolean;
}

export interface IContractorSettings {
    id?: number;
    workId: number;
    showGeneralIndicators: boolean;
    legalFormMandatory: boolean;
    jhcStartDateMandatory: boolean;
    jhcDescriptionMandatory: boolean;
    cifCantBeRepeated: boolean;
    canAddSubWorkers: boolean;
    canAddSubMachineries: boolean;
}

export interface IEventTrigger {
    id: number;
    workId: number;
    name: string;
    code: string;
    eventTriggerTypeId: number;
    resourceTypeId: number;
}

export enum EventTriggerType {
    BeforeUpdate = 1,
    BeforeCreate = 2,
    BeforeUpdateOrCreate = 3,
    AfterUpdate = 4,
    AfterCreate = 5,
    AfterUpdateOrCreate = 6,
};

export const getEventTriggerTypes = (t: Function) => {
    return [
        { id: EventTriggerType.BeforeUpdate, name: t('Before update') },
        { id: EventTriggerType.BeforeCreate, name: t('Before create') },
        { id: EventTriggerType.BeforeUpdateOrCreate, name: t('Before create or update') },
        { id: EventTriggerType.AfterUpdate, name: t('After update') },
        { id: EventTriggerType.AfterCreate, name: t('After create') },
        { id: EventTriggerType.AfterUpdateOrCreate, name: t('After create or update') },
    ];
}
