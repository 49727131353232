import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import { IUserIdentity, IWork, IWorkPermission } from '../../models';
import { evalAndParse } from '../../utils/expressions';

function WithPermissions({action, children, work, user}: {
    action: string, children: any, work: IWork, user: IUserIdentity
}) {
    const [hasPermissions, setHasPermissions] = useState(false);

    const evalPermission = (p: IWorkPermission) => {
        if (user.roles.includes('gestor')) {
            return true;
        }
        if (p.expression) {
            return evalAndParse(p.expression, {
                identity: user,
                work: work,
                workId: work?.id,
            });
        }
        else if (p.roles) {
            const roles = p.roles.split(',');
            return user.roles.find(r => roles.includes(r)) != undefined;
        }
        else if (p.policies) {
            const policies = p.policies.split(',');
            return user.policies.find(r => policies.includes(r)) != undefined;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        const perms = work.permissions.filter(p => p.action == action);
        setHasPermissions(perms.length == 0 || perms.find(evalPermission) != undefined);
    }, [user, work, action]);

    if (hasPermissions) {
        return children;
    }
    else {
        return null;
    }
}

export default connect(
    (state: ApplicationState) => ({
        work: state.user.work,
        user: state.identity?.user,
    }),
    {},
)(WithPermissions as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
