import * as React from 'react';

import { INotification, IWorkI18NResource } from '@models';
import {
    NotificationsBox,
    useForm,
    useTranslation,
    ValidationBuilder,
    G,
} from '@components';
import { defaultLanguage, languages } from '../../i18n';

import './WorkForm.css';

export interface IProps {
    data?: IWorkI18NResource;
    workId: number;
    footer?: any;
    notifications: INotification[];
    onChange?: Function;
    onSubmit: Function;
}

export const validateI18NResource = (data: IWorkI18NResource) => {
    return ValidationBuilder.create(data)
        .notEmpty('name')
        .notEmpty('value')
        .build();
}

export const isValid = (data: IWorkI18NResource) => {
    return Object.keys(validateI18NResource(data)).length == 0;
}

import I18nResources from '../../i18n/es.json';

export function WorkI18NResourceForm(props: IProps){
    const { t } = useTranslation();

    const form = useForm<IWorkI18NResource>({
        initialValues: {
            id: props.data?.id ?? 0,
            language: props.data?.language ?? defaultLanguage,
            name: props.data?.name ?? '',
            value: props.data?.value ?? '',
            workId: props.workId,
        },
        validate: validateI18NResource,
        onSubmit: (data: IWorkI18NResource) => {
            props.onSubmit(data);
            return { ok: true };
        },
        onChange: props.onChange,
    });

    const keys = Object.keys(I18nResources);

    return <div>
        <form onSubmit={form.handleSubmit}>
            <div className='form-1 l200 md pd g-30'>
                <G label={t('Language')}>
                    {form.select('language', languages)}
                </G>
                <G label={t('Name')}>
                    {form.autoComplete('name', { suggestions: keys })}
                </G>
                <G label={t('Value')}>
                    {form.input('value')}
                </G>
            </div>
            <div className='errors-container'>
                {form.errorBox()}
                <NotificationsBox notifications={props.notifications} />
            </div>
            {props.footer != undefined && props.footer}
        </form>
    </div>
}