import * as React from 'react';

import { BreadcrumbItem, useDataTable, useSearch, useTranslation } from "@components";
import { IJob, IJobLog, ISecurity } from '@models';
import DateUtils from '@utils/date-utils';

type JobLogT = IJobLog & { contractorName: string, contractorCode: string };

export interface IProps {
    job: IJob;
    jobId: number;
    security: ISecurity;
    workId: number;
}

export function JobInformationLoadedControl(props: IProps) {
    const data = useSearch<JobLogT>({
        search: 'jobRequirements/downloads',
        filters: {
            jobId: props.jobId,
            workId: props.workId
        },
        workId: props.workId,
    });
    const { t } = useTranslation();

    const dataTable = useDataTable<JobLogT>({
        data: data.value,
        columns: [
            { field: 'contractorName', className: 'center', title: t('Contractor'), sortKey: 'contractorName'},
            { field: 'contractorCode', className: 'center', title: t('Code'), sortKey: 'contractorCode' },
            { field: 'dateTime', className: 'center', title: t('DateTime'), render: (r:any) => DateUtils.formatDateTimeFromUtc(r.dateTime), sortKey: 'dateTime' },
        ]
    });

    return <div className={'c we he JobInformationLoadedControl'}>
        <BreadcrumbItem text={t('job.downloads')} />
        {data.renderLoading()}
        {dataTable()}
    </div>
}