import { connect } from "react-redux";
import { ApplicationState } from '../../store';
import * as RequirementsStore from '@store/requirements';
import * as WorkersStore from '@store/workers';
import * as MachineriesStore from '@store/machineries';
import { ResourcePhoto } from '../../resources/ResourcePhoto';
import { flatten } from '@utils/index';

export default connect(
    (state: ApplicationState) => ({
        ...RequirementsStore.plainActions,
        ...WorkersStore.plainActions,
        ...MachineriesStore.plainActions,
        identity: state.identity?.user,
        appUsers: state.user.work?.users,
        requirementStatusTypes: state.user.requirementStatusTypes,
        requirementTypes: flatten(
            state
                .user
                .requirementGroups
                .map(g => g.requirementTypes ?? []))
    }),
    {},
)(ResourcePhoto as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any