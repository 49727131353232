import * as React from 'react';

import {
    BreadcrumbItem, useDispatcher, useDataTable, useSearch, useTranslation, usePermissions,
} from '@components';
import { ResourceStatus } from '@components/common/ResourceStatus';
import {
    ISecurity, IWork, IMachinery, ResourceType, IJob,
    ModuleManager,
} from '@models';

type MachineryHasJob = {
    name: string;
    jobHasMachineryId: number;
    jobName: string;
    jobCode: string;
    resourceStatusType: number;
    resourceStatusName: string;
    workId: number;
    workspaceName?: string;
    contractorName?: string;
}

export interface IProps {
    security: ISecurity;
    moduleManager: ModuleManager;
    work: IWork;
}

enum FormType {
    None = 0,
    List = 1,
    ShowJobs = 2,
}

const renderResourceStatus = (w: MachineryHasJob) => {
    return <ResourceStatus
        currentStatusType={w.resourceStatusType}
        resourceId={w.jobHasMachineryId}
        resourceType={ResourceType.JobHasMachinery}
        workId={w.workId} />
}

export function Machineries(props: IProps) {
    const { t } = useTranslation();

    const [formType, setFormType] = React.useState<FormType>(FormType.None);

    const searchMachineries = useSearch<IMachinery>({
        search: 'controlAccess/machineries',
        lazy: true,
        normalizeKeys: true,
        workId: props.work.id,
    });

    const myJobs = useSearch<IJob>({
        search: 'controlAccess/own.jobs',
        lazy: false,
        normalizeKeys: true,
        workId: props.work.id,
    });

    const searchJobs = useSearch<MachineryHasJob>({
        search: 'machinery_has_jobs',
        lazy: true,
        normalizeKeys: true,
        workId: props.work.id
    });

    const [machinery, setMachinery] = React.useState<IMachinery | undefined>();

    // parametros de busqueda
    const [code, setCode] = React.useState<string>('');
    const [name, setName] = React.useState<string>('');
    const [licensePlate, setPlate] = React.useState<string>('');

    const renderLicensePlate = (m: IMachinery) => {
        if (m.prefixLicenseNumber != null) {
            return <div>
                <span>{m.prefixLicenseNumber + '' + m.licenseNumber + '' + m.suffixLicenseNumber}</span>
            </div>;
        }
        else {
            return null;
        }
    }

    React.useEffect(() => {
        setFormType(machinery != undefined ? FormType.ShowJobs : FormType.List);
        if (machinery != undefined) {
            const filters = {
                machineryId: machinery.id
            };
            searchJobs.doSearch(filters);
        }
    }, [machinery]);

    React.useEffect(() => {
        setFormType(FormType.List);
    }, [searchMachineries.value]);

    let workersTable = null;

    if (props.work.id != 19) {
        workersTable = useDataTable<IMachinery>({
            data: searchMachineries.value ?? [],
            columns: ['name', 'code',
                { title: t('License plate'), render: d => renderLicensePlate(d) },
                'contractorName'
            ],
            actions: [
                { text: t('Select'), className: 'alt slim', headerClassName: 'td-md', onClick: setMachinery }
            ]
        });
    }
    else {
        workersTable = useDataTable<IMachinery>({
            data: searchMachineries.value ?? [],
            columns: [
                { title: t('License plate'), render: d => renderLicensePlate(d) },
                'contractorName'
            ],
            actions: [
                { text: t('Select'), className: 'alt slim', headerClassName: 'td-md', onClick: setMachinery }
            ]
        });
    }

    const jobsTable = useDataTable<MachineryHasJob>({
        data: searchJobs.value ?? [],
        columns: [
            {
                title: t('Contractor'),
                render: j => j.contractorName,
                sortKey: 'contractorName',
            },
            {
                title: t('Job'),
                render: j => j.jobName,
                sortKey: 'jobName',
            },
            {
                title: t('JobNumber'),
                render: j => j.jobCode,
                sortKey: 'jobCode',
            },
            searchJobs.value[0]?.workspaceName && {
                title: t('Job.WorkspaceId'),
                render: j => j.workspaceName,
                sortKey: 'workspaceName',
            },
            {
                title: t('accessControl.machinery.state'),
                className: 'td-md',
                render: renderResourceStatus,
                sortKey: 'resourceStatusType',
            }
        ]
    });

    const permissions = usePermissions(props, {
        debug: false,
    }, {
        all: 'accessControl.all',
    });

    const doSearch = async (values: any) => {
        const filters: any = {
            workId: props.work.id,
            code: values.code,
            name: values.name,
            licensePlate: values.licensePlate,
        };

        if (props.security.isGestorOrWorker()
            && !permissions.get('all')) {
            const jobIds = myJobs.value.map(j => j.id);
            filters['jobId'] = jobIds;
        }

        if (filters.name?.length > 0
            || filters.code?.length > 0
            || filters.licensePlate?.length > 0) {
            await searchMachineries.doSearch(filters);
        }
        else {
            searchMachineries.clear();
        }
    }

    const dispatcher = useDispatcher({
        dispatch: doSearch,
        handlers: {
            code: setCode,
            name: setName,
            licensePlate: setPlate,
        }
    });

    return <div className={'c'}>
        <BreadcrumbItem text={t('Machineries')} />

        <div className={'r md pd center v-center g-20'}>
            {props.work.id != 19 && <div className='r g-10 v-center'>
                <label className={'light'}>
                    {t('accessControl.machinery.code')}
                </label>
                <input type={'text'}
                    className={'br mw300x'}
                    value={code}
                    onChange={e => dispatcher.append('code', e)}
                    placeholder={t('1 digit at least')} />
                <label className={'light'}>{t('Name')}</label>
                <input type={'text'}
                    className={'br wm-300 mw300x'}
                    value={name}
                    onChange={e => dispatcher.append('name', e)}
                    placeholder={t('1 character at least')} />
            </div>
            }

            <label className={'light'}>{t('License plate')}</label>
            <input type="text"
                className={'br mw300x'}
                value={licensePlate}
                onChange={e => dispatcher.append('licensePlate', e)}
                placeholder={t('1 digit at least')} />
        </div>

        {searchMachineries.renderLoading()}

        {formType === FormType.List && <div>
            {workersTable()}
        </div>}

        {formType === FormType.ShowJobs && <div>
            <div className={'blue box rounded lg mr grid-2 gap'} style={{ textAlign: "center", justifyContent: "space-around", display: "flex" }}>
                <div>
                    <label>{t('Code')}</label>
                    <strong> {machinery?.code} </strong>
                </div>
                <div>
                    <label>{t('Name')}</label>
                    <strong>{`${" "} ${machinery?.name}`}</strong>
                </div>
                <div>
                    <label>{t('License plate')}</label>
                    <strong> {`${machinery?.prefixLicenseNumber}${machinery?.licenseNumber}${machinery?.suffixLicenseNumber}`}</strong>
                </div>
            </div>

            {searchJobs.renderLoading()}

            {jobsTable()}
        </div>}
    </div>;
}