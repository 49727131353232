import * as React from 'react';

import { useForm, useLoading, useTranslation } from '@components';
import { IWorkerSettings, IWork } from '@models';
import { WorkToolbar } from '../WorkToolbar';

export interface IProps {
    work: IWork;
    saveWorkerSettings: Function;
}

export function ManageWorkerSettings(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();

    const workerSettings = props.work.workerSettings ?? {
        showWorkerImage: false,
        showGeneralIndicators: false,
	    workerTypeIsMandatory: false,
        workId: props.work.id
    };

    const form = useForm<IWorkerSettings>({
        initialValues: workerSettings,
    });

    const doSave = loading.wrap(async () => {
        await props.saveWorkerSettings(props.work.id, form.values);
    });

    return <div className='c e md pd'>
        <WorkToolbar work={props.work} />

        <strong className='page-subtitle'>{t('worker.settings')}</strong>

        <div className='c e sm pd g-20'>
            <div className='r g-20 v-center'>
                {form.checkBox('showWorkerImage')}
                <label htmlFor='showWorkerImage'>
                    {t('worker.settings.showWorkerImage')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('showGeneralIndicators')}
                <label htmlFor='showGeneralIndicators'>
                    {t('worker.settings.showGeneralIndicators')}
                </label>
        </div>
        <div className='r g-20 v-center'>
            {form.checkBox('workerTypeIsMandatory')}
            <label htmlFor='workerTypeIsMandatory'>
                {t('worker.settings.workerTypeIsMandatory')}
            </label>
        </div>
        <div className='e'></div>
        </div>
        <div className='r r-end'>
            {loading.render()}
            <button
                className='primary'
                disabled={loading.isLoading()}
                onClick={_ => doSave()}>{t('Save')}</button>
        </div>
    </div>;
}
