import * as React from 'react';

import { INotificationTemplate, IOption, IWork } from '@models';
import { useConfirm, useDataTable, useDialogs, useForm, useLoading, useRemoteData, useTranslation } from '@components';
import { NotificationTemplateForm } from './NotificationTemplateForm';

import './NotificationMessages.scss';

type GetF = (workId: number) => Promise<INotificationTemplate[]>;
type RemoveF = (workId: number, id: number) => Promise<IOption<boolean>>;
type SaveF = (workId: number, data: INotificationTemplate) => Promise<IOption<boolean>>;

export interface IProps {
    getNotificationTemplates: GetF;
    removeNotificationTemplate: RemoveF;
    saveNotificationTemplate: SaveF;
    work: IWork;
}

type Filters = {
    action?: string;
    title?: string;
}

export function AdminNotificationTemplates(props: IProps) {
    const { t } = useTranslation();

    const dialogs = useDialogs();
    const loading = useLoading();

    const data = useRemoteData<INotificationTemplate[]>(
        props.getNotificationTemplates,
        {
            parameters: [props.work.id]
        });

    const doRemove = loading.wrap(async (r: INotificationTemplate) => {
        await props.removeNotificationTemplate(props.work.id, r.id!);
        data.query();
    });

    const confirmDelete = useConfirm({
        accept: doRemove,
        message: t('Are you sure to delete the element ?'),
    });

    const doSave = loading.wrap(async (r: INotificationTemplate) => {
        const _ = await props.saveNotificationTemplate(props.work.id, r);
        await data.query();
        dialogs.clear();
    });

    const filtersForm = useForm<Filters>({
        initialValues: {},
    });
    
    const [filteredTemplates, setFilteredTemplates] = React.useState(data.value ?? []);

    const isBlank = (s: string|undefined) =>
        s == undefined || s == '';

    const filterTemplate = (m: INotificationTemplate, filters: Filters) => {
        const predicates = [
            [
                isBlank(filters.action),
                m.name.toLowerCase().includes(filters.action?.toLowerCase() ?? ''),
            ],
            [
                isBlank(filters.title),
                m.title.toLowerCase().includes(filters.title?.toLowerCase() ?? ''),
            ],
        ];

        return predicates
            .reduce((a, b) => a && (b[0] == true || b[1] == true), true);
    };

    React.useEffect(() => {
        setFilteredTemplates(
            (data.value ?? [])
                .filter(m => filterTemplate(m, filtersForm.values)));
    }, [data.value, filtersForm.values]);

    const dataTable = useDataTable<INotificationTemplate>({
        columns: [
            { title: 'id', field: 'id', sortable: true },
            { title: 'name', field: 'name', sortable: true },
            { title: 'title', field: 'title', sortable: true },
            { title: 'message', field: 'message', sortable: true },
        ],
        actions: [
            { icon: 'pencil', onClick: (r: INotificationTemplate) => dialogs.showFromEvent('edit', r)() },
            { icon: 'trash', onClick: confirmDelete }
        ],
        data: filteredTemplates,
    });

    return <div className='NotificationMessages'>
        {data.renderLoading()}

        {dialogs.render('edit', { title: t('Edit template'), style: { minWidth: '60%' }, className: 'g pd' }, (d: INotificationTemplate) =>
            <NotificationTemplateForm
                data={d}
                isLoading={loading.isLoading()}
                onCancel={() => dialogs.clear()}
                onSubmit={doSave}
                showFooter={true}
                workId={props.work.id} />)}

        {dialogs.render('add', { title: t('Add template'), style: { minWidth: '60%' }, className: 'g pd' }, (_: any) =>
            <NotificationTemplateForm
                isLoading={loading.isLoading()}
                onCancel={() => dialogs.clear()}
                onSubmit={doSave}
                showFooter={true}
                workId={props.work.id} />)}

        <div className="filters">
            <div className='filter'>
                <span className='filter-label'>{t('Action')}</span>
                {filtersForm.input('action', { placeholder: t('name')})}
            </div>
            <div className='filter'>
                <span className='filter-label'>{t('Title')}</span>
                {filtersForm.input('title', { placeholder: t('title')})}
            </div>
        </div>

        {dataTable()}

        <div className='r r-end footer'>
            <button className='primary' onClick={dialogs.showFromEvent('add')}>
                <i className='fa fa-plus' />
                {t('Add notification template')}
            </button>
        </div>
    </div>
}
