import { IMachinery } from '../../models';
import { IActivityType, IDepartment, ILegalForm, IMachineryType, IMachinerySubType, IWorker, IWorkerDocumentType, IWorkerWithRelations, IWorkerType, IWorkFunction, IWorkI18NResource } from '../../models/resources';
import { getClient, gql, noCache, m, q } from './graphql';
import { ensureType, formatProperty } from './utils';

export function saveMachinery(machinery: IMachinery) {
    return m(`
mutation SaveMachinery($machinery: MachineryInputType!) {
    saveMachinery(machinery: $machinery) {
        isError, error, value, hasValue
    }
}
    `, { machinery }, { returnPath: 'saveMachinery' });
}

export async function saveMachineryType(machineryType: IMachineryType) {
    return m(`
mutation SaveMachineryType($machineryType: MachineryTypeInputType!) {
    saveMachineryType(machineryType: $machineryType) {
        isError, error, value, hasValue
    }
}
    `, { machineryType }, { returnPath: 'saveMachineryType' });
}

export async function removeMachineryType(workId: number, id: number) {

    const resp = await m(`mutation RemoveMachineryType($workId: Int!, $id: Int!) {
        removeMachineryType(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { workId, id });

    return resp?.data.removeMachineryType;
}

export async function saveMachinerySubType(workId: number, machinerySubType: IMachinerySubType) {
    return m(`
mutation SaveMachinerySubType($workId: Int!, $machinerySubType: MachinerySubTypeInputType!) {
    saveMachinerySubType(workId: $workId, machinerySubType: $machinerySubType) {
        isError, error, value, hasValue
    }
}
    `, { workId, machinerySubType }, { returnPath: 'saveMachinerySubType' });
}

export async function removeMachinerySubType(workId: number, parentId: number, id: number) {

    const resp = await m(`mutation RemoveMachinerySubType($workId: Int!, $parentId: Int!, $id: Int!) {
        removeMachinerySubType(workId: $workId, parentId: $parentId, id: $id) {
            isError, error, value
        }
    }`, { workId, parentId, id });

    return resp?.data.removeMachinerySubType;
}

export function saveWorker(inputData: IWorkerWithRelations) {

    const worker = {
        ...inputData,
        properties: inputData.properties?.map(formatProperty(inputData.workId))
    };

    const departments = inputData.departments;
    const workspaces = inputData.workspaces;

    if (inputData.departments) {
        delete worker['departments'];
    }

    if (inputData.workspaces) {
        delete worker['workspaces'];
    }

    return m(`
mutation SaveWorker($worker: WorkerInputType!, $workspaces: [Int!], $departments: [Int!]) {
    saveWorker(worker: $worker, workspaces: $workspaces, departments: $departments) {
        isError, error, value, hasValue
    }
}
    `, { worker, departments, workspaces }, { returnPath: 'saveWorker' });
}

export async function getWorkers(workId: number, rrhh: boolean = false) {
    const query = `
query GetWorkers($workId: Int!, $rrhh: Boolean) {
    work(id: $workId) {
        workers(rrhh: $rrhh) {
            id, name, surname, remarks,
            properties {
                id, propertyGroupTypeId, workerId, jobHasWorkerId, value
            },
            appUser {
                id, userName, email
            }
        }
    }
}`;

    const resp = await q(
        query, {
        workId, rrhh
    },
        { returnPath: 'work.workers', mapEvery: (d: IWorker) => ({ ...d, fullName: d.name + ' ' + d.surname }) }
    );

    return resp;
}

export async function getWorkI18NResources(workId: number) {
    const client = getClient('getWorkI18NResources');
    const query = gql`
query GetWorkI18NResources($workId: Int!) {
    work(id: $workId) {
        workI18NResources {
            id, language, name, value
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function saveWorkI18NResource(workI18NResource: IWorkI18NResource) {
    const client = getClient('saveWorkI18NResource');
    const mutation = gql`
mutation SaveWorkI18NResource($workI18NResource: WorkI18NInputType!) {
    saveWorkI18NResource(workI18NResource: $workI18NResource) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            workI18NResource
        }
    });

    return resp;
}

export async function getActivities(workId: number) {
    const client = getClient('getActivities');
    const query = gql`
query GetActivities($workId: Int!) {
    work(id: $workId) {
        activities {
            id, name
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function saveActivity(activityType: IActivityType) {
    const client = getClient('saveActivity');
    const mutation = gql`
mutation SaveActivity($activityType: ActivityTypeInputType!) {
    saveActivity(activityType: $activityType) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            activityType
        }
    });

    return resp;
}

export async function getWork(id: number) {
    const client = getClient('getWork');
    const query = gql`
query GetWork($id: Int!) {
    work(id: $id) {
        id, name, description, countryId, users {
            id, userName, email, roles, policies
        },
        machineries {
            id, name, description, machineryTypeId, machinerySubTypeId,
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            id
        }
    });

    return resp;
}

export async function removeMachinery(id: number, workId: number, remove: boolean = false) {
    const client = getClient('removeMachinery');
    const mutation = gql`
    mutation RemoveMachinery($id: Int!, $workId: Int!, $remove: Boolean!) {
        removeMachinery(id: $id, workId: $workId, remove: $remove) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

export async function removeWorker(id: number, workId: number, remove: boolean = false) {
    const client = getClient('removeWorker');
    const mutation = gql`
    mutation RemoveWorker($id: Int!, $workId: Int!, $remove: Boolean!) {
        removeWorker(id: $id, workId: $workId, remove: $remove) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId,
            remove
        }
    });

    return resp;
}

export async function removeWorkI18NResource(id: number, workId: number) {
    const client = getClient('removeWorkI18NResource');
    const mutation = gql`
    mutation RemoveWorkI18NResource($id: Int!, $workId: Int!) {
        removeWorkI18NResource(id: $id, workId: $workId) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

export async function removeActivity(id: number, workId: number) {
    const client = getClient('removeActivity');
    const mutation = gql`
    mutation RemoveActivity($id: Int!, $workId: Int!) {
        removeActivity(id: $id, workId: $workId) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

export async function getLegalForms(workId: number) {
    const client = getClient('getLegalForms');
    const query = gql`
query GetLegalForms($workId: Int!) {
    work(id: $workId) {
        legalForms {
            id, name, hideContractorProperties,
            workerTypes {
                    id, workerType {
                        id, name
                    }
            }
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function saveLegalForm(legalForm: ILegalForm) {
    const client = getClient('saveLegalForm');
    const mutation = gql`
mutation SaveLegalForm($legalForm: LegalFormInputType!) {
    saveLegalForm(legalForm: $legalForm) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            legalForm
        }
    });

    return resp;
}

export async function removeLegalForm(id: number, workId: number) {
    const client = getClient('removeLegalForm');
    const mutation = gql`
    mutation RemoveLegalForm($id: Int!, $workId: Int!) {
        removeLegalForm(id: $id, workId: $workId) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

export async function getWorkerDepartments(workId: number, workerId: number) {
    const client = getClient('getWorkerDepartments');
    const query = gql`
query GetWorkerDepartments($workId: Int!, $workerId: Int!) {
    work(id: $workId) {
        worker(id: $workerId) {
            departments {
                id, name, code
            }
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId, workerId
        }
    });

    return resp.data.work.worker.departments;
}

export async function getWorkerHasWorkspaces(workId: number, workerId: number) {
    const client = getClient('getWorkerHasWorkspaces');
    const query = gql`
query GetWorkerHasWorkspaces($workId: Int!, $workerId: Int!) {
    work(id: $workId) {
        worker(id: $workerId) {
            workspaces {
                id, name
            }
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId, workerId
        }
    });

    return resp.data.work.worker.workspaces;
}

export async function getWorkerWorkFunctions(workId: number, workerId: number) {
    const client = getClient('getWorkerWorkFunctions');
    const query = gql`
query GetWorkerWorkFunctions($workId: Int!, $workerId: Int!) {
    work(id: $workId) {
        worker(id: $workerId) {
            work_functions {
                id, name
            }
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId, workerId
        }
    });

    return resp.data.work.worker.work_functions;
}

export async function getDepartmentWorkers(workId: number, departmentId: number) {
    const client = getClient('getDepartmentWorkers');
    const query = gql`
query GetDepartmentWorkers($workId: Int!, $departmentId: Int!) {
    work(id: $workId) {
        department(id: $departmentId) {
            workers {
                id, name, surname
            }
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId, departmentId
        }
    });

    return resp.data.work.department.workers;
}

export async function getWorkFunctionWorkers(workId: number, workFunctionId: number) {
    const client = getClient('getWorkFunctionWorkers');
    const query = gql`
query GetWorkFunctionWorkers($workId: Int!, $workFunctionId: Int!) {
    work(id: $workId) {
        work_function(id: $workFunctionId) {
            workers {
                id, name
            }
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId, workFunctionId
        }
    });

    return resp.data.work.work_function.workers;
}

export async function saveDepartmentHasWorker(workId: number, departmentId: number, workerId: number) {
    const client = getClient('saveDepartmentHasWorker');
    const mutation = gql`
mutation SaveDepartmentHasWorker($workId: Int!, $departmentId: Int!, $workerId: Int!) {
    saveDepartmentHasWorker(workId: $workId, departmentId: $departmentId, workerId: $workerId) {
        error, hasValue,
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: {
            workId, workerId, departmentId
        }
    });

    return resp.data.saveDepartmentHasWorker;
}

export async function saveWorkerHasWorkspace(workId: number, workerId: number, workspaceId: number) {
    const client = getClient('saveWorkerHasWorkspace');
    const mutation = gql`
mutation SaveWorkerHasWorkspace($workId: Int!, $workerId: Int!, $workspaceId: Int!) {
    saveWorkerHasWorkspace(workId: $workId, workerId: $workerId, workspaceId: $workspaceId) {
        error, hasValue,
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: {
            workId, workerId, workspaceId
        }
    });

    return resp.data.saveWorkerHasWorkspace;
}

export async function removeWorkerHasWorkspace(workId: number, workerId: number, workspaceId: number) {
    const client = getClient('removeWorkerHasWorkspace');
    const mutation = gql`
mutation removeWorkerHasWorkspace($workId: Int!, $workerId: Int!, $workspaceId: Int!) {
    removeWorkerHasWorkspace(workId: $workId, workerId: $workerId, workspaceId: $workspaceId) {
        error, hasValue,
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: {
            workId, workerId, workspaceId
        }
    });

    return resp.data.removeWorkerHasWorkspace;
}

export async function saveWorkFunctionHasWorker(workId: number, workFunctionId: number, workerId: number) {
    const client = getClient('saveWorkFunctionHasWorker');
    const mutation = gql`
mutation SaveWorkFunctionHasWorker($workId: Int!, $workFunctionId: Int!, $workerId: Int!) {
    saveWorkFunctionHasWorker(workId: $workId, workFunctionId: $workFunctionId, workerId: $workerId) {
        error, hasValue,
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: {
            workId, workerId, workFunctionId
        }
    });

    return resp.data.saveWorkFunctionHasWorker;
}

export async function removeDepartmentHasWorker(workId: number, departmentId: number, workerId: number) {
    const client = getClient('removeDepartmentHasWorker');
    const mutation = gql`
mutation RemoveDepartmentHasWorker($workId: Int!, $departmentId: Int!, $workerId: Int!) {
    removeDepartmentHasWorker(workId: $workId, departmentId: $departmentId, workerId: $workerId) {
        error, hasValue,
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: {
            workId, workerId, departmentId
        }
    });

    return resp.data.removeDepartmentHasWorker;
}

export async function removeWorkFunctionHasWorker(workId: number, workFunctionId: number, workerId: number) {
    const client = getClient('removeWorkFunctionHasWorker');
    const mutation = gql`
mutation RemoveWorkFunctionHasWorker($workId: Int!, $workFunctionId: Int!, $workerId: Int!) {
    removeWorkFunctionHasWorker(workId: $workId, workFunctionId: $workFunctionId, workerId: $workerId) {
        error, hasValue,
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: {
            workId, workerId, workFunctionId
        }
    });

    return resp.data.removeWorkFunctionHasWorker;
}

export async function getWorkspaces(workId: number) {
    const client = getClient('getWorkspaces');
    const query = gql`
query GetWorkspaces($workId: Int!) {
    work(id: $workId) {
        workspaces {
            id, name
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function getDepartments(workId: number) {
    const client = getClient('getDepartments');
    const query = gql`
query GetDepartments($workId: Int!) {
    work(id: $workId) {
        departments {
            id, name, policies, isOblique
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function saveDepartment(inputDepartment: IDepartment) {
    const client = getClient('saveDepartment');

    const department = ensureType(inputDepartment, {
        exclude: 'workers'
    });
    const mutation = gql`
mutation SaveDepartment($department: DepartmentInputType!) {
    saveDepartment(department: $department) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            department
        }
    });

    return resp;
}

export async function removeDepartment(id: number, workId: number) {
    const client = getClient('removeDepartment');
    const mutation = gql`
    mutation RemoveDepartment($id: Int!, $workId: Int!) {
        removeDepartment(id: $id, workId: $workId) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

export async function addDepartmentPolicy(workId: number, departmentId: number, policy: string) {
    return m(`mutation SaveDepartmentPolicy($workId: Int!, $departmentId: Int!, $policy: String!) {
        saveDepartmentPolicy(workId: $workId, departmentId: $departmentId, policy: $policy) {
            value, isError, error
        }
    }`, { workId, departmentId, policy }, { returnPath: 'saveDepartmentPolicy' });
}

export async function removeDepartmentPolicy(workId: number, departmentId: number, policy: string) {
    return m(`mutation RemoveDepartmentPolicy($workId: Int!, $departmentId: Int!, $policy: String!) {
        removeDepartmentPolicy(workId: $workId, departmentId: $departmentId, policy: $policy) {
            value, isError, error
        }
    }`, { workId, departmentId, policy }, { returnPath: 'removeDepartmentPolicy' });
}

export async function getWorkFunctions(workId: number) {
    const client = getClient('getWorkFunctions');
    const query = gql`
query GetWorkFunctions($workId: Int!) {
    work(id: $workId) {
        work_functions {
            id, name
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function getContractType(workId: number) {
    const client = getClient('getContractType');
    const query = gql`
    query GetContractType($workId: Int!) {
        work(id: $workId) {
            contractTypes {
                id, name
            }
        }
    }`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function getPreventiveResource(workId: number) {
    const client = getClient('getPreventiveResource');
    const query = gql`
    query GetPreventiveResource($workId: Int!) {
        work(id: $workId) {
            preventiveResources {
                id, name
            }
        }
    }`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function saveWorkFunction(workFunction: IWorkFunction) {
    const client = getClient('saveWorkFunction');
    const mutation = gql`
mutation SaveWorkFunction($workFunction: WorkFunctionInputType!) {
    saveWorkFunction(workFunction: $workFunction) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            workFunction
        }
    });

    return resp;
}

export async function removeWorkFunction(id: number, workId: number) {
    const client = getClient('removeWorkFunction');
    const mutation = gql`
    mutation RemoveWorkFunction($id: Int!, $workId: Int!) {
        removeWorkFunction(id: $id, workId: $workId) {
            isError, error, value
        }
    }
        `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id,
            workId
        }
    });

    return resp;
}

function workId(workId: any): (value: import("../../models").IPropertyGroup, index: number, array: import("../../models").IPropertyGroup[]) => { id: number | undefined; workId: number; jobId: number | undefined; propertyGroupTypeId: number; value: string; } {
    throw new Error('Function not implemented.');
}

// MACHINERY TYPES
export async function getMachineryTypes(workId: number) {
    const client = getClient('getMachineryTypes');
    const query = gql`
query GetMachineryTypes($workId: Int!) {
    work(id: $workId) {
        machineryTypes {
            id, name, subTypes {id, name, parentId}
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

// WORKER DOCUMENT TYPES
export async function getWorkerDocumentTypes(workId: number) {
    const client = getClient('getWorkerDocumentTypes');
    const query = gql`
query GetWorkerDocumentTypes($workId: Int!) {
    work(id: $workId) {
        workerDocumentTypes {
            id, name
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function saveWorkerDocumentType(workerDocumentType: IWorkerDocumentType) {
    return m(`
mutation SaveWorkerDocumentType($workerDocumentType: WorkerDocumentTypeInputType!) {
    saveWorkerDocumentType(workerDocumentType: $workerDocumentType) {
        isError, error, value, hasValue
    }
}
    `, { workerDocumentType }, { returnPath: 'saveWorkerDocumentType' });
}

export async function removeWorkerDocumentType(workId: number, id: number) {

    const resp = await m(`mutation RemoveWorkerDocumentType($workId: Int!, $id: Int!) {
        removeWorkerDocumentType(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { id, workId });

    return resp?.data.removeWorkerDocumentType;
}

// WORKER TYPES
export async function getWorkerTypes(workId: number) {
    const client = getClient('getWorkerTypes');
    const query = gql`
query getWorkerTypes($workId: Int!) {
    work(id: $workId) {
        workerTypes {
            id, name
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            workId
        }
    });

    return resp;
}

export async function saveWorkerType(workerType: IWorkerType) {
    return m(`
mutation SaveWorkerType($workerType: WorkerTypeInputType!) {
    saveWorkerType(workerType: $workerType) {
        isError, error, value, hasValue
    }
}
    `, { workerType }, { returnPath: 'saveWorkerType' });
}

export async function removeWorkerType(workId: number, id: number) {

    const resp = await m(`mutation RemoveWorkerType($workId: Int!, $id: Int!) {
        removeWorkerType(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { id, workId });

    return resp?.data.removeWorkerType;
}
