import * as React from 'react';

import { INotification } from '@models';
import {
    Accordion,
    AccordionTab,
    Chip,
    classNames,
    InputGroup,
    InputText,
    MultiSelect,
    NotificationsBox,
    ValidationBuilder,
    useForm,
    useTranslation,
    G,
} from '@components';

import './UserForm.css';

export interface IUserFormData {
    id?: string;
    userName?: string;
    email?: string;
    password?: string;
    passwordConfirmation?: string;
    roles: string[];
    policies: string[];
}

export interface IProps {
    availableRoles: string[];
    data: IUserFormData;
    footer?: any;
    notifications: INotification[];
    onChange: Function;
    onSubmit?: Function;
    saveUser?: Function;
}

export const validateUser = (data: IUserFormData) => {
    return ValidationBuilder.create(data)
        .notEmpty('userName')
        .notEmpty('email')
        .notEmptyIf(data.id == undefined, 'password')
        .match('passwordConfirmation', data.password, 'Passwords do not match')
        .build();
}

export const isValidUser = (data: IUserFormData) => {
    return Object.keys(validateUser(data)).length == 0;
}

function UserPolicies(props: any) {
    const { t } = useTranslation();
    const [newPolicy, setNewPolicy] = React.useState<string>('');

    const addPolicy = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (newPolicy && newPolicy.trim() !== '') {
            props.data.policies.push(newPolicy);
            setNewPolicy('');
        }
    }

    const removePolicy = (policy: string) => {
        const i = props.data.policies.indexOf(policy);
        props.data.policies.splice(i);
    }

    return (<form>
        <strong className='title'>{t('Policies')}</strong>

        <div className='sm pd r fwrap g-10'>
            {(props.data?.policies ?? [])
                .map((p: string, i: number) =>
                    <Chip className='primary' key={i} label={p} removable onRemove={() => removePolicy(p)}></Chip>)}
        </div>

        <div className='r'>
            <div className='p-inputgroup'>
                <input
                    type='text'
                    placeholder={t('Add policy')}
                    value={newPolicy}
                    onChange={e => setNewPolicy(e.target.value)}
                    className='p-inputtext p-component' />
                <button
                    className='p-inputgroup-addon'
                    type='submit'
                    onClick={addPolicy}>
                    <i className='pi pi-plus' />
                </button>
            </div>
        </div>
    </form>);
}

export function UserForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<IUserFormData>({
        initialValues: {
            id: props.data?.id,
            userName: props.data?.userName ?? '',
            password: '',
            passwordConfirmation: '',
            email: props.data?.email ?? '',
            roles: props.data?.roles ?? [],
            policies: props.data?.policies ?? []
        },
        validate: validateUser,
        onSubmit: (data: IUserFormData) => {
            if (props.saveUser) {
                props.saveUser(data);
            }
            if (props.onSubmit) {
                props.onSubmit(data);
            }
            return { ok: true };
        },
        onChange: props.onChange,
    });

    return <div>
        <form onSubmit={form.handleSubmit}>
            <div className='md pd mr-top user-form-container'>
                <div className='c g-10 md pd form-1 l200'>
                    <G label={t('User')}>
                        {form.input('userName', { autoFocus: true })}
                    </G>
                    <G label={t('Email')}>
                        <InputGroup suffix='envelope'>
                            {form.input('email', { className: 'flat-right', type: 'email' })}
                        </InputGroup>
                    </G>

                    { props.data?.id && <Accordion>
                        <AccordionTab header={t('Password')}>
                            {form.suffixField('password', 'lock', () =>
                                <InputText
                                    autoComplete={'asdasdasdasd'}
                                    id='password'
                                    type='password'
                                    value={form.values.password}
                                    placeholder={t('Password')}
                                    onChange={form.handleChange}
                                    className={classNames({'p-invalid': !form.isFormFieldValid('password')})} />)}

                            {form.suffixField('passwordConfirmation', 'lock', () =>
                                <InputText
                                    id='passwordConfirmation'
                                    autoComplete={'asdasdasdasdasda'}
                                    type='password'
                                    value={form.values.passwordConfirmation}
                                    placeholder={t('Password confirmation')}
                                    onChange={form.handleChange}
                                    className={classNames({'p-invalid': !form.isFormFieldValid('passwordConfirmation')})} />)}
                        </AccordionTab>
                    </Accordion> }

                    {props.data?.id == undefined &&
                        <React.Fragment>
                            {form.suffixField('password', 'lock', () =>
                                <InputText
                                    id='password'
                                    type='password'
                                    value={form.values.password}
                                    placeholder={t('Password')}
                                    onChange={form.handleChange}
                                    className={classNames({'p-invalid': !form.isFormFieldValid('password')})} />)}

                            {form.suffixField('passwordConfirmation', 'lock', () =>
                                <InputText
                                    id='passwordConfirmation'
                                    type='password'
                                    value={form.values.passwordConfirmation}
                                    placeholder={t('Password confirmation')}
                                    onChange={form.handleChange}
                                    className={classNames({'p-invalid': !form.isFormFieldValid('passwordConfirmation')})} />)}
                        </React.Fragment>}
                </div>
                <div>
                    {!props.data?.roles.includes('contractor') &&
                        <>
                            <strong className='title'>{t("Roles")}</strong>
                            {form.field('roles', t('Roles'), () =>
                                <MultiSelect
                                    id='roles'
                                    value={form.values.roles}
                                    options={props.availableRoles}
                                    onChange={form.handleChange}
                                    />)}
                        </>}
                    <UserPolicies data={form.values} />
                </div>

                <div className='errors-container center'>
                    {form.errorBox()}
                    <NotificationsBox notifications={props.notifications} />
                </div>
            </div>
            {props.footer != undefined && <div className='footer'>{props.footer}</div>}
        </form>
    </div>
}
