import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { EditJobHasContractorFields } from '../../components/jobs/EditJobHasContractorFields';
import { hasPermission } from '../../utils/security';
import { isJobHasContractorPropertyGroupType } from '../../models';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        activityTypes: state.user.activityTypes,
        constants: state.constants,
        propertyGroupTypes: state.user.propertyGroupTypes
            .filter(isJobHasContractorPropertyGroupType),
        hasPermission: (p: string) => hasPermission(p, {
            user: state.identity?.user,
            work: state.user.work,
            explicit: true,
        }),
        legalForms: state.user.legalForms,
        resourceStatusTypes: state.user.resourceStatusTypes,
    }),
    JobsStore.actionCreators,
)(EditJobHasContractorFields as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
