import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '@store/jobs';
import * as ContractorsStore from '@store/contractors';
import { JobContractors } from '@components/jobs/JobContractors';
import { hasPermission } from '../../utils/security';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        ...ContractorsStore.plainActions,
        ...state.identity,
        ...state.user,
        moduleManager: state.modules.moduleManager,
        hasPermission: (p: string) => hasPermission(p, {
            user: state.identity?.user,
            work: state.user.work,
            explicit: true,
        }),
        ...state.jobs,
    }),
    JobsStore.actionCreators,
)(JobContractors as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
