import './EditContractor.scss';

import * as React from 'react';

import {
    FieldsBox, G, Portal,
    useForm, useLoading, useToast, useTranslation, useWorkFormSettings,
    useEvents,
    ValidationBuilder,
} from '@components';
import { CountryRegionSelect } from '@components/common/CountryRegionSelect';
import { DynamicProperties } from '@components/user/DynamicProperties';
import {
    IConstants, ICountry, IFieldValidation, IPropertyGroup, IPropertyGroupType, ISecurity, IWork,
    PropertyGroupObjectType, PropertyTypes, ResourceType
} from '@models';
import {
    IActivityType, IContractor, ILegalForm, IResourceStatusType
} from '@models/resources';
import { delay, redirectTo } from '@utils';
import { ModuleManager } from '@modules';

type GetContractorF = (workId: number, id: number) => Promise<IContractor>;

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    legalForms: ILegalForm[];
    activityTypes: IActivityType[];
    constants: IConstants;
    getContractor: GetContractorF;
    work: IWork;
    contractor: IContractor;
    contractorId: number;
    security: ISecurity;
    saveContractor: Function;
    resourceStatusTypes: IResourceStatusType[];
    showRequirements?: boolean;
    modules: ModuleManager;
}

const validateContractorF = (fieldValidations: IFieldValidation[] | undefined) =>
    ValidationBuilder
        .new()
        .notEmpty('name')
        .withFieldValidations(ResourceType.Contractor, fieldValidations)
        .lift();

export function EditContractorForm(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const toast = useToast();
    const events = useEvents();

    const settings = useWorkFormSettings({ work: props.work, scope: 'contractor' });

    const [selectedLegalForm, setSelectedLegalForm] = React.useState<ILegalForm>();

    const propertiesInvalid = React.useRef<boolean>();

    const saveContractor = loading.wrap(async (req: IContractor) => {
        const resp = await props.saveContractor(props.work.id, req);

        if (resp.hasValue) {
            toast.show(t('Contractor saved successfully'));
            events.fire('save', resp.value, props);

            if (props.work.id != 21) {
                await delay(2000);
                redirectTo(`/work/${props.work.id}/contractors`)
            }
        }
        else {
            toast.error(t(resp.error))
        }
    });

    const doSave = (..._: any) => {
        if (selectedLegalForm?.hideContractorProperties) {
            form.values.properties.map((p: IPropertyGroup) => {
                if (props.propertyGroupTypes.find(g => g.id == p.propertyGroupTypeId)?.hidable) {
                    p.value = "{}";
                    p.valueObj = "{}";
                }
            });
        }
        saveContractor(form.values);
    }


    const initializeCountries = (workId: number) => {
        let countries: ICountry[] = [];
        props.constants.countries.forEach(c => countries.push(Object.assign({}, c)));
        if (workId == 19) {
            for (let c in countries) {
                if (countries[c].regions.length > 0) {
                    countries[c].regions = [];
                }
            }
        }
        return countries;
    };

    const canSave = props.security.hasPermission('contractors.edit');

    const validateContractor = validateContractorF(props.work.validations);

    const form = useForm<IContractor>({
        readonly: !canSave,
        initialValues: {
            id: props.contractorId,
            workId: props.work.id,
            name: props.contractor.name,
            code: props.contractor.code,
            legalFormId: props.contractor.legalFormId,
            activityTypeId: props.contractor.activityTypeId,
            otherActivityType: props.contractor.otherActivityType,
            address: props.contractor.address,
            postalCode: props.contractor.postalCode,
            countryId: props.contractor.countryId,
            regionId: props.contractor.regionId,
            countyId: props.contractor.countyId,
            isActive: props.contractor.isActive,
            properties: props.contractor.properties,
            phone: props.contractor.phone,
            remarks: props.contractor.remarks,
            providerCode: props.contractor.providerCode,
            city: props.contractor.city,
            contactPerson: props.contractor.contactPerson,
            creationDate: props.contractor.creationDate
        },
        validate: validateContractor,
        onSubmit: (data: IContractor) => {
            return saveContractor(data);
        }
    })

    const updateRegion = ({ countryId, regionId, countyId }: any) => {
        form.setFieldValue('countryId', countryId);
        if (props.work.id == 21) {
            form.setFieldValue('regionId', regionId);
            form.setFieldValue('countyId', countyId);
        }
    }

    const calculateFormPropertiesAreValid = () => {
        const mandatoryPropertyIds: number[] = [];
        const mandatoryPropertyNames: string[] = [];

        //Si existen el formulario, evaluamos las propiedades dinámicas acordes a las existentes
        if (form.values.properties != undefined && form.values.properties.length > 0) {
            let hiddens: number = 0;
            if (props.propertyGroupTypes != undefined && props.propertyGroupTypes.length > 0) {
                const boolTextNames: string[] = [];
                props.propertyGroupTypes.map(g => {
                    // ¿Está ocultada esta propiedad por la forma legal y la propiedad hidable del grupo de propiedades?
                    const isHidden = selectedLegalForm?.hideContractorProperties && g.hidable;
                    if (!isHidden) {
                        if (g.mandatory) {
                            // Si el grupo de propiedades no está oculto y es obligatorio -> cargamos su ID y los nombres de sus propiedades
                            mandatoryPropertyIds.push(g.id!);
                            if (g.properties != undefined && g.properties.length > 0) {
                                g.properties.map(p => {
                                    mandatoryPropertyNames.push(p.name);
                                    // Si alguna de las propiedades es de tipo BoolText, la almacenamos
                                    if (p.typeId == PropertyTypes.BOOLTEXT) {
                                        boolTextNames.push(p.name);
                                    }
                                })
                            }
                        }
                    }
                    else {
                        // Si está ocultada, ya sea por:
                        //      1- El grupo de propiedades no es ocultable
                        //      2- La forma legal no oculta las propiedades
                        // -> La contabilizamos
                        hiddens += 1;
                    }
                });
                if (mandatoryPropertyIds != undefined && mandatoryPropertyIds.length > 0) {
                    const properties: any[] = [];

                    // Evaluamos cuáles son las propiedades del formulario que son obligatorias
                    form.values.properties.map((p: any) => {
                        mandatoryPropertyIds.map(m => {
                            if (p.propertyGroupTypeId == m) {
                                properties.push(p);
                            }
                        })
                    });

                    if (properties != undefined && properties.length > 0) {
                        const values: boolean[] = []

                        properties.map(p => {
                            // Si cualquiera de estas propiedades tiene valor, lo evaluamos
                            if (p.valueObj != "{}") {
                                mandatoryPropertyNames.map(m => {
                                    // Evaluamos si los BoolTexts hallados tienen valor de texto
                                    if (boolTextNames.includes(m)) {
                                        boolTextNames.map(b => {
                                            if (b == m) {
                                                if (p.valueObj != undefined) {
                                                    if (p.valueObj[m + "_text"] != undefined) {
                                                        const valueText = p.valueObj[m + "_text"];
                                                        if (valueText == "" || valueText == undefined) {
                                                            values.push(false);
                                                        }
                                                        else {
                                                            values.push(true);
                                                        }
                                                    }
                                                }
                                                else {
                                                    const value = JSON.parse(p.value)[m + "_text"];
                                                    if (value == undefined || value == "") {
                                                        values.push(false);
                                                    }
                                                    else {
                                                        values.push(true);
                                                    }
                                                }
                                            }
                                        })
                                    }
                                    // Obtenemos su valor teniendo en cuenta que undefined es false
                                    else {
                                        if (p.valueObj != undefined) {
                                            const value = p.valueObj[m];
                                            if (value != undefined) {
                                                values.push(value);
                                            }
                                            else {
                                                values.push(false);
                                            }
                                        }
                                        else {
                                            const value = JSON.parse(p.value)[m];
                                            if (value != undefined) {
                                                values.push(value);
                                            }
                                            else {
                                                values.push(false);
                                            }
                                        }
                                    }
                                })
                            }
                            else {
                                values.push(false);
                            }
                        });
                        // Si existe algún true en el array, entonces el grupo de propiedades es válido
                        if (values.some(v => v)) {
                            propertiesInvalid.current = false;
                        }
                        else {
                            if (hiddens < props.propertyGroupTypes.length) {
                                propertiesInvalid.current = true;
                            }
                        }
                    }
                }
                if (hiddens == props.propertyGroupTypes.filter(g => g.mandatory).length) {
                    propertiesInvalid.current = false;
                }
            }
        }
    }

    React.useEffect(() => {
        calculateFormPropertiesAreValid();
    }, [form.values.properties, selectedLegalForm])

    React.useEffect(() => {
        const legalForm = props.legalForms.find(l => l.id == form.values.legalFormId);
        setSelectedLegalForm(legalForm);
    }, [form.values.legalFormId]);


    props.modules.injectComponents(
        'contractor.edit.form',
        {...props, events },
        { form });

    return <div className='EditMachineryContainer'>

        <Portal container={'#breadcrumb-right'}>
            {loading.render()}
        </Portal>

        {toast.render()}

        {props.contractor &&
            <div className='md mr pd'>
                <div className='c lg pd g-20 form-1 l200 he'>
                    <G label={t('Contractor.Name')}>
                        {form.input('name', { autoFocus: true })}
                    </G>
                    {/*// TODO: Revisar si es necesario */}
                    <G label={props.work.id == 21 ? t('contractor.code') : t('CIF')}>
                        {form.input('code')}
                    </G>
                    {props.work.contractorSettings?.legalFormMandatory == true
                        ? <G label={t('Legal form')}>
                            {form.select('legalFormId', props.legalForms)}
                        </G>
                        : undefined
                    }
                    {settings.show('activityTypeId') &&
                        <G label={t('Activity type')}>
                            {form.select('activityTypeId', props.activityTypes)}
                        </G>
                    }
                    {props.work.id != 21 &&
                        <G label={t('Activity type')}>
                            {form.input('otherActivityType')}
                        </G>}
                    <G label={t('Provider code')}>
                        {form.input('providerCode')}
                    </G>
                    <FieldsBox title={t('Address')} collapsible>
                        <G label={t('Address')}>
                            {form.input('address')}
                        </G>
                        <G label={t('Postal code')}>
                            {form.input('postalCode')}
                        </G>
                        {/* //TODO: Revisar si es necesario */}
                        <G label={props.work.id == 21 ? t('Region') : t('Country')}>
                            <CountryRegionSelect
                                countries={initializeCountries(props.work.id)}
                                data={form.values}
                                onChange={updateRegion}
                                readonly={!canSave} />
                        </G>
                        {/* //TODO: Revisar si es necesario */}
                        {props.work.id != 21 &&
                            <G label={t('City')}>
                                {form.input('city')}
                            </G>}
                        {/* //TODO: Revisar si es necesario */}
                        {props.work.id != 21 &&
                            <G label={t('Contact person')}>{form.input('contactPerson')}</G>}
                        <G label={t('Phone')}>
                            {form.input('phone')}</G>
                    </FieldsBox>
                    <G label={t('Remarks')} className='CorrectRemark'>
                        {form.textarea('remarks')}
                    </G>
                    <span />
                    <div id={'embed-properties'}>
                        <DynamicProperties
                            className='e sm mr-left'
                            inline
                            readonly={!canSave}
                            hideContractorProperties={selectedLegalForm?.hideContractorProperties}
                            object={props.contractor}
                            onChange={v => form.setFieldValue('properties', v)}
                            embedPortal={'#embed-properties'}
                            objectType={PropertyGroupObjectType.JobHasContractor}
                            propertyGroupTypes={props.propertyGroupTypes} />
                        {propertiesInvalid.current && <small className='p-error right-align'>{t('dynamic.properties.error')}</small>}
                    </div>

                    {form.errorBox()}

                    {props.security.isGestor() && < G label={t('DateTime.platform.start')}>
                        {form.input('creationDate', { type: 'date' })}
                    </G>}

                    <div id='contractor.edit.form' />

                    <div className='e' />
                    <div className='r'>
                        <span className='e' />
                        {canSave &&
                            <button
                                className='primary'
                                disabled={form.isInvalid() || propertiesInvalid.current}
                                onClick={doSave}>
                                {t('Save')}
                            </button>}
                    </div>
                </div>
            </div>
        }
    </div >
}
