import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { JobInfoCard } from '@components/jobs/JobInfoCard';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        ...state.identity,
        ...state.user,
        ...state.constants,
        moduleManager: state.modules.moduleManager,
        workSettings: state.user.work?.settingsObj,
    }),
    JobsStore.actionCreators,
)(JobInfoCard as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
