import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import { ManageSubcontractors } from '../../components/contractors/ManageSubcontractors';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...ContractorsStore.plainActions,
        ...state.jobs.selectedJobHasContractor
    }),
    {},
)(ManageSubcontractors as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any