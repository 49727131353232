import * as React from 'react';

import {
    BreadcrumbItem, Portal, RequireRole, useRemoteData, useTranslation,
    TabPanel, TabView,
} from '@components';
import {
    IJob, IJobHasDepartment, IJobHasContractor, IJobStatus, ISecurity, IWork,
    IWorkSettings,
    ModuleManager,
} from '@models';
import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { RequirementTargetType } from '@models/requirements';
import { getTabs } from './JobRequirementsSubContractor';

import './JobRequirements.scss';
import { goToJobCreateRequirement, goToJobNotifyInformationLoaded } from "@components/routes";

export interface IProps {
    getJobDepartments: Function;
    getJobStatus: Function;
    job: IJob;
    jobId: number;
    moduleManager: ModuleManager;
    workId: number;
    work: IWork;
    security: ISecurity;
    selectedJobHasContractor?: IJobHasContractor;
}

// controlamos que un target tenga algun requerimiento pendiente
type TargetReqPending = {
    [key: number]: boolean;
}

// #762: Si el usuario es un subcontratista asociado al trabajo
// se muestran primero los requerimientos de su contratista padre
export function JobRequirements(props: IProps) {
    const { t } = useTranslation();

    const status = useRemoteData<IJobStatus[]>(props.getJobStatus, {
        parameters: [props.workId, props.jobId]
    });

    const workSettings: IWorkSettings | undefined = props.work.settingsObj;

    const [currentStatus, setCurrentStatus] = React.useState<IJobStatus | undefined>(undefined);

    // constrolamos por targetId los requerimientos pendientes
    const [targetReqPending, setTargetReqPending] = React.useState<TargetReqPending>({});

    const allowAlwaysNotifyInformationLoaded = workSettings?.allowAlwaysNotifyInformationLoaded ?? false;
    const reqPending = Object.values(targetReqPending).find(r => r);

    React.useEffect(() => {
        const current = (status.value ?? []).find(s => s.isCurrent);
        setCurrentStatus(current);
    }, [status.value]);

    const departments = useRemoteData<IJobHasDepartment[]>(
        props.getJobDepartments,
        {
            parameters: [props.workId, props.jobId]
        });

    const mainDepartment = props.job?.departments?.find(r => r.isMain == true);

    const [canSave, setCanSave] = React.useState<boolean>(false);
    const [canNotify, setCanNotify] = React.useState<boolean>(false);

    const permCtx = {
        security: props.security,
        job: props.job,
    };

    const calculateCanSave = async () => {
        const p = props.security.hasPermission('job.requirements.create')
            || props.security.hasWorker(mainDepartment?.responsibleId);
        const res = await props.moduleManager.requirePermission(
            'job.requirements.create',
            permCtx,
            p);

        const resN = await props.moduleManager.requirePermission(
            'job.notify-information-loaded',
            permCtx,
            false);
        setCanSave(res ?? false);
        setCanNotify(resN ?? false);
    }

    React.useEffect(() => {
        calculateCanSave();
    }, [props.security, props.job]);

    const canSaveRequirements = props.security.hasPermission('job.requirements.upload')
        || props.security.hasWorker(mainDepartment?.responsibleId);

    const createRequirement = () => {
        goToJobCreateRequirement(props.workId, props.jobId);
    }

    const notifyInformationLoaded = () => {
        goToJobNotifyInformationLoaded(props.workId, props.jobId);
    }

    const contractorTabs = props.security.isContractor()
        ? getTabs({
            contractors: props.job.contractors,
            selectedJobHasContractor: props.selectedJobHasContractor,
            workId: props.workId,
            jobId: props.jobId,
            job: props.job,
            security: props.security,
        })
        : null;

    return <div className='JobRequirements c md pd we'>
        <BreadcrumbItem text={t('Documentation')} />
        {departments.renderLoading()}

        <RequireRole oneOf={['worker', 'gestor']}>
            <Portal container={'#job-actions'}>
                {canSave &&
                    <button onClick={createRequirement}>
                        <i className='fas fa-plus' />
                        <span className='e'>{t('Create job requirement')}</span>
                    </button>}

                {(reqPending || allowAlwaysNotifyInformationLoaded)
                    && ((props.security.isGestor()
                        || props.security.hasPolicy('consejero'))
                        || canSave
                        || canNotify)
                    && (props.security.hasPermission('job.notify-information-loaded') || canNotify) &&
                    <button onClick={notifyInformationLoaded}>
                        <i className='fas fa-paper-plane' />
                        <span className='e'>{t('job.notify-information-loaded')}</span>
                    </button>}
            </Portal>
        </RequireRole>

        <div className={'c we g-20'}>
            {props.security.isContractor() && <>
                <TabView className='light' id='job_requirements'>
                    <TabPanel header={t('job.requirements')}>
                        {departments.value?.length === 0 &&
                            <div className='md mr pd center'>
                                <span className='mutted'>
                                    <i className='sm pd-right pi pi-exclamation-triangle' />
                                    <span>{t('job.departments.empty')}</span>
                                </span>
                            </div>}
                        <div className={'c g-20'}>
                            {departments.value && departments.value.map((dep, i) =>
                                <>
                                    {props.workId !== 23 && <div className={'department-name'}>
                                        {t('Department')}:
                                        <strong className={'sm pd-left'}>{dep.department?.name}</strong>
                                        <i className="fas fa-user-shield" />
                                    </div>}
                                    <EditRequirements
                                        key={i}
                                        job={props.job}
                                        readOnly={!canSaveRequirements}
                                        onHasPendingRequirements={(value: boolean) => setTargetReqPending({ ...targetReqPending, [dep.id ?? 0]: value })}
                                        targetId={dep.id!}
                                        targetTitle={dep.department?.name}
                                        targetType={RequirementTargetType.JobHasDepartment}
                                        workId={props.workId} />
                                </>)}
                            <EditRequirements
                                onHasPendingRequirements={(value: boolean) => setTargetReqPending({ ...targetReqPending, [props.jobId]: value })}
                                job={props.job}
                                targetId={props.jobId}
                                targetTitle={t('Job Requirements')}
                                targetType={RequirementTargetType.Job}
                                workId={props.workId} />
                        </div>
                    </TabPanel>
                    <TabPanel headerClassName='transparent e' headerTemplate={() => null}></TabPanel>
                    {/** Si somos un subcontratista accedemos primero a los requerimientos del contratista principal **/}
                    {contractorTabs}
                </TabView>
            </>}
            {!props.security.isContractor() && <>
                {departments.value?.length === 0 &&
                    <div className='md mr pd center'>
                        <span className='mutted'>
                            <i className='sm pd-right pi pi-exclamation-triangle' />
                            <span>{t('job.departments.empty')}</span>
                        </span>
                    </div>}
                {departments.value && departments.value.map((dep, i) =>
                    <>
                        {props.workId !== 23 && <div className={'department-name'}>
                            {t('Department')}:
                            <strong className={'sm pd-left'}>{dep.department?.name}</strong>
                            <i className="fas fa-user-shield" />
                        </div>}
                        <EditRequirements
                            key={i}
                            job={props.job}
                            readOnly={!canSaveRequirements}
                            onHasPendingRequirements={(value: boolean) => setTargetReqPending({ ...targetReqPending, [dep.id ?? 0]: value })}
                            targetId={dep.id!}
                            targetTitle={dep.department?.name}
                            targetType={RequirementTargetType.JobHasDepartment}
                            workId={props.workId} />
                    </>)}
                <EditRequirements
                    onHasPendingRequirements={(value: boolean) => setTargetReqPending({ ...targetReqPending, [props.jobId]: value })}
                    job={props.job}
                    targetId={props.jobId}
                    targetTitle={t('Job Requirements')}
                    targetType={RequirementTargetType.Job}
                    workId={props.workId} />
            </>}
        </div>
    </div>
}
