import * as React from 'react';

import { useTranslation } from 'react-i18next';
import RemoteHtml from './custom/RemoteHtml';
import { IWork } from '@models';
import { useDialogs } from '@utils/hooks';
import './Footer.css';
import { currentLocale } from '../i18n';

interface IProps {
    work?: IWork;
}

export default function Footer(props: IProps) {
    const [footer, setFooter] = React.useState<string | undefined>();

    const dialogs = useDialogs();

    const { t } = useTranslation();

    const locale = currentLocale();

    const showInfo = () => {
        dialogs.show('info');
    }

    const showPolicy = () => {
        dialogs.show('politics');
    }

    React.useEffect(() => {
        if (props.work && props.work.footerResource) {
            const resourceId = props.work.footerResource;
            setFooter(resourceId);
        }
        else {
            setFooter(undefined);
        }
    }, [props.work]);

    if (footer == undefined) {
        const email = props.work?.contextDataObj.email ?? 'info@cae-gstore.com';
        const phone = props.work?.contextDataObj.phone ?? '+34 984 18 36 74';

        return <div className='c footer-container'>
            <div id='footer'>
                <div className='left'>
                    <img src='/img/logo_footer.png' alt='gstore' />
                </div>
                {/* <div className='desc'>
                    <span className='e'></span>
                    <img src='/img/logo_gstore_pie.png' alt='gstore' />
                </div> */}
                <div className='info'>
                    <div className='r imgs'>
                        <div className='link pointer' onClick={() => window.open('https://cae-gstore.com')}>
                            <img src='/img/web.png' alt='gstore' className='web' />
                            <br />
                            <span className='e'>www.cae-gstore.com</span>
                        </div>
                        <div className='link pointer'>
                            <a href={`mailto:${email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <img src='/img/mail.png' alt='gstore' className='mail' />
                                <br />
                                <span className='e'>{`${email}`}</span>
                            </a>
                        </div>
                        <div className='link pointer' onClick={() => window.open('https://es.linkedin.com/company/caegstore')}>
                            <img src='/img/linked_in.png' alt='gstore' className='linkedin' />
                            <br />
                            <span className='e'>CAE Gstore</span>
                        </div>
                        <div className='link pointer'>
                            <img src='/img/phone.png' alt='gstore' className='phone' />
                            <br />
                            <span className='e'>{`${phone}`}</span>
                        </div>
                    </div>
                    <br />
                    <p className='copyright'>
                        Copyright 2022 G-STORE Soluciones y proyectos G-STORE S.L.&nbsp;|&nbsp;
                        {locale != 'es' ? <>
                            <a onClick={showInfo} style={{ color: '#838383', textDecoration: 'underLine', cursor: 'pointer' }}>{t('footer.cookies.link')}</a>&nbsp;|&nbsp;
                            <a onClick={showPolicy} target='_blank' style={{ color: '#838383', textDecoration: 'underLine', cursor: 'pointer' }}>{t('footer.police.link')}</a>
                        </> : <>
                            <a href='https://cae-gstore.com/politica-de-cookies/' target='_blank'
                                style={{ color: '#838383', textDecoration: 'underLine', cursor: 'pointer' }}>{t('footer.cookies.link')}</a>&nbsp;|&nbsp;
                            <a href='https://cae-gstore.com/politica-privacidad/' target='_blank'
                                style={{ color: '#838383', textDecoration: 'underLine', cursor: 'pointer' }}>{t('footer.police.link')}</a>&nbsp;|&nbsp;
                            <a href='https://cae-gstore.com/wp-content/uploads/2021/06/politica.pdf' target='_blank'
                                style={{ color: '#838383', textDecoration: 'underLine', cursor: 'pointer' }}>{t('footer.quality.politic.link')}</a>&nbsp;|&nbsp;
                            <a href='https://cae-gstore.com/wp-content/uploads/2022/09/POLITICA-SGSI.pdf' target='_blank'
                                style={{ color: '#838383', textDecoration: 'underLine', cursor: 'pointer' }}>{t('footer.sgsi.politic.link')}</a></>}
                    </p>
                    {dialogs.render('info', { title: t('cookie.politic.title') }, () => <RemoteHtml resource='CookiePolicy.html' useI18n />)}
                    {dialogs.render('politics', { title: t('privacy.politics') }, () => <RemoteHtml resource='CookiePolitics.html' useI18n />)}
                </div>
                <div className='certs'>
                    <div className='cert'>
                        <img src='/img/certs/9001.png' alt='gstore' style={{ width: "52px", height: "99px" }} />
                    </div>
                    <div className='cert'>
                        <img src='/img/certs/14001.png' alt='gstore' style={{ width: "52px", height: "99px" }} />
                    </div>
                    <div className='cert'>
                        <img src='/img/certs/cert_4.png' alt='gstore' style={{ width: "52px", height: "99px" }} />
                    </div>
                    <div className='cert'>
                        <img src='/img/certs/REC-45001.png' alt='gstore' style={{ width: "52px", height: "99px" }} />
                    </div>
                </div>
            </div>
        </div>
    }
    else {
        return <RemoteHtml
            contextData={props.work?.contextDataObj}
            resource={footer} />
    };
}
