import * as React from 'react';

import ContractorProfile from '@containers/ContractorProfileContainer';
import WorkerProfile from '@containers/WorkerProfileContainer';
import { ISecurity, IUserIdentity } from '@models';

export interface IProps {
    security: ISecurity;
    user: IUserIdentity;
    workId?: number;
}

export default function UserProfile(props: IProps) {
    if (props.workId == undefined) {
        return null;
    }
    else if (props.security.isWorker()) {
        return <WorkerProfile
            user={props.user}
            security={props.security}
            workId={props.workId} />;
    }
    else if (props.security.isContractor()) {
        return <ContractorProfile
            user={props.user}
            workId={props.workId}
            security={props.security} />;
    }
    else {
        return (<div>
            TODO
        </div>);
    }
}
