import * as React from 'react';

import { IUserIdentity } from '@models';
import Sec from '@utils/security';
import { ProgressBar, useTranslation } from '@components';
import AdminModule from '@containers/admin/ModuleContainer';
import UserModuleContainer from '@containers/user/UserModuleContainer';
import GuestHome from './guest/Home';

import './Home.css';
import { storageClear } from '@utils/storage';

export interface IProps {
    user: IUserIdentity;
    loading: boolean;
    match: any;
}

export default function Home(props: IProps) {
    const { t } = useTranslation();

    React.useEffect(() => {
        storageClear(k => k.startsWith('filters'));
    }, []);

    if (props.user && Sec.isAdmin(props.user)) {
        return <AdminModule />
    }
    else if (props.loading) {
        return <div className='center lg padding'>
            <div id='home-progress-container' className='c g-20'>
                <span>{t("AppLoading")}</span>
                <ProgressBar mode='indeterminate' />
            </div>
        </div>
    }
    else if (props.user) {
        return <UserModuleContainer match={props.match} />
    }
    else {
        return <GuestHome />
    }
}
