import * as React from 'react';

import { search } from '@store/actions/search';
import { useLoading, useMessage } from '@utils/hooks';

export interface IProps {
    search: string;
    filters?: any;
    workId: number;
    normalizeKeys?: boolean;
    lazy?: boolean;
    dependencies?: any;
}

export default function useSearchFirst<T>(props: IProps) {
    const [data, setData] = React.useState<T[]>([]);
    const loading = useLoading();
    const messages = useMessage();

    const initialize = loading.wrap(async (filters: any = undefined) => {
        const resp = await search<T>(props.workId, props.search, {
            normalizeKeys: props.normalizeKeys ?? true,
            filters: filters ?? props.filters
        });
        messages.set(resp);
        if (resp.hasValue) {
            setData(resp.value);
        }
    });

    React.useEffect(() => {
        if (!props.lazy) {
            initialize();
        }
    }, props.dependencies ?? []);

    return {
        value: data && data.length > 0 ? data[0] : undefined,
        loading,
        messages,
        renderLoading: loading.render,
        doSearch: (filters: any = undefined) => initialize(filters),
    };
}
