import { IContractorSettings, IJobSettings, ILegalForm, IMachinerySettings, IWorkerSettings, IWorkFunction, ResourceType } from '@models';
import { IUserIdentity } from './identity';
import { IJob } from './jobs';
import { IRequirementGroup, IRequirementValidationType } from './requirements';
import { IContractor, IMachinery, IWorkI18NResource } from './resources';
import { get as i18n } from "../i18n";

const parsePropertyGroupType = (pg: IPropertyGroupType) => {
    if (pg.style) {
        return { ...pg, styleObj: JSON.parse(pg.style) };
    }
    else {
        return pg;
    }
}

export function parseWork(work: IWork) {
    if (work.settings && !work.settingsObj) {
        work.settingsObj = JSON.parse(work.settings);
    }
    if (work.contextData && !work.contextDataObj) {
        work.contextDataObj = JSON.parse(work.contextData);
    }
    if (work.propertyGroupTypes) {
        work.propertyGroupTypes = work.propertyGroupTypes.map(parsePropertyGroupType);
    }

    return work;
}

export enum JobEncodingType {
    Sequential = 0,
    SequentialByWorkspace = 1,
    Manual = 2,
}

export interface IWorkSettings {
    jobEncodingType?: JobEncodingType;
    jobEncodingPrefix?: string;
    acceptSubContractorRequests?: boolean;
    mainContractorCanFulfillSubs?: boolean;
    reactivateResourcesBehavior?: number;
    rememberSubContractorAcceptances?: boolean;
    jobHiddenFields?: string[];
    contractorHiddenFields?: string[];
    machineryHiddenFields?: string[];
    workerHiddenFields?: string[];
    signals?: string;
    clientImage?: string;
    screenImage?: string;
    createJobRelations?: boolean;
    allowAlwaysNotifyInformationLoaded?: boolean;
    simpleDelayedRevokation?: boolean;
    jobDelayedRevokation?: boolean;
    hideByDefaultEnded?: boolean;
    hideByDefaultEndedContractors?: boolean;
    showHelpDesk?: boolean;
    jobChainFinalisation?: boolean;
}

export interface IWorkDepartment {
    id: number;
    name: string;
    description?: string;
    isOblique?: boolean;
}

export interface IModuleInfo {
    id: string;
    name: string;
    description: string;
    version: string;
    resources: string[];
    hasFrontend?: boolean;
    frontendResource?: string;
}

export interface IWorkHasModule {
    id?: number;
    workId: number;
    moduleName: string;
    isActive: boolean;
}

export interface IWork {
    id: number;
    name: string;
    description?: string;
    countryId: number;
    settings: string;
    defaultEmail?: string;
    helpDeskEmail?: string;
    footerResource?: string;
    contextData?: string;

    contractorInvitationTemplateId?: number;
    resetPasswordTemplateId?: number;

    settingsObj?: IWorkSettings;
    contextDataObj?: any;

    users: IUserIdentity[];
    workspaces: IWorkspace[];
    legalForms: ILegalForm[];
    workShifts: IWorkShift[];
    departments?: IWorkDepartment[];
    workFunctions?: IWorkFunction[];

    permissions: IWorkPermission[];

    workI18nResources?: IWorkI18NResource;
    propertyGroupTypes?: IPropertyGroupType[];
    requirementGroups?: IRequirementGroup[];
    requirementValidationTypes?: IRequirementValidationType[];

    validations?: IFieldValidation[];

    contractorSettings?: IContractorSettings;
    workerSettings?: IWorkerSettings;
    machinerySettings?: IMachinerySettings;
    jobSettings?: IJobSettings;

    workHasModules?: IWorkHasModule[];
    activeModules?: IModuleInfo[];
}

export interface IFieldValidation {
    id?: number;
    workId: number;
    name: string;
    field: string;
    value: string;
    resourceType: number;
    errorMessage: string;
}

export interface IWorkPermission {
    id?: number;
    workId: number;
    name: string;
    action: string;
    expression?: string;
    roles?: string;
    policies?: string;
}

export interface IWorkspace {
    id: number;
    name: string;
    description?: string;
    isActive: boolean;
    workId: number;

    countryId?: number;
    countyId?: number;
    regionId?: number;

    contact?: string;
    address?: string;
    location?: string;
    postalCode?: string;
    phone?: string;
    fax?: string;
    email?: string;

    properties?: IPropertyGroup[];
}

export interface IWorkShift {
    id: number;
    name: string;
    workId: number;
    startMinutes: number;
    endMinutes: number;
}

export interface IContractType {
    id: number;
    name: string;
}

export interface ISubMainContractorRequest {
    workId: number,
    jobId: number,
    targetContractorId: number,
    startDate?: Date;
    jobsDescription: string;
    shipmentNumber: string;
}

export interface ISubContractorRequest {
    id?: number;
    workId: number;
    jobId?: number;
    dateTime?: Date;
    jobsDescription: string;
    shipmentNumber: string;
    sourceContractorId: number;
    targetContractorId: number;

    jobHasContractorId: number;
    sourceContractor?: IContractor;
    targetContractor?: IContractor;

    acceptedByWork?: boolean;
    acceptedByWorkDateTime?: Date;
    acceptedByWorkUserId?: number;

    acceptedByContractor?: boolean;
    acceptedByContractorDateTime?: Date;
    acceptedByContractorUserId?: number;

    rejected?: boolean;
    rejectedReason?: string;
    rejectedDateTime?: Date;

    job?: IJob;

    properties?: IPropertyGroup[];
}

export interface IContractorInvitation {
    id?: number;
    workId: number;
    jobId?: number;
    subject?: string;
    message?: string;
    parentJobHasContractorId?: number;
    name: string;
    email: string;
    dateTime?: Date;
    acceptedDate?: Date;
    // Si tiene jobId
    startDate?: Date;
    shipmentNumber?: string;
    jobsDescription?: string;

    propertyGroupTypes?: IPropertyGroupType[];
}

export interface IContractorInvitationRegisterData {
    invitationId?: number;
    jobId?: number;
    workId: number;

    name: string;
    code: string;
    remarks?: string;
    legalFormId: number;
    activityTypeId: number;
    otherActivityType?: string;
    address?: string;
    postalCode?: string;
    countryId?: number;
    regionId?: number;
    countyId?: number;
    parentId?: number;

    contactName?: string;
    contactSurname?: string;
    contactPhone?: string;
    contactEmail?: string;

    userName?: string;

    email: string;
    password: string;
    passwordConfirmation?: string;
    properties: IPropertyGroup[];

    shipmentNumber?: string;
    jobsDescription?: string;
    startDate?: Date;

    city?: string;
    contactPerson?: string;
}

export enum PropertyGroupObjectType {
    Job = 0,
    Contractor = 1,
    JobHasContractor = 2,
    Machinery = 3,
    JobHasMachinery = 4,
    JobHasContractorRequest = 5,
    JobHasWorker = 6,
    Worker = 7,
    Workspace = 8,
}

export function isJobPropertyGroupType(p: IPropertyGroupType) {
    return p.objectType == PropertyGroupObjectType.Job;
}

export function isJobHasContractorPropertyGroupType(p: IPropertyGroupType) {
    return p.objectType == PropertyGroupObjectType.JobHasContractor;
}

export function isMachineryPropertyGroupType(p: IPropertyGroupType) {
    return p.objectType == PropertyGroupObjectType.JobHasMachinery;
}

export function isWorkerPropertyGroupType(p: IPropertyGroupType) {
    return p.objectType == PropertyGroupObjectType.JobHasWorker;
}

export function isWorkspacePropertyGroupType(p: IPropertyGroupType) {
    return p.objectType == PropertyGroupObjectType.Workspace;
}

export function isContractorWorkerPropertyGroupType(p: IPropertyGroupType) {
    return p.objectType == PropertyGroupObjectType.JobHasWorker && p.isInternal !== true;
}

export function isRRHHPropertyGroupType(p: IPropertyGroupType) {
    return p.objectType == PropertyGroupObjectType.JobHasWorker && p.isInternal === true;
}

export enum PropertyTypes {
    TEXT = 1,
    BOOL = 2,
    BOOLTEXT = 3,
    SELECT = 4,
    TEXTAREA = 5,
}

export const AllPropertyTypes = [
    { id: PropertyTypes.TEXT, name: 'TEXT' },
    { id: PropertyTypes.BOOL, name: 'BOOL' },
    { id: PropertyTypes.BOOLTEXT, name: 'BOOLTEXT' },
    { id: PropertyTypes.SELECT, name: 'SELECT' },
    { id: PropertyTypes.TEXTAREA, name: 'TEXTAREA' },
];

export interface IPropertyType {
    id?: number;
    name: string;
    title: string;
    typeId: number;
    placeholder?: string;
    help?: string;
    style?: string;
    parentId: number;
    propertyOrder?: number;
    selectOptions?: string;
}

export interface IPropertyGroupType {
    id?: number;
    workId: number;
    objectType: PropertyGroupObjectType;
    name: string;
    title: string;
    mandatory: boolean;
    hidable: boolean;
    description?: string;
    styleClassName?: string;
    style?: string;
    containerType?: string;
    properties?: IPropertyType[];
    isInternal?: boolean;
    // isolated?: boolean;
    styleObj?: any;
}

export interface IPropertyGroup {
    id?: number;
    workId: number;
    propertyGroupTypeId: number;
    jobId?: number;
    contractorId?: number;
    jobHasContractorId?: number;
    machineryId?: number;
    jobHasMachineryId?: number;
    value: string;
    isInternal?: boolean;

    valueObj?: any;
}

export interface IElementHasProperties {
    properties?: IPropertyGroup[];
}

export interface IPreventiveResource {
    id: number;
    name: string;
}

export interface INotificationTemplate {
    id?: number;
    name: string;
    title: string;
    message: string;
    workId: number;
}

export interface INotificationMessage {
    id?: number;
    workId: number;
    action: number;
    resourceType: number;
    notificationTemplateId: number;
    byMail?: boolean;
    notificationTemplate?: INotificationTemplate;
    restrictToResourceType?: number;
    restrictToResourceId?: number;
    actionKey?: string;
}

export const getNotificationMessageActions = (t: Function) => {
    return [
        { id: NotificationMessageAction.CreateJob, name: t('job.create') },
        { id: NotificationMessageAction.JobAssignContractor, name: t('job.assign.contractor') },
        { id: NotificationMessageAction.SubContractorRequestCreate, name: t('subcontractor.request.create') },
        { id: NotificationMessageAction.SubContractorRequestAccept, name: t('subcontractor.request.accept') },
        { id: NotificationMessageAction.SubContractorRequestReject, name: t('subcontractor.request.reject') },
        { id: NotificationMessageAction.JobInformationLoaded, name: t('job.notify-information-loaded') },
        { id: NotificationMessageAction.JobFinalized, name: t('job.notify.finalized') },
        { id: NotificationMessageAction.JobAuthorized, name: t('job.notify.authorized') },
        { id: NotificationMessageAction.JHCAuthorized, name: t('jhc.notify.authorized') },
        { id: NotificationMessageAction.JHWAuthorized, name: t('jhw.notify.authorized') },
        { id: NotificationMessageAction.JHMAuthorized, name: t('jhm.notify.authorized') },
        { id: NotificationMessageAction.JHCRejected, name: t('jhc.notify.rejected') },
        { id: NotificationMessageAction.JHWRejected, name: t('jhw.notify.rejected') },
        { id: NotificationMessageAction.JHMRejected, name: t('jhm.notify.rejected') },
        { id: NotificationMessageAction.ReqExpired, name: t('req.notify.expired') },
        { id: NotificationMessageAction.NotifyRequirementExpirationWithinTolerance, name: t('req.notify.expired.within.tolerance') },
        { id: NotificationMessageAction.ReqIncidence, name: t('req.notify.incidence') },
        { id: NotificationMessageAction.ReqFulfilled, name: t('req.notify.upload') },
        { id: NotificationMessageAction.ReqValidated, name: t('req.notify.validation') },
        { id: NotificationMessageAction.ModuleMessage, name: t('module.message') },
        // { id: NotificationMessageAction.JobCreatedAfterInfoLoaded, name: t('job.created.info.loaded') },
        { id: NotificationMessageAction.RequirementNote, name: t('requirement.note') },
        { id: NotificationMessageAction.JobRequirementNote, name: t('job.requirement.note') },
        { id: NotificationMessageAction.JobAssignImpliedRSO, name: t('job.assign.implied-rso') },
        { id: NotificationMessageAction.JobAssignMainRSO, name: t('job.assign.main-rso') },
        { id: NotificationMessageAction.JobAssignMedicalDepartment, name: t('job.assign.medical-department') },
        { id: NotificationMessageAction.JobAssignEnvironmentDepartment, name: t('job.assign.environment-rso') },
        { id: NotificationMessageAction.JobContractorInvitation, name: t('job.contractor.invitation') },
        { id: NotificationMessageAction.NotifyUnauthorizedJob, name: t('job.unauthorized.notify') },
        { id: NotificationMessageAction.NotifyAuthorizedJob, name: t('job.authorized.notify') },
        { id: NotificationMessageAction.NotifyJobReopen, name: t('job.reopen.notify') },
        { id: NotificationMessageAction.ITAWorkerNotFound, name: t('ita.worker.not-found') },
        { id: NotificationMessageAction.IrregularityCreated, name: t('irregularity.created') },
        { id: NotificationMessageAction.CorrectiveMeasureLoaded, name: t('corrective-measure.pessma.loaded') },
        { id: NotificationMessageAction.CorrectiveMeasureContractorLoaded, name: t('corrective-measure.contractor.loaded') },
        { id: NotificationMessageAction.CorrectiveMeasureValidated, name: t('corrective-measure.pessma.validated') },
        { id: NotificationMessageAction.CorrectiveMeasureContractorValidated, name: t('corrective-measure.contractor.validated') },
        { id: NotificationMessageAction.CorrectiveMeasureRejected, name: t('corrective-measure.pessma.rejected') },
        { id: NotificationMessageAction.CorrectiveMeasureContractorRejected, name: t('corrective-measure.contractor.rejected') },
        { id: NotificationMessageAction.IrregularityModified, name: t('irregularity.modified') },
        { id: NotificationMessageAction.JobEvaluationsPending, name: t('job.evaluations.pending') },
        { id: NotificationMessageAction.GeneralEvaluationsPending, name: t('general.evaluations.pending') },
        { id: NotificationMessageAction.NotifyRequirementExpiration, name: t('requirement.expiration.notify') },
        { id: NotificationMessageAction.NotifyJobContractorStart, name: t('job.contractor.start') },
        { id: NotificationMessageAction.UploadedOrModifiedDocumentInDownloadArea, name: t('document.uploaded.modified') },
        { id: NotificationMessageAction.NotifyCreatedOrChangedFolderInDownloadArea, name: t('folder.created.modified') },
        { id: NotificationMessageAction.ContractorIrregularityExpiredWithTolerance, name: t('contractor.irregularity.expired.tolerance') },
        { id: NotificationMessageAction.PessmaIrregularityExpiredWithTolerance, name: t('pessma.irregularity.expired.tolerance') },
        { id: NotificationMessageAction.ContractorCorrectiveMeasureExpired, name: t('contractor.corrective-measure.expired') },
        { id: NotificationMessageAction.PessmaCorrectiveMeasureExpired, name: t('pessma.corrective-measure.expired') },
    ].sort((a, b) => a.name.localeCompare(b.name));
}

export enum NotificationMessageAction {
    CreateJob = 1,
    JobAssignContractor = 2,
    SubContractorRequestCreate = 3,
    SubContractorRequestAccept = 4,
    SubContractorRequestReject = 5,
    JobInformationLoaded = 6,
    ReqValidated = 7,
    JobFinalized = 8,
    JobAuthorized = 9,
    JobCreatedAfterInfoLoaded = 10,
    JHCAuthorized = 11,
    JHWAuthorized = 12,
    JHMAuthorized = 13,
    JHCRejected = 14,
    JHWRejected = 15,
    JHMRejected = 16,
    ReqExpired = 17,
    ReqIncidence = 18,
    ReqFulfilled = 19,
    ModuleMessage = 20,
    RequirementNote = 21,
    JobRequirementNote = 22,
    JobAssignImpliedRSO = 23,
    JobAssignMainRSO = 24,
    JobAssignMedicalDepartment = 25,
    JobAssignEnvironmentDepartment = 26,
    JobContractorInvitation = 27, // Invitación contrata trabajo
    NotifyUnauthorizedJob = 28, // Notificar PESSMA Desautorización
    NotifyAuthorizedJob = 29, // Notificar PESSMA autorización,
    NotifyJobReopen = 30, // Notificar Reactivación de PESSMA
    ITAWorkerNotFound = 31, // Trabajador no encontrado en ITA
    IrregularityCreated = 32, // Irregularidad creada
    CorrectiveMeasureLoaded = 33, // Medida correctora cargada de un trabajo
    CorrectiveMeasureValidated = 34, // Medida correctora validada de un trabajo
    CorrectiveMeasureRejected = 35, // Medida correctora rechazada de un trabajo
    JobEvaluationsPending = 36, // Evaluaciones trabajo pendientes
    GeneralEvaluationsPending = 37, // Evaluaciones generales pendientes
    NotifyRequirementExpiration = 38, // Notificar caducidad requerimiento
    NotifyJobContractorStart = 39,
    CorrectiveMeasureContractorLoaded = 40, // Medida correctora cargada de un contratista
    CorrectiveMeasureContractorValidated = 41, // Medida correctora validada de un contratista
    CorrectiveMeasureContractorRejected = 42, // Medida correctora rechazada de un contratista
    NotifyRequirementExpirationWithinTolerance = 43, // Notificar caducidad requerimiento dentro de la tolerancia
    IrregularityModified = 44, // Irregularidad modificada
    UploadedOrModifiedDocumentInDownloadArea = 45, // Documento subido o modificado en área de descargas
    NotifyCreatedOrChangedFolderInDownloadArea = 46, // Carpeta creada o modificada en área de descargas
    ContractorIrregularityExpiredWithTolerance = 47, // Irregularidad Contratista caducada con tolerancia
    PessmaIrregularityExpiredWithTolerance = 48, // Irregularidad PESSMA caducada con tolerancia
    ContractorCorrectiveMeasureExpired = 49, // Medida Correctora Contratista caducada
    PessmaCorrectiveMeasureExpired = 50, // Medida Correctora PESSMA caducada
}

export const fieldValidationResourceTypes = [
    { id: ResourceType.Worker, name: i18n('Worker') },
    { id: ResourceType.Machinery, name: i18n('Machinery') },
    { id: ResourceType.Contractor, name: i18n('Contractor') },
];

const __fieldValidations: any = {};
export const createFieldValidation = (v: string) => {
    if (__fieldValidations[v]) {
        return __fieldValidations[v];
    }
    else {
        const expression = atob(v);
        const body = 'const a = ' + expression + '; return a(i, ctx);';
        const fn = new Function('i', 'ctx', body);
        __fieldValidations[v] = fn;

        return fn;
    }
}
