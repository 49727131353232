import * as React from 'react';

import { Chips, G, Select, useTranslation } from '@components';
import { IPropertyType, AllPropertyTypes, PropertyTypes } from '@models';
import { useObjectState } from '@utils/hooks';

export interface IProps {
    footer: any;
    loading?: boolean;
    onChange?: Function;
    parentId: number;
}

export function PropertyTypeForm(props: IProps) {
    const { t } = useTranslation();

    const data = useObjectState<IPropertyType>({
        name: '',
        title: '',
        parentId: props.parentId,
        typeId: PropertyTypes.TEXT,
        selectOptions: "",
    }, {
        onChange: props.onChange,
    });

    const [selectOptions, setSelectOptions] = React.useState<any[]>([]);

    const isValid =
        data.value.name != '' && data.value.title != '' && !props.loading;

    React.useEffect(() => {
        if (data.value.typeId != PropertyTypes.SELECT) {
            setSelectOptions([]);
        }
    }, [data.value.typeId]);

    React.useEffect(() => {
        data.set('selectOptions')(selectOptions.join(','));
    }, [selectOptions]);

    return <div className='c'>
        <div className='form-1 l200 sm pd'>
            <G label={t('Name')}>
                <input autoFocus type='text' value={data.field('name')} onChange={data.set('name')} />
            </G>
            <G label={t('Title')}>
                <input type='text' value={data.field('title')} onChange={data.set('title')} />
            </G>
            <G label={t('Type')}>
                <Select
                    options={AllPropertyTypes}
                    optionLabel={'name'}
                    optionValue={'id'}
                    value={data.field('typeId')}
                    onChange={data.setInt('typeId')} />
            </G>
            {data.value.typeId == PropertyTypes.SELECT && <G label={t('Options')}>
                <div className='c'>
                    <Chips
                        className='e-ch'
                        separator=','
                        value={selectOptions}
                        onAdd={d => setSelectOptions([...selectOptions, d.value])}
                        onRemove={d =>setSelectOptions(selectOptions.filter((o: any) => o != d.value))}
                        />
                </div>
            </G>}
        </div>
        {props.footer({disabled: !isValid})}
    </div>
}