type DispatcherF = (value: string) => void;

export default class InputEventDispatcher {
    private value?: string;
    private ts: number;
    private sended: boolean;

    constructor(
        private dispatchEvent: DispatcherF,
        private onChange?: DispatcherF,
        private delay: number = 500) {
        this.ts = new Date().getTime();
        this.value = undefined;

        this.enqueue = this.enqueue.bind(this);
        this.sended = false;
    }

    private enqueue() {
        const nts = new Date().getTime();
        if (nts - this.ts > this.delay) {
            if (this.sended) {
                return;
            }
            this.sended = true;
            this.dispatchEvent(this.value ?? '');
        }
        else {
            setTimeout(this.enqueue, this.delay);
        }
    }

    public append(event: any) {
        const value = event.target.value;
        const ts = new Date().getTime();

        this.sended = value == this.value;
        // console.log('InputEventdispatcher, set ', value, this.onChange);
        this.onChange?.(value);

        this.value = value;
        setTimeout(this.enqueue, this.delay);

        this.ts = ts;
    }
}
