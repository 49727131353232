import {m, q} from './graphql';
import { ensureType } from './utils';
import {IOption} from '@models';
import {IDocument, IFolder} from '@models/documents';

export async function pushS3TemporalResource(f: File): Promise<IOption<string>> {
    const url = 'https://nzjvbux75nqmfarnutjugr5n5i0bphru.lambda-url.eu-west-3.on.aws/';
    const formData = new FormData();
    formData.append('file', f);
    formData.append('name', f.name);
    formData.append('session', 'todo');

    const options = {
        method: 'POST',
        body: formData,
    };
    const resp = await fetch(url, options);
    const id = await resp.text();
    return { hasValue: true, value: id };
}

export async function pushLocalTemporalResource(f: File): Promise<IOption<string>> {
    const url = '/api/files/resource/push';

    const formData = new FormData();
    formData.append('file', f);

    const options = {
        method: 'POST',
        body: formData,
    };
    const resp = await fetch(url, options);
    return await resp.json();
}

export function pushTemporalResource(f: File): Promise<IOption<string>> {
    // TODO: buscar la forma de activar/desactivar s3 en tiempo de ejecucion ??
    // return pushS3TemporalResource(f);
    return pushLocalTemporalResource(f);
}

export function getDocumentVersions(workId: number, documentId: number) {
    return q(`query GetDocumentInfo($workId: Int!, $documentId: Int!) {
        work(id: $workId) {
            document(id: $documentId) {
                versions {
                    id, name, mimeType, size, dateTime, userId
                }
            }
        }
    }`,
    { workId, documentId },
    { returnPath: 'work.document.versions' });
}

export function getDocumentLastVersion(workId: number, documentId: number) {
    return q(`query GetDocumentInfo($workId: Int!, $documentId: Int!) {
        work(id: $workId) {
            document(id: $documentId) {
                lastVersion {
                    id, name, mimeType, size, dateTime, userId
                }
            }
        }
    }`,
    { workId, documentId },
    { returnPath: 'work.document.lastVersion' });
}

export function getDocumentInfo(workId: number, documentId: number) {
    return q(`query GetDocumentInfo($workId: Int!, $documentId: Int!) {
        work(id: $workId) {
            document(id: $documentId) {
                info {
                    hasValue, isError, error, value
                }
            }
        }
    }`,
    { workId, documentId },
    { returnPath: 'work.document.info'});
}

export function getFolders(workId: number, includeFolderKinds: number[] = []) {
    return m(`query GetFolders($workId: Int!, $includeFolderKinds: [Int!]) {
        work(id: $workId) {
            folders(includeFolderKinds: $includeFolderKinds) {
                id, name, description, workId, parentFolderId, dateTime, userId, sortOrder,
                folderKind,
            }
        }
    }`, { workId, includeFolderKinds }, { returnPath: 'work.folders' });
}

export function getDocumentsAndFolders(workId: number) {
    return m(`query GetDocumentsAndFolders($workId: Int!) {
        work(id: $workId) {
            folders {
                id, name, description, workId, parentFolderId, dateTime, userId, sortOrder,
                folderKind,
            },
            documents {
                id, name, description, workId, folderId, documentTypeId, title, sortOrder, dateTime,
                uploadDateTime, code, edition, year,
                documentKind,
            }
        }
    }`, { workId }, { returnPath: 'work' });
}

export function getDocuments(workId: number, includeDocumentKinds: number[] = []) {
    return m(`query GetDocuments($workId: Int!, $includeDocumentKinds:[Int!] ) {
        work(id: $workId) {
            documents (includeDocumentKinds: $includeDocumentKinds) {
                id, name, description, workId, folderId, documentTypeId, title, sortOrder, dateTime,
                uploadDateTime, code, edition, year,
                documentKind,
            }
        }
    }`, { workId, includeDocumentKinds }, { returnPath: 'work.documents' });
}

export function saveFolder(workId: number, inputFolder: IFolder) {
    const folder = ensureType<IFolder>(inputFolder, {
        include: ['id', 'name', 'description', 'parentFolderId', 'workId', 'sortOrder', 'folderKind']
    });

    return m(`mutation SaveFolder($workId: Int!, $folder: FolderGraphInputType!) {
        saveFolder(workId: $workId, folder: $folder) {
            isError, error, value, hasValue
        }
    }`, { folder, workId }, { returnPath: 'saveFolder' });
}

export function removeFolder(workId: number, id: number) {
    return m(`mutation RemoveFolder($workId: Int!, $id: Int!) {
        removeFolder(workId: $workId, id: $id) {
            isError, error, value, hasValue
        }
    }`, { id, workId }, { returnPath: 'removeFolder' });
}

export function removeDocument(workId: number, id: number) {
    return m(`mutation RemoveDocument($workId: Int!, $id: Int!) {
        removeDocument(workId: $workId, id: $id) {
            isError, error, value, hasValue
        }
    }`, { id, workId }, { returnPath: 'removeDocument' });
}

export function saveDocument(workId: number, inputDoc: IDocument) {
    const doc = ensureType<IDocument>(inputDoc, {
        include: [
            'id', 'name', 'description', 'folderId',
            'workId', 'sortOrder', 'title', 'temporalResource',
            'code', 'year', 'edition',
            'documentKind',
            'areaDownload'
        ],
        ints: [ 'sortOrder' ]
    });

    return m(`mutation SaveDocument($workId: Int!, $doc: DocumentGraphInputType!) {
        saveDocument(workId: $workId, document: $doc) {
            isError, error, value, hasValue
        }
    }`, { workId, doc }, { returnPath: 'saveDocument' });
}
