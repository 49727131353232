import * as React from 'react';

import EditRequirements from '@containers/requirements/EditRequirementsContainer';
import { RequirementTargetType } from '@models/requirements';

export interface IProps {
    machineryId: number;
    machineryName: string;
    workId: number;
}

export function MachineryRequirements(props: IProps) {
    return <EditRequirements
        targetId={props.machineryId}
        targetTitle={props.machineryName}
        targetType={RequirementTargetType.Machinery}
        workId={props.workId} />
}