import * as React from 'react';
import { Route } from 'react-router';

import './Module.scss';

import EditContractorContainer from '@containers/contractors/EditContractorContainer';
import EditMachineryContainer from '@containers/machineries/EditMachineryContainer';
import EditWorkerContainer from '@containers/workers/EditWorkerContainer';
import JobContainer from '@containers/user/JobContainer';
import JobsContainer from '@containers/user/JobsContainer';
import JobLoader from '@containers/user/JobLoader';
import ManageContractorsContainer from '@containers/contractors/ManageContractorsContainer';
import ManageSubcontractorsContainer from '@containers/contractors/ManageSubcontractorsContainer';
import { ContractorModule } from '@components/contractors/ContractorModule';
import SelectWorkContainer from '@containers/user/SelectWorkContainer';
import ReportContainer from '@containers/reports/ReportContainer';
import WorkAccessControl from '@containers/work/WorkAccessControl';
import WorkContainer from '@containers/user/WorkContainer';
import WorkLoaderContainer from '@containers/user/WorkLoaderContainer';
import WorkReports from '@containers/work/WorkReportsContainer';
import WorkSubContractorRequestsContainer from '@containers/work/WorkSubContractorRequestsContainer';

import { redirectTo } from '@utils';
import { BreadCrumb, LoadingBox, useSignalR, useTranslation } from '@components';
import { ICommunication, IJob, IMessage, IWork, MessageType } from '@models';
import ViewMachineriesContainer from '@containers/machineries/ViewMachineriesContainer';
import ViewWorkersContainer from '@containers/workers/ViewWorkersContainer';
import WorkDocumentsContainer from '@containers/work/WorkDocumentsContainer';
import WorkUsersContainer from '@containers/users/WorkUsersContainer';
import WorkRequirementDocumentContainer from '@containers/work/WorkRequirementDocumentContainer';
import { ContractorRoutes } from '@components/contractors/ContractorRoutes';
import CommmunicationFormContainer from '@containers/communications/CommunicationFormContainer';
import CommunicationsListContainer from '@containers/communications/CommunicationsListContainer';
import CurrentCommunicationsContainer from '@containers/communications/CurrentCommunicationsContainer';

export interface IProps {
    communications: ICommunication[];
    loading: boolean;
    loadMessages: Function;
    loadingMessage?: string;
    location: any;
    match: any;
    initialize: Function;
    selectedJob?: IJob;
    version: number;
    works: IWork[];
    work?: IWork;
}

const createItems = (url: string, props: IProps, t: Function) => {
    const items = [];
    const work = props.work;
    const job = props.selectedJob;

    items.push({
        label: t('Works'),
        command: () => redirectTo('/'),
    });

    if (work && url.startsWith(`/work/${work.id}/jobs`)) {
        items.push({
            label: work.name,
            command: () => redirectTo(`/work/${work.id}`),
        });
        items.push({
            label: t('Jobs'),
            command: () => redirectTo(`/work/${work.id}/jobs`),
        });
    }
    else if (url.startsWith('/work/') && work) {
        items.push({
            label: work.name,
            command: () => redirectTo(`/work/${work.id}`),
        });
    }

    return items;
}

const wrapWork = (f: React.FC) => (props: any) => <WorkLoaderContainer {...props}>
    {f(props)}
</WorkLoaderContainer>;

const wrapJob = (f: React.FC) => (props: any) =>
    <WorkLoaderContainer {...props}>
        <JobLoader {...props}>
            {f(props)}
        </JobLoader>
    </WorkLoaderContainer>;

// Jobs
const JobWrapper = wrapJob(p => <JobContainer {...p} />);
const JobsWrapper = wrapWork(p => <JobsContainer {...p} />);
const ReportWrapper = wrapWork(p => <ReportContainer {...p} />);
// Contractors
const ContractorWrapper = wrapWork(p => <ContractorModule {...p} />);
const ContractorsWrapper = wrapWork(p => <ManageContractorsContainer {...p} />);
const EditContractorWrapper = wrapWork(p => <EditContractorContainer {...p} />);
const EditContractorRequirementsWrapper = wrapWork(p => <EditContractorContainer showRequirements={true} {...p} />);
const WorkSubContractorRequestsWrapper = wrapWork(p => <WorkSubContractorRequestsContainer {...p} />);
const SubcontractorsWrapper = wrapWork(p => <ManageSubcontractorsContainer {...p} />);

// Machinery
const EditMachineryWrapper = wrapWork(p => <EditMachineryContainer {...p} />);
const MachineriesWrapper = wrapWork(p => <ViewMachineriesContainer {...p} />);
// Workers
const EditWorkerWrapper = wrapWork(p => <EditWorkerContainer {...p} />);
const WorkersWrapper = wrapWork(p => <ViewWorkersContainer {...p} />);
// Others
const WorkAccessControlWrapper = wrapWork(props => <WorkAccessControl {...props} />);
const WorkDocumentsWrapper = wrapWork(p => <WorkDocumentsContainer {...p} />);
const WorkUsersWrapper = wrapWork(p => <WorkUsersContainer {...p} />);
const WorkReportsWrapper = wrapWork(props => <WorkReports {...props} />);
const WorkWrapper = wrapWork(p => <WorkContainer {...p} />);

const RequirementDocumentWrapper = wrapWork(p => <WorkRequirementDocumentContainer {...p} />);

const CommunicationFormWrapper = wrapWork(p => <CommmunicationFormContainer {...p} />);
const CommunicationsListWrapper = wrapWork(p => <CommunicationsListContainer {...p} />);
const CurrentCommunicationsWrapper = wrapWork(p => <CurrentCommunicationsContainer {...p} />);
const ContractorRoutesWrapper = wrapWork(p => <ContractorRoutes {...p} />);

function Module(props: IProps) {
    const [items, setItems] = React.useState<any[]>([]);
    const lastMessagesReload = React.useRef(new Date().getTime());
    const { t } = useTranslation();
    // console.log('RELOAD MODULE');
    const home = {
        icon: 'pi pi-home',
        command: () => redirectTo('/'),
    };

    const _ = useSignalR<IMessage>({
        key: 'MainModule',
        method: 'NotificationMessage',
        onMessage: r => {
            if (r.notificationType === MessageType.RELOAD_NOTIFICATIONS) {
                const dt = new Date().getTime();
                if (dt - lastMessagesReload.current > 1000 * 30) {
                    lastMessagesReload.current = dt;
                    props.loadMessages();
                }
            }
        }
    });

    React.useEffect(() => {
        if (props.match) {
            setItems(
                createItems(
                    window.location.pathname,
                    props,
                    t));
        }
    }, [props.location, props.match]);

    React.useEffect(() => {
        if (!props.version) {
            props.initialize();
        }
    }, []);

    React.useEffect(() => {
        // no redirigir en caso de que ya estemos en communications
        if (props.communications?.length > 0 && !window.location.pathname.includes('communications/current')) {
            // redirigir a communications current
            redirectTo('/work/' + props.work?.id + '/communications/current');
        }
    }, [props.communications, window.location.pathname]);

    return <div className='c he'>
        <div className='r breadcrumb-container sticky-0'>
            {items.length > 0 &&
                <BreadCrumb model={items} home={home} />}
            <div id='user-breadcrumb' />
            <span className='e' />
            <div id='breadcrumb-right' />
        </div>
        {props.loading && <div className='md mg pd center'>
            <LoadingBox message={props.loadingMessage} />
        </div>}

        <Route path='/work/:workid/contractors/:contractorid' component={ContractorRoutesWrapper} />

        <Route exact path='/' component={SelectWorkContainer} />
        <Route exact path='/work/:workid' component={WorkWrapper} />
        <Route exact path='/work/:workid/documents' component={WorkDocumentsWrapper} />
        <Route exact path='/work/:workid/users' component={WorkUsersWrapper} />
        <Route exact path='/work/:workid/reports' component={WorkReportsWrapper} />
        <Route path='/work/:workid/access-control' component={WorkAccessControlWrapper} />
        <Route exact path='/work/:workid/jobs' component={JobsWrapper} />
        <Route exact path='/work/:workid/reports/:reportName' component={ReportWrapper} />
        <Route path='/work/:workid/jobs/:jobid' component={JobWrapper} />
        {/* <Route exact path='/work/:workid/contractors/:contractorid'
               component={EditContractorWrapper} />
        <Route exact path='/work/:workid/contractors/:contractorid/requirements'
               component={EditContractorRequirementsWrapper} /> */}
        <Route exact path='/work/:workid/subcontractorrequests' component={WorkSubContractorRequestsWrapper} />
        <Route path='/work/:workid/contractor' component={ContractorWrapper} />
        <Route exact path='/work/:workid/contractors' component={ContractorsWrapper} />
        <Route exact path='/work/:workid/subcontractors' component={SubcontractorsWrapper} />
        <Route path='/work/:workid/machineries/:machineryid' component={EditMachineryWrapper} />
        <Route exact path='/work/:workid/machineries' component={MachineriesWrapper} />
        <Route path='/work/:workid/workers/:workerid' component={EditWorkerWrapper} />
        <Route exact path='/work/:workid/workers' component={WorkersWrapper} />
        <Route exact path='/work/:workid/requirements/:requirementid/documents/:documentid' component={RequirementDocumentWrapper} />
        <Route exact path='/work/:workid/communications' component={CommunicationsListWrapper} />
        <Route exact path='/work/:workid/communications/current' component={CurrentCommunicationsWrapper} />
        <Route exact path='/work/:workid/communications/new' component={CommunicationFormWrapper} />
    </div>;
}

export default React.memo(
    Module,
    (prev, next) =>
        prev.location === next.location
        && prev.match == next.match);
