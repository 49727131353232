import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as User from '../../store/user';
import * as WorkersStore from '@store/workers';
import { ProfessionalQualificationsDialog } from '../../components/workers/ProfessionalQualificationsDialog';
import { isWorkerPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState) => ({
        ...state.user,
        ...state.identity,
        ...User.plainActions,
        ...WorkersStore.plainActions,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isWorkerPropertyGroupType),
    }),
    WorkersStore.actionCreators,
)(ProfessionalQualificationsDialog as any) as any;
