import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import * as JobsStore from '../../store/jobs';
import { JobAddContractor } from '../../components/jobs/JobAddContractor';
import { isJobHasContractorPropertyGroupType } from '../../models';

export default connect(
    (state: ApplicationState) => ({
        ...JobsStore.plainActions,
        ...ContractorsStore.plainActions,
        security: state.identity?.security,
        modules: state.modules.moduleManager,
        propertyGroupTypes: state.user.work?.propertyGroupTypes?.filter(isJobHasContractorPropertyGroupType),
    }),
    JobsStore.actionCreators,
)(JobAddContractor as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
