import * as React from 'react';

import { R, ValidationBuilder, useForm, useLoading, useMessage, useTranslation } from '@components';
import { IJob, IOption, JobStatusType, ModuleManager, ISecurity } from '@models';

type SaveT = (workId: number, jobId: number, statusTypeId: number, remarks: string) => Promise<IOption<boolean>>;

export interface IProps {
    job: IJob;
    onCancel: Function;
    onSuccess: Function;
    saveJobStatus: SaveT;
    workId: number;
    moduleManager: ModuleManager;
    security: ISecurity;
}

type Authorization = {
    jobId: number,
    remarks: string;
    statusTypeId: JobStatusType,
}

export function JobFinalizeFormImpl(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const msg = useMessage();

    const form = useForm<Authorization>({
        initialValues: {
            jobId: props.job.id,
            remarks: '',
            statusTypeId: JobStatusType.FINALIZED,
        },
        validate: ValidationBuilder
            .new()
            .notEmpty('remarks')
            .lift()
    });

    const doSave = loading.wrap(async () => {
        const res = await props.saveJobStatus(
            props.workId,
            form.values.jobId,
            form.values.statusTypeId,
            form.values.remarks);

        msg.set(res);

        if (res.hasValue) {
            props.onSuccess();
        }
    });

    return <div className={'c we'}>
        <div className={'md pd c we'}>
        </div>
        <div className={'footer r r-end'}>
            {msg.renderIfError()}
            {loading.render()}
            {props.onCancel &&
                <button disabled={loading.isLoading()} onClick={() => props.onCancel()}>{t('Cancel')}</button>}
            {props.onSuccess &&
                <button className={'danger'}
                    disabled={loading.isLoading() || form.isInvalid()} onClick={doSave}>{t('Save')}</button>}
        </div>
    </div>
}

export function JobFinalizeForm(props: IProps) {

    const ctx = {

    }

    const res = props
        .moduleManager
        .renderComponent<IProps>('JobFinalizeForm', { ...props, ctx }, JobFinalizeFormImpl);

    return res;
}