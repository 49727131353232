import * as React from 'react';

import {
    FieldsBox, G, useForm, useLoading, useTranslation,
    ValidationBuilder,
} from '@components';
import { CountryRegionSelect } from  '@components/common/CountryRegionSelect';
import { IActivityType, IConstants, IContractorRequest, IJob, ILegalForm, IPropertyGroup, IPropertyGroupType, IWork, PropertyGroupObjectType } from '@models';
import { BreadcrumbItem } from '../custom/BreadcrumbItem';
import { DynamicProperties } from '../user/DynamicProperties';

export interface IProps {
    activityTypes: IActivityType[];
    constants: IConstants;
    createContractorRequest: Function;
    job: IJob;
    legalForms: ILegalForm[];
    propertyGroupTypes: IPropertyGroupType[];
    onCancel: Function;
    onSuccess: Function;
    work: IWork;
}

export const validateContractorRequest = (data: IContractorRequest) => {
    return ValidationBuilder.create(data)
        .notEmpty('contractorName')
        .notEmpty('contractorCode')
        .notEmpty('startDate')
        .notEmpty('legalFormId')
        .build();
}

export function JobRequestContractor(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading(false);

    const saveContractor = loading.wrap(async (req: IContractorRequest) => {
        const resp = await props.createContractorRequest(props.work.id, req);

        if (resp.hasValue) {
            props.onSuccess('Contractor requested successfully');
            return { ok: true };
        }
        else {
            return { ok: false, message: resp.error };
        }
    });

    const form = useForm<IContractorRequest>({
        initialValues: {
            contractorName: '',
            contractorCode: '',
            jobId: props.job.id,
            legalFormId: 0,
            properties: [],
        },
        validate: validateContractorRequest,
        onSubmit: (data: IContractorRequest) => {
            return saveContractor(data);
        },
    });

    const updateRegion = ({countryId, regionId, countyId}: any) => {
        form.setFieldValue('countryId', countryId);
        form.setFieldValue('regionId', regionId);
        form.setFieldValue('countyId', countyId);
    }

    return <form
            className='lg pd'
            onSubmit={form.handleSubmit}>
        <BreadcrumbItem
            text={t('Request new contractor')} />

        <div className='r g-10'>
            <div className='form-1 e l200'>
                <div className='c g-10'>
                    <div className='r g-20'>
                        <G label={t('Contractor social id')} className='e'>
                            {form.input('contractorName', {autoFocus: true})}
                        </G>
                        <G label={t('Code')} className='e'>
                            {form.input('contractorCode')}
                        </G>
                    </div>
                    <div className='r g-20'>
                        <G label={t('Legal form')} className='e'>
                            {form.select('legalFormId', {
                                options: props.legalForms,
                            })}
                        </G>
                        <G label={t('Activity type')} className='e'>
                            {form.select('activityTypeId', {
                                options: props.activityTypes,
                            })}
                        </G>
                    </div>
                </div>
                <FieldsBox title={t('Address')} collapsible>
                    <G label={t('Address')}>
                        {form.input('address', { containerStyle: {gridColumn: '2/5'}})}
                    </G>
                    <G label={t('Region')}>
                        <CountryRegionSelect
                            countries={props.constants.countries}
                            data={form.values}
                            onChange={updateRegion} />
                    </G>
                    <G label={t('Postal code')}>
                        {form.input('postalCode')}
                    </G>
                </FieldsBox>
                <FieldsBox title={t('Job')} collapsible>
                    <G label={t('Entry date')}>
                            {form.input('startDate', { type: 'date', style: {width: '150px'}})}
                    </G>
                    <G label={t('Jobs into this work')}>
                        {form.textarea('jobsDescription')}
                    </G>
                </FieldsBox>
                <FieldsBox title={t('Contact data')} collapsible>
                    <G label={t('Name')}>
                        {form.input('contactName')}
                    </G>
                    <G label={t('Surname')}>
                        {form.input('contactSurname')}
                    </G>
                    <G label={t('Phone')}>
                        {form.input('contactPhone')}
                    </G>
                    <G label={t('Email')}>
                        {form.input('contactEmail')}
                    </G>
                </FieldsBox>
                <div className='errors-container'>
                    {form.errorBox()}
                </div>
            </div>

            <DynamicProperties
                className='e'
                objectType={PropertyGroupObjectType.JobHasContractorRequest}
                object={form.values}
                propertyGroupTypes={props.propertyGroupTypes}
                onChange={(v: IPropertyGroup[]) => form.setFieldValue('properties', v)} />
        </div>
        <div className='cols-4 r sm pd-top'>
            <span className='e'></span>
            <div className='p-buttonset'>
                <button
                    onClick={() => props.onCancel()}>
                    {t('Cancel')}
                </button>
                <button
                    disabled={loading.isLoading()}
                    type='submit'
                    className='primary'>
                    {t('Create')}
                </button>
            </div>
        </div>
    </form>;
}