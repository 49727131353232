import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import * as User from '../../store/user';
import * as WorkersStore from '@store/workers';
import { WorkerJobsDialog } from '../../components/workers/WorkerJobsDialog';

export default connect(
    (state: ApplicationState) => ({
        ...state.user,
        ...state.identity,
        ...JobsStore.plainActions,
        ...User.plainActions,
        ...WorkersStore.plainActions,
        moduleManager: state.modules.moduleManager,
    }),
    JobsStore.actionCreators,
)(WorkerJobsDialog as any) as any;
