import { Action, Reducer } from 'redux';
import {
} from '../models';
import * as DocumentActions from './actions/documents';

export interface IDocumentsState {
}

// types
enum Types {
}

export type KnownAction = {type: undefined};

export const plainActions = {
    pushTemporalResource: DocumentActions.pushTemporalResource,
    getDocumentsAndFolders: DocumentActions.getDocumentsAndFolders,
    getDocuments: DocumentActions.getDocuments,
    saveDocument: DocumentActions.saveDocument,
    getFolders: DocumentActions.getFolders,
    saveFolder: DocumentActions.saveFolder,
    removeDocument: DocumentActions.removeDocument,
    removeFolder: DocumentActions.removeFolder,
}

export const actionCreators = {
};

export const reducer: Reducer<IDocumentsState> = (state: IDocumentsState | undefined, incomingAction: Action): IDocumentsState => {
    if (state === undefined) {
        return { };
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        default:
            return state;
    }
};
