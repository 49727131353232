import * as React from 'react';

import {
    BreadcrumbItem,
    useConfirm,
    useDataTable,
    useDialogs,
    useLoading,
    useMessage,
    useRemoteData,
    useResolveName,
    useTranslation
} from '@components';
import { WorkToolbar } from '@components/admin/WorkToolbar';
import { fieldValidationResourceTypes, IOption } from '@models';
import { IFieldValidation, IWork } from '@models/works';
import { FieldValidationForm } from './FieldValidationForm';

type GetT = (workId: number) => Promise<IFieldValidation[]>;
type RemoveT = (workId: number, id: number) => Promise<IOption<boolean>>;
type SaveT = (workId: number, data: IFieldValidation) => Promise<IOption<boolean>>;

export interface IProps {
    getFieldValidations: GetT;
    removeFieldValidation: RemoveT;
    saveFieldValidation: SaveT;
    work: IWork;
}

export function AdminWorkValidations(props: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const loading = useLoading();
    const messages = useMessage();
    const resolveResourceType = useResolveName(fieldValidationResourceTypes);
    const confirm = useConfirm({
        message: t('Are you sure to delete the element ?'),
        accept: console.log
    });

    const data = useRemoteData<IFieldValidation[]>(props.getFieldValidations, { parameters: [ props.work.id ] });
    const dataTable = useDataTable<IFieldValidation>({
        data: data.value,
        loading: data.loading,
        columns: [
            'name',
            { title: 'ResourceType', render: d => resolveResourceType(d.resourceType) },
            'field', 'errorMessage'
        ],
        actions: [
            { icon: 'pencil', onClick: (r: IFieldValidation) => dialogs.show('edit', r) },
            { icon: 'trash', onClick: confirm }
        ]
    });

    const doSave = loading.wrap(async (v: IFieldValidation) => {
        const res = await props.saveFieldValidation(props.work.id, v);
        messages.set(res);

        dialogs.clear();
        return data.query();
    });

    return (<div className='c md pd'>
        {dialogs.render('add', { title: t('Add validation') }, () =>
            <FieldValidationForm
                onCancel={dialogs.clear}
                onSave={doSave}
                workId={props.work.id} />
        )}
        {dialogs.render('edit', { title: t('Edit validation') }, (data: IFieldValidation) =>
            <FieldValidationForm
                data={data}
                onCancel={dialogs.clear}
                onSave={doSave}
                workId={props.work.id} />
        )}

        <BreadcrumbItem text={t('Validations')} />
        <WorkToolbar work={props.work}>
            <button onClick={dialogs.showFromEvent('add')}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add validation')}
            </button>

            {loading.render()}
            {data.renderLoading()}
        </WorkToolbar>

        {dataTable()}
    </div>)
}