import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

import { ModuleView } from '@components/modules/ModuleView';

export default connect(
  (state: ApplicationState, props: any) => ({
    state,
    moduleName: props.match.params.module,
    moduleView: props.match.params.view,
    match: props.match,
    params: props,
  }),
  { },
)(ModuleView as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
