import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as ContractorsStore from '../../store/contractors';
import * as JobsStore from '../../store/jobs';
import { ManageSubContractorRequests } from '../../components/contractors/ManageSubContractorRequests';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...ContractorsStore.plainActions,
        moduleManager: state.modules.moduleManager,
    }),
    {}
)(ManageSubContractorRequests as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
