import * as React from 'react';

import { useTranslation, Chip } from '@components';
import { SaveUserPolicies } from '@store/actions/user';

interface ISource {
    appUserId?: number,
    code?: string,
    departments?: string,
    id?: number,
    isActive?: boolean,
    legalFormId?: number,
    name?: string,
    phone?: string,
    policies?: string[],
    remarks?: string,
    surname?: string,
    userEmail?: string,
    userName?: string,
    workId?: number,
}


interface IProps {
    source: string[];
    removePolicy: (policy: string) => void;
    addPolicy: (policy: string) => void;
    hasAssociatedUser?: number;
}

export function ManagePolicies(props: IProps) {
    const { t } = useTranslation();

    const [newPolicy, setNewPolicy] = React.useState<string>('');

    const addPolicy = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (newPolicy && newPolicy.trim() !== '') {
            props.addPolicy(newPolicy);
            setNewPolicy('');
        }
    }

    const removePolicy = (policy: string) => {
        props.removePolicy(policy);
    }

    function generateId() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    }

    const chips = () => {
        const result = props.source.map((p, i) => chip(p, i));
        return result;
    };

    const chip = (p: string, i: number) => {
        const id = generateId();
        const chip = <Chip className='primary' key={id} label={p} removable onRemove={() => removePolicy(p)}></Chip>;
        return chip;
    };

    return <div>
        {props.hasAssociatedUser ?
            <div>
                <strong className='title'>{t('Policies')}</strong>

                <div className='sm pd r fwrap g-10'>
                    {chips()}
                </div>

                <div className='r'>
                    <div className='p-inputgroup'>
                        <input
                            type='text'
                            placeholder={t('Add policy')}
                            value={newPolicy}
                            onChange={e => setNewPolicy(e.target.value)}
                            className='p-inputtext p-component' />
                        <button
                            className='p-inputgroup-addon'
                            type='submit'
                            onClick={addPolicy}>
                            <i className='pi pi-plus' />
                        </button>
                    </div>
                </div>

            </div>
            :
            <h3>El trabajador no tiene asociado un usuario</h3>}
    </div>;
}