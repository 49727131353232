import * as React from 'react';

import './HelpDeskSupport.scss';
import { useForm, useLoading, useTranslation, useToast, F, ValidationBuilder } from '@components';
import { IHelpDeskItem, IOption } from '@models';
import { formatFileSize } from '@utils';

type CreateHelpDeskItemT = (item: IHelpDeskItem, files: File[]) => Promise<IOption<boolean>>;
type OnCloseT = (ok: boolean) => void;

export interface IProps {
    createHelpDeskItem: CreateHelpDeskItemT;
    onClose: OnCloseT;
    workId: number;
}

export default function HelpDeskSupport(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();

    const toast = useToast();
    const inputFileRef = React.useRef<any>();
    const [selectedFiles, setSelectedFiles] = React.useState<any[]>([]);

    const form = useForm<IHelpDeskItem>({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            description: '',
            workId: props.workId,
        },
        validateOnMount: true,
        validate: ValidationBuilder
            .create()
            .email('email')
            .notEmpty('email')
            .notEmpty('subject')
            .notEmpty('name')
            .lift(),
    });

    React.useEffect(() => {
        if (selectedFiles.length > 5) {
            setSelectedFiles(b => b.slice(0, 5));
            toast.show(t('maxFiles.error.5'), { severity: 'error' });
        }
    }, [selectedFiles]);


    const appendFile = (e: any) => {
        const files: any[] = [];
        for (let i = 0; i < e.target.files.length; i++) {
            files.push(e.target.files[i]);
        }
        setSelectedFiles(f => f.concat(files));
    }

    const selectFile = (e: any) => {
        inputFileRef.current.click();
    }

    const removeSelectedFile = (f: any) => {
        setSelectedFiles(a => a.filter(x => x != f));
    }

    const doSend = loading.wrap(async (e: any) => {
        const data = form.values;

        const res = await props.createHelpDeskItem(data, selectedFiles);
        if (res.error) {
            toast.show(t(res.error), { severity: 'error' });
            return;
        }
        props.onClose((res.hasValue && res.value) ?? false);
    });

    const dropHandler = (event: any) => {
        event.preventDefault();

        const files: File[] = [];

        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    const file = event.dataTransfer.items[i].getAsFile();
                    files.push(file);
                }
            }
        }
        else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                files.push(event.dataTransfer.files[i]);
            }
        }

        setSelectedFiles(f => f.concat(files));
    }

    const dragOverHandler = (event: any) => {
        event.preventDefault();
    }

    return (<div id='help-desk-support'>
        <div className="header">
            <span className="title">{t('helpDesk.title')}</span>
            <div className='close-handle pointer' onClick={() => props.onClose(false)}>
                <i className='fa fa-times' />
            </div>
        </div>

        {toast.render()}

        <div className="content">
            {loading.renderBox({ style: { margin: '50px' } })}

            {!loading.isLoading() && <>
                <strong>{t('helpDesk.contact')}</strong>

                <F label={t('helpDesk.name')}>
                    {form.input('name', { autoFocus: true })}
                </F>

                <F label={t('helpDesk.email')}>
                    {form.input('email', { type: 'email' })}
                </F>

                <F label={t('helpDesk.subject')}>
                    {form.input('subject')}
                </F>

                <F label={t('helpDesk.description')}>
                    {form.textarea('description', { rows: 8 })}
                </F>

                <div
                    className="files-box"
                    onClick={selectFile}
                    onDrop={dropHandler}
                    onDragOver={dragOverHandler}>
                    <i className="fa fa-upload" />

                    <input
                        type="file"
                        multiple
                        onChange={e => appendFile(e)}
                        ref={inputFileRef} />

                    <strong>{t('helpDesk.filesTitle')}</strong>
                    <span>{t('helpDesk.filesSubTitle')}</span>
                </div>

                {selectedFiles.length > 0 && <div className="selected-files">
                    <span className="title">
                        {selectedFiles.length}
                        <span>&nbsp;</span>
                        {selectedFiles.length == 1 && t('attachment-singular')}
                        {selectedFiles.length > 1 && t('attachment-plural')}
                    </span>

                    <div className="list">
                        {selectedFiles.map((f, i) => <div key={i} className="file">
                            <div className="file-name">
                                <strong>{f.name}</strong>
                                <span>{formatFileSize(f.size)}</span>
                            </div>

                            <i className="fa fa-trash pointer"
                                onClick={_ => removeSelectedFile(f)} />
                        </div>)}
                    </div>
                </div>}

                <button
                    disabled={form.isInvalid() || loading.isLoading()}
                    onClick={doSend}>{t('helpDesk.send')}</button>
            </>}
        </div>
    </div>);
}
