import * as React from 'react';

import { Column, Loading, DataTable, Tooltip, useTranslation, useMemoized } from '@components';
import { ILog, IUserIdentity } from '@models';
import { getLogs } from '@store/actions/admin';
import { formatLocalDate } from '@utils/date-utils';

export interface IProps {
    users: IUserIdentity[];
}

export function LastLogs(props: IProps) {
    const [loading, setLoading] = React.useState(true);
    const [logs, setLogs] = React.useState<ILog[]>([]);
    const [currentFilter, setCurrentFilter] = React.useState<string>('');

    const { t } = useTranslation();

    const formatDate = useMemoized((d: Date) =>
        formatLocalDate(d));

    const userName = useMemoized((id: number) =>
        props.users.find(u => u.id == id)?.userName);

    const initialize = async () => {
        const d = await getLogs();
        setLogs(d);
        setLoading(false);
    }

    React.useEffect(() => {
        initialize();
    }, []);

    const filterLogs = async (filter: string) => {
        setLoading(true);
        setCurrentFilter(filter);
        const d = await getLogs(filter);
        setLogs(d);
        setLoading(false);
    }

    const Header = (p: any) => {
        const [filter, setFilter] = React.useState<string>(p.filter);
        return <div className='r toolbar'>
            <input
                type='search'
                className='flat e'
                value={filter}
                onChange={a => setFilter(a.target.value)}
                placeholder={t('Search')} />
            <button type='submit' onClick={() => filterLogs(filter)}>
                <i className='pi pi-search' />
            </button>
        </div>
    }

    return <div>
        {loading && <Loading />}

        <DataTable
            header={<Header filter={currentFilter} />}
            className='table'
            resizableColumns
            columnResizeMode='fit'
            showGridlines
            scrollable scrollHeight='500px'
            value={logs}>
            <Column header={t('Date')} body={u => formatDate(u.dateTime)} />
            <Column header={t('User')} body={u => userName(u.userId)} />
            <Column header={t('Ctx')} field={'ctx'} />
            <Column header={t('Action')} field={'action'} />
            <Column header={t('Data')} field={'actionData'} />
            <Column header={''}
                headerClassName='td-sm'
                body={u => <span>
                    <i className={`pi pi-info-circle info-${u.id} `} />
                    <Tooltip
                        target={`.info-${u.id}`}
                        className='lg-tooltip'
                        autoHide={false}>
                        <div className="p-d-flex p-ai-center pd sm">
                            <span>{u.data}</span>
                        </div>
                    </Tooltip>
                </span>} />
        </DataTable>
    </div>
}