import * as React from 'react';

import {
    useTranslation,
    useSearch, useDataTable
} from '@components';

import './RestrictWorker.scss';

interface IProps {
    workId: number,
    removeRestrictedWorker: Function,
    handleToast: (message: string, success: boolean) => void,
}

interface IResult {
    applicantEmail: string,
    applicantName: string,
    code: string,
    contractorId: number,
    contractorName: string,
    date: string,
    id: number,
    isActive: boolean,
    name: string,
    reason: string,
    surname: string,
    workId: number,
    deactivatedAt?: string,
}

export function RestrictedWorkers(props: IProps) {

    const { t } = useTranslation();

    const workers = useSearch<IResult>({
        workId: props.workId,
        search: 'workers/restricted',
        filters: {
            workId: props.workId,
        },
    });

    React.useEffect(() => {
        workers.doSearch();
    }, []);

    const handleRemove = async (id: number, workId: number) => {
        const result = await props.removeRestrictedWorker(id, workId);

        if (result.data.removeRestrictedWorker.isError === false) {
            props.handleToast(t('Worker has been unrestricted'), true);
            workers.doSearch();
        }
        else {
            props.handleToast(t('Error while unrestricting worker'), false);
        }
    }


    const getActions = () => {
        return [
            {
                title: t('Unrestrict'),
                icon: 'fa fa-unlock',
                disabled: (row: IResult) => !row.isActive,
                onClick: (row: IResult) => {
                    handleRemove(row.id, row.workId);
                }
            }
        ];
    }

    const showDate = (date: string) => {
        return new Date(date).toLocaleDateString();
    }



    const dataTable = useDataTable({
        className: ``,
        tooltip: true,
        columns:
            [
                {
                    title: t('Date'),
                    render: d => <span title={showDate(d.date)}>{showDate(d.date)}</span>,
                    field: 'date',
                    className: 'td-md',
                    sortKey: 'date',
                },
                {
                    title: t('In effect'),
                    render: d => <span title={`${d.isActive ? t('Yes') : showDate(d.deactivatedAt!)}`}>{`${d.isActive ? t('Yes') : showDate(d.deactivatedAt!)}`}</span>,
                    field: 'isActive',
                    className: 'td-md',
                    sortKey: 'isActive',
                },
                {
                    title: t('Name'),
                    field: 'name',
                    className: 'td-md left',
                    sortKey: 'name',
                },
                {
                    title: t('Surname'),
                    field: 'surname',
                    className: 'td-md left',
                    sortKey: 'surname',
                },
                {
                    title: t('accessControl.worker.code'),
                    field: 'code',
                    className: 'td-md',
                    sortKey: 'code',
                },
                {
                    title: t('Contractor'),
                    field: 'contractorName',
                    className: 'td-md left',
                    sortKey: 'contractorName',
                },
                {
                    title: t('Applicant'),
                    field: 'applicantName',
                    className: 'td-md left',
                    sortKey: 'applicantName',
                },
                {
                    title: t('Applicant email'),
                    field: 'applicantEmail',
                    className: 'td-md left',
                    sortKey: 'applicantEmail',
                },
                {
                    title: t('Reason'),
                    field: 'reason',
                    className: 'td-md left',
                    sortKey: 'code',
                },
                {
                    title: t('Remarks'),
                    field: 'remarks',
                    className: 'td-md left',
                    sortKey: 'code',
                },
            ],
        actions: [
            {
                headerClassName: 'td-sm',
                actions: getActions()
            }
        ],
        data: workers.value,
    });


    return (
        <div className='restricted-workers'>
            {dataTable()}
        </div>
    )
}