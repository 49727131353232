import { IUserIdentity } from './identity';
import { IRequirement } from './requirements';
import { IPropertyGroup } from './works';
import { get as i18n } from '../i18n';
import { IWorkspace } from '@models';
import { IDocument } from './documents';

export interface IResourceStatusType {
  id: number;
  name: string;
}

export interface IResourceHasStatus {
  currentStatusDate?: Date;
  currentStatusType?: number;
  status?: IResourceStatus[];
}

export interface IResourceStatus {
  id: number;
  dateTime: Date;
  statusTypeId: number;
  userId?: number;
  contractorId?: number;
  machineryId?: number;
  workerId?: number;
  jobHasDepartmentId?: number;
  jobHasContractorId?: number;
  jobHasMachineryId?: number;
  jobHasWorkerId?: number;
  forcedStatusUntilDate?: Date;
}

export interface IMachinery extends IResourceHasStatus {
  id: number;
  name: string;
  code?: string;
  description?: string;
  workId: number;
  isActive?: boolean;

  // license plate
  prefixLicenseNumber?: string;
  licenseNumber?: string;
  suffixLicenseNumber?: string;

  contractor?: IContractor;
  contractorId?: number;
  machineryTypeId?: number;
  machinerySubTypeId?: number;

  properties?: IPropertyGroup[];
  requirements?: IRequirement[];
  // TODO: add picture capability
  photo?: IDocument;
}

export type IWorkerWithRelations = IWorker & {
  departments?: number[];
  workspaces?: number[];
  policies?: string[];
}

export interface IWorker extends IResourceHasStatus {
  id: number;
  workId: number;
  name: string;
  code?: string;
  surname?: string;
  remarks?: string;
  isActive?: boolean;
  fullName?: string;
  appUser?: IUserIdentity;
  workerTypeId?: number;
  workerDocumentTypeId?: number;
  phone?: string;

  workspaceId?: number;
  workspace?: IWorkspace;
  workspaces?: IWorkspace[];

  legalFormId?: number;
  legalForm?: ILegalForm;

  contractorId?: number;
  contractor?: IContractor;
  contractorName?: string;
  properties?: IPropertyGroup[];
  requirements?: IRequirement[];
  photo?: IDocument;
}

export interface IWorkI18NResource {
  id: number;
  language: string;
  name: string;
  value: string;
  workId: number;
}

export interface IActivityType {
  id: number;
  name: string;
  workId: number;
}

export interface ILegalForm {
  id: number;
  name: string;
  workId: number;
  hideContractorProperties: boolean;
  workerTypes?: ILegalFormHasWorkerType[];
}

export interface ILegalFormHasWorkerType {
  id: number;
  legalFormId: number;
  legalForm: ILegalForm;
  workerTypeId: number;
  workerType: IWorkerType;
}

export interface IDepartment {
  id: number;
  name: string;
  code?: string;
  description?: string;
  isOblique?: boolean;
  notifyJobCreation?: boolean;

  workId: number;

  workers?: IWorker[];
  policies?: string[];
}

export interface IWorkFunction {
  id: number;
  name: string;
  workId: number;
}

export interface IContractor extends IResourceHasStatus {
  id: number;
  name: string;
  code: string;
  workId: number;

  isActive?: boolean;
  legalFormId?: number;
  activityTypeId?: number;
  otherActivityType?: string;
  address?: string;
  postalCode?: string;
  countryId?: number;
  regionId?: number;
  countyId?: number;
  phone?: string;
  providerCode?: string;
  remarks?: string;
  city?: string;
  contactPerson?: string;
  creationDate?: Date;

  properties?: IPropertyGroup[];
  requirements?: IRequirement[];
}

export interface IContractorHasAppUser {
  id: number;
  contractorId: number;
  appUserId: number;
  dateTime: Date;

  appUser?: IUserIdentity;
}

export interface IContractorRequest {
  id?: number;
  jobId: number;
  contractorName?: string;
  contractorCode?: string;
  legalFormId: number;

  startDate?: Date;
  jobsDescription?: string;

  accepted?: boolean;
  discarded?: boolean;
  acceptedDate?: Date;
  discardedDate?: Date;
  resultMessage?: string;

  properties?: IPropertyGroup[];
}

export interface IMachinerySubType {
  id: number;
  name: string;
  parentId: number;
}

export interface IMachineryType {
  id: number;
  name: string;
  workId: number;

  subTypes?: IMachinerySubType[];
}

export interface IWorkerType {
  id: number;
  name: string;
  workId: number;
}

export interface IWorkerDocumentType {
  id: number;
  name: string;
  workId: number;
}

export function departmentIsProductive(d: IDepartment) {
  return d && d?.isOblique != true;
}

export enum ResourceStatusType {
  NOT_AUTHORIZED = 1,
  AUTHORIZED = 2,
  FINALIZED = 3,
  FORCED_AUTHORIZED = 4,
  FORCED_REVOKED,
  RESTRICTED = 6,
}

export const resourceStatusTypes = [
  { id: ResourceStatusType.NOT_AUTHORIZED, name: i18n('NOT AUTHORIZED') },
  { id: ResourceStatusType.AUTHORIZED, name: i18n('AUTHORIZED') },
  { id: ResourceStatusType.FINALIZED, name: i18n('FINALIZED') },
  { id: ResourceStatusType.FORCED_AUTHORIZED, name: i18n('FORCED AUTHORIZED') },
  { id: ResourceStatusType.FORCED_REVOKED, name: i18n('FORCED REVOKED') },
  { id: ResourceStatusType.RESTRICTED, name: i18n('RESTRICTED') },
]

export const getNotificationResourceTypes = (t: Function) => {
  return [
    { id: ResourceType.Contractor, name: t('Contractor') },
    { id: ResourceType.JobHasDepartment, name: t('Department') },
    { id: ResourceType.MainContractor, name: t('contractor.main') },
    { id: ResourceType.SubContractor, name: t('contractor.subcontractor') },
    { id: ResourceType.MainRSO, name: t('resource.main-rso') },
    { id: ResourceType.MainRSOCoordinator, name: t('resource.main-rso.coordinator') },
    { id: ResourceType.AssociatedRSO, name: t('resource.associated-rso') },
    { id: ResourceType.AssociatedRSOCoordinator, name: t('resource.associated-rso.coordinator') },
    { id: ResourceType.ObliqueDepartment, name: t('department.oblique') },
    { id: ResourceType.RequirementCreator, name: t('requirement.creator') },
  ];
}

type IGetResourceTypesOpts = {
  includeSubContractors?: boolean,
}

export const getResourceTypes = (t: Function, opts: IGetResourceTypesOpts = {}) => {
  const res = [
    { id: ResourceType.Contractor, name: t('Contractor') },
    { id: ResourceType.Worker, name: t('Worker') },
    { id: ResourceType.Machinery, name: t('Machinery') },
    { id: ResourceType.JobHasContractor, name: t('JobHasContractor') },
    { id: ResourceType.JobHasWorker, name: t('JobHasWorker') },
    { id: ResourceType.JobHasMachinery, name: t('JobHasMachinery') },
  ];

  const contractorRes = [
    { id: ResourceType.MainContractor, name: t('contractor.main') },
    { id: ResourceType.SubContractor, name: t('contractor.subcontractor') },
  ];

  if (opts.includeSubContractors ?? false) {
    return res.concat(contractorRes);
  }
  else {
    return res;
  }
}

export const getEventResourceTypes = (t: Function) => {
  return [
    { id: EventResourceType.Contractor, name: t('Contractor') },
    { id: EventResourceType.Worker, name: t('Worker') },
    { id: EventResourceType.Machinery, name: t('Machinery') },
    { id: EventResourceType.JobHasContractor, name: t('JobHasContractor') },
    { id: EventResourceType.JobHasWorker, name: t('JobHasWorker') },
    { id: EventResourceType.JobHasMachinery, name: t('JobHasMachinery') },
    { id: EventResourceType.JobHasMachinery, name: t('JobHasMachinery') },
    { id: EventResourceType.JobStatus, name: t('JobStatus') },
  ];
}

export enum EventResourceType {
  Worker = 1,
  Machinery = 2,
  Contractor = 3,
  JobHasWorker = 4,
  JobHasMachinery = 5,
  JobHasContractor = 6,
  Job = 7,
  JobStatus = 8,
}

export enum ResourceType {
  Contractor = 1,
  Machinery = 2,
  Worker = 3,
  JobHasContractor = 4,
  JobHasDepartment = 5,
  JobHasMachinery = 6,
  JobHasWorker = 7,
  Job = 8,
  MainContractor = 9,
  SubContractor = 10,
  MainRSO = 11,
  AssociatedRSO = 12,
  MainRSOCoordinator = 13,
  AssociatedRSOCoordinator = 14,
  ObliqueDepartment = 15,
  RequirementCreator = 16,
};
