import * as React from 'react';

import { useForm, useLoading, useToast, useTranslation, F, ValidationBuilder } from '@components';
import { IConstants, IOption, ISecurity, IUserIdentity, IWorker } from '@models';
import { goBack } from '@utils';

import './UserProfile.scss';

type SaveWorkerT = (workId: number, data: IWorker) => Promise<IOption<boolean>>;

export interface IProps {
    constants: IConstants;
    getWorker: Function;
    user: IUserIdentity;
    saveWorker: SaveWorkerT;
    security: ISecurity;
    workId: number;
}

export default function WorkerUserProfile(props: IProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const loading = useLoading(true);

    const [worker, setWorker] = React.useState<IWorker | undefined>();

    React.useEffect(() => {
        const workerIds = props.security.getWorkerIds();
        if (workerIds.length > 0 && workerIds[0]) {
            initialize(props.workId, workerIds[0]);
        }
    }, [props.security, props.workId]);

    const form = useForm<Partial<IWorker>>({
        initialValues: {},
        readonly: loading.isLoading(),
        validate: ValidationBuilder
            .create()
            .notEmpty('name')
            .notEmpty('code')
            .lift(),
    });

    const initialize = loading.wrap(async (workId: number, workerId: number) => {
        const worker = await props.getWorker(workId, workerId);

        setWorker(worker);
        form.setFieldValues(worker);
    });

    const doSave = loading.wrap(async () => {
        const res = await props.saveWorker(props.workId, form.values);

        if (res.hasValue && res.value) {
            toast.show(t('data.success'));
        }
        else {
            toast.error(res.error ?? t('data.error'));
        }
    });

    return (<div>

        {toast.render()}

        <div className='profile-container'>
            <div className="header">
                {worker?.name}
                &nbsp;
                {worker?.surname}
            </div>
            <div className="data-container">
                <div className='data'>
                    <div className="grid-4 gap">
                        <F label={t('name')}>
                            {form.input('name', { autoFocus: true, className: 'e' })}
                        </F>
                        <F label={t('surname')}>
                            {form.input('surname', { className: 'e' })}
                        </F>
                        <F label={t('Email')}>
                            <input type="text" readOnly value={props.user.email} className='e' />
                        </F>
                        <F label={t('Phone')}>
                            {form.input('phone', { className: 'e' })}
                        </F>
                    </div>
                    <F label={t('Remarks')}>
                        {form.textarea('remarks', { rows: 8 })}
                    </F>
                </div>
            </div>
            <div className="r footer-actions">
                <button onClick={_ => goBack()}>{t('Cancel')}</button>
                <span className="e"></span>
                {loading.renderBox()}
                <button
                    className="primary"
                    disabled={loading.isLoading() || form.isInvalid()}
                    onClick={_ => doSave()}>{t('Save')}</button>
            </div>
        </div>
    </div>);
}
