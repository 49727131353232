import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as JobsStore from '@store/jobs';
import * as RequirementStore from '@store/requirements';

import { UploadRequirement } from '@components/jobs/UploadRequirement';

export default connect(
    (state: ApplicationState) => ({
        ...state.user,
        ...state.identity,
        ...JobsStore.plainActions,
        ...RequirementStore.plainActions,
    }),
    JobsStore.actionCreators,
)(UploadRequirement as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
