import * as React from 'react';

import { Tag, useOverlay, useResolveName, useTranslation } from '@components';
import { ResourceType } from '@models';
import { resourceStatusTypes } from '@models/resources';
import { ViewResourceHistory } from "@components/requirements/ViewResourceHistory";

export interface IProps {
    currentStatusType: number;
    resourceId: number;
    resourceType: ResourceType;
    workId: number;
    showResourceType?: boolean;
}

export function ResourceStatus(props: IProps) {

    const { t } = useTranslation();

    const history = useOverlay({
        className: 'sm pd',
        style: { minWidth: '300px' },
        render: () =>
            <ViewResourceHistory
                resourceId={props.resourceId}
                resourceType={props.resourceType}
                workId={props.workId} />,
    });

    const resolveStatusType = useResolveName(resourceStatusTypes, { translate: true });

    return <div className="we">
        <div onClick={history.show} className="we">
            <Tag className={`resource-status resource-status-${props.currentStatusType} pointer`}
                value={`${resolveStatusType(props.currentStatusType)}${props.showResourceType ? ' ' + t(`resource-${props.resourceType}`) : ''}`} />
        </div>
        {history.render()}
    </div>;
}
