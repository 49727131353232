import * as React from 'react';

import { useTranslation, useLoading, } from '@components';
import { ICommunication } from '@models';

import './ValidateCommunication.scss';

export interface IProps {
    communication: ICommunication;
    requestClose: Function;
    validateCommunication: Function;
}

export function ValidateCommunication(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();

    const doValidate = loading.wrap(async () => {
        props.validateCommunication(props.communication.id);
        props.requestClose();
    });

    return <div className='ValidateCommunication'>
        <div className='validate-container'>
            {loading.renderBox()}

            <div className='message'>
                {t('communication.validation.message')}
                <strong>{props.communication.title}</strong>
            </div>
        </div>

        <div className='actions'>
            <button onClick={_ => props.requestClose()}>
                {t('Close')}
            </button>
            <span className='e'></span>
            <button className='primary' onClick={doValidate}>
                {t('Validate')}
            </button>
        </div>
    </div>
}