import * as React from 'react';

import { IWorkShift } from '@models';
import {
    G,
    useForm,
    useTranslation,
    ValidationBuilder
} from '@components';
import { formatMinutes, timeToMinutes } from '@utils';

export interface IProps {
    data?: IWorkShift;
    footer?: any;
    onChange?: Function;
    onSubmit: Function;
    workId: number;
}

export const validateWorkShift = (data: any) => {
    return ValidationBuilder.create(data)
        .notEmpty('name', 'Name is required')
        .notEmpty('startMinutes', 'Value is required')
        .notEmpty('endMinutes', 'Value is required')
        .build();
}

export const isValid = (data: IWorkShift) => {
    return Object.keys(validateWorkShift(data)).length == 0;
}

const convert = (data: IWorkShift) => {
    return {
        ...data,
        startMinutes: timeToMinutes(data.startMinutes),
        endMinutes: timeToMinutes(data.endMinutes)
    };
}

export function WorkShiftForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<Partial<IWorkShift|any>>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            workId: props.workId,
            startMinutes: formatMinutes(props.data?.startMinutes ?? 0),
            endMinutes: formatMinutes(props.data?.endMinutes ?? 0),
        },
        validate: validateWorkShift,
        onSubmit: (data: IWorkShift) => {
            props.onSubmit(convert(data));
            return { ok: true };
        },
        onChange: props.onChange,
    });

    return <div>
        <form onSubmit={form.handleSubmit}>
            <div className='form-1 l200 md pd'>
                <G label={t('Name')}>
                    {form.input('name', { autoFocus: true })}
                </G>
                <G label={t('Start')}>
                    {form.input('startMinutes', { type: 'time' })}
                </G>
                <G label={t('End')}>
                    {form.input('endMinutes', { type: 'time' })}
                </G>
            </div>
            <div className='errors-container'>
                {form.errorBox()}
            </div>

            {props.footer != undefined && <div className='footer'>{props.footer}</div>}
        </form>
    </div>
}