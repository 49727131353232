import * as React from 'react';

import { Message, useLoading, useRemoteData, useSearch, useSearchFirst, useTranslation } from '@components';
import { IRequirement, IRequirementHasDocument } from '@models/requirements';
import { getDocumentInfo, getDocumentLastVersion } from '@store/actions/documents';
import { formatFileSize } from '@utils';
import { IOption } from '@models';
import * as DateUtils from '@utils/date-utils';

import './RequirementDocumentInfo.scss';
import { IDocumentVersion } from '@models/documents';

function PropertyList({ properties, title }: { properties: any, title?: string }) {
    const { t } = useTranslation();
    const keys = Object.keys(properties);

    const resolveValue = (value: any, name: string) => {
        if (name.includes('fileSize')) {
            return formatFileSize(value);
        }
        else {
            if (value.length > 60) {
                return <span title={value}>{value.substring(0, 60) + '...'}</span>
            } else {
                return value;
            }
        }
    }

    return <div className='properties g'>
        {title && <strong>{title}</strong>}
        {keys
            .filter(k => properties[k])
            .map((k, i) => <div key={i} className='property'>
                <label className='property-name'>{t(k)}</label>
                <span className='property-value'>{resolveValue(properties[k], k)}</span>
            </div>)}
    </div>
}

type JobR = {
    jobName?: string;
    jobCode?: string;
}

type RequirementInfoR = {
    jobName?: string,
    jobCode?: string,

    contractorCode?: string,
    contractorName?: string,

    machineryCode?: string,
    machineryName?: string,
    machineryType?: string;
    machinerySubType?: string;
    machineryPrefixLicenseNumber?: string;
    machineryLicenseNumber?: string;
    machinerySuffixLicenseNumber?: string;

    workerCode?: string;
    workerName?: string;
    workerSurname?: string;
}

export function RequirementDocumentInfo({
    document,
    requirement,
    jobId,
    lastDocumentVersion
}:
    {
        document?: IRequirementHasDocument,
        requirement: IRequirement,
        jobId?: number,
        lastDocumentVersion?: IDocumentVersion
    }) {

    const [lv, setLv] = React.useState<IDocumentVersion | undefined>();
    const loading = useLoading();
    const { t } = useTranslation();
    const infoSearch = useSearch<RequirementInfoR>({
        workId: requirement.workId,
        search: 'requirements/requirementInfo',
        filters: {
            requirementId: requirement.id,
        },
        dependencies: [requirement, jobId]
    });
    const jobSearch = useSearchFirst<JobR>({
        workId: requirement.workId,
        search: 'requirements/job',
        filters: {
            jobId: jobId ?? 0,
        },
        dependencies: [requirement, jobId]
    });
    const data = useRemoteData<IOption<string>>(getDocumentInfo, {
        parameters: [requirement.workId, document?.document.id],
        map: (v) => {
            if (v != undefined) {
                return v.hasValue
                    ? { ...v, value: JSON.parse(v.value) }
                    : v
            }
            else {
                return v;
            }
        },
    });

    const [info, setInfo] = React.useState<RequirementInfoR | undefined>();

    const getLastVersion = async () => {
        if (document && !lastDocumentVersion) {
            const d = await getDocumentLastVersion(requirement.workId ?? 0, document?.document.id);
            setLv(d);
        }
        else if (lastDocumentVersion) {
            setLv(lastDocumentVersion);
        }
    }

    React.useEffect(() => {
        if (infoSearch.value && infoSearch.value.length > 0) {
            setInfo(infoSearch.value[0]);
        }
    }, [infoSearch.value]);

    React.useEffect(() => {
        data.query();
        getLastVersion();
    }, [document]);

    // const lastVersion = document?.document;

    return <div className='DocumentInfo'>
        {infoSearch.renderLoading()}
        {loading.render()}
        {data.renderLoading()}

        {/** <strong className={'debug-label'}>{requirement.id} {document?.document?.id}</strong> */}
        <div className='properties y'>
            <strong>{t('Requirement data')}</strong>
            <div className='property'>
                <label className='property-name'>{t('Upload date')}</label>
                <span className='property-value'>{DateUtils.formatFullTime(lv?.dateTime)}</span>
            </div>
            {document?.dateTime && <div className='property'>
                <label className='property-name'>{t('Upload date')}</label>
                <span className='property-value'>{DateUtils.formatFullTime(lv?.dateTime)}</span>
            </div>}

            {info && info.contractorName && <div className='property'>
                <label className='property-name'>{t('Contractor')}</label>
                <span className='property-value'>{info.contractorName}</span>
            </div>}

            {info && info.contractorName && <div className='property'>
                <label className='property-name'>{t('CIF')}</label>
                <span className='property-value'>{info.contractorCode}</span>
            </div>}

            {info && info.workerName && <div className='property'>
                <label className='property-name'>{t('Worker')}</label>
                <span className='property-value'>{info.workerName} {info.workerSurname}</span>
            </div>}

            {info && info.workerCode && <div className='property'>
                <label className='property-name'>{t('Worker code')}</label>
                <span className='property-value'>{info.workerCode}</span>
            </div>}

            {info && info.machineryName && <div className='property'>
                <label className='property-name'>{t('Machinery')}</label>
                <span className='property-value'>{info.machineryName}</span>
            </div>}

            {info && info.machineryType && <div className='property'>
                <label className='property-name'>{t('Machinery type')}</label>
                <span className='property-value'>{info.machineryType}</span>
            </div>}

            {info && info.machinerySubType && <div className='property'>
                <label className='property-name'>{t('Machinery sub type')}</label>
                <span className='property-value'>{info.machinerySubType}</span>
            </div>}


            {info && info.machineryLicenseNumber && <div className='property'>
                <label className='property-name'>{t('Machinery license number')}</label>
                <span className='property-value'>
                    {info.machineryPrefixLicenseNumber}
                    {info.machineryLicenseNumber}
                    {info.machinerySuffixLicenseNumber}
                </span>
            </div>}

            {info && info.machineryName && <div className='property'>
                <label className='property-name'>{t('Machinery')}</label>
                <span className='property-value'>{info.machineryName}</span>
            </div>}
            {info && info.jobName && <div className='property'>
                <label className='property-name'>{t('Job')}</label>
                <span className='property-value'>{info.jobName}</span>
            </div>}
            {jobSearch.value && <div className='property'>
                <label className='property-name'>{t('Job code')}</label>
                <span className='property-value'>{jobSearch.value.jobCode}</span>
            </div>}
            {jobSearch.value && <div className='property'>
                <label className='property-name'>{t('Job')}</label>
                <span className='property-value'>{jobSearch.value.jobName}</span>
            </div>}
        </div>

        <div className='divider'></div>

        {data.value && <>
            {data.value.isError &&
                <Message severity='error' text={data.value.error} />}
            {data.value.hasValue &&
                <PropertyList
                    title={t('Document properties')}
                    properties={data.value.value} />}
        </>}
    </div>;
}
