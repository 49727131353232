import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';
import { JobRoutes } from '../../components/jobs/JobRoutes';
import { ModuleManager } from '@modules';

export default connect(
    (state: ApplicationState) => ({
        notifications:
            state.notifications.notifications
                .filter(n => n.ctx == 'jobs.save'),
        ...state.user,
        ...state.identity,
        ...JobsStore.plainActions,
        ...state.jobs,
        moduleManager: state.modules.moduleManager,
    }),
    JobsStore.actionCreators,
)(JobRoutes as any) as any;
