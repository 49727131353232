import * as React from 'react';

import { useDataTable, useRemoteData, useTranslation, useLoading } from '@components';
import { IRequirement, IRequirementIncidence } from '@models/requirements';
import DateUtils from '@utils/date-utils';

import './ViewRequirementIncidences.scss';

export interface IProps {
    getRequirementIncidences: Function;
    requirement: IRequirement;
    targetType: number;
    targetId: number;
    onClose: Function;
}

export function ViewRequirementIncidences(props: IProps) {
    const { t } = useTranslation();

    const loading = useLoading();

    const remoteData = useRemoteData<IRequirementIncidence[]>(props.getRequirementIncidences, {
        parameters: [props.requirement.workId, props.requirement.id, props.targetType, props.targetId],
    });

    const [lastVersion, setLastVersion] = React.useState<[number, number] | undefined>();

    React.useEffect(() => {
        const documents = props.requirement.documents ?? [];
        if (documents.length > 0 && (documents[0].document.versions?.length ?? 0) > 0) {
            const v = documents[0].document!.versions!.sort((a, b) => b.id - a.id)[0];
            setLastVersion([documents[0].document.id, v.id]);
        }
    }, [props.requirement]);

    const initialize = loading.wrap(async () => {
        await remoteData.query();
    });

    const incidences = (remoteData.value ?? [])
        .sort((a, b) => (b.creationDate as any).localeCompare(a.creationDate as any));

    const renderIncidence = (d: IRequirementIncidence) => {
        return <textarea readOnly className='transparent we'>
            {d.remarks}
        </textarea>
    }

    const renderDate = (d: IRequirementIncidence) => {
        const date = DateUtils.formatLocalDate(d.creationDate);
        const [day, time] = date.split(' ');
        return <div className='wrap-text c'>
            <span>{day}</span>
            <span>{time}</span>
        </div>
    }

    const dataTable = useDataTable({
        columns: [
            {
                title: t('Date'),
                className: 'td-md center',
                render: renderDate,
            },
            { title: t('Incidence'), render: renderIncidence },
            {
                className: 'td-sm', render: (d: IRequirementIncidence) => {
                    if (d.documentVersionId != null) {
                        const [documentId, _] = lastVersion!;
                        return <i
                            className='fa fa-download pointer'
                            onClick={_ => downloadFile([documentId, d.documentVersionId!])} />
                    }
                    else {
                        return null;
                    }
                }
            }
        ],
        data: incidences,
        loading: remoteData.loading,
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const downloadFile = ([documentId, versionId]: [number, number]) => {
        const url = `/api/files/${props.requirement.workId}/document/${documentId}/version/${versionId}`;

        window.location.href = url;
    }

    return <div className='ViewRequirementIncidences'>
        <div>
            {dataTable()}
        </div>
        {lastVersion &&
            <div className='footer r r-end'>
                <button onClick={() => props.onClose()}>{t('Close')}</button>
            </div>}
        <div className='e'></div>
    </div>
}
