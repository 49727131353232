import * as React from 'react';

import { Message } from 'primereact/message';
import { INotification, NotificationType } from '../../models';
import { useTranslation } from 'react-i18next';

export interface IProps {
    notification: INotification;
}

export function NotificationBox(props: IProps) {
    const { t } = useTranslation();
    if (props.notification.type === NotificationType.ERROR) {
        return <Message severity={'error'} text={t(props.notification.message)} />;
    }
    else {
        return null;
    }
}