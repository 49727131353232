import * as React from 'react';

import * as Actions from '@store/actions/admin';
import {
    Column, ConfirmDialog, DataTable, Dialog, Loading, Message, useTranslation,
} from '@components';
import { IWork, IWorkShift } from '@models';
import { formatMinutes, getGraphqlError } from '@utils';
import { WorkShiftForm } from './WorkShiftForm';

export interface IProps {
    work: IWork;
}

export function AdminWorkShifts(props: IProps) {
    const { t } = useTranslation();
    const [error, setError] = React.useState<string|undefined>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [workShifts, setWorkShifts] = React.useState<IWorkShift[]>(
        props.work.workShifts ?? []
    );

    // add
    const [showAdd, setShowAdd] = React.useState<boolean>(false);
    // edit
    const [showEdit, setShowEdit] = React.useState<IWorkShift|undefined>();
    // remove
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IWorkShift|undefined>();

    const loadShifts = async (workId: number) => {
        setLoading(true);

        const w = await Actions.getWork(workId);
        setWorkShifts(w.data.work.workShifts);

        setLoading(false);
    }

    const removeWorkShift = async (id: number) => {
        setLoading(true);
        setError(undefined);

        const resp = await Actions.removeWorkShift(id);
        if (resp.data.removeWorkShift?.value) {
            await loadShifts(props.work.id);
        }
        else {
            setError(getGraphqlError(resp, 'removeWorkShift'));
        }
        setShowConfirmRemove(undefined);
        setLoading(false);
    }

    const saveWorkShift = async (data: IWorkShift) => {
        setLoading(true);
        setError(undefined);

        const resp = await Actions.saveWorkShift(data);
        if (!resp.data.saveWorkShift.isError) {
            await loadShifts(props.work.id);
            setShowAdd(false);
            setShowEdit(undefined);
        }
        else {
            setError(getGraphqlError(resp));
        }

        setLoading(false);
    }

    const AddEditFooter = (p: any) => {
        return <div className='r'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />
            {loading && <Loading />}
            <div className='p-buttonset'>
                <button
                    onClick={() => p.edit ? setShowEdit(undefined) : setShowAdd(false)}>
                    {t('Cancel')}
                </button>
                <button
                    className='primary'
                    disabled={loading}>
                    {t('Save')}
                </button>
            </div>
        </div>
    }

    const ListFooter = (p: any) => {
        return <div className='r sm pd footer'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />

            <span className='e' />
            <button className='p-button'
                onClick={_ => setShowAdd(true)}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add')}
            </button>
        </div>
    }

    return (<div className='c h-100p'>
        {showAdd && <Dialog
            header={t('Add work shift')}
            onHide={() => setShowAdd(false)}
            visible>
            <WorkShiftForm
                footer={<AddEditFooter />}
                onSubmit={(d: IWorkShift) => saveWorkShift(d)}
                workId={props.work.id} />
        </Dialog>}

        {showEdit && <Dialog
            header={t('Edit work shift')}
            onHide={() => setShowEdit(undefined)}
            visible>
            <WorkShiftForm
                data={showEdit}
                footer={<AddEditFooter edit />}
                onSubmit={(d: IWorkShift) => saveWorkShift(d)}
                workId={props.work.id} />
        </Dialog>}

        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={<div className='md pd'>{`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}</div>}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeWorkShift(showConfirmRemove.id)} />}

        <DataTable
            className='table'
            loading={loading}
            value={workShifts}>
            <Column header={t('Id')} headerClassName='td-sm' bodyClassName='td-sm' field='id' />
            <Column header={t('Name')} field='name' />
            <Column header={t('Start')}
                body={d => formatMinutes(d.startMinutes)}
                className='center' />
            <Column header={t('End')}
                body={d => formatMinutes(d.endMinutes)}
                className='center' />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={(d: IWorkShift) =>
                    <i className='pi pi-pencil pointer'
                        title={t('Editar')}
                        onClick={() => setShowEdit(d)} />} />
            <Column headerClassName='td-sm'
                bodyClassName='td-sm'
                body={(d: IWorkShift) =>
                    <i className='pi pi-trash pointer'
                        onClick={() => setShowConfirmRemove(d)} />} />
        </DataTable>

        <span className='e' />
        <ListFooter />
    </div>);
}
