import * as Identity from './identity';
import * as AppState from './app';
import * as AdminState from './admin';
import * as ConstantsState from './constants';
import * as JobState from './jobs';
import * as NotificationsState from './notifications';
import * as WorkState from './work';
import * as UserState from './user';
import * as ContractorState from './contractors';
import * as ModulesState from './modules';

export interface ApplicationState {
    identity: Identity.IdentityState | undefined;
    constants: ConstantsState.IConstantsState,
    app: AppState.IAppState,
    admin: AdminState.IAdminState,
    notifications: NotificationsState.INotificationsState,
    work: WorkState.IWorkState,
    user: UserState.IUserState,
    jobs: JobState.IJobState,
    contractors: ContractorState.IContractorState,
    modules: ModulesState.IModulesState,
}

export const reducers = {
    identity: Identity.reducer,
    app: AppState.reducer,
    admin: AdminState.reducer,
    constants: ConstantsState.reducer,
    jobs: JobState.reducer,
    notifications: NotificationsState.reducer,
    work: WorkState.reducer,
    user: UserState.reducer,
    contractors: ContractorState.reducer,
    modules: ModulesState.reducer,
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

type _IdentityState = Identity.IdentityState;

export type IdentityState = _IdentityState;
