import * as React from 'react';

import {
    G, useForm, useLoading, useToast, useRemoteData, useTranslation, Portal, Panel,
    ValidationBuilder, useDialogs, RequirePermission, useResolveName, useSearchFirst
} from '@components';
import { DynamicProperties } from '@components/user/DynamicProperties';
import {
    IJob, IJobHasWorker, IPropertyGroup, IPropertyGroupType, ISecurity, IWork, PropertyGroupObjectType, ResourceType
} from '@models';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import { redirectTo } from '@utils';
import { IContractor, IWorkerDocumentType, IWorkerType } from '@models/resources';
import { ResourceStatus } from '@components/common/ResourceStatus';

export interface IProps {
    getJobHasWorker: (workId: number, jobId: number, relationId: number) => Promise<IJobHasWorker>,
    jobHasWorker: IJobHasWorker;
    onCancel: Function;
    onSuccess: Function;
    propertyGroupTypes: IPropertyGroupType[];
    saveJobHasWorker: Function;
    security: ISecurity;
    work: IWork;
    job: IJob;
    jobHasContractorId?: number;
    workerTypes: IWorkerType[];
    workerDocumentTypes: IWorkerDocumentType[];
}

type FormData = Partial<IJobHasWorker>;

enum FormType {
    Data = 0,
}

export const validateJobWorkerData = (data: IJobHasWorker) => {
    return ValidationBuilder.create(data).notEmpty('name').build();
}

export function EditJobHasWorker(props: IProps) {
    const loading = useLoading();
    const { t } = useTranslation();
    const toast = useToast();
    const dialogs = useDialogs();

    const resolveWorkerType = useResolveName(props.workerTypes);
    const resolveWorkerDocumentType = useResolveName(props.workerDocumentTypes);

    const contractorData = useSearchFirst<IContractor>({
        workId: props.work.id,
        search: 'workers/contractor.get',
        filters: {
            contractorId: props.jobHasWorker.worker?.contractorId
        }
    });

    const jobHasWorker = useRemoteData<IJobHasWorker>(
        props.getJobHasWorker, {
        parameters: [props.work.id, props.jobHasWorker.jobId, props.jobHasWorker.id]
    });

    const [formType, setFormType] = React.useState<FormType>(FormType.Data);

    const save = loading.wrap(async (req: FormData) => {
        req.jobHasContractorId = props.jobHasContractorId;
        const resp = await props.saveJobHasWorker(props.work.id, req);

        if (resp.hasValue) {
            toast.show(t('Worker saved successfully'));
            return { ok: true };
        }
        else {
            return { ok: false, message: resp.error };
        }
    });

    const doSave = (..._: any) => {
        save(form.values);
    }

    const loadRequirements = () => {
        redirectTo(`/work/${props.work.id}/jobs/${props.job.id}/worker/${props.jobHasWorker.id}/requirements`);
    }

    const form = useForm<FormData>({
        initialValues: {
            id: props.jobHasWorker.id,
            jobId: props.jobHasWorker.jobId,
            startDate: props.jobHasWorker.startDate,
            workerId: props.jobHasWorker.workerId,
            fullName: props.jobHasWorker.fullName,
            properties: props.jobHasWorker.properties ?? [],
        },
        onSubmit: function(data: FormData) {
            return save(data);
        }
    });

    return <div className='c he'>
        <Portal container='#job-actions'>
            <Panel
                className='sub-section section'
                header={<div className={'c center g-10'}>
                    <div className='r we'>
                        <i className='fas fa-user-friends' />
                        <span className='e' />
                        <ResourceStatus currentStatusType={props.jobHasWorker.currentStatusType!}
                            resourceId={props.jobHasWorker.id}
                            resourceType={ResourceType.JobHasWorker}
                            workId={props.work.id} />
                    </div>
                    <span className='section-title'>{props.jobHasWorker.fullName}</span>
                </div>}>
                <div className={'r r-end fwrap'}>
                    <RequirePermission permission='jobs.forceStatus'>
                        <button onClick={dialogs.showFromEvent('force-validation')}>
                            <i className='fas fa-check-square' />
                            <span className='e'>{t('Validate') + ' ' + t('Worker')}</span>
                        </button>
                    </RequirePermission>
                    <button onClick={() => setFormType(FormType.Data)}>
                        <i className='fas fa-table' />
                        <span className='e'>{t('View data')}</span>
                    </button>
                    <button onClick={loadRequirements}>
                        <i className='fas fa-folder' />
                        <span className='e'>{t('Requirements')}</span>
                    </button>
                </div>
            </Panel>
        </Portal>

        <Portal container='#user-breadcrumb'>
            <ul>
                <li>{props.jobHasWorker.fullName}</li>
            </ul>
        </Portal>

        {dialogs.render('force-validation', { title: t('Force resource validation'), className: 'g pd' }, () =>
            <ForceValidationResourceContainer
                currentStatusType={props.jobHasWorker.currentStatusType}
                workId={props.work.id}
                requestClose={dialogs.clear}
                resourceType={ResourceType.JobHasWorker}
                resourceId={props.jobHasWorker.id} />
        )}

        {jobHasWorker.renderLoading()}

        {toast.render()}

        {formType == FormType.Data && <>
            <div className='form-header'>
                {t('Edit worker')}
            </div>
            <div className='r'>
                <div className='form-1 l200 lg pd e'>
                    <G label={t('Worker document type')}>
                        <span className='value'>{resolveWorkerDocumentType(props.jobHasWorker.worker?.workerDocumentTypeId)}</span>
                    </G>
                    <G label={t('Code')}>
                        <span className='value'>{props.jobHasWorker.worker?.code}</span>
                    </G>
                    <G label={t('Name')}>
                        <span className='value'>{props.jobHasWorker.worker?.name}</span>
                    </G>
                    <G label={t('Surname')}>
                        <span className='value'>{props.jobHasWorker.worker?.surname}</span>
                    </G>
                    <G label={t('Worker type')}>
                        <span className='value'>{resolveWorkerType(props.jobHasWorker.worker?.workerTypeId)}</span>
                    </G>
                    <G label={t('Contractor')}>
                        <span className='value'>{contractorData?.value?.name}</span>
                    </G>
                    {/* <G label={t('Start date')}>
                        {form.input('startDate', {type:'date'})}
                    </G> */}
                </div>
                <div className='sm pd-top pd-right e'>
                    <DynamicProperties
                        propertyGroupTypes={props.propertyGroupTypes}
                        object={props.jobHasWorker}
                        objectType={PropertyGroupObjectType.JobHasWorker}
                        onChange={(v: IPropertyGroup[]) => form.setFieldValue('properties', v)}
                        readonly />
                </div>
            </div>
            <div className='e' />
            <div className='r md pd'>
                <span className='e' />
                <div className='p-buttonset'>
                    <button
                        onClick={() => props.onCancel()}>
                        {t('Cancel')}
                    </button>
                    <button
                        disabled={loading.isLoading()}
                        onClick={doSave}
                        className='primary'>
                        {t('Save')}
                    </button>
                </div>
            </div>
        </>}
    </div>
}
