import * as React from 'react';

export default function useEvents() {
    const events = React.useRef<any>({});
    const on = (name: string, callback: Function) => {
        return React.useEffect(() => {
            if (!events.current[name]) {
                events.current[name] = [];
            }
            events.current[name].push(callback);
            return () => dispose(name, callback);
        }, [name, callback]);
    }
    const fire = (name: string, ...args: any[]) => {
        if (events.current[name]) {
            for (const handler of events.current[name]) {
                handler(...args);
            }
        }
    }
    const dispose = (key: string, callback: Function) => {
        if (events.current[key]) {
            events.current[key] =
                events.current[key]
                    .filter((c: Function) => c != callback);
        }
    }

    return { events, on, fire, dispose };
}
