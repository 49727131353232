export interface ICountry {
    id: number;
    name: string;
    code: string;
    regions: IRegion[];
}

export interface IDocumentType {
    id: number;
    name: string;
    description: string;
    isActive: boolean;
}

export interface IRegion {
    id: number;
    name: string;
    counties: ICounty[];
}

export interface ICounty {
    id: number;
    name: string;
    code: string;
}

export interface INode {
    label: string;
    key: any;
    data: any;
    children: INode[];
}

export const countyToNode = (c: ICounty, r: IRegion, y: ICountry) => {
    return {
        label: c.name,
        key: [y.id, r.id, c.id],
        data: c.id
    }
}

export const regionToNode = (r: IRegion, c: ICountry) => {
    return {
        label: r.name,
        key: [c.id, r.id],
        data: r.id,
        children: r.counties.map(ct => countyToNode(ct, r, c)),
    }
}

export const countryToNode = (c: ICountry) => {
    return {
        label: c.name,
        key: [c.id],
        data: c.id,
        children: c.regions.map(r => regionToNode(r, c)),
    }
}

export const countriesToNode = (c: ICountry[]) =>
    c.map(countryToNode) as any[];