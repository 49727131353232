import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { WorkReports } from '@components/work/WorkReports';

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        work: state.user.work,
    }),
    {}
)(WorkReports as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
