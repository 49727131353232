import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';

import { EditRequirementTypeExpression } from '@components/admin/requirementTypes/EditRequirementTypeExpression';

export default connect(
    (state: ApplicationState) => ({
        constants: {
            ...state.user,
            ...state.constants,
        }
    }),
    {},
)(EditRequirementTypeExpression as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
