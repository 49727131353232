import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as User from '../../store/user';

import { SelectWork } from '../../components/user/SelectWork';

export default connect(
    (state: ApplicationState) => ({
        works: state.user.works ?? [],
    }),
    User.actionCreators,
)(SelectWork as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
