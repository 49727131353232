import * as React from 'react';

import ValidateCommunicationContainer from '@containers/communications/ValidateCommunicationContainer';
import {
    useDialogs, useLoading, useTranslation, BreadcrumbItem, TabPanel, TabView, Portal
} from '@components';
import { ICommunication } from '@models';

import './CurrentCommunications.scss';
import { redirectTo } from '@utils';

export interface IProps {
    communications: ICommunication[];
    workId: number;
}

export function CurrentCommunications(props: IProps) {
    const loading = useLoading();
    const dialogs = useDialogs();
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [currentDocuments, setCurrentDocuments] = React.useState<any[]>([]);
    const { t } = useTranslation();

    const validate = () => {
        const c = props.communications[activeTab];
        dialogs.show('validate', c);
    }

    const communications = props.communications;

    const renderValidation = (c: ICommunication) => {
        return <ValidateCommunicationContainer
            requestClose={dialogs.clear}
            communication={c} />
    }

    React.useEffect(() => {
        if (communications.length == 0) {
            redirectTo(`/work/${props.workId}/`)
        }
    }, [communications]);

    React.useEffect(() => {
        if (communications[activeTab] != undefined) {
            setCurrentDocuments(communications[activeTab].documents ?? []);
        }
    }, [communications[activeTab]]);


    return <div className='CurrentCommunications'>
        <BreadcrumbItem
            text={t('communications.current')} />

        <Portal container={'#breadcrumb-right'}>
            {loading.render()}

            <button className='breadcrumb-action-green' onClick={validate}>
                {t('communication.resolve')}
            </button>
        </Portal>

        {dialogs.render('validate', { title: t('communication.resolve') }, renderValidation)}

        <TabView onTabChange={e => setActiveTab(e.index)} activeIndex={activeTab}>
            {communications.map(c => <TabPanel key={c.id} header={c.title}>
                <div className='view' dangerouslySetInnerHTML={{__html: c.description}}>
                </div>
                {currentDocuments.length > 0 && <hr />}
                {currentDocuments.length > 0 && <span className='title'>{t('communications.files')}</span>}
                {currentDocuments.map(d =><div className='c mg'><div className='file-row' key={d.id}>
                    <span className='e'>{d.name}</span>
                    <a href={`/api/files/${props.workId}/document/${d.id}`}>
                        <i className='fa fa-download' />
                    </a>
                </div>
                </div>)}
            </TabPanel>)}
        </TabView>
    </div>
}