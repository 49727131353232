import * as React from 'react';

import {
    ConfirmDialog, Message, useDataTable, useFormDialog, useLoading, useTranslation
} from '@components';
import { IActivityType } from '@models/resources';
import * as Actions from '@store/actions/resources';
import { ActivityTypeForm } from './ActivityTypeForm';

export interface IProps {
    workId: number;
}

export function AdminActivityType(props: IProps) {
    const { t } = useTranslation();

    const [error, setError] = React.useState<string | undefined>();
    const [activities, setActivities] = React.useState<IActivityType[]>([]);
    const loading = useLoading(true);
    const formDialog = useFormDialog({
        editTitle: t('Edit activity'),
        addTitle: t('Add activity'),
    });
    const [showConfirmRemove, setShowConfirmRemove] = React.useState<IActivityType | undefined>();

    const initialize = loading.wrap(async () => {
        const res = await Actions.getActivities(props.workId);

        setActivities(res.data.work.activities)
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const saveActivity = loading.wrap(async (w: IActivityType) => {
        await Actions.saveActivity(w);
        await initialize();

        formDialog.clear();
    });

    const removeActivity = loading.wrap(async (id: number, workId: number) => {
        setError(undefined);

        workId = props.workId;
        await Actions.removeActivity(id, workId);
        await initialize();

        formDialog.clear();
    });

    const dataTable = useDataTable({
        data: activities,
        className: 'e',
        loading,
        columns: [{ field: 'id', className: 'td-sm' }, 'name'],
        actions: [
            { icon: 'pencil', tooltip: t('Edit'), onClick: formDialog.showEdit },
            { icon: 'trash', tooltip: t('Delete'), onClick: setShowConfirmRemove }
        ]
    });

    return <div className='he c'>
        {showConfirmRemove && <ConfirmDialog
            visible
            onHide={() => setShowConfirmRemove(undefined)}
            message={`${t('Are you sure to delete the element ?')} ${showConfirmRemove.name}`}
            rejectLabel={t('Cancel')}
            acceptLabel={t('Delete')}
            reject={() => setShowConfirmRemove(undefined)}
            accept={() => removeActivity(showConfirmRemove.id, showConfirmRemove.workId)} />}

        {formDialog.render((data: IActivityType) =>
            <ActivityTypeForm
                data={data}
                workId={props.workId}
                notifications={[]}
                onSubmit={saveActivity}
                footer={formDialog.Footer()} />)}

        {dataTable()}

        <div className='r sm pd footer'>
            {error && <Message severity='error' text={error} />}
            <span className='e' />
            <button className='p-button'
                onClick={() => formDialog.showAdd()}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add')}
            </button>
        </div>
    </div>

}