import * as React from 'react';

import { paginate, searchExportToXls, DEFAULT_LIMIT } from '@store/actions/search';
import { useLoading, useMessage } from '@utils/hooks';
import { IPaginateResult, IPaginateSort } from '@models';
import { downloadBlob } from '@utils/file-utils';
import i18next from 'i18next';

export interface IProps {
    search: string;
    filters?: any;
    sortBy?: IPaginateSort[];
    workId: number;
    normalizeKeys?: boolean;
    lazy?: boolean;
    page?: number;
    limit?: number;
    map?: Function;
    dependencies?: any;
    exportFilename?: string;
    persist?: true;
}

const __pages: {[key: string]: number} = {};

export default function useSearchPaginate<T>(props: IProps) {

    const prevDependencies = React.useRef<any>(props.dependencies);
    const [data, setData] = React.useState<IPaginateResult<T>>({
        total: 0,
        page: __pages[props.search] ?? 0,
        data: [],
        limit: DEFAULT_LIMIT,
    });
    const [currentLimit, setCurrentLimit] = React.useState<number>(props.limit ?? DEFAULT_LIMIT);

    const [sortBy, setSortBy] = React.useState<IPaginateSort | undefined>();

    const message = useMessage();
    const loading = useLoading();

    const initialized = React.useRef(false);

    const initialize = loading.wrap(async (filters: any = undefined, page: number | undefined = undefined, limit: number | undefined = undefined) => {
        const pageValue = page ?? props.page ?? __pages[props.search] ?? 0;
        const actualLimit = limit ?? currentLimit ?? props.limit;

        const resp = await paginate<T>(props.workId, props.search, {
            normalizeKeys: props.normalizeKeys ?? true,
            filters: filters ?? props.filters,
            page: pageValue,
            limit: actualLimit,
            sortBy: sortBy ? [sortBy] : props.sortBy,
        });
        message.set(resp);
        setCurrentLimit(actualLimit);

        if (props.persist ?? false) {
            __pages[props.search] = pageValue;
        }

        if (resp.hasValue) {
            const v = resp.value;
            const rawData = (v.data && props.map)
                ? v.data.map(r => props.map!(r))
                : v.data;
            setData({ ...v, data: rawData });

            return rawData;
        }
        return undefined;
    });

    React.useEffect(() => {
        if (prevDependencies.current == props.dependencies) {
            return;
        }
        if (!props.lazy || initialized.current) {
            initialize();
        }

        if (!initialized.current) {
            initialized.current = true;
        }

        prevDependencies.current = props.dependencies;
    }, props.dependencies ?? []);

    React.useEffect(() => {
        if (sortBy != props.sortBy) {
            initialize();
        }
    }, [sortBy]);

    const exportToXls = async (filters: any, filename: string | undefined = undefined, useI18N: boolean = false) => {
        const resp = await searchExportToXls(props.workId, props.search, {
            filters: filters ?? props.filters,
        }, useI18N ? i18next.language : undefined);
        downloadBlob(resp, filename ?? props.exportFilename ?? 'export.xlsx');
    }

    const doSortBy = (name: string, asc: boolean = true) => {
        setSortBy({ name, asc });
    }

    return {
        data: data.data ?? [],
        value: data,
        loading,
        message,
        renderLoading: loading.renderBox,
        sortBy: doSortBy,
        exportToXls: (filters: any = undefined, filename: string | undefined = undefined, useI18N: boolean = false) => exportToXls(filters, filename, useI18N),
        doSearch: (filters: any = undefined, page: number | undefined = undefined, limit: number | undefined = undefined) => initialize(filters, page, limit),
    };
}
