import * as React from 'react';

import { useLoading, useMessage, useTranslation } from '@components';
import { interpolate } from '@utils';
import { currentLocale } from '../../i18n';

export interface IProps {
    contextData?: any;
    resource?: string;
    useI18n?: boolean;
    silent?: boolean;
    url?: string;
}

export default function RemoteHtml({ contextData, url, useI18n, resource, silent }: IProps) {
    const loading = useLoading();
    const errors = useMessage();

    const { t } = useTranslation();

    const [data, setData] = React.useState<string>('');

    const initialize = loading.wrap(async () => {
        const locale = useI18n ? currentLocale() + '/': '';
        const finalUrl = url
            ? url
            : resource
                ? '/api/resources/b/' + locale + btoa(resource)
                : '';
        const resp = await fetch(finalUrl);
        if (resp.status === 200) {
            const d = await resp.text();

            if (contextData) {
                setData(interpolate(d, contextData));
            }
            else {
                setData(d);
            }
        }
        else if (resp.status === 404 && !silent) {
            errors.setError(t('resource.404'));
        }
    });

    React.useEffect(() => {
        initialize();
    }, []);

    return <>
        {loading.render()}
        {errors.renderIfError()}
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </>;
}
