import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Identity from '../store/identity';
import * as App from '../store/app';

import Home from '../components/Home';

type Props =
    Identity.IdentityState &
    App.IAppState;

export default connect(
  (state: ApplicationState) => ({
      user: state.identity?.user,
      loading: state.app.loading,
  })
)(Home as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
