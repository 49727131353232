import * as React from 'react';

import { BreadcrumbItem, useTranslation } from '@components';
import ManageDocuments from '@containers/documents/ManageDocumentsContainer';
import { IWork } from '@models';

export interface IProps {
    work: IWork;
}

export function WorkDocuments(props: IProps) {
    const { t } = useTranslation();

    return <div className='we he'>
        <BreadcrumbItem text={t('Download area')} />
        <ManageDocuments work={props.work} />
    </div>
}
