import * as React from 'react';

import { Loading } from './Loading';

export interface IProps {
    message?: string;
}

export function LoadingBox(props: IProps) {
    return (<div className='c'>
        <div><Loading /></div>
        <span>{props.message}</span>
    </div>)
}