import { Action, Reducer } from 'redux';
import {
    IJob, parseJob, IContractor,
} from '../models';
import * as JobsActions from './actions/jobs';
import { actionCreators as N } from './notifications';
import { getJobLogs, jobNotifyInformationLoaded } from "./actions/jobs";
import { IJobHasContractor } from '@models';

export interface IJobState {
    selectedJob?: IJob;
    selectedJobHasContractor?: IJobHasContractor;
}

// types
enum Types {
    LoadJob = '[JOBS] LOAD JOB',
    OnJobLoaded = '[USER] ON JOB LOADED',
    SelectJobHasContractor = '[JOBS] SELECT CONTRACTOR',
    ClearSelectedContractor = '[JOBS] CLEAR SELECTED CONTRACTOR',
    ClearSelectedJobHasContractor = '[JOBS] CLEAR SELECTED JOB HAS CONTRACTOR',
    ClearSelectedJob = '[JOBS] CLEAR SELECTED JOB',
    LocationChange = '@@router/LOCATION_CHANGE',
}

// actions
export interface OnJobLoaded {
    type: Types.OnJobLoaded;
    data: IJob;
}

export interface SelectJobHasContractor {
    type: Types.SelectJobHasContractor;
    data: IJobHasContractor;
}

export interface ClearSelectedContractor {
    type: Types.ClearSelectedContractor;
}

export interface ClearSelectedJobHasContractor {
    type: Types.ClearSelectedJobHasContractor;
}

export interface ClearSelectedJob {
    type: Types.ClearSelectedJob;
}

export interface LocationChangeAction {
    type: Types.LocationChange;
    payload: any;
}

export type KnownAction = OnJobLoaded
    | SelectJobHasContractor
    | ClearSelectedJobHasContractor
    | ClearSelectedContractor
    | ClearSelectedJob
    | LocationChangeAction
    | { type: undefined };

export const plainActions = {
    getJobStatus: JobsActions.getJobStatus,
    saveJobStatus: JobsActions.saveJobStatus,
    jobNotifyInformationLoaded: JobsActions.jobNotifyInformationLoaded,
    createContractorRequest: JobsActions.createContractorRequest,
    processContractorRequest: JobsActions.processContractorRequest,
    getJobContractorRequests: JobsActions.getJobContractorRequests,
    getJob: JobsActions.getJob,
    activateJobHasContractor: JobsActions.activateJobHasContractor,
    saveJobHasContractor: JobsActions.saveJobHasContractor,
    removeJobHasContractor: JobsActions.removeJobHasContractor,
    removeJobHasContractorRequest: JobsActions.removeJobHasContractorRequest,
    removeJobHasContractorInvitation: JobsActions.removeJobHasContractorInvitation,
    deleteJobHasContractor: JobsActions.deleteJobHasContractor,
    deleteJobHasWorker: JobsActions.deleteJobHasWorker,
    deleteJobHasMachinery: JobsActions.deleteJobHasMachinery,
    searchContractors: JobsActions.searchContractors,
    getJobContractors: JobsActions.getJobContractors,
    getJobContractorsViewModel: JobsActions.getJobContractorsViewModel,
    getJobHasContractor: JobsActions.getJobHasContractor,
    getJobDepartments: JobsActions.getJobDepartments,
    saveJobHasDepartment: JobsActions.saveJobHasDepartment,
    removeJobHasDepartment: JobsActions.removeJobHasDepartment,
    getJobHasMachineries: JobsActions.getJobHasMachineries,
    getJobHasMachinery: JobsActions.getJobHasMachinery,
    activateJobHasMachinery: JobsActions.activateJobHasMachinery,
    saveJobHasMachinery: JobsActions.saveJobHasMachinery,
    searchMachineries: JobsActions.searchMachineries,
    removeJobHasMachinery: JobsActions.removeJobHasMachinery,
    // workers
    getJobHasWorker: JobsActions.getJobHasWorker,
    getJobHasWorkers: JobsActions.getJobHasWorkers,
    searchWorkers: JobsActions.searchWorkers,
    activateJobHasWorker: JobsActions.activateJobHasWorker,
    saveJobHasWorker: JobsActions.saveJobHasWorker,
    removeJobHasWorker: JobsActions.removeJobHasWorker,
    // logs
    getJobLogs: JobsActions.getJobLogs,
}

export const actionCreators = {
    clearSelectedJob: () => (dispatch: Function) => {
        dispatch({
            type: Types.ClearSelectedJob,
        });
    },
    clearSelectedContractor: () => (dispatch: Function) => {
        dispatch({
            type: Types.ClearSelectedContractor,
        });
    },
    clearSelectedJobHasContractor: () => (dispatch: Function) => {
        dispatch({
            type: Types.ClearSelectedJobHasContractor,
        });
    },
    selectJobHasContractor: (jobHasContractor: IJobHasContractor) => (dispatch: Function) => {
        dispatch({
            type: Types.SelectJobHasContractor,
            data: jobHasContractor,
        });
    },
    loadJob: (workId: number, jobId: number) => async (dispatch: Function) => {
        dispatch(N.startLoading({
            ctx: 'jobs.load',
        }));
        const resp = await JobsActions.getJob(workId, jobId);
        dispatch({
            type: Types.OnJobLoaded,
            data: parseJob(resp.data.work.job),
        });
        dispatch(N.stopLoading({
            ctx: 'jobs.load',
        }));
    },
    saveJob: (workId: number, job: IJob) => async (dispatch: Function) => {
        dispatch(N.startLoading({ ctx: 'jobs.save' }));

        const saveResp = await JobsActions.saveJob(job);
        const jobId = job.id ?? saveResp.value;
        const resp = await JobsActions.getJob(workId, jobId);
        dispatch({
            type: Types.OnJobLoaded,
            data: parseJob(resp.data.work.job),
        });
        dispatch(N.success({
            ctx: 'jobs.save',
            action: 'save',
            delay: 5000
        }));
        dispatch(N.stopLoading({ ctx: 'jobs.save' }));
    }
};

export const reducer: Reducer<IJobState> = (state: IJobState | undefined, incomingAction: Action): IJobState => {
    if (state === undefined) {
        return {};
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case Types.LocationChange:
            if (action.payload.location.pathname?.includes('/jobs/')) {
                return state;
            }
            else {
                return { ...state, selectedJob: undefined, selectedJobHasContractor: undefined };
            }

        case Types.ClearSelectedJob:
            return { ...state, selectedJob: undefined, selectedJobHasContractor: undefined };

        case Types.OnJobLoaded:
            return { ...state, selectedJob: action.data, selectedJobHasContractor: undefined };

        case Types.SelectJobHasContractor:
            return { ...state, selectedJobHasContractor: action.data };

        case Types.ClearSelectedContractor:
            return { ...state, selectedJobHasContractor: undefined };

        case Types.ClearSelectedJobHasContractor:
            return { ...state, selectedJobHasContractor: undefined };

        default:
            return state;
    }
};
