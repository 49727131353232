import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import ContractorProfile from '../components/ContractorProfile';
import * as UserStore from '../store/user';
import * as ContractorStore from '../store/contractors';

import { isJobHasContractorPropertyGroupType } from '@models';

export default connect(
    (state: ApplicationState) => ({
        constants: state.constants,
        workId: state.user?.work?.id,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isJobHasContractorPropertyGroupType),
        user: state.identity?.user,
        ...UserStore.plainActions,
        ...ContractorStore.plainActions,
        moduleManager: state.modules.moduleManager,
    }),
    {},
)(ContractorProfile as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
