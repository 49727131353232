import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ValidateCommunication } from '@components/communications/ValidateCommunication';
import * as UserStore from '@store/user';
import * as DocumentsStore from '@store/documents';
import * as Actions from '@store/actions/resources'

export default connect(
    (state: ApplicationState) => ({
        ...state.identity,
        ...state.user,
        ...state.constants,
        ...UserStore.plainActions,
        ...DocumentsStore.plainActions,
        ...Actions.getDepartments,
        workId: state.user.work?.id,
    }),
    {
        ...UserStore.actionCreators,
    },
)(ValidateCommunication as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
