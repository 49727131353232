import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as Admin from '@store/admin';
import * as App from '@store/app';

import AdminUsers, { IProps } from '@components/admin/AdminUsers';
import { NotificationType } from '@models';

export default connect(
  (state: ApplicationState) => ({
      users: state.admin.users,
      roles: state.admin.roles,
      notifications: state.notifications.notifications
        .filter(n => n.ctx === 'adminusers'),
      loadingNotifications: state.notifications.notifications
        .filter(n => n.ctx === 'adminusers' && n.type === NotificationType.LOADING),
      loading: state.app.loading,
      loadingMessage: state.app.loadingMessage
  }),
  Admin.actionCreators,
)(AdminUsers as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
