import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as User from '../../store/user';
import * as Identity from '../../store/identity';

import { Work } from '../../components/user/Work';

export default connect(
  (state: ApplicationState) => ({
      ...state.identity,
      work: state.user.work,
      user: state.identity?.user,
      moduleManager: state.modules.moduleManager,
      communications: state.user.communications,
  }),
  User.actionCreators,
)(Work as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
