import * as React from 'react';

import {
    BreadcrumbItem, useDataTable, useSearch, useTranslation, useDispatcher, usePermissions,
} from '@components';
import { ResourceStatus } from '@components/common/ResourceStatus';
import {
    IJob, ISecurity, IWork, IWorker, ResourceType,
    ModuleManager,
} from '@models';
import { search } from '@store/actions/search';

type WorkerHasJob = {
    name: string;
    jobHasWorkerId: number;
    jobName: string;
    jobCode: string;
    resourceStatusType: number;
    resourceStatusName: string;
    workId: number;
    jobId: number;
    workspaceName?: string;
    contractorName?: string;
}

export interface IProps {
    security: ISecurity;
    work: IWork;
    job: IJob;
    getContractorFromAppUser: Function;
    moduleManager: ModuleManager;
}

enum FormType {
    None = 0,
    ListWorkers = 1,
    ShowJobs = 2,
}

const renderResourceStatus = (w: WorkerHasJob) => {
    return <ResourceStatus
        currentStatusType={w.resourceStatusType}
        resourceId={w.jobHasWorkerId}
        resourceType={ResourceType.JobHasWorker}
        workId={w.workId} />
}

export function Workers(props: IProps) {
    const { t } = useTranslation();

    const [formType, setFormType] = React.useState<FormType>(FormType.None);

    const searchWorkers = useSearch<IWorker>({
        search: 'controlAccess/workers',
        lazy: true,
        normalizeKeys: true,
        workId: props.work.id,
    });

    const searchJobs = useSearch<WorkerHasJob>({
        search: 'worker_has_jobs',
        lazy: true,
        normalizeKeys: true,
        workId: props.work.id,
        filters: {
            workId: props.work.id,
        }
    });

    const myJobs = useSearch<IJob>({
        search: 'controlAccess/own.jobs',
        lazy: false,
        normalizeKeys: true,
        workId: props.work.id,
    });

    const [worker, setWorker] = React.useState<IWorker|undefined>();

    // parametros de busqueda
    const [code, setCode] = React.useState<string>('');
    const [name, setName] = React.useState<string>('');

    React.useEffect(() => {
        setFormType(worker != undefined ? FormType.ShowJobs : FormType.ListWorkers);
        if (worker != undefined) {
            const filters = {
                workerId: worker.id,
                RequireContractor: worker.contractorId
            };
            searchJobs.doSearch(filters);
        }
    }, [worker]);

    React.useEffect(() => {
        setFormType(FormType.ListWorkers);
    }, [searchWorkers.value]);

    const workersTable = useDataTable<IWorker>({
        data: searchWorkers.value ?? [],
        columns: [
            'name', 'surname',
            {
                title: t('accessControl.worker.code'),
                render: w => w.code,
            },
            'contractorName'
        ],
        actions: [
            {text: t('Select'), className: 'alt slim', headerClassName: 'td-md', onClick: setWorker}
        ]
    });

    const jobsTable = useDataTable<WorkerHasJob>({
        data: searchJobs.value ?? [],
        columns: [
            {
                title: t('Contractor'),
                render: j => j.contractorName,
                sortKey: 'contractorName',
            },
            {
                title: t('Job'),
                render: j => j.jobName,
                sortKey: 'jobName',
            },
            {
                title: t('JobNumber'),
                render: j => j.jobCode,
                sortKey: 'jobCode',
                className: 'center'
            },
            searchJobs.value[0]?.workspaceName && {
                title: t('Job.WorkspaceId'),
                render: j => j.workspaceName,
                sortKey: 'workspaceName',
                className: 'center'
            },
            {
                title: t('accessControl.worker.state'),
                className: 'td-md',
                render: renderResourceStatus,
                sortKey: 'resourceStatusType',
            }
        ]
    });

    const permissions = usePermissions(props, {
        debug: false,
    }, {
        all: 'accessControl.all',
    });

    const doSearch = async (data: any) => {
        const filters: any = {
            workId: props.work.id,
            name: data.name,
            code: data.code,
        };

        if (props.security.isGestorOrWorker()
            && !permissions.get('all')) {
            const jobIds = myJobs.value.map(j => j.id);
            filters['jobId'] = jobIds;
        }

        if (filters.name?.length > 0
            || filters.code?.length > 0) {
            searchWorkers.doSearch(filters);
        }
        else {
            searchWorkers.clear();
        }
    }

    const dispatcher = useDispatcher({
        dispatch: doSearch,
        handlers: {
            code: setCode,
            name: setName,
        }
    });

    return <div className={'c'}>
        <BreadcrumbItem text={t('Workers')} />

        <div className={'r md pd center v-center g-20'}>
            <label className={'light'}>{t('DNI')}</label>
            <input type={'text'}
                   className={'br mw300x'}
                   value={code}
                   onChange={dispatcher.onEvent('code')}
                   placeholder={t('1 digit at least')} />
            <label className={'light'}>{t('Name')}</label>
            <input type={'text'}
                   className={'br wm-300 mw300x'}
                   value={name}
                   onChange={dispatcher.onEvent('name')}
                   placeholder={t('1 character at least')} />
        </div>

        {searchWorkers.renderLoading()}

        {formType === FormType.ListWorkers && <div>
            {workersTable()}
        </div>}

        {formType === FormType.ShowJobs && <div>
            <div className={'blue box rounded lg mr grid-2 gap'}>
                <label>{t('DNI')}</label>
                <strong>{worker?.code}</strong>

                <label>{t('Name')}</label>
                <strong>{`${worker?.name} ${worker?.surname}`}</strong>
            </div>

            {searchJobs.renderLoading()}

            {jobsTable()}
        </div>}
    </div>;
}