import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '@store';
import * as JobsStore from '@store/jobs';
import { SelectJobHasMachinery } from '@components/machineries/SelectJobHasMachinery';
import { hasPermission } from '@utils/security';
import { isMachineryPropertyGroupType } from '@models';
import * as U from '@utils';

export default connect(
    (state: ApplicationState, props: any) => ({
        ...JobsStore.plainActions,
        ...state.identity,
        ...state.user,
        ...state.jobs,
        propertyGroupTypes: state.user.propertyGroupTypes.filter(isMachineryPropertyGroupType),
        hasPermission: (p: string, explicit: boolean | undefined = undefined) => hasPermission(p, {
            user: state.identity?.user,
            work: state.user.work,
            job: state.jobs.selectedJob,
            explicit: explicit ?? true,
        }),
        moduleManager: state.modules.moduleManager,
        resourceStatusTypes: state.user.resourceStatusTypes,
        contractorId: props.contractorId,
        jobHasContractorId: props.jobHasContractorId ?? state.jobs.selectedJobHasContractor?.id ?? U.getQueryParameter(window.location.search, 'jobContractor', { map: parseInt })
    }),
    JobsStore.actionCreators,
)(SelectJobHasMachinery as any) as any;
