import * as React from 'react';

import * as UserActions from '@store/actions/user';

import {
    RequireRole, SplitButton, Tag, useConfirm, useDataTable, useDialogs,
    useLoading,
    usePermissions,
    useResolveName, useSearch, useTranslation,
    useWorkFormSettings,
} from '@components';
import {
    goToJob,
    goToJobAddContractor,
    goToJobContractors,
    goToJobDocumentation,
    goToJobSubContractors,
    goToJobEditContractorRequirements, goToJobEditMachineryRequirements,
    goToJobEditWorkerRequirements, goToJobInformationLoadedControl,
    goToJobMachineries,
    goToJobWorkers
} from '@components/routes';
import {
    IActivityType, IJob, ISecurity, IWorkspace,
    ResourceType, IWork, IJobHasContractor, ModuleManager,
} from '@models';
import DateUtils from '@utils/date-utils';
import { ResourceStatus } from '@components/common/ResourceStatus';
import { redirectTo } from '@utils';
import './JobCard.scss';
import { IContractor, IMachineryType, IWorkerDocumentType, IWorkerType } from '@models/resources';
import {
    getJobHasContractorFromAppUser, getJobHasContractorFromId, getJobHasContractors
} from '@store/actions/contractors';
import JobStatus from '@containers/jobs/JobStatusContainer';
import { getJobHasContractor } from '@store/actions/jobs';

type IJobAdditional = {
    jobHasContractorId?: number;
    jobHasContractorCurrentStatusType?: number;
    jobStatusTypeId?: number;
    shipmentNumber?: string;
    departmentName?: string;
    responsibleName?: string;
    contractorsTotal?: number;
    contractorsValidated?: number;
    machineriesTotal?: number;
    machineriesValidated?: number;
    workersTotal?: number;
    workersValidated?: number;
    depTotal?: number;
    depValidated?: number;
    depReqTotal?: number;
    depReqValidated?: number;
    statusId?: number;

    requirementsTotalCount?: number;
    requirementsValidatedCount?: number;
}

type jobHasWorkerC = {
    id: number;
    name: string;
    surname?: string;
    code: string;
    jobId: number;
    currentStatusType?: number;
    workerDocumentTypeId: number;
    workerTypeId?: number;
    contractorName: string;
    totalCount?: number;
    validatedCount?: number;
}

type jobHasMachineryC = {
    id: number;
    name: string;
    code: string;
    jobId: number;
    currentStatusType: number;
    machineryTypeId: number;
    licensePlate?: string;
    contractorName?: string;
    totalCount?: number;
    validatedCount?: number;
}

type jobHasContractorC = {
    id: number;
    name: string;
    code: string;
    jobId: number;
    jobHasContractorId: number;
    jobsDescription?: string;
    startDate?: Date;
    currentStatusType: number;
    legalFormName: string;
    acitivityName: string;

    workersTotalCount?: number;
    workersValidatedCount?: number;

    machineriesTotalCount?: number;
    machineriesValidatedCount?: number;

    subcontractorsTotalCount?: number;
    subcontractorsValidatedCount?: number;

    requirementsTotalCount?: number;
    requirementsValidatedCount?: number;
}

export interface IProps {
    job: IJob & IJobAdditional;
    work: IWork;
    security: ISecurity;
    workspaces: IWorkspace[];
    activityTypes: IActivityType[];
    workerDocumentTypes: IWorkerDocumentType[];
    workerTypes: IWorkerType[];
    machineryTypes: IMachineryType[];
    reload: Function;
    selectedContractor?: IContractor;
    moduleManager: ModuleManager;
    currentJobHasContractorId?: number;
}

function Indicator({ title, count, total, colorize, hideCount }: { title: string, count?: number, total: number, colorize?: boolean, hideCount?: boolean }) {
    const { t } = useTranslation();

    const className = colorize && count != undefined && count < total
        ? 'error'
        : '';

    return <div className={'indicator'}>
        <label>{t(title)}</label>
        <Tag className={className} value={!hideCount ? `${count}/${total}` : total} />
    </div>
}

type JobDepartmentR = {
    id: number;
    departmentName: string;
    responsibleName: any;
    statusTypeId: number;
    isMain: boolean;
}

function JobDepartments({ job, work }: Partial<IProps>) {
    const { t } = useTranslation();
    const departmentSearchs = useSearch<JobDepartmentR>({
        search: 'jobList/departmentIndicatorsList',
        workId: job!.workId,
        filters: {
            jobId: job!.id
        }
    });

    const departmentsTable = useDataTable<JobDepartmentR>({
        className: 'confortable',
        columns: [
            { title: 'Department', field: 'departmentName', className: 'td-md' },
            { title: 'Name', field: 'responsibleName', className: 'td-md' },
            { className: 'td-sm', render: (d) => {
                if (d.isMain) {
                    return <i className="pi pi-circle-on" title={t('Main department')} />
                }
            }}
            // { field: 'currentStatusType', render: renderStatus, className:'td-md' },
        ],
        data: departmentSearchs.value ?? []
    });

    return <div style={{ width: '40vw !important', padding: '6px' }}>
        {departmentsTable()}
    </div>
}

function RemoveJob({ job, work, reload }: IProps) {
    const { t } = useTranslation();

    const loading = useLoading();

    const doRemoveWorker = loading.wrap(async (i: IJob) => {
        await UserActions.removeJob(job.id, work.id);
        reload();
    });

    const confirmRemove = useConfirm({
        message: t('Are you sure you want to remove this job?'),
        accept: doRemoveWorker
    });

    return <div className='pointer delete-mr' onClick={confirmRemove}>
        <i className='fas fa-trash' />
        <span className='delete-ml'>{t('Delete')}</span>
    </div>
}

function JobWorkers({ job, workerDocumentTypes, work, workerTypes }: IProps) {
    // WORKERS
    const resolveWorkerDocumentType = useResolveName(workerDocumentTypes, { translate: true });
    const resolveWorkerType = useResolveName(workerTypes, { translate: true });
    const renderWorkerDocumentType = (w: jobHasWorkerC) => {
        return <div>
            <span>{resolveWorkerDocumentType(w.workerDocumentTypeId)}</span>
        </div>
    }
    const renderWorkerType = (w: jobHasWorkerC) => {
        return <div>
            <span>{resolveWorkerType(w.workerTypeId)}</span>
        </div>
    }

    const renderWorkerStatus = (w: jobHasWorkerC) =>
        <ResourceStatus
            currentStatusType={w.currentStatusType!}
            resourceId={w.id}
            resourceType={ResourceType.JobHasWorker}
            workId={job.workId} />;

    const renderContractor = (w: jobHasWorkerC) => {
        return <Tag value={w.contractorName} />
    }

    const workersSearch = useSearch<jobHasWorkerC>({
        search: 'job_has_workers.list',
        normalizeKeys: true,
        workId: job.workId,
        filters: {
            jobId: job.id
        }
    });

    React.useEffect(() => {
        workersSearch.doSearch();
    }, []);

    const workersTable = useDataTable<jobHasWorkerC>({
        columns: [
            { title: 'Name', field: 'name', className: 'td-md' },
            { title: 'Surname', field: 'surname', className: 'td-md' },
            { title: 'Worker type', render: renderWorkerType, className: 'td-lg' },
            { title: 'Worker document type', render: renderWorkerDocumentType, className: 'td-md' },
            { title: 'Code', field: 'code', className: 'td-md' },
            { title: 'Contractor', render: renderContractor, className: 'td-lg' },
            {
                title: 'Documentation',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobEditWorkerRequirements(job.workId, job.id, d.id)}>
                        <Tag value={`${d.validatedCount ?? 0}/${d.totalCount ?? 0}`} />
                    </div>
            },
            { field: 'currentStatusType', render: renderWorkerStatus, className: 'td-md' }
        ],
        actions: [
            {
                icon: 'fas fa-edit',
                onClick: (r) => goToJobWorkers(work.id, r.jobId)
            }
        ],
        data: workersSearch.value
    });

    return <div>
        {workersTable()}
    </div>
}

function JobMachines({ job, machineryTypes, work }: IProps) {
    // MACHINERIES
    const resolveMachineryType = useResolveName(machineryTypes, { translate: true });
    const renderMachineryType = (m: jobHasMachineryC) => {
        return <div>
            <span>{resolveMachineryType(m.machineryTypeId)}</span>
        </div>
    }

    const renderContractor = (m: jobHasMachineryC) => {
        return <Tag value={m.contractorName} />
    }

    const renderMachineryStatus = (m: jobHasMachineryC) =>
        <ResourceStatus
            currentStatusType={m.currentStatusType}
            resourceId={m.id}
            resourceType={ResourceType.JobHasMachinery}
            workId={job.workId} />;

    const machineriesSearch = useSearch<jobHasMachineryC>({
        search: 'job_has_machineries.list',
        normalizeKeys: true,
        workId: job.workId,
        filters: {
            jobId: job.id
        }
    });

    React.useEffect(() => {
        machineriesSearch.doSearch();
    }, []);

    const machineriesTable = useDataTable<jobHasMachineryC>({
        columns: [
            { title: 'Name', field: 'name', className: 'td-lg' },
            { title: 'Code', field: 'code', className: 'td-md' },
            { title: 'Machinery type', render: renderMachineryType, className: 'td-md' },
            { title: 'License plate', field: 'licensePlate', className: 'td-md' },
            { title: 'Contractor', render: renderContractor, className: 'td-lg' },
            {
                title: 'Documentation',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobEditMachineryRequirements(job.workId, job.id, d.id)}>
                        <Tag value={`${d.validatedCount ?? 0}/${d.totalCount ?? 0}`} />
                    </div>
            },
            { title: 'Status', render: renderMachineryStatus, className: 'td-md' }
        ],
        actions: [
            {
                icon: 'fas fa-edit',
                onClick: (r) => goToJobMachineries(work.id, r.jobId)
            }
        ],
        data: machineriesSearch.value
    });

    return <div>
        {machineriesTable()}
    </div>
}

function JobContractors({ job, work, security }: IProps) {
    // CONTRACTORS
    const renderContractorStatus = (c: jobHasContractorC) =>
        <ResourceStatus
            currentStatusType={c.currentStatusType}
            resourceId={c.id}
            resourceType={ResourceType.JobHasContractor}
            workId={job.workId} />;

    const contractorsSearch = useSearch<jobHasContractorC>({
        search: 'job_has_contractors.list',
        normalizeKeys: true,
        workId: job.workId,
        filters: {
            jobId: job.id
        }
    });

    React.useEffect(() => {
        contractorsSearch.doSearch();
    }, []);

    const contractorsTable = useDataTable<jobHasContractorC>({
        columns: [
            { title: 'Name', field: 'name', className: 'td-md' },
            { title: 'StartDate', field: 'startDate', delegate: 'date', className: 'td-md center' },
            { title: 'JobsDescription', field: 'jobsDescription' },
            {
                title: 'Documentation',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobEditContractorRequirements(job.workId, job.id, d.jobHasContractorId)}>
                        <Tag value={`${d.requirementsValidatedCount ?? 0}/${d.requirementsTotalCount ?? 0}`} />
                    </div>
            },
            {
                title: 'Workers',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobWorkers(job.workId, job.id)}>
                        <Tag value={`${d.workersValidatedCount ?? 0}/${d.workersTotalCount ?? 0}`} />
                    </div>
            },
            {
                title: 'Machineries',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobMachineries(job.workId, job.id)}>
                        <Tag value={`${d.machineriesValidatedCount ?? 0}/${d.machineriesTotalCount ?? 0}`} />
                    </div>
            },
            {
                title: 'Subcontractors',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobContractors(job.workId, job.id)}>
                        <Tag value={`${d.subcontractorsValidatedCount ?? 0}/${d.subcontractorsTotalCount ?? 0}`} />
                    </div>
            },
            { title: 'Status', render: renderContractorStatus, className: 'td-md' }
        ],
        actions: [
            {
                icon: 'fas fa-edit',
                disabled: security.isContractor(),
                onClick: (r) => goToJobContractors(work.id, r.jobId),
            }
        ],
        data: contractorsSearch.value
    });

    return <div>
        {contractorsTable()}
    </div>
}

function JobSubContractors({ job, work, security }: IProps) {
    // CONTRACTORS
    const renderContractorStatus = (c: jobHasContractorC) =>
        <ResourceStatus
            currentStatusType={c.currentStatusType}
            resourceId={c.id}
            resourceType={ResourceType.JobHasContractor}
            workId={job.workId} />;

    const [parentId, setParentId] = React.useState<number>(0);

    React.useEffect(() => {
        getJobHasContractorFromAppUser(job.workId, job.id)
            .then((u: IJobHasContractor) => setParentId(u.id));
    }, []);

    React.useEffect(() => {
        if (parentId) {
            contractorsSearch.doSearch({
                jobId: job.id,
                parentId: parentId
            });
        }
    }, [parentId]);

    const contractorsSearch = useSearch<jobHasContractorC>({
        search: 'job_has_contractors.list',
        normalizeKeys: true,
        workId: job.workId,
        filters: {
            jobId: job.id,
            parentId: parentId
        },
        lazy: true,
    });

    React.useEffect(() => {
        contractorsSearch.doSearch();
    }, []);

    const contractorsTable = useDataTable<jobHasContractorC>({
        columns: [
            { title: 'Name', field: 'name', className: 'td-md' },
            { title: 'StartDate', field: 'startDate', delegate: 'date', className: 'td-md center' },
            { title: 'JobsDescription', field: 'jobsDescription' },
            {
                title: 'Documentation',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobEditContractorRequirements(job.workId, job.id, d.jobHasContractorId)}>
                        <Tag value={`${d.requirementsValidatedCount ?? 0}/${d.requirementsTotalCount ?? 0}`} />
                    </div>
            },
            {
                title: 'Workers',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobWorkers(job.workId, job.id)}>
                        <Tag value={`${d.workersValidatedCount ?? 0}/${d.workersTotalCount ?? 0}`} />
                    </div>
            },
            {
                title: 'Machineries',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobMachineries(job.workId, job.id)}>
                        <Tag value={`${d.machineriesValidatedCount ?? 0}/${d.machineriesTotalCount ?? 0}`} />
                    </div>
            },
            {
                title: 'Subcontractors',
                className: 'td-md center',
                render: d =>
                    <div className='pointer' onClick={() => goToJobContractors(job.workId, job.id)}>
                        <Tag value={`${d.subcontractorsValidatedCount ?? 0}/${d.subcontractorsTotalCount ?? 0}`} />
                    </div>
            },
            { title: 'Status', render: renderContractorStatus, className: 'td-md' }
        ],
        actions: [
            {
                icon: 'fas fa-edit',
                onClick: (r) => goToJobContractors(work.id, r.jobId),
            }
        ],
        data: contractorsSearch.value
    });

    return <div>
        {contractorsTable()}
    </div>
}

export function JobCard({
    job, security, workspaces, activityTypes, work,
    currentJobHasContractorId,
    workerDocumentTypes, workerTypes, machineryTypes, reload,
    selectedContractor, moduleManager,
}: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const loading = useLoading();
    const [canAddSubContractorState, setCanAddSubContractorState] = React.useState<boolean>(true);

    const jobSettings = React.useMemo(() => work.jobSettings, [work]);

    const doRemoveJob = loading.wrap(async (i: IJob) => {
        await UserActions.removeJob(job.id, work.id);
        reload();
    });
    const confirmRemoveJob = useConfirm({
        message: t('Are you sure you want to remove this job?'),
        accept: doRemoveJob,
    });

    const canRemove = security.isGestor() || security.hasPermission('jobs.forceStatus');
    const perms = usePermissions({ moduleManager, work, security }, { ctx: { job } }, {
        remove: {
            name: 'job.remove',
            default: canRemove,
        },
    });

    const [isSubContractor, setIsSubContractor] = React.useState<boolean>(false);
    const [currentEmployer, setCurrentEmployer] = React.useState<string>('');

    const initialize = async () => {
        if (security.isContractor()) {
            if (job.jobHasContractorId) {
                const currentJobHasContractor = await getJobHasContractorFromId(job.workId, job.jobHasContractorId);
                setIsSubContractor(currentJobHasContractor.parentId ? true : false);
                if (currentJobHasContractor.parentId) {
                    const currentEmployer: any = await getJobHasContractorFromId(job.workId, currentJobHasContractor.parentId);
                    setCurrentEmployer(currentEmployer.contractorName);
                }
            }
        }
    }

    React.useEffect(() => {
        const ctx = {
            job: job,
            security: security,
            getJobHasContractorFromId: getJobHasContractorFromId,
            getJobHasContractors: getJobHasContractors,
            selfContractorId: job.jobHasContractorId,
            setCanAddSubContractorState: setCanAddSubContractorState,
        };

        moduleManager
            .hasPermission('canAddSubContractor', ctx)
            .then(setCanAddSubContractorState);
    }, []);

    React.useEffect(() => {
        initialize();
    }, [job]);

    const goOpts = { jobHasContractorId: currentJobHasContractorId };

    const settings = useWorkFormSettings({ work, scope: 'job' });

    const resolveWorkspace = useResolveName(workspaces);
    const resolveActivity = useResolveName(activityTypes);

    const addSubContractor = async () => {
        const currentJobHasContractor = await getJobHasContractorFromAppUser(job.workId, job.id);
        goToJobAddContractor(job.workId, job.id, currentJobHasContractor.id);
    }

    const goToSubContractors = async () => {
        const currentJobHasContractor = await getJobHasContractor(job.workId, job.id, currentJobHasContractorId ?? 0);
        const opts = { ...goOpts, parentId: currentJobHasContractor?.id };
        goToJobSubContractors(job.workId, job.id, opts);
    }

    const [actions, setActions] = React.useState<any[]>([]);

    const actionsI = [
        {
            label: t((work.id == 23) ? 'job.view.azsa' : 'job.view'),
            icon: 'fas fa-wrench',
            command: (e: any) => goToJob(job.workId, job.id, goOpts)
        }
    ];

    if (security.isWorker() || security.isGestor()) {
        actionsI.push({
            label: t((work.id == 23 && security.isWorker()) ? 'job.documentation.azsa' : 'job.documentation'),
            icon: 'fas fa-folder',
            command: () => goToJobDocumentation(job.workId, job.id, goOpts)
        });
        actionsI.push({
            label: t('job.contractors'),
            icon: 'fas fa-id-card-alt',
            command: () => goToJobContractors(job.workId, job.id)
        });
        actionsI.push({
            label: t('job.workers'),
            icon: 'fas fa-user-friends',
            command: () => goToJobWorkers(job.workId, job.id, goOpts)
        });
        actionsI.push({
            label: t('job.machineries'),
            icon: 'fas fa-truck-loading',
            command: () => goToJobMachineries(job.workId, job.id, goOpts)
        });
        actionsI.push({
            label: t('job.downloads'),
            icon: 'fas fa-download',
            command: () => goToJobInformationLoadedControl(job.workId, job.id),
        })
    }
    else if (security.isContractor()) {
        actionsI.push({
            label: t('job.documentation'),
            icon: 'fas fa-folder',
            command: () => goToJobDocumentation(job.workId, job.id, goOpts)
        });
        actionsI.push({
            label: t('job.contractors'),
            icon: 'fas fa-id-card-alt',
            command: () => goToJobContractors(job.workId, job.id)
        });
        actionsI.push({
            label: t('job.workers'),
            icon: 'fas fa-user-friends',
            command: () => goToJobWorkers(job.workId, job.id, goOpts)
        });
        actionsI.push({
            label: t('job.machineries'),
            icon: 'fas fa-truck-loading',
            command: () => goToJobMachineries(job.workId, job.id, goOpts)
        });
        if (canAddSubContractorState) {
            actionsI.push({
                label: t('job.contractors.add-subcontractor'),
                icon: 'fas fa-plus',
                command: addSubContractor
            });
        }
    }

    if (security.isGestorOrWorker() && perms.get('remove')) {
        actionsI.push({
            label: t('Delete'),
            icon: 'fas fa-trash',
            command: (e: any) => confirmRemoveJob(e),
        });
    }

    //TODO llevar al modulo
    const evaluations = async () => {
        const ctx = {
            roles: security.user?.roles ?? [],
            userDepartments: security.user?.departments ?? [],
            job: job,
            security: security,
        };

        // const can = await moduleManager.hasPermission('canViewEvaluations', ctx);
        const can = security.isGestor()

        if (work.id == 23 && can) {
            actionsI.push({
                label: t('PessmaEvaluations'),
                icon: 'fas fa-calendar-check',
                command: () => redirectTo(`/modules/${work.id}/AzsaApi/PessmaEvaluations?id=${job.id}`),
            });
        }
        //* Los contratistas no pueden ver las evaluaciones pendientes
        if (work.id == 23 && can && !security.isContractor()) {
            actionsI.push({
                label: t('Pending evaluations PESSMA'),
                icon: 'fas fa-calendar',
                command: () => redirectTo(`/modules/${work.id}/AzsaApi/PessmaPendingEvaluations?id=${job.id}`),
            })
        }

        setActions(actionsI);
    }

    React.useEffect(() => {
        evaluations()
    }, [security, moduleManager.getId(), perms.id]);

    return <div className={'JobCard'}>
        <div className={'header'}>
            <span className={'job-code'}>{job.code}</span>
            <span className={'e'} />
            <div className='pointer delete-mr status-mr'>
                <JobStatus
                    job={job}
                    jobStatus={{
                        id: job.currentStatus?.id ?? job.statusId,
                        jobId: job.id,
                        jobStatusTypeId: job.currentStatus?.jobStatusTypeId ?? job.jobStatusTypeId,
                        isCurrent: true,
                    }}
                    jobSettings={jobSettings}
                    workId={job.workId} />
            </div>
            {loading.render()}
            <SplitButton menuClassName={'mw300x'} model={actions} />
        </div>
        <div className={'status'}>
            <span className={'e'} />
            {security.isContractor() &&
                <div className='pointer delete-mr'>
                    <ResourceStatus
                        currentStatusType={job.jobHasContractorCurrentStatusType!}
                        resourceId={job.jobHasContractorId!}
                        resourceType={ResourceType.JobHasContractor}
                        workId={job.workId}
                        showResourceType={work.id === 23} />
                </div>
            }
        </div>
        <div className={'data'}>
            <label>{t((work.id == 23) ? 'Job azsa' : 'Job')}</label>
            <span className={'value'}>
                {job.name}
            </span>

            <label>{t('Workspace')}</label>
            <span className={'value'}>
                {resolveWorkspace(job.workspaceId)}
            </span>

            {settings.show('activityTypeId') &&
                <>
                    <label>{t('Activity type')}</label>
                    <span className={'value'}>
                        {resolveActivity(job.activityTypeId)}
                    </span>
                </>}

            <label>{t((work.id == 23) ? 'RSO azsa' : 'RSO')}</label>
            <span className='value'>{job.responsibleName}</span>

            <label>{t('StartDate')}</label>
            <span className={'value'}>{DateUtils.format(job.startDate)}</span>

            <label>{t('EndDate')}</label>
            <span className={'value'}>{DateUtils.format(job.endDate)}</span>

            {(security.isContractor() && !isSubContractor) && <>
                <label>{t('job.shipment.number')}</label>
                <span className={'value'}>{job.shipmentNumber}</span>
            </>}

            {(security.isContractor() && isSubContractor) && <>
                <label>{t('Dependent company')}</label>
                <span className={'value'}>{currentEmployer}</span>
            </>}
        </div>

        <div className={'indicators'}>
            <RequireRole role='contractor'>
                <div className='pointer'
                    onClick={(_) => goToJobEditContractorRequirements(work.id, job.id, job.jobHasContractorId!)}>
                    <Indicator
                        title={'job.indicators.self-documentation'}
                        total={job.requirementsTotalCount ?? 0}
                        count={job.requirementsValidatedCount ?? 0} />
                </div>
            </RequireRole>

            <RequireRole oneOf={['worker', 'gestor']}>
                <div className='pointer'
                    // onClick={r => dialogs.show('contractors-dialog', r)}>
                    onClick={r => goToJobContractors(work.id, job.id, true)}>
                    <Indicator title={'Contractors'}
                        colorize={jobSettings?.colorizeContractorIndicators ?? false}
                        total={job.contractorsTotal!}
                        count={job.contractorsValidated!} />
                </div>
            </RequireRole>

            <div className='pointer'
                onClick={(_) => goToJobDocumentation(work.id, job.id, goOpts)}>
                <Indicator
                    title={'job.indicators.documentation'}
                    total={job.depReqTotal ?? 0}
                    count={job.depReqValidated ?? 0} />
            </div>

            <div className='pointer'
                // onClick={r => dialogs.show('workers-dialog', r)}>
                onClick={r => goToJobWorkers(work.id, job.id, goOpts)}>
                <Indicator title={'Workers'}
                    total={job.workersTotal ?? 0}
                    count={job.workersValidated ?? 0} />
            </div>

            <RequireRole role='contractor'>
                <div className='pointer'
                    // onClick={(_) => dialogs.show('subcontractors')}>
                    onClick={goToSubContractors}>
                    {/* TODO: QUITAR CLAVE */}
                    <Indicator title={(work.id == 23 && security.isContractor()) ? 'Subcontractors azsa' : 'Subcontractors'}
                        total={job.contractorsTotal ?? 0}
                        count={job.contractorsValidated ?? 0} />
                </div>
            </RequireRole>
            <RequireRole role={'contractor'}>
                <div className='pointer'
                    // onClick={r => dialogs.show('machineries-dialog', r)}>
                    onClick={r => goToJobMachineries(work.id, job.id, goOpts)}>
                    <Indicator title={'Machineries'}
                        total={job.machineriesTotal ?? 0}
                        count={job.machineriesValidated ?? 0} />
                </div>
                <div className='pointer'
                    // onClick={(_) => goToJobDocumentation(work.id, job.id)}>
                    onClick={() => dialogs.show('departments')}>
                    <Indicator
                        title={'Departments'}
                        total={job.depTotal ?? 0}
                        count={job.depValidated ?? 0}
                        hideCount={true} />
                </div>
            </RequireRole>
            <RequireRole oneOf={['worker', 'gestor']}>
                <div className='pointer'
                    // onClick={(_) => goToJobDocumentation(work.id, job.id)}>
                    onClick={() => dialogs.show('departments')}>
                    <Indicator
                        title={'Departments'}
                        total={job.depTotal ?? 0}
                        hideCount={true}
                    />
                </div>
                <div className='pointer'
                    // onClick={r => dialogs.show('machineries-dialog', r)}>
                    onClick={r => goToJobMachineries(work.id, job.id, goOpts)}>
                    <Indicator title={'Machineries'}
                        total={job.machineriesTotal ?? 0}
                        count={job.machineriesValidated ?? 0} />
                </div>
            </RequireRole>
        </div>

        {/** DEPARTMENTS */}
        {dialogs.render('departments', { title: t('Departments') + ': ' + job.code, className: 'pd g', style: { width: '40vw' } }, (_: any) =>
            <JobDepartments job={job} work={work} />)}
    </div>
}
