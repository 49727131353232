import * as React from 'react';

import {
    G, Select, useForm, useLoading, useTranslation,
    ValidationBuilder,
} from '@components';
import {
    IActivityType, IConstants, IContractorRequest, IJob, ILegalForm, IPropertyGroup, IPropertyGroupType, IWork, PropertyGroupObjectType
} from '@models';
import { DynamicProperties } from '../user/DynamicProperties';
import { CountryRegionSelect } from '../common/CountryRegionSelect';

export interface IProps {
    activityTypes: IActivityType[];
    constants: IConstants;
    createContractorRequest: Function;
    data: IContractorRequest;
    job: IJob;
    legalForms: ILegalForm[];
    onCancel: Function;
    onSuccess: Function;
    propertyGroupTypes: IPropertyGroupType[];
    work: IWork;
}

export const validateContractorRequest =
    ValidationBuilder.new()
        .notEmpty('contractorName')
        .notEmpty('contractorCode')
        .notEmpty('startDate')
        .notEmpty('legalFormId')
        .lift();

enum Result {
    ACCEPT = 1,
    REJECT = 2,
}

const exclude = (obj: any, props: string[]) => {
    const res: any = {};
    for (const k of Object.keys(obj)) {
        if (!props.includes(k) && !k.startsWith('__')) {
            res[k] = obj[k];
        }
    }

    return res;
}

export function ProcessContractorRequest(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading(false);

    const saveContractor = loading.wrap(async (req: IContractorRequest) => {
        const resp = await props.createContractorRequest(props.work.id, req);

        if (resp.hasValue) {
            props.onSuccess('Contractor processed successfully');
            return { ok: true };
        }
        else {
            return { ok: false, message: resp.error };
        }
    });

    const form = useForm<IContractorRequest>({
        initialValues: props.data,
        validate: validateContractorRequest,
        onSubmit: (data: IContractorRequest) => {
            if (result == Result.ACCEPT) {
                data.accepted = true;
                data.acceptedDate = new Date();
            }
            else {
                data.accepted = false;
                data.discarded = true;
                data.discardedDate = new Date();
            }
            return saveContractor(exclude(data, ['appUserId']));
        },
    });

    const [result, setResult] = React.useState<Result|undefined>();
    const resultOptions = [
        {value: Result.ACCEPT, label: t('Create contractor')},
        {value: Result.REJECT, label: t('Reject')}
    ];

    const hasError = form.hasError() || result == undefined;

    const updateRegion = ({countryId, regionId, countyId}: any) => {
        form.setFieldValues({countryId, regionId, countyId});
    }

    return <form
            className='form-1 l200 lg pd'
            onSubmit={form.handleSubmit}>
        <div className='r'>
            <div className='form-1 l200'>
                <G label={t('Result')}>
                    <Select
                        onChange={(e: any) => setResult(e.target.value)}
                        options={resultOptions}
                        optionLabel='label'
                        optionValue='value'
                        placeholder={t('Request result')}
                        value={result} />
                </G>
                {result == Result.REJECT && <G label={t('Denial reason')}>
                    {form.textarea('resultMessage')}
                </G>}
                <G label={t('Contractor social id')}>
                    {form.input('contractorName', {autoFocus: true})}
                </G>
                <G label={t('Code')}>
                    {form.input('contractorCode')}
                </G>
                <G label={t('Legal form')}>
                    {form.select('legalFormId', {
                        options: props.legalForms,
                    })}
                </G>
                <G label={t('Activity type')}>
                    {form.select('activityTypeId', {
                        options: props.activityTypes,
                    })}
                </G>
                <div className='r g-20 cols-2'>
                    <div className='fields-box e'>
                        <div className='title'>{t('Address')}</div>
                        <div className='c md g-10 pd'>
                            <G label={t('Address')}>
                                {form.input('address')}
                            </G>
                            <G label={t('Postal code')}>
                                {form.input('postalCode')}
                            </G>
                            <G label={t('Region')}>
                                <CountryRegionSelect
                                    countries={props.constants.countries}
                                    data={form.values}
                                    onChange={updateRegion} />
                            </G>
                        </div>
                    </div>
                    <div className='fields-box'>
                        <div className='title'>{t('Contact data')}</div>
                        <div className='c sm g-10 pd'>
                            <G label={t('Name')}>
                                {form.input('contactName')}
                            </G>
                            <G label={t('Surname')}>
                                {form.input('contactSurname')}
                            </G>
                            <G label={t('Phone')}>
                                {form.input('contactPhone')}
                            </G>
                            <G label={t('Email')}>
                                {form.input('contactEmail')}
                            </G>
                        </div>
                    </div>
                    <div className='fields-box'>
                        <div className='title'>{t("Job data")}</div>
                        <div className='c sm g-10 pd'>
                            <G label={t('Entry date')}>
                                {form.input('startDate', { type: 'date'})}
                            </G>
                            <G label={t('Description')}>
                                {form.textarea('jobsDescription')}
                            </G>
                        </div>
                    </div>
                </div>
            </div>

            <DynamicProperties
                object={form.values}
                objectType={PropertyGroupObjectType.JobHasContractorRequest}
                propertyGroupTypes={props.propertyGroupTypes}
                onChange={(p: IPropertyGroup[]) => form.setFieldValue('properties', p)} />
        </div>
        <div className='errors-container'>
            {form.errorBox()}
        </div>
        <div className='cols-2 r'>
            <span className='e' />
            <div className='p-buttonset'>
                <button
                    onClick={() => props.onCancel()}>
                    {t('Cancel')}
                </button>
                <button
                    disabled={loading.isLoading() || hasError}
                    type='submit'
                    className='primary'>
                    {t('Create')}
                </button>
            </div>
        </div>
    </form>
}