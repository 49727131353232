import * as React from 'react';

import { Tree } from 'primereact/tree';
import TreeNode from "primereact/treenode/treenode";
import { Toast } from "primereact/toast";

import {
    useDialogs, useLoading, useMessage, useRemoteData, useTranslation, SplitButton,
    IPermissions,
    usePermissions,
    classNames,
    Tag,
    useTreeTable,
} from '@components';
import { ModuleManager, IOption, ISecurity, IWork } from '@models';
import { documentIcons, DocumentKind, IDocument, IFolder, } from '@models/documents';
import { downloadFileFromUrl } from '@utils';

import { DocumentLogs } from './DocumentLogs';
import { FolderForm } from './FolderForm';
import { UploadFileForm } from './UploadFileForm';
import { EditDocumentForm } from './EditDocumentForm';
import './ManageDocuments.scss';
import { useConfirm } from '@utils/hooks';
import dateUtils from '@utils/date-utils';

type SaveDocumentT = (workId: number, doc: IDocument) => Promise<IOption<number>>;
type RemoveDocumentT = (workId: number, docId: number) => Promise<IOption<boolean>>;
type RemoveFolderT = (workId: number, folderId: number) => Promise<IOption<boolean>>;
type SaveFolderT = (workId: number, folder: IFolder) => Promise<IOption<number>>;

export interface IProps {
    moduleManager: ModuleManager;
    getDocuments: Function;
    getFolders: Function;
    removeDocument: RemoveDocumentT;
    removeFolder: RemoveFolderT;
    pushTemporalResource: Function;
    saveDocument: SaveDocumentT;
    saveFolder: SaveFolderT;
    security: ISecurity;
    work: IWork;
}

const createFolderNode = (f: IFolder) => {
    return {
        key: `folder_${f.id}`,
        icon: 'pi pi-folder',
        draggable: true,
        droppable: true,
        label: f.name,
        data: f,
        children: [],
        sortOrder: f.sortOrder,
    }
}

const createDocumentNode = (f: IDocument) => {
    return {
        key: `document_${f.id}`,
        icon: iconForDocument(f.name),
        draggable: true,
        droppable: true,
        label: f.title ?? f.name,
        data: f,
        children: [],
        sortOrder: (f.sortOrder ?? 100) * 10000
    }
}

const iconForDocument = (name: string) => {
    const icons = documentIcons;

    const n = name.toLowerCase();
    const k = Object.keys(icons)
        .find(k => n.includes(k)) ?? '*';

    return icons[k];
}

type FolderOrDoc = IFolder | IDocument;

function isDocument(o: any): o is IDocument {
    return o.__typename === 'Document';
}

function isFolder(o: any): o is IFolder {
    return o.__typename === 'Folder';
}

const createDocumentsModel = (folders: IFolder[], documents: IDocument[]) => {
    const nodes: TreeNode[] = [];
    const hierarchy: any = {};

    const stack: FolderOrDoc[] = [...folders, ...documents];
    let it = 0;

    while (stack.length > 0 && it < 100000) {
        const f = stack.pop();

        if (isFolder(f)) {
            if (f && f.parentFolderId === null) {
                const n = createFolderNode(f);
                nodes.push(n);
                hierarchy[f.id!] = n;
            } else if (f && f.parentFolderId && hierarchy[f.parentFolderId!]) {
                const n = createFolderNode(f);
                hierarchy[f.id!] = n;
                hierarchy[f.parentFolderId!].children.push(n);
                hierarchy[f.parentFolderId!].children =
                    hierarchy[f.parentFolderId!]
                        .children
                        .sort((a: any, b: any) => a.sortOrder - b.sortOrder);
            } else if (f) {
                stack.splice(0, 0, f);
            }
        }
        else if (isDocument(f)) {
            if (f.folderId && hierarchy[f.folderId!]) {
                const n = createDocumentNode(f);
                hierarchy[f.folderId!].children.push(n);
                hierarchy[f.folderId!].children =
                    hierarchy[f.folderId!]
                        .children
                        .sort((a: any, b: any) => a.sortOrder - b.sortOrder);
            } else if (f && f.folderId === null) {
                const n = createDocumentNode(f);
                nodes.push(n);
            } else if (f) {
                stack.splice(0, 0, f);
            }
        }

        it++;
    }

    return nodes.sort((a: any, b: any) => a.sortOrder - b.sortOrder);;
}

const nodeIsFolder = (key: string) => {
    return key?.startsWith('folder_') ?? false;
}

const nodeIsDocument = (key: string) => {
    return key?.startsWith('document_') ?? false;
}

const nodeKeyGetId = (key: string) => {
    return parseInt(key.split('_')[1]);
}

type CreateMenuOpts = {
    actions: any;
    security: ISecurity;
    permissions: IPermissions;
}

const createMenu = (t: Function, key: string, opts: CreateMenuOpts) => {
    const actions = opts.actions ?? {};
    const security = opts.security;
    const perms = opts.permissions;
    const isGestor = security.isGestor();

    if (nodeIsFolder(key)) {
        const id = nodeKeyGetId(key);
        return [
            {
                label: t('Add folder'),
                icon: 'pi pi-plus',
                command: () => actions.addFolder(id),
                iF: perms.get('addFolder'),
            },
            {
                label: t('Edit folder'),
                icon: 'pi pi-pencil',
                command: () => actions.editFolder(id),
                iF: perms.get('addFolder'),
            },
            {
                label: t('Upload file'),
                icon: 'pi pi-upload',
                command: () => actions.uploadFile(id),
                iF: perms.get('addFile'),
            },
            {
                label: t('Remove folder'),
                icon: 'pi pi-trash',
                command: () => actions.removeFolder(id),
                iF: perms.get('addFolder'),
            }
        ].filter(f => f.iF == undefined || f.iF == true);
    }
    else if (nodeIsDocument(key)) {
        const id = nodeKeyGetId(key);
        const items = [
            {
                label: t('Download file'),
                icon: 'pi pi-download',
                command: (_: any) => actions.downloadFile(id),
            },
            {
                label: t('document.show-logs'),
                icon: 'pi pi-list',
                command: (_: any) => actions.showDocumentLogs(id),
                iF: perms.get('addFolder'),
            },
            {
                label: t('Edit document'),
                icon: 'pi pi-pencil',
                command: (ev: any) => actions.editDocument(id, ev),
                iF: perms.get('removeFile'),
            },
            {
                label: t('Remove file'),
                icon: 'pi pi-trash',
                command: (ev: any) => actions.removeDocument(id, ev),
                iF: perms.get('removeFile'),
            }
        ];

        return items.filter(f => f.iF == undefined || f.iF == true);
    }
}

type Node = {
    type: 'document' | 'folder',
    label: string,
    data: any,
    icon?: string | undefined,
    id: string,
    parentId: string | undefined,
    code: string | undefined,
    year: number | undefined,
    edition: string | undefined,
    date: string | undefined,
    sortOrder: number | undefined,
};

export function ManageDocuments(props: IProps) {
    const loading = useLoading();
    const messages = useMessage();
    const { t } = useTranslation();

    const cm = React.useRef<any>(null);
    const toast = React.useRef<any>(null);

    const [model, setModel] = React.useState<TreeNode[]>([]);
    const [expandedKeys, setExpandedKeys] = React.useState<any>({});
    const [selectedNode, setSelectedNode] = React.useState<any>();

    const [documents, setDocuments] = React.useState<IDocument[]>();
    const [folders, setFolders] = React.useState<IFolder[]>();

    const [includeDocumentKinds, setIncludeDocumentKinds] = React.useState<number[]>([]);

    const perms = usePermissions(props, {}, {
        includeDocumentKinds: 'includeDocumentKinds'
    });

    const foldersQuery = useRemoteData<IFolder[]>(
        props.getFolders,
        { parameters: [props.work.id, includeDocumentKinds] });


    const documentsQuery = useRemoteData<IDocument[]>(
        props.getDocuments,
        { parameters: [props.work.id, includeDocumentKinds] });

    const dialogs = useDialogs();

    React.useEffect(() => {
        setModel(createDocumentsModel(folders ?? [], documents ?? []));
    }, [folders, documents]);

    React.useEffect(() => {
        foldersQuery.query();
        documentsQuery.query();
    }, [includeDocumentKinds])

    React.useEffect(() => {
        setFolders(foldersQuery.value);
    }, [foldersQuery.value])

    React.useEffect(() => {
        setDocuments(documentsQuery.value);
    }, [documentsQuery.value]);


    React.useEffect(() =>  {
        const include = perms.get('includeDocumentKinds');

        if (include) {
            setIncludeDocumentKinds([1, 2, 3]);
        }
    }, [ perms.get('includeDocumentKinds')])

    const initializeFolders = loading.wrap(async () => {
        await foldersQuery.query();
        setFolders(foldersQuery.value);
    });
    const initializeDocuments = loading.wrap(async () => {
        await documentsQuery.query();
        setDocuments(documentsQuery.value);
    });

    // Acciones
    const doDownloadFile = (documentId: number) => {
        const document = documents?.find(d => d.id === documentId)!;
        const name = document.name;
        const url = `/api/files/${props.work.id}/document/${documentId}`;

        downloadFileFromUrl(url, name);
    }

    const doMoveFolder = async (folderId: number, target: number) => {
        const f = folders?.find(s => s.id === folderId)!;
        f.parentFolderId = target;

        const result = await props.saveFolder(props.work.id, f);
        messages.set(result);

        await initializeFolders();
    }

    const doRemoveFolder = loading.wrap(async (folderId: number) => {
        const f = foldersQuery.value.find(s => s.id === folderId)!;
        const result = await props.removeFolder(props.work.id, f.id!);
        messages.set(result);

        await initializeFolders();
    });

    const confirmRemove = useConfirm({
        message: t('documents.remove.confirm'),
        icon: 'pi pi-trash',
        accept: (id: number) => doRemoveDocument(id),
    });

    const doRemoveDocument = loading.wrap(async (documentId: number) => {
        const f = documents?.find(s => s.id === documentId)!;
        const result = await props.removeDocument(props.work.id, f.id!);
        messages.set(result);

        await initializeDocuments();
    });

    const doSaveFolder = loading.wrap(async (f: IFolder) => {
        const resp = await props.saveFolder(props.work.id, f);
        messages.clear();
        if (resp.hasValue) {
            dialogs.clear();
            await initializeFolders();
        }
        else {
            messages.set(resp);
        }
    });

    const doSaveDocument = loading.wrap(async (f: IDocument) => {
        f.areaDownload = true;
        const resp = await props.saveDocument(props.work.id, f);
        messages.clear();
        if (resp.hasValue) {
            dialogs.clear();
            await initializeDocuments();
        }
        else {
            messages.set(resp);
        }
    });

    const doUploadFile = loading.wrap(async (d: any, file: File) => {
        const fileId = file
            ? await props.pushTemporalResource(file)
            : undefined;
        const doc = {
            ...d,
            temporalResource: fileId?.value,
            folderId: d.folderId === true ? undefined : d.folderId,
            areaDownload: true,
        };

        const resp = await props.saveDocument(props.work.id, doc);
        messages.set(resp);

        dialogs.clear();

        await initializeDocuments();
    });

    const doSaveFile = loading.wrap(async (d: any, file: File) => {
        const fileId = file
            ? await props.pushTemporalResource(file)
            : undefined;
        const doc: IDocument = {
            ...d,
            temporalResource: fileId?.value,
            areaDownload: true,
        };

        const resp = await props.saveDocument(props.work.id, doc);
        messages.set(resp);

        dialogs.clear();

        await initializeDocuments();
    });

    const menuActions = {
        addFolder: (id: number) => dialogs.show('folder', { workId: props.work.id, name: '', parentFolderId: id }),
        downloadFile: (id: number) => doDownloadFile(id),
        editFolder: (id: number) => dialogs.show('folder', foldersQuery.value.find(f => f.id === id)),
        uploadFile: (folderId: number) => dialogs.show('upload-file', folderId),
        showDocumentLogs: (id: number) => dialogs.show('logs', id),
        removeFolder: doRemoveFolder,
        removeDocument: confirmRemove,
        editDocument: (id: number) => dialogs.show('edit-document', documentsQuery.value.find(d => d.id == id)),
    };

    const permissions = usePermissions(props, { debug: false }, {
        addFile: { name: 'work.add.file', default: props.security.isGestor(), },
        addFolder: { name: 'work.add.folder', default: props.security.isGestor(), },
        removeFile: { name: 'work.removeDocument', default: props.security.isGestor(), }
    });

    const createNodeMenu = (nodeKey: string) => {
        return createMenu(t, nodeKey, {
            actions: menuActions,
            security: props.security,
            permissions: permissions,
        });
    }

    const handleDragAndDrop = async (event: any) => {
        const dragNode = event.dragNode;
        const dropNode = event.dropNode ?? event.dropIndex;
        if (!isNaN(dropNode)) {
            const movingObject = dragNode;
            movingObject.data.sortOrder = dropNode;
            const newModel = [...model];

            const k = model.find(d => d.key == dragNode.key)!;
            const index = model.indexOf(k);

            const [dragNodeObj] = newModel.splice(index, 1);
            newModel.splice(dropNode, 0, dragNodeObj);

            for (let i = 0; i < newModel.length; i++) {
                newModel[i].data.sortOrder = i;

                if (isFolder(newModel[i].data)) {
                    await props.saveFolder(props.work.id, newModel[i].data);
                }
                else {
                    await props.saveDocument(props.work.id, newModel[i].data);
                }
            }

            setModel([...newModel]);
        }

        else if (nodeIsFolder(dropNode.key) && nodeIsFolder(dragNode.key)) {
            await doMoveFolder(dragNode.data.id, dropNode.data.id);
        }
        else if (nodeIsFolder(dropNode.key) && nodeIsDocument(dragNode.key)) {
            const documentId = nodeKeyGetId(dragNode.key);
            const doc: IDocument = documents?.find(d => d.id === documentId)!;
            const folderId = dropNode.data.id;
            doc.folderId = folderId;

            await doSaveDocument(doc);
        }
    }

    const permissionIcons: { [key: number]: string | undefined } = {
        [DocumentKind.INTERNAL]: 'internal',
        [DocumentKind.CONTRACTOR]: 'contractor',
        [DocumentKind.ALL]: 'all',
    };

    const renderPermissions = (node: TreeNode | Node) => {
        const icon = permissionIcons[node.data.documentKind] ?? 'all';
        return <span className={classNames('doc-perm', icon)}></span>;
    }

    const renderDocumentLabel = (data: any, label: string | any) => {
        if (data) {
            const icon = typeof (label) === 'object' ? label.icon : undefined;
            const text = typeof (label) === 'object' ? label.title : label;
            const info = typeof (label) === 'object' ? label.info : undefined;
            const pre = text ? `${text}: ` : '';
            return <span title={info}><Tag rounded icon={icon} value={`${pre}${data}`} /></span>
        }
        else {
            return null;
        }
    }

    const createNodeTemplate = (node: TreeNode) => {
        const documentId = nodeKeyGetId(node.key as string);
        const actions = createNodeMenu(node.key as string);

        if (nodeIsDocument(node.key as string)) {
            return <div className='r we v-center' onDoubleClick={() => doDownloadFile(documentId)}>
                {permissions.get('addFile') && renderPermissions(node)}
                {permissions.get('addFile') && node.data.sortOrder &&
                    <span className="sort-order">({node.data.sortOrder})</span>}
                {node.data.title ?? node.label}
                <span className='e' />
                {props.work.id != 21 &&
                    <div className='tabular-data-labels'>
                        {renderDocumentLabel(node.data.code, {
                            info: t('document.code'),
                            icon: 'pi pi-bars',
                        })}
                        {renderDocumentLabel(node.data.edition, {
                            info: t('document.edition'),
                            icon: 'pi pi-bookmark'
                        })}
                        {renderDocumentLabel(node.data.year, {
                            icon: 'pi pi-sort-numeric-up',
                            info: t('document.year'),
                        })}
                        {renderDocumentLabel(
                            dateUtils.formatFromUtc(node.data.uploadDateTime),
                            { icon: 'pi pi-calendar', info: t('document.upload-time') })}
                    </div>}
                <SplitButton
                    className='tree-split-btn'
                    menuClassName='sm gray'
                    model={actions}
                />
            </div>
        }
        else {
            return <div className='r we v-center'>
                {permissions.isGestor() && node.data.sortOrder &&
                    <span className="sort-order">({node.data.sortOrder})</span>}

                {node.label}
                <span className='e' />
                <SplitButton
                    className='tree-split-btn'
                    menuClassName='sm gray'
                    model={actions} />
            </div>
        }
    }

    const document2Node = (d: IDocument) => {
        return {
            label: d.title,
            data: d,
            id: `document_${d.id}`,
            parentId: `folder_${d.folderId}`,
            code: d.code,
            year: d.year,
            edition: d.edition,
            date: dateUtils.formatFromUtc(d.uploadDateTime),
            icon: iconForDocument(d.name),
            sortOrder: 1000 * (d.sortOrder ?? 1000),

        } as Node
    }

    const folder2Node = (f: IFolder) => {
        return {
            label: f.name,
            data: f,
            id: `folder_${f.id}`,
            parentId: `folder_${f.parentFolderId}`,
            icon: 'pi pi-folder',
            sortOrder: f.sortOrder,
        } as Node
    }

    const createNodes = (docs: IDocument[], folders: IFolder[]) => {
        let res: Node[] = [];
        if (docs != undefined) {
            res = res.concat(docs
                .sort((a: IDocument, b: IDocument) => (b.sortOrder ?? 0) - (a.sortOrder ?? 0))
                .map(document2Node));
        }
        if (folders != undefined) {
            res = res
                .concat(
                    folders
                        .sort((a: IFolder, b: IFolder) => (b.sortOrder ?? 0) - (a.sortOrder ?? 0))
                        .map(folder2Node));
        }

        return res;
    }

    const [treeNodes, setTreeNodes] = React.useState<Node[]>([]);
    React.useEffect(() => {
        const data = createNodes(documentsQuery.value, foldersQuery.value);
        setTreeNodes(data);
    }, [includeDocumentKinds, foldersQuery.value, documentsQuery.value]);

    const treeTable = useTreeTable<Node>({
        data: treeNodes,
        sortProperty: 'sortOrder',
        selectable: true,
        columns: [
            {
                title: '',
                className: 'td-expand',
                render: (node: Node) => {
                    return <span className="node-container"
                        draggable
                        onDrop={handleDragAndDrop}>
                        {node.icon && <i className={classNames(node.icon, 'node-icon')} />}
                        {permissions.get('addFile') && renderPermissions(node)}
                        {permissions.get('addFile') && node.data.sortOrder &&
                            <span className="sort-order">({node.data.sortOrder})</span>}
                        {node.data.title ?? node.label}
                    </span>;
                }
            },
            { title: 'document.code', field: 'code', className: 'td-md' },
            { title: 'document.year', field: 'year', className: 'td-md' },
            { title: 'document.edition', field: 'edition', className: 'td-md' },
            { title: 'document.upload-time', field: 'date', className: 'td-md' },
            {
                title: '',
                className: 'td-actions',
                render: (node: Node) => {
                    const nodeActions = createNodeMenu(node.id as string);

                    if ((nodeActions?.length ?? 0) > 0) {
                        return <SplitButton
                            className='tree-split-btn'
                            menuClassName='sm gray'
                            model={nodeActions} />

                    }
                    else {
                        return null;
                    }
                }
            },
        ]
    });

    return <div className='ManageDocuments'>
        {dialogs.render('edit-document', { title: t('Edit document'), className: 'g pd' }, (document: IDocument) =>
            <EditDocumentForm
                document={document}
                loading={loading.isLoading()}
                onCancel={dialogs.clear}
                onSave={doSaveFile}
                workId={props.work.id} />
        )}
        {dialogs.render('upload-file', { title: t('Upload file'), className: 'g pd' }, (folderId: number) =>
            <UploadFileForm
                folderId={folderId}
                loading={loading.isLoading()}
                onCancel={dialogs.clear}
                onSave={doUploadFile}
                workId={props.work.id} />
        )}
        {dialogs.render('folder', { title: t('Folder'), className: 'g pd' }, (data: any) =>
            <FolderForm
                folder={data}
                onCancel={dialogs.clear}
                loading={loading.isLoading()}
                onSubmit={doSaveFolder}
                workId={props.work.id} />)}
        {dialogs.render('logs', { title: t('document.logs'), className: 'g pd' }, (id: number) =>
            <DocumentLogs
                workId={props.work.id}
                work={props.work}
                documentId={id} />)}

        <div className='header toolbar sticky'>
            {messages.render()}
            {foldersQuery.renderLoading({ className: 'lg md pd center' })}
            <span className={'e'} />
            {permissions.get('addFile') &&
                <button className={'tool'} onClick={dialogs.showFromEvent('upload-file')}>
                    <i className={'fa fa-plus'} />
                    {t('Add document')}
                </button>}
            {permissions.get('addFolder') &&
                <button className={'tool'} onClick={dialogs.showFromEvent('folder', { workId: props.work.id, name: '' })}>
                    <i className={'fa fa-plus'} />
                    {t('Add folder')}
                </button>}
        </div>

        <Toast ref={toast} />

        <div className={'tree-container'}>
            <div className="tree-table-headers">
                <div className='doc-perm-legend'>
                    <span className='doc-perm contractor'></span>
                    {t('document.kind.contractor')}
                    <span className='doc-perm internal'></span>
                    {t('document.kind.internal')}
                    <span className='doc-perm all'></span>
                    {t('document.kind.all')}
                </div>
                <span className="e" />
                {props.work.id != 21 && false && <>
                    <div className="code-header">{t("document.code")}</div>
                    <div className="edition-header">{t("Edition")}</div>
                    <div className="year-header">{t("Year")}</div>
                    <div className="upload-time-header">{t("Upload date")}</div>
                </>}
            </div>

            {(![21, 23].includes(props.work.id)) && treeTable()}
            {props.work.id == 23 && <><div className={`work-${props.work.id}`}>{treeTable()}</div></>}
            {props.work.id == 21 &&
                <Tree
                    nodeTemplate={createNodeTemplate}
                    className='tree'
                    dragdropScope='manage-documents'
                    onDragDrop={handleDragAndDrop}
                    expandedKeys={expandedKeys}
                    onToggle={e => setExpandedKeys(e.value)}
                    contextMenuSelectionKey={selectedNode}
                    onContextMenuSelectionChange={event => setSelectedNode(event.value)}
                    // onContextMenu={event => cm.current.show(event.originalEvent)}
                    value={model} />}
        </div>
    </div>
}
