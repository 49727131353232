import * as React from 'react';

export interface IProps {
    className?: string;
    label: string;
    labelClassName?: string;
    children: any;
}

export function R({className, label, labelClassName, children}: IProps) {
    return <div className={'r vc ' + className}>
        <label className={labelClassName}>{label}</label>
        {children}
    </div>
}