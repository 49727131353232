import * as React from 'react';

import {
    BreadcrumbItem,
    Panel,
    Portal,
    useDialogs,
    useLoading,
    useRemoteData,
    useToast,
    useTranslation,
    useSearch,
    useConfirm,
} from '@components';
import {
    IContractor,
    IMachinery,
    IMachinerySubType,
    IMachineryType,
    IPropertyGroupType,
    ISecurity,
    IWork,
    ResourceType
} from '@models';
import { redirectTo } from '@utils';
import './EditMachinery.scss';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import { goToMachineryRequirements, goToMachinery, goToMachineries } from "@components/routes";
import { MachineryRoutes } from './MachineryRoutes';

type getMachineryF = (workId: number, id: number) => Promise<IMachinery>;

export interface IProps {
    propertyGroupTypes: IPropertyGroupType[];
    getMachinery: getMachineryF;
    work: IWork;
    machinery: IMachinery;
    machineryId: number;
    reactivateMachinery: Function;
    security: ISecurity;
    saveMachinery: Function;
    machineryTypes: IMachineryType[];
    machinerySubTypes: IMachinerySubType[];
}


function EditMachineryInner(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const dialogs = useDialogs();
    const toast = useToast();

    const doReactivateMachinery = loading.wrap(async () => {
        await props.reactivateMachinery(props.work.id, props.machinery.id);
        goToMachineries(props.work.id);
    });

    const confirmActivate = useConfirm({
        message: t('resource.activate.machinery.confirm'),
        accept: (_: any) => doReactivateMachinery(),
    });

    const contractorData = useSearch<IContractor>({
        workId: props.work.id,
        search: 'machineries/contractor.get',
        filters: {
            contractorId: props.machinery.contractorId
        }
    });
    const contractor = contractorData.value && contractorData.value.length > 0
        ? contractorData.value[0]
        : undefined;

    const displayName = props.machinery?.name != ''
        ? props.machinery.name
        : props.machinery?.code != ''
            ? props.machinery.code
            : `${props.machinery.prefixLicenseNumber}${props.machinery.licenseNumber}${props.machinery.suffixLicenseNumber}`;

    return <div className='EditMachineryContainer'>
        <BreadcrumbItem
            text={t('Machineries')}
            onClick={() => redirectTo(`/work/${props.work.id}/machineries`)} />

        <BreadcrumbItem
            text={displayName} />

        <Portal container={'#breadcrumb-right'}>
            {loading.render()}
        </Portal>

        {toast.render()}

        {props.machinery &&
            <div className='form-container'>
                <MachineryRoutes />

                <div className='actions'>
                    {contractor && <div className='panel-contractor'>
                        <div className='r title-box'>
                            <i className="fas fa-id-card-alt" />
                            <span className='title'>{t('Contractor')}</span>
                        </div>
                        <span className='contractor-name'>{contractor.name}</span>
                    </div>}

                    <Panel header={
                        <div className={'r v-center'}>
                            <i className='fas fa-truck-loading' />
                            <span className={'e'} />
                            <span>{displayName}</span>
                        </div>} className='buttons'>
                        <div className='c'>
                            <button className='plain rounded' onClick={() => goToMachinery(props.work.id, props.machineryId)}>
                                <i className='fas fa-table' />
                                <span className='e'>{t('View machinery data')}</span>
                            </button>
                            <button className='plain rounded' onClick={() => goToMachineryRequirements(props.work.id, props.machineryId)}>
                                <i className='fas fa-folder' />
                                <span className='e'>{t('View requirements')}</span>
                            </button>
                            {props.security.isGestorOrContractor() && props.machinery.isActive === false &&
                                <button onClick={confirmActivate}>
                                    <i className='fas fa-check' />
                                    <span className='e'>{t('resource.activate')}</span>
                                </button>}
                        </div>
                    </Panel>
                    {dialogs.render('force-validation', { title: t('Force resource validation'), className: 'g pd' }, () =>
                        <ForceValidationResourceContainer
                            currentStatusType={props.machinery.currentStatusType}
                            workId={props.work.id}
                            requestClose={dialogs.clear}
                            resourceType={ResourceType.Machinery}
                            resourceId={props.machinery.id} />
                    )}
                </div>
            </div>}
    </div>
}

export function EditMachinery(props: IProps) {
    const data = useRemoteData<IMachinery>(props.getMachinery, {
        parameters: [props.work.id, props.machineryId]
    });

    return <div className='EditMachineryContainer'>
        {data.renderLoading()}
        {data.value && <EditMachineryInner
            {...props}
            machinery={data.value} />}
    </div>
}
