import * as React from 'react';

import { IWork, IDepartment, IOption, IWorker } from '@models';
import { WorkToolbar } from './WorkToolbar';
import { BreadcrumbItem, useDataTable, useDialogs, useFormDialog, useLoading, useRemoteData, useTranslation, useSearchFilters, useInputSearch, useKeyListener, R } from '@components';
import { DepartmentForm } from './DepartmentForm';
import * as Actions from '@store/actions/resources';
import { ManageManyToManyRelations } from '@components/common/ManageManyToManyRelations';

type GetDepartmentsF = (workId: number) => Promise<IDepartment>;
type SaveDepartmentF = (workId: number, data: IDepartment) => Promise<IOption<boolean>>;

export interface IProps {
    getWorkDepartments: GetDepartmentsF;
    saveDepartment: SaveDepartmentF;
    work: IWork;
}

export function AdminWorkDepartments(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();

    const dialogs = useDialogs();

    const [searchFilter, setSearchFilter] = React.useState<string>('');
    const [departments, setDepartments] = React.useState<IDepartment[]>([]);
    const [filter, setFilter] = React.useState<string>('');

    const workersDialog = useFormDialog({
        editTitle: t('Workers'),
    });

    const data = useRemoteData<IDepartment[]>(
        props.getWorkDepartments,
        {
            parameters: [props.work.id]
        });

    React.useEffect(() => {
        initialize();
    }, [data.value]);

    const userFilters = useSearchFilters({
        workId: props.work.id,
        name: 'admin.departments',
        references: {
            departments: data.value,
        },
        persist: false,
    });

    React.useEffect(() => {
        initialize();
    }, [userFilters.filters.isOblique, userFilters.filters.isNotOblique]);

    React.useEffect(() => {
        if(userFilters.filters.departments){
            const result = data.value?.filter(x => userFilters.filters.departments.includes(x.id));
            setDepartments(result);
        }else{
            userFilters.setFilter('departments', null);
            initialize();
        }
    }, [userFilters.filters.departments]);

    const initialize = () => {
        let result : IDepartment[] = data.value;
        if(userFilters.filters && userFilters.filters.isOblique == true && userFilters.filters.isNotOblique == true){
            doStringSearch(result);
        }else if(userFilters.filters && userFilters.filters.isOblique == true){
            userFilters.filters.isNotOblique && userFilters.setFilter('isNotOblique', false);
            setFilter('isOblique');
            result = data.value?.filter(x => x.isOblique == true);
            doStringSearch(result);
        }else if(userFilters.filters && userFilters.filters.isNotOblique == true){
            userFilters.filters.isOblique && userFilters.setFilter('isOblique', false);
            setFilter('isNotOblique');
            result = data.value?.filter(x => x.isOblique == false);
            doStringSearch(result);
        }else if(userFilters.filters && userFilters.filters.isOblique == false && userFilters.filters.isNotOblique == false){
            if(filter == 'isOblique'){
                setFilter('');
                userFilters.setFilter('isNotOblique', true);
            }else if(filter == 'isNotOblique'){
                setFilter('');
                userFilters.setFilter('isOblique', true);
            }
        }
    }

    const doStringSearch = (departments: IDepartment[]) => {
        if (searchFilter != '') {
            const filtered = departments.filter(x => x.name.toLowerCase().includes(searchFilter.toLowerCase()));
            if (filtered) {
                setDepartments(filtered);
            }
        }
        else {
            setDepartments(departments);
        }
    };

    React.useEffect(() => {
        initialize();
    }, [searchFilter]);


    const dataTable = useDataTable<IDepartment>({
        tableStyle: { width: '100%' },
        columns: [
            { field: 'id', className: 'td-md', sortKey: 'id' },
            { title:'Code', field: 'code', sortKey:'code' },
            {
                title:'name',
                field: 'name',
                sortKey: 'name',
                render: (d: IDepartment) => <span title={d.name}>{d.name}</span>
            },
            { title:'description', field: 'description', sortKey:'description' },
            {
                title: 'Policies',
                render: r => {
                    const policies = (r.policies ?? []).join(', ');
                    return <span title={policies}>{policies}</span>
                },
                sortKey: 'policies',
            },
            { title: 'notifyJobCreation', field: 'notifyJobCreation', delegate: 'boolean', className: 'td-md center', sortKey: 'notifyJobCreation' },
            { title: 'IsOblique', field: 'isOblique', delegate: 'boolean', className: 'td-md center', sortKey: 'isOblique' }
        ],
        actions: [
            {
                icon: 'fas fa-people-carry',
                onClick: (d: IDepartment) => workersDialog.showEdit(d)
            },
            {
                icon: 'fa fa-edit',
                onClick: (d: IDepartment) => dialogs.show('edit', d)
            }
        ],
        className: 'fixed-header-t36 fixed-table',
        data: departments,
    });

    const onSaved = () => {
        dialogs.clear();
        data.query();
    }

    const searchCtrl = React.useRef<any>();
    useKeyListener({
        key: 'f',
        keyCtrl: true,
        handle: () => {
            searchCtrl.current?.focus();
        },
    });

    const addDepartmentHasWorker = async (worker: IWorker, department: IDepartment) => {
        loading.start('workers');
        workersDialog.clearErrors();

        const resp = await Actions.saveDepartmentHasWorker(props.work.id, department.id, worker.id);
        if (resp.hasValue) {
            workersDialog.clearErrors();
        }
        else {
            workersDialog.setEditError(
                t('Cannot add worker relation') + ': ' + worker.name + ' ' + worker.surname);
        }

        loading.stop();
    }

    // remove Worker
    const removeDepartmentHasWorker = async (worker: IWorker, department: IDepartment) => {
        loading.start('workers');

        workersDialog.clearErrors();
        const resp = await Actions.removeDepartmentHasWorker(props.work.id, department.id, worker.id);
        if (!resp.hasValue) {
            workersDialog.setEditError(
                t('Cannot remove worker relation'));
        }

        loading.stop();
    }


    return <div className='c c-sc'>
        <BreadcrumbItem text={t('Departments')} />
        <WorkToolbar work={props.work}>
            {data.renderLoading()}
            {loading.render()}
            <button
                onClick={dialogs.showFromEvent('add')}>
                <i className='pi pi-plus sm pd-right' />
                {t('Add department')}
            </button>
        </WorkToolbar>

        {workersDialog.render((department: IDepartment, { error }: { error: string }) =>
            <ManageManyToManyRelations
                error={error}
                source={department}
                columns={[
                    'name', 'surname'
                ]}
                onAdd={(d: IWorker) => addDepartmentHasWorker(d, department)}
                onRemove={(d: IWorker) => removeDepartmentHasWorker(d, department)}
                loading={loading.isLoading('workers')}
                targetLabel='fullName'
                loadTargets={() => Actions.getWorkers(props.work.id, true)}
                loadRelations={() => Actions.getDepartmentWorkers(props.work.id, department.id)} />)}


        {dialogs.render(
            'add',
            { title: t('Add department'), className: 'g pd' },
            (_: any) =>
                <DepartmentForm
                    workId={props.work.id}
                    saveDepartmentPolicy={Actions.addDepartmentPolicy}
                    removeDepartmentPolicy={Actions.removeDepartmentPolicy}
                    saveDepartment={props.saveDepartment}
                    onCancel={dialogs.clear}
                    onSaved={onSaved} />
        )}
        {dialogs.render(
            'edit',
            { title: t('Edit department'), className: 'g pd' },
            (dep: IDepartment) =>
                <DepartmentForm
                    data={dep}
                    workId={props.work.id}
                    saveDepartmentPolicy={Actions.addDepartmentPolicy}
                    removeDepartmentPolicy={Actions.removeDepartmentPolicy}
                    saveDepartment={props.saveDepartment}
                    onCancel={dialogs.clear}
                    onSaved={onSaved} />
        )}

        <div className='h100p c he we'>
            <div className='c he r we'>
                <div className='p-inputgroup toolbar'>
                <input
                    type='search'
                    // ref={searchCtrl}
                    className='e'
                    value={searchFilter}
                    // onKeyDown={e => keyIsEnter(e) ? doSearch() : undefined}
                    onChange={e => setSearchFilter(e.target.value)}
                    // onBlur={_ => doSearch()}
                    placeholder={t('Search')} />
            <span className='key-shortcut' title={t('Search')}>Ctrl+F</span>
                    <userFilters.Component />
                </div>
            </div>
        </div>
        {dataTable()}
    </div>;
}
