import * as React from 'react';

import { INotification, ILegalForm } from '@models';
import {
    classNames,
    InputText,
    NotificationsBox,
    useForm,
    useTranslation,
    ValidationBuilder,
} from '@components';

import './WorkForm.css';
import { IWorkerType } from '@models/resources';

export interface IProps {
    data?: ILegalForm;
    workId: number;
    footer?: any;
    notifications: INotification[];
    onChange?: Function;
    onSubmit: Function;
    workerTypes: IWorkerType[];
}

export const validateLegalForm = (data: ILegalForm) => {
    return ValidationBuilder.create(data)
        .notEmpty('name')
        .build();
}

export const isValid = (data: ILegalForm) => {
    return Object.keys(validateLegalForm(data)).length == 0;
}

export interface IWorkerTypeWithSelected extends ILegalForm {
    workerTypesReal: number[];
};

export function LegalFormForm(props: IProps) {
    const { t } = useTranslation();

    const form = useForm<IWorkerTypeWithSelected>({
        initialValues: {
            id: props.data?.id ?? 0,
            name: props.data?.name ?? '',
            workId: props.workId,
            workerTypesReal: props.data?.workerTypes?.map(wt => wt.workerType.id) ?? [],
            hideContractorProperties: props.data?.hideContractorProperties ?? false,
        },
        validate: validateLegalForm,
        onSubmit: () => {
            const data = getLegalForm();
            props.onSubmit(data);
            return { ok: true };
        },
        onChange: props.onChange,
    });

    const getLegalForm = () => {
        return {
            id: props.data?.id ?? 0,
            name: form.values.name,
            workId: props.workId,
            workerTypes: props.workerTypes.filter(wt => form.values.workerTypesReal.includes(wt.id)).map(wt => ({
                workerTypeId: wt.id,
            })),
            hideContractorProperties: form.values.hideContractorProperties,
        }
    }

    return <div>
        <form onSubmit={form.handleSubmit}
            className='p-fluid'>
            <div className='c md pd g-30'>
                {form.floatField('name', t('Name'), () =>
                    <InputText
                        id='name'
                        type='text'
                        value={form.values.name}
                        onChange={form.handleChange}
                        autoFocus
                        className={classNames({ 'p-invalid': !form.isFormFieldValid('name') })} />)}

                {form.multiselect('workerTypesReal', props.workerTypes, true)}
                <div className='r g-20 v-center'>
                    {form.checkBox('hideContractorProperties')}
                    <label htmlFor="hideContractorProperties">
                        {t('hideContractorProperties')}
                    </label>
                </div>
            </div>
            <div className='errors-container'>
                {form.errorBox()}
                <NotificationsBox notifications={props.notifications} />
            </div>
            {props.footer != undefined && props.footer}
        </form>
    </div>
}