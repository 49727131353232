import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import * as JobsStore from '../../store/jobs';

import { JobReactivateForm } from '@components/jobs/JobReactivateForm';

export default connect(
    (state:ApplicationState) => ({
        ...JobsStore.plainActions,
    }),
    JobsStore.actionCreators,
)(JobReactivateForm as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any