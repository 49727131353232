import { Action, Reducer } from 'redux';
import {
    IModuleInfo, IWork,
} from '../models';
import { IFrontendModule, MenuItem } from '../../../../GStore.Modules/FrontEnd';
import ModuleManager from '../modules/ModuleManager';
import * as Utils from '@utils';
import * as DateUtils from '@utils/date-utils';
import * as Components from '@components';
import * as Containers from '../containers';
import * as Models from '@models';
import * as I18n from '../i18n';
import * as Search from './actions/search';
import * as Contractors from './actions/contractors';
import * as Documents from './actions/documents';
import * as Users from './actions/user';
import * as Requirements from './actions/requirements';
import * as Jobs from './actions/jobs';
import { ApplicationState } from './index';
import { IdentityState } from './identity';

export interface IModulesState {
    menuItems: MenuItem[];
    modules: RuntimeModule[];
    moduleManager: ModuleManager;
}

const defaultState = {
    menuItems: [],
    modules: [],
    moduleManager: new ModuleManager([]),
}

// types
enum Types {
    ModulesLoaded = '[MODULES] MODULES LOADED',
    ClearModules = '[MODULES] CLEAR MODULES',
    InitializeModuleManager = '[MODULES] INITIALIZE MODULE MANAGER',
}

type RuntimeModule = IFrontendModule;

// actions
export interface OnModulesLoaded {
    type: Types.ModulesLoaded;
    menuItems: MenuItem[],
    modules: RuntimeModule[],
}
export interface ClearModules {
    type: Types.ClearModules,
}
export interface OnInitializeModuleManager {
    type: Types.InitializeModuleManager,
    identity: IdentityState,
    modules: RuntimeModule[],
}

export type KnownAction = ClearModules | OnModulesLoaded | OnInitializeModuleManager | { type: undefined };

export const plainActions = {
};

(window as any).__modules = {
    Containers,
    Components,
    Utils,
    DateUtils,
    Models,
    I18n,
    Search,
    Contractors,
    Documents,
    Users,
    Requirements,
    Jobs,
};

const runtimeModules = (window as any).__modules;

const loadModule = async (m: IModuleInfo) => {
    if (runtimeModules[m.id]) {
        return runtimeModules[m.id];
    }

    const url = `/api/modules/${m.id}/resource/${m.frontendResource}`;
    // console.log('try to load module', m, url);
    const resp = await fetch(url);
    // console.log('try to load module', m, resp);
    const content = await resp.text();
    try {
        eval(content);
    } catch (ex) {
        console.error('error loading module', m, ex);
    }
    finally {
        runtimeModules[m.id].activate();
        runtimeModules[m.id].name = m.name;
        return runtimeModules[m.id];
    }
}

const unloadModule = (m: RuntimeModule) => {
    m.deactivate();
}

function mergeI18n(a: any, b: any) {
    for (const key in b) {
        if (a[key]) {
            a[key] = { ...a[key], ...b[key] };
        }
        else {
            a[key] = b[key];
        }
    }
    return a;
}

export const actionCreators = {
    clearModules: () => (dispatch: Function) => {
        dispatch({
            type: Types.ClearModules,
        });
    },
    initializeWork: (work: IWork, i18nResources: any = undefined) => async (dispatch: Function, getState: () => ApplicationState) => {
        const activeModules = work.activeModules ?? [];
        const state = getState();

        for (const m of state.modules.modules) {
            unloadModule(m);
        }

        const security = state.identity?.security;
        const menuItems = [];
        const modules = [];
        let modulesI18n = {};
        for (const moduleInfo of activeModules) {
            if (moduleInfo.hasFrontend) {
                const module = await loadModule(moduleInfo);
                modules.push(module);
                const moduleItems = await module.getMenuItems({
                    work, security,
                });
                for(const mi of moduleItems) {
                    menuItems.push(mi);
                }

                if (module.i18n) {
                    modulesI18n = mergeI18n(modulesI18n, module.i18n);
                }
            }
        }

        if (Object.keys(i18nResources).length > 0) {
            I18n.installResources(i18nResources, modulesI18n);
        }

        dispatch({
            type: Types.InitializeModuleManager,
            identity: state.identity,
            modules: modules,
        });

        dispatch({
            type: Types.ModulesLoaded,
            menuItems: menuItems,
            modules: modules,
        });
    },
};

export const reducer: Reducer<IModulesState> = (state: IModulesState | undefined, incomingAction: Action): IModulesState => {
    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case Types.ClearModules:
            return defaultState;

        case Types.ModulesLoaded:
            return {
                ...state,
                modules: action.modules,
                menuItems: action.menuItems,
            };

        case Types.InitializeModuleManager:
            return {
                ...state,
                moduleManager: new ModuleManager(
                    action.modules || state.modules,
                    action.identity,
                    Math.random() * new Date().getTime() + '_v',
                ),
            }

        default:
            return state;
    }
};
