import * as React from 'react';

import { G, ValidationBuilder, useForm, useTranslation } from '@components';
import {
    getNotificationMessageActions,
    getNotificationResourceTypes,
    getResourceTypes,
    INotificationMessage,
    INotificationTemplate,
    ResourceType,
} from '@models';
import { IWorkDepartment } from '@models/works';

type OnSubmitF = (data: INotificationMessage) => any;

export interface IProps {
    data?: INotificationMessage;
    templates: INotificationTemplate[];
    isLoading?: boolean;
    onCancel?: Function;
    onSubmit?: OnSubmitF;
    showFooter?: boolean;
    workId: number;
    departments: IWorkDepartment[];
}

export function NotificationMessageForm(props: IProps) {
    const { t } = useTranslation();

    const commonActions = getNotificationMessageActions(t).sort((a, b) => a.name.localeCompare(b.name));

    const notificationResourceTypes = getNotificationResourceTypes(t);

    const resourceTypes = getResourceTypes(t);

    const form = useForm<Partial<INotificationMessage>>({
        initialValues: {
            id: props.data?.id,
            action: props.data?.action,
            resourceType: props.data?.resourceType,
            restrictToResourceType: props.data?.restrictToResourceType,
            restrictToResourceId: props.data?.restrictToResourceId,
            notificationTemplateId: props.data?.notificationTemplateId,
            byMail: props.data?.byMail ?? false,
            workId: props.workId,
            actionKey: props.data?.actionKey
        },
        validate: d => ValidationBuilder.create(d)
            .notEmpty('action')
            .notEmpty('resourceType')
            .notEmpty('notificationTemplateId')
            .build(),
    });

    const obliqueDepartments = props.departments.filter(d => d.isOblique === true);

    return <div>
        <div className='c md pd form-1 l200'>
            <G label={t('Action')}>
                {form.select('action', { options: commonActions, filter: true })}
            </G>
            {form.values.action == 20 &&
                <G label={t('Action key')}>
                    {form.input('actionKey')}
                </G>
            }
            <G label={t('ResourceType')}>
                {form.select('resourceType', notificationResourceTypes)}
            </G>
            {form.values.resourceType == ResourceType.ObliqueDepartment && <>
                <G label={t('department.oblique')}>
                    {form.select('restrictToResourceId', obliqueDepartments)}
                </G>
            </>}
            <G label={t('Template')}>
                {form.select('notificationTemplateId', { options: props.templates, filter: true })}
            </G>
            <G label={t('Restrict resource')}>
                {form.select('restrictToResourceType', resourceTypes)}
            </G>
            <G label={t('ByMail')}>
                {form.checkBox('byMail', { containerClassName: 'center' })}
            </G>
            {form.errorBox()}
        </div>
        {props.showFooter && <div className='r r-end footer'>
            <button onClick={() => props.onCancel?.()}>{t('Cancel')}</button>
            <button
                className='primary'
                disabled={form.isInvalid() || props.isLoading}
                onClick={() => props.onSubmit?.(form.values)}>{t('Save')}</button>
        </div>}
    </div>
}
