import * as React from 'react';

import {
    F, Message, TabPanel, TabView, Tag, useDataTable, useDialogs, useForm,
    useLoading, useMessage, useRemoteData, useSearch, useTranslation, useTreeTable
} from '@components';
import { IContractor, IOption, ISecurity, ISubContractorRequest, IWork, ModuleManager, IUserIdentity } from '@models';
import DateUtils from '@utils/date-utils';

type ProcessF = (workId: number, subContractorRequestId: number, reason?: string) => IOption<number>;

export interface IProps {
    acceptSubContractorRequest: ProcessF;
    getSubContractorRequestsAsWorker: Function;
    rejectSubContractorRequest: ProcessF;
    getContractor: Function;
    getJob: Function;
    work: IWork;
    security: ISecurity;
    moduleManager: ModuleManager;
    user: IUserIdentity
}

function RequestState({ request }: { request: ISubContractorRequest }) {
    const { t } = useTranslation();

    if (request.rejected) {
        return <Tag value={t('Rejected request')} className='danger' />;
    }
    else if (!request.acceptedByContractor && !request.acceptedByWork) {
        return <Tag value={t('Pending')} />;
    }
    else if (request.acceptedByContractor) {
        return <Tag value={t('Accepted')} className='success' />;
    }
    else if (request.acceptedByWork) {
        return <Tag value={t('Accepted by main company')} className='info' />;
    }
    else {
        return null;
    }
}

function ProcessSubContractorRequest({
    accept, reject,
    onClose, onSuccess, request,
    workId
}: {
    accept: ProcessF, reject: ProcessF,
    onSuccess: Function,
    onClose: Function, request: ISubContractorRequest,
    workId: number,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const message = useMessage();

    const form = useForm({
        initialValues: request,
    });

    const doAccept = loading.wrap(async () => {
        const res = await accept(workId, request.id!);
        message.set(res);

        if (res.hasValue) {
            onSuccess();
        }
    });

    const doReject = loading.wrap(async () => {
        const res = await reject(workId, request.id!, form.get<string>('rejectedReason'));
        message.set(res);

        if (res.hasValue) {
            onSuccess();
        }
    });

    return <div className='c'>
        <div className='c md pd'>
            <Message text={t('Process subcontractor request')}></Message>
            <div className='r v-center sm pd'>
                <strong className='sm pd-right'>{request.sourceContractor?.name}</strong>
                <span>{t('Has requested {{name}} as subcontractor for the job', { name: request.targetContractor?.name })}</span>
                <span className='sm pd-right pd-left'>{request.job?.name}</span>
                <Tag>{request.job?.code}</Tag>
            </div>
            <TabView >
                <TabPanel header={t('Accept')}>
                    <div className='r'>
                        <div className='e' />
                        <button disabled={loading.isLoading()} onClick={doAccept}>{t('Accept request')}</button>
                    </div>
                </TabPanel>
                <TabPanel header={t('Reject')}>
                    <div className='form-1 l200'>
                        <F label={t('Reason')}>
                            {form.textarea('rejectedReason')}
                        </F>
                        <div className='r'>
                            <div className='e' />
                            <button disabled={loading.isLoading()} onClick={doReject} className='danger'>{t('Rejected reason')}</button>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>

        {message.render()}

        <div className='footer r'>
            {loading.render()}
            <div className='e' />
            <button onClick={() => onClose()}>{t('Cancel')}</button>
        </div>
    </div>
}

const renderRequestInfo = (r: ISubContractorRequest) => {
    if (r.rejected) {
        return <>
            <span>{DateUtils.format(r.rejectedDateTime)}</span>
            <span className='sm pd-left text-ellision'>{r.rejectedReason}</span>
        </>;
    }
    else if (r.acceptedByContractor) {
        return <>
            <span className='text-ellision'>{DateUtils.format(r.acceptedByContractorDateTime)}</span>
        </>;
    }
}

export function WorkSubContractorRequestsImpl(props: IProps) {
    const { t } = useTranslation();
    const dialogs = useDialogs();
    const [data, setData] = React.useState<ISubContractorRequest[]>([]);

    const prepareToProcessRequest = (r: ISubContractorRequest) => {
        dialogs.show('process', r);
    }

    const query = useRemoteData<ISubContractorRequest[]>(
        props.getSubContractorRequestsAsWorker,
        {
            parameters: [props.work.id],
        });

    const invitationSearch = useSearch({
        workId: props.work.id,
        search: 'jobs/subcontractor.invitations.contractors',
        filters: {
            workId: props.work.id,
        },
        lazy: true,
    });

    const userInvitationsSearch = useSearch({
        workId: props.work.id,
        search: 'jobs/subcontractor.invitations.users',
        filters: {
            workId: props.work.id,
        },
        lazy: true,
    });

    const initialize = async () => {
        const req = await query.query();
        const invitations = await invitationSearch.doSearch();
        const userInvitations = await userInvitationsSearch.doSearch();

        let invitationsAsRequests: ISubContractorRequest[] = [];
        for (const invitation of invitations) {
            const job = await props.getJob(props.work.id, invitation.jobId);
            let request: ISubContractorRequest = {
                jobsDescription: invitation.description,
                jobId: invitation.jobId,
                shipmentNumber: invitation.shipmentNumber,
                sourceContractorId: invitation.sourceId,
                sourceContractor: await props.getContractor(props.work.id, invitation.sourceId),
                targetContractorId: 0,
                targetContractor: invitation.targetName,
                workId: props.work.id,
                dateTime: invitation.dateTime,
                acceptedByContractorDateTime: invitation.acceptedDate,
                rejected: invitation.accepted != null ? !invitation.accepted : false,
                acceptedByContractor: invitation.accepted,
                job: job.data.work.job,
                id: 0,
                jobHasContractorId: invitation.jobHasContractorId,
            };
            invitationsAsRequests.push(request);
        }

        let userInvitationsAsRequests: ISubContractorRequest[] = [];
        for (const invitation of userInvitations) {
            if (invitation.jobId != null) {
                const job = await props.getJob(props.work.id, invitation.jobId);
                let request: ISubContractorRequest = {
                    jobsDescription: invitation.description,
                    jobId: invitation.jobId,
                    shipmentNumber: invitation.shipmentNumber,
                    sourceContractorId: 0,
                    sourceContractor: invitation.source,
                    targetContractorId: 0,
                    targetContractor: invitation.contractorName,
                    dateTime: invitation.dateTime,
                    workId: props.work.id,
                    rejected: invitation.accepted != null ? !invitation.accepted : false,
                    acceptedByContractor: invitation.accepted,
                    acceptedByContractorDateTime: invitation.acceptedDate,
                    job: job.data.work.job,
                    id: 0,
                    jobHasContractorId: invitation.jobHasContractorId,
                };
                userInvitationsAsRequests.push(request);
            }
        }

        const totalInvitations = req.concat(invitationsAsRequests).concat(userInvitationsAsRequests);
        const result = filter(totalInvitations);
        setData(result);
    }

    React.useEffect(() => {
        initialize();
    }, []);

    const filter = (data: ISubContractorRequest[]) => {
        const ctx = {
            props: props,
        };
        const filterData = props.moduleManager.filterDependency('work.invitations', ctx);

        const result = data.filter(filterData);
        return result;
    }

    const dataTable = useDataTable({
        columns: [
            { title: t('Date'), sortKey: 'dateTime', className: 'td-datetime', field: 'dateTime', delegate: 'date' },
            { title: t('Job'), sortKey: 'job.code', render: r => <><Tag value={r.job?.code} /><span className='sm pd-left'>{r.job?.name}</span></> },
            { title: t('Applicant'), sortKey: 'sourceContractor', render: r => r.sourceContractor?.name ?? r.sourceContractor },
            { title: t('Requested contractor'), sortKey: 'targetContractor', render: r => r.targetContractor?.name ?? r.targetContractor },
            { title: t('Description'), sortKey: 'jobsDescription', field: 'jobsDescription' },
            { title: t('State'), className: 'td-lg', sortKey: 'accepted', render: r => <RequestState request={r} /> },
            { render: renderRequestInfo }
        ],
        actions: [
            { icon: 'play', onClick: prepareToProcessRequest, disabled: r => r.acceptedByContractor || r.acceptedByWork || r.rejected || r.id == 0 || props.security.isWorker() },
        ],
        data: data,
        loading: query.loading && invitationSearch.loading && userInvitationsSearch.loading,
    });

    const refresh = () => {
        dialogs.clear();
        query.query();
    }

    return <div className='he c'>
        {dataTable()}

        {dialogs.render(
            'process',
            {},
            (data: ISubContractorRequest) =>
                <ProcessSubContractorRequest
                    accept={props.acceptSubContractorRequest}
                    reject={props.rejectSubContractorRequest}
                    workId={props.work.id}
                    onSuccess={refresh}
                    onClose={() => dialogs.clear()}
                    request={data} />)}
    </div>;
}

export function WorkSubContractorRequests(props: IProps) {
    const res = props
        .moduleManager
        .renderComponent<IProps>('WorkSubContractorRequests', { ...props }, WorkSubContractorRequestsImpl);
    return res;
}