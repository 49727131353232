import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { RecoverPassword } from '@components/identity/RecoverPassword';
import * as UserStore from '@store/user';

export default connect(
    (_: ApplicationState, props: any) => ({
        token: props.match.params.token,
        ...UserStore.plainActions,
    }),
    {}
)(RecoverPassword as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
