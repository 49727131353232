import * as React from 'react';

import {
    useTranslation,
    useSearch, useDataTable
} from '@components';


interface IProps {
    workId: number,
    removeRestrictedMachinery: Function,
    handleToast: (message: string, success: boolean) => void,
}

interface IResult {
    id: number,
    machineryType: number
    machineryTypeName: string
    name: string
    licenseNumber: string
    code: string
    contractorName: string
    applicantName: string
    applicantEmail: string
    date: string
    motive: string
    remarks: string
    isActive: boolean
    deactivatedAt?: string
    workId: number
}

export function RestrictedMachineries(props: IProps) {

    const { t } = useTranslation();

    const machineries = useSearch<IResult>({
        workId: props.workId,
        search: 'machineries/restricted',
        filters: {
            workId: props.workId,
        },
    });

    React.useEffect(() => {
        machineries.doSearch();
    }, []);

    const handleRemove = async (workId: number, id: number,) => {
        const resp = await props.removeRestrictedMachinery(workId, id);

        if (resp.isError === false) {
            props.handleToast(t('Machinery has been unrestricted'), true);
            machineries.doSearch();
        }
        else {
            props.handleToast(t('Error while unrestricting machinery'), false);
        }

    }

    const getActions = () => {
        return [
            {
                title: t('Unrestrict'),
                disabled: (row: IResult) => !row.isActive,
                icon: 'fa fa-unlock',
                onClick: (row: IResult) => {
                    handleRemove(row.workId, row.id);
                }
            }
        ];
    }

    const showDate = (date: string) => {
        return new Date(date).toLocaleDateString();
    }

    const dataTable = useDataTable({
        className: ``,
        tooltip: true,
        columns:
            [
                {
                    title: t('Date'),
                    render: d => <span title={showDate(d.date)}>{showDate(d.date)}</span>,
                    field: 'date',
                    className: 'td-md',
                    sortKey: 'date',
                },
                {
                    title: t('In effect'),
                    render: d => <span title={`${d.isActive ? t('Yes') : showDate(d.deactivatedAt!)}`}>{`${d.isActive ? t('Yes') : showDate(d.deactivatedAt!)}`}</span>,
                    field: 'isActive',
                    className: 'td-md',
                    sortKey: 'isActive',
                },
                {
                    title: t('Name'),
                    field: 'name',
                    className: 'td-md left',
                    sortKey: 'name',
                },
                {
                    title: t('Machinery type'),
                    field: 'machineryTypeName',
                    className: 'td-md left',
                    sortKey: 'machineryTypeName',
                },
                {
                    title: t('License number'),
                    field: 'licenseNumber',
                    className: 'td-md left',
                    sortKey: 'licenseNumber',
                },
                {
                    title: t('machinery.codes'),
                    field: 'code',
                    className: 'td-md left',
                    sortKey: 'code',
                },
                {
                    title: t('Contractor'),
                    field: 'contractorName',
                    className: 'td-md left',
                    sortKey: 'contractorName',
                },
                {
                    title: t('Applicant'),
                    field: 'applicantName',
                    className: 'td-md left',
                    sortKey: 'applicantName',
                },
                {
                    title: t('Applicant email'),
                    field: 'applicantEmail',
                    className: 'td-md left',
                    sortKey: 'applicantEmail',
                },
                {
                    title: t('Reason'),
                    field: 'reason',
                    className: 'td-md left',
                    sortKey: 'code',
                },
                {
                    title: t('Remarks'),
                    field: 'remarks',
                    className: 'td-md left',
                    sortKey: 'code',
                },
            ],
        actions: [
            {
                headerClassName: 'td-sm',
                actions: getActions()
            }
        ],
        data: machineries.value,
    });

    return (
        <div>
            {dataTable()}
        </div>
    );

}