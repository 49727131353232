import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Admin from '@store/admin';

import { AdminWorkNotifications } from '@components/admin/AdminWorkNotifications';

export default connect(
    (state: ApplicationState) => ({
        ...Admin.plainActions,
    }),
    Admin.actionCreators,
)(AdminWorkNotifications as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
