import * as React from 'react';

import {
    IJob, IWork, IPropertyGroup, PropertyGroupObjectType, IPropertyGroupType,
    IJobHasContractor,
    IJobHasWorker, IWorker, IPaginateResult, ISecurity, IUserIdentity, ModuleManager,
} from '@models';
import {
    Accordion, AccordionTab, F,
    useDataTable, useForm, useLoading, useSearch, useStringState, useTranslation,
    ValidationBuilder, Select, useNumberState, usePermissions,
} from '@components';
import { unique } from '@utils';
import DateUtils from '@utils/date-utils';
import { DynamicProperties } from '@components/user/DynamicProperties';
import { BreadcrumbItem } from '@components/custom/BreadcrumbItem';

export interface IProps {
    contractorId?: number;
    job: IJob;
    jobHasContractorId?: number;
    selectedJobHasContractor?: IJobHasContractor;
    getJobHasContractor: Function;
    propertyGroupTypes: IPropertyGroupType[];
    onCancel: Function;
    onSuccess: Function;
    saveJobHasWorker: Function;
    searchWorkers: Function;
    security: ISecurity;
    work: IWork;
    getJobContractors: Function;
    user: IUserIdentity;
    moduleManager: ModuleManager;
}

const validateJobHasWorker = (data: Partial<IJobHasWorker>) => {
    return ValidationBuilder
        .create(data)
        .notEmpty('startDate', 'Date is required')
        .build();
}

const isValid = (data: Partial<IJobHasWorker>) => {
    return Object.keys(validateJobHasWorker(data)).length == 0;
}

type IExistingWorkerR = {
    workerId: number;
}

type IWorkerRow = IWorker & {
    contractorName?: string;
    jobHasContractorId?: number;
}

function JobAddWorkerImpl(props: IProps) {
    const { t } = useTranslation();
    const currentSearchTerm = useStringState();
    const loading = useLoading(false);

    const viewParentSelector = () => props.user?.roles.includes('gestor')
    //TODO remove key
    const showProperties = ![22, 23].includes(props.work.id);

    const subContractors = React.useRef<IJobHasContractor[]>([]);
    const activeContractor = React.useRef<IJobHasContractor>();

    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    const [workers, setWorkers] = React.useState<IPaginateResult<IWorkerRow>>({
        total: 0,
        data: [],
        page: 0,
        limit: 20
    });
    const [selectedWorker, setSelectedWorker] = React.useState<IWorkerRow | undefined>();
    const [parentContractors, setParentContractors] = React.useState<SearchR[]>([]);
    const selectedJobHasContractor = useNumberState();

    type SearchR = {
        parentContractorId?: number;
        parentContractorName?: string;
        jobHasContractorId: number;
    }

    // #1125: Trabajadores a pessma, cada una la suya
    const canAddSubWorkers = props.security.isContractor()
        ? (props.work.contractorSettings?.canAddSubWorkers ?? false)
        : true;

    const searchContractorHasContracts = useSearch<SearchR>({
        search: 'contractorHasContracts.list',
        normalizeKeys: true,
        workId: props.work.id,
        filters: {
            contractorId: selectedWorker?.contractor?.id,
            jobId: props.job.id,
        },
        lazy: true,
        dependencies: [selectedWorker?.contractor?.id, props.job.id]
    });

    React.useEffect(() => {
        if (selectedWorker?.contractor?.id != undefined && viewParentSelector()) {
            searchContractorHasContracts.doSearch({
                contractorId: selectedWorker?.contractor?.id,
                jobId: props.job.id
            });
        }
    }, [selectedWorker?.contractor?.id]);

    React.useEffect(() => {
        if (searchContractorHasContracts.value != undefined
            && searchContractorHasContracts.value.length > 0
            && viewParentSelector()) {
            const parentContractors = searchContractorHasContracts.value.map((c: any) => ({
                parentContractorId: c.parentContractorId,
                parentContractorName: calculateLevel(c),
                jobHasContractorId: c.jobHasContractorId,
            }));
            setParentContractors(parentContractors);
        } else {
            setParentContractors([]);
        }
    }, [searchContractorHasContracts.value]);

    const contractors = [
        { id: undefined, name: t('Filter by contractor') } as any
    ].concat(props.job.contractors.map(c => ({ ...c, name: c.contractor.name })));

    const calculateLevel = (jobHasContractor: SearchR) => {
        let level = 0;
        let parentContractorId = jobHasContractor.parentContractorId;
        while (parentContractorId != undefined) {
            let parentContractor = contractors.find(c => c.id == parentContractorId);
            if (parentContractor != undefined) {
                parentContractorId = parentContractor.parentId
            }
            else {
                parentContractorId = undefined;
            }
            level++;
        }

        level--;

        return level === -1
            ? t('main contractor')
            : `${jobHasContractor.parentContractorName} ${t('level')} ${level}`;
    }

    const existingWorkers = useSearch<IJobHasWorker>({
        workId: props.work.id,
        search: 'jobs/existingWorkers',
        filters: {
            jobId: props.job.id,
            workId: props.work.id,
        },
    });

    const initializeForWorkers = async () => {
        const contractors: IJobHasContractor[] = await props.getJobContractors(props.work.id, props.job.id);
        subContractors.current = contractors;
    }

    const initializeForContractors = async () => {
        const contractors: IJobHasContractor[] = await props.getJobContractors(props.work.id, props.job.id);
        const selectedContractor = props.selectedJobHasContractor;
        activeContractor.current = selectedContractor;
        const subcontractors = contractors.filter(c => c.parentId == selectedContractor?.id);
        const subcontractorIds = subcontractors.map(c => c.contractorId);
        const deepSubcontractors: any = [];
        contractors.map(c => {
            if (subcontractorIds.includes(c.parentId ?? 0)) {
                deepSubcontractors.push(c);
            }
        });
        subContractors.current = subcontractors.concat(deepSubcontractors);
    }

    const initialize = () => {
        if (props.security.isContractor()) {
            return initializeForContractors();
        }
        else {
            return initializeForWorkers();
        }
    };

    React.useEffect(() => {
        initialize();
    }, []);

    const doSearch = loading.wrap(async (term: string | undefined) => {
        const jobHasContractors =
            props.security.isContractor() && canAddSubWorkers && props.selectedJobHasContractor
                ? subContractors.current.concat([props.selectedJobHasContractor])
                : props.security.isContractor() && props.selectedJobHasContractor
                    ? [props.selectedJobHasContractor]
                    : props.security.isContractor()
                        ? []
                        : subContractors.current;

        let data: any = [];

        for (const jhc of jobHasContractors) {
            const res = await props.searchWorkers(
                props.work.id,
                term,
                props.job.id,
                jhc.contractorId);
            data = data.concat(res.data.map((w: IWorker) => ({
                ...w,
                contractorName: jhc.contractor?.name,
                jobHasContractorId: jhc.id
            })));
        }

        const excludeWorkers = existingWorkers
            .value?.filter((w: IJobHasWorker) =>
                w.jobHasContractorId == props.selectedJobHasContractor?.id
                || props.selectedJobHasContractor == undefined)?.map((w: IJobHasWorker) => w.workerId) ?? [];

        const workers = unique(data)
            .filter(w => w.contractorId != undefined && !excludeWorkers.includes(w.id));

        setWorkers({
            ...data,
            data: workers,
        });
    });

    React.useEffect(() => {
        doSearch(currentSearchTerm.value);
    }, [existingWorkers.value, currentSearchTerm.value]);

    React.useEffect(() => {
        setActiveIndex(selectedWorker ? 1 : 0);
    }, [selectedWorker]);

    const saveWorkerRelation = loading.wrap(async (data: Partial<IJobHasWorker>) => {
        const resp = await props.saveJobHasWorker(props.work.id, data);
        if (resp.hasValue) {
            props.onSuccess('Worker added successfully');
            return { ok: true };
        }
        else {
            return { ok: false, message: resp.error };
        }
    });

    const form = useForm<Partial<IJobHasWorker>>({
        initialValues: {
            jobId: props.job.id,
            properties: [],
            startDate: DateUtils.toIso8601(new Date()),
        },
        validate: (data: Partial<IJobHasWorker>) => {
            return validateJobHasWorker(data);
        },
        onSubmit: (data: Partial<IJobHasWorker>) => {
            data.workerId = selectedWorker?.id;
            data.jobHasContractorId =
                selectedWorker?.jobHasContractorId
                ?? props.jobHasContractorId
                ?? selectedJobHasContractor.value;
            return saveWorkerRelation(data);
        },
    });

    const workersTable = useDataTable({
        columns: [
            { title: t('workerCode'), field: 'code', sortKey: 'code' },
            { title: t('name'), field: 'name', sortKey: 'name' },
            { title: t('surname'), field: 'surname', sortKey: 'surname' },
            { title: t('Contractor'), field: 'contractor', sortKey: 'contractor', render: d => d.contractor?.name }
        ],
        actions: [
            { text: 'Select', headerStyle: { width: '116px' }, className: 'alt slim', onClick: setSelectedWorker }
        ],
        data: workers?.data,
    });

    return <>
        <BreadcrumbItem>
            {t('Add worker')}
        </BreadcrumbItem>
        <Accordion
            className='flat borderless'
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}>
            <AccordionTab header={t('Search worker')}>
                <div className='c'>
                    <div className='r'>
                        <input
                            className='e'
                            type='search'
                            value={currentSearchTerm.value}
                            onChange={currentSearchTerm.set} />
                        {loading.render()}
                        {existingWorkers.renderLoading()}
                    </div>
                    {!existingWorkers.loading.isLoading() &&
                        workersTable()
                    }
                </div>
            </AccordionTab>
            <AccordionTab header={t('Worker data')} disabled={!selectedWorker}>
                {selectedWorker &&
                    <form
                        className='sm pd c'
                        onSubmit={form.handleSubmit}>
                        <div className='r'>
                            <div className='c e form-2'>
                                <F label={t('Worker to add')}>
                                    <span>
                                        <span className='sm pd'>
                                            {selectedWorker?.name}
                                        </span>
                                        <span className='sm pd'>
                                            {selectedWorker?.surname}
                                        </span>
                                    </span>
                                </F>
                                <F label={t('Contractor')}>
                                    <span>
                                        <span className='sm pd'>
                                            {selectedWorker?.contractor?.name}
                                        </span>
                                    </span>
                                </F>
                                {viewParentSelector() && <F label={t('contractor')} labelClassName='md mr-right'>
                                    <Select
                                        options={parentContractors}
                                        optionLabel='parentContractorName'
                                        optionValue='jobHasContractorId'
                                        placeholder={t('Select parent contractor')}
                                        value={selectedJobHasContractor.value}
                                        onChange={selectedJobHasContractor.set} />
                                </F>}
                                <div className='errors-container'>
                                    {form.errorBox()}
                                </div>
                            </div>

                            {showProperties && <div className='c sm pd e'>
                                <DynamicProperties
                                    propertyGroupTypes={props.propertyGroupTypes}
                                    object={form.values}
                                    objectType={PropertyGroupObjectType.JobHasWorker}
                                    onChange={(properties: IPropertyGroup[]) => form.setFieldValue('properties', properties)} />
                            </div>}
                        </div>
                        <div className='e'></div>
                        <div className='cols-2 r'>
                            <span className='e'></span>
                            <div className='p-buttonset'>
                                <button
                                    onClick={() => props.onCancel()}>
                                    {t('Cancel')}
                                </button>
                                <button
                                    disabled={!isValid(form.values) || form.values.startDate <= props.job.startDate}
                                    type='submit'
                                    className='primary'>
                                    {t('Create')}
                                </button>
                            </div>
                        </div>
                    </form>}
            </AccordionTab>
        </Accordion>
    </>;
}


export function JobAddWorker(props: IProps) {
    const ctx = {
        BreadcrumbItem: BreadcrumbItem
    }

    if (props.user.roles.includes('gestor')) {
        return JobAddWorkerImpl(props);
    }

    const res = props
        .moduleManager
        .renderComponent<IProps>('JobAddWorker', { ...props, ctx }, JobAddWorkerImpl);

    return res;
}
