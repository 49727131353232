import { IContractorRequest, IPaginateOptions } from '../../models';
import { IJobHasContractor, IJobHasDepartment, IJobHasContractorAppUser, IJobHasMachinery, IJob, IJobHasWorker } from '../../models/jobs';
import { m, q } from './graphql';
import { formatProperty, parseProperties } from './utils';

export async function getJob(workId: number, jobId: number) {
    const resp = await q(`
query GetJob($workId: Int!, $jobId: Int!) {
    work(id: $workId) {
        job(id: $jobId) {
            id, name, code, workId, workspaceId, activityTypeId, workShiftId, contractTypeId,
                description, startDate, endDate, remarks, preventiveResourceId, isActive, informationLoaded,
            departments {
                id, departmentId, jobId, responsibleId, dateTime, isActive, isMain,
                endDateTime, department { id, name }, responsible { id, name, surname }
            },
            currentStatus {
                id, jobId, jobStatusTypeId, dateTime, isCurrent,
            },
            contractors {
                id, jobsDescription, contactEmail, contactName, contactSurname, contactPhone,
                startDate,
                jobId,
                contractorId,
                shipmentNumber,
                parentId,
                currentStatusType, currentStatusDate,
                hierarchyLevel,
                properties {
                    id, jobHasContractorId, propertyGroupTypeId, value
                },
                contractor {
                    id, name, code, legalFormId, activityTypeId, address, postalCode, countryId, regionId, countyId, currentStatusType, currentStatusDate,
                }
            },
            properties {
                id, jobId, propertyGroupTypeId, value
            }
        }
    }
}`, { workId, jobId });

    return resp;
}

export function getJobContractors(workId: number, jobId: number, name: string = "", statusTypeId: number = 0) {

    return q(`
query GetJobContractors($workId: Int!, $jobId: Int!, $name: String, $statusTypeId: Int) {
    work(id: $workId) {
        job(id: $jobId) {
            contractors(name: $name, resourceStatusType: $statusTypeId) {
                id, jobsDescription, contactEmail, contactName, contactSurname, contactPhone,
                startDate,
                jobId,
                contractorId,
                parentId,
                shipmentNumber,
                currentStatusType, currentStatusDate, forcedStatusUntilDate,
                hierarchyLevel,
                properties {
                    id, jobHasContractorId, propertyGroupTypeId, value
                },
                contractor {
                    id, name, code, legalFormId, activityTypeId, address, postalCode, countryId, regionId, countyId, currentStatusType, currentStatusDate,
                }
                status {
                    id, statusTypeId, datetime, isCurrent,
                }
            }
        }
    }
    }`,
        { workId, jobId, name, statusTypeId },
        { returnPath: 'work.job.contractors' });
}

export function getJobContractorsViewModel(workId: number, jobId: number) {
    return q(`
query GetJobContractorsViewModel($workId: Int!, $jobId: Int!) {
    work(id: $workId) {
        job(id: $jobId) {
            contractorsViewModel {
                id, jobId, contractorId, countContractorMachineries, countContractorWorkers,
                countContractorSubContractors, countSubContractorsAuthorized,
                countMachineriesAuthorized, countWorkersAuthorized, contractorName,
                contractorStartDate, contractorStatusType
            }
        }
    }
    }`,
        { workId, jobId },
        { returnPath: 'work.job.contractorsViewModel' });
}

export async function getJobContractorRequests(workId: number, jobId: number) {
    const resp = await q(`
query GetJob($workId: Int!, $jobId: Int!) {
    work(id: $workId) {
        job(id: $jobId) {
            contractorRequests {
                id, contactEmail, contactName, contactSurname, contactPhone,
                appUserId,
                address, activityTypeId, postalCode, countryId, regionId, countyId,
                contractorCode, contractorName,
                jobsDescription, startDate, accepted, discarded, acceptedDate, resultMessage,
                jobId, legalFormId,
                properties {
                    id, jobHasContractorRequestId, propertyGroupTypeId, value
                }
            }
        }
    }
}`, { workId, jobId });

    return resp.data.work.job.contractorRequests;
}

export async function getJobDepartments(workId: number, jobId: number) {
    const resp = await q(`
query GetJobDepartments($workId: Int!, $jobId: Int!) {
    work(id: $workId) {
        job(id: $jobId) {
            departments {
                id, departmentId, jobId, responsibleId, dateTime, isActive, isMain,
                endDateTime, department { id, name }, responsible { id, name }
            }
        }
    }
}`, { workId, jobId });
    return resp.data.work.job.departments;
}

export async function searchContractors(workId: number, searchTerm: string) {
    const resp = await q(`query SearchContractors($workId: Int!, $searchTerm: String!) {
        work(id: $workId) {
            searchContractors(searchTerm: $searchTerm) {
                id, name, code
            }
        }
    }`, { searchTerm, workId });

    return resp.data.work.searchContractors;
}

export async function createContractorRequest(workId: number, contractorRequest: IContractorRequest) {
    const inputData = {
        ...contractorRequest,
        properties: contractorRequest?.properties?.map(formatProperty(workId))
    };

    const resp = await m(`mutation CreateContractorRequest($workId: Int!, $inputData: JobHasContractorRequestInputType!) {
        saveJobHasContractorRequest(workId: $workId, jobHasContractorRequest: $inputData) {
            hasValue, value, error
        }
    }`, { workId, inputData });

    return resp?.data.saveJobHasContractorRequest;
}

export async function processContractorRequest(workId: number, contractorRequest: IContractorRequest) {
    const inputData = {
        ...contractorRequest,
        properties: contractorRequest?.properties?.map(formatProperty(workId))
    };

    const resp = await m(`mutation ProcessContractorRequest($workId: Int!, $inputData: JobHasContractorRequestInputType!) {
        processJobHasContractorRequest(workId: $workId, jobHasContractorRequest: $inputData) {
            hasValue, value, error
        }
    }`, { workId, inputData });

    return resp?.data.processJobHasContractorRequest;
}

export async function activateJobHasContractor(workId: number, jobId: number, contractorId: number, jobHasContractorId: number) {
    const resp = await m(`mutation ActivateJobHasContractor($workId: Int!, $jobId: Int!, $contractorId: Int!, $jobHasContractorId: Int!) {
        activateJobHasContractor(workId: $workId, jobId: $jobId, contractorId: $contractorId, jobHasContractorId: $jobHasContractorId) {
            hasValue, value, error
        }
    }`, { workId, jobId, contractorId, jobHasContractorId });

    return resp?.data.activateJobHasContractor;
}

export async function saveJobHasContractor(workId: number, contractor: IJobHasContractor) {
    const inputData = {
        ...contractor,
        properties: contractor.properties?.map(formatProperty(workId)),
    };
    const resp = await m(`mutation CreateJobHasContractor($workId: Int!, $inputData: JobHasContractorInputType!) {
        saveJobHasContractor(workId: $workId, jobHasContractor: $inputData) {
            hasValue, value, error
        }
    }`, { workId, inputData });

    return resp?.data.saveJobHasContractor;
}

export async function saveJobHasDepartment(workId: number, jobId: number, jobHasDepartment: IJobHasDepartment) {
    const resp = await m(`mutation SaveJobHasDepartment($workId: Int!, $jobId: Int!, $jobHasDepartment: JobHasDepartmentInputType!) {
        saveJobHasDepartment(workId: $workId, jobId: $jobId, jobHasDepartment: $jobHasDepartment) {
            error, hasValue, value
        }
    }`, { workId, jobHasDepartment, jobId });

    return resp?.data.saveJobHasDepartment;
}

export async function removeJobHasDepartment(workId: number, jobId: number, jobHasDepartmentId: number) {
    const resp = await m(`mutation RemoveJobHasDepartment($workId: Int!, $jobId: Int!, $jobHasDepartmentId: Int!) {
        removeJobHasDepartment(workId: $workId, jobId: $jobId, jobHasDepartmentId: $jobHasDepartmentId) {
            error, hasValue,
        }
    }`, { workId, jobHasDepartmentId, jobId });

    return resp?.data.removeJobHasDepartment;
}

export async function removeJobHasContractor(workId: number, jobId: number, jobHasContractorId: number) {
    const resp = await m(`mutation RemoveJobHasContractor($workId: Int!, $jobId: Int!, $jobHasContractorId: Int!) {
        removeJobHasContractor(workId: $workId, jobId: $jobId, jobHasContractorId: $jobHasContractorId) {
            hasValue, value, error
        }
    }`, { workId, jobId, jobHasContractorId });

    return resp?.data.removeJobHasContractor;
}

export async function removeJobHasContractorRequest(workId: number, jobId: number, sourceContractorId: number, targetContractorId: number) {
    const resp = await m(`mutation RemoveJobHasContractorRequest($workId: Int!, $jobId: Int!, $sourceContractorId: Int!, $targetContractorId: Int!) {
        removeJobHasContractorRequest(workId: $workId, jobId: $jobId, sourceContractorId: $sourceContractorId, targetContractorId: $targetContractorId) {
            hasValue, value, error
        }
    }`, { workId, jobId, sourceContractorId, targetContractorId });

    return resp?.data.removeJobHasContractorRequest;
}

export async function removeJobHasContractorInvitation(workId : number, jobId : number, contractorName : string, startDate : string) {
    const resp = await m(`mutation removeJobHasContractorInvitation($workId: Int!, $jobId: Int!, $contractorName: String!, $startDate: String!) {
        removeJobHasContractorInvitation(workId: $workId, jobId: $jobId, contractorName: $contractorName, startDate: $startDate) {
            hasValue, value, error
        }
    }`, { workId, jobId, contractorName, startDate });

    return resp?.data.removeJobHasContractorInvitation;
}

export async function deleteJobHasContractor(workId: number, jobId: number, jobHasContractorId: number) {
    const resp = await m(`mutation DeleteJobHasContractor($workId: Int!, $jobId: Int!, $jobHasContractorId: Int!) {
        deleteJobHasContractor(workId: $workId, jobId: $jobId, jobHasContractorId: $jobHasContractorId) {
            hasValue, value, error
        }
    }`, { workId, jobId, jobHasContractorId });

    return resp?.data.deleteJobHasContractor;
}

export async function deleteJobHasWorker(workId: number, jobId: number, jobHasWorkerId: number) {
    const resp = await m(`mutation DeleteJobHasWorker($workId: Int!, $jobId: Int!, $jobHasWorkerId: Int!) {
        deleteJobHasWorker(workId: $workId, jobId: $jobId, jobHasWorkerId: $jobHasWorkerId) {
            hasValue, value, error
        }
    }`, { workId, jobId, jobHasWorkerId });

    return resp?.data.deleteJobHasWorker;
}

export async function deleteJobHasMachinery(workId: number, jobId: number, jobHasMachineryId: number) {
    const resp = await m(`mutation DeleteJobHasWorker($workId: Int!, $jobId: Int!, $jobHasMachineryId: Int!) {
        deleteJobHasMachinery(workId: $workId, jobId: $jobId, jobHasMachineryId: $jobHasMachineryId) {
            hasValue, value, error
        }
    }`, { workId, jobId, jobHasMachineryId });

    return resp?.data.deleteJobHasMachinery;
}

export function getJobHasContractor(workId: number, jobId: number, jobHasContractorId: number) {
    return q(`query GetJobHasContractor($workId: Int!, $jobId: Int!, $jobHasContractorId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                jobHasContractor(id: $jobHasContractorId) {
                    id, jobsDescription, contactEmail, contactName, contactSurname, contactPhone,
                    startDate,
                    jobId,
                    contractorId,
                    parentId,
                    currentStatusType, currentStatusDate, forcedStatusUntilDate,
                    hierarchyLevel,
                    properties {
                        id, jobHasContractorId, propertyGroupTypeId, value
                    },
                    contractor {
                        id, name, code, legalFormId, activityTypeId, address, postalCode, countryId, regionId, countyId, currentStatusType, currentStatusDate,
                    }
                }
            }
        }
    }`, { workId, jobId, jobHasContractorId }, {
        returnPath: 'work.job.jobHasContractor',
    });
}

export async function getJobHasContractorAppUsers(workId: number, jobId: number, jobHasContractorId: number) {
    const resp = await q(`query GetJobHasContractorAppUsers($workId: Int!, $jobId: Int!, $jobHasContractorId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                jobHasContractor(id: $jobHasContractorId) {
                    appUsers {
                        id, jobContractorId, appUserId, startDate, endDate,
                        appUser {
                            id, userName, email
                        }
                    }
                }
            }
        }
    }`, { workId, jobId, jobHasContractorId });

    return resp.data.work.job.jobHasContractor.appUsers;
}

export async function searchMachineries(workId: number, term: string, machineryTypeId: number | undefined = undefined, contractorId: number | undefined, jobId: undefined | number) {
    const resp = await q(`query SearchMachineries($workId: Int!, $term: String, $machineryTypeId: Int, $contractorId: Int, $jobId: Int) {
        work(id: $workId) {
            searchMachineries(searchTerm: $term, machineryTypeId: $machineryTypeId, contractorId: $contractorId, jobId: $jobId) {
                id, name, code, machineryTypeId, machinerySubTypeId, contractorId,
                prefixLicenseNumber, licenseNumber, suffixLicenseNumber, contractor {
                    id, name
                },
            }
        }
    }`, { machineryTypeId, term, workId, contractorId, jobId });

    return resp.data.work.searchMachineries;
}

export async function getJobHasMachineries(workId: number, jobId: number) {
    const resp = await q(`query GetJobHasMachineries($workId: Int!, $jobId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                machineries {
                    id, jobId, machineryId, startDate, endDate,
                    currentStatusType, currentStatusDate,
                    hasDelayedRevokation, forcedStatusUntilDate,
                    properties {
                        id, propertyGroupTypeId, jobHasMachineryId, value
                    },
                    machinery {
                        id, name, code, machineryTypeId, machinerySubTypeId,
                        currentStatusType, currentStatusDate,
                        contractor { id, name }, contractorId
                        prefixLicenseNumber, licenseNumber, suffixLicenseNumber
                    }
                }
            }
        }
    }`, { jobId, workId });

    return resp.data.work.job.machineries;
}

export function getJobHasMachinery(workId: number, jobId: number, relationId: number) {
    return q(`query GetJobHasMachinery($workId: Int!, $jobId: Int!, $relationId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                machinery(id: $relationId) {
                    id, jobId, machineryId, startDate, endDate,
                    currentStatusType, currentStatusDate, forcedStatusUntilDate,
                    jobHasContractorId,
                    properties {
                        id, propertyGroupTypeId, jobHasMachineryId, value
                    },
                    machinery {
                        id, name, code, machineryTypeId, machinerySubTypeId,
                        currentStatusType, currentStatusDate, contractorId,
                        prefixLicenseNumber, licenseNumber, suffixLicenseNumber,
                    }
                }
            }
        }
    }`,
        { jobId, workId, relationId },
        {
            returnPath: 'work.job.machinery'
        });
}

export async function activateJobHasMachinery(workId: number, jobId: number, machineryId: number, jobHasMachineryId: number) {

    const resp = await m(`mutation ActiveJobHasMachinery($workId: Int!, $jobId: Int!, $machineryId: Int!, $jobHasMachineryId: Int!) {
        activateJobHasMachinery(workId: $workId, jobId: $jobId, machineryId: $machineryId, jobHasMachineryId: $jobHasMachineryId) {
            hasValue, value, error
        }
    }`, { workId, jobId, machineryId, jobHasMachineryId });

    return resp?.data.activateJobHasMachinery;
}

export async function saveJobHasMachinery(
    workId: number,
    jobId: number,
    data: IJobHasMachinery,
    jobHasContractorId: number) {
    const inputData = {
        machineryId: data.machineryId,
        jobId: data.jobId,
        jobHasContractorId: data.jobHasContractorId ?? jobHasContractorId,
        machineryTypeId: data.machineryTypeId,
        machinerySubTypeId: data.machinerySubTypeId,
        machineryName: data.machineryName,
        startDate: data.startDate,
        machineryCode: data.machineryCode,
        machineryPrefixLicenseNumber: data.machineryPrefixLicenseNumber,
        machineryLicenseNumber: data.machineryLicenseNumber,
        machinerySuffixLicenseNumber: data.machinerySuffixLicenseNumber,
        properties: (data.properties ?? []).map(formatProperty(workId))
    };

    const resp = await m(`mutation SaveJobHasMachineries($workId: Int!, $jobId: Int!, $jobHasContractorId: Int!, $inputData: JobHasMachineryInputType!) {
        saveJobHasMachinery(workId: $workId, jobId: $jobId, jobHasContractorId: $jobHasContractorId, data: $inputData) {
            hasValue, value, error
        }
    }`, { workId, jobId, jobHasContractorId, inputData });
    return resp?.data.saveJobHasMachinery;
}

export async function removeJobHasMachinery(workId: number, jobId: number, jobHasMachineryId: number) {
    const resp = await m(`mutation RemoveJobHasMachineries($workId: Int!, $jobId: Int!, $jobHasMachineryId: Int!) {
        removeJobHasMachinery(workId: $workId, jobId: $jobId, jobHasMachineryId: $jobHasMachineryId) {
            hasValue, value, error
        }
    }`, { workId, jobHasMachineryId, jobId });

    return resp?.data.removeJobHasMachinery;
}

export async function saveJob(data: IJob, departments: IJobHasDepartment[] = []) {
    const inputData = {
        id: data.id,
        contractTypeId: data.contractTypeId,
        description: data.description,
        endDate: data.endDate,
        startDate: data.startDate,
        remarks: data.remarks,
        workId: data.workId,
        name: data.name,
        code: data.code,
        workspaceId: data.workspaceId,
        workShiftId: data.workShiftId,
        activityTypeId: data.activityTypeId,
        preventiveResourceId: data.preventiveResourceId,
        isActive: true,
        properties: (data.properties ?? []).map(formatProperty(data.workId)),
    };

    const resp = await m(`mutation SaveJob($workId: Int!, $inputData: JobInputType!) {
        saveJob(workId: $workId, job: $inputData) {
            hasValue, value, error
        }
    }`, { workId: data.workId, inputData, departments });

    return resp?.data.saveJob;
}

export async function getJobHasWorker(workId: number, jobId: number, relationId: number) {
    return q<IJobHasWorker>(`query GetJobHasWorker($workId: Int!, $jobId: Int!, $relationId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                worker(id: $relationId) {
                    id, jobId, startDate, workerId,
                    currentStatusType, currentStatusDate, forcedStatusUntilDate,
                    jobHasContractorId,
                    worker {
                        id, name, surname, code,
                        currentStatusType, currentStatusDate, forcedStatusUntilDate,
                        workerTypeId, workerDocumentTypeId,
                        contractor { id, name }, contractorId
                    },
                    properties {
                        id, jobHasWorkerId, propertyGroupTypeId, value
                    },
                }
            }
        }
    }`,
        { workId, jobId, relationId }, {
        returnPath: 'work.job.worker',
        map: w => {
            return {
                ...parseProperties(w),
                fullName: `${w.worker?.name} ${w.worker?.surname}`,
            };
        }
    });
}

export async function getJobHasWorkers(workId: number, jobId: number) {
    return q<IJobHasWorker>(`query GetJobHasWorkers($workId: Int!, $jobId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                workers {
                    id, jobId, startDate, workerId,
                    currentStatusType, currentStatusDate,
                    hasDelayedRevokation, forcedStatusUntilDate,
                    properties {
                        id, jobHasWorkerId, propertyGroupTypeId, value
                    },
                    worker {
                        id, name, surname, code,
                        currentStatusType, currentStatusDate,
                        workerTypeId, workerDocumentTypeId,
                        contractor { id, name }, contractorId
                    },
                }
            }
        }
    }`,
        { workId, jobId }, {
        returnPath: 'work.job.workers',
        mapEvery: w => parseProperties(w)
    });
}

export async function searchWorkers(
    workId: number,
    searchTerm: string,
    jobId: number | undefined = undefined,
    contractorId: number | undefined = undefined,
    limit: number | undefined = undefined) {
    const options: IPaginateOptions = {
        limit: limit,
        searchTerm: searchTerm,
    };

    const resp = await q(`query SearchWorkers(
        $workId: Int!,
        $options: PaginateOptionsGraphInputType!,
        $jobId: Int!,
        $contractorId: Int) {
        work(id: $workId) {
            searchWorkers(options: $options, jobId: $jobId, contractorId: $contractorId) {
                page, total, limit, data {
                    id, name, surname, code,
                    currentStatusType, currentStatusDate, forcedStatusUntilDate, contractorId
                    contractor {
                        id, name, code, currentStatusType, currentStatusDate,
                    }
                }
            }
        }
    }`, { jobId, options, workId, contractorId });

    return resp.data.work.searchWorkers;
}

export async function saveJobHasWorker(workId: number, worker: IJobHasWorker) {
    const inputData = {
        id: worker.id,
        jobId: worker.jobId,
        workerId: worker.workerId,
        startDate: worker.startDate,
        jobHasContractorId: worker.jobHasContractorId,
        properties: worker.properties?.map(formatProperty(workId)),
    };

    const resp = await m(`mutation SaveJobHasWorker($workId: Int!, $inputData: JobHasWorkerGraphInputType!) {
        saveJobHasWorker(workId: $workId, jobHasWorker: $inputData) {
            hasValue, value, error
        }
    }`, { workId, inputData });

    return resp?.data.saveJobHasWorker;
}

export async function activateJobHasWorker(workId: number, jobId: number, workerId: number, jobHasWorkerId: number) {
    const resp = await m(`mutation SaveJobHasWorker($workId: Int!, $jobId: Int!, $workerId: Int!, $jobHasWorkerId: Int!) {
        activateJobHasWorker(workId: $workId, jobId: $jobId, workerId: $workerId, jobHasWorkerId: $jobHasWorkerId) {
            hasValue, value, error
        }
    }`, { workId, jobId, workerId, jobHasWorkerId });

    return resp?.data.activateJobHasWorker;
}

export async function removeJobHasWorker(workId: number, jobId: number, jobHasWorkerId: number) {
    const resp = await m(`mutation RemoveJobHasWorker($workId: Int!, $jobId: Int!, $jobHasWorkerId: Int!) {
        removeJobHasWorker(workId: $workId, jobId: $jobId, jobHasWorkerId: $jobHasWorkerId) {
            hasValue, value, error
        }
    }`, { workId, jobId, jobHasWorkerId });

    return resp?.data.removeJobHasWorker;
}

export function saveJobStatus(workId: number, jobId: number, statusTypeId: number, remarks: string) {
    return m(`mutation SaveJobStatus($workId: Int!, $jobId: Int!, $remarks: String, $statusTypeId: Int!) {
       saveJobStatus(workId: $workId, jobId: $jobId, statusTypeId: $statusTypeId, remarks: $remarks) {
            hasValue, value, error
       }
    }`, { workId, jobId, statusTypeId, remarks }, { returnPath: 'saveJobStatus' });
}

export function getJobStatus(workId: number, jobId: number | undefined = undefined) {
    return q(`query GetJobStatus($workId: Int!, $jobId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                status {
                    id, userId, dateTime, jobStatusTypeId, remarks, jobId, isCurrent,
                }
            }
        }
    }`, { jobId, workId }, { returnPath: 'work.job.status' });
}

export function jobNotifyInformationLoaded(workId: number, jobId: number, remarks: string | null = null) {
    return m(`mutation NotifyInformationLoaded($workId: Int!, $jobId: Int!, $remarks: String) {
       jobNotifyInformationLoaded(workId: $workId, jobId: $jobId, remarks: $remarks) {
            hasValue, value, error
       }
    }`, { workId, jobId, remarks, }, { returnPath: 'jobNotifyInformationLoaded' });
}

export function getJobLogs(workId: number, jobId: number) {
    return q(`query GetJobLogs($workId: Int!, $jobId: Int!) {
        work(id: $workId) {
            job(id: $jobId) {
                logs {
                    dateTime, userId, jobLogType
                }
            }
        }
    }`, { workId, jobId }, { returnPath: 'work.job.logs' });
}
