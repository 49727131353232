import * as React from 'react';

import {
    InlineInputText,
    InlineSelect,
    useConfirm,
    useDialogs,
    useFormDialog,
    useLoading,
    useMessage,
    useObjectState,
    useResolveName,
    useStringState,
    useTranslation,
    useTreeTable,
    useForm,
    F
} from '@components';
import {
    IPropertyGroupType,
    IPropertyType,
    AllPropertyTypes,
    IWork,
} from '@models';
import { PropertyTypeForm } from './PropertyTypeForm';
import { PropertyGroupTypeForm, dispositionTypes } from './PropertyGroupTypeForm';
import { flatten } from "@utils";

export interface IProps {
    getPropertyGroupTypes: Function;
    isInternal?: boolean;
    loadWorks: Function;
    objectType: number;
    propertyGroupTypes: IPropertyGroupType[];
    removePropertyGroupType: Function;
    removePropertyType: Function;
    savePropertyGroupType: Function;
    savePropertyType: Function;
    work: IWork;
}

function PropertyGroupStyle({ group, onSave, onClose, workId }: any) {
    const color = useStringState('#ffffff');
    const { t } = useTranslation();

    React.useEffect(() => {
        if (group.style) {
            const style = JSON.parse(group.style);
            color.set(style.backgroundColor ?? '#ffffff');
        }
    }, []);

    const updateColor = async (_: any) => {
        const style = JSON.stringify({ backgroundColor: color.value });
        await onSave(workId, { ...group, style });

        onClose();
    }

    return <div className='c'>
        <div className='md mr pd'>
            <input
                className='we'
                type='color'
                value={color.value}
                onChange={color.set} />
        </div>

        <div className='r'>
            <button onClick={onClose}>
                {t('Cancel')}
            </button>
            <div className='e' />
            <button className='primary' onClick={updateColor}>
                {t('Save')}
            </button>
        </div>
    </div>
}

function PropertyGroupTypeHidableForm({ propertyGroupType, onSave, onClose, workId }: any) {
    const form = useForm({
        initialValues: {
            hidable: propertyGroupType.hidable,
            mandatory: propertyGroupType.mandatory,
        },
    });
    const { t } = useTranslation();


    const doSave = async () => {
        await onSave(workId, {
            ...propertyGroupType,
            hidable: form.values.hidable,
            mandatory: form.values.mandatory
        })
        onClose();
    }

    return <>
        <div className='c md pd g-30'>
            <div className='r g-20 v-center'>
                {form.checkBox('hidable')}
                <label htmlFor="hidable">
                    {t('Hidable')}
                </label>
            </div>
            <div className='r g-20 v-center'>
                {form.checkBox('mandatory')}
                <label htmlFor="mandatory">
                    {t('Mandatory')}
                </label>
            </div>
        </div>
        <div className='r'>
            <button onClick={onClose}>
                {t('Cancel')}
            </button>
            <div className='e' />
            <button className='primary' onClick={doSave}>
                {t('Save')}
            </button>
        </div>
    </>
}

function PropertyType({ propertyType, onSave, onClose, workId }: any) {
    const state = useObjectState(propertyType);
    const { t } = useTranslation();

    const doSave = async () => {
        await onSave(workId, { ...propertyType, help: state.value['help'] });
        onClose();
    }

    return <div className='c'>
        <div className='sm pd'>
            <input
                className='we'
                type='text'
                value={state.field('help')}
                onChange={state.set('help')} />
        </div>

        <div className='r'>
            <button onClick={onClose}>
                {t('Cancel')}
            </button>
            <div className='e' />
            <button className='primary' onClick={doSave}>
                {t('Save')}
            </button>
        </div>
    </div>
}

const parsePropertyGroup = (g: IPropertyGroupType) => {
    return {
        ...g,
        properties: g?.properties?.sort((a, b) => (a?.propertyOrder ?? 0) - (b?.propertyOrder ?? 0)),
    };
}

export function PropertyGroupTypes(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const groupDialogs = useDialogs();
    const msg = useMessage();
    const dialogs = useFormDialog({
        addTitle: t('Add property group'),
    });
    const propertyDialog = useFormDialog({
        editTitle: t('Add property'),
    });
    const resolvePropertyTypeName = useResolveName(AllPropertyTypes, {
        defaultName: t('TEXT'),
    });

    const doRemove = loading.wrap(async (r: any) => {
        if (r.__typename === 'PropertyGroupTypeGraphType') {
            await props.removePropertyGroupType(props.work.id, r.id);
        }
        else {
            await props.removePropertyType(props.work.id, r.id);
        }
        props.loadWorks();
    });

    const confirmRemove = useConfirm({
        message: t('Are you sure you want to remove this property?'),
        accept: doRemove
    });

    const [data, setData] = React.useState<IPropertyGroupType[]>(props.propertyGroupTypes);
    const [addRecord, setAddRecord] = React.useState<IPropertyGroupType | undefined>();
    const [addPropertyRecord, setAddPropertyRecord] = React.useState<IPropertyType | undefined>();

    React.useEffect(() => {
        setData(props.propertyGroupTypes.map(parsePropertyGroup));
    }, [props.propertyGroupTypes]);

    const EditableText = ({ object, style, field, className }: any) =>
        object.__typename === 'PropertyGroupTypeGraphType'
            ? <InlineInputText
                style={style}
                className={className}
                object={object}
                field={field}
                save={(o: IPropertyGroupType) => props.savePropertyGroupType(props.work.id, o)} />
            : <InlineInputText
                className={className}
                object={object}
                field={field}
                save={(o: IPropertyType) => props.savePropertyType(props.work.id, o)} />;

    const isPropertyType = (d: any) => d.__typename != 'PropertyGroupTypeGraphType';
    const isGroupType = (d: any) => !isPropertyType(d);

    const allowDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const onDrop = loading.wrap(async (prop: IPropertyType, event: React.DragEvent<HTMLDivElement>) => {
        const currentOrder = prop.propertyOrder;
        const sourceId = parseInt(event.dataTransfer.getData('text/plain'));
        const sourceProperty = flatten(props.propertyGroupTypes.map(g => g.properties ?? []))
            .find(r => r.id === sourceId);
        if (sourceProperty) {
            sourceProperty.propertyOrder = (currentOrder ?? 0);
            const parent = props.propertyGroupTypes.find(g => g.id === prop.parentId);
            const siblings = (parent?.properties ?? []).filter(r =>
                r.id != sourceId
                && (
                    ((r.propertyOrder ?? 0) > (currentOrder ?? 0))
                    || ((r.propertyOrder === currentOrder) && r.id! > prop.id!)));
            siblings?.forEach(s => s.propertyOrder = (s.propertyOrder ?? 0) + 1);
            prop.propertyOrder = (currentOrder ?? 0) + 1;

            await props.savePropertyType(props.work.id, sourceProperty);
            await props.savePropertyType(props.work.id, prop);
            for (const s of siblings ?? []) {
                await props.savePropertyType(props.work.id, s);
            }
            await reload();
        }
    });

    const onDragStart = (prop: IPropertyType, event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('text/plain', prop.id + '');
    }

    const dataTable = useTreeTable({
        columns: [
            {
                title: 'Name', render: (d: any) => {
                    if (d.__typename === 'PropertyTypeGraphType') {
                        return <div className={'r v-center'}
                            style={{ float: 'right' }}
                            onDrop={e => onDrop(d, e)}
                            onDragOver={allowDrop}>
                            <div className='sort-handle'
                                style={{ color: '#888' }}
                                draggable
                                onDragStart={e => onDragStart(d, e)}>
                                <i className='fas fa-grip-vertical' />
                            </div>
                            <EditableText
                                style={{ backgroundColor: d.styleObj?.backgroundColor }}
                                className='inline-block'
                                object={d}
                                field='name' />
                        </div>
                    }
                    else {
                        return <EditableText
                            style={{ backgroundColor: d.styleObj?.backgroundColor }}
                            className='inline-block w80p'
                            object={d}
                            field='name' />;
                    }
                }
            },
            { title: 'Title', render: (d: any) => <EditableText object={d} field='title' /> },
            {
                title: 'Description', render: (d: any) => <EditableText
                    object={d}
                    field={d.__typename === 'PropertyGroupTypeGraphType' ? 'description' : 'placeholder'} />
            },
            {
                title: 'Type', render: (d: any) => d.__typename == 'PropertyTypeGraphType'
                    ? <InlineSelect
                        options={AllPropertyTypes}
                        optionLabel={'name'}
                        optionValue='id'
                        object={d}
                        save={(d: IPropertyType) => props.savePropertyType(props.work.id, d)}
                        field={'typeId'} />
                    //resolvePropertyTypeName(d.propertyType)
                    : <InlineSelect
                        options={dispositionTypes}
                        optionLabel='name'
                        optionValue='id'
                        object={d}
                        save={(o: IPropertyGroupType) => props.savePropertyGroupType(props.work.id, o)}
                        field='containerType' />
            }
        ],
        childrenProperty: 'properties',
        actions: [
            { icon: 'fas fa-palette', onClick: (g: any) => groupDialogs.show('color', g), disabled: isPropertyType },
            { icon: (g: any) => isGroupType(g) ? 'fas fa-edit' : 'far fa-qestion-circle', onClick: (g: any) => isGroupType(g) ? groupDialogs.show('settings', g) : groupDialogs.show('help', g) },
            { icon: 'plus', onClick: propertyDialog.showEdit, disabled: isPropertyType },
            { icon: 'trash', onClick: confirmRemove },
        ],
        expand: true,
        data,
    });

    const reload = async () => {
        const resp = await props.getPropertyGroupTypes(props.work.id);
        const types = resp.filter((p: IPropertyGroupType) => p.objectType == props.objectType && p.isInternal == props.isInternal);
        setData(types.map(parsePropertyGroup));
    }

    const doAddPropertyType = loading.wrap(async (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();

        const res = await props.savePropertyType(props.work.id, addPropertyRecord);
        msg.set(res);
        await reload();
        propertyDialog.clear();

        props.loadWorks();
    });

    const doAddPropertyGroup = loading.wrap(async (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();

        await props.savePropertyGroupType(props.work.id, addRecord);
        const resp = await props.getPropertyGroupTypes(props.work.id);
        const types = resp
            .filter((p: IPropertyGroupType) => p.objectType == props.objectType && p.isInternal == props.isInternal);
        setData(types);
        dialogs.clear();
    });

    return <div className='c h100p'>
        {dataTable()}
        <div className='e' />

        {groupDialogs.render('color', { title: t('Set color') }, (group: IPropertyGroupType) =>
            <PropertyGroupStyle
                group={group}
                onSave={props.savePropertyGroupType}
                workId={props.work.id}
                onClose={groupDialogs.clear} />
        )}
        {groupDialogs.render('help', { title: t('Set help text') }, (p: IPropertyType) =>
            <PropertyType
                propertyType={p}
                onSave={props.savePropertyType}
                workId={props.work.id}
                onClose={groupDialogs.clear} />
        )}

        {groupDialogs.render('settings', { title: t('Settings') }, (g: IPropertyGroupType) =>
            <PropertyGroupTypeHidableForm
                propertyGroupType={g}
                onSave={props.savePropertyGroupType}
                workId={props.work.id}
                onClose={groupDialogs.clear} />
        )}

        {dialogs.render((_: any) =>
            <form onSubmit={doAddPropertyGroup}>
                <PropertyGroupTypeForm
                    loading={loading.isLoading()}
                    footer={(props: any) => <dialogs.Footer {...props} />}
                    objectType={props.objectType}
                    isInternal={props.isInternal}
                    onChange={setAddRecord}
                    workId={props.work.id} />
            </form>)}

        {propertyDialog.render((data: IPropertyGroupType) =>
            <form onSubmit={doAddPropertyType}>
                <PropertyTypeForm
                    loading={loading.isLoading()}
                    footer={(props: any) => <propertyDialog.Footer {...props} />}
                    onChange={setAddPropertyRecord}
                    parentId={data.id!} />
            </form>)}

        <div className='r'>
            <div className='e' />
            {msg.renderIfError()}
            {loading.render()}
            <button className='primary' onClick={dialogs.showAdd}>
                <i className='pi pi-plus' />
                {t('Add property group')}
            </button>
        </div>
    </div>;
}
