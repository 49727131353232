import * as React from 'react';

import { IMessage, MessageType } from '@models/notifications';

export interface IProps {
    message: IMessage;
}

export function NotificationMessageDisplayName({message}: IProps) {
    if (false && message.notificationType === MessageType.PENDING_REQUIREMENT && message.data) {
        return <div className='message-display-name'>
            <strong>{message.data.ResourceName}</strong>
            <i className='sm pd-left'>{message.data.RequirementName}</i>
        </div>;
    }
    else {
        return null;
    }
}