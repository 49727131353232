import { connect } from 'react-redux';
import { ApplicationState } from '../store';

import Footer from '@components/Footer';

export default connect(
    (state: ApplicationState) => ({
        work: state.user.work,
    })
)(Footer as any) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
