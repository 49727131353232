import * as React from 'react';

import { BreadcrumbItem, useTranslation } from "@components";
import { IJob, ISecurity } from '@models';

import './JobInformationLoaded.scss';

export interface IProps {
    job: IJob;
    jobId: number;
    security: ISecurity;
    workId: number;
}

export function JobInformationLoaded(props: IProps) {
    const { t } = useTranslation();

    return <div className={'c we he JobInformationLoaded'}>
        <BreadcrumbItem text={t('job.notify-information-loaded')} />

        <div className={'md pd mr center c'}>
            <div className={'box md pd message'}>
                {t('job.information-loaded.download')}
            </div>

            <i className={'fas fa-download'} />

            <a href={`/api/${props.workId}/jobs/${props.jobId}/requirements/download`}
               className={'button primary'}
               target={'_blank'}>
                {t('Download')}
            </a>
        </div>
    </div>
}