import { getClient, gql } from "./graphql";

export async function initialize() {
    const client = getClient('initialize');
    const query = gql`
query Initialize {
    user {
        id, userName, email, roles, policies,
        departments, workFunctions,
        workerIds, obliqueDepartments,
        contractorIds
    },
    documentTypes {
        id, name, description, isActive
    },
    countries {
        id, name, code,
        regions {
            id, name,
            counties {
                id, name, code
            }
        }
    },
    requirementExpirationTypes {
        id, name, description
    },
    requirementTypeKinds {
        id, name, description
    }
}`;

    const resp = await client.query({ query });

    return resp;
}
